import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
import {
  Profile,
  ProfileType,
  IndividualProfileSignUp,
  GroupProfileSignUp,
} from "./profile"

//#region types
export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  profiles: Profile[]
  profile: Profile | null | undefined
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type FetchProfilesPayload = {
  auth: Auth0ContextInterface
  page?: number
  perPage?: number
}
export const fetchProfiles = createAsyncThunk<any, FetchProfilesPayload>(
  "admin/profiles/fetchProfiles",
  async ({ auth }) => {
    return useApi(auth, "/admin/profiles").then((res) => res.json())
  }
)

type CreateIndividualProfilesPayload = {
  auth: Auth0ContextInterface
  profile: IndividualProfileSignUp
}
export const createIndividualProfile = createAsyncThunk<
  any,
  CreateIndividualProfilesPayload
>("admin/profiles/createIndividualProfile", async ({ auth, profile }) => {
  return useApi(auth, "/admin/profiles", {
    method: "POST",
    body: JSON.stringify({ ...profile, type: ProfileType.INDIVIDUAL }),
  }).then((res) => res.json())
})

type CreateGroupProfilesPayload = {
  auth: Auth0ContextInterface
  profile: GroupProfileSignUp
}
export const createGroupProfile = createAsyncThunk<
  any,
  CreateGroupProfilesPayload
>("admin/profiles/createGroupProfile", async ({ auth, profile }) => {
  return useApi(auth, "/admin/profiles", {
    method: "POST",
    body: JSON.stringify({
      ...profile,
      type: ProfileType.GROUP,
    }),
  }).then((res) => res.json())
})

type ArchiveProfilePayload = {
  auth: Auth0ContextInterface
  profileId: string
}
export const archiveProfile = createAsyncThunk<any, ArchiveProfilePayload>(
  "admin/profiles/archiveProfile",
  async ({ auth, profileId }) => {
    return useApi(auth, `/admin/profiles/${profileId}/archive`, {
      method: "PATCH",
    }).then((res) => res.json())
  }
)

export const clearState = createAsyncThunk<any>(
  "admin/profiles/clearState",
  () => {
    return
  }
)

type FetchProfilePayload = {
  auth: Auth0ContextInterface
  username: string
}
export const fetchProfile = createAsyncThunk<any, FetchProfilePayload>(
  "admin/profiles/fetchProfile",
  async ({ auth, username }) => {
    return useApi(auth, `/admin/profile/${username}`).then((res) => res.json())
  }
)

type UpdateProfilePayload = {
  auth: Auth0ContextInterface
  profile: Profile
}
export const updateProfile = createAsyncThunk<any, UpdateProfilePayload>(
  "admin/profile/updateProfile",
  async ({ auth, profile }) => {
    const form = new FormData()
    for (let [key, value] of Object.entries(profile)) {
      if (key === "location" && profile.type === "individual") {
        const locationTmp = value.split(",")
        let street = ""
        if (locationTmp.length - 4 >= 0) {
          const streetTmp = locationTmp[locationTmp.length - 4]
          if (/^\d/.test(streetTmp)) {
            street = streetTmp + ", "
            value = value.replace(street, "")
          }
        }
      }
      if (value) form.append(key, value)
    }

    form.delete("dailyNewsletter")
    form.delete("weeklyNewsletter")
    form.delete("awards")
    form.delete("credentials")
    form.delete("file") //file upload moved from api to react app
    form.delete("groups")
    form.delete("placeholderAvatar")
    form.delete("skills")
    //form.delete("isAdmin")

    if (!profile.role) form.append("role", "")
    if (!profile.linkedinUrl) form.append("linkedinUrl", "")
    if (!profile.twitterUrl) form.append("twitterUrl", "")
    if (!profile.facebookUrl) form.append("facebookUrl", "")
    if (!profile.instagramUrl) form.append("instagramUrl", "")
    if (!profile.personalCompanyUrl) form.append("personalCompanyUrl", "")

    form.append("dailyNewsletter", JSON.stringify(profile.dailyNewsletter))
    form.append("weeklyNewsletter", JSON.stringify(profile.weeklyNewsletter))

    return useApi(auth, `/admin/profile/${profile.id!}`, {
      method: "PATCH",
      body: form,
      headers: {
        "Content-Type": "Automatic",
      },
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  profiles: [],
  profile: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "adminProfiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.profiles = action.payload
        state.error = null
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.profile = action.payload
        state.error = null
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profile = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(createIndividualProfile.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.error = null
      })
      .addCase(createIndividualProfile.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(createGroupProfile.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.error = null
      })
      .addCase(createGroupProfile.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(archiveProfile.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.error = null
      })
      .addCase(archiveProfile.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        const filtered = state.profiles.map((profile) => {
          if (profile.username === action.payload.username)
            profile.archivedAt = new Date()
          return profile
        })
        state.profiles = filtered
      })
      .addCase(archiveProfile.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(clearState.fulfilled, (state) => {
        state.error = undefined
        state.profile = undefined
      })
  },
})
//#endregion

//#region selectors
export const selectAllProfiles = ({ adminProfiles }: RootState) =>
  adminProfiles.profiles
export const selectProfile = ({ adminProfiles }: RootState) =>
  adminProfiles.profile
//#endregion
