import { Typography, TextField, Box } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const CommentField = styled(TextField)`
  && {
    background-color: ${colors.white};
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiInputBase-root {
    border-radius: 0;
    border: none;
  }

  textarea {
    font-size: 14px;
    color: #434343;

    ::placeholder {
      opacity: 0.8;
    }
  }

  fieldset {
    display: none;
  }
`

export const CommentsBox = styled(Box)`
  margin-top: 32px;
`

export const CommentBox = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;
`

export const ProfileAvatar = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;

  ${theme.breakpoints.down("xs")} {
    width: 32px;
    height: 32px;
  }
`

export const CommentArea = styled(Box)`
  background-color: #f7f7f7;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 10px;
  }
`

export const CommentAuthorName = styled(Typography)`
  font-size: 20px;
  color: #2d2d2d;

  && span {
    color: #979797;
    font-size: 14px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;

    && span {
      font-size: 12px;
    }
  }
`

export const CommentContentText = styled(Typography)`
  font-size: 16px;
  line-height: 22px;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const CommentFooter = styled(Box)`
  margin-top: 12px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
  }
`

export const FooterLinks = styled(Box)`
  display: flex;
  gap: 24px;
`

export const Link = styled(Typography)<{ $isActive?: boolean }>`
  cursor: pointer;
  color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#C4C4C4")};
  font-family: Public-Sans-Medium;

  &:hover {
    color: #2d2d2d;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const CommentReplyArea = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`

export const ReplyMessageBox = styled(Box)`
  background-color: #f7f7f7;
  display: flex;
  padding: 10px 20px;
  width: 100%;
  flex-flow: column;
  gap: 8px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 10px;
  }
`

export const ReplyInputBox = styled(Box)`
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  gap: 16px;
`

export const StyledInput = styled.input`
  width: 100%;
  color: #434343;
  font-size: 14px;
  border: 0;
  background-color: #f7f7f7;

  &:focus {
    border: 0;
    outline: none;
  }
`

export const PostLink = styled(Typography)`
  color: rgba(48, 87, 225, 0.5);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3057e1;
  }
`
