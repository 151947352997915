import React from "react"
import { CircularProgress } from "@material-ui/core"
import { colors } from "../../theme"
import { ConnectButton } from "./Styles"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

export type Props = {
  isLoading: boolean
  onConfirm: () => void
  onDeny: () => void
  onUndo: () => void
  type: string
}

const View = ({ onConfirm, onDeny, onUndo, isLoading, type }: Props) => {
  const { width } = useWindowDimensions()

  let Button
  if (type === "confirm") {
    Button = (
      <ConnectButton
        style={{
          marginRight: width >= theme.breakpoints.values.sm ? "8px" : "0",
        }}
        size="medium"
        color="primary"
        data-testid="pending-button"
        type="button"
        disabled={isLoading}
        onClick={onConfirm}
      >
        {isLoading ? (
          <CircularProgress size={16} style={{ color: colors.white }} />
        ) : (
          <>Confirm</>
        )}
      </ConnectButton>
    )
  } else if (type === "deny") {
    Button = (
      <ConnectButton
        style={{
          background: "#F7F7F7",
          color: "#2D2D2D",
          marginTop: width >= theme.breakpoints.values.sm ? "0" : "6px",
        }}
        size="medium"
        color="primary"
        data-testid="pending-button"
        type="button"
        disabled={isLoading}
        onClick={onDeny}
      >
        {isLoading ? (
          <CircularProgress size={16} style={{ color: colors.white }} />
        ) : (
          <>Delete</>
        )}
      </ConnectButton>
    )
  } else if (type === "undo") {
    Button = (
      <ConnectButton
        style={{
          background: "#F7F7F7",
          color: "#2D2D2D",
        }}
        size="medium"
        color="primary"
        data-testid="pending-button"
        type="button"
        disabled={isLoading}
        onClick={onUndo}
      >
        {isLoading ? (
          <CircularProgress size={16} style={{ color: colors.white }} />
        ) : (
          <>Undo</>
        )}
      </ConnectButton>
    )
  }

  return <>{Button}</>
}

export default View
