import React from "react"

import { Box } from "@material-ui/core"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
} from "./styles"
import { Competition } from "../../redux/competition"

type Props = {
  competition?: Competition
}

const Header = ({ competition }: Props) => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <Box>
            <StyledLogo src={competition?.logoUrl} alt={"Logo"} />
          </Box>
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
