import React from "react"
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone"

import { DropzoneProps } from "."
import { CompactDropzoneContainer, IconOverlay, IconContainer } from "./styles"

import { ReactComponent as AddIcon } from "../../images/icon-add.svg"

interface Props extends DropzoneProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
}

const View: React.FC<Props> = ({
  isDragAccept,
  isDragActive,
  isDragReject,
  getRootProps,
  getInputProps,
}) => {
  return (
    <>
      <CompactDropzoneContainer
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        <IconOverlay>
          <IconContainer>
            <AddIcon
              style={{ width: "32px", height: "32px", fill: "lightgrey" }}
            />
          </IconContainer>
        </IconOverlay>

        <input {...getInputProps()} />
      </CompactDropzoneContainer>
    </>
  )
}

export default View
