import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type Education = {
  id?: string
  school: string
  degree: string
  field: string
  startYear: number
  endYear?: number
  current: boolean
  additionalInfo?: string
  additionalInfoRTF?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  educations: Education[]
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type CreateEducationPayload = {
  auth: Auth0ContextInterface
  education: Education
}
export const createEducation = createAsyncThunk<any, CreateEducationPayload>(
  "experience/educations/createEducation",
  async ({ auth, education }) => {
    const body = { ...education, origin: window.location.origin }

    return useApi(auth, "/educations", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type UpdateEducationPayload = {
  auth: Auth0ContextInterface
  education: Education
}
export const updateEducation = createAsyncThunk<any, UpdateEducationPayload>(
  "experience/educations/updateEducation",
  async ({ auth, education }) => {
    return useApi(auth, `/educations/${education.id}`, {
      method: "PATCH",
      // Replace `undefined` to `null` because `undefined` values are removed by JSON.stringify
      body: JSON.stringify(education, (_k, v) => (v === undefined ? null : v)),
    }).then((res) => res.text())
  }
)

type DeleteEducationPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteEducation = createAsyncThunk<any, DeleteEducationPayload>(
  "experience/educations/deleteEducation",
  async ({ auth, id }) => {
    return useApi(auth, `/educations/${id}`, {
      method: "DELETE",
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  educations: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "educations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEducation.fulfilled, (state, action) => {
        state.educations = [action.payload, ...state.educations]
      })
      .addCase(createEducation.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(updateEducation.fulfilled, (state, _action) => {
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(deleteEducation.fulfilled, (state, _action) => {
        state.status = LoadingStatuses.Succeeded
      })
  },
})
//#endregion

//#region selectors
//#endregion
