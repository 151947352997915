import React, { useState } from "react"
import View from "./View"
import * as RouteConstants from "../../constants/routes"
import { normalizeProjectTitle } from "../../utils"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import {
  CollaboratorManagement,
  updateCollaboratorsState,
} from "../../redux/connections"
import { confirmCollaborator, rejectCollaborator } from "../../redux/projects"
import { useSelector } from "react-redux"
import { updateCollaboratorsProfileState } from "../../redux/profile"

export type Props = {
  collaborator: CollaboratorManagement
}

const CollaboratorListCard = ({ collaborator }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const [isLoading, setIsLoading] = useState(false)

  const handleRemove = async (collaboratorId: string) => {
    try {
      setIsLoading(true)
      const { type } = await dispatch(
        rejectCollaborator({ auth, id: collaboratorId })
      )

      if (rejectCollaborator.fulfilled.type === type) {
        setIsLoading(false)

        await dispatch(
          updateCollaboratorsState({ collaboratorId, action: "remove" })
        )

        await dispatch(
          updateCollaboratorsProfileState({ collaboratorId, action: "remove" })
        )

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleConfirm = async (collaboratorId: string) => {
    try {
      setIsLoading(true)
      const { type } = await dispatch(
        confirmCollaborator({ auth, id: collaboratorId })
      )

      if (confirmCollaborator.fulfilled.type === type) {
        setIsLoading(false)

        await dispatch(
          updateCollaboratorsState({ collaboratorId, action: "confirm" })
        )

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const goToProject = (collaboratorData: CollaboratorManagement) => {
    return `${RouteConstants.PROFILE_CONTAINER}/${
      collaboratorData?.username
    }/projects/${collaboratorData?.projectId}/${normalizeProjectTitle(
      collaboratorData?.projectName!
    )}`
  }

  const goToProfile = () => {
    return authenticatedProfile?.connectionId === collaborator.profileId
      ? "#"
      : `${RouteConstants.PROFILE_CONTAINER}/${collaborator.username}`
  }

  return (
    <View
      collaborator={collaborator}
      goToProject={goToProject}
      goToProfile={goToProfile}
      isLoading={isLoading}
      onConfirm={handleConfirm}
      onRemove={handleRemove}
    />
  )
}

export default CollaboratorListCard
