import React from "react"
import { NavLink as RouterLink } from "react-router-dom"
import { Box } from "@material-ui/core"
import {
  NavbarContainer,
  Logo,
  LogoContainer,
  Actions,
  NavBarButton,
  StyledLink,
  LinksContainer,
  LogoText,
} from "./styles"
import LogoImage from "../../images/logo-charcoal.svg"

export type Props = {
  goToHome: () => void
}

const View = ({ goToHome }: Props) => {
  return (
    <NavbarContainer aria-label="navigation bar">
      <LogoContainer>
        <RouterLink to="/">
          <Logo
            src={LogoImage}
            alt="Spectacular"
            aria-label="specatular logo"
          />
        </RouterLink>
        <LogoText>Admin</LogoText>
      </LogoContainer>
      <LinksContainer maxWidth="lg">
        <Box display="flex" flexDirection="row">
          <StyledLink
            component={RouterLink}
            to="/admin/projects"
            aria-label="Projects"
            activeClassName="link-active"
            exact={true}
          >
            Projects
          </StyledLink>
          <StyledLink
            component={RouterLink}
            to="/admin/profiles"
            aria-label="Profiles"
            activeClassName="link-active"
            exact={true}
          >
            Profiles
          </StyledLink>
          <StyledLink
            component={RouterLink}
            to="/admin/posts"
            aria-label="Flagged Posts"
            activeClassName="link-active"
            exact={true}
          >
            Flagged Posts
          </StyledLink>
          <StyledLink
            component={RouterLink}
            to="/admin/flagged-projects"
            aria-label="Flagged Projects"
            activeClassName="link-active"
            exact={true}
          >
            Flagged Projects
          </StyledLink>
          <StyledLink
            component={RouterLink}
            to="/admin/news"
            aria-label="News"
            activeClassName="link-active"
            exact={true}
          >
            News
          </StyledLink>
        </Box>
      </LinksContainer>
      <Actions>
        <NavBarButton
          aria-label="add new project"
          color="primary"
          data-testid="add-project-button"
          onClick={goToHome}
        >
          Back to Home
        </NavBarButton>
      </Actions>
    </NavbarContainer>
  )
}

export default View
