import React from "react"
import { Profile } from "../../redux/profile"
import {
  Collaborator as ProjectCollaborator,
  Project,
} from "../../redux/projects"
import * as RouteConstants from "../../constants/routes"
import {
  ButtonsBox,
  CollaboratorAvatar,
  CollaboratorBox,
  CollaboratorLocation,
  CollaboratorName,
  ListButtonBox,
  NameBox,
  PendingText,
} from "./styles"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import ConnectButton from "../../components/ConnectButton"
import FollowButton from "../../components/FollowButton"
import ConfirmConnectionDialog from "../../components/ConfirmConnectionDialog"
import CollaboratorDotMenu from "../../components/CollaboratorDotMenu"
import { Box } from "@material-ui/core"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

interface Props {
  collaborator?: ProjectCollaborator
  profile?: Profile
  showButtons?: boolean
  authenticatedProfile?: Profile
  isProjectOwner: boolean
  project?: Project
}

export const Collaborator = ({
  collaborator,
  profile,
  showButtons,
  isProjectOwner,
  authenticatedProfile,
  project,
}: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const displayProfile = collaborator ? collaborator.profile : profile
  const company = collaborator ? collaborator.company : project?.firmName
  const discipline = collaborator ? collaborator.discipline : project?.role

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${displayProfile!.username}`
  }

  let name = displayProfile?.groupName
  if (displayProfile?.type !== "group")
    name = displayProfile?.firstName + " " + displayProfile?.lastName

  const isProfileOwner =
    authenticatedProfile?.connectionId === displayProfile?.connectionId

  const isPendingInvite =
    isProjectOwner &&
    authenticatedProfile?.connectionId === collaborator?.profileId

  if (isPendingInvite) name = collaborator?.invite?.email

  return (
    <>
      <CollaboratorBox>
        <LinkBox to={isPendingInvite ? "#" : goToProfile()}>
          <CollaboratorAvatar
            src={
              isPendingInvite
                ? getRandomPlaceholder(collaborator?.placeholderAvatar!)
                : displayProfile?.smallImageUrl ||
                  getRandomPlaceholder(displayProfile?.placeholderAvatar!)
            }
          />
        </LinkBox>
        <NameBox>
          <LinkBox to={isPendingInvite ? "#" : goToProfile()}>
            <CollaboratorName>
              {name}
              {collaborator?.profile?.isAmbassador && (
                <img
                  src={AmbassadorIcon}
                  alt="Student Ambassador"
                  className="StudentAmbassadorIcon"
                />
              )}
            </CollaboratorName>
          </LinkBox>
          {company && <CollaboratorLocation>{company}</CollaboratorLocation>}
          {discipline && (
            <CollaboratorLocation>{discipline}</CollaboratorLocation>
          )}
          {collaborator?.status === "pending" &&
            (isProjectOwner || isProfileOwner) && (
              <PendingText>
                <span>•</span> Pending
              </PendingText>
            )}
        </NameBox>
        <Box style={{ width: "100%" }}>
          <Box
            style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
          >
            {!showButtons && !isProfileOwner && (
              <ListButtonBox>
                <FollowButton
                  profile={displayProfile!}
                  size={"large"}
                  color={"light-blue"}
                />
              </ListButtonBox>
            )}
            {isProfileOwner &&
              !isProjectOwner &&
              collaborator &&
              collaborator?.status === "pending" && (
                <>
                  <CollaboratorDotMenu collaborator={collaborator} />
                </>
              )}

            {isProjectOwner && collaborator && (
              <>
                <CollaboratorDotMenu
                  collaborator={collaborator}
                  remove={true}
                />
              </>
            )}
          </Box>
        </Box>
      </CollaboratorBox>
      {showButtons && !isProjectOwner && (
        <>
          <ButtonsBox>
            {displayProfile?.type !== "group" && (
              <ConnectButton
                profile={displayProfile!}
                action={"dialog"}
                handleOpenDialog={handleOpen}
              />
            )}
            <FollowButton
              profile={displayProfile!}
              size={"large"}
              color={displayProfile?.type === "group" ? "" : "light-blue"}
            />
          </ButtonsBox>

          <ConfirmConnectionDialog
            open={open}
            onClose={handleClose}
            profile={displayProfile!}
          />
        </>
      )}
    </>
  )
}
