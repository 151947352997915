import styled from "styled-components"
import { Box, Button } from "@material-ui/core"

import theme from "../../theme"

export const PopUpButton = styled(Button)`
  max-height: 40px;
  width: 100%;
  padding: 8px 22px;
  border-radius: 1px;
  font-family: "Public-Sans-Bold";
  font-weight: 500 !important;

  && {
    font-weight: 500 !important;

    MuiButton-label {
      font-weight: 500 !important;
    }
  }
`

export const PopUp = styled(Box)`
  width: 400px;
  background: #fff;
  box-shadow: 0px 10px 40px rgba(113, 113, 113, 0.15);
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999999999999;
  transition: all 0.4s;
  border-radius: 1px;

  ${theme.breakpoints.down(550)} {
    right: 0;
    margin: auto;
    left: 0;
    bottom: 40px;
    width: 90%;
  }
`

export const PopUpTitle = styled(Box)`
  font-size: 20px;
  align-content: center;
  display: flex;
  flex-flow: wrap;
  color: #2d2d2d;
`

export const PopUpHeader = styled(Box)`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 24px;
  justify-content: space-between;
`

export const PopUpCloseIcon = styled.img`
  cursor: pointer;
`
