import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import {
  fetchProjects,
  selectAllProjects,
  SortType,
  SortProperty,
} from "../../../redux/adminProjects"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

const AdminProjectsPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const projects = useSelector(selectAllProjects) || []
  const [sort, setSort] = useState<SortType>({
    property: undefined,
    direction: "asc",
  })

  const handleSortChanged = (property: SortProperty) => {
    const isAsc = sort.property === property && sort.direction === "asc"
    setSort({ property, direction: isAsc ? "desc" : "asc" })
  }

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchProjects({ auth, sort }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth, sort])

  return (
    <View projects={projects} onSortChanged={handleSortChanged} sort={sort} />
  )
}

export default AdminProjectsPage
