import React from "react"
import View from "./View"

type Props = {
  onClose: () => void
  open: boolean
  activeSearch: string
  onChangeActiveSearch: (value: string, reset: boolean) => void
  typologyFilter?: string[]
  toolsFilter?: string[]
  keyMaterialsFilter?: string[]
  myNetworkFilter: boolean
  profileTypeFilter?: string[]
  onUpdateTypologyFilter: (value: string) => void
  onUpdateToolsFilter: (value: string) => void
  onUpdateKeyMaterialsFilter: (value: string) => void
  onUpdateNetworkFilter: (value: boolean) => void
  onUpdateProfileTypeFilter: (value: string) => void
}

const FilterHomepageDialog = ({
  open,
  onClose,
  activeSearch,
  onChangeActiveSearch,
  typologyFilter,
  toolsFilter,
  keyMaterialsFilter,
  myNetworkFilter,
  profileTypeFilter,
  onUpdateTypologyFilter,
  onUpdateToolsFilter,
  onUpdateKeyMaterialsFilter,
  onUpdateNetworkFilter,
  onUpdateProfileTypeFilter,
}: Props) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <View
      onClose={handleClose}
      open={open}
      activeSearch={activeSearch}
      onChangeActiveSearch={onChangeActiveSearch}
      typologyFilter={typologyFilter}
      toolsFilter={toolsFilter}
      keyMaterialsFilter={keyMaterialsFilter}
      myNetworkFilter={myNetworkFilter}
      profileTypeFilter={profileTypeFilter}
      onUpdateTypologyFilter={onUpdateTypologyFilter}
      onUpdateToolsFilter={onUpdateToolsFilter}
      onUpdateKeyMaterialsFilter={onUpdateKeyMaterialsFilter}
      onUpdateNetworkFilter={onUpdateNetworkFilter}
      onUpdateProfileTypeFilter={onUpdateProfileTypeFilter}
    />
  )
}

export default FilterHomepageDialog
