import styled from "styled-components"
import { Box } from "@material-ui/core"
import theme from "../../theme"

export const FormFlexBox = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;

  && .MuiFormControl-marginNormal {
    margin-top: 8px;
  }

  && .MuiFilledInput-input {
    padding: 19px 12px 10px;
  }

  && .MuiFormHelperText-contained {
    margin-left: 0;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`

export const FormFlexBoxItem = styled(Box)`
  width: 100%;
`
