import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Box, Chip, FormHelperText, Button } from "@material-ui/core"

export type Props = {
  value: string
  tags: string[]
  suggestions: string[]
  onBlur: () => void
  onFocus: () => void
  displayError: boolean
  onInputChange: (value: string) => void
  onEnteredPressed: (jobTag: string) => void
  onDeletedPressed: (jobTag: string) => void
}

const View = ({
  tags,
  value,
  onBlur,
  onFocus,
  suggestions,
  displayError,
  onInputChange,
  onEnteredPressed,
  onDeletedPressed,
}: Props) => {
  const onKeyPress = (event: any) => {
    if (event.key === "Enter" && value.trim().length > 0)
      onEnteredPressed(value)
  }

  const onAddPress = (event: any) => {
    if (value.trim().length > 0) {
      event.preventDefault()
      onEnteredPressed(value)
    }
  }

  const handleDelete = (tagToDelete: string) => () =>
    onDeletedPressed(tagToDelete)

  return (
    <>
      <Box marginBottom={1}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value}
          id="job-tags-autocomplete"
          options={suggestions}
          onInputChange={(_, newValue) => onInputChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              placeholder="e.g: Some sort of tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Button
                    size="small"
                    color="primary"
                    onClick={onAddPress}
                    style={{ marginBottom: "0.4rem" }}
                  >
                    Add tag
                  </Button>
                ),
              }}
            />
          )}
        />

        <FormHelperText
          error={false}
          style={{ marginTop: "8px", lineHeight: "11px" }}
        >
          Add tags by pressing enter...
        </FormHelperText>
        {displayError && (
          <Box marginBottom={2}>
            <FormHelperText error={true}>
              There was an error saving the job tag. Please try again!
            </FormHelperText>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        {tags.map((tag: string, index: number) => (
          <Box key={index} marginRight={1} marginTop={1} display="flex">
            <Chip
              label={tag}
              onDelete={handleDelete(tag)}
              color="primary"
              data-testid="job-tag-item"
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default View
