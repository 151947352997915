import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type Spotlight = {
  id?: string
  profileId?: string
  description?: string
  startDate: string
  endDate: string
  createdAt?: string
}
//#endregion

//#region api
type CreateSpotlightPayload = {
  auth: Auth0ContextInterface
  spotlight: Spotlight
}
export const createSpotlight = createAsyncThunk<any, CreateSpotlightPayload>(
  "admin/spotlight/create",
  async ({ auth, spotlight }) => {
    const { id, ...spotlightData } = spotlight
    return useApi(auth, "/admin/spotlight", {
      method: "POST",
      body: JSON.stringify(spotlightData),
    }).then((res) => res.json())
  }
)

type UpdateSpotlightPayload = {
  auth: Auth0ContextInterface
  spotlight: Spotlight
}
export const updateSpotlight = createAsyncThunk<any, UpdateSpotlightPayload>(
  "admin/spotlight/update",
  async ({ auth, spotlight }) => {
    return useApi(auth, `/admin/spotlight/${spotlight.id}`, {
      method: "PATCH",
      body: JSON.stringify(spotlight),
    }).then((res) => res.json())
  }
)

type DeleteSpotlightPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteSpotlight = createAsyncThunk<any, DeleteSpotlightPayload>(
  "admin/spotlight/delete",
  async ({ auth, id }) => {
    return useApi(auth, `/admin/spotlight/${id}`, {
      method: "DELETE",
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
//#endregion

//#region selectors
//#endregion
