const styles = {
  width: "100%",
  control: {
    fontSize: 16,
    fontWeight: "normal",
    backgroundColor: "#fff",
    border: 0,
    width: "100%",
  },

  "&multiLine": {
    control: {
      minHeight: 50,
      overflow: "auto",
      boxSizing: "border-box",
      padding: "5px 2px",
      width: "100%",
      backgroundColor: "transparent",
    },
    highlighter: {
      lineHeight: "24px",
      fontFamily: "Public-Sans",
      border: "1px solid transparent",
    },
    input: {
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    backgroundColor: "#F7F7F7",
    display: "inline-block",

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    zIndex: 8,
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "2px",
    marginTop: "30px",
    list: {
      backgroundColor: "white",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "2px",
    },
    item: {
      padding: "8px 16px",
      "&focused": {
        backgroundColor: "#F5F5F5",
      },
    },
  },
}

export default styles
