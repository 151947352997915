import React from "react"
import { Helmet } from "react-helmet"

import View from "./View"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const AboutPage = () => {
  setTimeout(() => {
    // @ts-ignore
    window.prerenderReady = true
  }, 0)

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Nominations`}
        classTitle="Nominations"
      />
      <Helmet>
        <title>Nominations - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Nominations - Spectacular`} />
        <meta name="title" content={`Nominations - Spectacular`} />
      </Helmet>
      <View />
    </>
  )
}

export default AboutPage
