import React from "react"
import { Profile } from "../../redux/profile"

import View from "./View"

type Props = {
  profile: Profile
  onClose: () => void
  open: boolean
}

const ConfirmConnectionDialog = ({ profile, open, onClose }: Props) => {
  const handleClose = () => {
    onClose()
  }

  return <View profile={profile} onClose={handleClose} open={open} />
}

export default ConfirmConnectionDialog
