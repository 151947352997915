import React from "react"
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core"
import styled from "styled-components"
import { FieldAttributes, useField } from "formik"

import { ReactComponent } from "../../../images/chevron-down.svg"
import { useWindowDimensions } from "../../../hooks"

const DropdownIcon = styled(ReactComponent)`
  margin-top: 7px;
  margin-right: 5px;
`

const StyledControl = styled(FormControl)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  && {
    .MuiInputLabel-filled {
      transform: translate(12px, 15px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.75);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }
  }
`

export type SelectOption = {
  name: string | number
  value: string | number
}

export type Props = {
  mobile?: any
  style?: React.CSSProperties
  blank?: string | undefined
  options: Array<SelectOption>
  onChange?: (value: string) => void
}

const SelectField = (props: Props & FieldAttributes<any>) => {
  const { width } = useWindowDimensions()
  const [field, meta, helpers] = useField(props)
  let hasError = !!(meta.touched && (meta.error || meta.value === ""))

  if (props.disabled) hasError = false

  const { setValue } = helpers
  const labelId = `${props.name}-label`

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    setValue(event.target.value)

    if (props.onChange) props.onChange(event.target.value)
  }

  return (
    <div
      style={
        width < 960
          ? { paddingBottom: hasError ? "24px" : "16px" }
          : { paddingBottom: "24px" }
      }
    >
      <StyledControl error={hasError} {...props}>
        <InputLabel
          id={labelId}
          style={
            props.mobile
              ? { color: "#979797", fontSize: "14px" }
              : { color: "#979797" }
          }
        >
          {props.label}
        </InputLabel>
        <Select
          IconComponent={DropdownIcon}
          labelId={labelId}
          id={props.name}
          value={field.value || ""}
          onChange={handleChange}
          style={props.style ? { ...props.style } : {}}
        >
          {props.blank && (
            <MenuItem value="">
              <em>{props.blank}</em>
            </MenuItem>
          )}

          {props.options.map(({ value, name }: SelectOption) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>

        {hasError && (
          <FormHelperText style={{ paddingBottom: "4px" }}>
            {meta.error}
          </FormHelperText>
        )}
      </StyledControl>
    </div>
  )
}

export default SelectField
