import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ProjectCard from "../../cards/ProjectCard"
import { useAppDispatch } from "../../redux/configureStore"
import {
  PlaceholderProjectCard,
  ProjectsListBox,
  ProjectsListCardBox,
  ProjectsListContainer,
  ShowMoreBorder,
  ShowMoreBox,
  ShowMoreButton,
  ShowMoreLabel,
} from "./styles"
import { Feed, getFeed } from "../../redux/feed"
import PostCard from "../../cards/PostCard"
import ShowMoreIcon from "../../images/new-homepage/icons/show-more.svg"
import LoadingPage from "../LoadingPage"
import SuggestionsCard from "../../cards/SuggestionsCard"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import DiscoverProjectsPopUp from "../../components/DiscoverProjectsPopUp"
import InfiniteScroll from "react-infinite-scroll-component"
import JobSuggestionsCard from "../../cards/JobSuggestionsCard"
import { useHistory } from "react-router-dom"
import NewsCard from "../../cards/NewsCard"
import { logEvent } from "../../utils/analytics"

interface Props {
  email?: string
}

const HomeFeed = ({ email }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { isAuthenticated } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const homeFeed = useSelector((state: any) => state.feed.feed) as Feed[]
  const idHash = useSelector((state: any) => state.feed.hash) as string

  const checkHasMore = (payload: Feed[]) => {
    const checkPayload = payload.filter((item) => {
      return item.type !== "connections" && item.type !== "jobs"
    })

    if (checkPayload.length === 0) return true

    return false
  }

  const loadFeed = useCallback(
    async (hash: string, pagination: boolean, action?: string) => {
      try {
        if (
          history.action !== "POP" ||
          action === "FETCH" ||
          homeFeed.length === 0
        ) {
          setIsLoading(true)
          const { payload, type } = await dispatch(
            getFeed({ email, idHash: hash, pagination })
          )

          if (getFeed.fulfilled.type === type) {
            setIsLoading(false)

            if (checkHasMore(payload.feed)) setHasMore(false)

            setTimeout(() => {
              // @ts-ignore
              window.prerenderReady = true
            }, 0)
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [dispatch, email]
  )

  const fetchMoreData = async () => {
    await loadFeed(idHash, true, "FETCH")
    await logEvent("home_feed_scroll")
  }

  useEffect(() => {
    loadFeed(idHash, false)
    // eslint-disable-next-line
  }, [loadFeed])

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  return (
    <ProjectsListContainer>
      <ProjectsListBox>
        <InfiniteScroll
          dataLength={homeFeed.length}
          next={fetchMoreData}
          hasMore={isAuthenticated ? hasMore : false}
          loader={<LoadingPage />}
          style={{ overflow: "hidden" }}
          scrollThreshold={0.8}
        >
          <ProjectsListCardBox>
            {homeFeed.map((feed, key) => {
              return (
                <React.Fragment key={`feed-${key}`}>
                  {feed.type === "project" && (
                    <ProjectCard project={feed.project!} source="homepage" />
                  )}
                  {feed.type === "post" && (
                    <PostCard post={feed.post!} isHomepage={true} />
                  )}
                  {feed.type === "ads" && (
                    <PostCard
                      post={feed.post!}
                      isHomepage={true}
                      isAds={true}
                    />
                  )}
                  {feed.type === "connections" && (
                    <SuggestionsCard profiles={feed.connections!} />
                  )}
                  {feed.type === "jobs" && (
                    <JobSuggestionsCard jobs={feed.jobs!} />
                  )}
                  {feed.type === "news" && <NewsCard news={feed.news!} />}
                </React.Fragment>
              )
            })}
            {!isEven(homeFeed.length) && <PlaceholderProjectCard />}
          </ProjectsListCardBox>
        </InfiniteScroll>

        {!isAuthenticated && (
          <ShowMoreBox>
            <ShowMoreBorder />
            <ShowMoreButton
              onClick={async () => {
                await dispatch(toggleDialog({ opened: true }))
              }}
            >
              <img src={ShowMoreIcon} alt="Show More" />
            </ShowMoreButton>
            <ShowMoreLabel
              onClick={async () => {
                await dispatch(toggleDialog({ opened: true }))
              }}
            >
              Show more
            </ShowMoreLabel>
          </ShowMoreBox>
        )}
        {!hasMore && !isLoading && <DiscoverProjectsPopUp isOpen={true} />}
      </ProjectsListBox>
    </ProjectsListContainer>
  )
}

export default HomeFeed
