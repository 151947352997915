import React from "react"
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useParams } from "react-router-dom"
import { Formik, Form, FormikHelpers } from "formik"
import { Visibility, VisibilityOff } from "@material-ui/icons"

import { StyledLink } from "./Styles"

import TextField from "./TextField"
import { RegisterFormSchema } from "./ValidationSchema"
import SelectField, { SelectOption } from "./SelectField"

import theme from "../../theme"
import { Checkbox } from "../../components"
import { useWindowDimensions } from "../../hooks"
import { ProfileType } from "../../redux/profile"
import { useSelector } from "react-redux"
import { useIntercom } from "react-use-intercom"

const options: SelectOption[] = [
  { value: ProfileType.GROUP, name: "Group" },
  { value: ProfileType.INDIVIDUAL, name: "Individual" },
]

interface FormValues {
  type: string
  email: string
  password: string
  lastName: string
  firstName: string
  groupName: string
  referralCode?: string
  origin?: string
  termAcceptance: boolean
}

export type SubmitHandler = (
  values: FormValues,
  formikHelpers: FormikHelpers<FormValues>
) => void | Promise<any>

export const RegisterForm: React.FC<{
  mobile?: any
  onSubmit: SubmitHandler
  showSSOText: boolean
}> = ({ mobile, onSubmit, showSSOText }) => {
  const { width } = useWindowDimensions()
  const { type } = useParams<{ type: string }>()
  const [toggle, setToggle] = React.useState(false)
  const error = useSelector((state: any) => state.profile.createProfileError)

  const initialValues = {
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    groupName: "",
    termAcceptance: false,
    type: type === "firm" ? ProfileType.GROUP : "",
  }

  const { show } = useIntercom()

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={RegisterFormSchema}
      >
        {(formik) => (
          <Form style={{ maxWidth: "400px" }}>
            {error && (
              <div style={{ paddingBottom: "24px" }}>
                <Alert severity="error">{error}</Alert>
              </div>
            )}

            {showSSOText && (
              <div style={{ paddingBottom: "24px" }}>
                <Alert severity="error">
                  Login with your Black Spectacles email & password to claim
                  your Spectacular Profile! <br />
                  <span
                    onClick={show}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    Click here for further assistance.
                  </span>
                </Alert>
              </div>
            )}

            <SelectField
              name="type"
              mobile={mobile}
              options={options}
              blank="Choose an option"
              label="Individual or Group"
              style={{
                fontFamily: "White",
                fontSize: "14px",
                maxHeight: "48px",
              }}
              onFocus={() => formik.setFieldTouched("type", true)}
            />

            {formik.values.type === "" ||
            formik.values.type === ProfileType.INDIVIDUAL ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={width <= 1280 ? {} : { paddingRight: "16px" }}
                >
                  <TextField
                    compact
                    fullWidth
                    mobile={mobile}
                    name="firstName"
                    label="First Name"
                    style={{ fontFamily: "White", fontSize: "14px" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    compact
                    fullWidth
                    mobile={mobile}
                    name="lastName"
                    label="Last Name"
                    style={{ fontFamily: "White", fontSize: "14px" }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {formik.values.type === ProfileType.GROUP ? (
              <TextField
                compact
                fullWidth
                mobile={mobile}
                name="groupName"
                label="Group Name"
                style={{ fontFamily: "White", fontSize: "14px" }}
              />
            ) : null}

            <TextField
              compact
              fullWidth
              name="email"
              label="Email"
              mobile={mobile}
              autoComplete="email"
              style={{ fontFamily: "White", fontSize: "14px" }}
            />

            <TextField
              compact
              fullWidth
              mobile={mobile}
              name="password"
              label="Password"
              autoComplete="current-password"
              type={toggle ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setToggle(!toggle)}
                      aria-label="toggle password visibility"
                    >
                      {!toggle ? (
                        <Visibility style={{ color: "#000000" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#000000" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Grid
              style={{
                marginTop: width >= theme.breakpoints.values.sm ? "-4px" : "0",
              }}
            >
              <Checkbox
                checked={formik.values.termAcceptance}
                onChange={() =>
                  formik.setFieldValue(
                    "termAcceptance",
                    !formik.values.termAcceptance
                  )
                }
                label={
                  <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
                    By clicking 'Create an account', you agree to the
                    Spectacular{" "}
                    <StyledLink href="/terms_use" target="_blank">
                      User Agreement
                    </StyledLink>{" "}
                    and{" "}
                    <StyledLink href="/privacy_policy" target="_blank">
                      Privacy Policy
                    </StyledLink>
                    .
                  </Typography>
                }
              />
            </Grid>

            <Box style={{ paddingTop: "24px" }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{
                      float: "left",
                      maxHeight: "48px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      width: mobile ? "100%" : "160px",
                      maxWidth: mobile ? "100%" : "160px",
                      border:
                        formik.isSubmitting || !formik.isValid || !formik.dirty
                          ? "1px solid rgba(0, 0, 0, 0)"
                          : "1px solid #2d2d2d",
                    }}
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.dirty
                    }
                  >
                    Create an account
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}
