import React from "react"
import { Switch } from "react-router-dom"
import ProtectedRoute from "../components/ProtectedRoute"
import AdminNavBar from "../components/AdminNavBar"
import AdminDashboard from "../pages/Admin/Dashboard"
import AdminProjectsPage from "../pages/Admin/ProjectsPage"
import AdminProjectEditPage from "../pages/Admin/ProjectEditPage"
import AdminProfilesPage from "../pages/Admin/ProfilesPage"
import AdminProfileNewPage from "../pages/Admin/ProfileNewPage"
import AdminProfileEditPage from "../pages/Admin/ProfileEditPage"
import AdminPostsPage from "../pages/Admin/PostsPage"
import AdminFlaggedProjectsPage from "../pages/Admin/FlaggedProjectsPage"
import AdminNewsPage from "../pages/Admin/NewsPage"

export const AdminRoutes = () => (
  <>
    <AdminNavBar />
    <div className="main-content">
      <Switch>
        <ProtectedRoute
          exact
          path="/admin/profiles/new"
          component={AdminProfileNewPage}
          admin
        />
        <ProtectedRoute
          exact
          path="/admin/profiles"
          component={AdminProfilesPage}
          admin
        />
        <ProtectedRoute
          path="/admin/profiles/:username/edit"
          component={AdminProfileEditPage}
          admin
        />
        <ProtectedRoute
          path="/admin/projects/:id/edit"
          component={AdminProjectEditPage}
          admin
        />
        <ProtectedRoute
          exact
          path="/admin/projects"
          component={AdminProjectsPage}
          admin
        />
        <ProtectedRoute
          exact
          path="/admin/posts"
          component={AdminPostsPage}
          admin
        />
        <ProtectedRoute
          exact
          path="/admin/flagged-projects"
          component={AdminFlaggedProjectsPage}
          admin
        />
        <ProtectedRoute
          exact
          path="/admin/news"
          component={AdminNewsPage}
          admin
        />
        <ProtectedRoute exact path="/admin" component={AdminDashboard} admin />
      </Switch>
    </div>
  </>
)

export default AdminRoutes
