import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const ActionButton = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Counter = styled(Typography)<{ $isActive: boolean }>`
  color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#717171")};

  ${theme.breakpoints.down("xs")} {
    font-size: 12px;
  }
`

export const Icon = styled.img`
  height: 26px;

  ${theme.breakpoints.down("xs")} {
    height: 22px;
  }
`
