import React from "react"
import styled from "styled-components"
import { TextField, TextFieldProps, Typography } from "@material-ui/core"
import theme from "../../../theme"

import "./edge-clearfix.css"

const Title = styled(Typography)`
  font-size: 16px;

  && span {
    color: #ff0000;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

const Field = styled(TextField)`
  margin-top: 7px !important;
  margin-bottom: 0px !important;

  && {
    .MuiFilledInput-input {
      padding: 1px 12px !important;
    }

    .MuiInputLabel-filled {
      transform: translate(12px, 14px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.7);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }
  }
`

export type Props = TextFieldProps & {
  errorMessage?: string | null
  inputTestId?: string
  displayTitle?: string
}

const View = ({ displayTitle, inputTestId, errorMessage, ...props }: Props) => {
  return (
    <>
      {displayTitle && (
        <Title>
          {displayTitle} <span>*</span>
        </Title>
      )}
      <Field
        {...props}
        error={!!errorMessage}
        style={!!errorMessage ? { marginBottom: "0" } : {}}
        helperText={errorMessage || ""}
        InputLabelProps={{
          style: {
            color: "#717171",
            fontSize: "16px",
            marginTop: "3px",
          },
        }}
        InputProps={{
          style: props.style,
          ...props.InputProps,
        }}
      />
    </>
  )
}

export default View
