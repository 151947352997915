import React from "react"
import styled from "styled-components"
import { Button, Box, Link, Typography } from "@material-ui/core"
import { Form } from "formik"

import theme from "../../theme"

export const ActionButton = styled(Button)`
  max-height: 48px;
  max-width: 140px;
  padding: 8px 22px;
  border-radius: 1px;
  font-family: "Public-Sans";
  font-weight: 400 !important;

  && {
    font-weight: 400 !important;

    MuiButton-label {
      font-weight: 400 !important;
    }
  }
`

export const RichTextEditorContainer = styled(Box)<{ error?: any }>`
  min-height: 248px;
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;
  margin-top: 16px;
  word-break: break-word;
  && {
    #mui-rte-editor {
      padding: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    min-height: 200px;
    && {
      #mui-rte-container {
        margin: 0;
      }

      #mui-rte-toolbar .MuiIconButton-root {
        padding: 8px;
      }
    }
  }
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #717171;
`

export const StyledLinkBox = styled(Box)`
  display: inline;
  font-family: Public-Sans;
  a {
    border-bottom: solid 0.5px #717171;
  }
`

export const Alert = styled.img`
  position: absolute;
  margin-left: -36px;
  margin-top: 35px;
`

export const FormStyled = styled(Form)`
  max-width: 475px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  padding: 48px 40px;
  border-radius: 3px;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 20px 32px;
  }
`

export const FormStyled2 = styled(Form)`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  gap: 40px;

  @media only screen and (min-width: 1500px) {
    padding: 0 160px;
  }

  @media only screen and (max-width: 1500px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    padding: 0;
  }
`

export const ButtonContainer = styled.div<{ isFirstStep: boolean }>`
  display: flex;
  padding-top: ${({ isFirstStep }) => (isFirstStep ? "24px" : "16px")};
  flex-flow: ${({ isFirstStep }) => (isFirstStep ? "column" : "wrap")};
  gap: 16px;
  align-items: center;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    padding-top: ${({ isFirstStep }) => (isFirstStep ? "24px" : "32px")};
  }
`

export const JobContainer = styled(Box)`
  margin-top: -40px;
  margin-bottom: -64px;
  background-color: #f7f7f7;
  padding: 80px 0 160px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 80px;
    min-height: calc(100vh - 400px);
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const Step1Title = styled(Typography)`
  font-size: 33px;
  line-height: 34px;
  margin-top: -5px;
  margin-bottom: 16px;

  ${theme.breakpoints.down("sm")} {
    font-size: 25px;
    margin-bottom: 8px;
    line-height: 26px;
    margin-top: -5px;
  }
`

export const Step2LeftBox = styled(Box)`
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 40px 16px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px 80px;
  }
`

export const Step2RightBox = styled(Box)`
  box-sizing: border-box;

  @media only screen and (max-width: 1100px) {
    min-width: 290px;
    max-width: 290px;
  }

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const Divider = styled(Box)`
  border-bottom: 0.5px solid #d8d8d8;
  margin-left: -40px;
  margin-right: -40px;

  ${theme.breakpoints.down("sm")} {
    margin-left: -20px;
    margin-right: -20px;
  }
`

export const BoxHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    height: 64px;
  }
`

export const BoxHeaderTitle = styled(Typography)`
  font-size: 25px;

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`

export const TextFieldLabel = styled(Typography)`
  font-size: 20px;
  line-height: 22px;
  margin-top: -1px;

  && span {
    color: #3057e1;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 18px;
    line-height: 19px;
  }
`

export const TextFieldDescription = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  margin-top: -4px;
  margin-bottom: -3px;
  padding-top: 16px;
  padding-bottom: 8px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-bottom: -1px;
    margin-top: -4px;
  }
`

export const StyledTextFieldArea = styled(Box)`
  && .MuiInputBase-root {
    border-radius: 1px;
    box-sizing: border-box;

    fieldset {
      border-color: #d8d8d8;
    }

    .Mui-focused {
      fieldset {
        border-width: 0.5px !important;
      }
    }
  }
`

export const JobSummaryBox = styled(Box)`
  padding-top: 48px;

  && .MuiInputBase-root {
    height: 124px;
  }

  ${theme.breakpoints.down("sm")} {
    padding-top: 32px;
  }
`

export const JobDescriptionBox = styled(Box)`
  padding-top: 32px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 16px;
  }
`

export const JobTagsBox = styled(Box)`
  padding-top: 40px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
  }
`

export const JobUrlBox = styled(Box)`
  padding-top: 40px;
  padding-bottom: 32px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

export const RightTitle = styled(Typography)`
  font-size: 20px;
  color: #2d2d2d;
  line-height: 20px;
  margin-top: -2px;
`

export const GreyBall = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #717171;
  margin-right: 8px;
`
export const GreenBall = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #2ecc71;
  margin-right: 8px;
`
export const RedBall = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #f44c49;
  margin-right: 8px;
`

export const StatusText = styled(Typography)`
  font-size: 16px;
  color: #717171;
  line-height: 12px;
`

export const DescriptionText = styled(Typography)`
  font-size: 16px;
  color: #717171;
  line-height: 18px;
  margin-top: -4px;
`

export const AdBox = styled(Box)`
  background: #fff;
  border-radius: 3px;
  padding: 20px;
  box-sizing: border-box;
`

export const AdText = styled(Typography)`
  font-size: 16px;
  color: #2d2d2d;
  margin-top: -5px;
  padding-top: 16px;
  margin-bottom: -3px;
`
