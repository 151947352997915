import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import {
  CompetitionProject,
  LoadingStatuses,
  Project,
  selectProjectsStatus,
} from "../../redux/projects"
import { MainContainer, ProjectsListCardBox } from "./../HomePage/styles"

import { MobileSearchBar } from "../../components/Styles/MainSearchBar"

import LoadingPage from "../LoadingPage"
import InfiniteScroll from "react-infinite-scroll-component"

import FilterIcon from "../../images/mobile-filter-icon.svg"
import SearchIcon from "../../images/mobile-search.svg"

import ProjectCard from "../../cards/ProjectCard"

import { Search, FilterBox, ProjectListTitle } from "./styles"
import FilterProjectDialog from "../../components/FilterProjectDialog"
import BackButton from "../../components/BackButton"
import { useSelector } from "react-redux"

export type Props = {
  projects: Project[]
  filterBy: string
  onSearch: (searchText: string) => void
  page: number
  projectsCount: number
  hasMore: boolean
  term: string
  handleChangePagination: (value: number) => void
  handleChangeHasMore: (value: boolean) => void
  onChangeAction: (value: string) => void
  competition: string
  competitionProjects: any
}

const MobileView = ({
  projects,
  filterBy,
  onSearch,
  page,
  projectsCount,
  handleChangePagination,
  handleChangeHasMore,
  hasMore,
  onChangeAction,
  competition,
  competitionProjects,
}: Props) => {
  const [searchText, setSearchText] = useState("")
  const [open, setOpen] = useState(false)

  const [appliedSortBy, setAppliedSortBy] = useState(filterBy)

  const projectStatus = useSelector(selectProjectsStatus)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSetAppliedSortBy = (value: string) => {
    setAppliedSortBy(value)
  }

  const handleSearch = (e: any) => {
    onSearch(searchText)
    e.preventDefault()
  }

  const fetchMoreData = () => {
    if (projects.length >= projectsCount) {
      handleChangeHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  const handleChange = (e: any) => {
    setSearchText(e.target.value)
  }

  return (
    <>
      <MainContainer
        style={{
          position: "sticky",
          backgroundColor: "#fff",
          top: "57px",
          zIndex: 100,
          padding: "20px 16px",
          boxShadow: "none",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
          alignItems: "center",
        }}
      >
        <BackButton style={{ position: "unset", marginTop: 0 }} />
        <form onSubmit={handleSearch} style={{ display: "contents" }}>
          <Search src={SearchIcon} alt={"search-icon"} onClick={handleSearch} />
          <MobileSearchBar
            onChange={(e) => handleChange(e)}
            onSubmit={handleSearch}
            data-testid="searchBar"
            placeholder="Search..."
            style={{ marginLeft: "8px" }}
          />
          <FilterBox onClick={handleOpen}>
            <img src={FilterIcon} alt={"filter"} />
          </FilterBox>
          <input
            type="submit"
            name="submit"
            style={{ position: "fixed", marginLeft: "-10000px" }}
          />
        </form>
      </MainContainer>

      <FilterProjectDialog
        open={open}
        onClose={handleClose}
        onSetHasMore={handleChangeHasMore}
        onChangeAction={onChangeAction}
        handleSetAppliedSortBy={handleSetAppliedSortBy}
        filterBy={appliedSortBy}
        searchText={searchText}
      />

      <MainContainer marginTop={"0px !important"}>
        {competition?.includes("studio-awards") ? (
          <>
            {projectStatus === LoadingStatuses.Idle ||
            projectStatus === LoadingStatuses.Loading ? (
              <LoadingPage />
            ) : (
              <>
                {competitionProjects.map((row: CompetitionProject) => {
                  return (
                    <>
                      <ProjectListTitle variant="h2">
                        {row.groupName}
                      </ProjectListTitle>
                      <ProjectsListCardBox>
                        {row.projects?.map((project) => (
                          <ProjectCard project={project} />
                        ))}
                      </ProjectsListCardBox>
                    </>
                  )
                })}
              </>
            )}
          </>
        ) : (
          <InfiniteScroll
            dataLength={projects.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingPage />}
            style={{
              overflow: "hidden",
              marginTop: 0,
            }}
          >
            <Grid
              container
              spacing={3}
              direction="row"
              data-testid="projectsContainer"
            >
              {projects?.map((project, key) => (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <ProjectCard project={project} />
                  </Grid>
                </>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </MainContainer>
    </>
  )
}

export default MobileView
