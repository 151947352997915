import { useAuth0 } from "@auth0/auth0-react"
import React, { useState, useEffect, useCallback } from "react"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { useHistory, useParams } from "react-router-dom"
import { submitInnovationAwards } from "../../redux/projects"
import View from "./View"
import { Helmet } from "react-helmet"
import AwardsLogo from "../../images/innovation-awards/awards-logo.svg"
import { useSelector } from "react-redux"
import {
  LoadingStatuses,
  getCompetition,
  selectCompetition,
  selectCompetitionStatus,
} from "../../redux/competition"
import LoadingPage from "../LoadingPage"
import { toggleDialog } from "../../redux/config"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import camelize from "../../utils/camelize"

export interface InnovationAwardsCoAuthor {
  name: string
  profileLink: string
}

export interface InnovationAwardsForm {
  name: string
  projectLink: string
  projectTitle: string
}

interface Params {
  slug: string
}

const InnovationAwardPage = () => {
  const { slug } = useParams<Params>()
  const auth = useAuth0()

  const dispatch = useAppDispatch()
  const history = useHistory()

  const competition = useSelector(selectCompetition)
  const status = useSelector(selectCompetitionStatus)
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const { isAuthenticated } = useAuth0()

  const [showMessage, setShowMessage] = useState(false)
  const [marketingDialog, setMarketingDialog] = useState(false)
  const [marketingDialogOpened, setMarketingDialogOpened] = useState(false)

  const goToForm = () => {
    document.getElementById("awardForm")?.scrollIntoView({ behavior: "smooth" })
  }

  const goToUrl = (url: string) => history.push(url)

  const goToProject = () => {
    isAuthenticated ? goToUrl(`/projects/new`) : goToUrl("/register")
  }

  const goToProjects = () => {
    goToUrl(`/projects?competition=${competition?.code}`)
  }

  const handleSubmit = async (data: InnovationAwardsForm) => {
    try {
      if (!isAuthenticated) {
        await dispatch(toggleDialog({ opened: true }))
        return
      }

      const { type } = await dispatch(
        submitInnovationAwards({ code: competition?.code!, ...data, auth })
      )
      if (submitInnovationAwards.fulfilled.type === type) {
        setShowMessage(true)

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleClose = () => {
    setShowMessage(false)
  }

  const handleCloseMarketingDialog = () => {
    setMarketingDialog(false)
    setMarketingDialogOpened(true)
  }

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  useEffect(() => {
    if (!isAuthenticated && !marketingDialogOpened) {
      setTimeout(() => {
        setMarketingDialog(true)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [])

  const loadCompetition = useCallback(async () => {
    try {
      const { type } = await dispatch(getCompetition({ slug }))

      if (type === getCompetition.fulfilled.type) {
        // @ts-ignore
        window.prerenderReady = true
      }
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, slug])

  useEffect(() => {
    loadCompetition()
  }, [loadCompetition])

  if (status === LoadingStatuses.Loading || status === LoadingStatuses.Idle) {
    return <LoadingPage />
  }

  const screenTitle = `Innovation Awards - ${competition?.title}`

  return (
    <>
      <RouteChangeTracker
        screenTitle={screenTitle}
        classTitle={camelize(screenTitle)}
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={AwardsLogo} />
        <meta
          property="og:title"
          content={`Spectacular ${competition?.title}`}
        />
        <meta property="og:description" content={competition?.description} />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta name="title" content={`Spectacular ${competition?.title}`} />
        <meta name="description" content={competition?.description} />
        <title>{`Spectacular ${competition?.title}`}</title>
      </Helmet>
      <View
        goToForm={goToForm}
        goToProject={goToProject}
        goToProjects={goToProjects}
        goToUrl={goToUrl}
        onSubmit={handleSubmit}
        onClose={handleClose}
        showMessage={showMessage}
        isAuthenticated={isAuthenticated}
        marketingDialog={marketingDialog}
        onCloseMarketingDialog={handleCloseMarketingDialog}
        competition={competition}
        authenticatedProfile={authenticatedProfile}
      />
    </>
  )
}

export default InnovationAwardPage
