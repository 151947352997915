import styled from "styled-components"
import { Box } from "@material-ui/core"
import theme from "../../theme"

export const ConnectListBox = styled(Box)`
  background: #fff;
  width: 500px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 10px 40px rgba(113, 113, 113, 0.15);

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    margin-top: 0;
    box-shadow: none;
  }
`
