import React, { useState, useEffect } from "react"
import View, { ActionsType } from "./View"
import {
  initialWorkplaceType,
  initialEmploymentType,
  initialExperienceLevel,
} from "../../pages/JobsPage/initialStates"
import { CSSProperties } from "styled-components"

type Props = {
  onApply: (type: string, value: ActionsType[]) => void
  actions: ActionsType[]
  title: string
  type: string
  reset: boolean
  styles?: CSSProperties
}

const JobsDropDownMultiselect = ({
  onApply,
  actions,
  title,
  type,
  reset,
  styles,
}: Props) => {
  const [stateActions, setStateActions] = useState(actions)
  const [defaultActions, setDefaultActions] = useState(
    JSON.parse(JSON.stringify(actions))
  )

  const handleChangeStateActions = (changedActions: ActionsType[]) => {
    setStateActions(changedActions)
  }

  const handleChangeDefaultActions = (changedActions: ActionsType[]) => {
    setDefaultActions(changedActions)
  }

  useEffect(() => {
    if (reset) {
      if (type === "workplace-type") {
        setStateActions(JSON.parse(JSON.stringify(initialWorkplaceType)))
        setDefaultActions(JSON.parse(JSON.stringify(initialWorkplaceType)))
      } else if (type === "employment-type") {
        setStateActions(JSON.parse(JSON.stringify(initialEmploymentType)))
        setDefaultActions(JSON.parse(JSON.stringify(initialEmploymentType)))
      } else if (type === "experience-level") {
        setStateActions(JSON.parse(JSON.stringify(initialExperienceLevel)))
        setDefaultActions(JSON.parse(JSON.stringify(initialExperienceLevel)))
      }
    }
    // eslint-disable-next-line
  }, [reset])

  return (
    <View
      onApply={onApply}
      handleChangeStateActions={handleChangeStateActions}
      handleChangeDefaultActions={handleChangeDefaultActions}
      defaultActions={defaultActions}
      actions={stateActions}
      title={title}
      type={type}
      styles={styles}
    />
  )
}

export default JobsDropDownMultiselect
