import React from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"

import { Profile, removeConnection } from "../../redux/profile"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  profile: Profile
}

const ConnectDotMenu = ({ profile }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const handleRemove = async () => {
    try {
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        removeConnection({ auth, profileId, connectionId })
      )

      if (removeConnection.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <>
      {authenticatedProfile &&
      authenticatedProfile?.connectionId !== profile.connectionId ? (
        <View onRemove={handleRemove} />
      ) : null}
    </>
  )
}

export default ConnectDotMenu
