import { Box, Link, Typography } from "@material-ui/core"
import React, { ChangeEvent, useRef } from "react"
import {
  SortEndHandler,
  SortableElement,
  SortableContainer,
} from "react-sortable-hoc"

import { Image } from "../../redux/projects"
import CloseIcon from "../../images/icon-close.svg"
import UploadImagePlaceholder from "../../images/upload-image-placeholder.svg"

import {
  ImageCell,
  ImageInput,
  ImagesGrid,
  ImageCellImage,
  ImageCellAction,
  ImageCellOverlay,
  PlaceholderImage,
  ValidationErrorList,
} from "./styles"

export type Props = {
  title: string
  banner?: any
  images: Array<Image>
  allowMultiple: boolean
  isCompressing?: boolean
  isConverting?: boolean
  onSortImages?: SortEndHandler
  onRemoveImage: (image: Image) => void
  errors?: Array<{ file: File; error: string }>
  onAddImages: (event: ChangeEvent<HTMLInputElement>) => void
}

const View = ({
  title,
  images,
  errors,
  banner,
  onAddImages,
  onSortImages,
  onRemoveImage,
  allowMultiple,
  isCompressing,
  isConverting,
}: Props) => {
  const imageInput = useRef(null)

  const openFileInput = () => (imageInput.current! as HTMLElement).click()

  const SortableImage = SortableElement(({ image }: { image: Image }) => (
    <ImageCell>
      <ImageCellImage src={image.thumbnailImageUrl || image.url} alt="" />
      <ImageCellAction
        alt="close"
        src={CloseIcon}
        onClick={() => onRemoveImage(image)}
      />
      <ImageCellOverlay className="overlay" />
    </ImageCell>
  ))

  const SortableImages = SortableContainer(() => {
    return (
      <ImagesGrid>
        {images.map((image) => (
          <SortableImage
            image={image}
            index={image.order}
            disabled={!onSortImages}
            key={`item-${image.id || image.url || image.thumbnailImageUrl}`}
          />
        ))}
      </ImagesGrid>
    )
  })

  return (
    <>
      {isCompressing ? (
        <Box marginTop={1} textAlign={"center"}>
          <Typography>Compressing Image...</Typography>
        </Box>
      ) : null}

      {isConverting ? (
        <Box marginTop={1} textAlign={"center"}>
          <Typography>Converting Image...</Typography>
        </Box>
      ) : null}
      {banner && images.length > 0 && (
        <Box>
          <ImageCell fullWidth>
            <ImageCellImage
              alt=""
              src={images[0].thumbnailImageUrl || images[0].url}
            />
            <ImageCellAction
              alt="close"
              src={CloseIcon}
              onClick={() => onRemoveImage(images[0])}
            />
            <ImageCellOverlay className="overlay" />
          </ImageCell>
        </Box>
      )}
      {!banner && (
        <SortableImages axis="xy" onSortEnd={onSortImages} pressDelay={300} />
      )}
      {images.length < 1 && (
        <PlaceholderImage
          alt="Upload image"
          onClick={openFileInput}
          src={UploadImagePlaceholder}
        />
      )}
      {(allowMultiple || images.length < 1) && (
        <Box marginTop={3}>
          <ImageInput
            type="file"
            ref={imageInput}
            onChange={onAddImages}
            multiple={allowMultiple}
            accept="application/pdf,image/*"
          />
          {errors ? (
            <ul>
              {errors.map(({ file, error }) => (
                <ValidationErrorList key={`file-${file.name}`}>
                  {error}
                </ValidationErrorList>
              ))}
            </ul>
          ) : null}
          <Link onClick={openFileInput}>{title}</Link>
        </Box>
      )}
    </>
  )
}

export default View
