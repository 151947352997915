import React, { useState } from "react"
import { ProjectFeatured } from "../../redux/adminProjectFeatured"
import View from "./View"

export interface EditableProjectFeatured extends ProjectFeatured {
  editing?: boolean
}

type Props = {
  projectFeatured: EditableProjectFeatured
  onSave: (projectFeatured: EditableProjectFeatured) => void
  onDelete: (projectFeatured: EditableProjectFeatured) => void
}

const AdminProjectFeaturedRow = ({
  projectFeatured,
  onSave,
  onDelete,
}: Props) => {
  const [original] = useState(projectFeatured)
  const [featured, setFeatured] = useState(projectFeatured)

  const handleOnSave = () => {
    onSave(featured)
  }

  const handleOnEdit = () => {
    setFeatured({ ...featured, editing: true })
  }

  const handleOnChange = (
    date: Date | null,
    property: keyof EditableProjectFeatured
  ) => {
    setFeatured({ ...featured, [property]: date?.toDateString() })
  }

  const handleOnCancel = () => {
    setFeatured({ ...original, editing: false })
  }

  const handleOnDelete = () => {
    onDelete(featured)
  }

  return (
    <View
      featured={featured}
      onEdit={handleOnEdit}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onChange={handleOnChange}
      onDelete={handleOnDelete}
    />
  )
}

export default AdminProjectFeaturedRow
