import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { Project, shareProject } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { Share } from "@capacitor/share"
import environment from "../../environment"
import { normalizeProjectTitle } from "../../utils"
import * as RouteConstants from "../../constants/routes"
import { updateFeedState } from "../../redux/feed"
import { logEvent } from "../../utils/analytics"

type Props = {
  project: Project
}

const ProjectShareIcon = ({ project }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleShareClick = async (wasShared: boolean): Promise<void> => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    if (!wasShared) {
      handleProjectShare()
    }
  }

  const handleProjectShare = async () => {
    setDispatchComplete(false)
    try {
      const shareStatus = await dispatch(
        shareProject({ auth, id: project.id! })
      )
      if (shareStatus.type === "projects/share/fulfilled") {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "project",
            id: project.id!,
            action: "share",
          })
        )

        await logEvent("project_share")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false)
  }

  const handleOpenDialog = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    if (environment.device !== "desktop") {
      const projectURL = `${environment.appUrl}${
        RouteConstants.PROFILE_CONTAINER
      }/${project.username}/projects/${project.id!}/${normalizeProjectTitle(
        project.name
      )}`

      await Share.share({
        url: projectURL,
      })

      handleProjectShare()
    } else {
      setDialogIsOpen(true)
    }
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      project={project}
      handleShareClick={handleShareClick}
      dialogIsOpen={dialogIsOpen}
      handleCloseDialog={handleCloseDialog}
      handleOpenDialog={handleOpenDialog}
    />
  )
}

export default ProjectShareIcon
