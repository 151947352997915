import React from "react"
import { FieldAttributes, useField } from "formik"
import View from "./View"

interface Props extends FieldAttributes<any> {
  displayTitle?: string
}

const TextField: React.FC<Props> = (props) => {
  const [field, meta] = useField(props as FieldAttributes<any>)
  const error = meta.touched ? meta.error : null

  return <View {...props} {...field} errorMessage={error} />
}

export default TextField
