import React from "react"
import { AvailableNullstates } from "../../constants/nullstates"

import { Box } from "@material-ui/core"
import PlusIconSrc from "../../images/icon-plus.svg"

import {
  NullstateButton,
  NullstateTitle,
  NullstateText,
  PlusIcon,
} from "./styles"

export type Props = {
  onClick?: () => void
  type:
    | "owner-projects-tab"
    | "group-owner-projects-tab"
    | "group-projects-tab"
    | "projects-tab"
    | "owner-jobs-tab"
    | "group-jobs-tab"
    | "jobs-tab"
    | "owner-experience-tab"
    | "experience-tab"
    | "group-owner-about-tab"
    | "owner-about-tab"
    | "group-about-tab"
    | "about-tab"
    | "profile-in-progress"
    | "owner-posts-tab"
    | "posts-tab"
    | "posts-tab-group"
}

const View = ({ onClick, type }: Props) => {
  const curretNullstate = AvailableNullstates.find(
    (value) => value.type === type
  )

  return (
    <Box>
      <Box>
        <PlusIcon
          src={PlusIconSrc}
          alt="Spectacular"
          aria-label="specatular Icon"
          style={{
            width: curretNullstate?.iconSize,
            height: curretNullstate?.iconSize,
          }}
        />
      </Box>
      {curretNullstate?.title && (
        <Box paddingTop={5}>
          <NullstateTitle tabIndex={0} variant="h2">
            {curretNullstate?.title}
          </NullstateTitle>
        </Box>
      )}

      {curretNullstate?.text && (
        <Box paddingTop={2.5}>
          <NullstateText>{curretNullstate?.text}</NullstateText>
        </Box>
      )}

      {curretNullstate?.buttonText && (
        <Box paddingTop={4}>
          <NullstateButton color="primary" onClick={onClick}>
            {curretNullstate?.buttonText}
          </NullstateButton>
        </Box>
      )}
    </Box>
  )
}

export default View
