import React from "react"

import { useSelector } from "react-redux"
import { BellContainer, RedDot } from "./styles"

import Bell from "../../images/new-header/header-notification-icon.svg"
import BellActive from "../../images/new-header/header-notification-icon-active.svg"
import { useLocation } from "react-router-dom"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { logEvent } from "../../utils/analytics"

const InviteBellMobile = () => {
  const location = useLocation()

  const active = location.pathname.includes("/notifications")
  const unreadCount = useSelector(
    (state: any) => state.bellNotifications?.notificationsUnreadCount
  )

  const handleClick = async () => {
    await logEvent("navbar_notifications_click")
  }

  return (
    <LinkBox to={`/notifications`} onClick={handleClick} $isActive={active}>
      <BellContainer
        hover={false}
        isNav={false}
        width={56}
        height={56}
        style={{ color: active ? "#717171" : "#2d2d2d" }}
      >
        {unreadCount > 0 && (
          <RedDot
            style={{
              minWidth: unreadCount > 9 ? "19px" : "14px",
              marginLeft: unreadCount > 9 ? "24px" : "19px",
            }}
          >
            {unreadCount > 9 ? "9 +" : unreadCount}
          </RedDot>
        )}
        <img
          src={active ? BellActive : Bell}
          style={{ paddingBottom: "6px", width: "24px" }}
          alt="bell-icon"
        />
        Notifications
      </BellContainer>
    </LinkBox>
  )
}

export default InviteBellMobile
