//@ts-ignore
import * as pdfjsLib from "pdfjs-dist/webpack"
import Jimp from "jimp"
import UTIF from "utif"
import axios from "axios"

const readFileData = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target!.result)
    }
    reader.onerror = (err) => {
      reject(err)
    }
    reader.readAsDataURL(file)
  })
}

export async function convertPdfToImages(file: any) {
  const images: any = []
  const data: any = await readFileData(file)

  const pdf = await pdfjsLib.getDocument(data).promise

  const canvas = document.createElement("canvas")
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1)
    const viewport = page.getViewport({ scale: 1 })
    const context = canvas.getContext("2d")
    canvas.height = viewport.height
    canvas.width = viewport.width
    await page.render({ canvasContext: context!, viewport: viewport }).promise
    images.push(canvas.toDataURL())
  }
  canvas.remove()
  return images
}

export async function convertImages(
  files: FileList | any[],
  onConvert?: (isConverting: boolean) => void
) {
  const processedFiles: any = []
  for (let index = 0; index < files.length; index++) {
    const file = files[index]
    if (file.type.includes("pdf")) {
      //convert pdf to jpeg
      if (onConvert) onConvert(true)
      const imageFiles = await convertPdfToImages(file)
      await pushPdfImages(
        imageFiles,
        file.name.replace(".pdf", ""),
        processedFiles
      )
    } else if (file.type.includes("tiff")) {
      //convert tiff to jpeg
      if (onConvert) onConvert(true)
      const tiffBuffer = await file.arrayBuffer()
      const ifds = UTIF.decode(tiffBuffer)

      UTIF.decodeImages(tiffBuffer, ifds)

      for (let i = 0; i < ifds.length; i++) {
        const ifd = ifds[i]
        const rgba = UTIF.toRGBA8(ifd)
        const arrayBuffer = UTIF.encodeImage(rgba, ifd.width, ifd.height)
        const tiffFile = new File([arrayBuffer], file.name.replace(".tiff", ""))
        const blobImg = URL.createObjectURL(tiffFile)
        const image = await Jimp.read(blobImg)
        const buffer = await image.getBase64Async(Jimp.MIME_JPEG)
        const newImage = await urltoFile(buffer, file.name.replace(".tiff", ""))
        processedFiles.push(newImage)
      }
    } else if (file.type.includes("svg")) {
      //convert svg to png
      if (onConvert) onConvert(true)

      const svgString = await downloadSvg(URL.createObjectURL(file))

      const convertedFile = await SVGToImage({
        svg: svgString,
        mimetype: "image/png",
        width: "auto",
        height: "auto",
        quality: 1,
        outputFormat: "blob",
      })

      const blobImg = URL.createObjectURL(convertedFile)
      const image = await (await Jimp.read(blobImg)).background(0xffffffff)
      const buffer = await image.getBase64Async(Jimp.MIME_JPEG)
      const newImage = await urltoFile(buffer, file.name)

      processedFiles.push(newImage)
    } else {
      processedFiles.push(file)
    }
  }
  return await processedFiles
}

const pushPdfImages = async (
  images: any,
  filename: string,
  processedFiles: any[]
) => {
  return Promise.all(
    images.map(async (image: any) => {
      const newImage = await urltoFile(image, filename)
      processedFiles.push(newImage)
    })
  )
}

const urltoFile = (url: any, filename: string) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: "image/jpeg" })
    })
}

function SVGToImage(settings: any) {
  let _settings: any = {
    svg: null,
    mimetype: "image/png",
    quality: 0.92,
    width: "auto",
    height: "auto",
    outputFormat: "blob",
  }

  for (let key in settings) {
    _settings[key] = settings[key]
  }

  return new Promise(function (resolve, reject) {
    let svgNode

    if (typeof _settings.svg === "string") {
      let SVGContainer = document.createElement("div")
      SVGContainer.style.display = "none"
      SVGContainer.innerHTML = _settings.svg
      svgNode = SVGContainer.firstElementChild
    } else {
      svgNode = _settings.svg
    }

    let canvas = document.createElement("canvas")
    let context: any = canvas.getContext("2d")

    let svgXml = new XMLSerializer().serializeToString(svgNode)
    let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml)

    const image = new Image() as any

    image.onload = function () {
      let finalWidth, finalHeight

      if (_settings.width === "auto" && _settings.height !== "auto") {
        finalWidth = (this.width / this.height) * _settings.height
      } else if (_settings.width === "auto") {
        finalWidth = this.naturalWidth
      } else {
        finalWidth = _settings.width
      }

      if (_settings.height === "auto" && _settings.width !== "auto") {
        finalHeight = (this.height / this.width) * _settings.width
      } else if (_settings.height === "auto") {
        finalHeight = this.naturalHeight
      } else {
        finalHeight = _settings.height
      }

      canvas.width = finalWidth
      canvas.height = finalHeight

      context.drawImage(this, 0, 0, finalWidth, finalHeight)

      if (_settings.outputFormat === "blob") {
        canvas.toBlob(
          function (blob) {
            resolve(blob)
          },
          _settings.mimetype,
          _settings.quality
        )
      } else {
        resolve(canvas.toDataURL(_settings.mimetype, _settings.quality))
      }
    }

    image.src = svgBase64
  })
}

async function downloadSvg(url: string) {
  let response = await axios.get(url)
  const svgXml = response.data
  return svgXml
}
