import React from "react"
import {
  DeleteBox,
  DeleteButtonBox,
  DeleteContainer,
  DeleteText,
  DeleteTitle,
  StyledA,
  StyledLink,
} from "./styles"
import { Button } from "../../components"
import ConfirmDialog from "../../components/ConfirmDialog"

export type Props = {
  onDelete: () => void
}

const View = ({ onDelete }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  return (
    <DeleteContainer>
      <ConfirmDialog
        title="Delete profile"
        message="Are you sure you want to delete your Spectacular profile?"
        isOpen={isDialogOpen}
        onConfirm={onDelete}
        onClose={() => setIsDialogOpen(false)}
      />
      <DeleteBox>
        <DeleteTitle>Delete my Spectacular profile</DeleteTitle>
        <DeleteText>
          Are you sure you want to delete your profile? Once deleted, your
          connections, job applications, projects, and posts will be permanently
          lost. If you are having issues with your account please contact{" "}
          <StyledA href="mailto:hello@spectacular.design">
            hello@spectacular.design
          </StyledA>{" "}
          for help or click “continue to delete” to permanently delete your
          profile.
        </DeleteText>
        <DeleteButtonBox>
          <Button
            onClick={() => setIsDialogOpen(true)}
            children={"Continue to delete"}
            color={"primary"}
            size={"medium"}
            style={{
              width: "194px",
              height: "48px",
              fontSize: "16px",
            }}
          />

          <StyledLink to={"/profile/edit"}>Cancel</StyledLink>
        </DeleteButtonBox>
      </DeleteBox>
    </DeleteContainer>
  )
}

export default View
