import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { JobPosition } from "./jobPositions"
import { JobListing } from "./jobListings"
import { Education } from "./educations"
import { RootState } from "./rootReducer"
import { AwardSubmission, Image as ImageType } from "./projects"
import { ProjectReward } from "./adminProjectReward"
import { Spotlight } from "./adminSpotlight"

//#region types
export type ProfileProject = {
  id: string
  name: string
  imageUrl: string
  smallImageUrl?: string
  createdAt: string
  likes: number
  liked: boolean
  canBeLiked: boolean
  canBeNominated: boolean
  nominated: boolean
  nominations: number
  commentsCount: number
  commented: boolean
  username: string
  email: string
  published: boolean
  updatedAt?: string
  canBeEdited: boolean
  rewards?: ProjectReward[]
}

export type ConnectionList = {
  followers: Profile[]
  connections: Profile[]
}

export type ConnectionStatus = {
  following: Connection[]
  followers: Connection[]
  connections: Connection[]
  requests: Connection[]
}

export type Connection = {
  profileId: string
  connectionId: string
  status: string
  type: string
  createdAt: string
  updatedAt: string
}

export enum ProfileType {
  INDIVIDUAL = "individual",
  GROUP = "group",
}

export type NetworkCount = {
  followers: number
  following: number
  connections: number
  requests: number
  collaborators: number
  suggestions: number
}

export type Profile = {
  id?: string
  cleanId?: string
  connectionId?: string
  email?: string
  phoneNumber?: string
  type?: ProfileType
  firstName: string
  lastName?: string
  username?: string
  jobTitle?: string
  location: string
  company?: string
  awards?: string[]
  groups?: string[]
  skills?: string[]
  companySize?: string
  contactEmail?: string
  groupName?: string
  groupType?: string
  credentials?: string[]
  linkedinUrl?: string
  twitterUrl?: string
  facebookUrl?: string
  instagramUrl?: string
  personalCompanyUrl?: string
  aboutSelf?: string
  picture?: string
  file?: any
  referralCode?: string
  banner?: ImageType
  bannerFile?: any
  bannerImageUrl?: string
  smallImageUrl?: string
  jobPositions?: JobPosition[]
  educations?: Education[]
  projects?: ProfileProject[]
  featuredProject?: ProfileProject
  jobListings?: JobListing[]
  isAdmin?: boolean
  archivedAt?: Date
  placeholderAvatar?: number
  role?: string
  about?: string
  nominations?: number
  likes?: number
  aboutRft?: string
  dailyNewsletter?: boolean
  weeklyNewsletter?: boolean
  bothNewsletter?: boolean
  noNewsletter?: boolean
  connections?: ConnectionStatus
  followersCount?: number
  connectionsCount?: number
  createdAt?: Date
  firstLoginDate?: Date
  lastNominationDate?: Date
  hideNominationPopup?: boolean
  lastNominatedProject?: string
  display?: string
  allowEmailNotifications?: boolean
  skipEmailValidation?: boolean
  networkCount?: NetworkCount
  rewards?: ProjectReward[]
  eventRegistrations?: any[]
  spotlights?: Spotlight[]
  postsQuantity?: number
  tempPicture?: string
  tempAbout?: string
  isAmbassador?: boolean
  showCompleteProfilePopUp?: boolean
  popUpShownAt?: Date
  awardsSubmissions?: AwardSubmission
  ambassador: Ambassador
}

export type Ambassador = {
  program: string
  programStatus: string
}

export type IndividualProfileSignUp = {
  firstName: string
  lastName: string
  email: string
  password: string
  referralCode?: string
  inviteCode?: string
  termAcceptance: boolean
}

export type GroupProfileSignUp = {
  groupName: string
  firstName: string
  lastName: string
  email: string
  password: string
  referralCode?: string
  inviteCode?: string
  termAcceptance: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  profile?: Profile
  profiles?: Profile[]
  allProfiles?: Profile[]
  homeSpotlightedProfile?: Profile
  profilesCount: number
  newProfilesCount: number
  profilesStatus: LoadingStatuses
  selectedProfile?: Profile
  selectedProfileDetailedConnections?: ConnectionList
  status: LoadingStatuses
  selectedStatus: LoadingStatuses
  error: string | null | undefined
  createProfileError: string | null | undefined
  credentialsSuggestions: string[]
  groupSuggestions: string[]
  intercomHash: string[]
  reSendVerificationEmailError: string | null | undefined
}
//#endregion

//#region api
//Business wants to unlock user profile pages to the public
type FetchProfileByUserNamePayload = {
  username: string
}
export const fetchProfileByUsername = createAsyncThunk<
  any,
  FetchProfileByUserNamePayload
>("profile/fetchProfileByUsername", async ({ username }) => {
  return useApi(null, `/profile/${username}`).then(async (res) => {
    return await res.json()
  })
})
//This endpoint to get user profile with authorization

export const clearProfileState = createAsyncThunk<any>(
  "profile/clearProfile",
  async () => {}
)

type FetchProfileByUserNameWithAuthorizationPayload = {
  auth: Auth0ContextInterface
  username: string
}
export const fetchProfileByUsernameWithAuthorization = createAsyncThunk<
  any,
  FetchProfileByUserNameWithAuthorizationPayload
>(
  "profile/fetchProfileByUsernameWithAuthorization",
  async ({ auth, username }) => {
    return useApi(auth, `/profile/member/${username}`).then(async (res) => {
      return await res.json()
    })
  }
)

type FetchProfileByIdWithAuthorizationPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const fetchProfileByIdWithAuthorization = createAsyncThunk<
  any,
  FetchProfileByIdWithAuthorizationPayload
>("profile/fetchProfileByIdWithAuthorization", async ({ auth, id }) => {
  return useApi(auth, `/profile/memberById/${id}`).then(async (res) => {
    return await res.json()
  })
})

type FetchProfilePayload = {
  auth: Auth0ContextInterface
}
export const fetchProfile = createAsyncThunk<any, FetchProfilePayload>(
  "profile/fetchProfile",
  async ({ auth }) => {
    return useApi(auth, "/profile").then(async (res) => {
      return await res.json()
    })
  }
)

type CreatePlayerIdPayload = {
  auth: Auth0ContextInterface
  playerId: string
}
export const createPlayerId = createAsyncThunk<any, CreatePlayerIdPayload>(
  "players/createPlayerId",
  async ({ auth, playerId }) => {
    return useApi(auth, "/players", {
      method: "POST",
      body: JSON.stringify({ playerId }),
    }).then(async (res) => {
      return await res.text()
    })
  }
)

type RemovePlayerIdPayload = {
  auth: Auth0ContextInterface
  playerId: string
}
export const removePlayerId = createAsyncThunk<any, RemovePlayerIdPayload>(
  "players/removePlayerId",
  async ({ auth, playerId }) => {
    return useApi(auth, "/players", {
      method: "DELETE",
      body: JSON.stringify({ playerId }),
    }).then(async (res) => {
      return await res.text()
    })
  }
)

type FetchSpotlightedProfilePayload = {
  email?: string
  page?: number
  perPage?: number
  spotlight?: boolean
}

export const fetchSpotlightedProfile = createAsyncThunk<
  any,
  FetchSpotlightedProfilePayload
>(
  "profile/fetchSpotlightedProfile",
  async ({ email, page = 1, perPage = 10, spotlight }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/profiles?skip=${skip}&limit=${perPage}`
    if (spotlight) endpoint += `&spotlight=${spotlight}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type NewFetchProfilesPayload = {
  email?: string
  page?: number
  perPage?: number
  myNetwork?: boolean
  search?: string
  profileType?: string[]
  sortBy: string
}

export const newFetchProfiles = createAsyncThunk<any, NewFetchProfilesPayload>(
  "profile/newFetchProfiles",
  async ({
    email,
    page = 1,
    perPage = 8,
    search,
    myNetwork,
    profileType,
    sortBy,
  }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/newProfiles?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    if (myNetwork) endpoint += `&myNetwork=${myNetwork}`
    if (profileType) endpoint += `&profileType=${JSON.stringify(profileType)}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type FetchProfilesPayload = {
  email?: string
  page?: number
  perPage?: number
  onlyFollowing?: boolean
  location?: string
  skills?: string[]
  search?: string
  sortBy?: string
}

export const fetchProfiles = createAsyncThunk<any, FetchProfilesPayload>(
  "profile/fetchProfiles",
  async ({
    email,
    page = 1,
    perPage = 10,
    location,
    skills,
    search,
    onlyFollowing,
    sortBy,
  }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/profilesWithProjects?skip=${skip}&limit=${perPage}`
    if (location) endpoint += `&location=${location}`
    if (skills) endpoint += `&skills=${skills}`
    if (search) endpoint += `&search=${search}`
    if (onlyFollowing) endpoint += `&onlyFollowing=${onlyFollowing}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

export const refreshProfile = createAsyncThunk<any, FetchProfilePayload>(
  "profile/refreshProfile",
  async ({ auth }) => {
    return useApi(auth, "/profile").then(async (res) => {
      return await res.json()
    })
  }
)

type UpdateProfilePayload = {
  auth: Auth0ContextInterface
  profile: Profile
}
export const updateProfile = createAsyncThunk<any, UpdateProfilePayload>(
  "profile/updateProfile",
  async ({ auth, profile }) => {
    const form = new FormData()
    for (let [key, value] of Object.entries(profile)) {
      if (key === "location" && profile.type === "individual" && value) {
        const locationTmp = value.split(",")
        let street = ""
        if (locationTmp.length - 4 >= 0) {
          const streetTmp = locationTmp[locationTmp.length - 4]
          if (/^\d/.test(streetTmp)) {
            street = streetTmp + ", "
            value = value.replace(street, "")
          }
        }
      }
      if (value) form.append(key, value)
    }

    form.delete("groupType")
    form.delete("dailyNewsletter")
    form.delete("weeklyNewsletter")
    form.delete("allowEmailNotifications")

    if (!profile.linkedinUrl) form.append("linkedinUrl", "")

    if (!profile.twitterUrl) form.append("twitterUrl", "")

    if (!profile.facebookUrl) form.append("facebookUrl", "")

    if (!profile.instagramUrl) form.append("instagramUrl", "")

    form.append("dailyNewsletter", JSON.stringify(profile.dailyNewsletter))

    form.append("weeklyNewsletter", JSON.stringify(profile.weeklyNewsletter))

    form.append(
      "allowEmailNotifications",
      JSON.stringify(profile.allowEmailNotifications)
    )

    if (!profile.personalCompanyUrl) form.append("personalCompanyUrl", "")

    form.append(
      "groupType",
      profile.groupType?.replace("Other:", "").trim() || ""
    )

    form.append("origin", window.location.origin)

    form.delete("credentials")
    form.delete("awards")
    form.delete("skills")
    form.delete("groups")
    form.delete("isAdmin")
    form.delete("placeholderAvatar")
    form.delete("file") //file upload moved from api to react app
    return useApi(auth, "/profile", {
      method: "PATCH",
      body: form,
      headers: {
        "Content-Type": "Automatic",
      },
    }).then((res) => res.json())
  }
)

type UpdateAwardsProfilePayload = {
  auth: Auth0ContextInterface
  awards: string[]
}
export const updateAwardsProfile = createAsyncThunk<
  any,
  UpdateAwardsProfilePayload
>("profile/updateAwardsProfile", async ({ auth, awards }) => {
  return useApi(auth, "/profile/awards", {
    method: "PATCH",
    body: JSON.stringify({ awards }),
  }).then((res) => res.text())
})

type UpdateCareerFairProfilePayload = {
  auth: Auth0ContextInterface
  firstName: string
  lastName: string
  contactEmail: string
}
export const updateCareerFairProfile = createAsyncThunk<
  any,
  UpdateCareerFairProfilePayload
>(
  "profile/updateCareerFairProfile",
  async ({ auth, firstName, lastName, contactEmail }) => {
    return useApi(auth, "/profile/awards", {
      method: "PATCH",
      body: JSON.stringify({ firstName, lastName, contactEmail }),
    }).then((res) => res.text())
  }
)

type UpdateGroupsProfilePayload = {
  auth: Auth0ContextInterface
  groups: string[]
}
export const updateGroupsProfile = createAsyncThunk<
  any,
  UpdateGroupsProfilePayload
>("profile/updateGroupsProfile", async ({ auth, groups }) => {
  return useApi(auth, "/profile/groups", {
    method: "PATCH",
    body: JSON.stringify({ groups }),
  }).then((res) => res.text())
})

type UpdateCredentialsProfilePayload = {
  auth: Auth0ContextInterface
  credentials: string[]
}
export const updateCredentialsProfile = createAsyncThunk<
  any,
  UpdateCredentialsProfilePayload
>("profile/updateProfileCredentials", async ({ auth, credentials }) => {
  return useApi(auth, "/profile/credentials", {
    method: "PATCH",
    body: JSON.stringify({ credentials }),
  }).then((res) => res.text())
})

type UpdateSkillsProfilePayload = {
  auth: Auth0ContextInterface
  skills: string[]
}
export const updateSkillsProfile = createAsyncThunk<
  any,
  UpdateSkillsProfilePayload
>("profile/updateSkillsProfile", async ({ auth, skills }) => {
  return useApi(auth, "/profile/skills", {
    method: "PATCH",
    body: JSON.stringify({ skills }),
  }).then((res) => res.text())
})

type CreateIndividualProfilePayload = {
  values: IndividualProfileSignUp
}
export const createIndividualProfile = createAsyncThunk<
  any,
  CreateIndividualProfilePayload
>("profile/createIndividualProfile", async ({ values }) => {
  return useApi(null, "/profile", {
    method: "POST",
    body: JSON.stringify({ ...values, type: ProfileType.INDIVIDUAL }),
  }).then((res) => res.json())
})

type CreateGroupProfilePayload = {
  values: GroupProfileSignUp
}
export const createGroupProfile = createAsyncThunk<
  any,
  CreateGroupProfilePayload
>("profile/createGroupProfile", async ({ values }) => {
  const { groupName, ...groupValues } = values
  return useApi(null, "/profile", {
    method: "POST",
    body: JSON.stringify({
      ...groupValues,
      groupName,
      // firstName: groupName,
      type: ProfileType.GROUP,
    }),
  }).then((res) => res.json())
})

type GetCredentialSuggestionsPayload = {
  auth: Auth0ContextInterface
}
export const getCredentialSuggestions = createAsyncThunk<
  any,
  GetCredentialSuggestionsPayload
>("profile/getCredentialSuggestions", async ({ auth }) => {
  return useApi(auth, "/profile/credentials/suggestions", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type GetGroupSuggestionsPayload = {
  auth: Auth0ContextInterface
}
export const getGroupSuggestions = createAsyncThunk<
  any,
  GetGroupSuggestionsPayload
>("profile/getGroupSuggestions", async ({ auth }) => {
  return useApi(auth, "/profile/group/suggestions", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type GetIntercomUserHashPayload = {
  auth: Auth0ContextInterface
}
export const getIntercomUserHash = createAsyncThunk<
  any,
  GetIntercomUserHashPayload
>("profile/getIntercomUserHash", async ({ auth }) => {
  return useApi(auth, "/profile/intercom", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type ReSendVerificationEmailPayload = {
  email: string
}
export const reSendVerificationEmail = createAsyncThunk<
  any,
  ReSendVerificationEmailPayload
>("profile/reSendVerificationEmail", async ({ email }) => {
  return useApi(null, `/profile/send_verification_email`, {
    method: "POST",
    body: JSON.stringify({ email: email }),
  }).then(async (res) => {
    return await res.json()
  })
})

type AvailableUserNameCheckPayload = {
  username: string
}
export const availableUserNameCheck = createAsyncThunk<
  any,
  AvailableUserNameCheckPayload
>("profile/availableUserNameCheck", async ({ username }) => {
  return useApi(null, `/profile/available/${username}`).then(async (res) => {
    return await res.json()
  })
})

type AuthorizedUserNameCheckPayload = {
  auth: Auth0ContextInterface
  id: string
  username: string
}
export const authorizedUserNameCheck = createAsyncThunk<
  any,
  AuthorizedUserNameCheckPayload
>("profile/authorizedUserNameCheck", async ({ auth, id, username }) => {
  return useApi(auth, `/profile/authorized/${id}/${username}`).then(
    async (res) => {
      return await res.json()
    }
  )
})

type AvailableEmailCheckPayload = {
  auth: Auth0ContextInterface
  email: string
}
export const availableEmailCheck = createAsyncThunk<
  any,
  AvailableEmailCheckPayload
>("profile/AvailableEmailCheck", async ({ auth, email }) => {
  return useApi(auth, `/profile/email/${email}`).then(async (res) => {
    return await res.json()
  })
})

type ValidateEmailPayload = {
  email: string
}
export const validateEmail = createAsyncThunk<any, ValidateEmailPayload>(
  "profile/validateEmail",
  async ({ email }) => {
    return useApi(null, `/profile/validateEmail/${email}`).then(async (res) => {
      return await res.text()
    })
  }
)

type ValidateProfileUrlPayload = {
  profileLink: string
}

export const validateProfileUrl = createAsyncThunk<
  any,
  ValidateProfileUrlPayload
>("projects/validateProfileUrl", async ({ profileLink }) => {
  const form = {
    profileLink,
  }

  return useApi(null, "/profile/validate-url", {
    method: "POST",
    body: JSON.stringify(form),
  }).then((res) => res.text())
})

type FollowProfilePayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
  isAdmin?: boolean
}
export const followProfile = createAsyncThunk<any, FollowProfilePayload>(
  "profile/followProfile",
  async ({ auth, profileId, connectionId, isAdmin }) => {
    const product = "spectacular"
    const status = isAdmin ? "admin" : "accepted"
    const type = "follow"
    return useApi(auth, "/connections", {
      method: "POST",
      body: JSON.stringify({
        profileId,
        connectionId,
        product,
        status,
        type,
      }),
    }).then((res) => res.text())
  }
)

type UnfollowProfilePayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
}
export const unfollowProfile = createAsyncThunk<any, UnfollowProfilePayload>(
  "profile/unfollowProfile",
  async ({ auth, profileId, connectionId }) => {
    const type = "follow"
    return useApi(auth, `/connections/${profileId}/${connectionId}`, {
      method: "DELETE",
      body: JSON.stringify({
        type,
      }),
    }).then((res) => res.text())
  }
)

type ConnectionRequestPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
  isAdmin?: boolean
}
export const connectionRequest = createAsyncThunk<
  any,
  ConnectionRequestPayload
>(
  "profile/connectionRequest",
  async ({ auth, profileId, connectionId, isAdmin }) => {
    const product = "spectacular"
    const status = isAdmin ? "admin" : "requested"
    const type = "connection"
    return useApi(auth, "/connections", {
      method: "POST",
      body: JSON.stringify({
        profileId,
        connectionId,
        product,
        status,
        type,
      }),
    }).then((res) => res.text())
  }
)

type RemoveConnectionPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
}
export const removeConnection = createAsyncThunk<any, RemoveConnectionPayload>(
  "profile/removeConnection",
  async ({ auth, profileId, connectionId }) => {
    const type = "connection"
    return useApi(auth, `/connections/${profileId}/${connectionId}`, {
      method: "DELETE",
      body: JSON.stringify({
        type,
      }),
    }).then((res) => res.text())
  }
)

type UndoDeniedRequestPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
  isAdmin?: boolean
}
export const undoDeniedRequest = createAsyncThunk<
  any,
  UndoDeniedRequestPayload
>(
  "profile/undoDeniedRequest",
  async ({ auth, profileId, connectionId, isAdmin }) => {
    const product = "spectacular"
    const status = isAdmin ? "admin" : "requested"
    const type = "connection"
    return useApi(auth, "/connections/undo-deny", {
      method: "POST",
      body: JSON.stringify({
        profileId,
        connectionId,
        product,
        status,
        type,
      }),
    }).then((res) => res.text())
  }
)

type WithdrawConnectionRequestPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
}
export const withdrawConnectionRequest = createAsyncThunk<
  any,
  WithdrawConnectionRequestPayload
>(
  "profile/withdrawConnectionRequest",
  async ({ auth, profileId, connectionId }) => {
    const type = "connection"
    return useApi(auth, `/connections/${profileId}/${connectionId}`, {
      method: "DELETE",
      body: JSON.stringify({
        type,
      }),
    }).then((res) => res.text())
  }
)

type ConfirmConnectionRequestPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
}
export const confirmConnectionRequest = createAsyncThunk<
  any,
  ConfirmConnectionRequestPayload
>(
  "profile/confirmConnectionRequest",
  async ({ auth, profileId, connectionId }) => {
    const type = "connection"
    const product = "spectacular"
    return useApi(auth, `/connections/${profileId}/${connectionId}`, {
      method: "PATCH",
      body: JSON.stringify({
        type,
        product,
      }),
    }).then((res) => res.text())
  }
)

type DenyConnectionRequestPayload = {
  auth: Auth0ContextInterface
  profileId?: string
  connectionId?: string
}
export const denyConnectionRequest = createAsyncThunk<
  any,
  DenyConnectionRequestPayload
>(
  "profile/denyConnectionRequest",
  async ({ auth, profileId, connectionId }) => {
    const type = "connection"
    const product = "spectacular"
    return useApi(auth, `/connections/deny/${profileId}/${connectionId}`, {
      method: "DELETE",
      body: JSON.stringify({
        type,
        product,
      }),
    }).then((res) => res.text())
  }
)

type FetchDetailedConnectionsPayload = {
  auth: Auth0ContextInterface
  username?: string
}

export const fetchDetailedConnections = createAsyncThunk<
  any,
  FetchDetailedConnectionsPayload
>("profile/fetchDetailedConnections", async ({ auth, username }) => {
  return useApi(auth, `/profile/connections/list/${username}`, {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type FetchProfileSuggestionPayload = {
  auth: Auth0ContextInterface
  query?: string
  profiles?: string[]
}

export const fetchProfileSuggestion = createAsyncThunk<
  any,
  FetchProfileSuggestionPayload
>("profile/fetchProfileSuggestion", async ({ auth, query, profiles }) => {
  let endpoint = `/profile/suggestions?query=${query}`
  if (profiles) endpoint += `&profilesToRemove=${JSON.stringify(profiles)}`

  return useApi(auth, endpoint, {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type UpdateProfileNominationStatePayload = {
  nominatedProject: string
  hide?: boolean
  clearProfile?: boolean
}

export const updateProfileNominationState = createAsyncThunk<
  any,
  UpdateProfileNominationStatePayload
>(
  "profile/updateProfileNominationState",
  async ({ nominatedProject, hide, clearProfile }) => {
    return ""
  }
)
type UpdateProjectNominationStatePayload = {
  id: string
  lastNominated?: string
  lastNominationDate?: Date
}

export const updateProjectNominationState = createAsyncThunk<
  any,
  UpdateProjectNominationStatePayload
>(
  "profile/updateProjectNominationState",
  async ({ lastNominated, id, lastNominationDate }) => {
    return ""
  }
)

type UpdateCollaboratorsProfileStatePayload = {
  collaboratorId: string
  action: string
}

export const updateCollaboratorsProfileState = createAsyncThunk<
  any,
  UpdateCollaboratorsProfileStatePayload
>(
  "profile/updateCollaboratorsProfileState",
  async ({ collaboratorId, action }) => {
    return ""
  }
)

type UpdatePostsProfileStatePayload = {
  action: string
}

export const updatePostsProfileState = createAsyncThunk<
  any,
  UpdatePostsProfileStatePayload
>("profile/updatePostsProfileState", async ({ action }) => {
  return ""
})

type SendNotificationEmailAndRemoveSuggestionPayload = {
  auth: Auth0ContextInterface
  profileId: string
  isSuggestion?: boolean
  templateName:
    | "PROFILE_CONNECTION_REQUEST"
    | "PROFILE_FOLLOW"
    | "PROFILE_CONNECTION_ACCEPTED"
}

export const sendNotificationEmailAndRemoveSuggestion = createAsyncThunk<
  any,
  SendNotificationEmailAndRemoveSuggestionPayload
>(
  "profiles/sendNotificationEmailAndRemoveSuggestion",
  async ({ auth, profileId, templateName, isSuggestion }) => {
    const form = {
      profileId,
      templateName,
      isSuggestion,
      origin: window.location.origin,
    }

    return useApi(auth, "/profile/sendEmail", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => res.text())
  }
)

type AmbassadorsPayload = {
  auth: Auth0ContextInterface
  program: string
  programStatus: string
  phoneNumber: string
  organization?: string
}

export const ambassadorsApply = createAsyncThunk<any, AmbassadorsPayload>(
  "/ambassadors",
  async ({ auth, program, programStatus, phoneNumber, organization }) => {
    const form = {
      program,
      programStatus,
      origin: window.location.origin,
      phoneNumber,
      organization,
    }

    return useApi(auth, "/ambassadors", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => res.text())
  }
)

type NewsletterSubscriptionIAPayload = {
  email: string
}

export const subscribeToHubspotIA = createAsyncThunk<
  any,
  NewsletterSubscriptionIAPayload
>("profiles/subscribeToHubspotIA", async ({ email }) => {
  const form = {
    email,
  }

  return useApi(null, "/profile/hubspot/subscribeIA", {
    method: "POST",
    body: JSON.stringify(form),
  }).then((res) => res.text())
})

type CompetitionApplicationPayload = {
  auth: Auth0ContextInterface
  email: string
  campaign: string
  collaborators?: any[]
}

export const compettitionApplication = createAsyncThunk<
  any,
  CompetitionApplicationPayload
>("competitions/apply", async ({ auth, email, campaign, collaborators }) => {
  const form = {
    email,
    collaborators,
    campaign,
    origin: window.location.origin,
  }

  return useApi(auth, "/competitions/apply", {
    method: "POST",
    body: JSON.stringify(form),
  }).then((res) => res.text())
})

type DeleteProfilePayload = {
  auth: Auth0ContextInterface
}

export const deleteProfile = createAsyncThunk<any, DeleteProfilePayload>(
  "competitions/apply",
  async ({ auth }) => {
    return useApi(auth, "/profiles", {
      method: "DELETE",
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  profile: undefined,
  profiles: [],
  allProfiles: [],
  homeSpotlightedProfile: undefined,
  profilesCount: 0,
  newProfilesCount: 0,
  profilesStatus: LoadingStatuses.Idle,
  selectedProfile: undefined,
  selectedProfileDetailedConnections: undefined,
  selectedStatus: LoadingStatuses.Idle,
  status: LoadingStatuses.Idle,
  error: undefined,
  createProfileError: undefined,
  credentialsSuggestions: [],
  groupSuggestions: [],
  intercomHash: [],
  reSendVerificationEmailError: undefined,
}

export default createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProfileNominationState.fulfilled, (state, action) => {
      if (state.profile) {
        if (action.meta.arg.clearProfile) {
          state.profile.lastNominatedProject =
            "110ec58a-a0f2-4ac4-8393-c866d813b8d1"
          state.profile.lastNominationDate = new Date(
            "2022-01-01"
          ).toISOString() as any
        } else {
          state.profile.lastNominatedProject = action.meta.arg.nominatedProject
          state.profile.lastNominationDate = new Date().toISOString() as any

          if (action.meta.arg.hide)
            state.profile.hideNominationPopup = action.meta.arg.hide
        }
      }
    })
    builder.addCase(updateProjectNominationState.fulfilled, (state, action) => {
      if (state.selectedProfile && state.selectedProfile.projects) {
        const lastNominationDate = new Date(
          action.meta.arg.lastNominationDate as any
        )
        const now =
          new Date().getDate() +
          new Date().getMonth() +
          new Date().getFullYear()
        const lastNomination = action.meta.arg.lastNominationDate
          ? lastNominationDate.getDate() +
            lastNominationDate.getMonth() +
            lastNominationDate.getFullYear()
          : 0

        const updatedProject = [...state.selectedProfile.projects]
        updatedProject.forEach((project) => {
          if (
            now === lastNomination &&
            project.id! === action.meta.arg.lastNominated
          ) {
            project.nominations!--
            project.nominated = false
          }

          if (now === lastNomination && project.id! === action.meta.arg.id) {
            project.nominations!++
            project.nominated = true
          }
        })
        state.selectedProfile.projects = updatedProject
      }
    })
    builder.addCase(
      updateCollaboratorsProfileState.fulfilled,
      (state, action) => {
        if (state.profile) {
          const newCount = state.profile?.networkCount?.collaborators! - 1

          const networkCount = {
            ...state.profile?.networkCount,
            collaborators: newCount,
          }

          const authenticatedProfile = {
            ...state.profile,
            networkCount,
          } as Profile

          state.profile = authenticatedProfile
        }
      }
    )
    builder.addCase(updatePostsProfileState.fulfilled, (state, action) => {
      if (state.selectedProfile) {
        const newQuantity =
          action.meta.arg.action === "delete"
            ? state.selectedProfile.postsQuantity! - 1
            : state.selectedProfile.postsQuantity! + 1
        state.selectedProfile.postsQuantity = newQuantity
      }
    })
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(clearProfileState.pending, (state, action) => {
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(clearProfileState.fulfilled, (state, action) => {
      state.profile = undefined
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(fetchSpotlightedProfile.fulfilled, (state, action) => {
      if (action.payload.data[0]) {
        state.homeSpotlightedProfile = action.payload.data[0]
        state.status = LoadingStatuses.Succeeded
      }
    })
    builder.addCase(fetchProfile.pending, (state, action) => {
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
    })
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload.data
      state.profilesCount = action.payload.count
      state.profilesStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(fetchProfiles.pending, (state) => {
      state.profilesStatus = LoadingStatuses.Loading
    })

    builder.addCase(newFetchProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload.data
      state.profilesCount = action.payload.count
      state.profilesStatus = LoadingStatuses.Succeeded

      state.newProfilesCount =
        state.newProfilesCount + action.payload.data.length

      state.allProfiles =
        action.meta.arg.page === 0 || action.meta.arg.page === 1
          ? action.payload.data
          : state.allProfiles?.concat(action.payload.data)

      state.error = null
    })
    builder.addCase(newFetchProfiles.pending, (state, action) => {
      state.profilesStatus = LoadingStatuses.Loading

      if (action.meta.arg.page === 0 || action.meta.arg.page === 1) {
        state.allProfiles = []
        state.newProfilesCount = 0
      }
    })

    builder.addCase(fetchProfiles.rejected, (state, action) => {
      state.profilesStatus = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(fetchProfileByUsername.fulfilled, (state, action) => {
      state.selectedProfile = action.payload
      state.selectedStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(fetchProfileByUsername.pending, (state, _) => {
      state.selectedStatus = LoadingStatuses.Loading
      state.selectedProfileDetailedConnections = undefined
      state.selectedProfile = undefined
    })
    builder.addCase(fetchProfileByUsername.rejected, (state, _) => {
      state.selectedStatus = LoadingStatuses.Failed
    })
    builder.addCase(
      fetchProfileByUsernameWithAuthorization.fulfilled,
      (state, action) => {
        state.selectedProfile = action.payload
        state.selectedStatus = LoadingStatuses.Succeeded
      }
    )
    builder.addCase(
      fetchProfileByIdWithAuthorization.fulfilled,
      (state, action) => {
        state.selectedProfile = action.payload
        state.selectedStatus = LoadingStatuses.Succeeded
      }
    )
    builder.addCase(
      fetchProfileByUsernameWithAuthorization.pending,
      (state, _) => {
        state.selectedStatus = LoadingStatuses.Loading
        state.selectedProfileDetailedConnections = undefined
        state.selectedProfile = undefined
      }
    )
    builder.addCase(
      fetchProfileByUsernameWithAuthorization.rejected,
      (state, _) => {
        state.selectedStatus = LoadingStatuses.Failed
      }
    )
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const placeholderAvatar = state.profile?.placeholderAvatar
      state.profile = action.payload
      state.profile!.placeholderAvatar = placeholderAvatar
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(updateCredentialsProfile.fulfilled, (state, _acion) => {
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(refreshProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(createIndividualProfile.pending, (state, action) => {
      state.createProfileError = null
    })
    builder.addCase(createIndividualProfile.rejected, (state, action) => {
      state.createProfileError = action.error.message
    })
    builder.addCase(createGroupProfile.pending, (state, action) => {
      state.createProfileError = null
    })
    builder.addCase(createGroupProfile.rejected, (state, action) => {
      state.createProfileError = action.error.message
    })
    builder.addCase(getCredentialSuggestions.fulfilled, (state, action) => {
      state.credentialsSuggestions = action.payload
    })
    builder.addCase(getGroupSuggestions.fulfilled, (state, action) => {
      state.groupSuggestions = action.payload
    })
    builder.addCase(getIntercomUserHash.fulfilled, (state, action) => {
      state.intercomHash = action.payload
    })
    builder.addCase(reSendVerificationEmail.fulfilled, (state, action) => {
      state.reSendVerificationEmailError = action.payload
    })
    builder.addCase(reSendVerificationEmail.pending, (state, action) => {
      state.reSendVerificationEmailError = null
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(reSendVerificationEmail.rejected, (state, action) => {
      state.reSendVerificationEmailError = action.error.message
      state.status = LoadingStatuses.Failed
    })
    builder.addCase(fetchDetailedConnections.pending, (state, action) => {
      state.selectedProfileDetailedConnections = undefined
    })
    builder.addCase(fetchDetailedConnections.fulfilled, (state, action) => {
      state.selectedProfileDetailedConnections = action.payload
    })
    builder.addCase(updateCareerFairProfile.fulfilled, (state, action) => {
      if (state.profile) {
        state.profile.firstName = action.meta.arg.firstName
        state.profile.lastName = action.meta.arg.lastName
        state.profile.contactEmail = action.meta.arg.contactEmail
      }
    })
    builder.addCase(
      sendNotificationEmailAndRemoveSuggestion.fulfilled,
      (state, action) => {
        if (action.meta.arg.isSuggestion) {
          const newCount = state.profile?.networkCount?.suggestions! - 1

          const networkCount = {
            ...state.profile?.networkCount,
            suggestions: newCount,
          }

          const authenticatedProfile = {
            ...state.profile,
            networkCount,
          } as Profile

          state.profile = authenticatedProfile
        }
      }
    )
    builder.addCase(followProfile.fulfilled, (state, action) => {
      if (state.selectedProfile) {
        let followersCount = state.selectedProfile?.followersCount!
        if (
          !state.profile?.isAdmin &&
          state.selectedProfile.connectionId === action.meta.arg.connectionId
        ) {
          followersCount = state.selectedProfile?.followersCount! + 1
        }

        const profile = {
          ...state.selectedProfile,
          followersCount: followersCount,
        } as Profile
        state.selectedProfile = profile
      }

      const payload = JSON.parse(action.payload)

      const newCount = state.profile?.networkCount?.following! + 1

      const networkCount = {
        ...state.profile?.networkCount,
        following: newCount,
      }

      const newConnection = {
        profileId: state.profile?.connectionId,
        connectionId: payload.connectionId,
      }

      const connections = {
        ...state.profile?.connections,
        following: [...state.profile?.connections?.following!, newConnection],
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
        networkCount,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(unfollowProfile.fulfilled, (state, action) => {
      if (state.selectedProfile) {
        let followersCount = state.selectedProfile?.followersCount!
        if (
          !state.profile?.isAdmin &&
          state.selectedProfile.connectionId === action.meta.arg.connectionId
        ) {
          followersCount = state.selectedProfile?.followersCount! - 1
        }

        const profile = {
          ...state.selectedProfile,
          followersCount: followersCount,
        } as Profile
        state.selectedProfile = profile
      }

      const authenticatedProfileWithoutConnections =
        state.profile?.connections?.following.filter((connection) => {
          return !(connection.connectionId === action.meta.arg.connectionId)
        })
      const connections = {
        ...state.profile?.connections,
        following: authenticatedProfileWithoutConnections,
      }

      const newCount = state.profile?.networkCount?.following! - 1

      const networkCount = {
        ...state.profile?.networkCount,
        following: newCount,
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
        networkCount,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(connectionRequest.fulfilled, (state, action) => {
      const payload = JSON.parse(action.payload)

      const newConnection = {
        profileId: state.profile?.connectionId,
        connectionId: payload.connectionId,
        status: "requested",
      }

      const connections = {
        ...state.profile?.connections,
        connections: [
          ...state.profile?.connections?.connections!,
          newConnection,
        ],
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(undoDeniedRequest.fulfilled, (state, action) => {
      const newConnection = {
        profileId: state.profile?.connectionId,
        connectionId: action.meta.arg.connectionId,
        status: "accepted",
      }

      const connections = {
        ...state.profile?.connections,
        connections: [
          ...state.profile?.connections?.connections!,
          newConnection,
        ],
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(confirmConnectionRequest.fulfilled, (state, action) => {
      const payload = JSON.parse(action.payload)

      if (state.selectedProfile) {
        let connectionsCount = state.selectedProfile?.connectionsCount!
        if (!state.profile?.isAdmin) {
          connectionsCount = state.selectedProfile?.connectionsCount! + 1
        }

        const profile = {
          ...state.selectedProfile,
          connectionsCount: connectionsCount,
        } as Profile
        state.selectedProfile = profile
      }

      const authenticatedProfileWithoutConnections =
        state.profile?.connections?.connections.filter((connection) => {
          return !(connection.connectionId === action.meta.arg.connectionId)
        })

      const authenticatedProfileWithoutRequests =
        state.profile?.connections?.requests.filter((connection) => {
          return !(connection.profileId === action.meta.arg.connectionId)
        })

      const newConnection = {
        profileId: state.profile?.connectionId,
        connectionId: payload.profileId,
        status: "accepted",
      }

      let connections = {}

      if (authenticatedProfileWithoutConnections) {
        connections = {
          ...state.profile?.connections,
          connections: [
            ...authenticatedProfileWithoutConnections,
            newConnection,
          ],
        }
      } else {
        connections = {
          ...state.profile?.connections,
          connections: [
            ...state.profile?.connections?.connections!,
            newConnection,
          ],
          requests: authenticatedProfileWithoutRequests,
        }
      }

      const newCount = state.profile?.networkCount?.requests! - 1
      const newCountConnections = state.profile?.networkCount?.connections! + 1

      const networkCount = {
        ...state.profile?.networkCount,
        requests: newCount,
        connections: newCountConnections,
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
        networkCount,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(withdrawConnectionRequest.fulfilled, (state, action) => {
      const authenticatedProfileWithoutConnections =
        state.profile?.connections?.connections.filter((connection) => {
          return !(connection.connectionId === action.meta.arg.connectionId)
        })
      const connections = {
        ...state.profile?.connections,
        connections: authenticatedProfileWithoutConnections,
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(denyConnectionRequest.fulfilled, (state, action) => {
      const authenticatedProfileWithoutRequests =
        state.profile?.connections?.requests.filter((connection) => {
          return !(connection.connectionId === action.meta.arg.profileId)
        })

      const connections = {
        ...state.profile?.connections,
        requests: authenticatedProfileWithoutRequests,
      }

      const newCount = state.profile?.networkCount?.requests! - 1

      const networkCount = {
        ...state.profile?.networkCount,
        requests: newCount,
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
        networkCount,
      } as Profile

      state.profile = authenticatedProfile
    })
    builder.addCase(removeConnection.fulfilled, (state, action) => {
      if (state.selectedProfile) {
        let connectionsCount = state.selectedProfile?.connectionsCount!
        if (!state.profile?.isAdmin) {
          connectionsCount = state.selectedProfile?.connectionsCount! - 1
        }

        const profile = {
          ...state.selectedProfile,
          connectionsCount: connectionsCount,
        } as Profile
        state.selectedProfile = profile
      }

      const authenticatedProfileWithoutConnections =
        state.profile?.connections?.connections.filter((connection) => {
          return !(connection.connectionId === action.meta.arg.connectionId)
        })

      const authenticatedProfileWithoutRequests =
        state.profile?.connections?.requests.filter((connection) => {
          return !(connection.profileId === action.meta.arg.connectionId)
        })

      const newCount = state.profile?.networkCount?.connections! - 1

      const networkCount = {
        ...state.profile?.networkCount,
        connections: newCount,
      }

      const connections = {
        ...state.profile?.connections,
        connections: authenticatedProfileWithoutConnections,
        requests: authenticatedProfileWithoutRequests,
      }

      const authenticatedProfile = {
        ...state.profile,
        connections,
        networkCount,
      } as Profile

      state.profile = authenticatedProfile
    })
  },
})
//#endregion

//#region selectors
export const selectProfile = ({ profile }: RootState) => profile?.profile
export const selectProfileStatus = ({ profile }: RootState) => profile?.profile
//#endregion
