import { useAuth0 } from "@auth0/auth0-react"
import React, { useState } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { requestJobsCreation } from "../../redux/jobListings"
import { JobRequest } from "../../redux/jobListings"

import View from "./View"

type Props = {
  onClose: () => void
  open: boolean
}

const JobRequestDialog = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const [requested, setRequested] = useState(false)

  const handleSend = async (jobRequest: JobRequest) => {
    try {
      jobRequest.origin = window.location.origin
      const { type } = await dispatch(requestJobsCreation({ auth, jobRequest }))
      if (requestJobsCreation.fulfilled.type === type) {
        setRequested(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleClose = () => {
    setRequested(false)
    onClose()
  }

  return (
    <View
      onClose={handleClose}
      open={open}
      onSubmit={handleSend}
      requested={requested}
    />
  )
}

export default JobRequestDialog
