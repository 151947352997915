import React, { useState } from "react"

import View from "./View"

type Props = {
  role?: string
  onChange: (newValue: string) => void
}

const suggestions = [
  "Architectural Associate",
  "Architectural Designer",
  "Design Director",
  "Interior Designer",
  "Lead Designer",
  "Principal",
  "Project Architect",
  "Project Manager",
  "Spec Writer",
  "Technical Lead",
  "Other: ",
]

const ProjectTags = ({ role, onChange }: Props) => {
  const [value, setValue] = useState<string>(role || "")

  const onInputChange = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <View
      value={value}
      suggestions={suggestions}
      onInputChange={onInputChange}
    />
  )
}

export default ProjectTags
