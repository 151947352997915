import React from "react"
import { TextField, Box } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

type Props = {
  error?: string
  value: string
  suggestions: string[]
  onInputChange: (value: string) => void
}

const View = ({ value, suggestions, onInputChange, error }: Props) => (
  <>
    <Box marginBottom={2}>
      <Autocomplete
        value={value}
        id="group-type"
        inputValue={value}
        options={suggestions}
        onInputChange={(_, newValue) => onInputChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={error}
            label="Group type*"
            error={error !== undefined}
            InputProps={{ ...params.InputProps }}
          />
        )}
      />
    </Box>
  </>
)

export default View
