import React, { MouseEvent, useState } from "react"
import { DotButton, Triangle, MenuItem, MenuBox } from "./Styles"
import DotIcon from "../../images/dots.svg"
import { Popover } from "@material-ui/core"

export type Props = {
  onRemove: () => void
}

const View = ({ onRemove }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const removeConnection = () => {
    onRemove()
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DotButton onClick={handleClick}>
        <img
          src={DotIcon}
          alt={"dot menu"}
          style={{ width: "15px", height: "3.5px" }}
        />
      </DotButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            marginLeft: "15px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          <MenuItem onClick={removeConnection}>Remove Connection</MenuItem>
        </MenuBox>
      </Popover>
    </>
  )
}

export default View
