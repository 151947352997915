import React from "react"
import View from "./View"
import { JobListing } from "../../redux/jobListings"
import moment from "moment"

export type Props = {
  jobs: JobListing[]
}

const JobSuggestionsCard = ({ jobs }: Props) => {
  moment.locale("en", {
    relativeTime: {
      s: "%d s",
      m: "1 m",
      mm: "%d m",
      h: "1 h",
      hh: "%d h",
      d: "1 d",
      dd: function (number) {
        if (number < 7) {
          return number + " d"
        } else {
          var weeks = Math.round(number / 7)
          return weeks + " w"
        }
      },
      M: "1 mo",
      MM: "%d mo",
      y: "1 y",
      yy: "%d y",
    },
  })

  return <View jobs={jobs} />
}

export default JobSuggestionsCard
