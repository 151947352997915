import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import View from "./View"
import { Helmet } from "react-helmet"
import PreviewImage from "../../images/career-fair/webinar-preview.jpg"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import SelectedView from "./SelectedView"

export interface InnovationAwardsCoAuthor {
  name: string
  profileLink: string
}

export interface InnovationAwardsForm {
  name: string
  category: string
  email: string
  projectLink: string
  projectTitle: string
  updated: boolean
  singleAuthor: boolean
  groupProject: boolean
  coAuthors?: InnovationAwardsCoAuthor[]
}

const InternExpoPage = () => {
  const history = useHistory()

  const [currentView, setCurrentView] = useState("home")

  const { isAuthenticated } = useAuth0()

  // @ts-ignore
  window.prerenderReady = true

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  const handleUpdateView = (view: string) => {
    setCurrentView(view)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Firm Spotlight`}
        classTitle="FirmSpotlight"
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={PreviewImage} />
        <meta
          property="og:title"
          content={`Intern-Expo Webinar 2024 - Spectacular`}
        />
        <meta
          property="og:description"
          content={`Spectacular is hosting a live webinar on April 12th to help our community of talented young designers get a foot in the door to some of the most sought after summer internships in Architecture. During the webinar you’ll have the opportunity to hear from top firms and receive priority access to amazing summer internships in your area!`}
        />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta name="title" content={`Intern-Expo Webinar 2024 - Spectacular`} />
        <meta
          name="description"
          content={`Spectacular is hosting a live webinar on April 12th to help our community of talented young designers get a foot in the door to some of the most sought after summer internships in Architecture. During the webinar you’ll have the opportunity to hear from top firms and receive priority access to amazing summer internships in your area!`}
        />
        <title>Intern-Expo Webinar 2024 - Spectacular</title>
      </Helmet>

      {currentView === "home" ? (
        <View
          isAuthenticated={isAuthenticated}
          onChangeView={handleUpdateView}
        />
      ) : (
        <SelectedView
          isAuthenticated={isAuthenticated}
          currentView={currentView}
        />
      )}
    </>
  )
}

export default InternExpoPage
