import React from "react"

import PopoverContent from "./PopoverContent"
import { ReferralDialog as Dialog } from "./styles"

interface Props {
  isOpen: boolean
  onClose: () => void
  referralCode: string
}

export const ReferralDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  referralCode,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="referral-dialog">
      <PopoverContent onClose={onClose} referralCode={referralCode} />
    </Dialog>
  )
}
