import React, { useCallback, useEffect } from "react"
import { Profile } from "../../redux/profile"

import View from "./View"
import { Camera } from "@capacitor/camera"

export type Props = {
  profile: Profile
  disableSubmit: boolean
  usernameCheck: string
  usernameValue: string
  onCheckUsernameAvailability: (
    _event: React.ChangeEvent<{ value: any }>
  ) => void
  onSubmit: Function
  onCancel: Function
  adminEditing?: boolean
}

const IndividualProfileForm = (props: Props) => {
  const CheckGalleryAccess = useCallback(async () => {
    try {
      const permission = await Camera.checkPermissions()
      if (permission.camera !== "granted" || permission.photos !== "granted") {
        await Camera.requestPermissions({ permissions: ["camera", "photos"] })
      }
    } catch (error) {
      console.log("failed to load camera")
    }
  }, [])

  useEffect(() => {
    CheckGalleryAccess()
  }, [CheckGalleryAccess])

  return <View {...props} />
}

export default IndividualProfileForm
