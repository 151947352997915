import React from "react"
import styled from "styled-components"
import { motion, AnimatePresence, PanInfo } from "framer-motion"
import AspectRatioIcon from "@material-ui/icons/AspectRatio"

import {
  LargeImage,
  LeftArrowIcon,
  RightArrowIcon,
  IconOverlayLeft,
  IconOverlayRight,
  IconOverlayTopRight,
  OpaqueOverlayContainer,
  ClearOverlayContainer,
} from "./Styles"

import { Image as ImageType, ImageUtils } from "../../redux/projects"
import { useWindowDimensions } from "../../hooks"

const variants = {
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
}

const SWIPE_CONFIDENCE_THRESHOLD = 10000

const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity

const AnimatedContainer = styled(motion.div)<{}>`
  width: 100%;
  height: 100%;
  position: absolute;
`

interface Props {
  images: ImageType[]
  projectName?: string
  selectedIndex: number
  onExpand: () => void
  onSelect: (img: ImageType, idx: number) => void
}

const Projector: React.FC<Props> = ({
  images,
  projectName,
  onSelect,
  onExpand,
  selectedIndex,
}) => {
  const { width } = useWindowDimensions()
  const [[page, direction], setPage] = React.useState([0, 0])

  React.useEffect(() => setPage([selectedIndex, 1]), [selectedIndex])

  const nextIndex = (selectedIndex + 1) % images.length
  const prevIndex = (selectedIndex + images.length - 1) % images.length

  const paginate = (newDirection: number) =>
    setPage([page + newDirection, newDirection])

  const next = () => {
    paginate(1)
    onSelect(images[nextIndex], nextIndex)
  }

  const prev = () => {
    paginate(-1)
    onSelect(images[prevIndex], prevIndex)
  }

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    { offset, velocity }: PanInfo
  ) => {
    const swipe = swipePower(offset.x, velocity.x)
    if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) next()
    else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) prev()
  }

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <AnimatedContainer
          drag="x"
          key={page}
          exit="exit"
          initial="enter"
          animate="center"
          dragElastic={1}
          custom={direction}
          variants={variants}
          onDragEnd={handleDragEnd}
          dragConstraints={{ left: 0, right: 0 }}
          transition={{
            opacity: { duration: 0.2 },
            x: { type: "spring", stiffness: 300, damping: 30 },
          }}
        >
          <LargeImage
            src={ImageUtils.getLargeThumbnailSrc(images[selectedIndex])}
            alt={`Online architect portfolio ${projectName}`}
          />
        </AnimatedContainer>
      </AnimatePresence>
      <IconOverlayTopRight>
        <OpaqueOverlayContainer onClick={() => onExpand()}>
          <AspectRatioIcon style={{ height: "100%", width: "100%" }} />
        </OpaqueOverlayContainer>
      </IconOverlayTopRight>
      {width >= 550 ? (
        <>
          <IconOverlayLeft>
            <ClearOverlayContainer onClick={() => prev()}>
              <LeftArrowIcon style={{ height: "100%", width: "100%" }} />
            </ClearOverlayContainer>
          </IconOverlayLeft>
          <IconOverlayRight>
            <ClearOverlayContainer onClick={() => next()}>
              <RightArrowIcon style={{ height: "100%", width: "100%" }} />
            </ClearOverlayContainer>
          </IconOverlayRight>
        </>
      ) : null}
    </>
  )
}

export default Projector
