import React, { ChangeEvent } from "react"
import { arrayMove } from "react-sortable-hoc"
import { compressAccurately } from "image-conversion"

import View from "./View"
import { Image as ImageType } from "../../redux/projects"

import { convertImages } from "../../utils/imagesConverter"

export type Props = {
  title?: string
  banner?: any
  minResX?: number
  minResY?: number
  maxResX?: number
  maxResY?: number
  allowMultiple?: boolean
  images: Array<ImageType>
  onRemoveImage: (image: ImageType) => void
  onAddImages: (images: Array<ImageType>) => void
  onSortImages?: (images: Array<ImageType>) => void
}

const resolveImageDimensions = (file: File) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
  })

const ImageUploader = ({
  images,
  banner,
  onAddImages,
  onSortImages,
  onRemoveImage,
  minResX = 100,
  minResY = 100,
  title = "Add images",
  allowMultiple = false,
}: Props) => {
  const [validationErrors, setValidationErrors] = React.useState<
    Array<{ file: File; error: string }>
  >([])
  const [isCompressing, setIsCompressing] = React.useState(false)
  const [isConverting, setIsConverting] = React.useState(false)

  const handleAddImages = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const newImages = new Array<ImageType>()
    const errors = new Array<{ file: File; error: string }>()

    const allFiles = await convertImages(files!, setIsConverting)

    setIsConverting(false)

    if (allFiles && allFiles.length > 0) {
      for (let idx = 0; idx < allFiles.length; idx++) {
        let file = allFiles[idx]
        const dimensions = await resolveImageDimensions(file)

        if (dimensions.width < minResX || dimensions.height < minResY)
          errors.push({
            file,
            error: `File: ${file.name}, resolution lower than ${minResX}x${minResY}.`,
          })

        if (file.size / 1000 > 10000) {
          setIsCompressing(true)
          const convertedFileObj = await compressAccurately(file, 10000) //conversion size must be passed in kb
          const convertedFile = new File([convertedFileObj], file.name, {
            type: convertedFileObj.type,
          })
          file = convertedFile
          setIsCompressing(false)
        }

        if (errors.findIndex((error) => error.file.name === file.name) === -1)
          newImages.push({
            file,
            url: URL.createObjectURL(file),
            order: idx + images.length,
            dimensions,
          })
      }

      if (errors.length === 0) setValidationErrors([])
      if (errors.length > 0) setValidationErrors(errors)

      onAddImages(newImages)
    }
  }

  const handleImagesSort = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    onSortImages &&
      onSortImages(
        arrayMove(images, oldIndex, newIndex).map((image, index) => ({
          ...image,
          order: index,
        }))
      )
  }

  return (
    <View
      title={title}
      banner={banner}
      images={images}
      errors={validationErrors}
      onAddImages={handleAddImages}
      onRemoveImage={onRemoveImage}
      allowMultiple={allowMultiple}
      isCompressing={isCompressing}
      isConverting={isConverting}
      onSortImages={handleImagesSort}
    />
  )
}

export default ImageUploader
