import { Box } from "@material-ui/core"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import ConnectionCard from "../../../../cards/ConnectionCard"
import { Profile } from "../../../../redux/profile"
import LoadingPage from "../../../LoadingPage"
import { StyledInput, Search, SearchBox } from "../../styles"
import SearchIcon from "../../../../images/mobile-search.svg"
import {
  ConnectionsBox,
  ConnectionsHeader,
  ConnectionsList,
  Divider,
} from "./styles"

export type Props = {
  connections: Profile[]
  connectionsCount: number
  hasMore: boolean
  isLoading: boolean
  page: number
  search?: string
  onUpdateHasMore: (value: boolean) => void
  onUpdatePage: (value: number) => void
  handleChangeSearch: (value: string) => void
}

const View = ({
  connections,
  connectionsCount,
  hasMore,
  page,
  search,
  isLoading,
  onUpdateHasMore,
  onUpdatePage,
  handleChangeSearch,
}: Props) => {
  const fetchMoreData = () => {
    if (connections.length >= connectionsCount) {
      onUpdateHasMore(false)
      return
    }
    onUpdatePage(page + 1)
  }

  if (isLoading)
    return (
      <ConnectionsBox>
        <LoadingPage />
      </ConnectionsBox>
    )

  return (
    <ConnectionsBox>
      <ConnectionsHeader>
        <Box>
          {connectionsCount}{" "}
          {connectionsCount === 1 ? "connection" : "connections"}
        </Box>
        <SearchBox>
          <Search src={SearchIcon} alt={"search-icon"} />
          <StyledInput
            type="text"
            placeholder="Search..."
            onChange={(e) => handleChangeSearch(e.target.value)}
          />
        </SearchBox>
      </ConnectionsHeader>
      <ConnectionsList>
        {connections.length > 0 ? (
          <InfiniteScroll
            dataLength={connections.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingPage />}
            style={{ overflow: "hidden" }}
          >
            {connections.map((connection, idx) => {
              return (
                <>
                  <ConnectionCard profile={connection} />
                  {connections.length - 1 !== idx && (
                    <>
                      <Divider />
                    </>
                  )}
                </>
              )
            })}
          </InfiniteScroll>
        ) : (
          <>
            {search ? (
              <>There are no matches for your search</>
            ) : (
              <>There are no connections for now</>
            )}
          </>
        )}
      </ConnectionsList>
    </ConnectionsBox>
  )
}

export default View
