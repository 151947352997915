import React from "react"

import {
  SuccessDialogArea,
  SuccessDialogTitle,
  SuccessDialogBox,
  SuccessDialogText,
  DialogBgBox,
  SuccessIconImg,
  CloseIconImg,
} from "../InnovationAwardPage/styles"

import SuccessIcon from "../../images/innovation-awards/modal-success.png"

import CloseIcon from "../../images/innovation-awards/close-icon.png"

export type Props = {
  onClose: () => void
  open: boolean
}

const SuccessDialog = ({ open, onClose }: Props) => {
  return (
    <SuccessDialogArea maxWidth={"md"} open={open} onClose={onClose}>
      <SuccessDialogBox>
        <DialogBgBox>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={onClose} />
          <SuccessIconImg src={SuccessIcon} alt={"success"} />
          <SuccessDialogTitle variant="h2">Success</SuccessDialogTitle>
          <SuccessDialogText>
            <span>Thank you, your registration has been sent.</span>
          </SuccessDialogText>
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default SuccessDialog
