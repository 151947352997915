import _ from "lodash"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { AppState, useAppDispatch } from "../../redux/configureStore"
import {
  refreshProfile,
  updateSkillsProfile,
  updateGroupsProfile,
  updateAwardsProfile,
} from "../../redux/profile"

import View from "./View"
import * as RouteConstants from "../../constants/routes"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"
const ExperienceEditPage = () => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profile = useSelector((state: AppState) => state.profile.profile)
  const jobPositions = _.sortBy(profile?.jobPositions || [], [
    "endDate",
    "startDate",
  ]).reverse()
  const educations = _.sortBy(profile?.educations || [], [
    "endYear",
    "startYear",
  ]).reverse()

  useEffect(() => {
    dispatch(refreshProfile({ auth }))
  }, [auth, dispatch])

  const goToNewJobPosition = () => {
    history.push("/job_positions/new")
  }

  const goToNewEducation = () => {
    history.push("/educations/new")
  }

  const goToProfileExperience = () => {
    history.push(
      `${RouteConstants.PROFILE_CONTAINER}/${profile!.username}/experience`
    )
  }

  const handleAddNewSkill = async (skills: string[]) => {
    try {
      await dispatch(updateSkillsProfile({ auth, skills }))
      await dispatch(refreshProfile({ auth }))
    } catch (error) {
      console.log(`error`, error)
    }
  }

  const handleAddGroups = async (groups: string[]) => {
    try {
      await dispatch(updateGroupsProfile({ auth, groups }))
      await dispatch(refreshProfile({ auth }))
    } catch (error) {
      console.log(`error`, error)
    }
  }

  const handleAddAwards = async (awards: string[]) => {
    try {
      await dispatch(updateAwardsProfile({ auth, awards }))
      await dispatch(refreshProfile({ auth }))
    } catch (error) {
      console.log(`error`, error)
    }
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Edit Experience`}
        classTitle="EditExperience"
      />
      <Helmet>
        <title>Edit Experience - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Edit Experience - Spectacular`} />
        <meta name="title" content={`Edit Experience - Spectacular`} />
      </Helmet>
      <View
        awards={profile!.awards || []}
        groups={profile!.groups || []}
        skills={profile!.skills || []}
        educations={educations}
        jobPositions={jobPositions}
        onAddAwards={handleAddAwards}
        onAddGroups={handleAddGroups}
        onNewSkillAdded={handleAddNewSkill}
        onNewJobClicked={goToNewJobPosition}
        onCompletedClicked={goToProfileExperience}
        onNewEducationClicked={goToNewEducation}
      />
    </>
  )
}

export default ExperienceEditPage
