import React from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Profile } from "../../redux/profile"

const url_string = window.location.href
const url = new URL(url_string)
const error = url.searchParams.get("error")
const error_description = url.searchParams.get("error_description")

if (
  error === "unauthorized" &&
  error_description === "Please verify your email before logging in."
)
  window.location.replace("/new_user")

const OnboardingCheckPage = () => {
  const profile = useSelector((state: any): Profile => state.profile.profile)

  if (profile && !profile.location && !profile.aboutSelf) {
    return <Redirect to="/profile/edit" />
  } else {
    sessionStorage.clear()
    return <Redirect to="/" />
  }
}

export default OnboardingCheckPage
