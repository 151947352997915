import React from "react"

import { normalizeProjectTitle } from "../../utils"
import * as RouteConstants from "../../constants/routes"

import { Project } from "../../redux/projects"

import environment from "../../environment"

import CloseDialog from "../../images/new-homepage/icons/close-dialog.svg"
import FacebookIcon from "../../images/innovation-awards/active-facebook.svg"
import LinkedinIcon from "../../images/innovation-awards/active-linkedin.svg"
import PinterestIcon from "../../images/innovation-awards/active-pinterest.svg"
import XIcon from "../../images/innovation-awards/active-x.svg"
import CopyIcon from "../../images/innovation-awards/copy-icon.svg"
import EmailIcon from "../../images/innovation-awards/email-icon.svg"

import {
  DialogArea,
  DialogBody,
  DialogBox,
  DialogHeader,
  DialogTitle,
  CloseDialogButton,
  ShareItemBox,
  StyledTooltip,
  StyledA,
  StyledFacebookShareButton,
  StyledTwitterShareButton,
  StyledLinkedinShareButton,
  StyledPinterestShareButton,
} from "./styles"

export type Props = {
  onClose: () => void
  handleShare: (hide?: boolean) => void
  dialogIsOpen: boolean
  project: Project
}

const Dialog = ({ dialogIsOpen, onClose, handleShare, project }: Props) => {
  const [tooltipOpened, setTooltipOpened] = React.useState(false)

  const Share = () => {
    handleShare()
  }

  const projectURL = `${environment.appUrl}${
    RouteConstants.PROFILE_CONTAINER
  }/${project.username}/projects/${project.id!}/${normalizeProjectTitle(
    project.name
  )}`

  const copyLink = () => {
    navigator.clipboard.writeText(projectURL)
    handleTooltipOpen()
    Share()

    setTimeout(handleTooltipClose, 1000)
  }

  const handleTooltipClose = () => {
    setTooltipOpened(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpened(true)
  }

  return (
    <DialogArea maxWidth={"md"} open={dialogIsOpen} onClose={onClose}>
      <DialogBox>
        <CloseDialogButton src={CloseDialog} onClick={onClose} />
        <DialogHeader>
          <DialogTitle>Share with your community</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <StyledTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltipOpened}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Link copied"
          >
            <ShareItemBox onClick={copyLink}>
              <img src={CopyIcon} alt={"Copy Link"} /> Copy link
            </ShareItemBox>
          </StyledTooltip>
          <StyledA href={"mailto:hello@spectacular.design"}>
            <ShareItemBox onClick={Share}>
              <img src={EmailIcon} alt={"Email"} /> Email
            </ShareItemBox>
          </StyledA>
          <StyledFacebookShareButton
            url={projectURL}
            quote={project?.name}
            hashtag={"spectacular"}
          >
            <ShareItemBox onClick={Share}>
              <img src={FacebookIcon} alt={"Facebook"} /> Facebook
            </ShareItemBox>
          </StyledFacebookShareButton>

          <StyledLinkedinShareButton url={projectURL} title={project?.name!}>
            <ShareItemBox onClick={Share}>
              <img src={LinkedinIcon} alt={"Linkedin"} /> Linkedin
            </ShareItemBox>
          </StyledLinkedinShareButton>
          <StyledTwitterShareButton url={projectURL} hashtags={["spectacular"]}>
            <ShareItemBox onClick={Share}>
              <img src={XIcon} alt={"X"} /> X
            </ShareItemBox>
          </StyledTwitterShareButton>

          <StyledPinterestShareButton
            url={projectURL}
            media={project?.medImageUrl!}
          >
            <ShareItemBox onClick={Share}>
              <img src={PinterestIcon} alt={"Pinterest"} /> Pinterest
            </ShareItemBox>
          </StyledPinterestShareButton>
        </DialogBody>
      </DialogBox>
    </DialogArea>
  )
}

export default Dialog
