import React from "react"
import { Profile } from "../../redux/profile"
import {
  Collaborator as ProjectCollaborator,
  Project,
} from "../../redux/projects"
import * as RouteConstants from "../../constants/routes"
import {
  CollaboratorAvatar,
  CollaboratorBox,
  CollaboratorLocation,
  CollaboratorName,
  ListButtonBox,
  NameBox,
} from "../../pages/ProjectDetailPage/styles"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import FollowButton from "../../components/FollowButton"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

interface Props {
  collaborator?: ProjectCollaborator
  profile?: Profile
  project?: Project
}

export const Collaborator = ({ collaborator, profile, project }: Props) => {
  const displayProfile = collaborator ? collaborator.profile : profile

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${displayProfile!.username}`
  }

  const company = collaborator ? collaborator.company : project?.firmName
  const discipline = collaborator ? collaborator.discipline : project?.role

  let name = displayProfile?.groupName
  if (displayProfile?.type !== "group")
    name = displayProfile?.firstName + " " + displayProfile?.lastName

  return (
    <>
      <CollaboratorBox>
        <LinkBox to={goToProfile()}>
          <CollaboratorAvatar
            src={
              displayProfile?.smallImageUrl ||
              getRandomPlaceholder(displayProfile?.placeholderAvatar!)
            }
          />
        </LinkBox>
        <NameBox>
          <LinkBox to={goToProfile()}>
            <CollaboratorName>
              {name}
              {displayProfile?.isAmbassador && (
                <img
                  src={AmbassadorIcon}
                  alt="Student Ambassador"
                  className="StudentAmbassadorIcon"
                />
              )}
            </CollaboratorName>
          </LinkBox>
          {company && <CollaboratorLocation>{company}</CollaboratorLocation>}
          {discipline && (
            <CollaboratorLocation>{discipline}</CollaboratorLocation>
          )}
        </NameBox>
        <ListButtonBox>
          <FollowButton
            profile={displayProfile!}
            size={"large"}
            color={"light-blue"}
          />
        </ListButtonBox>
      </CollaboratorBox>
    </>
  )
}
