import React from "react"
import View from "./View"

interface Props {
  activeSearch: string
  onChangeActiveSearch: (value: string, reser: boolean) => void
  typologyFilter?: string[]
  toolsFilter?: string[]
  keyMaterialsFilter?: string[]
  myNetworkFilter: boolean
  profileTypeFilter?: string[]
  text: string
  onUpdateText: (value: string) => void
  onUpdateTypologyFilter: (value: string) => void
  onUpdateToolsFilter: (value: string) => void
  onUpdateKeyMaterialsFilter: (value: string) => void
  onUpdateNetworkFilter: (value: boolean) => void
  onUpdateTextFilter: (value: string) => void
  onUpdateProfileTypeFilter: (value: string) => void
  onShortcutClick: (value: string) => void
}

const HomeSearchBar = ({
  activeSearch,
  onChangeActiveSearch,
  typologyFilter,
  toolsFilter,
  keyMaterialsFilter,
  myNetworkFilter,
  profileTypeFilter,
  text,
  onUpdateText,
  onUpdateTypologyFilter,
  onUpdateToolsFilter,
  onUpdateKeyMaterialsFilter,
  onUpdateNetworkFilter,
  onUpdateTextFilter,
  onUpdateProfileTypeFilter,
  onShortcutClick,
}: Props) => {
  return (
    <View
      activeSearch={activeSearch}
      onChangeActiveSearch={onChangeActiveSearch}
      typologyFilter={typologyFilter}
      toolsFilter={toolsFilter}
      keyMaterialsFilter={keyMaterialsFilter}
      myNetworkFilter={myNetworkFilter}
      profileTypeFilter={profileTypeFilter}
      text={text}
      onUpdateText={onUpdateText}
      onUpdateTypologyFilter={onUpdateTypologyFilter}
      onUpdateToolsFilter={onUpdateToolsFilter}
      onUpdateKeyMaterialsFilter={onUpdateKeyMaterialsFilter}
      onUpdateNetworkFilter={onUpdateNetworkFilter}
      onUpdateTextFilter={onUpdateTextFilter}
      onUpdateProfileTypeFilter={onUpdateProfileTypeFilter}
      onShortcutClick={onShortcutClick}
    />
  )
}

export default HomeSearchBar
