import React from "react"
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary"
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone"

import { DropzoneProps } from "."
import { colors } from "../../theme"
import {
  DropzoneBox,
  DropzoneContainer,
  DropzoneLine,
  DropzoneList,
} from "./styles"

interface Props extends DropzoneProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
}

const View: React.FC<Props> = ({
  isDragAccept,
  isDragActive,
  isDragReject,
  getRootProps,
  getInputProps,
}) => {
  return (
    <DropzoneContainer
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
    >
      <DropzoneBox>
        <PhotoLibraryIcon
          style={{ width: "64px", height: "64px", color: colors.slate }}
        />
        <input {...getInputProps()} />
        <DropzoneLine>
          Drag and drop an image, or <span>Browse</span>
        </DropzoneLine>
        <DropzoneLine>1600x1200 or higher recommended.</DropzoneLine>
        <DropzoneList>
          <li>PDF and TIFF will be converted to JPG</li>
          <li>Images larger than 10MB will be compressed</li>
          <li>Only upload media you have permission to use</li>
        </DropzoneList>
      </DropzoneBox>
    </DropzoneContainer>
  )
}

export default View
