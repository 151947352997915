import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"

import { Box } from "@material-ui/core"

import NotificationsBell from "../../components/NotificationsBell"
import NotificationDotMenu from "../../components/NotificationDotMenu"

import {
  updateNotificationsState,
  fetchNotifications,
} from "../../redux/bellNotifications"

import { NotificationListBox } from "./styles"
import { Profile } from "../../redux/profile"
import { useAuth0 } from "@auth0/auth0-react"
import BackButton from "../../components/BackButton"
import environment from "../../environment"

export type Props = {
  profile: Profile
}

const View = ({ profile }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const [hasMore, setHasMore] = useState(true)
  const [notificationState, setNotificationState] = useState("all")

  const currentPage = useSelector(
    (state: any) => state.bellNotifications?.notificationCurrentPage
  )

  const profileId = profile.connectionId

  const [page, setPage] = React.useState(currentPage > 0 ? currentPage : 0)
  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangePagination = (value: number) => {
    setPage(value)
  }

  const handleAllRead = async () => {
    try {
      const state = "clicked"
      const { type } = await dispatch(
        updateNotificationsState({ auth, profileId, state })
      )
      if (updateNotificationsState.fulfilled.type === type) {
        await dispatch(
          fetchNotifications({
            currentPage: 0,
            auth,
            reset: true,
            notificationState,
          })
        )

        handleChangeHasMore(true)
        handleChangePagination(0)

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleChangeState = async (value: string) => {
    setNotificationState(value)
    await dispatch(
      fetchNotifications({
        currentPage: 0,
        auth,
        reset: true,
        notificationState: value,
      })
    )

    handleChangeHasMore(true)
    handleChangePagination(0)
  }

  return (
    <NotificationListBox>
      <BackButton
        style={{
          marginLeft: "10px",
          marginTop: environment.device === "ios-app" ? "0" : "",
        }}
      />
      <Box
        style={{
          fontSize: "25px",
          height: "64px",
          alignContent: "center",
          display: "flex",
          flexFlow: "wrap",
          padding: "0 32px 0 10px",
          justifyContent: "space-between",
        }}
      >
        <Box>Notifications</Box>
        <NotificationDotMenu
          handleAllRead={handleAllRead}
          handleChangeState={handleChangeState}
        />
      </Box>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
          marginBottom: "16px",
        }}
      />
      <Box style={{ padding: "0 10px" }}>
        <NotificationsBell
          hasMore={hasMore}
          handleChangeHasMore={handleChangeHasMore}
          handleChangePagination={handleChangePagination}
          notificationState={notificationState}
          page={page}
        />
      </Box>
    </NotificationListBox>
  )
}

export default View
