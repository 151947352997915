import styled from "styled-components"
import { Dialog, Typography, Box } from "@material-ui/core"
import { colors } from "../../theme"

export const RequestDialog = styled(Dialog)`
  min-height: 100vh;
  z-index: 2147483098 !important;
`

export const RequestDialogTitle = styled(Typography)`
  font-size: 16px;
  color: ${colors.dark};
  padding-left: 20px;
  padding-right: 20px;
  line-height: 16px;
`

export const RequestDialogLabel = styled(Box)`
  font-size: 14px;
  color: ${colors.mediumLightGray};
`

export const RequestDialogBox = styled(Box)`
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-flow: column;
`

export const RequestDialogSwitch = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`

export const RequestDialogCloseIcon = styled(Box)`
  text-align: right;
  padding-right: 20px;
  height: 16px;
`
