import React, { useCallback, useEffect, useState } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { useSelector } from "react-redux"
import {
  DropDownBox,
  NoResultsBox,
  NoResultsShortcut,
  NoResultsShortcuts,
  NoResultsText,
  NoResultsTitle,
  ProfilesListCardBox,
  ProjectsListBox,
  ProjectsListContainer,
} from "./styles"
import LoadingPage from "../LoadingPage"
import { Profile, newFetchProfiles } from "../../redux/profile"
import ProfileListingCard from "../../cards/ProfileListingCard"
import { Divider } from "../../components/Styles/Global"
import InfiniteScroll from "react-infinite-scroll-component"
import DropDownHomepage from "../../components/DropDownHomepage"

interface Props {
  email?: string
  profileTypeFilter?: string[]
  textFilter: string
  myNetworkFilter: boolean
  page: number
  onChangePage: (value: number) => void
  onShortcutClick: (value: string) => void
  onUpdateSortBy: (value: string) => void
  sortBy: string
}

const ProfilesList = ({
  email,
  profileTypeFilter,
  textFilter,
  myNetworkFilter,
  page,
  onChangePage,
  onShortcutClick,
  onUpdateSortBy,
  sortBy,
}: Props) => {
  const dispatch = useAppDispatch()

  const profiles = useSelector(
    (state: any) => state.profile.allProfiles
  ) as Profile[]
  const profilesCount = useSelector((state: any) => state.profile.profilesCount)
  const newProfilesCount = useSelector(
    (state: any) => state.profile.newProfilesCount
  )
  const checkCount = page === 1 ? 0 : newProfilesCount

  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const loadProjects = useCallback(async () => {
    try {
      setHasMore(true)
      setIsLoading(true)
      const search = textFilter
      const { type, payload } = await dispatch(
        newFetchProfiles({
          email,
          page,
          search,
          profileType: profileTypeFilter,
          myNetwork: myNetworkFilter,
          sortBy,
        })
      )

      if (newFetchProfiles.fulfilled.type === type) {
        if (checkCount >= payload.count || payload.data.length < 8) {
          setHasMore(false)
        }
        setIsLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    email,
    page,
    profileTypeFilter,
    myNetworkFilter,
    textFilter,
    sortBy,
  ])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  const sortActions = [
    {
      action: "engaging",
      display: "Most Appreciated",
    },
    {
      action: "recent",
      display: "Most Recent",
    },
    {
      action: "oldest",
      display: "Oldest",
    },
  ]

  return (
    <ProjectsListContainer>
      <ProjectsListBox>
        <DropDownBox>
          <DropDownHomepage
            actions={sortActions}
            onClick={onUpdateSortBy}
            title={"Sort"}
          />
        </DropDownBox>
        <InfiniteScroll
          dataLength={profiles.length}
          next={() => onChangePage(page + 1)}
          hasMore={hasMore}
          loader={<LoadingPage />}
          style={{ overflow: "hidden" }}
          scrollThreshold={0.8}
        >
          <ProfilesListCardBox>
            {profiles.map((profile, index) => {
              return (
                <>
                  <ProfileListingCard profile={profile} key={profile.id} />
                  {profiles.length - 1 !== index && (
                    <>
                      <Divider />
                    </>
                  )}
                </>
              )
            })}
          </ProfilesListCardBox>
        </InfiniteScroll>

        {!isLoading && profilesCount === 0 && (
          <NoResultsBox>
            <NoResultsTitle variant="h2">No results found.</NoResultsTitle>
            <NoResultsText>
              Sorry, we couldn’t find any results for this search.
              <br />
              Try shortening or rephrasing your search.
            </NoResultsText>
            <NoResultsShortcuts>
              <NoResultsShortcut onClick={() => onShortcutClick("Concrete")}>
                Concrete
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Photography")}>
                Photography
              </NoResultsShortcut>
              <NoResultsShortcut
                onClick={() => onShortcutClick("Interior Design")}
              >
                Interior Design
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Chicago")}>
                Chicago
              </NoResultsShortcut>
            </NoResultsShortcuts>
          </NoResultsBox>
        )}
      </ProjectsListBox>
    </ProjectsListContainer>
  )
}

export default ProfilesList
