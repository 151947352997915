import React, { useState } from "react"
import { Formik, Form, Field, FormikHelpers } from "formik"
import * as Yup from "yup"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
} from "@material-ui/core"
import {
  PaymentButtonContainer,
  WizardHeaderText,
  WizardStepHeaderContainer,
} from "../../pages/PromotionWizardPage/styles"
import { Button } from "../Button"
import Cards from "../../images/cards.svg"
import { FormFlexBox, FormFlexBoxItem } from "./styles"
import SelectField from "../SelectField"
import countryList from "react-select-country-list"
import states from "states-us"
import { useAppDispatch } from "../../redux/configureStore"
import {
  confirmSubscription,
  createPayment,
  Product,
  Tax,
} from "../../redux/product"
import { useAuth0 } from "@auth0/auth0-react"
import { JobListing } from "../../redux/jobListings"
import { Alert } from "@material-ui/lab"
import { logEcommerceEvent, logEvent } from "../../utils/analytics"

const useStyles = makeStyles({
  formControl: {
    margin: "10px 0",
    "& .MuiFormHelperText-root": {
      color: "#fa755a",
    },
  },
  stripeElementContainer: {
    position: "relative",
    height: "48px",
    boxSizing: "border-box",
    borderRadius: "2px",
    backgroundColor: "#fafafa",
    padding: "10px 0",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      transition: "0.2s",
    },
    "&::before": {
      left: 0,
      right: 0,
      bottom: 0,
      content: '"\\00a0"',
      position: "absolute",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      pointerEvents: "none",
    },
    "&::after": {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: "absolute",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderBottom: "2px solid #2d2d2d",
      pointerEvents: "none",
    },
    "&.focused::after, &.error::after": {
      transform: "scaleX(1)",
    },
    "&.error::after": {
      borderBottomColor: "#f44336",
    },
  },
  stripeElement: {
    border: "none",
    width: "100%",
    backgroundColor: "transparent",
    padding: "10px",
    fontSize: "16px",
    boxSizing: "border-box",
    animationName: "mui-auto-fill-cancel",
    animationDuration: "10ms",
  },
  errorText: {
    color: "#f44c49 !important",
    fontSize: "14px !important",
    fontWeight: 700,
  },
})

const StripeTextField = ({
  id,
  component: Component,
  options,
  error,
  ...props
}: any) => {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <div
        className={`${classes.stripeElementContainer} ${
          focused ? "focused" : ""
        } ${error ? "error" : ""}`}
      >
        <Component
          id={id}
          options={options}
          className={classes.stripeElement}
          {...props}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
      {error && (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

type Props = {
  handleBack: () => void
  handleOpenDialog: () => void
  calculateTax: (
    line1: string,
    city: string,
    state: string,
    country: string,
    postalCode: string
  ) => void
  product?: Product
  job?: JobListing
  tax?: Tax
}

const StripeForm = ({
  job,
  handleBack,
  handleOpenDialog,
  calculateTax,
  product,
  tax,
}: Props) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const countryOptions = countryList()
    .getData()
    .map((option) => {
      return {
        value: option.value,
        name: option.label,
      }
    })

  const stateOptions = states.map(({ abbreviation, name }) => {
    return { value: abbreviation, name }
  })

  const [cardErrors, setCardErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  })
  const [stripeComplete, setStripeComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })
  const [stripeError, setStripeError] = useState("")

  interface FormValues {
    name: string
    line1: string
    line2?: string
    city: string
    state: string
    country: string
    postalCode: string
    phoneNumber: string
    extension?: string
  }

  const initialValues: FormValues = {
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "US",
    postalCode: "",
    phoneNumber: "",
    extension: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Card name is required"),
    line1: Yup.string().required("Address line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    postalCode: Yup.string().required("Postal code is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  })

  const handleCalculateTax = (values: FormValues) => {
    calculateTax(
      values.line1,
      values.city,
      values.state,
      values.country,
      values.postalCode
    )
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      if (!stripe || !elements || !product) {
        actions.setSubmitting(false)
        return
      }

      const { type, payload } = await dispatch(
        createPayment({
          auth,
          productId: product.id,
          jobId: job?.id,
          taxId: tax?.taxId,
        })
      )
      if (type === createPayment.fulfilled.type) {
        const clientSecret = payload.clientSecret
        const subscriptionId = payload.subscriptionId
        const cardNumber = elements.getElement("cardNumber")
        if (cardNumber) {
          const options = {
            name: values.name,
            address: {
              postal_code: values.postalCode,
              line1: values.line1,
              line2: values.line2,
              city: values.city,
              state: values.state,
              country: values.country,
            },
            phone: values.phoneNumber,
          }

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: {
                card: cardNumber,
                billing_details: options,
              },
            }
          )

          if (error) {
            setStripeError(error.message ?? "")
            return
          }

          if (paymentIntent.status === "succeeded") {
            const { type } = await dispatch(
              confirmSubscription({
                auth,
                paymentMethodId: String(paymentIntent.payment_method),
                subscriptionId,
                jobId: job?.id,
              })
            )
            if (type === confirmSubscription.fulfilled.type) {
              handleOpenDialog()
              logEcommerceEvent({
                eventName: "checkout_success",
                product,
                tax: tax?.taxRate,
              })
            }
          } else {
            logEcommerceEvent({
              eventName: "checkout_submission_unsuccessful",
              product,
              tax: tax?.taxRate,
            })
            console.log({ error: "payment error", paymentIntent })
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    actions.setSubmitting(false)
  }

  const isStripeValid =
    cardErrors.cardNumber === "" &&
    stripeComplete.cardNumber &&
    cardErrors.cardExpiry === "" &&
    stripeComplete.cardExpiry &&
    cardErrors.cardCvc === "" &&
    stripeComplete.cardCvc

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
    >
      {({ values, isSubmitting, touched, errors, isValid }) => (
        <Form>
          {stripeError && (
            <Alert severity="error" style={{ marginBottom: "20px" }}>
              {stripeError}
            </Alert>
          )}
          <Backdrop style={{ zIndex: 1000 }} open={isSubmitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <WizardStepHeaderContainer>
            <WizardHeaderText>
              <span>1. Card Information</span>{" "}
              <img src={Cards} alt="Credit Card" />
            </WizardHeaderText>
          </WizardStepHeaderContainer>
          <FormFlexBox>
            <FormFlexBoxItem>
              <label>
                Name on card <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <Field
                name="name"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={touched.name && errors.name ? errors.name : ""}
                error={touched.name && Boolean(errors.name)}
                variant="filled"
                placeholder="John Smith"
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>
                Card number <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <StripeTextField
                id="cardNumber"
                component={CardNumberElement}
                error={cardErrors.cardNumber}
                onChange={(event: any) => {
                  if (event.error) {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardNumber: event.error?.message || "",
                    }))
                  } else {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardNumber: "",
                    }))
                  }
                  setStripeComplete((prevComplete) => ({
                    ...prevComplete,
                    cardNumber: event.complete,
                  }))
                }}
              />
            </FormFlexBoxItem>
          </FormFlexBox>
          <FormFlexBox marginTop={2}>
            <FormFlexBoxItem>
              <label>
                Expiration date <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <StripeTextField
                id="cardExpiry"
                component={CardExpiryElement}
                error={cardErrors.cardExpiry}
                onChange={(event: any) => {
                  if (event.error) {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardExpiry: event.error?.message || "",
                    }))
                  } else {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardExpiry: "",
                    }))
                  }
                  setStripeComplete((prevComplete) => ({
                    ...prevComplete,
                    cardExpiry: event.complete,
                  }))
                }}
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>
                Security code <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <StripeTextField
                id="cardCvc"
                component={CardCvcElement}
                error={cardErrors.cardCvc}
                onChange={(event: any) => {
                  if (event.error) {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardCvc: event.error?.message || "",
                    }))
                  } else {
                    setCardErrors((prevErrors) => ({
                      ...prevErrors,
                      cardCvc: "",
                    }))
                  }
                  setStripeComplete((prevComplete) => ({
                    ...prevComplete,
                    cardCvc: event.complete,
                  }))
                }}
              />
            </FormFlexBoxItem>
          </FormFlexBox>

          <WizardStepHeaderContainer marginTop={4}>
            <WizardHeaderText>2. Billing Details</WizardHeaderText>
          </WizardStepHeaderContainer>

          <FormFlexBox>
            <FormFlexBoxItem>
              <label>
                Address line 1 <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <Field
                name="line1"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={touched.line1 && errors.line1 ? errors.line1 : ""}
                error={touched.line1 && Boolean(errors.line1)}
                variant="filled"
                placeholder="Street"
                onBlur={() => handleCalculateTax(values)}
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>Address line 2 </label>
              <Field
                name="line2"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={touched.line2 && errors.line2 ? errors.line2 : ""}
                error={touched.line2 && Boolean(errors.line2)}
                variant="filled"
                placeholder="Apt, suite, etc. (optional)"
              />
            </FormFlexBoxItem>
          </FormFlexBox>

          <FormFlexBox marginTop={2}>
            <FormFlexBoxItem>
              <label>
                City <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <Field
                name="city"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={touched.city && errors.city ? errors.city : ""}
                error={touched.city && Boolean(errors.city)}
                variant="filled"
                placeholder="Chicago"
                onBlur={() => handleCalculateTax(values)}
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>
                State <span style={{ color: "#3057E1" }}>*</span>
              </label>
              {values.country !== "US" ? (
                <Field
                  name="state"
                  as={TextField}
                  fullWidth
                  margin="normal"
                  helperText={touched.state && errors.state ? errors.state : ""}
                  error={touched.state && Boolean(errors.state)}
                  variant="filled"
                  onBlur={() => handleCalculateTax(values)}
                />
              ) : (
                <SelectField
                  name={"state"}
                  onFieldChange={(e: any) => (values.state = e.target.value)}
                  helperText={touched.state && errors.state ? errors.state : ""}
                  error={touched.state && Boolean(errors.state)}
                  options={stateOptions}
                  variant={"filled"}
                  onBlur={() => handleCalculateTax(values)}
                />
              )}
            </FormFlexBoxItem>
          </FormFlexBox>

          <FormFlexBox marginTop={2}>
            <FormFlexBoxItem>
              <label>
                Country <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <SelectField
                name={"country"}
                onFieldChange={(e: any) => (values.country = e.target.value)}
                helperText={
                  touched.country && errors.country ? errors.country : ""
                }
                error={touched.country && Boolean(errors.country)}
                options={countryOptions}
                variant={"filled"}
                onBlur={() => handleCalculateTax(values)}
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>
                Postal code <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <Field
                name="postalCode"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={
                  touched.postalCode && errors.postalCode
                    ? errors.postalCode
                    : ""
                }
                error={touched.postalCode && Boolean(errors.postalCode)}
                variant="filled"
                placeholder="60611"
                onBlur={() => handleCalculateTax(values)}
              />
            </FormFlexBoxItem>
          </FormFlexBox>

          <FormFlexBox marginTop={2}>
            <FormFlexBoxItem>
              <label>
                Phone number <span style={{ color: "#3057E1" }}>*</span>
              </label>
              <Field
                name="phoneNumber"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={
                  touched.phoneNumber && errors.phoneNumber
                    ? errors.phoneNumber
                    : ""
                }
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                variant="filled"
                placeholder="1254567890"
              />
            </FormFlexBoxItem>
            <FormFlexBoxItem>
              <label>Extension</label>
              <Field
                name="extension"
                as={TextField}
                fullWidth
                margin="normal"
                helperText={
                  touched.extension && errors.extension ? errors.extension : ""
                }
                error={touched.extension && Boolean(errors.extension)}
                variant="filled"
                placeholder="1"
              />
            </FormFlexBoxItem>
          </FormFlexBox>

          <PaymentButtonContainer>
            <Button
              children={"Pay now"}
              color={"secondary"}
              type="submit"
              size={"medium"}
              disabled={isSubmitting || !isValid || !isStripeValid}
              style={{
                height: "48px",
                width: "138px",
              }}
            />
            <Button
              children={"Back to previous"}
              color={"whiteGreyBorder"}
              onClick={async () => {
                handleBack()
                logEvent("return_to_pricing_page")
              }}
              size={"medium"}
              style={{
                height: "48px",
                width: "138px",
              }}
            />
          </PaymentButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

export default StripeForm
