import React from "react"
import {
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/DoneAllTwoTone"
import RevertIcon from "@material-ui/icons/NotInterestedOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import { EditableProjectMainFeature } from "./index"
// import TextField from "../TextField"
import { ActionsTableCell } from "./styles"
import moment from "moment"

export type Props = {
  error?: any
  mainFeature: EditableProjectMainFeature
  onEdit: () => void
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
  onChange: (
    date: any | null,
    property: keyof EditableProjectMainFeature
  ) => void
  onDateChange: (
    date: Date | null,
    property: keyof EditableProjectMainFeature
  ) => void
}

const View = ({
  error,
  mainFeature,
  onSave,
  onDelete,
  onCancel,
  onChange,
  onEdit,
  onDateChange,
}: Props) => {
  if (!mainFeature.editing) {
    return (
      <TableRow>
        <TableCell>
          {mainFeature.heroTitle
            ? mainFeature.heroTitle
            : "No custom hero title set"}
        </TableCell>
        <TableCell>
          {moment(mainFeature.startDate).format("MMM Do YY")}
        </TableCell>
        <TableCell>{moment(mainFeature.endDate).format("MMM Do YY")}</TableCell>
        <ActionsTableCell>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </ActionsTableCell>
      </TableRow>
    )
  }

  return (
    <TableRow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TableCell component="th" scope="row">
          <TextField
            name="heroTitle"
            label="Hero Title"
            data-testid="heroTitle"
            onChange={({ currentTarget }) =>
              onChange(currentTarget.value, "heroTitle")
            }
            error={error !== null}
            helperText={error ? error : null}
          />
          <Typography
            variant="body2"
            style={{
              float: "right",
              color:
                error &&
                mainFeature.heroTitle &&
                mainFeature.heroTitle.length > 62
                  ? "#f44336"
                  : "",
            }}
          >
            {mainFeature.heroTitle?.length}/62 characters
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <KeyboardDatePicker
            style={{ paddingBottom: "25px" }}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={mainFeature.startDate}
            onChange={(date) => onDateChange(date, "startDate")}
            autoOk={true}
          />
        </TableCell>
        <TableCell>
          <KeyboardDatePicker
            style={{ paddingBottom: "25px" }}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={mainFeature.endDate}
            onChange={(date) => onDateChange(date, "endDate")}
            autoOk={true}
          />
        </TableCell>
        <ActionsTableCell>
          <>
            <IconButton onClick={onSave}>
              <DoneIcon />
            </IconButton>
            {mainFeature.id && (
              <IconButton onClick={onCancel}>
                <RevertIcon />
              </IconButton>
            )}
          </>
        </ActionsTableCell>
      </MuiPickersUtilsProvider>
    </TableRow>
  )
}

export default View
