import React from "react"

import { Project } from "../../redux/projects"
import {
  IAPeopleChoiceBadge,
  IAWinnersCarouselBorderedContainer,
  IAWinnersCarouselBorderedProjectBox,
  IAWinnersProject,
  IAWinnersThumbnail,
  PeopleChoiceLabel,
  ProfileName,
  ProjectName,
  StyledLink,
  ImageThumbnail,
  ImageThumbnailContainer,
  IAWinnersProjectFooter,
} from "./styles"

import { LinkBox } from "../../cards/ProjectCard/styles"
import { normalizeProjectTitle } from "../../utils"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Competition } from "../../redux/competition"
import WeekendCompetititonLogo from "../../images/innovation-awards/weekend-competition-logo-white.svg"
import WeekendCompetititonMobileLogo from "../../images/innovation-awards/wk-badge.svg"

interface Props {
  project: Project
  label?: string
  competition?: Competition
}

const CarouselProjectCard = ({ project, label, competition }: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  let name = project.profile?.groupName
  if (project.profile?.type !== "group")
    name = project.profile?.firstName + " " + project.profile?.lastName

  const projectURL = `/sp/${
    project.username
  }/projects/${project.id!}/${normalizeProjectTitle(project.name)}`

  const reward = project?.rewards?.find(
    ({ campaign }) => campaign === competition?.code
  )

  const renderLabel = (prize?: string) => {
    if (prize === "1st-place")
      return (
        <>
          1<sup>st</sup> PLACE
        </>
      )

    if (prize === "2nd-place")
      return (
        <>
          2<sup>nd</sup> PLACE
        </>
      )

    if (prize === "3rd-place")
      return (
        <>
          3<sup>rd</sup> PLACE
        </>
      )

    if (prize === "people-choice-award")
      return (
        <>
          PEOPLE’S
          <br />
          CHOICE
        </>
      )

    return (
      <>
        <b>Winners</b>
        <br />
        {competition?.date}
      </>
    )
  }

  const collaborators = project.collaborators?.filter(
    (collab) => collab.status !== "pending"
  )

  return (
    <IAWinnersCarouselBorderedContainer>
      <IAWinnersCarouselBorderedProjectBox>
        <IAWinnersProject>
          <IAPeopleChoiceBadge>
            <img
              src={
                isMobile
                  ? WeekendCompetititonMobileLogo
                  : WeekendCompetititonLogo
              }
              alt={competition?.title}
            />
            <PeopleChoiceLabel>{renderLabel(reward?.prize)}</PeopleChoiceLabel>
          </IAPeopleChoiceBadge>

          <IAWinnersThumbnail>
            <LinkBox to={projectURL}>
              <ImageThumbnailContainer>
                <ImageThumbnail src={project.imageUrl} alt={project.name} />
              </ImageThumbnailContainer>
            </LinkBox>
          </IAWinnersThumbnail>
        </IAWinnersProject>
        <IAWinnersProjectFooter>
          <ProjectName style={{ fontSize: isMobile ? "16px" : "24px" }}>
            {project.firmName}
          </ProjectName>
          <ProjectName>
            <StyledLink to={projectURL}>{project.name}</StyledLink>
          </ProjectName>
          <ProfileName>
            {collaborators && collaborators?.length > 0 ? (
              <>
                Multiowner (
                <StyledLink to={`/sp/${project.profile?.username}`}>
                  {name}
                </StyledLink>
                ,&nbsp;
                {collaborators.map((collaborator, key) => {
                  let collaboratorName = collaborator.profile?.groupName
                  if (collaborator.profile?.type !== "group")
                    collaboratorName =
                      collaborator.profile?.firstName +
                      " " +
                      collaborator.profile?.lastName

                  if (collaborator.status === "pending") return null

                  return (
                    <StyledLink to={`/sp/${collaborator.profile?.username}`}>
                      {collaboratorName}
                      {collaborators && key < collaborators.length - 1 && ", "}
                    </StyledLink>
                  )
                })}
                )
              </>
            ) : (
              <>
                <StyledLink to={`/sp/${project.profile?.username}`}>
                  {name}
                </StyledLink>
              </>
            )}
          </ProfileName>
        </IAWinnersProjectFooter>
      </IAWinnersCarouselBorderedProjectBox>
    </IAWinnersCarouselBorderedContainer>
  )
}

export default CarouselProjectCard
