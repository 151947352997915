import styled from "styled-components"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import BlueCheckmark from "../../images/benefit-check-active.svg"
import GreyCheckmark from "../../images/benefit-check.svg"

import theme, { colors } from "../../theme"

export const PageContainer = styled(Box)`
  background: #f7f7f7;
  margin-bottom: -64px;
  margin-top: -40px;
  padding: 120px;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
    margin-top: 0;
    padding: 0;
  }
`

// ============================
// WIZARD CONTAINER & CONTENTS
// ============================
export const WizardContainer = styled(Box)`
  border-radius: 3px;
  flex: 7;
  height: fit-content;
  margin-bottom: 64px;

  ${theme.breakpoints.down("sm")} {
    margin-left: 0;
    margin-bottom: 0;
    max-width: none;
  }
`

export const WizardStepHeaderContainer = styled(Box)`
  border-bottom: solid 0.5px #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 32px;

  ${theme.breakpoints.down("sm")} {
    height: 64px;
  }
`

export const WizardPlanHeaderContainer = styled(Box)`
  border-bottom: solid 0.5px #d8d8d8;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 32px 16px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    height: 64px;
  }
`

export const WizardHeaderText = styled(Typography)`
  font-size: 25px;
  font-weight: 350;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`

export const WizardBgBox = styled(Box)`
  background: #ffffff;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  ${theme.breakpoints.down("sm")} {
    margin: 32px 0;
  }
`

export const WizardBodyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  width: 100%;
`

export const WizardContentHeaderText = styled(Typography)`
  display: flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  height: fit-content;
  letter-spacing: -0.005em;
  font-family: Public-Sans-Bold;

  ${theme.breakpoints.down("sm")} {
    font-size: 18px;
  }
`

export const WizardContentText = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: fit-content;
  line-height: 22px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const PlanOptionsContainer = styled(Box)`
  display: flex;
  margin: 0 20px 40px;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`

export const PlanOptionColumn = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: solid 1px #080808;
  border-radius: 6px;
  position: relative;

  && button {
    margin-top: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 0;
  }
`

export const BestOptionBox = styled(Box)`
  position: absolute;
  background-color: #080808;
  color: #fff;
  font-size: 14px;
  width: 106px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -14px;
  left: 50%;
  transform: translateX(-50%);
`

export const PlanOptionBox = styled(Box)`
  display: flex;
  flex-flow: column;
  margin: 0 12px;
  padding: 24px 24px 22px;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 18px 24px 22px;
  }
`

// Handles responsive re-sizing of plan options boxes from PLAN step
export const BottomPlanOptionBox = styled(Box)`
  padding: 0 24px 22px;
`

export const PlanOptionUl = styled.ul`
  margin: 0;
  padding: 0 8px;
`

export const PlanOptionLi = styled.li<{ $isActive?: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: fit-content;
  line-height: 22px;
  padding: 5px 0 6px 24px;

  list-style-type: none;

  ${({ $isActive }) =>
    $isActive
      ? `
      background: url(${BlueCheckmark}) no-repeat;
      color: #2D2D2D;
    `
      : `
      background: url(${GreyCheckmark}) no-repeat;
      color: #C4C4C4;
    `};

  background-position: 0px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

// ==================================
// BUDGET STEP CONTAINERS AND CONTENT
// ==================================
export const BudgetContentHeaderContainer = styled(Box)`
  border: solid #3057e1 1px;
  border-radius: 3px 3px 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 34px;
  padding: 19px 24px 21px;

  ${theme.breakpoints.down("sm")} {
    margin: 0 20px;
    padding: 19px 24px 22px;
  }
`

export const BudgetContentContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 34px 32px;

  border: solid 1px #3057e1;
  border-radius: 0 0 3px 3px;
  border-top: none;
  min-height: 100px;

  ${theme.breakpoints.down("sm")} {
    border: none;
    flex-direction: column;
    margin: 0 20px;
  }
`

export const BudgetContentRow = styled(Box)`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  padding: 31px 25px 28px;

  ${theme.breakpoints.down("sm")} {
    border: solid 1px #3057e1;
    border-radius: 0 0 3px 3px;
    border-top: none;
    flex-direction: column;
    padding: 23px 25px 20px;
  }
`

export const BudgetContentColumn = styled(Box)`
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
`

export const DurationFormControl = styled(FormControl)`
  margin: auto 0 13px;

  && {
    .MuiFilledInput-root:hover {
      background: none !important;
    }
    .MuiFilledInput-root.Mui-focused {
      background: none !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    margin: 14px 0 20px;
  }
`

export const DurationSelect = styled(Select)`
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 1px;
  color: #2d2d2d;
  font-size: 14px;
  height: 40px;

  && {
    .MuiSelect-select:focus {
      background: none;
    }

    .MuiFilledInput-input {
      align-items: center;
      display: flex;
      padding: 0 0 0 13px;
    }
  }
`

export const DurationMenuItem = styled(MenuItem)`
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 14px;
  margin: 0;
  padding: 16px 13px;

  && {
    &.Mui-disabled {
      color: #c4c4c4;
      opacity: 1;
    }
  }
`

export const BudgetFooter = styled(Box)`
  background-color: #ebeffc;
  display: block;
  margin: 0 25px 27px;
  padding: 23px 26px 24px;

  ${theme.breakpoints.down("sm")} {
    margin: 20px 0;
    padding: 17px 20px 19px;
  }
`

export const ErrorText = styled(Typography)`
  color: #f44c49;
  display: contents;
  font-family: Public-Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: -13px;
`

// ===================================
// PAYMENT STEP CONTAINERS AND CONTENT
// ===================================
export const ReviewOrderContainer = styled(Box)`
  border: solid 0.5px ${colors.mediumGray};
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  margin: 33px;

  ${theme.breakpoints.down("sm")} {
    margin: 25px 21px 33px;
  }
`

export const ReviewOrderHeaderContainer = styled(Box)`
  border-bottom: solid 0.5px ${colors.mediumGray};
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  padding-left: 25px;

  ${theme.breakpoints.down("sm")} {
    // TODO might need to tweak for SP-1148 after copy changes
    height: 67px;
  }
`

export const PaymentButtonContainer = styled(Box)`
  align-items: center;
  border-top: solid 0.5px ${colors.mediumGray};
  display: flex;
  gap: 32px;
  padding: 32px 0;
  margin-top: 32px;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 16px;

    padding: 32px 0 0;

    && button {
      width: 100% !important;
    }
  }
`

// ============================
// FAQ CONTAINER & CONTENTS
// ============================
export const FaqContainer = styled(Box)`
  flex: 3;
  height: fit-content;
  margin-bottom: 105px;
  background: #fff;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: -64px;

  ${theme.breakpoints.down("sm")} {
    max-width: none;
    padding: 36px 30px 50px;
    margin-bottom: -20px;
  }
`

export const FaqBox = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
`

export const JobTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
`

export const FirmLocation = styled(Typography)`
  color: #717171;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: -2px;
  margin-top: 16px;
`

export const FaqTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 350;
  line-height: 28px;
  margin-top: -5px;
  margin-bottom: 20px;
`

export const FaqAccordian = styled(Accordion)`
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 16px;
  position: inherit;

  && {
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px;
    }
  }
`

export const FaqSummary = styled(AccordionSummary)`
  padding: 0 20px;

  && {
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  }
`

export const FaqSummaryText = styled(Typography)`
  color: #2d2d2d;
  font-family: Public-Sans-Medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
  margin-right: 5px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const FaqDetails = styled(AccordionDetails)`
  padding: 0 52px 20px 20px;
`

export const FaqDetailsText = styled(Typography)`
  color: #717171;
  font-size: 16px;
  font-weight: 350;
  line-height: 22px;
  margin-top: -3px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const CheckoutBox = styled(Box)`
  display: flex;
  gap: 32px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`
export const FormContainer = styled(Box)`
  padding: 32px;
  background: #fff;
  width: 100%;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    margin-top: 32px;
  }
`

export const SummaryBox = styled(Box)`
  max-width: 374px;
  width: 100%;
  background: #fff;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px;

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
    margin-bottom: 32px;
  }
`

export const SummaryTitle = styled(Typography)`
  font-size: 18px;
  font-family: Public-Sans-Bold;
`

export const SummaryDivider = styled(Box)`
  height: 1px;
  width: 100%;
  background-color: #cecece;
  margin: 16px 0;
`

export const SummarySpacing = styled(Box)`
  width: 100%;
  margin: 16px 0;
`

export const SummaryItem = styled(Box)`
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 16px;

  && button {
    height: 48px;
  }

  && .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }

  && .MuiFormControl-root {
    margin: 0;
  }
`

export const ButtonsBox = styled(Box)`
  padding: 0 20px 32px;

  ${theme.breakpoints.down("sm")} {
    && button {
      width: 100% !important;
    }
  }
`
