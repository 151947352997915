import React, { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import LoadingPage from "../LoadingPage"

import View from "./View"

import { useAppDispatch, AppState } from "../../redux/configureStore"
import {
  createJobListing,
  JobListing,
  getJobListingById,
  updateJobListing,
  clearSelectedJob,
} from "../../redux/jobListings"
import * as RouteConstants from "../../constants/routes"
import { JobFormType } from "../../enums/JobFormType"
import { normalizeProjectTitle } from "../../utils"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

const JobListingWizardPage = () => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = React.useState(false)
  const profile = useSelector((state: any) => state.profile.profile)
  const job = useSelector((state: AppState) => state.jobListings.selectedJob)
  const { id, type } = useParams<{
    id: string
    type: JobFormType
  }>()

  const formType: JobFormType = type ? type : JobFormType.NEW

  const loadJob = useCallback(async () => {
    try {
      setLoading(true)
      if (formType === JobFormType.NEW) {
        const { type } = await dispatch(clearSelectedJob())
        if (clearSelectedJob.fulfilled.type === type) setLoading(false)
      } else {
        const { type } = await dispatch(getJobListingById({ id, auth }))
        if (getJobListingById.fulfilled.type === type) {
          setLoading(false)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, id, auth, formType])

  useEffect(() => {
    loadJob()
  }, [loadJob])

  const handleCancel = () => history.goBack()

  const jobUrl = (job: JobListing) => {
    return `${RouteConstants.PROFILE_CONTAINER}/${
      profile?.username
    }/job_listings/${job.id}/${normalizeProjectTitle(job.title)}`
  }

  const handleSubmit = async (jobsData: JobListing) => {
    setLoading(!loading)
    try {
      const jobListing = { ...jobsData }
      if (formType === JobFormType.EDIT) {
        const { type } = await dispatch(updateJobListing({ jobListing, auth }))
        if (updateJobListing.fulfilled.type === type) {
          history.push(jobUrl(jobListing))
        }
      } else {
        if (formType === JobFormType.REPOST) jobListing.status = "open"

        const { payload, type } = await dispatch(
          createJobListing({ jobListing, auth })
        )

        if (createJobListing.fulfilled.type === type && payload.id)
          history.push(`/job_listings/${payload.id}/promotion/start`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (loading) return <LoadingPage />

  return (
    <>
      <RouteChangeTracker
        screenTitle={`${id ? "Edit" : "New"} Job`}
        classTitle={`${id ? "EditJob" : "NewJob"}`}
      />
      <Helmet>
        <meta
          name="title"
          content={`${id ? "Edit" : "New"} Job - Spectacular`}
        />
        <meta
          property="og:title"
          content={`${id ? "Edit" : "New"} Job - Spectacular`}
        />
        <meta property="og:url" content={window.location.href} />
        <title>{`${id ? "Edit" : "New"} Job - Spectacular`}</title>
      </Helmet>
      <View
        profile={profile}
        jobListing={job}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        type={formType}
      />
    </>
  )
}

export default JobListingWizardPage
