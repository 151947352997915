import React from "react"
import View from "./View"
import { PostComment } from "../../redux/posts"
import { Comment } from "../../redux/projects"

type Props = {
  comment: Comment | PostComment
  handleDeleteDialogOpen: (
    comment: Comment | PostComment,
    value: boolean
  ) => void
  handleEditDialogOpen: (comment: Comment | PostComment, value: boolean) => void
}

const CommentDotMenu = ({
  comment,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
}: Props) => {
  const handleDelete = async () => {
    handleDeleteDialogOpen(comment, true)
  }

  const handleEdit = async () => {
    handleEditDialogOpen(comment, true)
  }

  return <View onDelete={handleDelete} onEdit={handleEdit} />
}

export default CommentDotMenu
