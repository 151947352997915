import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import Footer from "../components/Footer"
import { Profile } from "../redux/profile"
import UserRoutes from "./user-routes"
import AdminRoutes from "./admin-routes"
import SomethingWentWrong from "../components/SomethingWentWrong"
import Navbar from "../components/Navbar"
import ScrollToTop from "../components/ScrollToTop"
import NotificationPopUp from "../components/NotificationPopUp"
import { useAppDispatch } from "../redux/configureStore"
import { getIntercomUserHash } from "../redux/profile"
import { useAuth0 } from "@auth0/auth0-react"
import CompleteProfilePopUp from "../components/CompleteProfilePopUp"

interface Props {
  profile: Profile
}

export const AuthenticatedRoutes = ({ profile }: Props) => {
  const location = useLocation()
  const { boot: bootIntercom } = useIntercom()
  const isAdminRoute =
    profile?.isAdmin && location.pathname.startsWith("/admin")
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  useEffect(() => {
    if (profile) {
      let fullName = profile.firstName + profile.lastName

      if (typeof fullName === "string") {
        fullName = (profile.firstName + profile.lastName).trim()
      }
      ;(async () => {
        try {
          const intercom = await dispatch(getIntercomUserHash({ auth }))
          bootIntercom({
            email: profile.email,
            name: fullName,
            userHash: intercom.payload.hash,
          })
        } catch (error) {
          console.error(error)
        }
      })()
    }
    // eslint-disable-next-line
  }, [bootIntercom, auth, dispatch])

  return (
    <>
      <ScrollToTop />
      {profile && (isAdminRoute ? <AdminRoutes /> : <UserRoutes />)}
      {!profile && (
        <>
          <Navbar />
          <SomethingWentWrong />
        </>
      )}
      <NotificationPopUp />
      {profile.showCompleteProfilePopUp ? (
        <CompleteProfilePopUp profile={profile} />
      ) : null}
      <Footer />
    </>
  )
}
