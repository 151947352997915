import React from "react"
import Image from "material-ui-image"
import { Box, Grid, Typography, Button } from "@material-ui/core"

import SquarePicture from "../../components/SquarePicture"
import CoverSrc from "../../images/about-cover.jpg"
import {
  MainContainer,
  TextContainer,
  Text,
} from "../../components/Styles/StaticPages"
import HeathMaySrc from "../../images/bio/HeathMay.png"
import SchendelSrc from "../../images/bio/Schendel.png"
import LaureMichelonSrc from "../../images/bio/LaureMichelon.png"
import LaurenStokesSrc from "../../images/bio/LaurenStokes.png"
import LeeGreenbergSrc from "../../images/bio/LeeGreenberg.jpg"
import ShawnGehleSrc from "../../images/bio/ShawnGehle.png"
import SainaXiangSrc from "../../images/bio/SainaXiang.png"
import SamuelBagerSrc from "../../images/bio/SamuelBager.png"
import JenniferPark from "../../images/bio/JenniferPark.png"
import BethBailey from "../../images/bio/BethBailey.png"
import BurakUnel from "../../images/bio/BurakUnel.jpg"
import { BioBox, FlexBox, ImageBox } from "./styles"

type Props = {
  goToMembersBenefits: () => void
}

const View = ({ goToMembersBenefits }: Props) => (
  <MainContainer>
    <Image src={CoverSrc} aspectRatio={16 / 9} disableSpinner />
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <Box pb={4}>
            <Typography variant="h1">About Us</Typography>
          </Box>
          <Typography variant="h4">
            Spectacular is where firms find their next great talent and
            architects make their next career move.
          </Typography>
          <Text py={2}>
            Our mission is to help architects thrive in their careers, across
            the globe. Opening avenues for networking and mentors at every stage
            of the architect’s career life cycle is crucial to achieving that
            goal. With Spectacular, we’re reinventing how architects and firms
            connect with each other through a shared appreciation of their work.
            In forging these working relationships, we help shape the future of
            your career so you are free to shape the future of the world around
            us.
          </Text>
          <Text py={2}>
            Spectacular builds on the mission of&nbsp;
            <a href="https://go.blackspectacles.com/">Black Spectacles</a>, to
            provide support and inspiration to architects through world-class
            educational material and career resources. Founder Marc Teer started
            Black Spectacles in 2010 to make Software Learning more accessible
            to architects, adding now NCARB-approved ARE Test Prep to the course
            offerings.&nbsp;
            <a href="https://go.blackspectacles.com/about">
              Read about his motivation
            </a>
            &nbsp;to create spaces for architects by architects, to remove
            barriers to career expansion, so everyone can experience
            professional growth.
          </Text>
          <Box pt={2} pb={5} display="flex" justifyContent="center">
            <Button
              color="primary"
              data-testid="gotomembers"
              onClick={goToMembersBenefits}
            >
              Learn about member benefits
            </Button>
          </Box>
          <Box py={2}>
            <Typography variant="h2">Meet our advisory board </Typography>
          </Box>
          <Text py={2}>
            As we prepared to launch Spectacular in July 2021, Spectacular
            wanted to hear from a variety of professionals and students with
            diverse viewpoints through a new Product Advisory Board. This
            advisory board met several times in Q1/Q2 of 2021 and provided
            feedback on Spectacular as well as information about emerging trends
            and practices in the architecture industry. Their valuable ideas
            helped us shape Spectacular and launch it to the Architecture
            community.
          </Text>

          <Box py={2}>
            <FlexBox>
              <BioBox>
                <ImageBox>
                  <SquarePicture src={HeathMaySrc} alt="Heath May" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Heath May</Typography>
                  <Text py={2}>
                    Heath May AIA is Principal and Director of HKS Laboratory
                    for INtensive Exploration. He leads a studio responsible for
                    projects that have been recognized for progressive design
                    with numerous awards. His work and research include
                    data-driven design optimization, simulation, and digital
                    design, fabrication and delivery. Heath has taught advanced
                    graduate design studio as an Adjunct Assistant Professor at
                    University of Texas Arlington, CAPPA, School of
                    Architecture. Practicing across multiple sectors, recent
                    projects featuring the design and/or collaboration of LINE
                    include SoFi Stadium, Pacific Plaza Pavilion, and
                    Alcon-Novartis Café. He practices in San Francisco, with a
                    current focus on collaborative digital platforms.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture
                    src={SchendelSrc}
                    alt="Jerónimo van Schendel"
                  />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Jerónimo van Schendel</Typography>
                  <Text py={2}>
                    Jerónimo van Schendel is a Spanish architect and
                    entrepreneur. He is the Co-founder and CEO of www.bildia.com
                    and the Director of the Master in Business for Architecture
                    and Design at IE University. He holds an M.Arch II with
                    Distinction, by the Harvard Graduate School of Design
                    (2016).
                  </Text>
                </Box>
              </BioBox>
              <BioBox>
                <ImageBox>
                  <SquarePicture src={LaureMichelonSrc} alt="Laure Michelon" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Laure Michelon</Typography>
                  <Text py={2}>
                    Laure Michelon is an architectural technologist and designer
                    with a focus in architecture, machine learning, energy
                    analysis, and fashion. She has a M.S. in Architectural
                    Technologies from SCI-Arc and a B.S. in Civil Engineering
                    from Columbia University SEAS.stinction, by the Harvard
                    Graduate School of Design (2016).
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={LaurenStokesSrc} alt="Lauren Stokes" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Lauren Stokes</Typography>
                  <Text py={2}>
                    Lauren Stokes graduated Magna Cum Laude from Iowa State with
                    a Bachelor of Architecture and minor in digital media in
                    November 2020. She joined the team at RDG Planning & Design
                    in Des Moines, IA as an architectural intern and is
                    currently working toward gaining her architectural license.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={SamuelBagerSrc} alt="Samuel Bager" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Samuel Bager</Typography>
                  <Text py={2}>
                    Samuel is currently pursuing a Master of Architecture at
                    Columbia University GSAPP in New York after receiving my
                    B.F.A. in Architecture from SCAD. He is passionate about
                    designing architectural moments and spaces that solve more
                    problems than they create.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={SainaXiangSrc} alt="Saina Xiang" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Saina Xiang</Typography>
                  <Text py={2}>
                    Saina Xiang is an internationally experienced architectural
                    designer. After recently receiving her M.Arch Degree from
                    the University of Pennsylvania and Certificate in Real
                    Estate and Development from Wharton School, she will work as
                    a junior architectural designer at SOM Chicago.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={ShawnGehleSrc} alt="Shawn Gehle" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Shawn Gehle</Typography>
                  <Text py={2}>
                    Shawn Gehle, Co-Founder and Principal at OFFICEUNTITLED
                    (OU), is a recognized design and thought leader whose
                    diverse experience includes work for technology, creative
                    workplace, education, and mixed-use clients. Gehle is
                    responsible for the conception and design of AVA LA Arts
                    District, Cayton Children’s Museum by Sharewell, and
                    multiple large-scale creative and mixed-use masterplans.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={LeeGreenbergSrc} alt="Lee Greenberg" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Lee Greenberg</Typography>
                  <Text py={2}>
                    Lee is an Architect in Chicago who believes that everyone
                    deserves good design. He also believes that well
                    thought-out, meaningful projects are the outcome of strong
                    teams who understand the natural goals of the project, the
                    client, and the site. Design should create a positive impact
                    on people's lives by transforming their environment. His
                    experience includes hospitality, corporate office buildings
                    and headquarters, community, residential housing, and master
                    planning.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={JenniferPark} alt="Jennifer Park" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Jennifer Park</Typography>
                  <Text py={2}>
                    Jennifer Park is a Principal at Chicago-based Brininstool +
                    Lynch. Park is the recipient of the AIA National Young
                    Architects Award and AIA Chicago Dubin Family Young
                    Architect Award. She teaches as a Studio Assistant Professor
                    and Second Year Coordinator. She also leads committees on
                    Diversity and Inclusion in both academia and the profession.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={BethBailey} alt="Beth Bailey" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Beth Bailey</Typography>
                  <Text py={2}>
                    Beth Bailey is the Talent Manager and Senior Associate
                    within the Chicago Studio of Perkins&Will, and is
                    responsible for the management of the full employee
                    lifecycle ranging from recruiting, onboarding, and growth
                    and development. Ms. Bailey has over 15 years of HR
                    experience, with a passion towards bringing education and
                    awareness of J.E.D.I. (Justice, Equity, Engagement,
                    Diversity, and Inclusion).
                  </Text>
                  <Text>
                    Prior to joining Perkins&Will, Ms. Bailey worked as the
                    Human Resources leader for GBBN in Cincinnati, Ohio, a
                    mid-size design firm.
                  </Text>
                  <Text py={2}>
                    Ms. Bailey attended Northern Kentucky University, and has
                    achieved her SHRM-SCP certification.
                  </Text>
                </Box>
              </BioBox>

              <BioBox>
                <ImageBox>
                  <SquarePicture src={BurakUnel} alt="Fahir Burak Unel" />
                </ImageBox>
                <Box>
                  <Typography variant="h3">Fahir Burak Unel</Typography>
                  <Text py={2}>
                    Burak is a licensed architect, educator, and computational
                    technology lead with over a decade of experience practicing
                    with prominent international firms as well as volunteering
                    as designer and consultant for regional NGOs that empower
                    disenfranchised communities through construction, design,
                    and advocacy.
                  </Text>
                  <Text>
                    He holds a B.Arch. degree from Syracuse University, an M.
                    Arch. II degree in advanced building technologies from
                    Cornell University, and currently works in New York City at
                    Ennead Architects. He further dedicates his time to
                    mentorship programs at the national level, and works in
                    collaboration with organizations like NCARB to tackle issues
                    of education, licensure, and DEI in architecture, while
                    contributing think-pieces to architectural publications like
                    Metropolis magazine. He is Co-chair of the Emerging New York
                    Architects (ENYA) Committee at AIA New York, a member of the
                    Civic Leadership Program (CLP) Advisory Taskforce, and a
                    member of the TORCH Executive Committee.
                  </Text>
                </Box>
              </BioBox>
            </FlexBox>
          </Box>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
