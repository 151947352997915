import React from "react"
import View from "./view"
import { Post } from "../../redux/posts"

type Props = {
  post: Post
  small?: boolean
}

const PostContentText = ({ post, small }: Props) => {
  return <View post={post} small={small} />
}

export default PostContentText
