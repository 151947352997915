import React from "react"
import * as Yup from "yup"
import { DatePicker } from "@material-ui/pickers"
import { FormikContextType, useFormikContext } from "formik"
import {
  Box,
  Typography,
  LinearProgress,
  FormHelperText,
} from "@material-ui/core"

import { Project } from "../../redux/projects"

import { Profile } from "../../redux/profile"

import projectStates from "../../constants/project-states"
import projectTypes from "../../constants/project-types"

import TextFieldLabelAbove from "../../components/TextFieldLabelAbove"
import SelectFieldLabelAbove from "../../components/SelectFieldLabelAbove"
import ProjectTypology from "../../components/ProjectTypology"
import ProjectKeyMaterials from "../../components/ProjectKeyMaterials"
import ProjectToolSelector from "../../components/ProjectToolSelector"

import { WizardStep, StepProps } from "./Wizard"

import { StyledLinkBox, StyledLink, Title, DatePickerBox } from "./styles"
import { FormSpacingBox } from "../InnovationAwardPage/styles"

const MAX_CHAR_COUNT = 3000

interface Props extends StepProps {
  isUploading: boolean
  currentFile: string
  profile: Profile
  typeSelected: string
  onTypeSelected: (typeSelected: string) => void
}

const Step2: React.FC<Props> = ({
  onCancel,
  isUploading,
  currentFile,
  profile,
  typeSelected,
  onTypeSelected,
}) => {
  const formik: FormikContextType<Project> = useFormikContext()

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
  }

  const toggleTypeSelected = (_event: React.ChangeEvent<{ value: any }>) => {
    onTypeSelected(String(_event))
  }

  const isProjectComplete = formik.values.state === "complete"
  const isProjectInProgress = formik.values.state === "in_progress"

  React.useEffect(() => {}, [isUploading])

  return (
    <WizardStep>
      <>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography tabIndex={0} variant="h3">
            Project Details
          </Typography>
          <Box mt={3}>
            <StyledLinkBox onClick={onCancel}>
              <StyledLink>Cancel</StyledLink>
            </StyledLinkBox>
          </Box>
        </Box>
        <Typography>
          <span style={{ color: "#ff0000" }}>*</span> = Required
        </Typography>
        <FormSpacingBox>
          <TextFieldLabelAbove
            fullWidth
            asterisk={true}
            name="name"
            placeholder="Give your project a title"
            displayTitle="Title"
            data-testid="project-name-field"
            style={{ height: "48px" }}
          />
        </FormSpacingBox>
        <FormSpacingBox>
          <TextFieldLabelAbove
            name="description"
            displayTitle="Project description"
            placeholder="Tell us about your project"
            asterisk={true}
            multiline={true}
            rows={12}
            rowsMax={24}
            data-testid="project-description-field"
            value={formik.values.description}
            onChange={formik.handleChange}
            onInput={characterCounter}
          />
          <Typography variant="body2" style={{ float: "right" }}>
            {formik.values.description.length}/{MAX_CHAR_COUNT} characters
          </Typography>
        </FormSpacingBox>

        {profile.type !== "group" && (
          <>
            <FormSpacingBox>
              <SelectFieldLabelAbove
                name="type"
                label="Type of project"
                asterisk={true}
                options={projectTypes}
                blank="Is this a firm, school or individual project?"
                data-testid="project-type-field"
                onChange={(event: any) => {
                  toggleTypeSelected(event)
                }}
              />
            </FormSpacingBox>

            {typeSelected === "firm" && (
              <FormSpacingBox>
                <TextFieldLabelAbove
                  name="firmName"
                  displayTitle="Firm Name"
                  placeholder="Tell us the name of the firm"
                  asterisk={true}
                  data-testid="project-firm-name-field"
                  style={{ height: "48px" }}
                />
              </FormSpacingBox>
            )}

            {typeSelected === "school" && (
              <FormSpacingBox>
                <TextFieldLabelAbove
                  name="firmName"
                  displayTitle="School Name"
                  placeholder="Tell us the name of the school"
                  asterisk={true}
                  data-testid="project-firm-name-field"
                  style={{ height: "48px" }}
                />
              </FormSpacingBox>
            )}
          </>
        )}

        <FormSpacingBox>
          <SelectFieldLabelAbove
            name="state"
            label="Project status"
            asterisk={true}
            options={projectStates}
            blank="Select project status"
            data-testid="project-state-field"
            onChange={(e: any) => {
              formik.setFieldValue("stateDate", null)

              if (e) formik.setFieldValue("state", e)
              else if (e === "") formik.setFieldError("state", "Required")
            }}
          />
        </FormSpacingBox>
        {(formik.values.state === "complete" ||
          formik.values.state === "in_progress") && (
          <FormSpacingBox>
            <Title>
              {isProjectComplete
                ? "Date completed"
                : "Estimated completion date"}
              <span>*</span>
            </Title>
            <DatePickerBox>
              <DatePicker
                autoOk
                openTo="month"
                maxDate={isProjectComplete ? new Date() : undefined}
                minDate={isProjectInProgress ? new Date() : undefined}
                views={["month", "year"]}
                format="MM/yyyy"
                value={formik.values.stateDate}
                placeholder={"MM/YYYY"}
                error={
                  formik.errors.stateDate !== undefined &&
                  formik.getFieldMeta("stateDate").touched
                }
                onChange={(newValue) =>
                  formik.setFieldValue(
                    "stateDate",
                    newValue
                      ? new Date(newValue?.toDateString() as string)
                      : undefined
                  )
                }
              />
            </DatePickerBox>
            {formik.errors.stateDate &&
              formik.getFieldMeta("stateDate").touched && (
                <FormHelperText error>Required</FormHelperText>
              )}
          </FormSpacingBox>
        )}

        <ProjectTypology
          error={
            formik.touched.category && formik.errors.category
              ? formik.errors.category
              : undefined
          }
          typology={formik.values.category!}
          onChange={(values) => formik.setFieldValue("category", values)}
        />

        <ProjectToolSelector
          error={
            formik.touched.tools && formik.errors.tools
              ? formik.errors.tools
              : undefined
          }
          tools={formik.values.tools!}
          onChange={(values) => formik.setFieldValue("tools", values)}
        />

        <ProjectKeyMaterials
          error={
            formik.touched.keyMaterials && formik.errors.keyMaterials
              ? formik.errors.keyMaterials
              : undefined
          }
          keyMaterials={formik.values.keyMaterials!}
          onChange={(value) => formik.setFieldValue("keyMaterials", value)}
        />

        {isUploading ? (
          <Box marginTop={12}>
            <Typography>Uploading: {currentFile}</Typography>
            <LinearProgress />
          </Box>
        ) : null}
      </>
    </WizardStep>
  )
}

Step2.defaultProps = {
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    type: Yup.string().required("Required").typeError("Required"),
    firmName: Yup.string()
      .ensure()
      .when("type", {
        is: "firm",
        then: Yup.string().required("Required").typeError("Required"),
      }),
    description: Yup.string()
      .required("Required")
      .max(3000, "Must be less than 3000 characters"),
    state: Yup.string().required("Required").typeError("Required"),
    stateDate: Yup.string()
      .ensure()
      .when("state", {
        is: (state) => state === "complete" || state === "in_progress",
        then: Yup.string().required("Required"),
      }),
    category: Yup.array(Yup.string()).min(1, "Required"),
    keyMaterials: Yup.array().min(1, "Required"),
    tools: Yup.array().min(1, "Required"),
  }),
  groupValidationSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string()
      .required("Required")
      .max(3000, "Must be less than 3000 characters"),
    state: Yup.string().required("Required"),
    stateDate: Yup.string()
      .ensure()
      .when("state", {
        is: (state) => state === "complete" || state === "in_progress",
        then: Yup.string().required("Required"),
      }),
    category: Yup.array(Yup.string()).min(1, "Required"),
    keyMaterials: Yup.array().min(1, "Required"),
    tools: Yup.array().min(1, "Required"),
  }),
}

export default Step2
