const camelize = (str: string) => {
  if (str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return word.toUpperCase()
      })
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9]/g, "")
  } else {
    return ""
  }
}

export default camelize
