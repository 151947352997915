import React, { useRef, useState } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import {
  CardBox,
  CardHeader,
  CardContent,
  HeaderBox,
  HeaderTitle,
  LinkBox,
  WhiteGradient,
  ArrowRight,
  ArrowLeft,
  WhiteGradientLeft,
  CardFooter,
  JobCard,
  JobCardHeader,
  JobCardAvatar,
  JobCardBody,
  JobName,
  JobDescription,
  JobLocation,
  JobTitle,
  JobHint,
} from "./styles"
import Avatar from "../../components/Avatar"
import SimpleAddress from "../../components/SimpleAddress"

import ArrowRightIcon from "../../images/new-homepage/arrow-right.svg"
import SuggestionsArrow from "../../images/new-homepage/suggestions-arrow.svg"
import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import { JobListing } from "../../redux/jobListings"
import moment from "moment"
import { normalizeProjectTitle } from "../../utils"
import { StyledLink } from "../../components/Styles/Global"

export type Props = {
  jobs: JobListing[]
}

const View = ({ jobs }: Props) => {
  const { width } = useWindowDimensions()

  const isMobile = width < theme.breakpoints.values.sm

  const [showRightGradient, setShowRightGradient] = useState(true)
  const [showLeftGradient, setShowLeftGradient] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const scroll = (scrollOffset: number, orientation: string) => {
    let scrollAmount = 0
    const sliderSmooth = setInterval(function () {
      if (ref.current) {
        if (orientation === "right") {
          ref.current.scrollLeft += 10
        } else {
          ref.current.scrollLeft -= 10
        }

        scrollAmount += 10
        if (scrollAmount >= scrollOffset) {
          window.clearInterval(sliderSmooth)
        }
      }
    }, 5)
  }

  const scrollRight = () => {
    scroll(500, "right")
    setShowLeftGradient(true)
    setShowRightGradient(false)
  }

  const scrollLeft = () => {
    scroll(500, "left")
    setShowLeftGradient(false)
    setShowRightGradient(true)
  }

  const getContractTypeLabel = (contractType: string) => {
    if (contractType === "internship") return "Internship"

    if (contractType === "contract") return "Contract"

    if (contractType === "part-time") return "Part-time"

    if (contractType === "full-time") return "Full-time"
  }

  return (
    <CardBox>
      <CardHeader>
        <HeaderBox>
          <HeaderTitle>Job Suggestions</HeaderTitle>
        </HeaderBox>
      </CardHeader>
      <CardContent ref={ref} $isSingleJob={jobs.length === 1}>
        {jobs.map((job) => {
          const location = SimpleAddress({
            profileType: job.profile!.type,
            location: job.location,
          })

          const goToJob = () => {
            return `/sp/${job.profile?.username}/job_listings/${
              job.id
            }/${normalizeProjectTitle(job.title)}`
          }

          return (
            <JobCard>
              <JobCardHeader>
                <JobCardAvatar>
                  <LinkBox to={`/sp/${job.profile?.username}`}>
                    <Avatar
                      profile={job.profile!}
                      width={isMobile ? 64 : 85}
                      height={isMobile ? 64 : 85}
                    />
                  </LinkBox>
                </JobCardAvatar>
              </JobCardHeader>
              <JobCardBody>
                <JobName>
                  <LinkBox to={`/sp/${job.profile?.username}`}>
                    {job.profile?.groupName}
                  </LinkBox>
                </JobName>
                <JobLocation>
                  <span>{location}</span>
                </JobLocation>

                <JobTitle>
                  <StyledLink component={RouterLink} to={goToJob()}>
                    {job.title}
                  </StyledLink>
                </JobTitle>
                <JobDescription>
                  <span>{job.summary}</span>
                </JobDescription>
              </JobCardBody>
              <JobHint>
                {job.employmentType
                  ? getContractTypeLabel(job.employmentType)
                  : ""}
                <span>•</span>
                {moment(job?.createdAt).fromNow(true).replace(" ", "")} ago
              </JobHint>
            </JobCard>
          )
        })}
      </CardContent>
      {showRightGradient && jobs.length > 1 && (
        <>
          <ArrowRight onClick={scrollRight}>
            <img src={ArrowRightIcon} alt="Arrow Right" />
          </ArrowRight>
          <WhiteGradient />
        </>
      )}
      {showLeftGradient && jobs.length > 1 && (
        <>
          <ArrowLeft onClick={scrollLeft}>
            <img src={ArrowRightIcon} alt="Arrow Right" />
          </ArrowLeft>
          <WhiteGradientLeft />
        </>
      )}
      <CardFooter>
        <StyledLink component={RouterLink} to={`/jobs`}>
          <span>See more jobs</span>
          <img src={SuggestionsArrow} alt="See more" />
        </StyledLink>
      </CardFooter>
    </CardBox>
  )
}

export default View
