import React from "react"
import moment from "moment"
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core"
import { JobPosition } from "../../redux/jobPositions"
import { Education } from "../../redux/educations"
import {
  EducationGrid,
  CredentialListBox,
  JobDescriptionBox,
  ActionButton,
} from "./styles"
import { NullstateTab, TabTitleText } from "../ProfileDetailPage/styles"
import theme from "../../theme"
import LightButtressDivider from "../../components/LightButtressDivider"
import MUIRichTextEditor from "mui-rte"
import ProfileNullstate from "../../components/ProfileNullstate"
import { Profile } from "../../redux/profile"

export interface Props {
  awards: string[]
  groups: string[]
  skills: string[]
  credentials: string[]
  educations: Education[]
  jobPositions: JobPosition[]
  newExperienceClicked: () => void
  isPublicProfile: boolean
  goToUrl: (url: string) => void
  authenticatedProfile?: Profile
}

const View = ({
  awards,
  groups,
  skills,
  educations,
  credentials,
  jobPositions,
  newExperienceClicked,
  isPublicProfile,
  goToUrl,
  authenticatedProfile,
}: Props) => {
  const hasJobPositions = jobPositions.length > 0
  const hasExperienceDetails =
    hasJobPositions ||
    educations.length > 0 ||
    credentials.length > 0 ||
    skills.length > 0 ||
    groups.length > 0 ||
    awards.length > 0

  const nullstateAction = () => {
    authenticatedProfile
      ? goToUrl(`/sp/${authenticatedProfile.username}`)
      : goToUrl("/register")
  }

  return (
    <>
      {!hasExperienceDetails ? (
        <Box style={{ padding: 0 }}>
          {!isPublicProfile ? (
            <NullstateTab>
              <ProfileNullstate
                onClick={newExperienceClicked}
                type={"owner-experience-tab"}
              />
            </NullstateTab>
          ) : (
            <NullstateTab>
              <ProfileNullstate
                onClick={nullstateAction}
                type={"experience-tab"}
              />
            </NullstateTab>
          )}
        </Box>
      ) : (
        <Box marginTop={4} style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={12} md={hasJobPositions ? 8 : 12}>
              <Box paddingRight={4}>
                {hasJobPositions && (
                  <Box marginBottom={2}>
                    <TabTitleText>Experience</TabTitleText>
                  </Box>
                )}
                {jobPositions.map((jobPosition: JobPosition) => (
                  <Box
                    marginBottom={3}
                    key={jobPosition.id}
                    aria-label="job experience"
                  >
                    <Typography
                      tabIndex={0}
                      variant="body1"
                      data-testid="jobTitle"
                      style={{ fontWeight: 600 }}
                    >
                      {jobPosition.title}
                    </Typography>
                    <Typography tabIndex={0} variant="body1">
                      {jobPosition.company}
                    </Typography>
                    <Typography tabIndex={0} variant="body1">
                      {moment(jobPosition.startDate).format("MM/YYYY")} -{" "}
                      {jobPosition.current
                        ? "Present"
                        : moment(jobPosition.endDate).format("MM/YYYY")}
                    </Typography>
                    <Box marginTop={3} marginBottom={5}>
                      <JobDescriptionBox>
                        Description: {jobPosition.description}
                      </JobDescriptionBox>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={hasJobPositions ? 4 : 12}
              className="profile-education"
            >
              {educations.length > 0 && (
                <EducationGrid
                  container
                  classes={{
                    root: hasJobPositions ? "has-job-experience" : "",
                  }}
                >
                  <Grid item xs={12}>
                    <Box marginBottom={2}>
                      <Typography tabIndex={0} variant="h5">
                        Education
                      </Typography>
                    </Box>
                    {educations.map((education: Education) => (
                      <Box
                        marginBottom={2}
                        key={education.id}
                        aria-label="education"
                      >
                        <Typography
                          variant="body1"
                          data-testid="educationSchool"
                        >
                          {education.school}
                        </Typography>
                        <Typography tabIndex={0} variant="body1">
                          {education.degree}
                        </Typography>
                        <Typography tabIndex={0} variant="body1">
                          {education.field}
                        </Typography>
                        <Typography tabIndex={0} variant="body1">
                          {education.startYear} -{" "}
                          {education.current ? "Present" : education.endYear}
                        </Typography>

                        <Box
                          marginTop={3}
                          style={{ lineHeight: 1.375, color: "#2d2d2d" }}
                        >
                          <MUIRichTextEditor
                            defaultValue={
                              education.additionalInfoRTF
                                ? education.additionalInfoRTF
                                : ""
                            }
                            readOnly={true}
                            controls={[]}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </EducationGrid>
              )}
              {educations.length > 0 && credentials.length > 0 && (
                <LightButtressDivider />
              )}
              {credentials.length > 0 && (
                <EducationGrid
                  container
                  aria-label="credentials"
                  classes={{
                    root: hasJobPositions ? "has-job-experience" : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                      paddingBottom: theme.spacing(3),
                    }}
                  >
                    <Typography tabIndex={0} variant="h5">
                      Credentials
                    </Typography>
                    <CredentialListBox>
                      <List>
                        {credentials.map((credential: string) => (
                          <ListItem>{credential}</ListItem>
                        ))}
                      </List>
                    </CredentialListBox>
                  </Grid>
                </EducationGrid>
              )}
              {skills.length > 0 && credentials.length > 0 && (
                <LightButtressDivider />
              )}
              {skills.length > 0 && (
                <EducationGrid
                  container
                  aria-label="skills"
                  classes={{
                    root: hasJobPositions ? "has-job-experience" : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                      paddingBottom: theme.spacing(3),
                    }}
                  >
                    <Typography tabIndex={0} variant="h5">
                      Skills
                    </Typography>
                    {skills.map((skill: string, index: number) => (
                      <Box
                        key={index}
                        marginRight={1}
                        marginBottom={1}
                        marginTop={1}
                        display="inline-block"
                      >
                        <Button
                          color="primary"
                          size="small"
                          onClick={() =>
                            goToUrl(
                              `/profiles?skills=${JSON.stringify([skill])}`
                            )
                          }
                        >
                          {skill}
                        </Button>
                      </Box>
                    ))}
                  </Grid>
                </EducationGrid>
              )}
              {skills.length > 0 && groups.length > 0 && (
                <LightButtressDivider />
              )}
              {groups.length > 0 && (
                <EducationGrid
                  container
                  aria-label="groups"
                  classes={{
                    root: hasJobPositions ? "has-job-experience" : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                      paddingBottom: theme.spacing(3),
                    }}
                  >
                    <Typography tabIndex={0} variant="h5">
                      Professional Affiliations
                    </Typography>
                    <CredentialListBox>
                      <List>
                        {groups.map((group: string) => (
                          <ListItem>{group}</ListItem>
                        ))}
                      </List>
                    </CredentialListBox>
                  </Grid>
                </EducationGrid>
              )}
              {groups.length > 0 && awards.length > 0 && (
                <LightButtressDivider />
              )}
              {awards.length > 0 && (
                <EducationGrid
                  container
                  aria-label="awards"
                  classes={{
                    root: hasJobPositions ? "has-job-experience" : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                      paddingBottom: theme.spacing(3),
                    }}
                  >
                    <Typography tabIndex={0} variant="h5">
                      Awards
                    </Typography>
                    <CredentialListBox>
                      <List>
                        {awards.map((award: string) => (
                          <ListItem>{award}</ListItem>
                        ))}
                      </List>
                    </CredentialListBox>
                  </Grid>
                </EducationGrid>
              )}
            </Grid>

            <Grid item>
              <Box marginTop={3}>
                {hasExperienceDetails && !isPublicProfile && (
                  <ActionButton color="primary" onClick={newExperienceClicked}>
                    Edit experience
                  </ActionButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default View
