import React from "react"
import { Box } from "@material-ui/core"

import { BannerImage } from "../styles"
import { New1440Box, New1440Container } from "../../HomePage/styles"
import { Profile } from "../../../redux/profile"
import DefaultBannerImage from "../../../images/banner_default.png"

import TabView from "./TabView"
import ProfileHeader from "./ProfileHeader"
import { useWindowDimensions } from "../../../hooks"

import theme from "../../../theme"

export type Props = {
  profile: Profile
  authenticatedProfile: Profile
  currentTab: string
  isPublicProfile: boolean
  onAddProject: () => void
  onAddJobListing: () => void
  goToUrl: (url: string) => void
  onTabClicked: (url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  onEditAbout: (plainText: string, richText: string) => Promise<void>
}

const View = ({
  goToUrl,
  profile,
  authenticatedProfile,
  onEditAbout,
  onTabChanged,
  onTabClicked,
  onAddProject,
  isPublicProfile,
  onAddJobListing,
  currentTab = "projects",
}: Props) => {
  const { width } = useWindowDimensions()

  React.useEffect(() => {}, [profile])

  const bannerSrc = () =>
    profile!.banner && profile!.banner.id
      ? profile!.banner.url
      : DefaultBannerImage

  const isDesktop = width >= theme.breakpoints.values.lg
  const isTablet =
    width >= theme.breakpoints.values.sm && width < theme.breakpoints.values.lg

  return (
    <>
      <BannerImage
        src={bannerSrc()}
        style={{ height: isDesktop ? "151px" : isTablet ? "128px" : "120px" }}
      />
      <New1440Container>
        <New1440Box>
          <Box>
            <ProfileHeader
              profile={profile}
              goToUrl={goToUrl}
              isPublicProfile={isPublicProfile}
            />
          </Box>

          <Box style={{ paddingTop: isDesktop ? "80px" : "30px" }}>
            <TabView
              profile={profile}
              authenticatedProfile={authenticatedProfile}
              currentTab={currentTab}
              onEditAbout={onEditAbout}
              onAddProject={onAddProject}
              onTabChanged={onTabChanged}
              onTabClicked={onTabClicked}
              onAddJobListing={onAddJobListing}
              goToUrl={goToUrl}
              isPublicProfile={isPublicProfile}
            />
          </Box>
        </New1440Box>
      </New1440Container>
    </>
  )
}

export default View
