import React, { useState } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { useAuth0 } from "@auth0/auth0-react"
import { fetchProfileSuggestion } from "../../redux/profile"
import { CommentMention } from "../../redux/projects"
import getFromBetween from "../../utils/getStringFromBetween"

type Props = {
  oneLine?: boolean
  handleSave: (
    comment: string,
    mentions?: CommentMention[],
    parentCommentId?: string
  ) => void
  parentCommentId?: string
  focus?: boolean
  replyFromDetails?: boolean
  isEditMode?: boolean
  defaultValue?: string
  toggleEditMode?: () => void
}

const CommentTextField = ({
  oneLine,
  handleSave,
  parentCommentId,
  focus,
  replyFromDetails,
  isEditMode,
  defaultValue,
  toggleEditMode,
}: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [comment, setComment] = useState(defaultValue || "")
  const [commenting, setCommenting] = useState(false)

  const handleGetSuggestions = async (query: string, callback: any) => {
    try {
      const { type, payload } = await dispatch(
        fetchProfileSuggestion({ auth, query })
      )
      if (fetchProfileSuggestion.fulfilled.type === type) {
        callback(payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeComment = (text: string) => {
    setComment(text)
  }

  const handleEnteringComment = (value: boolean) => {
    setCommenting(value)
  }

  const onSave = () => {
    const mentions: CommentMention[] = []
    const separatedMentions = getFromBetween.get(comment, "@{{", ")}}")
    separatedMentions.forEach((value: string) => {
      const arr1 = value.split("}}")
      const arr2 = arr1[1].replace("{{(", "").split("|")

      mentions.push({
        name: arr1[0],
        profileId: arr2[0],
        username: arr2[1],
        fullString: "@{{" + value + ")}}",
      })
    })

    handleSave(comment, mentions, parentCommentId)
    setComment("")
    setCommenting(false)
  }

  return (
    <View
      comment={comment}
      handleGetSuggestions={handleGetSuggestions}
      oneLine={oneLine}
      commenting={commenting}
      handleEnteringComment={handleEnteringComment}
      handleChangeComment={handleChangeComment}
      onSave={onSave}
      focus={focus}
      replyFromDetails={replyFromDetails}
      isEditMode={isEditMode}
      toggleEditMode={toggleEditMode}
    />
  )
}

export default CommentTextField
