import React from "react"
import { Grid } from "@material-ui/core"

import { MainContainer } from "./../HomePage/styles"

const View = () => (
  <MainContainer>
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <div
          name="termly-embed"
          data-id="e8f37e6d-c64f-43d1-ba82-c47aa4825879"
          data-type="iframe"
        ></div>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
