import React, { useCallback, useEffect, useState, ChangeEvent } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import View from "./view"
import MobileView from "./mobile-view"

import { useAppDispatch } from "../../redux/configureStore"
import { fetchProjects, selectCompetitionProjects } from "../../redux/projects"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"

type Props = {
  perPage?: number
}

type DefaultFilters = {
  term: string
  competition: string
  keyMaterials: string[]
  tools: string[]
  typology: string[]
  tags: string[]
  sortBy: string
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ProjectsPage = ({ perPage = 12 }: Props) => {
  const { width } = useWindowDimensions()
  const query = useQuery()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { user } = useAuth0()
  const email = user?.email

  const randomOption = Math.floor(Math.random() * (5 - 0 + 1) + 0)
  const sortOptions: string[] = [
    "score",
    "createdAt",
    "recentlyUpdated",
    "likes",
    "comments",
    "nominations",
  ]

  const initialFilters: DefaultFilters = {
    term: "",
    competition: query.get("competition") || "",
    keyMaterials: query.get("keyMaterials")
      ? (query.get("keyMaterials") as any)
      : [],
    tools: query.get("tools") ? (query.get("tools") as any) : [],
    typology: query.get("typology") ? (query.get("typology") as any) : [],
    tags: query.get("tags") ? (query.get("tags") as any) : [],
    sortBy: query.get("sortBy") || "",
  }

  const projects = useSelector((state: any) => state.projects.allProjects)
  const currentPage = useSelector(
    (state: any) => state.projects.allProjectsCurrentPage
  )
  const projectsCount = useSelector(
    (state: any) => state.projects.projectsCount
  )

  const currentFilter = useSelector(
    (state: any) => state.projects.currentFilter
  )

  const [action, setAction] = useState("POP")

  const hasMoreDefaultValue = projects.length < projectsCount

  const [hasMore, setHasMore] = useState(hasMoreDefaultValue)

  const projectSort = query.get("sortBy")
    ? query.get("sortBy")
    : sessionStorage.getItem("project_sort")

  if (!projectSort) {
    sessionStorage.setItem("project_sort", sortOptions[randomOption])
  }

  if (
    action === "POP" &&
    history.action !== "PUSH" &&
    (!query.get("competition") || currentFilter?.competition) &&
    (!query.get("tools") || currentFilter?.tools) &&
    (!query.get("typology") || currentFilter?.typology) &&
    (!query.get("tags") || currentFilter?.tags) &&
    (!query.get("keyMaterials") || currentFilter?.keyMaterials) &&
    (!query.get("sortBy") || currentFilter?.sortBy)
  ) {
    initialFilters.term = currentFilter?.search
    initialFilters.competition = currentFilter?.competition
    initialFilters.typology = currentFilter?.typology
    initialFilters.tools = currentFilter?.tools
    initialFilters.keyMaterials = currentFilter?.keyMaterials
    initialFilters.tags = currentFilter?.tags
    initialFilters.sortBy = currentFilter?.sortBy
  }

  const [term, setTerm] = useState(initialFilters.term)
  const competition = initialFilters.competition

  const [typology] = useState<string[]>(initialFilters.typology)
  const [tools] = useState<string[]>(initialFilters.tools)
  const [keyMaterials] = useState<string[]>(initialFilters.keyMaterials)
  const [tags] = useState<string[]>(initialFilters.tags)
  const [sortBy, setSortBy] = useState(
    query.get("sortBy")
      ? query.get("sortBy") || "createdAt"
      : (sessionStorage.getItem("project_sort") as string)
  )

  const [page, setPage] = React.useState(
    currentPage > 1 &&
      ((action !== "POP" && history.action === "PUSH") ||
        (action === "POP" && history.action === "POP"))
      ? currentPage
      : 1
  )

  const handleChangeAction = (action: string) => {
    setAction(action)
    if (action === "FETCH") {
      setPage(1)
      setHasMore(true)
    }
  }

  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangeFilterBy = (event: ChangeEvent<{ value: unknown }>) => {
    handleChangeAction("FETCH")
    setSortBy(event.target.value as string)
    sessionStorage.setItem("project_sort", event.target.value as string)
    window.scrollTo(0, 0)
  }

  const handleChangePagination = (value: number) => {
    handleChangeAction("PAGINATE")
    setPage(value)
  }

  const handleSearch = async (searchText: string): Promise<void> => {
    try {
      setAction("SEARCH")
      setHasMore(true)
      setTerm(searchText)
      setPage(1)
      window.scrollTo(0, 0)
      const { type, payload } = await dispatch(
        fetchProjects({
          email,
          page: 1,
          perPage,
          sortBy,
          search: searchText,
          competition,
          action,
        })
      )

      if (fetchProjects.fulfilled.type === type) {
        if (payload.data.length >= payload.count) setHasMore(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const loadProjects = useCallback(
    async () => {
      try {
        if (
          (history.action !== "POP" ||
            action === "FETCH" ||
            action === "PAGINATE" ||
            projects.length === 0) &&
          action !== "SEARCH"
        ) {
          setHasMore(true)
          const search = term
          const { type, payload } = await dispatch(
            fetchProjects({
              email,
              page,
              perPage,
              sortBy,
              search,
              competition,
              typology,
              tools,
              keyMaterials,
              tags,
              action,
            })
          )

          if (fetchProjects.fulfilled.type === type) {
            setTimeout(() => {
              // @ts-ignore
              window.prerenderReady = true
            }, 0)

            if (payload.data.length >= payload.count) setHasMore(false)
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [
      dispatch,
      email,
      page,
      perPage,
      sortBy,
      competition,
      typology,
      tools,
      keyMaterials,
      tags,
      history.action,
    ]
  )

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  const competitionProjects = useSelector(selectCompetitionProjects)

  return (
    <>
      <RouteChangeTracker screenTitle={`Projects`} classTitle="Projects" />
      <Helmet>
        <meta name="keywords" content="Online architect portfolio" />
        <title>Projects - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Projects - Spectacular`} />
        <meta name="title" content={`Projects - Spectacular`} />
      </Helmet>
      {width <= theme.breakpoints.values.sm ? (
        <MobileView
          projects={projects}
          filterBy={sortBy}
          onSearch={handleSearch}
          projectsCount={projectsCount}
          page={page}
          handleChangePagination={handleChangePagination}
          handleChangeHasMore={handleChangeHasMore}
          term={term}
          hasMore={hasMore}
          onChangeAction={handleChangeAction}
          competition={competition}
          competitionProjects={competitionProjects}
        />
      ) : (
        <View
          projects={projects}
          handleChangeFilterBy={handleChangeFilterBy}
          filterBy={sortBy}
          onSearch={handleSearch}
          projectsCount={projectsCount}
          page={page}
          handleChangePagination={handleChangePagination}
          handleChangeHasMore={handleChangeHasMore}
          term={term}
          hasMore={hasMore}
          competition={competition}
          competitionProjects={competitionProjects}
        />
      )}
    </>
  )
}

export default ProjectsPage
