import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type ProjectMainFeature = {
  id?: string
  startDate: string
  endDate: string
  heroTitle?: string
  createdAt?: string
  projectId?: string
}
//#endregion

//#region api
type CreateProjectMainFeaturePayload = {
  auth: Auth0ContextInterface
  projectMainFeature: ProjectMainFeature
}
export const createProjectMainFeature = createAsyncThunk<
  any,
  CreateProjectMainFeaturePayload
>("admin/projectMainFeature/create", async ({ auth, projectMainFeature }) => {
  const { id, ...projectMainFeatureData } = projectMainFeature
  return useApi(auth, "/admin/project_main_feature", {
    method: "POST",
    body: JSON.stringify(projectMainFeatureData),
  }).then((res) => res.json())
})

type UpdateProjectMainFeaturePayload = {
  auth: Auth0ContextInterface
  projectMainFeature: ProjectMainFeature
}
export const updateProjectMainFeature = createAsyncThunk<
  any,
  UpdateProjectMainFeaturePayload
>("admin/projectMainFeature/update", async ({ auth, projectMainFeature }) => {
  return useApi(auth, `/admin/project_main_feature/${projectMainFeature.id}`, {
    method: "PATCH",
    body: JSON.stringify(projectMainFeature),
  }).then((res) => res.json())
})

type DeleteProjectMainFeaturePayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteProjectMainFeature = createAsyncThunk<
  any,
  DeleteProjectMainFeaturePayload
>("admin/projectMainFeature/delete", async ({ auth, id }) => {
  return useApi(auth, `/admin/project_main_feature/${id}`, {
    method: "DELETE",
  }).then((res) => res.json())
})
//#endregion

//#region slice
//#endregion

//#region selectors
//#endregion
