import React, { useEffect, useState } from "react"
import {
  FeaturedProjectContainer,
  FeaturedProjectBox,
  FeaturedProjectTitle,
  FeaturedProjectDivider,
  FeaturedProjectCard,
  PostSearchBox,
  ProjectsListBox,
  StickyListContainer,
  MobileStickyBar,
  AvatarBox,
} from "./styles"
import { Project } from "../../redux/projects"

import FeaturedIcon from "../../images/new-homepage/icons/nominate-active.svg"

import ProjectCard from "../../cards/ProjectCard"
import Header from "./Header"
import { useAuth0 } from "@auth0/auth0-react"
import PlaceholderCard from "../../cards/PlaceholderCard"
import { Box } from "@material-ui/core"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Profile, selectProfile } from "../../redux/profile"
import SpotlightedFirm from "../../components/SpotlightedFirm"
import HomeSearchBar from "../../components/HomeSearchBar"
import HomeFeed from "./HomeFeed"
import ProjectsList from "./ProjectsList"
import ProfilesList from "./ProfilesList"
import PostsList from "./PostsList"
import { toggleDialog } from "../../redux/config"
import { useAppDispatch } from "../../redux/configureStore"
import FilterFeed from "./FilterFeed"
import { Competition } from "../../redux/competition"
import InnovationAwardsAnnouncement from "../../components/InnovationAwardsAnnouncement"
import Avatar from "../../components/Avatar"
import { useSelector } from "react-redux"
import { LinkBox } from "../../cards/ProjectCard/styles"
import PostButton from "../../components/PostButton"
import NewsList from "./NewsList"
import { useHistory, useLocation } from "react-router-dom"
import StudentAmbassador from "./StudentAmbassador"

export type Props = {
  heroProject: Array<Project>
  spotlightedProfile: Profile
  email?: string
  competitions?: Competition[]
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const View = ({
  heroProject,
  spotlightedProfile,
  email,
  competitions,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const query = useQuery()
  const hashtag = query.get("hashtag") || ""

  const [activeSearch, setActiveSearch] = useState("")

  const profile = useSelector(selectProfile)

  const handleUpdateActiveSearch = (value: string) => {
    document
      .getElementById("homeSearch")
      ?.scrollIntoView({ behavior: "smooth" })
    setActiveSearch(value)
    setIsHashtag(false)
  }

  if (hashtag) {
    document
      .getElementById("homeSearch")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  //REGULAR FILTERS
  const [text, setText] = useState(hashtag)
  const [textFilter, setTextFilter] = useState(hashtag)
  const [isHashtag, setIsHashtag] = useState(hashtag ? true : false)
  const [myNetworkFilter, setMyNetworkFilter] = useState(false)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState("engaging")

  const handleUpdateSortBy = (value: string) => {
    setSortBy(value)
    handleChangePage(1)
  }

  const handleUpdateText = (value: string) => {
    setText(value)
    setIsHashtag(false)
  }

  const handleUpdateHashtag = (value: string) => {
    setText(value)
    setTextFilter(value)
    handleChangePage(1)
    setIsHashtag(true)
  }

  const handleChangePage = (value: number) => {
    if (value === 1) {
      document
        .getElementById("homeSearch")
        ?.scrollIntoView({ behavior: "smooth" })
    }
    setPage(value)
  }

  const handleUpdateNetworkFilter = (value: boolean) => {
    setMyNetworkFilter(value)
    handleChangePage(1)
  }

  const handleUpdateTextFilter = async (value: string) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    setTextFilter(value)
    handleChangePage(1)
  }
  //END REGULAR FILTERS

  //PROFILE FILTERS
  const [profileTypeFilter, setProfileTypeFilter] = useState<string[]>()

  const handleUpdateProfileTypeFilter = (value: string) => {
    if (profileTypeFilter?.includes(value)) {
      const newValue = [...profileTypeFilter]
      const index = newValue.indexOf(value)
      newValue.splice(index, 1)

      setProfileTypeFilter(newValue)
    } else {
      setProfileTypeFilter(
        profileTypeFilter ? [...profileTypeFilter, value] : [value]
      )
    }

    handleChangePage(1)
  }
  //END PROFILE FILTERS

  //PROJECT FILTERS
  const [typologyFilter, setTypologyFilter] = useState<string[]>()
  const [toolsFilter, setToolsFilter] = useState<string[]>()
  const [keyMaterialsFilter, setKeyMaterialsFilter] = useState<string[]>()

  const handleUpdateTypologyFilter = (value: string) => {
    if (typologyFilter?.includes(value)) {
      const newValue = [...typologyFilter]
      const index = newValue.indexOf(value)
      newValue.splice(index, 1)

      setTypologyFilter(newValue)
    } else {
      setTypologyFilter(typologyFilter ? [...typologyFilter, value] : [value])
    }

    handleChangePage(1)
  }

  const handleUpdateToolsFilter = (value: string) => {
    if (toolsFilter?.includes(value)) {
      const newValue = [...toolsFilter]
      const index = newValue.indexOf(value)
      newValue.splice(index, 1)

      setToolsFilter(newValue)
    } else {
      setToolsFilter(toolsFilter ? [...toolsFilter, value] : [value])
    }

    handleChangePage(1)
  }

  const handleUpdateKeyMaterialsFilter = (value: string) => {
    if (keyMaterialsFilter?.includes(value)) {
      const newValue = [...keyMaterialsFilter]
      const index = newValue.indexOf(value)
      newValue.splice(index, 1)

      setKeyMaterialsFilter(newValue)
    } else {
      setKeyMaterialsFilter(
        keyMaterialsFilter ? [...keyMaterialsFilter, value] : [value]
      )
    }

    handleChangePage(1)
  }
  //END PROJECT FILTERS

  const handleShortcutClick = async (value: string) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    setPage(1)
    setTextFilter(value)
    setText(value)
    setIsHashtag(true)
  }

  const handleChangeActiveSearch = async (value: string, reset: boolean) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    handleUpdateActiveSearch(activeSearch === value ? "" : value)
    if (reset) {
      setPage(1)
      setKeyMaterialsFilter([])
      setToolsFilter([])
      setTypologyFilter([])
      setProfileTypeFilter([])
      setMyNetworkFilter(false)
      setTextFilter("")
      setText("")
      setSortBy(value === "posts" ? "recent" : "engaging")
    }
  }

  let showFirmBadge = false

  const { width } = useWindowDimensions()
  const isTablet =
    width <= theme.breakpoints.values.md && width > theme.breakpoints.values.sm
  const isMobile = width <= theme.breakpoints.values.md

  if (heroProject[0] && spotlightedProfile) {
    showFirmBadge =
      heroProject[0].profile?.username === spotlightedProfile.username
  }

  const competition = competitions?.find(
    (competition) => competition.showHomeBanner
  )

  useEffect(() => {
    return history.listen((loc) => {
      const params = new URLSearchParams(loc.search.substring(1))
      const hashtagFilter = params.get("hashtag")
      if (hashtagFilter) {
        handleUpdateHashtag(hashtagFilter)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return (
    <Box
      style={{
        overflow: "clip",
        marginTop: isAuthenticated && !isMobile ? "-40px" : 0,
      }}
    >
      <h1 style={{ position: "absolute", right: 9000 }}>
        Grow your professional network & discover architectural inspiration with
        your Spectacular Portfolio
      </h1>
      <p style={{ position: "absolute", right: 9000 }}>
        Show off your Spectacular portfolio to the largest community of
        Architects in the world! Network with Architects around the world,
        discover career opportunities, and exciting new design projects.
      </p>

      {!isAuthenticated && <Header />}

      {isMobile && (
        <>
          <StickyListContainer
            style={{ height: isTablet ? "118px" : "58px" }}
            className="stickyMenu"
          >
            <ProjectsListBox>
              <MobileStickyBar>
                {isAuthenticated && profile && (
                  <LinkBox to={`/sp/${profile.username}`}>
                    <AvatarBox>
                      <Avatar isNav width={38} height={38} profile={profile!} />
                    </AvatarBox>
                  </LinkBox>
                )}
                <HomeSearchBar
                  activeSearch={activeSearch}
                  onChangeActiveSearch={handleChangeActiveSearch}
                  typologyFilter={typologyFilter}
                  toolsFilter={toolsFilter}
                  keyMaterialsFilter={keyMaterialsFilter}
                  myNetworkFilter={myNetworkFilter}
                  profileTypeFilter={profileTypeFilter}
                  text={text}
                  onUpdateText={handleUpdateText}
                  onUpdateTypologyFilter={handleUpdateTypologyFilter}
                  onUpdateToolsFilter={handleUpdateToolsFilter}
                  onUpdateKeyMaterialsFilter={handleUpdateKeyMaterialsFilter}
                  onUpdateNetworkFilter={handleUpdateNetworkFilter}
                  onUpdateTextFilter={handleUpdateTextFilter}
                  onUpdateProfileTypeFilter={handleUpdateProfileTypeFilter}
                  onShortcutClick={handleShortcutClick}
                />
              </MobileStickyBar>
            </ProjectsListBox>
          </StickyListContainer>
        </>
      )}

      {competition?.showHomeBanner &&
        competition.winners.winners.length >= 3 && (
          <InnovationAwardsAnnouncement
            competition={competition}
            carousel={true}
          />
        )}

      <FeaturedProjectContainer $isAuthenticated={isAuthenticated}>
        <FeaturedProjectBox $isAuthenticated={isAuthenticated}>
          <FeaturedProjectTitle>
            <img src={FeaturedIcon} alt={"Project of the Day"} />
            Project of the Day
          </FeaturedProjectTitle>
          <FeaturedProjectDivider />
          <FeaturedProjectCard>
            {heroProject[0] ? (
              <ProjectCard
                project={heroProject[0]}
                large={true}
                showFirmBadge={showFirmBadge}
              />
            ) : (
              <PlaceholderCard large={true} />
            )}
          </FeaturedProjectCard>
        </FeaturedProjectBox>
      </FeaturedProjectContainer>

      {spotlightedProfile && <SpotlightedFirm profile={spotlightedProfile} />}

      <StudentAmbassador />

      <div id="homeSearch"></div>

      {!isMobile && (
        <StickyListContainer className="stickyMenu">
          <ProjectsListBox>
            <PostSearchBox>
              <PostButton origin={"homepage"} />
              <HomeSearchBar
                activeSearch={activeSearch}
                onChangeActiveSearch={handleChangeActiveSearch}
                typologyFilter={typologyFilter}
                toolsFilter={toolsFilter}
                keyMaterialsFilter={keyMaterialsFilter}
                myNetworkFilter={myNetworkFilter}
                profileTypeFilter={profileTypeFilter}
                text={text}
                onUpdateText={handleUpdateText}
                onUpdateTypologyFilter={handleUpdateTypologyFilter}
                onUpdateToolsFilter={handleUpdateToolsFilter}
                onUpdateKeyMaterialsFilter={handleUpdateKeyMaterialsFilter}
                onUpdateNetworkFilter={handleUpdateNetworkFilter}
                onUpdateTextFilter={handleUpdateTextFilter}
                onUpdateProfileTypeFilter={handleUpdateProfileTypeFilter}
                onShortcutClick={handleShortcutClick}
              />
            </PostSearchBox>
          </ProjectsListBox>
        </StickyListContainer>
      )}

      {!activeSearch ? (
        <>
          {textFilter ? (
            <FilterFeed
              search={textFilter}
              isHashtag={isHashtag}
              onShortcutClick={handleShortcutClick}
            />
          ) : (
            <HomeFeed email={email} />
          )}
        </>
      ) : (
        <>
          {activeSearch === "projects" && (
            <ProjectsList
              email={email}
              typologyFilter={typologyFilter}
              toolsFilter={toolsFilter}
              keyMaterialsFilter={keyMaterialsFilter}
              textFilter={textFilter}
              myNetworkFilter={myNetworkFilter}
              page={page}
              onChangePage={handleChangePage}
              onShortcutClick={handleShortcutClick}
              sortBy={sortBy}
              onUpdateSortBy={handleUpdateSortBy}
            />
          )}
          {activeSearch === "profiles" && (
            <ProfilesList
              email={email}
              profileTypeFilter={profileTypeFilter}
              textFilter={textFilter}
              myNetworkFilter={myNetworkFilter}
              page={page}
              onChangePage={handleChangePage}
              onShortcutClick={handleShortcutClick}
              sortBy={sortBy}
              onUpdateSortBy={handleUpdateSortBy}
            />
          )}
          {activeSearch === "posts" && (
            <PostsList
              email={email}
              textFilter={textFilter}
              myNetworkFilter={myNetworkFilter}
              page={page}
              onChangePage={handleChangePage}
              onShortcutClick={handleShortcutClick}
              sortBy={sortBy}
              onUpdateSortBy={handleUpdateSortBy}
            />
          )}
          {activeSearch === "news" && (
            <NewsList
              email={email}
              textFilter={textFilter}
              page={page}
              onChangePage={handleChangePage}
              onShortcutClick={handleShortcutClick}
              sortBy={sortBy}
              onUpdateSortBy={handleUpdateSortBy}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default View
