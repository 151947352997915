import React from "react"
import { TextField, TextFieldProps } from "@material-ui/core"

export type Props = TextFieldProps & {
  errorMessage?: string
  inputTestId?: string
}

const View = ({ errorMessage, inputTestId, ...props }: Props) => {
  return (
    <TextField
      {...props}
      error={!!errorMessage}
      helperText={errorMessage || ""}
      inputProps={{
        "data-testid": inputTestId || `${props.name}Input`,
      }}
    />
  )
}

export default View
