import React from "react"
import { IconButton, TextField, Box } from "@material-ui/core"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/DoneAllTwoTone"
import RevertIcon from "@material-ui/icons/NotInterestedOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import { EditableSpotlight } from "./index"
// import TextField from "../TextField"
import moment from "moment"

export type Props = {
  error?: any
  spotlight: EditableSpotlight
  onEdit: () => void
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
  onChange: (date: any | null, property: keyof EditableSpotlight) => void
  onDateChange: (date: Date | null, property: keyof EditableSpotlight) => void
}

const View = ({
  error,
  spotlight,
  onSave,
  onDelete,
  onCancel,
  onChange,
  onEdit,
  onDateChange,
}: Props) => {
  if (!spotlight.editing) {
    return (
      <Box
        style={{
          display: "flex",
          gap: "16px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box width={"50%"}>
          {spotlight.description
            ? spotlight.description
            : "No description set yet"}
        </Box>
        <Box width={"15%"}>
          {moment(spotlight.startDate).format("MMM Do YY")}
        </Box>
        <Box width={"15%"}>{moment(spotlight.endDate).format("MMM Do YY")}</Box>
        <Box width={"20%"} textAlign={"right"}>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <TextField
        name="description"
        multiline
        rows={3}
        label="Description"
        data-testid="description"
        onChange={({ currentTarget }) =>
          onChange(currentTarget.value, "description")
        }
        value={spotlight.description}
        error={error !== null}
        helperText={error ? error : null}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box style={{ display: "flex", gap: "16px" }}>
          <KeyboardDatePicker
            style={{ paddingBottom: "25px" }}
            disableToolbar
            variant="inline"
            label={"Start Date"}
            format="MM/dd/yyyy"
            value={spotlight.startDate}
            onChange={(date) => onDateChange(date, "startDate")}
            autoOk={true}
          />
          <KeyboardDatePicker
            style={{ paddingBottom: "25px" }}
            disableToolbar
            variant="inline"
            label={"End Date"}
            format="MM/dd/yyyy"
            value={spotlight.endDate}
            onChange={(date) => onDateChange(date, "endDate")}
            autoOk={true}
          />
          <IconButton onClick={onSave}>
            <DoneIcon />
          </IconButton>
          {spotlight.id && (
            <IconButton onClick={onCancel}>
              <RevertIcon />
            </IconButton>
          )}
        </Box>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default View
