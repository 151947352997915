import GA4React from "ga-4-react"
import environment from "../environment"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"
import { Product } from "../redux/product"

type EcommerceProps = {
  eventName: string
  tax?: number
  product: Product
}

export const logEvent = async (eventName: string) => {
  if (environment.device === "desktop") {
    const ga4react = new GA4React(process.env.REACT_APP_GA4_ID as any)
    ga4react.gtag("event", eventName)
  } else {
    await FirebaseAnalytics.logEvent({
      name: eventName,
      params: {},
    })
  }
}

export const logEcommerceEvent = async ({
  eventName,
  tax,
  product,
}: EcommerceProps) => {
  if (environment.device === "desktop") {
    const ga4react = new GA4React(process.env.REACT_APP_GA4_ID as any)

    ga4react.gtag("event", eventName, {
      price: `Total price: ${product.amount}`,
      ecommerce: {
        value: `${product.amount}`,
        items: [
          {
            item_name: `${product.title}`,
            item_id: `${product.id}`,
            price: (product.amount / 100).toFixed(2),
            item_category: ``,
            item_category2: ``,
            quantity: "1",
            tax: tax ? `${tax}` : "0",
          },
        ],
      },
    })
  } else {
    await FirebaseAnalytics.logEvent({
      name: eventName,
      params: {
        price: `Total price: ${product.amount}`,
        ecommerce: {
          value: `${product.amount}`,
          items: [
            {
              item_name: `${product.title}`,
              item_id: `${product.id}`,
              price: (product.amount / 100).toFixed(2),
              item_category: ``,
              item_category2: ``,
              quantity: "1",
              tax: tax ? `${tax}` : "0",
            },
          ],
        },
      },
    })
  }
}
