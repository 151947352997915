import React from "react"

import { Notification } from "../../redux/bellNotifications"

import View from "./View"

type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  goToNotifications?: boolean
  hideButton?: boolean
}

const NotificationProject = ({
  notification,
  goToNotifications,
  hideButton,
  onNotificationClick,
}: Props) => {
  return (
    <View
      notification={notification}
      onNotificationClick={onNotificationClick}
      goToNotifications={goToNotifications}
      hideButton={hideButton}
    />
  )
}

export default NotificationProject
