import React, { useCallback, useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"
import ImageIcon from "../../images/post-image-icon.svg"
import { RejectedFile, StyledDropzone } from "../../components/StyledDropzone"
import { MentionsInput, Mention } from "react-mentions"
import {
  Addon,
  AddonsBox,
  CounterBox,
  DialogButtonBox,
  DialogCloseButton,
  ErrorBox,
  ErrorItem,
  ImageThumbnail,
  ImageThumbnailContainer,
  ImagesBox,
  PostDialog,
  PostDialogBox,
  PostDialogTitle,
  ProfileBox,
  ProfileTitle,
  TextBox,
} from "./DialogStyles"
import { Profile, fetchProfileSuggestion } from "../../redux/profile"
import { Image } from "../../redux/projects"
import { Button } from "../Button"
import Avatar from "../Avatar"
import { NewStyledImageList } from "../NewStyledImageList"
import UpLoadingPage from "../../pages/UpLoadingPage"
import inputStyle from "./inputStyle"
import inputStyleSmallText from "./inputStyleSmallText"
import defaultMentionStyle from "../CommentTextField/defaultMentionStyle"
import { DescriptionBox, NameTitle, UserBox } from "../CommentTextField/styles"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { Post, PostMention } from "../../redux/posts"
import getFromBetween from "../../utils/getStringFromBetween"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Camera } from "@capacitor/camera"

export type Props = {
  post?: Post
  profile: Profile
  onClose: () => void
  onSubmit: (
    content: string,
    images: Image[],
    removedImages: string[],
    mentions?: PostMention[],
    postId?: string
  ) => void
  open: boolean
  currentFile: string
  isPosting: boolean
  isUploading: boolean
  isEditing?: boolean
  isImage?: boolean
}

const Dialog = ({
  post,
  profile,
  open,
  onClose,
  onSubmit,
  currentFile,
  isPosting,
  isUploading,
  isEditing,
  isImage,
}: Props) => {
  let defaultClearText = ""

  const CheckGalleryAccess = useCallback(async () => {
    try {
      const permission = await Camera.checkPermissions()
      if (permission.camera !== "granted" || permission.photos !== "granted") {
        await Camera.requestPermissions({ permissions: ["camera", "photos"] })
      }
    } catch (error) {
      console.log(error)
      console.log("failed to load camera")
    }
  }, [])

  if (post) {
    defaultClearText = post.content
    const separatedMentions = getFromBetween.get(defaultClearText, "@{{", ")}}")

    separatedMentions.forEach((value: string) => {
      const arr1 = value.split("}}")
      const mention = "@{{" + value + ")}}"
      defaultClearText = defaultClearText.replace(mention, `@${arr1[0]}`)
    })
  }

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const [hover, setHover] = useState<boolean>(false)
  const [images, setImages] = useState<Image[]>(
    post ? (post.images as Image[]) : []
  )
  const [errors, setErrors] = useState<RejectedFile[]>([])
  const [text, setText] = useState<string>(post ? post.content : "")
  const [clearText, setClearText] = useState<string>(defaultClearText)
  const [isAddingImage, setIsAddingImage] = useState<boolean>(
    isImage ? true : false
  )
  const [removedImages, setRemovedImages] = useState<string[]>([])

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  let inputEl = React.createRef<HTMLTextAreaElement>()

  const handleImagesChange = (
    newImages: Image[],
    imageErrors?: RejectedFile[]
  ) => {
    console.log("images ###")
    console.log(JSON.stringify(newImages))
    setErrors(imageErrors ? imageErrors : [])
    setImages(newImages)
    setIsAddingImage(false)
  }

  const handleGetSuggestions = async (query: string, callback: any) => {
    try {
      const { type, payload } = await dispatch(
        fetchProfileSuggestion({ auth, query })
      )
      if (fetchProfileSuggestion.fulfilled.type === type) {
        callback(payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleImageListChange = (newImages: Image[], removed?: string[]) => {
    setErrors([])

    if (removed && removed.length > 0) {
      handleRemovedImages(removed)
    }

    setImages(newImages)
  }

  const handleRemovedImages = (removed: string[]) => {
    const newRemovedImages: any = removedImages
    newRemovedImages.push(removed)
    setRemovedImages(newRemovedImages)
  }

  const MAX_CHAR_COUNT = 2200
  const characterCounter = (value: string) => {
    let newText = value
    const separatedMentions = getFromBetween.get(newText, "@{{", ")}}")

    separatedMentions.forEach((value: string) => {
      const arr1 = value.split("}}")
      const mention = "@{{" + value + ")}}"
      newText = newText.replace(mention, `@${arr1[0]}`)
    })

    const diff = value.length - newText.length

    if (value.length > MAX_CHAR_COUNT + diff) {
      value = value.substr(0, MAX_CHAR_COUNT + diff)
    }

    if (newText.length > MAX_CHAR_COUNT) {
      newText = newText.substr(0, MAX_CHAR_COUNT)
    }

    setText(value)
    setClearText(newText)
  }

  const handleClose = () => {
    onClose()
    setText("")
    setClearText("")
    setImages([])
    setIsAddingImage(false)
    setRemovedImages([])
    setErrors([])
  }

  const handleSubmit = () => {
    const mentions: PostMention[] = []
    const separatedMentions = getFromBetween.get(text, "@{{", ")}}")
    separatedMentions.forEach((value: string) => {
      const arr1 = value.split("}}")
      const arr2 = arr1[1].replace("{{(", "").split("|")

      mentions.push({
        name: arr1[0],
        profileId: arr2[0],
        username: arr2[1],
        fullString: "@{{" + value + ")}}",
      })
    })

    const postId = post ? post.id : undefined

    onSubmit(text, images, removedImages, mentions, postId)
    setText("")
    setImages([])
    setIsAddingImage(false)
    setRemovedImages([])
    setErrors([])
  }

  useEffect(() => {
    CheckGalleryAccess()
  }, [CheckGalleryAccess])

  return (
    <PostDialog
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      className={"PostDialog"}
      disableBackdropClick={true}
    >
      {isPosting || isUploading ? (
        <PostDialogBox>
          <UpLoadingPage
            message={
              isUploading
                ? `Uploading file ${currentFile}`
                : isEditing
                ? "Updating post"
                : "Creating post"
            }
          />
        </PostDialogBox>
      ) : (
        <>
          <PostDialogTitle>
            <Box>{isEditing ? `Edit post` : `Create post`}</Box>
            <DialogCloseButton
              src={hover ? CloseIconHover : CloseIcon}
              onMouseEnter={() => {
                setHover(true)
              }}
              onMouseLeave={() => {
                setHover(false)
              }}
              alt={"close"}
              onClick={handleClose}
            />
          </PostDialogTitle>
          <PostDialogBox>
            {isAddingImage ? (
              <>
                <StyledDropzone
                  onChange={handleImagesChange}
                  images={images ? images : []}
                />
                <DialogButtonBox>
                  <Button
                    onClick={() => setIsAddingImage(false)}
                    children={"Cancel"}
                    color={"primary"}
                    size={"medium"}
                    style={{
                      width: "100%",
                    }}
                  />
                </DialogButtonBox>
              </>
            ) : (
              <>
                <ProfileBox>
                  <Avatar profile={profile} width={32} height={32} />
                  <ProfileTitle>
                    {profile.type === "group"
                      ? profile.groupName
                      : profile.firstName + " " + profile.lastName}
                  </ProfileTitle>
                </ProfileBox>
                <ErrorBox>
                  {errors ? (
                    <>
                      {errors.map(({ file, errors }) => (
                        <ErrorItem key={`file-${file.name}`}>
                          {errors[0].message}
                        </ErrorItem>
                      ))}
                    </>
                  ) : null}
                </ErrorBox>
                {images.length > 0 && (
                  <ImagesBox>
                    <ImageThumbnailContainer>
                      <ImageThumbnail
                        $isHeightBigger={
                          images[0].dimensions?.height! >
                          images[0].dimensions?.width!
                        }
                        src={images[0].url}
                        alt={"Post image"}
                      />
                    </ImageThumbnailContainer>
                    <NewStyledImageList
                      images={images!}
                      onChange={handleImageListChange}
                      onSelect={(_) => {}}
                    />
                  </ImagesBox>
                )}
                <TextBox
                  $isSmallPost={
                    clearText.length > 0 &&
                    clearText.length <= 150 &&
                    images.length === 0
                  }
                >
                  <MentionsInput
                    inputRef={inputEl}
                    value={text}
                    onChange={(e) => characterCounter(e.target.value)}
                    style={
                      clearText.length > 0 &&
                      clearText.length <= 150 &&
                      images.length === 0
                        ? inputStyleSmallText
                        : inputStyle
                    }
                    allowSpaceInQuery
                    placeholder={
                      "Share your thoughts, architectural photography, or a recent vignette..."
                    }
                  >
                    <Mention
                      trigger={"@"}
                      markup={"@{{__display__}}{{(__id__)}}"}
                      displayTransform={(id, name) => `@${name}`}
                      data={handleGetSuggestions}
                      style={defaultMentionStyle}
                      appendSpaceOnAdd
                      renderSuggestion={(suggestion) => {
                        const newData = { ...suggestion } as any
                        return (
                          <UserBox>
                            <Avatar profile={newData} width={32} height={32} />
                            <DescriptionBox>
                              <NameTitle>{newData.name}</NameTitle>
                            </DescriptionBox>
                          </UserBox>
                        )
                      }}
                    />
                  </MentionsInput>
                </TextBox>
                <CounterBox>
                  {clearText.length}/{MAX_CHAR_COUNT} characters
                </CounterBox>
                <AddonsBox>
                  <Addon>
                    <span>Add:</span>
                  </Addon>
                  <Addon onClick={() => setIsAddingImage(true)}>
                    <img src={ImageIcon} alt={"Add"} /> Photo
                  </Addon>
                </AddonsBox>
                <DialogButtonBox>
                  <Button
                    onClick={handleSubmit}
                    disabled={
                      (images?.length === 0 && text.length === 0) ||
                      isPosting ||
                      isUploading
                    }
                    children={isEditing ? "Update" : "Post"}
                    color={"primary"}
                    size={"medium"}
                    style={{
                      width: "100%",
                    }}
                  />
                </DialogButtonBox>
              </>
            )}
          </PostDialogBox>
        </>
      )}
    </PostDialog>
  )
}

export default Dialog
