import React, { MouseEvent, useState } from "react"
import {
  DropDownBox,
  MenuItem,
  MenuItemArea,
  MenuBox,
  Divider,
  ButtonsArea,
  ClearFilter,
  ClearFilterActive,
} from "./Styles"
import DDDown from "../../images/dd-down.svg"
import DDUp from "../../images/dd-up.svg"
import Active from "../../images/dialog-checked.svg"
import Inactive from "../../images/dialog-not-checked.svg"
import { Popover } from "@material-ui/core"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import Dialog from "./Dialog"
import { Button } from "../Button"
import { CSSProperties } from "styled-components"

export type ActionsType = {
  display: string
  action: string
  active: boolean
}

export type Props = {
  onApply: (type: string, value: ActionsType[]) => void
  handleChangeStateActions: (selectedAction: ActionsType[]) => void
  handleChangeDefaultActions: (selectedAction: ActionsType[]) => void
  actions: ActionsType[]
  defaultActions: ActionsType[]
  title: string
  type: string
  styles?: CSSProperties
}

const View = ({
  handleChangeStateActions,
  handleChangeDefaultActions,
  onApply,
  defaultActions,
  actions,
  title,
  type,
  styles,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { width } = useWindowDimensions()

  const handleClose = () => {
    setAnchorEl(null)
    setDialogOpen(false)
    handleChangeStateActions(JSON.parse(JSON.stringify(defaultActions)))
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setDialogOpen(true)
  }

  const handleSelect = (selectedAction: ActionsType, key: number) => {
    const tempActions = [...actions]
    const tempAction = { ...selectedAction }
    tempAction.active = !tempAction.active
    tempActions[key] = tempAction
    handleChangeStateActions(tempActions)
  }

  const clearFilter = () => {
    const tempActions = [...actions]
    const changed = tempActions.map((action) => {
      const tempField = { ...action }
      tempField.active = false
      return { ...tempField }
    })
    handleChangeStateActions(changed)
  }

  const handleApply = async () => {
    setAnchorEl(null)
    setDialogOpen(false)
    onApply(type, actions)
    handleChangeDefaultActions(JSON.parse(JSON.stringify(actions)))
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DropDownBox
        onClick={handleClick}
        style={open ? { ...styles, border: "0.5px solid #2D2D2D" } : styles}
      >
        {title}
        <img src={open ? DDUp : DDDown} alt={"drop-down"} />
      </DropDownBox>
      {width <= theme.breakpoints.values.sm ? (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          onSelect={handleSelect}
          onApply={handleApply}
          title={title}
          actions={actions}
          type={type}
        />
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              borderRadius: 0,
              background: "transparent",
              boxShadow: "none",
              overflow: "hidden",
              paddingLeft: "15px",
              paddingBottom: "15px",
              paddingRight: "15px",
              marginLeft: "-15px",
            },
          }}
        >
          <MenuBox>
            <MenuItemArea>
              {actions?.map((action, key) => (
                <MenuItem
                  key={action.action}
                  onClick={() => handleSelect(action, key)}
                >
                  <img
                    style={{ width: "16px" }}
                    src={action.active ? Active : Inactive}
                    alt={"checked"}
                  />
                  {action.display}
                </MenuItem>
              ))}
            </MenuItemArea>
            <Divider />
            <ButtonsArea>
              {actions.find((value) => value.active) ? (
                <ClearFilterActive onClick={clearFilter}>
                  Clear Filter
                </ClearFilterActive>
              ) : (
                <ClearFilter>Clear Filter</ClearFilter>
              )}
              <Button
                onClick={handleApply}
                children={"Apply"}
                color={"secondary"}
                size={"small"}
                data-testid="apply-filters"
                style={{
                  minWidth: "62px",
                  height: "24px",
                  fontSize: "14px",
                }}
              />
            </ButtonsArea>
          </MenuBox>
        </Popover>
      )}
    </>
  )
}

export default View
