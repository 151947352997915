import React from "react"

import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Wizard from "./Wizard"

import { Collaborator, Image, Invite } from "../../redux/projects"
import { Profile } from "../../redux/profile"

export type Props = {
  isUploading: boolean
  currentFile: string
  error?: string
  profile: Profile
  onSubmit: Function
  onCancel: () => void
  typeSelected: string
  inviteCollaboratorDialogIsOpen: boolean
  onTypeSelected: (typeSelected: string) => void
  onChangeInviteCollaboratorDialog: (value: boolean) => void
}

export type Project = {
  name: string
  description: string
  category: string[]
  state: string
  stateDate?: Date
  location: string
  role?: string
  images: Array<Image>
  tags: Array<string>
  tools: Array<string>
  keyMaterials: Array<string>
  published: boolean
  type: string
  firmName?: string
  collaborators?: Collaborator[]
  invites?: Invite[]
}

const initialValues: Project = {
  name: "",
  description: "",
  category: [],
  state: "",
  stateDate: undefined,
  location: "",
  role: "",
  images: [],
  tags: [],
  tools: [],
  keyMaterials: [],
  published: false,
  type: "",
  firmName: "",
  collaborators: [
    {
      discipline: "",
      company: "",
      name: "",
      profileId: "",
      id: "",
    },
  ],
  invites: [
    {
      email: "",
      discipline: "",
      company: "",
    },
  ],
}

const View = ({
  onSubmit,
  onCancel,
  error,
  profile,
  isUploading,
  currentFile,
  typeSelected,
  onTypeSelected,
  inviteCollaboratorDialogIsOpen,
  onChangeInviteCollaboratorDialog,
}: Props) => {
  return (
    <div>
      <Wizard
        initialValues={initialValues}
        onSubmit={async (values: Project, published: boolean) =>
          onSubmit(values, published)
        }
        error={error}
        profile={profile}
      >
        <Step1 onCancel={onCancel} />
        <Step2
          onCancel={onCancel}
          currentFile={currentFile}
          isUploading={isUploading}
          typeSelected={typeSelected}
          onTypeSelected={onTypeSelected}
          profile={profile}
        />
        <Step3
          profile={profile}
          onCancel={onCancel}
          currentFile={currentFile}
          isUploading={isUploading}
          inviteCollaboratorDialogIsOpen={inviteCollaboratorDialogIsOpen}
          onChangeInviteCollaboratorDialog={onChangeInviteCollaboratorDialog}
        />
      </Wizard>
    </div>
  )
}

export default View
