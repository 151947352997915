import React from "react"

import Header from "./Header"

import {
  MainContainer,
  HowItWorksBox,
  HowItWorksContainer,
  DescriptionFont,
  DescriptionBox,
  DescriptionContainer,
  DescriptionIconBox,
  HowItWorksTitle,
  HowItWorksList,
  HowItWorksListItem,
  HowItWorksDescriptionBox,
  DescriptionFlexBox,
  DescriptionLeftBox,
  HowItWorksBoxBordered,
  HowItWorksInnerTitle,
  HowItWorksInnerList,
  HeaderButtonBox,
  RegisterContainer,
  RegisterBox,
  RegisterContent,
  RequirementsBox,
  RequirementsContainer,
  RequirementsTitle,
  RequirementsFlexBox,
  RequirementsLeftBox,
  RequirementsInnerTitle,
  RequirementsItem,
  QuotesBox,
  QuotesContainer,
  QuotesFlexBox,
  QuotesDescription,
  QuotesDescriptionBox,
  QuotesImageBox,
  QuotesHint,
  RegisterTitle,
  HowToWinImage,
  OportinityBox,
  OportinityItem,
} from "./styles"

import spBigIcon from "../../images/innovation-awards/sp-big-icon.svg"
import awardsIcon from "../../images/innovation-awards/awards-icon.svg"
import MoneyIcon from "../../images/studio-awards/money-icon.svg"
import NominateIcon from "../../images/studio-awards/nominate-icon.svg"
import ConnectionIcon from "../../images/studio-awards/connection-icon.svg"

import RegisterDialog from "./RegisterDialog"
import { Button } from "../../components"
import BackButton from "../../components/BackButton"
import environment from "../../environment"
import { Competition } from "../../redux/competition"

import JeanneGang from "../../images/innovation-awards/jeanne-gang.jpg"
import ThomMayne from "../../images/innovation-awards/thom-mayne.jpg"

import JeanneGangMobile from "../../images/innovation-awards/jury-1-mobile.jpg"
import ThomMayneMobile from "../../images/innovation-awards/jury-2-mobile.jpg"

import HowToWin from "../../images/innovation-awards/how-to-win.png"
import { Box } from "@material-ui/core"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import {
  ProjectsBox,
  ProjectsButtonBox,
  ProjectsContainer,
  ProjectsContent,
  ProjectsImageBox,
  ProjectsText,
  ProjectsTitle,
} from "../WeekendCompetitionPage/styles"
import InnovationAwardsAnnouncement from "../../components/InnovationAwardsAnnouncement"

interface Props {
  goToForm: () => void
  onCloseRegisterDialog: () => void
  onOpenRegisterDialog: (type: string) => void
  goToProjects: () => void
  isAuthenticated: boolean
  isRegisterDialogOpen: boolean
  competition?: Competition
}

const View = ({
  goToForm,
  onCloseRegisterDialog,
  onOpenRegisterDialog,
  goToProjects,
  isAuthenticated,
  isRegisterDialogOpen,
  competition,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const renderHTML = (rawHTML: string) => {
    const html = rawHTML?.replace(
      "{awardsIcon}",
      `<img src='${awardsIcon}' alt='awards' />`
    )
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: html },
    })
  }

  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <BackButton
        style={{
          marginLeft: "20px",
          position: "fixed",
          marginTop: environment.device === "ios-app" ? "0" : "",
        }}
      />
      <Header competition={competition} />
      <DescriptionBox>
        <DescriptionContainer>
          <DescriptionFlexBox>
            <DescriptionLeftBox>
              <DescriptionFont variant={"body1"}>
                {renderHTML(competition?.description!)}
              </DescriptionFont>
              {competition?.status === "open" && (
                <HeaderButtonBox>
                  <Button
                    onClick={goToForm}
                    children={"Apply now"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                </HeaderButtonBox>
              )}
            </DescriptionLeftBox>
            <DescriptionIconBox>
              <img src={spBigIcon} alt={"SP Icon"} />
            </DescriptionIconBox>
          </DescriptionFlexBox>
        </DescriptionContainer>
      </DescriptionBox>

      <div id="winners">
        {competition?.winners?.winners &&
          competition.winners?.winners?.length >= 3 && (
            <>
              <InnovationAwardsAnnouncement competition={competition} />
            </>
          )}
      </div>

      <QuotesBox>
        <QuotesContainer>
          <QuotesFlexBox>
            <QuotesDescriptionBox>
              <QuotesDescription variant={"h2"}>
                “Spectacular is a good opportunity for young designers to
                promote their work and have someone react to it.”
              </QuotesDescription>
              <QuotesHint>Jeanne Gang</QuotesHint>
            </QuotesDescriptionBox>
            <QuotesImageBox>
              <img
                src={isMobile ? JeanneGangMobile : JeanneGang}
                alt="Jeanne Gang"
              />
            </QuotesImageBox>
          </QuotesFlexBox>
        </QuotesContainer>
      </QuotesBox>

      <RequirementsBox id={"requirementsArea"}>
        <RequirementsContainer>
          <RequirementsTitle variant={"h1"}>
            This is your opportunity to:
          </RequirementsTitle>
        </RequirementsContainer>
      </RequirementsBox>

      <RequirementsBox>
        <RequirementsContainer>
          <OportinityBox>
            <OportinityItem>
              <img src={ConnectionIcon} alt="Award Winner" />
              Grow your
              <br />
              network
            </OportinityItem>
            <OportinityItem>
              <img src={NominateIcon} alt="Award Winner" />
              Gain notoriety for
              <br />
              your design work
            </OportinityItem>
            <OportinityItem>
              <img src={MoneyIcon} alt="Award Winner" />
              Become an award
              <br />
              winner and receive $300
            </OportinityItem>
          </OportinityBox>
        </RequirementsContainer>
      </RequirementsBox>

      <RequirementsBox id={"requirementsArea"}>
        <RequirementsContainer>
          <RequirementsTitle variant={"h1"}>Requirements</RequirementsTitle>
        </RequirementsContainer>
      </RequirementsBox>

      <RequirementsBox>
        <RequirementsContainer>
          <RequirementsFlexBox>
            <RequirementsLeftBox>
              {competition?.requirements?.left?.map((item) => {
                return (
                  <Box>
                    <RequirementsInnerTitle>
                      {item.title}
                    </RequirementsInnerTitle>
                    {item.items.map((subItem) => {
                      return (
                        <RequirementsItem>
                          {renderHTML(subItem.item)}
                        </RequirementsItem>
                      )
                    })}
                  </Box>
                )
              })}
            </RequirementsLeftBox>
          </RequirementsFlexBox>
        </RequirementsContainer>
      </RequirementsBox>

      <QuotesBox>
        <QuotesContainer>
          <QuotesFlexBox>
            <QuotesDescriptionBox>
              <QuotesDescription variant={"h2"}>
                “There are some people doing some very interesting things that
                don’t get talked about enough”
              </QuotesDescription>
              <QuotesHint>Thom Mayne</QuotesHint>
            </QuotesDescriptionBox>
            <QuotesImageBox>
              <img
                src={isMobile ? ThomMayneMobile : ThomMayne}
                alt="Thom Mayne"
              />
            </QuotesImageBox>
          </QuotesFlexBox>
        </QuotesContainer>
      </QuotesBox>

      <HowItWorksBoxBordered>
        <HowItWorksContainer>
          <HowItWorksTitle variant={"h1"}>How to win:</HowItWorksTitle>
        </HowItWorksContainer>
      </HowItWorksBoxBordered>
      {competition?.howItWorks?.map((row, key) => {
        return (
          <HowItWorksBox>
            <HowItWorksContainer>
              <HowItWorksDescriptionBox
                style={
                  key === competition.howItWorks.length - 1
                    ? { borderBottom: 0 }
                    : {}
                }
              >
                <HowItWorksList>
                  <HowItWorksInnerTitle variant={"h2"}>
                    {row.title}
                  </HowItWorksInnerTitle>
                  <ol>
                    {row.items?.map((item) => {
                      return (
                        <HowItWorksListItem>
                          <li>{renderHTML(item.item)}</li>
                          {item.subItem?.map((subItem) => {
                            return (
                              <HowItWorksInnerList>
                                <span>a.</span> {renderHTML(subItem)}
                              </HowItWorksInnerList>
                            )
                          })}
                        </HowItWorksListItem>
                      )
                    })}
                  </ol>
                </HowItWorksList>
              </HowItWorksDescriptionBox>
              <HowToWinImage src={HowToWin} alt="How to Win" />
            </HowItWorksContainer>
          </HowItWorksBox>
        )
      })}

      {competition?.projects && (
        <ProjectsBox>
          <ProjectsContainer>
            <ProjectsContent>
              <ProjectsTitle variant={"h1"}>
                {competition.projects.title}
              </ProjectsTitle>
              <ProjectsText>
                {renderHTML(competition.projects.copy)}
              </ProjectsText>
              <ProjectsButtonBox>
                <Button
                  onClick={goToProjects}
                  children={"View Submissions"}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    width: "194px",
                    height: "48px",
                    fontSize: "16px",
                  }}
                />
              </ProjectsButtonBox>
            </ProjectsContent>
            <ProjectsImageBox>
              <img src={competition.projects.image} alt={"Projects"} />
            </ProjectsImageBox>
          </ProjectsContainer>
        </ProjectsBox>
      )}

      {competition?.registerOpen && (
        <RegisterBox id="register">
          <RegisterContainer>
            <RegisterContent>
              <RegisterTitle variant="h2">Apply here</RegisterTitle>
              <HeaderButtonBox>
                <Button
                  onClick={() => onOpenRegisterDialog("individual")}
                  children={"Apply now"}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    width: "220px",
                    height: "48px",
                    fontSize: "16px",
                  }}
                />
              </HeaderButtonBox>
            </RegisterContent>
          </RegisterContainer>
        </RegisterBox>
      )}

      <RegisterDialog
        open={isRegisterDialogOpen}
        onClose={onCloseRegisterDialog}
        campaign={competition?.code!}
      />
    </MainContainer>
  )
}

export default View
