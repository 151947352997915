import React from "react"
import ModalVideo from "react-modal-video"
import { useHistory } from "react-router-dom"
import { Box, Button, Grid, Typography } from "@material-ui/core"

import { RegisterForm, SubmitHandler } from "./RegisterForm"
import "./modal-video.css"

import Logo from "../../images/logo-charcoal.svg"
import BackArrow from "../../images/icon-back-arrow.svg"
import HeroImage1x from "../../images/home-building.jpg"

import {
  MobileHeroGrid,
  HeroImage,
  LoginSpan,
  VideoPlay,
  VideoPlayText,
} from "./Styles"
import { useAuth0 } from "@auth0/auth0-react"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"

const introVideoURL = `https://players.brightcove.net/5454530067001/xGo0KqA5Q_default/index.html?videoId=6252923902001`

const View: React.FC<{ onSubmit: SubmitHandler; showSSOText: boolean }> = ({
  onSubmit,
  showSSOText,
}) => {
  const history = useHistory()
  const { loginWithRedirect } = useAuth0()
  const [step, setStep] = React.useState(1)
  const [play, setPlay] = React.useState(false)
  const [showHero, setShowHero] = React.useState(true)

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  const renderStep = () => {
    if (step === 2)
      return (
        <Grid item xs={12} style={{ padding: "40px 20px 40px" }}>
          {/*Content above the form*/}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                alignSelf: "center",
                width: "400px",
              }}
            >
              {/*Back button*/}
              <Grid
                onClick={() => {
                  setShowHero(true)
                  setStep(1)
                }}
                style={{ paddingBottom: "24px", cursor: "pointer" }}
              >
                <img
                  src={BackArrow}
                  alt="back-arrow"
                  style={{ height: "12px" }}
                />
              </Grid>

              {/*Spectacular logo*/}
              <img
                src={Logo}
                alt="logo-spectacular"
                style={{ height: "20px", paddingBottom: "24px" }}
              />

              <Typography
                variant="h3"
                style={{
                  fontSize: "18px",
                  color: "#2d2d2d",
                  marginTop: "-11px",
                  fontFamily: "Public-Sans",
                  paddingBottom: "8px",
                }}
              >
                Create an account
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: "14px",
                  color: "#2d2d2d",
                  fontFamily: "Public-Sans",
                  marginBottom: "-3px",
                  paddingBottom: "24px",
                }}
              >
                Sign up to create your individual or group online portfolio,
                follow architects and groups, and browse and create job
                listings.{" "}
              </Typography>
            </Box>
          </Box>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <RegisterForm
              mobile
              onSubmit={onSubmit}
              showSSOText={showSSOText}
            />
          </div>
        </Grid>
      )

    return (
      <Grid item xs={12} style={{ padding: "32px 20px 0px" }}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={Logo}
              alt="logo-spectacular"
              style={{ height: "32px", paddingBottom: "10px" }}
            />
          </Grid>

          <Typography
            variant="body1"
            style={{
              fontSize: "20px",
              color: "#2d2d2d",
              textAlign: "center",
              paddingBottom: "28px",
            }}
          >
            The professional network for architecture and design
          </Typography>

          <div style={{ paddingBottom: "8px", width: "100%" }}>
            <Button
              color="primary"
              onClick={() => {
                setShowHero(false)
                setStep(2)
              }}
              style={{
                height: "48px",
                width: "100%",
                fontSize: "14px",
              }}
            >
              Create an account
            </Button>
          </div>

          <div style={{ paddingBottom: "12px", width: "100%" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                history.push(`/`)
              }}
              style={{
                height: "48px",
                width: "100%",
                fontSize: "14px",
                border: "1px solid #2d2d2d",
              }}
            >
              Explore Spectacular
            </Button>
          </div>

          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body1"
              style={{
                fontSize: "12px",
                color: "#2d2d2d",
                textAlign: "center",
                paddingBottom: "40px",
              }}
            >
              Already have an account?{" "}
              <LoginSpan onClick={login}>Login</LoginSpan>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <MobileHeroGrid step={step} container>
        {showHero && (
          <>
            <ModalVideo
              autoplay
              isOpen={play}
              channel="custom"
              url={introVideoURL}
              onClose={() => setPlay(false)}
            />

            <Grid item xs={12}>
              <HeroImage
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage: "url(" + HeroImage1x + ")",
                }}
              >
                <VideoPlay onClick={() => setPlay(true)}>
                  <VideoPlayText>Play</VideoPlayText>
                </VideoPlay>
              </HeroImage>
            </Grid>
          </>
        )}
        {renderStep()}
      </MobileHeroGrid>
      <div style={{ marginBottom: "-18px" }} />
    </>
  )
}

export default View
