import React, { useState } from "react"
import View from "./View"
import MobileView from "./MobileView"
import * as RouteConstants from "../../constants/routes"
import { normalizeProjectTitle } from "../../utils"
import {
  confirmConnectionRequest,
  denyConnectionRequest,
  Profile,
  sendNotificationEmailAndRemoveSuggestion,
} from "../../redux/profile"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { updateProfileConnetionRequestState } from "../../redux/connections"

export type Props = {
  profile: Profile
  source?: string
  confirmButtons?: boolean
  isSuggestion?: boolean
}

const ProfileListingCard = ({
  profile,
  confirmButtons,
  source,
  isSuggestion,
}: Props) => {
  const { width } = useWindowDimensions()

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const handleConfirmConnection = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        confirmConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (confirmConnectionRequest.fulfilled.type === type) {
        await dispatch(
          updateProfileConnetionRequestState({
            profileId: connectionId!,
            type: "requests",
          })
        )

        await dispatch(
          sendNotificationEmailAndRemoveSuggestion({
            auth,
            profileId: connectionId!,
            templateName: "PROFILE_CONNECTION_ACCEPTED",
          })
        )
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleDenyConnection = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        denyConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (denyConnectionRequest.fulfilled.type === type) {
        await dispatch(
          updateProfileConnetionRequestState({
            profileId: connectionId!,
            type: "requests",
          })
        )

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const goToProject = (projectData: any) => {
    return `${RouteConstants.PROFILE_CONTAINER}/${
      projectData?.username
    }/projects/${projectData?.id!}/${normalizeProjectTitle(projectData?.name!)}`
  }

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${profile.username}`
  }

  const goToEditProfile = () => {
    history.push("/profile/edit")
  }

  const isCurrentProfile =
    authenticatedProfile?.connectionId === profile.connectionId

  return (
    <>
      {width >= theme.breakpoints.values.sm ? (
        <View
          profile={profile}
          goToProject={goToProject}
          goToProfile={goToProfile}
          goToEditProfile={goToEditProfile}
          source={source}
          isCurrentProfile={isCurrentProfile}
          confirmButtons={confirmButtons}
          isLoading={isLoading}
          onConfirmConnection={handleConfirmConnection}
          onDenyConnection={handleDenyConnection}
          isSuggestion={isSuggestion}
        />
      ) : (
        <MobileView
          profile={profile}
          goToProject={goToProject}
          goToProfile={goToProfile}
          goToEditProfile={goToEditProfile}
          isCurrentProfile={isCurrentProfile}
          source={source}
          confirmButtons={confirmButtons}
          isLoading={isLoading}
          onConfirmConnection={handleConfirmConnection}
          onDenyConnection={handleDenyConnection}
          isSuggestion={isSuggestion}
        />
      )}
    </>
  )
}

export default ProfileListingCard
