import React, { useState } from "react"
import { Profile } from "../../redux/profile"
import Avatar from "../Avatar"
import {
  LowerBox,
  NewPostBox,
  PostButton,
  SuggestedItem,
  UpperBox,
} from "./styles"

import PostIcon from "../../images/new-post-icon.svg"
import { useAuth0 } from "@auth0/auth0-react"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useHistory } from "react-router-dom"
import PostOptionsDialog from "../PostOptionsDialog"

type Props = {
  profile: Profile
  origin: "profile" | "homepage" | "projects" | "update-nav" | "image-nav"
}

const View = ({ profile, origin }: Props) => {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [isOptionsOpen, setOptionsIsOpen] = useState(false)

  const handleOpenDialog = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    if (origin === "projects") {
      history.push("/projects/new")
    }

    setOptionsIsOpen(true)
  }

  return (
    <NewPostBox>
      <UpperBox>
        <Avatar profile={profile} width={32} height={32} />
        <PostButton onClick={handleOpenDialog}>
          Share
          <img src={PostIcon} alt={"Share"} />
        </PostButton>
      </UpperBox>
      <LowerBox>
        <SuggestedItem>
          <span>Suggested:</span>
        </SuggestedItem>
        {origin === "projects" ? (
          <SuggestedItem>Projects</SuggestedItem>
        ) : (
          <>
            <SuggestedItem>Sketches</SuggestedItem>
            <SuggestedItem>Architectural Photographs</SuggestedItem>
            <SuggestedItem>Technical Questions</SuggestedItem>
            <SuggestedItem>Career Updates</SuggestedItem>
          </>
        )}
      </LowerBox>

      {isOptionsOpen && (
        <PostOptionsDialog
          open={isOptionsOpen}
          onClose={() => setOptionsIsOpen(false)}
        />
      )}
    </NewPostBox>
  )
}

export default View
