import React from "react"
import {
  Select,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@material-ui/core"
import styled from "styled-components"
import { FieldAttributes, useField } from "formik"

import { ReactComponent } from "../../images/chevron-down.svg"
import theme from "../../theme"

const Title = styled(Typography)`
  font-size: 16px;

  && span {
    color: #ff0000;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

const DropdownIcon = styled(ReactComponent)`
  margin-top: 7px;
  margin-right: 5px;
`

const StyledControl = styled(FormControl)`
  margin-top: 7px !important;
  margin-bottom: 0px !important;

  && {
    .MuiSelect-root {
      padding: 15px 12px !important;
    }

    .MuiInputLabel-filled {
      transform: translate(12px, 15px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.75);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }
  }
`

export type SelectOption = {
  name: string | number
  value: string | number
}

export type Props = {
  mobile?: any
  style?: React.CSSProperties
  blank?: string | undefined
  options: Array<SelectOption>
  onChange?: (value: string) => void
  asterisk?: boolean
}

const SelectFieldLabelAbove = (props: Props & FieldAttributes<any>) => {
  const [field, meta, helpers] = useField(props)
  let hasError = !!(meta.touched && (meta.error || meta.value === ""))

  if (props.disabled) hasError = false

  const { setValue } = helpers
  const labelId = `${props.name}-label`

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    setValue(event.target.value)

    if (props.onChange) props.onChange(event.target.value)
  }

  return (
    <div>
      {props.label && (
        <Title>
          {props.label}
          {props.asterisk && <span>*</span>}
        </Title>
      )}
      <StyledControl error={hasError} {...props}>
        <Select
          IconComponent={DropdownIcon}
          labelId={labelId}
          id={props.name}
          value={field.value || ""}
          onChange={handleChange}
          displayEmpty
          style={props.style ? { ...props.style } : {}}
        >
          {props.blank && (
            <MenuItem key="" value="">
              {props.blank}
            </MenuItem>
          )}

          {props.options.map(({ value, name }: SelectOption) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>

        {hasError && (
          <FormHelperText style={{ paddingBottom: "4px" }}>
            {meta.error}
          </FormHelperText>
        )}
      </StyledControl>
    </div>
  )
}

export default SelectFieldLabelAbove
