import React, { useState } from "react"

import View from "./View"

type Props = {
  initialSkills?: string[]
  onChange: (values: string[]) => void
}

const ProfileSkills = ({ initialSkills, onChange }: Props) => {
  const [displayError, setDisplayError] = useState<boolean>(false)
  const [skills, setSkills] = useState<string[]>(initialSkills || [])
  const defaultOptions = [
    "Project Management",
    "Public Speaking",
    "Rhino",
    "AutoCAD",
    "Grasshopper",
    "SketchUp",
    "ArchiCAD",
    "Revit",
    "Vector",
    "3D-printed models",
    "Physical models",
    "3Ds Max",
    "After Effects",
    "Dynamo",
    "InDesign",
    "Maxwell Render",
    "Photoshop",
    "Python",
    "Unity",
    "V-Ray",
    "Augmented Reality",
    "Other",
  ]
  const saveSkill = async (skills: string[]) => {
    setSkills(skills)
    onChange(skills)
    setDisplayError(false)
  }

  const handleChange = (newSkill: string) => {
    const otherSkill = !defaultOptions.includes(newSkill)

    if (otherSkill) {
      const existingOther = skills.filter((e) => !defaultOptions.includes(e))
      const existingOtherStr = existingOther.toString()

      let updatedSkills = skills.filter(
        (item) => !item.includes(existingOtherStr)
      )

      if (!existingOtherStr) updatedSkills = skills.filter((item) => item)

      saveSkill([...updatedSkills, newSkill])
    } else {
      if (skills.find((skill) => skill === newSkill)) {
        saveSkill(skills.filter((skill) => skill !== newSkill))
      } else saveSkill([...skills, newSkill])
    }
  }

  return (
    <View skills={skills} onChange={handleChange} displayError={displayError} />
  )
}

export default ProfileSkills
