import React from "react"

import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import { useLocation } from "react-router-dom"

import View from "./View"

const POPUP_KEY = "SP_SIGN_UP_POPUP"

const NotificationPopUp: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false)

  const { width } = useWindowDimensions()
  const location = useLocation()

  const handleClose = () => {
    setOpen(false)
    sessionStorage.setItem(POPUP_KEY, "true")
  }

  React.useEffect(() => {
    if (
      sessionStorage.getItem(POPUP_KEY) === null &&
      location.pathname !== "/register"
    )
      setOpen(true)
    if (location.pathname === "/register" && open) setOpen(false)
    //eslint-disable-next-line
  }, [location.pathname])

  return (
    <>
      {width > theme.breakpoints.values.md && (
        <View open={open} onClose={handleClose} />
      )}
    </>
  )
}

export default NotificationPopUp
