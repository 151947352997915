import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const MentionsBox = styled(Box)<{
  $hasInnerButton?: boolean
  $replyFromDetails?: boolean
  $isEditMode?: boolean
}>`
  display: ${({ $hasInnerButton, $replyFromDetails }) =>
    $hasInnerButton || $replyFromDetails ? "flex" : "block"};
  margin-top: ${({ $hasInnerButton }) => ($hasInnerButton ? "10px" : "0")};
  width: 100%;
  background-color: ${({ $hasInnerButton, $replyFromDetails }) =>
    $hasInnerButton ? "#FFF" : $replyFromDetails ? "#f7f7f7" : "none"};
  align-items: center;
  border: 0.369847px solid #dadada;

  && textarea {
    line-height: 20px !important;
    font-family: Public-Sans !important;
    width: 100% !important;
    color: ${({ $hasInnerButton }) =>
      $hasInnerButton ? "#858585 !important" : "#434343 !important"};

    padding: ${({ $hasInnerButton }) =>
      $hasInnerButton ? "11px 10px !important" : "17px 20px !important"};
    margin-top: ${({ $isEditMode }) => ($isEditMode ? "17px !important" : "0")};
    border: 0 !important;
    &:focus {
      border: 0 !important;
      outline: none !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 0;
  }
`

export const UserBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;

  && img {
    border-radius: 50%;
  }
`

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
`

export const DescriptionBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const NameTitle = styled(Typography)`
  font-size: 14px;
  color: #2d2d2d;
  font-family: Public-Sans-Medium;
`

export const Address = styled(Typography)`
  color: #717171;
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 2px;
`

export const PostButton = styled(Typography)`
  color: rgba(48, 87, 225, 0.5);
  font-size: 14px;
  cursor: pointer;
  padding-right: 16px;
  font-family: Public-Sans-Bold;

  &:hover {
    color: #3057e1;
  }
`
