import React from "react"
import View from "./View"

export type Props = {
  message: string
}

const UpLoadingPage = ({ message }: Props) => <View message={message} />

export default UpLoadingPage
