import { Box, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../../theme"

export const DeleteContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const DeleteBox = styled(Box)`
  width: 100%;
  max-width: 740px;
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;

  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;
    border: 0;
    box-sizing: border-box;
  }
`

export const DeleteTitle = styled(Typography)`
  font-size: 24px;
  padding: 0 40px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #d8d8d8;
  font-family: Public-Sans-Medium;

  ${theme.breakpoints.down("xs")} {
    padding: 32px 0;
    height: auto;
  }
`

export const DeleteText = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  padding: 32px 40px 24px;

  ${theme.breakpoints.down("xs")} {
    padding: 32px 0 24px;
  }
`

export const DeleteButtonBox = styled(Box)`
  padding: 0 40px 32px;
  display: flex;
  gap: 32px;
  align-items: center;
  
  ${theme.breakpoints.down("xs")} {
    padding: 0
    width: 100%;
    flex-flow: column;
    justify-content: center;
  }
`

export const StyledA = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: #717171;

  :hover {
    color: #000000;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #717171;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
    color: #000000;
  }
`
