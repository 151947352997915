import React, { MouseEventHandler } from "react"
import { Button } from "@material-ui/core"

export type Props = {
  onLogin: MouseEventHandler
  style?: React.CSSProperties
}

const View = ({ onLogin, ...props }: Props) => {
  return (
    <Button
      {...props}
      color="primary"
      data-testid="login-button"
      onClick={onLogin}
    >
      Sign in
    </Button>
  )
}

export default View
