import React, { useState } from "react"
import { ActionsType } from "../DropDownMultiselect/View"
import View from "./View"

type Props = {
  onClose: () => void
  // onSetHasMore: (value: boolean) => void
  // onChangeAction: (value: string) => void
  handleApply: (value: any) => void
  open: boolean
  workplaceType: ActionsType[]
  employmentType: ActionsType[]
  experienceLevel: ActionsType[]
}

const FilterJobDialog = ({
  open,
  onClose,
  // onSetHasMore,
  // onChangeAction,
  handleApply,
  workplaceType,
  employmentType,
  experienceLevel,
}: Props) => {
  const [stateActions, setStateActions] = useState({
    workplaceType: workplaceType,
    employmentType: employmentType,
    experienceLevel: experienceLevel,
  })

  const [defaultActions, setDefaultActions] = useState({
    workplaceType: JSON.parse(JSON.stringify(workplaceType)),
    employmentType: JSON.parse(JSON.stringify(employmentType)),
    experienceLevel: JSON.parse(JSON.stringify(experienceLevel)),
  })

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async () => {
    onClose()
    // onChangeAction("SEARCH")
    // onSetHasMore(true)
    handleApply(stateActions)
  }

  const handleChangeStateActions = (changedActions: any) => {
    setStateActions(changedActions)
  }

  const handleChangeDefaultActions = (changedActions: any) => {
    setDefaultActions(changedActions)
  }

  return (
    <View
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={open}
      stateActions={stateActions}
      defaultActions={defaultActions}
      handleChangeStateActions={handleChangeStateActions}
      handleChangeDefaultActions={handleChangeDefaultActions}
    />
  )
}

export default FilterJobDialog
