import styled from "styled-components"
import { Box, Typography, Tab, Tabs as MUITabs } from "@material-ui/core"
import theme, { colors } from "../../theme"

export const DashboardContainer = styled(Box)`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-flow: nowrap;
  margin-top: -40px;
  margin-bottom: -64px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    min-height: fit-content;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const TabsBox = styled(Box)`
  background: #f7f7f7;
  padding: 48px 0;
  min-width: 337px;
  box-sizing: border-box;
  min-height: calc(100vh - 300px);

  ${theme.breakpoints.down("sm")} {
    align-items: center;
    background: ${colors.white};
    border-bottom: solid 0.5px ${colors.mediumGray};
    display: flex;
    height: 56px;
    min-height: 0;
    min-width: 100%;
    padding: 20px;
  }
`

export const ProfileBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 48px;
`

export const ProfilePicture = styled(Box)`
  width: 48px;
  display: flex;
`

export const ProfileLabel = styled(Typography)`
  font-size: 16px;
  color: #2d2d2d;
`

export const Tabs = styled(MUITabs)`
  .MuiTabs-flexContainerVertical {
    gap: 13px !important;
  }

  .Mui-selected p {
    font-weight: 700 !important;
  }
`

export const TabLabel = styled(Typography)`
  font-size: 16px;
  text-transform: none;
`

export const TabItem = styled(Tab)`
  padding: 0 0 0 48px;
  opacity: 1;
  .MuiTab-wrapper {
    align-items: flex-start !important;
  }
`
export const ButtonBox = styled(Box)`
  padding: 32px 0 0 48px;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    width: 100%;
  }
`

export const MobileTab = styled(Box)`
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
  font-size: 16px;
`

export const MobileTabActive = styled(Box)`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 350;
  line-height: 22px;
`

export const MobileTabArea = styled(Box)`
  display: flex;
`

export const ContentBox = styled(Box)`
  width: 100%;
  padding: 48px 48px 0;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`
