import React, { useState } from "react"
import { Box, Link, Grid, Typography } from "@material-ui/core"
import EducationForm from "../../components/EducationForm"
import { Education } from "../../redux/educations"
import ConfirmDialog from "../../components/ConfirmDialog"

export type Props = {
  education?: Education
  onSubmit: (values: Education) => void
  onDelete: (education?: Education) => void
}

const View = ({ education, onSubmit, onDelete }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const showConfirmation = () => {
    setIsDialogOpen(true)
  }

  return (
    <Box paddingLeft={2} paddingRight={2}>
      <Grid container justify="center">
        <Grid item lg={3} sm={6}>
          <Box marginTop={5}>
            <Typography tabIndex={0} variant="h5">
              Edit education
            </Typography>
          </Box>
          <Box marginTop={2} marginBottom={2}>
            <Link onClick={showConfirmation} color="error">
              Delete education
            </Link>
          </Box>
        </Grid>
      </Grid>
      <EducationForm education={education} onSubmit={onSubmit} />
      <ConfirmDialog
        title="Delete education"
        message="Are you sure you want to delete this education?"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => onDelete(education)}
      />
    </Box>
  )
}

export default View
