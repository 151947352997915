import React, { useState } from "react"
import { Post, PostMention } from "../../redux/posts"
import {
  Divider,
  CardBox,
  PostCardActions,
  PostCardContentBigText,
  PostCardContentSmallText,
  PostCardContentImage,
  PostCardFooter,
  PostCardHeader,
  ProfileTitle,
  PostCardSmallText,
  PostCardBigText,
  ImageThumbnail,
  BlockingBox,
  CommentsBox,
  CommentsInnerBox,
  CommentsLabel,
  CommentsBelowBox,
  DividerBorder,
  SeeMoreButton,
  PostCardBigTextBelow,
  LinkBox,
  HeaderBox,
  WhiteGradient,
  SpectacularAvatar,
} from "./styles"
import Avatar from "../../components/Avatar"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import Carousel from "react-material-ui-carousel"

import ArrowRightDesktop from "../../images/new-homepage/carousel-arrow-right.svg"
import ArrowLeftDesktop from "../../images/new-homepage/carousel-arrow-left.svg"
import ArrowRightMobile from "../../images/new-homepage/carousel-arrow-right-mobile.svg"
import ArrowLeftMobile from "../../images/new-homepage/carousel-arrow-left-mobile.svg"
import MoreImages from "../../images/new-homepage/icons/more-images-icon.svg"
import SPAvatar from "../../images/sp-avatar.png"
import { MoreImagesIcon } from "../ProjectCard/styles"
import PostCardIconActions from "../../components/PostCardIconActions"
import CommentsList from "./CommentsList"
import CommentTextField from "../../components/CommentTextField"
import PostContentText from "../../components/PostContentText"
import PostDotMenu from "../../components/PostDotMenu"
import ConfirmDialog from "../../components/ConfirmDialog"
import { Profile } from "../../redux/profile"
import EditPost from "../../components/EditPost"
import FlagContentDialog from "../../components/FlagContentDialog"
import Zoomable from "react-instagram-zoom"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  post: Post
  authenticatedProfile?: Profile
  isPostingComment: boolean
  fullView?: boolean
  isHomepage?: boolean
  isDeleteDialogOpen: boolean
  isEditDialogOpen: boolean
  isFlagDialogOpen: boolean
  isAuthenticated: boolean
  handleDeletePost: () => void
  handleDeleteDialogOpen: (value: boolean) => void
  handleEditDialogOpen: (value: boolean) => void
  handleFlagDialogOpen: (value: boolean) => void
  handlePostComment: (
    text: string,
    parentCommentId?: string,
    mentions?: PostMention[]
  ) => void
  isAds?: boolean
}

const View = ({
  post,
  authenticatedProfile,
  fullView,
  isHomepage,
  isPostingComment,
  isDeleteDialogOpen,
  isEditDialogOpen,
  isFlagDialogOpen,
  isAuthenticated,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
  handleDeletePost,
  handlePostComment,
  handleFlagDialogOpen,
  isAds,
}: Props) => {
  const [showNavigation, setShowNavigation] = useState(false)
  const [commentsQuantity, setCommentsQuantity] = useState(
    fullView ? 99999999 : 1
  )
  const [seeMore, setSeeMore] = useState(fullView ? true : false)

  const { width } = useWindowDimensions()

  const isMobile = width < theme.breakpoints.values.sm

  const profileName =
    post.profile?.type === "group"
      ? post.profile.groupName
      : `${post.profile?.firstName} ${post.profile?.lastName}`

  let navButtonStyled = {
    backgroundColor: "rgba(255,255,255, 0.9)",
    zIndex: 5,
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "-10px 20px 0",
  }

  let ArrowRight = ArrowRightDesktop
  let ArrowLeft = ArrowLeftDesktop

  if (width <= theme.breakpoints.values.md) {
    navButtonStyled = {
      backgroundColor: "rgba(255,255,255, 0.9)",
      zIndex: 5,
      width: "27px",
      height: "27px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 10px 0",
    }

    ArrowRight = ArrowRightMobile
    ArrowLeft = ArrowLeftMobile
  }

  const handleSowMoreComments = () => {
    if (post.comments && post.comments?.length > commentsQuantity)
      setCommentsQuantity(commentsQuantity + 3)
    else setCommentsQuantity(1)
  }

  const getCommentLabel = () => {
    if (post.comments?.length === 0 || !post.comments)
      return <>Be the first to comment</>

    if (
      post.comments &&
      post.comments?.length > 1 &&
      post.comments?.length <= commentsQuantity
    )
      return <>View less</>

    if (post.comments?.length === 1) return <>&nbsp;</>

    return <>View more comments</>
  }

  const postComment = (comment: string, mentions?: PostMention[]) => {
    if (!isPostingComment && comment) {
      handlePostComment(comment, undefined, mentions)
    }
  }

  const oversized = post.content.length > 500
  const oversizedBelow = post.content.length > 170

  const renderContentComponent = () => {
    if (post.images?.length === 0 && post.content.length <= 150) {
      return (
        <PostCardContentSmallText>
          <PostCardSmallText>
            <PostContentText post={post} small={true} />
          </PostCardSmallText>
        </PostCardContentSmallText>
      )
    } else if (post.images && post.images?.length > 0) {
      return (
        <PostCardContentImage
          onMouseEnter={() =>
            setShowNavigation(() =>
              width <= theme.breakpoints.values.md ? false : true
            )
          }
          onMouseLeave={() => setShowNavigation(false)}
        >
          <Zoomable>
            {post.images.length > 1 ? (
              <>
                <Carousel
                  navButtonsAlwaysVisible={true}
                  animation={"slide"}
                  interval={0}
                  NextIcon={<img src={ArrowRight} alt={"Arrow"} />}
                  PrevIcon={<img src={ArrowLeft} alt={"Arrow"} />}
                  indicatorIconButtonProps={{
                    style: {
                      color: "#D8D8D8",
                      visibility: !showNavigation ? "hidden" : "visible",
                      opacity: !showNavigation ? "0" : "1",
                      transition: "all 0.5s",
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: "#3057E1",
                      visibility: !showNavigation ? "hidden" : "visible",
                      opacity: !showNavigation ? "0" : "1",
                      transition: "all 0.5s",
                    },
                  }}
                  indicatorContainerProps={{
                    style: {
                      marginTop: "-40px",
                      position: "absolute",
                      zIndex: 5,
                    },
                  }}
                  navButtonsProps={{
                    style: {
                      ...navButtonStyled,
                      visibility: !showNavigation ? "hidden" : "visible",
                      opacity: !showNavigation ? "0" : "1",
                      transition: "all 0.5s",
                    },
                  }}
                  autoPlay={false}
                >
                  {post.images.map((image) => {
                    return (
                      <div key={image.id}>
                        <MoreImagesIcon src={MoreImages} alt={"More Images"} />
                        <ImageThumbnail
                          src={image.largeImageUrl}
                          alt={"Post"}
                        />
                      </div>
                    )
                  })}
                </Carousel>
              </>
            ) : (
              <ImageThumbnail src={post.images[0].largeImageUrl} alt={"Post"} />
            )}
          </Zoomable>
        </PostCardContentImage>
      )
    } else {
      return (
        <PostCardContentBigText
          $isOversized={oversized}
          $isShowingMore={seeMore}
        >
          <PostCardBigText $isOversized={oversized} $isShowingMore={seeMore}>
            <PostContentText post={post} />
          </PostCardBigText>
          {oversized && !fullView && (
            <>
              {!seeMore ? (
                <>
                  <WhiteGradient />
                  <SeeMoreButton onClick={() => setSeeMore(true)}>
                    See More
                  </SeeMoreButton>
                </>
              ) : (
                <SeeMoreButton onClick={() => setSeeMore(false)}>
                  See Less
                </SeeMoreButton>
              )}
            </>
          )}
        </PostCardContentBigText>
      )
    }
  }

  return (
    <CardBox key={post.id}>
      <ConfirmDialog
        title="Delete post"
        message="Are you sure you want to delete this post?"
        isOpen={isDeleteDialogOpen}
        onConfirm={handleDeletePost}
        onClose={() => handleDeleteDialogOpen(false)}
      />

      {authenticatedProfile && (
        <EditPost
          post={post}
          authenticatedProfile={authenticatedProfile}
          handleEditDialogOpen={() => handleEditDialogOpen(false)}
          isEditDialogOpen={isEditDialogOpen}
        />
      )}

      {isFlagDialogOpen && (
        <FlagContentDialog
          handleClose={() => handleFlagDialogOpen(false)}
          isOpen={isFlagDialogOpen}
          id={post.id!}
          origin={"post"}
          isHomepage={isHomepage}
        />
      )}

      <PostCardHeader>
        <HeaderBox>
          {isAds ? (
            <>
              <SpectacularAvatar src={SPAvatar} alt={"Spectacular"} />
              <ProfileTitle>Spectacular</ProfileTitle>
            </>
          ) : (
            <>
              <LinkBox to={`/sp/${post.profile?.username}`}>
                <Avatar profile={post.profile!} width={36} height={36} />
              </LinkBox>
              <LinkBox to={`/sp/${post.profile?.username}`}>
                <ProfileTitle>
                  {profileName}
                  {post.profile?.isAmbassador && (
                    <img
                      src={AmbassadorIcon}
                      alt="Student Ambassador"
                      className="StudentAmbassadorIcon"
                    />
                  )}
                </ProfileTitle>
              </LinkBox>
            </>
          )}
        </HeaderBox>
        {isAuthenticated &&
          !isAds &&
          (!isHomepage ||
            (isHomepage &&
              post.profile?.connectionId !==
                authenticatedProfile?.connectionId)) && (
            <PostDotMenu
              post={post}
              displayEdit={!isHomepage}
              handleDeleteDialogOpen={handleDeleteDialogOpen}
              handleEditDialogOpen={handleEditDialogOpen}
              handleFlagDialogOpen={handleFlagDialogOpen}
            />
          )}
      </PostCardHeader>

      {isAds ? (
        <LinkBox to={post.adsUrl}>{renderContentComponent()}</LinkBox>
      ) : (
        <>{renderContentComponent()}</>
      )}
      <PostCardActions>
        <PostCardIconActions post={post} />
      </PostCardActions>

      <Divider>
        <DividerBorder />
      </Divider>

      {post.images && post.images?.length > 0 && post.content.length > 0 && (
        <CommentsBelowBox>
          <PostCardBigTextBelow
            $isOversized={oversizedBelow}
            $isShowingMore={seeMore}
          >
            <PostContentText post={post} />
          </PostCardBigTextBelow>
          {oversizedBelow && !fullView && (
            <>
              {!seeMore ? (
                <SeeMoreButton $isBelow={true} onClick={() => setSeeMore(true)}>
                  See More
                </SeeMoreButton>
              ) : (
                <SeeMoreButton
                  $isBelow={true}
                  onClick={() => setSeeMore(false)}
                >
                  See Less
                </SeeMoreButton>
              )}
            </>
          )}
        </CommentsBelowBox>
      )}
      <PostCardFooter>
        <BlockingBox $isPosting={isPostingComment}>
          <CommentsBox>
            <CommentsInnerBox>
              {(!isMobile || (post.comments && post.comments.length > 1)) && (
                <CommentsLabel onClick={handleSowMoreComments}>
                  {getCommentLabel()}
                </CommentsLabel>
              )}
              <CommentTextField
                handleSave={postComment}
                oneLine={true}
                focus={false}
              />
              {post.comments && post.comments?.length > 0 && (
                <CommentsList
                  comments={post.comments}
                  quantity={commentsQuantity!}
                  handlePostComment={handlePostComment}
                  isPostingComment={isPostingComment}
                  authenticatedProfile={authenticatedProfile}
                  isHomepage={isHomepage}
                />
              )}
            </CommentsInnerBox>
          </CommentsBox>
        </BlockingBox>
      </PostCardFooter>
    </CardBox>
  )
}

export default View
