import React from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"

import { toggleDialog } from "../../redux/config"
import View from "./View"
import { Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  project: Project
}

const ProjectCommentsButton = ({ project }: Props) => {
  let history = useHistory()
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()

  const handleCommentsClicked = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    history.push(`/sp/${project.username}/projects/${project.id}#comments`)
  }

  return <View project={project} onCommentsClicked={handleCommentsClicked} />
}

export default ProjectCommentsButton
