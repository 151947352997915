type SelectOption = {
  name: string
  value: string
}

export const projectToolOptions: SelectOption[] = [
  { name: "V-Ray", value: "vray" },
  { name: "Unity", value: "unity" },
  { name: "Rhino", value: "rhino" },
  { name: "Revit", value: "revit" },
  { name: "Vector", value: "vector" },
  { name: "Python", value: "python" },
  { name: "Dynamo", value: "dynamo" },
  { name: "3ds Max", value: "3ds_max" },
  { name: "AutoCAD", value: "autocad" },
  { name: "InDesign", value: "indesign" },
  { name: "SketchUp", value: "sketchup" },
  { name: "ArchiCAD", value: "archicad" },
  { name: "Photoshop", value: "photoshop" },
  { name: "Grasshopper", value: "grasshopper" },
  { name: "After Effects", value: "after_effects" },
  { name: "Physical model", value: "physical_model" },
  { name: "Maxwell Render", value: "maxwell_render" },
  { name: "3D-printed models", value: "3d_printed_models" },
  { name: "Augmented Reality", value: "augmented_reality" },
  { name: "SOLIDWORKS", value: "solidworks" },
  { name: "Laser cutting", value: "laser_cutting" },
  { name: "Illustrator", value: "illustrator" },
  { name: "Other", value: "other" },
]

export const projectToolsByCategory: any[] = [
  {
    category: "BIM",
    options: [
      { name: "ArchiCAD", value: "archicad" },
      { name: "Revit", value: "revit" },
    ],
  },

  {
    category: "Drafting Tools",
    options: [
      { name: "AutoCAD", value: "autocad" },
      { name: "SOLIDWORKS", value: "solidworks" },
    ],
  },

  {
    category: "3D Modeling",
    options: [
      { name: "3D-printed models", value: "3d_printed_models" },
      { name: "Laser cutting", value: "laser_cutting" },
      { name: "Physical model", value: "physical_model" },
      { name: "Rhino", value: "rhino" },
      { name: "SketchUp", value: "sketchup" },
    ],
  },

  {
    category: "Rendering Tools",
    options: [
      { name: "3ds Max", value: "3ds_max" },
      { name: "Maxwell Render", value: "maxwell_render" },
      { name: "V-Ray", value: "vray" },
      { name: "Unity", value: "unity" },
    ],
  },

  {
    category: "Post-Production",
    options: [
      { name: "After Effects", value: "after_effects" },
      { name: "Augmented Reality", value: "augmented_reality" },
      { name: "Illustrator", value: "illustrator" },
      { name: "InDesign", value: "indesign" },
      { name: "Photoshop", value: "photoshop" },
      { name: "Vector", value: "vector" },
    ],
  },

  {
    category: "Parametric Design",
    options: [
      { name: "Dynamo", value: "dynamo" },
      { name: "Grasshopper", value: "grasshopper" },
      { name: "Python", value: "python" },
    ],
  },

  {
    category: "Other",
    options: [{ name: "Other", value: "other" }],
  },
]
