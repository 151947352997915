import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const SpotlightContainer = styled(Box)`
  background: #1a1a1a;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin-top: 64px;
  flex-flow: column;
  align-items: center;
  ${theme.breakpoints.down("sm")} {
    margin-top: 48px;
  }
  ${theme.breakpoints.down("xs")} {
    margin-top: 32px;
  }
`

export const SpotlightBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  position: relative;

  ${theme.breakpoints.down("sm")} {
    padding: 48px 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 32px 20px;
  }
`

export const SpotlightFlexBox = styled(Box)`
  display: flex;
  gap: 100px;

  @media only screen and (max-width: 1300px) {
    gap: 40px;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }

  ${theme.breakpoints.down("xs")} {
    gap: 32px;
  }
`

export const SpotlightLeftBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 420px;

  @media only screen and (max-width: 1300px) {
    padding-left: 40px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    min-height: auto;
  }
`

export const SpotlightRightBox = styled(Box)`
  background: #f4f4f4;
  padding: 0 150px;
  position: relative;
  display: flex;
  align-items: center;

  && img {
    width: 200px;
    height: 200px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    justify-content: center;
    padding: 100px 0;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 114px 0;

    && img {
      width: 160px;
      height: 160px;
    }
  }
`

export const SpotlightTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  && img {
    vertical-align: bottom;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 48px;

    && img {
      width: 48px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 32px;

    && img {
      width: 35px;
    }
  }
`

export const SpotlightSubTitle = styled(Typography)`
  font-size: 40px;
  color: #fff;
  padding-top: 16px;

  @media only screen and (max-width: 1080px) {
    font-size: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
  }
`

export const SpotlightCopy = styled(Typography)`
  color: #fff;
  font-size: 17px;
  font-family: Public-Sans-Bold;
  padding-top: 32px;
  line-height: 24px;
  white-space: break-spaces;

  @media only screen and (max-width: 1080px) {
    font-size: 16px;
    font-family: Public-Sans-Medium;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 17px;
    font-family: Public-Sans;
  }
`

export const SpotlightButtonBox = styled(Box)`
  padding-top: 32px;

  ${theme.breakpoints.down("sm")} {
    && button {
      width: 100% !important;
    }
  }
`

export const SpotlightFloatingCompanyName = styled(Typography)`
  position: absolute;
  top: 30px;
  left: 32px;
  font-size: 25px;
  width: 50%;

  @media only screen and (max-width: 1200px) {
    width: 75%;
  }
`

export const SpotlightFloatingIcon = styled(Box)`
  position: absolute;
  bottom: 31px;
  right: 31px;
  display: flex;

  && img {
    width: 48px;
    height: 48px;
  }
`

export const BorderTop = styled(Box)`
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  padding-top: 64px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const BorderBottom = styled(Box)`
  width: 100%;
  border-top: 1px solid #f4f4f4;
  padding-bottom: 64px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const BorderRight = styled(Box)`
  position: absolute;
  border-left: 1px solid #f4f4f4;
  height: calc(100% + 130px);
  right: 80px;
  top: -65px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const InnerBorder = styled(Box)`
  position: absolute;
  border-left: 1px solid #f4f4f4;
  height: calc(100% + 130px);
  left: 0px;
  top: -65px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const BorderLeft = styled(Box)`
  position: absolute;
  border-right: 1px solid #f4f4f4;
  height: calc(100% + 130px);
  left: 80px;
  top: -65px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`
