import React from "react"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import { Link } from "react-router-dom"
import theme from "../../theme"

export const IconContainer = styled(Box)<{ $isActive?: boolean }>`
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  height: 100%;
  color: ${({ $isActive }) => ($isActive ? `#979797;` : "#2d2d2d;")}

  ${({ $isActive }) =>
    $isActive
      ? `text-decoration-line: underline !important;
  text-decoration-thickness: 4px !important;
  text-decoration-color: rgb(0, 0, 0) !important;
  text-underline-offset: 10px !important;`
      : ""}

  &:hover {
    cursor: pointer;
  }

  && img {
    height: 24px;
    padding-bottom: 6px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  @media only screen and (max-width: 1230px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  
  @media only screen and (max-width: 1130px) {
    padding-left: 6px;
    padding-right: 6px;
  }
  
  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    font-family: Public-Sans-Medium;
    
    ${({ $isActive }) =>
      $isActive ? `text-decoration-line: none !important;` : ""}
  }
`

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;

  &:active {
    user-select: none;
  }
`
