import styled from "styled-components"
import { Button } from "@material-ui/core"
import theme from "../../theme"

export const FollowButton = styled(Button)`
  max-width: 136px;
  width: 100%;
  height: 40px;
  background-color: #3057e1;
  font-weight: 700;
  border-radius: 1px;

  &:hover {
    background-color: #1c43cd;
  }

  @media (max-width: 959px) {
    width: 103px;
  }
`

export const FollowButtonBig = styled(Button)`
  max-width: 268px;
  width: 100%;
  height: 48px;
  font-weight: 700;
  border-radius: 1px;
  background-color: #3057e1;
  color: #fff;

  &:hover {
    background-color: #1c43cd;
  }

  ${theme.breakpoints.down("xs")} {
    max-width: 100%;
    width: 100%;
  }
`

export const FollowButtonBlue = styled(Button)`
  max-width: 136px;
  width: 100%;
  height: 40px;
  background-color: #ebeffc;
  font-weight: 700;
  border-radius: 1px;
  color: #2d2d2d;

  &:hover {
    background-color: #d6e0f8;
  }

  @media (max-width: 959px) {
    width: 103px;
  }
`

export const FollowButtonBigBlue = styled(Button)`
  max-width: 100%;
  width: 100%;
  height: 48px;
  font-weight: 700;
  border-radius: 1px;
  background-color: #ebeffc;
  color: #2d2d2d;

  &:hover {
    background-color: #d6e0f8;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`
