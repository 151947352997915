import Photo1 from "../images/student-ambassador/sa-1.jpg"
import Photo2 from "../images/student-ambassador/sa-2.jpg"
import Photo3 from "../images/student-ambassador/sa-3.jpg"
import Photo4 from "../images/student-ambassador/sa-4.jpg"
import Photo5 from "../images/student-ambassador/sa-5.jpg"
import Photo6 from "../images/student-ambassador/sa-6.jpg"
import Photo7 from "../images/student-ambassador/sa-7.jpg"
import Photo8 from "../images/student-ambassador/sa-8.jpg"
import Photo9 from "../images/student-ambassador/sa-9.jpg"
import Photo10 from "../images/student-ambassador/sa-10.jpg"
import Photo11 from "../images/student-ambassador/sa-11.jpg"
import Photo12 from "../images/student-ambassador/sa-12.jpg"
import Photo13 from "../images/student-ambassador/sa-13.jpg"
import Photo14 from "../images/student-ambassador/sa-14.jpg"
import Photo15 from "../images/student-ambassador/sa-15.jpg"
import Photo16 from "../images/student-ambassador/sa-16.jpg"
import Photo17 from "../images/student-ambassador/sa-17.jpg"
import Photo18 from "../images/student-ambassador/sa-18.jpg"
import Photo19 from "../images/student-ambassador/sa-19.jpg"
import Photo20 from "../images/student-ambassador/sa-20.jpg"
import Photo21 from "../images/student-ambassador/sa-21.jpg"
import Photo22 from "../images/student-ambassador/sa-22.jpg"
import Photo23 from "../images/student-ambassador/sa-23.jpg"
import Photo24 from "../images/student-ambassador/sa-24.jpg"

export const ambassadorPhotos = [
  [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6],
  [Photo7, Photo8, Photo9, Photo10, Photo11, Photo12],
  [Photo13, Photo14, Photo15, Photo16, Photo17, Photo18],
  [Photo19, Photo20, Photo21, Photo22, Photo23, Photo24],
]
