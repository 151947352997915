import styled from "styled-components"
import { Box, Typography, Tab, Tabs as MUITabs } from "@material-ui/core"
import theme, { colors } from "../../theme"

export const NetworkContainer = styled(Box)`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-flow: nowrap;
  margin-top: -40px;
  margin-bottom: -64px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    min-height: fit-content;
    margin-top: 0;
  }
`

export const TabsBox = styled(Box)`
  background: #fff;
  padding: 48px 0;
  min-width: 337px;
  box-sizing: border-box;
  min-height: calc(100vh - 300px);

  @media only screen and (max-width: 1150px) {
    min-width: 250px;
  }

  ${theme.breakpoints.down("sm")} {
    align-items: center;
    background: ${colors.white};
    border-bottom: solid 0.5px ${colors.mediumGray};
    display: flex;
    height: 56px;
    min-height: 0;
    min-width: 100%;
    padding: 0;
  }
`

export const NetworkTitle = styled(Typography)`
  padding: 0 48px;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: -6px;
`

export const Divider = styled(Box)`
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  margin: 32px 0;
`

export const Tabs = styled(MUITabs)`
  .MuiTabs-flexContainerVertical {
    gap: 13px !important;
  }

  .Mui-selected p {
    font-weight: 700 !important;
  }
`

export const TabLabel = styled(Typography)<{ $isActive: boolean }>`
  font-size: 16px;
  text-transform: none;
  color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#717171")};
`

export const TabItem = styled(Tab)<{ $isActive: boolean }>`
  padding: 0 0 0 48px;
  opacity: 1;
  background-color: ${({ $isActive }) => ($isActive ? "#dadada" : "")};

  && img {
    margin-right: 8px;
    vertical-align: sub;
  }

  .MuiTab-wrapper {
    align-items: flex-start !important;
  }
`
export const ButtonBox = styled(Box)`
  padding: 32px 0 0 48px;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    width: 100%;
  }
`

export const MobileTab = styled(Box)<{ $isActive: boolean }>`
  color: #717171;
  font-size: 12px;
  display: flex;
  flex-flow: column;
  gap: 6px;

  && img {
    height: 16px;
  }

  ${({ $isActive }) =>
    $isActive
      ? `color:#2d2d2d;
    font-family: Public-Sans-Bold;
    text-decoration-line: underline !important;
    text-decoration-thickness: 2px !important;
    text-decoration-color: #2d2d2d !important;
    text-underline-offset: 10px !important;`
      : ""}
`

export const MobileTabArea = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 12px 0;
`

export const ContentBox = styled(Box)`
  width: 100%;
  padding: 48px;
  box-sizing: border-box;
  background-color: #f7f7f7;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const StyledInput = styled.input`
  height: 40px;
  width: 280px;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 1px;
  color: #979797;
  box-shadow: none;
  font-size: 14px;
  padding: 0 0 0 38px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`

export const Search = styled.img`
  position: absolute;
  margin-top: 12px;
  margin-left: 16px;
`

export const SearchBox = styled(Box)`
  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`
