import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { Competition } from "./competition"

//#region types
export type ProjectReward = {
  id?: string
  projectId?: string
  profileId?: string
  prize?: string
  campaign?: string
  competition?: Competition
}
//#endregion

//#region api
type CreateProjectRewardPayload = {
  auth: Auth0ContextInterface
  projectReward: ProjectReward
}
export const createProjecReward = createAsyncThunk<
  any,
  CreateProjectRewardPayload
>("admin/projectReward/create", async ({ auth, projectReward }) => {
  const { id, ...projectRewardData } = projectReward
  return useApi(auth, "/admin/project_reward", {
    method: "POST",
    body: JSON.stringify(projectRewardData),
  }).then((res) => res.json())
})

type UpdateProjectRewardPayload = {
  auth: Auth0ContextInterface
  projectReward: ProjectReward
}
export const updateProjectReward = createAsyncThunk<
  any,
  UpdateProjectRewardPayload
>("admin/projectReward/update", async ({ auth, projectReward }) => {
  return useApi(auth, `/admin/project_reward/${projectReward.id}`, {
    method: "PATCH",
    body: JSON.stringify(projectReward),
  }).then((res) => res.json())
})

type DeleteProjectRewardPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteProjectReward = createAsyncThunk<
  any,
  DeleteProjectRewardPayload
>("admin/projectReward/delete", async ({ auth, id }) => {
  return useApi(auth, `/admin/project_reward/${id}`, {
    method: "DELETE",
  }).then((res) => res.json())
})
//#endregion

//#region slice
//#endregion

//#region selectors
//#endregion
