import { App, URLOpenListenerEvent } from "@capacitor/app"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory()
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.includes("com.qa.spectacular")
        ? event.url.split("://").pop()
        : event.url.split(".design").pop()
      if (slug) {
        history.push(slug)
      }
    })
  }, [history])

  return null
}

export default AppUrlListener
