import React, { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import View from "./View"

import {
  Project,
  fetchProject,
  updateProject,
  getS3SignedURL,
} from "../../redux/projects"
import { resolveImageDimensionsByURL } from "../../utils"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { Helmet } from "react-helmet"
import LoadingPage from "../LoadingPage"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Camera } from "@capacitor/camera"

const ProjectWizardPage = () => {
  const auth = useAuth0()
  const email = auth.user?.email

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  const [isUploading, setIsUploading] = React.useState(false)
  const [typeSelected, setTypeSelected] = React.useState("individual")
  const [currentFile, setCurrentFile] = React.useState<string>("")
  const [uploadedImages, setUploadedImages] = React.useState<any[]>([])
  const [project, setProject] = React.useState<Project | undefined>(undefined)

  const [inviteCollaboratorDialogIsOpen, setinviteCollaboratorDialogIsOpen] =
    React.useState(false)

  const handleChangeInviteCollaboratorDialog = (value: boolean) => {
    setinviteCollaboratorDialogIsOpen(value)
  }

  const projectError = useSelector((state: any) => state.projects.error)
  const profile = useSelector((state: AppState) => state.profile.profile)

  const CheckGalleryAccess = useCallback(async () => {
    try {
      const permission = await Camera.checkPermissions()
      if (permission.camera !== "granted" || permission.photos !== "granted") {
        await Camera.requestPermissions({ permissions: ["camera", "photos"] })
      }
    } catch (error) {
      console.log("failed to load camera")
    }
  }, [])

  const handleProjectUpdate = async (project: Project, published: boolean) => {
    try {
      const { payload, type } = await dispatch(
        updateProject({ project, auth, published })
      )
      if (updateProject.fulfilled.type === type) {
        if (project.images && project.images?.length > 0) {
          setIsUploading(true)
          await handleUploadFiles(project)
          setIsUploading(false)
        }

        await dispatch(fetchProject({ id: payload.id, email }))
        history.push(`/sp/${project.profile?.username}/projects/${payload.id}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleTypeSelected = (value: string) => {
    setTypeSelected(value)
  }
  const handleUploadFiles = async (project: Project) => {
    if ("images" in project) {
      for (const key in project.images) {
        if ("file" in project.images[key as any]) {
          const projectImage = project.images[key as any]
          setUploadedImages([projectImage.file.name, ...uploadedImages])
          setCurrentFile(projectImage.file.name)

          const signedURL = await dispatch(
            getS3SignedURL({
              uploadType: "project",
              contentId: project.id as string,
              fileName: projectImage.file.name,
              fileType: projectImage.file.type,
              order: projectImage.order,
              credits: projectImage.credits,
              caption: projectImage.caption,
              width: projectImage.dimensions?.width,
              height: projectImage.dimensions?.height,
              auth,
            })
          )

          //Upload the image to the S3 bucket
          const myHeaders = new Headers()
          myHeaders.append("Content-Type", project.images[key as any].file.type)

          await fetch(signedURL.payload, {
            method: "PUT",
            headers: myHeaders,
            body: project.images[key as any].file,
          })
        }
      }
    }
  }

  const handleCancel = () => {
    history.push(`/sp/${project?.profile?.username}/projects/${id}`)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { type, payload } = await dispatch(fetchProject({ id, email }))
        if (fetchProject.fulfilled.type === type) {
          if (payload.images) {
            const processedImages = new Array<any>()
            for (const image of payload.images) {
              if (image.width && image.height) {
                processedImages.push({
                  ...image,
                  dimensions: { width: image.width, height: image.height },
                })
              } else {
                const dimensions = await resolveImageDimensionsByURL(image.url!)
                processedImages.push({ ...image, dimensions })
              }
            }

            setProject({ ...payload, images: processedImages })
            setTypeSelected(payload.type ?? "individual")
          }
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, id, email])

  useEffect(() => {
    CheckGalleryAccess()
  }, [CheckGalleryAccess])

  if (!project) return <LoadingPage />

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Edit Project`}
        classTitle="EditProject"
      />
      <Helmet>
        <title>Edit project - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Edit project - Spectacular`} />
        <meta name="title" content={`Edit project - Spectacular`} />
      </Helmet>
      <View
        isUploading={isUploading}
        typeSelected={typeSelected}
        currentFile={currentFile}
        profile={profile!}
        onSubmit={handleProjectUpdate}
        error={projectError}
        project={project}
        inviteCollaboratorDialogIsOpen={inviteCollaboratorDialogIsOpen}
        onCancel={handleCancel}
        onTypeSelected={handleTypeSelected}
        onChangeInviteCollaboratorDialog={handleChangeInviteCollaboratorDialog}
      />
    </>
  )
}

export default ProjectWizardPage
