import React from "react"
import { useSelector } from "react-redux"
import { Route, RouteProps, Redirect } from "react-router-dom"
import { selectProfile } from "../../redux/profile"

interface Props extends RouteProps {
  admin: boolean
  checkProfile: boolean
  typesAllowed?: ["individual" | "group"]
}

const ProtectedRoute = ({
  component,
  checkProfile,
  typesAllowed,
  admin,
  ...args
}: Props) => {
  const profile = useSelector(selectProfile)

  if (admin && !profile?.isAdmin) return <Redirect to="/" />
  else {
    if (typesAllowed && !typesAllowed.includes(profile?.type!))
      return <Redirect to="/" />

    return <Route {...args} component={component} />
  }
}

ProtectedRoute.defaultProps = {
  checkProfile: true,
  admin: false,
}

export default ProtectedRoute
