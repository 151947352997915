type Environment = {
  apiUrl: string
  appUrl: string
  segmentKey: string
  auth0Domain: string
  auth0ClientId: string
  auth0Audience: string
  requestAccessFormId: string
  registerUrl: string
  environment: string
  device: string
  redirectUri: string
  stripeKey: string
}

// Added QA Environment
const defaultConfig: Environment = {
  apiUrl: "https://staging.spectacular.design/api",
  appUrl: "https://staging.spectacular.design",
  auth0Domain: "login.spectacular.design",
  auth0ClientId: "r1QhUy4J6wyeN6ze9Gr7xN4yenYPACS5",
  auth0Audience: "https://dev-k9591ja0.us.auth0.com/api/v2/",
  requestAccessFormId: "lY7sMy4s",
  segmentKey: "w4EGi3HvyvPk799xiG6F6BEWHAAGgP9d",
  registerUrl: "https://staging.spectacular.design/register",
  environment: "Staging",
  device: "desktop",
  redirectUri: `${window.location.origin}/authentication_callback`,
  stripeKey: "pk_test_p6Hpb7GbHCUJus4azgtGuJxm",
}

type EnvironmentOrigin =
  | "https://qa.spectacular.design"
  | "https://staging.spectacular.design"
  | "https://spectacular.design"
  | "https://localhost"
  | "capacitor://localhost"

const environments = {
  "https://qa.spectacular.design": {
    ...defaultConfig,
    apiUrl: "https://qa.spectacular.design/api",
    appUrl: "https://qa.spectacular.design",
    segmentKey: "tpSGRc9Qe9aQizOulfmEjlPrkQEIsZ7Y",
    auth0Domain: "qalogin.spectacular.design",
    auth0ClientId: "3OUwgJxiAbmyMiosPsS15kM0dMm1xOZe",
    auth0Audience: "https://staging-spectacular.us.auth0.com/api/v2/",
    registerUrl: "https://qa.spectacular.design/register",
    environment: "QA",
  },
  "https://staging.spectacular.design": {
    ...defaultConfig,
    segmentKey: "u3ITuIjneugs6OaNTtYQTvmgYm1TG9Wz",
    appUrl: "https://staging.spectacular.design",
  },
  "https://spectacular.design": {
    ...defaultConfig,
    apiUrl: "https://spectacular.design/api",
    auth0ClientId: "bdIRK6NbZ1ns2PDZo2MUuMiBqozYHJkk",
    appUrl: "https://spectacular.design",
    registerUrl: "https://spectacular.design/register",
    environment: "Production",
    stripeKey: "pk_live_xSND9CJgHjaVVXKKIjAvzOYU",
  },
  "https://localhost": {
    ...defaultConfig,
    apiUrl: "https://spectacular.design/api",
    auth0ClientId: "jrNyvxK1Kw6KkeEQxpqK0nreSRxtDMC8",
    appUrl: "https://spectacular.design",
    registerUrl: "https://spectacular.design/register",
    environment: "Production",
    device: "android-app",
    redirectUri:
      "com.qa.spectacular://login.spectacular.design/capacitor/com.qa.spectacular/authentication_callback",
    stripeKey: "pk_live_xSND9CJgHjaVVXKKIjAvzOYU",
  },
  "capacitor://localhost": {
    ...defaultConfig,
    apiUrl: "https://spectacular.design/api",
    auth0ClientId: "jrNyvxK1Kw6KkeEQxpqK0nreSRxtDMC8",
    appUrl: "https://spectacular.design",
    registerUrl: "https://spectacular.design/register",
    environment: "Production",
    device: "ios-app",
    redirectUri:
      "com.spectacular.design://login.spectacular.design/capacitor/com.spectacular.design/authentication_callback",
    stripeKey: "pk_live_xSND9CJgHjaVVXKKIjAvzOYU",
  },
  dev: {
    ...defaultConfig,
    apiUrl: process.env.REACT_APP_API_URL,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    segmentKey: process.env.REACT_APP_SEGMENT_KEY,
    appUrl: "http://localhost:3001",
    ssoUrl: process.env.REACT_APP_SSO_URL,
    registerUrl: process.env.REACT_APP_REGISTER_URL,
    environment: "Dev",
  },
}

const environment =
  environments[window.location.origin as EnvironmentOrigin] || environments.dev

export default environment
