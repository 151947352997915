import React, { useState } from "react"

import View from "./View"

type Props = {
  initialGroups?: string[]
  onChange: (values: string[]) => void
}

const ProfileGroups = ({ initialGroups, onChange }: Props) => {
  const [value, setValue] = useState<string>("")
  const [displayError, setDisplayError] = useState<boolean>(false)
  const [groups, setGroups] = useState<string[]>(initialGroups || [])

  const saveSkill = async (groups: string[]) => {
    setGroups(groups)
    onChange(groups)
    setDisplayError(false)
  }

  const onInputChange = (value: string) => setValue(value)

  const onAdd = (newSkill: string) => {
    saveSkill([...groups, newSkill])
    setValue("")
  }

  const onDelete = (groupToDelete: string) =>
    saveSkill(groups.filter((group: string) => group !== groupToDelete))

  return (
    <View
      value={value}
      groups={groups}
      suggestions={[]}
      onEnteredPressed={onAdd}
      onDeletedPressed={onDelete}
      displayError={displayError}
      onInputChange={onInputChange}
    />
  )
}

export default ProfileGroups
