import React from "react"
import * as Yup from "yup"
import throttle from "lodash/throttle"
import { FormikContextType, useFormikContext } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import { WizardStep, StepProps } from "./Wizard"
import { JobListing } from "../../redux/jobListings"

import {
  Box,
  Grid,
  Typography,
  TextField as MUITextField,
} from "@material-ui/core"
import parse from "autosuggest-highlight/parse"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import { Step1Title } from "./styles"

import TextField from "../../components/TextField"
import SelectField from "../../components/SelectField"

import "./errors.css"

import {
  workplaceTypes,
  employmentTypes,
  experienceLevels,
} from "../../constants/jobs-constants"
import { JobFormType } from "../../enums/JobFormType"

interface Props extends StepProps {
  type: JobFormType
  isMobile: boolean
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

const Step1: React.FC<Props> = ({ type, isMobile }) => {
  const formik: FormikContextType<JobListing> = useFormikContext()

  let defaultLocation: PlaceType = {
    description: formik.values?.location,
    structured_formatting: {
      main_text: "main text",
      secondary_text: formik.values?.location,
      main_text_matched_substrings: [
        {
          offset: 1,
          length: 1,
        },
      ],
    },
  }

  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const [value, setValue] = React.useState<PlaceType | null>(defaultLocation)
  const loaded = React.useRef(false)

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB882GbnKnvMUITZPoLHSzr8No39YHUymE&libraries=places",
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string; types?: string[] },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google)
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()

    if (!autocompleteService.current) return undefined

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      { input: inputValue, types: ["geocode"] },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[]

          if (value) newOptions = [value]

          if (results) newOptions = [...newOptions, ...results]

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const getTitle = () => {
    if (type === JobFormType.REPOST) return "Repost job posting"

    if (type === JobFormType.EDIT) return "Edit job posting"

    return "Create a new job posting"
  }

  return (
    <WizardStep>
      <>
        <Box>
          <Step1Title>{getTitle()}</Step1Title>
        </Box>

        <Box>
          <TextField
            name="title"
            label={
              <>
                <span>Job title</span>{" "}
                <span
                  style={{
                    color:
                      formik.errors.title && formik.touched.title
                        ? "#f44336"
                        : "#3057E1",
                  }}
                >
                  *
                </span>
              </>
            }
            data-testid="jobTitle"
            inputProps={{ maxLength: 80 }}
          />
        </Box>

        <Box style={isMobile ? { marginTop: "-4px" } : {}}>
          <SelectField
            name="workplaceType"
            label={
              <>
                <span>Workplace type</span>{" "}
                <span
                  style={{
                    color:
                      formik.errors.workplaceType &&
                      formik.touched.workplaceType
                        ? "#f44336"
                        : "#3057E1",
                  }}
                >
                  *
                </span>
              </>
            }
            options={workplaceTypes}
            data-testid="workplaceType"
          />
        </Box>

        <Box style={isMobile ? { marginTop: "-4px" } : {}}>
          <Autocomplete
            id="google-map-demo"
            freeSolo={true}
            popupIcon={""}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            onBlur={() => formik.setFieldTouched("location")}
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(_: any, newValue: any) => {
              setOptions(newValue ? [newValue, ...options] : options)
              setValue(newValue)
              formik.setFieldValue("location", newValue?.description)
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            renderInput={(params) => (
              <MUITextField
                {...params}
                fullWidth
                error={
                  formik.errors.location !== undefined &&
                  formik.touched.location
                }
                helperText={
                  formik.errors.location !== undefined &&
                  formik.touched.location
                    ? formik.errors.location
                    : ""
                }
                name="location"
                data-testid="location"
                label={
                  <>
                    <span>Location</span>{" "}
                    <span
                      style={{
                        color:
                          formik.errors.location && formik.touched.location
                            ? "#f44336"
                            : "#3057E1",
                      }}
                    >
                      *
                    </span>
                  </>
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  formik.setFieldValue("location", e.target.value)
                }}
              />
            )}
            renderOption={(option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [
                  match.offset,
                  match.offset + match.length,
                ])
              )

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              )
            }}
          />
        </Box>

        <Box style={isMobile ? { marginTop: "-4px" } : {}}>
          <SelectField
            name="employmentType"
            label={
              <>
                <span>Employment type</span>{" "}
                <span
                  style={{
                    color:
                      formik.errors.employmentType &&
                      formik.touched.employmentType
                        ? "#f44336"
                        : "#3057E1",
                  }}
                >
                  *
                </span>
              </>
            }
            options={employmentTypes}
            data-testid="employmentType"
          />
        </Box>

        <Box style={isMobile ? { marginTop: "-4px" } : {}}>
          <SelectField
            name="experienceLevel"
            label={
              <>
                <span>Experience level</span>{" "}
                <span
                  style={{
                    color:
                      formik.errors.experienceLevel &&
                      formik.touched.experienceLevel
                        ? "#f44336"
                        : "#3057E1",
                  }}
                >
                  *
                </span>
              </>
            }
            options={experienceLevels}
            data-testid="experienceLevel"
          />
        </Box>
      </>
    </WizardStep>
  )
}

Step1.defaultProps = {
  validationSchema: Yup.object().shape({
    title: Yup.string()
      .required("Required")
      .max(80, "Title must be less than 80 characters"),
    location: Yup.string().required("Required"),
    workplaceType: Yup.string().required("Required"),
    experienceLevel: Yup.string().required("Required"),
    employmentType: Yup.string().required("Required"),
  }),
}

export default Step1
