/* eslint-disable */
import React from "react"
import { Checkbox as MUICheckbox } from "@material-ui/core"

import { CheckboxContainer } from "./Styles"

import { ReactComponent as CheckboxActive } from "../../../images/checkbox-active.svg"
import { ReactComponent as CheckboxDefault } from "../../../images/innovation-awards/checkbox-inactive.svg"

interface Props {
  checked?: boolean
  onChange: () => void
  label?: string | React.ReactNode
}

export const Checkbox: React.FC<Props> = ({ label, checked, onChange }) => {
  return (
    <CheckboxContainer>
      <MUICheckbox
        icon={<CheckboxDefault />}
        checkedIcon={<CheckboxActive />}
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <span>{label}</span>
    </CheckboxContainer>
  )
}
