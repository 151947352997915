import React from "react"

import { AvatarImg } from "./styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"

import { Profile } from "../../redux/profile"

export type Props = {
  isNav?: any
  hover?: any
  width?: number
  height?: number
  plain?: any
  border?: any
  profile: Profile
  onClick?: () => void
}

const Avatar = ({
  profile,
  width,
  height,
  hover,
  isNav,
  onClick,
  plain,
  border,
}: Props) => {
  const src = profile?.smallImageUrl
    ? profile?.smallImageUrl
    : profile?.tempPicture
    ? profile?.tempPicture
    : getRandomPlaceholder(profile?.placeholderAvatar!)

  let isTempImage = false
  if (!profile?.smallImageUrl && profile?.tempPicture) {
    isTempImage = true
  }

  return (
    <AvatarImg
      src={src}
      aria-hidden
      hover={hover}
      border={border}
      onClick={onClick}
      className="avatar"
      alt={profile?.firstName}
      data-testid="user-avatar"
      width={width ? width : 56}
      height={height ? height : 56}
      plain={plain ? true : false}
      isNav={isNav ? true : false}
      isTempImage={isTempImage}
    />
  )
}

export default Avatar
