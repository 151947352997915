import React from "react"
import { Formik, Form, FormikHelpers } from "formik"
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MUITextField,
} from "@material-ui/core"
import * as Yup from "yup"

import TextField from "../TextField"
import SelectField from "../SelectField"
import { monthsNames, availableYears } from "../../constants/months-years"
import { ActionButton } from "./styles"
import { JobPositionWithDates } from "./index"
import Autocomplete from "@material-ui/lab/Autocomplete"

export type Props = {
  values: JobPositionWithDates
  suggestions: string[]
  onSubmit: (
    values: JobPositionWithDates,
    bag: FormikHelpers<JobPositionWithDates>
  ) => void
  onCancel: () => void
}

const JobPositionSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  startMonth: Yup.number().moreThan(0, "Required").required("Required"),
  startYear: Yup.number().moreThan(0, "Required").required("Required"),
  description: Yup.string().required("Required"),
  endMonth: Yup.number().when("current", {
    is: false,
    then: Yup.number().moreThan(0, "Required").required("Required"),
  }),
  endYear: Yup.number().when("current", {
    is: false,
    then: Yup.number().moreThan(0, "Required").required("Required"),
  }),
})

const View = ({ values, onSubmit, onCancel, suggestions }: Props) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={JobPositionSchema}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form>
          <Grid container justify="center">
            <Grid item lg={3} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="title"
                    label="Job Title"
                    data-testid="jobTitle"
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    name="company"
                    value={formik.values.company}
                    data-testid="company"
                    options={suggestions}
                    onBlur={() => formik.setFieldTouched("company")}
                    onInputChange={(_, value) =>
                      formik.setFieldValue("company", value)
                    }
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        autoComplete="off"
                        name="company"
                        label="Company"
                        data-testid="companyName"
                        helperText={
                          formik.errors.company !== undefined &&
                          formik.touched.company
                            ? formik.errors.company
                            : ""
                        }
                        error={
                          formik.errors.company !== undefined &&
                          formik.touched.company
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={2} marginBottom={1}>
                    <Typography>Start date</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <SelectField
                      name="startMonth"
                      label="Month"
                      options={monthsNames}
                      blank="Select a month"
                      data-testid="startMonth"
                      margin="none"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginLeft={1} marginBottom={1}>
                    <SelectField
                      name="startYear"
                      label="Year"
                      options={availableYears}
                      blank="Select a year"
                      data-testid="startYear"
                      margin="none"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginBottom={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.current}
                          name="current"
                          onChange={formik.handleChange}
                          data-testid="currentJob"
                        />
                      }
                      label="I am currently working in this role"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginBottom={1}>
                    <Typography>End date</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <SelectField
                      name="endMonth"
                      label="Month"
                      options={monthsNames}
                      blank="Select a month"
                      data-testid="endMonth"
                      margin="none"
                      disabled={formik.values.current}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginLeft={1}>
                    <SelectField
                      name="endYear"
                      label="Year"
                      options={availableYears}
                      blank="Select a year"
                      data-testid="endYear"
                      margin="none"
                      disabled={formik.values.current}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={2} marginBottom={3}>
                    <TextField
                      label="Job and/or role description"
                      name="description"
                      multiline
                      rows={5}
                      data-testid="description"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <ActionButton
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {values.id ? "Update" : "Add"} position
                    </ActionButton>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginLeft={1}>
                    <Button fullWidth onClick={onCancel}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default View
