import React from "react"
import { useHistory } from "react-router-dom"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useAuth0 } from "@auth0/auth0-react"
import { Post } from "../../redux/posts"
type Props = {
  post: Post
}

const PostCardIconActions = (props: Props) => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const goToPost = async (post: Post) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    history.push(`/posts/${post.id!}`)
  }

  return <View {...props} goToPost={goToPost} />
}

export default PostCardIconActions
