import React, { useState } from "react"
import View from "./View"
import {
  Post,
  PostMention,
  addComment,
  deletePost,
  getPostsByProfile,
} from "../../redux/posts"
import { useAuth0 } from "@auth0/auth0-react"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { updatePostsProfileState } from "../../redux/profile"
import { updateFeedState } from "../../redux/feed"

export type Props = {
  post: Post
  fullView?: boolean
  isHomepage?: boolean
  isAds?: boolean
}

const PostCard = ({ post, fullView, isHomepage, isAds }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated

  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const [isPostingComment, setIsPostingComment] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isFlagDialogOpen, setIsFlagDialogOpen] = useState(false)

  const handlePostComment = async (
    text: string,
    parentCommentId?: string,
    mentions?: PostMention[]
  ) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      setIsPostingComment(true)
      const { type, payload } = await dispatch(
        addComment({ id: post.id!, auth, text, parentCommentId, mentions })
      )
      if (addComment.fulfilled.type === type) {
        setIsPostingComment(false)

        if (isHomepage) {
          await dispatch(
            updateFeedState({
              type: "post",
              id: post.id!,
              action: "comment",
              comment: payload,
              parentCommentId,
              mentions,
            })
          )
        }
      }
    } catch (error) {
      console.error(error)
      setIsPostingComment(false)
    }
  }

  const handleDeletePost = async () => {
    try {
      const { type } = await dispatch(deletePost({ id: post.id!, auth }))
      if (deletePost.fulfilled.type === type) {
        await dispatch(
          getPostsByProfile({
            profileId: authenticatedProfile?.connectionId!,
            email: authenticatedProfile?.email,
          })
        )

        await dispatch(updatePostsProfileState({ action: "delete" }))

        setIsDeleteDialogOpen(false)
        if (fullView) {
          history.push(`/sp/${authenticatedProfile?.username}/posts`)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteDialogOpen = (value: boolean) => {
    setIsDeleteDialogOpen(value)
  }

  const handleEditDialogOpen = (value: boolean) => {
    setIsEditDialogOpen(value)
  }

  const handleFlagDialogOpen = (value: boolean) => {
    setIsFlagDialogOpen(value)
  }

  return (
    <View
      post={post}
      authenticatedProfile={authenticatedProfile}
      isPostingComment={isPostingComment}
      handlePostComment={handlePostComment}
      handleDeleteDialogOpen={handleDeleteDialogOpen}
      handleDeletePost={handleDeletePost}
      isDeleteDialogOpen={isDeleteDialogOpen}
      handleEditDialogOpen={handleEditDialogOpen}
      isEditDialogOpen={isEditDialogOpen}
      handleFlagDialogOpen={handleFlagDialogOpen}
      isFlagDialogOpen={isFlagDialogOpen}
      fullView={fullView}
      isHomepage={isHomepage}
      isAuthenticated={isAuthenticated}
      isAds={isAds}
    />
  )
}

export default PostCard
