import React from "react"
import View from "./View"
import { useScript } from "../../hooks/useScript"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const TermsUsePage = () => {
  setTimeout(() => {
    // @ts-ignore
    window.prerenderReady = true
  }, 5000)

  useScript("https://app.termly.io/embed-policy.min.js")
  return (
    <>
      <RouteChangeTracker
        screenTitle={`Terms of Use`}
        classTitle="TermsOfUse"
      />
      <Helmet>
        <title>Terms of use - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Terms of use - Spectacular`} />
        <meta name="title" content={`Terms of use - Spectacular`} />
      </Helmet>
      <View />
    </>
  )
}

export default TermsUsePage
