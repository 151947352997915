export type Props = {
  profileType: any
  location: String
}

const SimpleAddress = ({ profileType, location }: Props) => {
  if (!location) return null
  if (profileType === "group") return location
  let address = location
    .split(/[,-]+/)
    .filter((el: any) => isNaN(el))
    .splice(-3)
  return address.join(", ")
}

export default SimpleAddress
