import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import StripeForm from "../StripeForm"
import { Product, Tax } from "../../redux/product"
import { JobListing } from "../../redux/jobListings"

const stripePromise = loadStripe("pk_test_p6Hpb7GbHCUJus4azgtGuJxm")

type Props = {
  handleBack: () => void
  handleOpenDialog: () => void
  calculateTax: (
    line1: string,
    city: string,
    state: string,
    country: string,
    postalCode: string
  ) => void
  product?: Product
  job?: JobListing
  tax?: Tax
}

function StripeProvider({
  handleBack,
  handleOpenDialog,
  calculateTax,
  product,
  job,
  tax,
}: Props) {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        job={job}
        handleBack={handleBack}
        handleOpenDialog={handleOpenDialog}
        calculateTax={calculateTax}
        product={product}
        tax={tax}
      />
    </Elements>
  )
}

export default StripeProvider
