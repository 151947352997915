import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useAuth0 } from "@auth0/auth0-react"
import { Post, likePost, dislikePost } from "../../redux/posts"
import { updateFeedState } from "../../redux/feed"
import { logEvent } from "../../utils/analytics"

type Props = {
  post: Post
}

const PostLikeIcon = ({ post }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)

  const handleLikeClick = async (like: boolean): Promise<void> => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    if (like) {
      handlePostDislike()
    } else {
      handlePostLike()
    }
  }

  const handlePostLike = async () => {
    setDispatchComplete(false)
    try {
      const likeStatus = await dispatch(likePost({ auth, id: post.id! }))
      if (likeStatus.type === likePost.fulfilled.type) {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "post",
            id: post.id!,
            action: "like",
          })
        )

        await logEvent("post_like")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handlePostDislike = async () => {
    setDispatchComplete(false)
    try {
      const disLikeStatus = await dispatch(dislikePost({ auth, id: post.id! }))
      if (disLikeStatus.type === dislikePost.fulfilled.type) {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "post",
            id: post.id!,
            action: "dislike",
          })
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      post={post}
      handleLikeClick={handleLikeClick}
    />
  )
}

export default PostLikeIcon
