import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import bgHeader from "../../images/innovation-awards/header-bg-desktop.png"
import bgHeaderMobile from "../../images/innovation-awards/header-bg-mobile.png"

import bgAwards from "../../images/innovation-awards/awards-bg.png"

import BorderUp from "../../images/innovation-awards/form-border-up.png"
import BorderDown from "../../images/innovation-awards/form-border-down.png"

import BorderUpDialog from "../../images/innovation-awards/dialog-border-up.png"
import BorderDownDialog from "../../images/innovation-awards/dialog-border-down.png"

import ulIcon from "../../images/innovation-awards/ul-icon.png"
import ulIconWhite from "../../images/innovation-awards/ul-icon-white.png"

import Quotes from "../../images/new-homepage/quotes.png"
import MobileQuotes from "../../images/new-homepage/quotes-mobile.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background: url(${bgHeader});
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  height: 417px;
  overflow: hidden;
  border-bottom: 2px solid #000000;

  ${theme.breakpoints.down("sm")} {
    height: auto;
  }

  ${theme.breakpoints.down("xs")} {
    height: 316px;
    align-items: center;
    background: url(${bgHeaderMobile});
    background-position: right;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
  }
`

export const HeaderButtonBox = styled(Box)`
  padding-top: 24px;
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 100px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;

    && button {
      width: 90% !important;
    }
  }

  @media only screen and (max-width: 429px) {
    padding: 0 20px;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const StyledLogo = styled.img`
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const DescriptionBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #F5F5F5;
  border-bottom: 2px solid #000000;
  box-sizing: border:box;
`

export const DescriptionContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const DescriptionFlexBox = styled(Box)`
  display: flex;
  gap: 32px;
`

export const DescriptionLeftBox = styled(Box)`
  padding-bottom: 78px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 47px;
  }
`

export const DescriptionFont = styled(Typography)`
  padding-top: 96px;
  font-size: 24px;
  line-height: 36px;

  && span {
    display: block;
    padding-top: 28px;
    font-size: 16px;
    line-height: 26px;
  }

  ${theme.breakpoints.down("sm")} {
    padding-top: 50px;

    && span {
      padding-top: 22px;
    }
  }
`

export const DescriptionIconBox = styled(Box)`
  border-left: 2px solid #000000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 418px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const HowItWorksBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #2d2d2d;
  border-bottom: 2px solid #fff;
`

export const HowItWorksContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0;
  }
`

export const HowItWorksTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  margin-top: 85px;
  margin-bottom: 72px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    line-height: 36px;
    margin-top: 44px;
    margin-bottom: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    padding-left: 20px;
  }
`

export const StepsBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    flex-flow: wrap;
  }
`

export const StepBox = styled(Box)<{ number: number }>`
  box-sizing: border-box;
  padding: 47px 45px 64px;
  width: 100%;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  border-right: 2px solid #ffffff;
  border-left: ${({ number }) => (number === 1 ? "2px solid #FFFFFF" : "")};
  background-color: #2d2d2d;

  &:hover {
    background-color: #3057e1;
  }

  ${theme.breakpoints.down("xs")} {
    display: flex;
    padding: 20px 40px 20px 20px;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: ${({ number }) => (number === 2 ? "2px solid #FFFFFF" : "")};
    border-top: ${({ number }) => (number === 2 ? "2px solid #FFFFFF" : "")};
  }
`

export const StyledUploadIcon = styled.img`
  width: 107px;
  height: 150px;
  display: flex;

  ${theme.breakpoints.down("xs")} {
    width: 43px;
    height: 60px;
  }
`

export const StyledSubmitIcon = styled.img`
  width: 120px;
  height: 120px;
  display: flex;

  ${theme.breakpoints.down("xs")} {
    width: 60px;
    height: 60px;
  }
`

export const StyledShareIcon = styled.img`
  width: 121px;
  height: 121px;
  display: flex;

  ${theme.breakpoints.down("xs")} {
    width: 60px;
    height: 60px;
  }
`

export const StepTitle = styled(Typography)`
  color: #fff;
  font-size: 48px;
  margin-top: -15px;
`

export const StepText = styled(Typography)`
  color: #fff;
  font-size: 24px;
  margin-top: 17px;

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;
    margin-top: -13px;
  }
`

export const StepImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
  height: 150px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 33px;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    height: 60px;
    width: 60px;
  }
`

export const ProjectsBox = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: #2d2d2d;
  border-bottom: 2px solid #fff;
  padding: 103px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 45px 0 50px;
  }
`

export const ProjectsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  display: flex;
  gap: 180px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  @media only screen and (max-width: 1250px) {
    gap: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
    gap: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    gap: 50px;
  }
`

export const ProjectsContent = styled(Box)``

export const ProjectsTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const ProjectsText = styled(Typography)`
  font-size: 16px;
  line-height: 26px;
  margin-top: 33px;
  color: #fff;

  && span {
    font-weight: bold;
  }
`

export const ProjectsButtonBox = styled(Box)`
  margin-top: 29px;

  ${theme.breakpoints.down("sm")} {
    text-align: center;

    && button {
      width: 90% !important;
    }
  }
`

export const ProjectsImageBox = styled(Box)`
  && img {
    border-radius: 4px;
  }

  @media only screen and (max-width: 850px) {
    && img {
      width: 320px;
    }
  }

  @media only screen and (max-width: 680px) {
    && img {
      width: 300px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    && img {
      width: 100%;
    }
  }
`

export const AwardsBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: url(${bgAwards});
  background-position: right;
  background-repeat: no-repeat;
  background-color: #2d2d2d;
  padding-bottom: 140px;
  border-bottom: 2px solid #fff;

  ${theme.breakpoints.down("sm")} {
    background: none;
    background-color: #2d2d2d;
    padding-bottom: 85px;
  }
`

export const AwardsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const AwardsTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  margin-top: 121px;
  color: #fff;

  && img {
    margin-right: 22px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    line-height: 36px;
    margin-top: 70px;
  }

  ${theme.breakpoints.down("xs")} {
    && img {
      vertical-align: sub;
    }
  }
`

export const AwardsContent = styled(Box)`
  width: 53%;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const AwardsLine = styled(Typography)`
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px;
  color: #fff;

  && a {
    color: #1da1f2;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
  }

  && a:hover {
    color: #1da1f2;
    text-decoration: underline;
  }

  && span {
    margin-top: 25px;
    display: block;
  }
`

export const AwardsButton = styled(Box)`
  margin-top: 45px;

  ${theme.breakpoints.down("sm")} {
    text-align: center;

    && button {
      width: 90% !important;
    }
  }
`

export const PanelistsTitleTemp1 = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  box-sizing: border-box;
  padding-top: 101px;

  && img {
    width: 45px;
    height: 45px;
    vertical-align: baseline;
    margin-left: 34px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 54px;

    && img {
      width: 40px;
      height: 40px;
      vertical-align: baseline;
      margin-left: 39px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    padding-top: 70px;

    && img {
      width: 24px;
      height: 24px;
      vertical-align: baseline;
      margin-left: 20px;
    }
  }
`

export const PanelistsTitleTemp2 = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  box-sizing: border-box;
  padding-bottom: 81px;
  padding-top: 10px;

  && img {
    width: 74px;
    height: 74px;
    vertical-align: sub;
    margin-left: 39px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 54px;

    && img {
      width: 64px;
      height: 64px;
      vertical-align: sub;
      margin-left: 39px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    padding-bottom: 55px;

    && img {
      width: 32px;
      height: 32px;
      vertical-align: sub;
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 453px) {
    && img {
      width: 32px;
      height: 32px;
      vertical-align: sub;
      margin-left: 20px;
      position: absolute;
      margin-top: -37px;
      right: 40px;
    }
  }

  @media only screen and (max-width: 401px) {
    font-size: 28px;
  }
`

export const PanelistsTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  margin-top: 88px;
  padding-bottom: 76px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    margin-top: 56px;
    font-size: 40px;
    padding-bottom: 41px;
  }
`

export const JuryBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  border-bottom: 2px solid #2d2d2d;
`

export const JuryBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;
  padding-bottom: 66px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 56px;
  }
`

export const JuryBoxTemp = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    border: 0;
    padding: 20px 0;
  }
`

export const JuryBorderedTemp = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    border-bottom: 2px solid #2d2d2d;
    border-top: 2px solid #2d2d2d;
  }
`

export const JuryContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const JuryTempBox = styled(Box)`
  border-left: 2px solid #2d2d2d;
  border-right: 2px solid #2d2d2d;
  padding-left: 80px;

  ${theme.breakpoints.down("sm")} {
    border: 0;
    padding: 0;
  }
`

export const PanelistsBox = styled(Box)`
  display: flex;
  margin-top: 55px;
  gap: 32px;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    gap: 32px;
    flex: 40%;
    justify-content: center;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    margin-top: 64px;
    flex: 100%;
  }
`

export const PanelistBox = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    width: 226px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const PanelistIcon = styled.img`
  width: 100%;
  border-radius: 4px;
`

export const PanelistTextBox = styled(Box)`
  margin-top: 11px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 6px;
  }
`

export const PanelistTitle = styled(Typography)`
  font-size: 24px;
  font-family: Public-Sans-Medium;
`

export const PanelistText = styled(Typography)`
  font-size: 17px;
  line-height: 22px;
  color: #717171;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 17px;
  }
`

export const PrizesBox = styled(Box)`
  margin-top: 14px;
  display: flex;
  gap: 24px;
  flex-flow: column;

  ${theme.breakpoints.down("sm")} {
    margin-top: 17px;
  }
`

export const PanelistIconsBox = styled(Box)`
  margin-top: 19px;
  display: flex;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 15px;

    && a {
      display: flex;
    }
  }
`

export const PanelistDescriptionBox = styled(Box)`
  margin-top: 16px;
`

export const PanelistDescription = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
`

export const DatesBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #2d2d2d;
`

export const DatesContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;
  display: flex;
  gap: 32px;

  @media only screen and (max-width: 1420px) {
    padding-left: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    flex-flow: column;
    gap: 0;
  }
`

export const DatesDescriptionBox = styled(Box)`
  width: 100%;
  padding-top: 86px;
  padding-bottom: 106px;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 20px 47px;
    flex-flow: column;
    width: auto;
  }
`

export const DatesTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const DatesList = styled(Box)`
  color: #fff;
  font-size: 20px;
`

export const DatesListItem = styled(Box)`
  color: #fff;
  font-size: 20px;
  background: url(${ulIconWhite}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  margin-top: 24px;

  ${theme.breakpoints.down("sm")} {
    line-height: 36px;
    background-position-y: 9px;
  }
`

export const DatesTimerBox = styled(Box)`
  border-left: 2px solid #fff;
  min-width: 418px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  
  ${theme.breakpoints.down("sm")} {
    border-top: 2px solid #fff;
    border-left: 0;
    padding-top: 27px;
    padding-bottom: 31px;
  }
  
  ${theme.breakpoints.down("xs")} {
    min-width: 100%;
  }
}
`

export const CountdownFlexBox = styled(Box)`
  display: flex;
  gap: 32px;
  margin-top: 10px;
  margin-bottom: 21px;

  ${theme.breakpoints.down("sm")} {
    gap: 24px;
  }
`

export const CountdownItem = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

export const CountdownText = styled(Typography)`
  color: #fff;
  font-size: 52px;
  text-align: center;
`

export const CountdownLabel = styled(Typography)`
  color: #d9d9d9;
  font-size: 14px;
  text-align: center;
  margin-top: -9px;
`

export const DatesTimerBoxTitle = styled(Typography)`
  font-size: 24px;
  color: #fff;
  font-family: Public-Sans-Medium;
`

export const RequirementsBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;
`

export const RequirementsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const RequirementsTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  padding-top: 68px;
  padding-bottom: 64px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding-top: 44px;
    padding-bottom: 42px;
    font-size: 40px;
  }
`

export const RequirementsFlexBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    display: initial;
  }
`

export const RequirementsLeftBox = styled(Box)`
  box-sizing: border-box;
  border-right: 2px solid #2d2d2d;
  border-left: 2px solid #2d2d2d;
  width: 100%;
  padding: 28px 20px 49px;
  flex-flow: column;
  display: flex;
  gap: 32px;

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0;
    margin-top: 45px;
  }
`

export const RequirementsRightBox = styled(Box)`
  box-sizing: border-box;
  border-right: 2px solid #2d2d2d;
  width: 100%;
  padding: 28px 20px 49px;
  flex-flow: column;
  display: flex;
  gap: 32px;

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0;
    margin-top: 45px;
    padding-bottom: 87px;
  }
`

export const RequirementsInnerTitle = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 24px;
`

export const RequirementsItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 17px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }
`
export const RequirementsSpacedBox = styled(Box)`
  margin-top: 45px;
`

export const FormArea = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #ffffff;
`

export const FormContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 100px 185px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px;
  }
`

export const FormBG = styled(Box)`
  background: url(${BorderUp}) top right no-repeat,
    url(${BorderDown}) bottom left no-repeat;
  box-sizing: border-box;
  padding: 36px 108px 52px;

  ${theme.breakpoints.down("xs")} {
    padding: 51px 31px 48px;
  }
`

export const FormSpacingBox = styled(Box)`
  padding-top: 27px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 20px;

    && button {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
`

export const FormTitle = styled(Typography)`
  font-size: 48px;
  line-height: 33px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const FormSubTitle = styled(Typography)`
  font-size: 16px;
  margin-bottom: 28px;
  margin-top: 18px;

  && span {
    color: #ff0000;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    margin-bottom: 18px;
    margin-top: 16px;
  }
`

export const SuccessDialogArea = styled(Dialog)`
  min-height: 100vh;
`

export const SuccessDialogBox = styled(Box)`
  overflow-x: hidden;
  width: 640px;
  height: fit-content;
  box-sizing: border-box;
  padding: 27px;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const DialogBgBox = styled(Box)<{ $marketingDialog?: boolean }>`
  box-sizing: border-box;
  background: url(${BorderUpDialog}) top left no-repeat,
    url(${BorderDownDialog}) bottom right no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: ${({ $marketingDialog }) =>
    $marketingDialog ? "80px 0" : "39px 0"};
`

export const MarketingTitleBox = styled(Box)`
  margin-top: 30px;
`

export const MarketingDialogTitle = styled(Typography)`
  font-size: 24px;
  line-height: 36px;
  font-family: Public-Sans-Bold;
  color: #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const MarketingDialogText = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  color: #2d2d2d;
`

export const TextfieldBox = styled(Box)`
  text-align: left;
  margin-top: 48px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;

    && button {
      width: 100% !important;
    }
  }
`

export const SuccessDialogTitle = styled(Typography)`
  font-size: 48px;
  color: #2d2d2d;
  margin-top: 32px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 18px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 40px;
  }
`

export const SuccessDialogText = styled(Typography)`
  font-size: 24px;
  margin-top: 30px;

  && span {
    font-family: "Public-Sans-Bold";
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 16px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 20px;
  }
`

export const SuccessDialogHint = styled(Typography)`
  font-family: "Public-Sans-Bold";
  font-size: 18px;
  line-height: 36px;
  margin-top: 49px;

  @media only screen and (max-width: 390px) {
    font-size: 16px;
    line-height: 32px;
  }
`

export const DialogIconsBox = styled(Box)`
  margin-top: 26px;
  display: flex;
  gap: 21px;

  @media only screen and (max-width: 390px) {
    gap: 16px;

    && img {
      width: 40px;
    }
  }
`

export const DialogManualShareBox = styled(Box)`
  display: flex;
  gap: 55px;
  margin-top: 22px;

  @media only screen and (max-width: 390px) {
    flex-flow: column;
    gap: 12px;
  }
`

export const CopyLinkText = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const EmailText = styled.a`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;
  color: #2d2d2d;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const SpIconImg = styled.img`
  margin-top: 39px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 23px;
  }
`

export const CheckboxLabel = styled(Typography)`
  font-size: 16px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`

export const CheckboxLabelSmall = styled(Typography)`
  font-size: 12px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`
export const SuccessIconImg = styled.img`
  ${theme.breakpoints.down("xs")} {
    height: 70px;
  }

  @media only screen and (max-width: 390px) {
    height: 60px;
  }
`

export const SuccessSubscriptionIconImg = styled.img`
  height: 66px;
`

export const DialogSpIcon = styled.img`
  height: 72px;
`

export const CloseIconImg = styled.img`
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;
`

export const EmailBox = styled(Box)`
  background: #2d2d2d;
  height: 239px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: -64px;
  border-bottom: 2px solid #ffffff;

  ${theme.breakpoints.down("xs")} {
    margin-bottom: -20px;
  }
`

export const EmailTitle = styled(Typography)`
  font-size: 48px;
  color: #fff;
  margin-bottom: 27px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const EmailLink = styled.a`
  font-size: 24px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #fff;
  }

  && img {
    margin-right: 16px;
    vertical-align: middle;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;

    && img {
      height: 20px;
    }
  }
`
export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0.9px;
    right: 138px;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const InactiveShareBox = styled(Box)`
  width: 320px;
  padding: 20px;
  font-size: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  font-family: Public-Sans-Bold;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    font-size: 14px;
  }
`

export const InactiveShareImages = styled(Box)`
  display: flex;
  gap: 30px;
  margin-top: 19px;
  justify-content: center;

  && img {
    width: 32px;
  }
`

export const AddAuthor = styled(Typography)`
  color: #3057e1;
  font-size: 16px;
  font-style: italic;
  cursor: pointer;
`

export const InlineLink = styled.span`
  color: #3057e1;
  font-style: italic;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled.a`
  font-family: Public-Sans;
  text-decoration: none;
  color: #3057e1;

  :hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const JuryBorder = styled(Box)`
  width: 100%;
  border-bottom: 2px solid #ffffff;
  padding-top: 80px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 64px;
  }
`

export const JuryBorderBottom = styled(Box)`
  width: 100%;
  border-top: 2px solid #ffffff;
`

export const JuryReviewContainer = styled(Box)`
  background-color: #2d2d2d;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`

export const JuryReviewBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0px 20px;
  }
`

export const JuryReviewTitle = styled(Typography)`
  padding-top: 60px;
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    padding-top: 32px;
  }
`

export const JuryReviewList = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 64px;
  margin: 64px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
    gap: 32px;
    margin: 32px 0;
  }
`

export const JuryReviewCard = styled(Box)<{ oriented?: string }>`
  width: 850px;
  height: 460px;
  filter: drop-shadow(-10px 11px 66px rgba(0, 0, 0, 0.25));
  display: flex;
  box-sizing: border-box;
  padding: 56px 64px;
  background: url(${Quotes}) #3f3e3e;
  background-position: ${({ oriented }) =>
    oriented === "left" ? "227px 184px" : "362px 184px"};
  background-repeat: no-repeat;
  border-radius: 1px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    height: auto;
    background: #3f3e3e;
    padding: 0;
  }
`

export const JuryReviewCardDescription = styled(Box)<{ oriented?: string }>`
  width: 100%;
  padding-left: ${({ oriented }) => (oriented === "left" ? "75px" : "0")};

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    padding: 24px;
    box-sizing: border-box;

    background: url(${MobileQuotes}) #3f3e3e;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 10px;
  }
`

export const JuryReviewCardImage = styled(Box)`
  && img {
    height: 283px;
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      height: 235px;
      display: flex;
    }
  }
`

export const JuryReviewCardTitle = styled(Typography)`
  color: #fff;
  font-size: 64px;
  line-height: 55.42px;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    line-height: 35.78px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
    line-height: 20.78px;
  }
`

export const JuryReviewCardText = styled(Typography)<{ oriented?: string }>`
  color: #fff;
  font-size: 24px;
  line-height: 32.78px;
  margin-top: 50px;
  padding-right: ${({ oriented }) => (oriented === "left" ? "0" : "80px")};

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 24.12px;
    padding-right: 0;
    margin-top: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 19.12px;
    margin-top: 12px;
  }
`

export const JuryReviewCardHint = styled(Typography)`
  color: #fff;
  font-size: 16px;
  margin-top: 20px;

  && span {
    font-family: "Public-Sans-Bold";
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 19.12px;
    margin-top: 16px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 19.12px;
    margin-top: 16px;
  }
`

export const CountdownFlexBoxAnnouncement = styled(Box)`
  display: flex;
  gap: 32px;
  margin-top: 10px;
  margin-bottom: 21px;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    gap: 24px;
  }
`

export const CountdownItemAnnouncement = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

export const CountdownTextAnnouncement = styled(Typography)`
  color: #fff;
  font-size: 52px;
  text-align: center;
`

export const CountdownLabelAnnouncement = styled(Typography)`
  color: #fff;
  font-size: 14px;
  text-align: center;
`

export const JuryCountdown = styled(Box)`
  display: flex;
  margin-top: 90px;
  padding-bottom: 90px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    margin-top: 50px;
    padding-bottom: 64px;

    && button {
      width: 100% !important;
    }
  }
`

export const JuryCountdownText = styled(Box)`
  min-width: 580px;

  @media only screen and (max-width: 1100px) {
    min-width: 400px;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 100%;
  }
`

export const JuryCountdownBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  && img {
    width: 120px;
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      width: 90px;
    }
  }
`

export const JuryCountdownTitle = styled(Typography)`
  color: #fff;
  font-size: 64px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const JuryCountdownDescription = styled(Typography)`
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 48px;

  && span {
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`

export const JuryCountdownBoxTitle = styled(Typography)`
  font-size: 24px;
  color: #fff;
  margin-top: 32px;
  ${theme.breakpoints.down("sm")} {
    margin-top: 16px;
    margin-top: 24px;
  }
`

export const VideoTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  margin-top: 88px;
  padding-bottom: 76px;
  box-sizing: border-box;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    margin-top: 56px;
    font-size: 40px;
    padding-bottom: 41px;
  }
`

export const VideoBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #2d2d2d;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
`

export const VideoBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #2d2d2d;
  box-sizing: border-box;
  padding-bottom: 66px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 56px;
  }
`

export const VideoContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`
export const VideoCarouselBox = styled(Box)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
`

export const VideoPlayerBox = styled(Box)`
  width: 720px;
  margin-left: auto;
  margin-right: auto;

  && video {
    max-height: 405px;
  }

  @media only screen and (max-width: 1090px) {
    width: 650px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const PendingJuryBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
`

export const PendingJuryBox = styled(Box)`
  width: 100%;
  background: #f5f5f5;
  box-sizing: border-box;
  padding: 0 0 64px;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 0;
  }
`

export const PendingJuryContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px 64px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  && span {
    font-size: 125px;
    color: #979797;
    font-family: "FreightDispProBook";
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px 40px;

    && span {
      font-size: 100px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    && span {
      font-size: 80px;
    }
  }

  @media only screen and (max-width: 470px) {
    && span {
      font-size: 64px;
    }
  }

  @media only screen and (max-width: 385px) {
    && span {
      font-size: 48px;
    }
  }
`
