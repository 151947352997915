import React from "react"

import { IconContainer } from "./styles"

import HomeIcon from "../../images/app-navbar/home-icon.svg"
import HomeIconActive from "../../images/app-navbar/home-icon-active.svg"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { useLocation } from "react-router-dom"
import { logEvent } from "../../utils/analytics"

export const HomeNavIcon = () => {
  const location = useLocation()
  const active = location.pathname === "/"

  const handleClick = async () => {
    await logEvent("navbar_home_click")
  }

  return (
    <LinkBox to={`/`} onClick={handleClick}>
      <IconContainer $isActive={active}>
        <img alt="Home" src={active ? HomeIconActive : HomeIcon} />
        Home
      </IconContainer>
    </LinkBox>
  )
}
