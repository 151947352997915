import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldLabelAbove from "../TextFieldLabelAbove"

export type Props = {
  value: string
  suggestions: string[]
  onInputChange: (value: string) => void
}

const View = ({ value, suggestions, onInputChange }: Props) => {
  return (
    <>
      <Autocomplete
        freeSolo
        id="project-role"
        value={value}
        inputValue={value}
        options={suggestions}
        onInputChange={(_, newValue) => onInputChange(newValue)}
        renderInput={(params) => (
          <TextFieldLabelAbove
            {...params}
            name={"project-role"}
            displayTitle={"Your Role"}
            placeholder={"Select your role in the project"}
            margin="normal"
            style={{ height: "48px", paddingTop: 0 }}
            InputProps={{ ...params.InputProps }}
          />
        )}
      />
    </>
  )
}

export default View
