import React from "react"
import { Box, Link, Typography } from "@material-ui/core"
import styled from "styled-components"

import { DropzoneProps } from "."
import theme, { colors } from "../../theme"

const getColor = ({
  isDragAccept,
  isDragReject,
  isDragActive,
}: DropzoneProps) => {
  if (isDragAccept) return colors.green

  if (isDragReject) return "#ff1744"

  if (isDragActive) return "#2196f3"

  return "lightgrey"
}

export const IconOverlay = styled.div`
  z-index: 2;
  position: relative;
`

export const IconContainer = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-40%, 75%);
`

export const DropzoneContainer = styled.div<DropzoneProps>`
  flex: 1;
  outline: none;
  display: flex;
  padding: 20px;
  border-width: 2px;
  min-height: 400px;
  max-width: 800px;
  border-radius: 2px;
  margin-top: 1.5rem;
  align-items: center;
  border-style: dashed;
  flex-direction: column;
  transition: border 0.24s ease-in-out;
  border-color: ${(props) => getColor(props)};

  ${theme.breakpoints.down("xs")} {
    min-height: auto;
    height: auto;
  }
`

export const CompactDropzoneContainer = styled.div<DropzoneProps>`
  flex: 1;
  outline: none;
  display: flex;
  padding: 20px;
  border-width: 2px;
  height: 91px;
  width: 126px;
  max-width: 126px;
  border-radius: 2px;
  margin-top: 1.5rem;
  align-items: center;
  border-style: dashed;
  flex-direction: column;
  transition: border 0.24s ease-in-out;
  border-color: ${(props) => getColor(props)};
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
`

export const StyledLinkBox = styled(Box)`
  display: inline;
  font-family: Public-Sans;
  a {
    border-bottom: solid 0.5px ${colors.dark};
  }
`

export const DropzoneBox = styled(Box)`
  margin-top: 32px;
  padding: 70px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    padding: 0;
    gap: 8px;
  }
`

export const DropzoneLine = styled(Typography)`
  margin-top: 0;

  && span {
    font-family: Public-Sans-Bold;
    text-decoration: underline;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const DropzoneList = styled.ul`
  && li {
    margin-bottom: 10px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`
