import React from "react"

import { Box } from "@material-ui/core"

import WeekendCompetitionLogo from "../../images/innovation-awards/weekend-competition-logo.svg"
import AIA from "../../images/career-fair/aias.svg"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
} from "./styles"
import { Competition } from "../../redux/competition"
import { AIABox, AIAImage, AIAText } from "../InternExpoPage/styles"

type Props = {
  competition?: Competition
}

const Header = ({ competition }: Props) => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <Box>
            <StyledLogo src={WeekendCompetitionLogo} alt={"Logo"} />
          </Box>
          {competition?.isAIA && (
            <AIABox>
              <AIAText>In Partnership with</AIAText>
              <AIAImage>
                <img src={AIA} alt="AIAS" />
              </AIAImage>
            </AIABox>
          )}
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
