import React from "react"
import { Grid } from "@material-ui/core"

import { MainContainer } from "./../HomePage/styles"
import { Box } from "@material-ui/core"

const View = () => (
  <MainContainer>
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <Box
          pl={4}
          dangerouslySetInnerHTML={{
            __html: `<button class="termly-cookie-preference-button" type="button" style="background: white; width: 165px; height: 30px; border-radius: 3px; border: 1px solid #5f7d9c; font-family: Roboto, Arial; font-size: 10px; color: #5f7d9c; font-weight: 600; box-sizing: border-box; cursor: pointer; padding: 0; outline: none;" onclick="displayPreferenceModal()">Manage Cookie Preferences</button>`,
          }}
        ></Box>
        <div
          name="termly-embed"
          data-id="3fb640da-f306-4d05-af57-bebabf2f656c"
          data-type="iframe"
        ></div>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
