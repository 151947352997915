import React from "react"
import {
  Typography,
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core"
import ListItem from "./ListItem"
import { Project } from "../../../redux/projects"

export type Props = {
  projects: Project[]
}

const View = ({ projects }: Props) => {
  return (
    <Container>
      <Box marginBottom={2}>
        <Typography variant="h2">Flagged Projects List</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Owner</TableCell>
              <TableCell>Post Date</TableCell>
              <TableCell>Number of Flags</TableCell>
              <TableCell>Last Flag Reason</TableCell>
              <TableCell>Last Flag Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => {
              return <ListItem project={project} key={project.id} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default View
