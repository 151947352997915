import React from "react"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { Box, Grid, Button, Container, Typography } from "@material-ui/core"

import {
  Divider,
  DetailsBox,
  FeaturedIndicator,
  SlateUnderLineLink,
  ProjectDetailsContainer,
  ProfileInfoContainer,
  DescriptionTitle,
  ProjectCardIconsBox,
} from "./styles"

import { Description } from "./Description"

import { Project } from "../../redux/projects"

import FeaturedIcon from "../../images/featured-icon.svg"

import projectStates from "../../constants/project-states"
import { projectToolOptions } from "../../constants/project-tools"
import { projectCategories } from "../../constants/project-categories"

import ImageGallery from "../../components/ImageGallery"
import ProjectComments from "../../components/ProjectComments"
import ProjectDeleteLink from "../../components/ProjectDeleteLink"
import ProjectPostComment from "../../components/ProjectPostComment"
import { useAuth0 } from "@auth0/auth0-react"
import { Collaborator } from "./Collaborator"
import { Profile } from "../../redux/profile"
import ProjectWinnerBadge from "../../components/ProjectWinnerBadge"
import ProjectCardIconActions from "../../components/ProjectCardIconActions"
import BackButton from "../../components/BackButton"
import environment from "../../environment"

export type Props = {
  project: Project
  goToUrl: (url: string) => void
  authenticatedProfile: Profile
  isProjectOwner: boolean
}

const MobileView = ({
  project,
  goToUrl,
  authenticatedProfile,
  isProjectOwner,
}: Props) => {
  const { isAuthenticated } = useAuth0()

  const stateName = projectStates.find(
    (state) => state.value === project.state
  )?.name

  const otherCategory = project.category!.filter((e) => {
    let currCategory = true
    for (let item in projectCategories) {
      if (projectCategories[item].value === e) currCategory = false
    }
    return currCategory
  })

  const foundOtherCategory = otherCategory.toString()

  const featured = project.featured
  const images =
    project.images?.slice()?.sort((a, b) => a.order - b.order) || []

  const history = useHistory()

  const keyMaterials = project.keyMaterials ?? []

  const isProjectComplete = project.state === "complete"

  const publishedDate = project.publishedAt
    ? moment(project.publishedAt).format("MMMM DD[,] YYYY")
    : moment(project.updatedAt).format("MMMM DD[,] YYYY")

  let options = projectToolOptions
  const otherTool = project.tools!.filter((e) => {
    let currTool = true
    for (let item in options) {
      if (options[item].value === e) currTool = false
    }
    return currTool
  })

  const foundOtherTool = otherTool.toString()

  const showButtons = project.collaborators?.length === 0

  const collaborators = project.collaborators?.filter((collaborator) => {
    if (
      collaborator.status !== "pending" ||
      collaborator.profile?.connectionId ===
        authenticatedProfile?.connectionId ||
      isProjectOwner
    )
      return true

    return false
  })

  return (
    <Container
      style={{ paddingRight: "20px", paddingLeft: "20px", overflow: "clip" }}
    >
      <BackButton />
      <Box
        style={{
          marginBottom: "16px",
          marginTop: environment.device === "ios-app" ? "64px" : "",
        }}
      >
        <Typography style={{ fontSize: "25px" }} tabIndex={0} variant="h3">
          {project.name}
        </Typography>
        {isAuthenticated && project.canBeEdited && (
          <>
            <Box marginRight={2} display="inline">
              <Button
                color="primary"
                size="medium"
                onClick={() => {
                  history.push(`/projects/${project.id!}/edit`)
                }}
              >
                Edit project
              </Button>
            </Box>
            <ProjectDeleteLink project={project} />
          </>
        )}
      </Box>

      <Box
        style={{
          paddingBottom: "10px",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      >
        <ImageGallery images={images} />
      </Box>

      <ProjectCardIconsBox>
        <ProjectCardIconActions project={project} />
      </ProjectCardIconsBox>

      <ProfileInfoContainer aria-label="author details">
        <DescriptionTitle variant={"h4"}>
          Project Collaborators
        </DescriptionTitle>

        <Collaborator
          profile={project.profile}
          showButtons={showButtons}
          authenticatedProfile={authenticatedProfile}
          isProjectOwner={isProjectOwner}
        />

        {collaborators?.map((collaborator) => {
          return (
            <Collaborator
              collaborator={collaborator}
              showButtons={false}
              authenticatedProfile={authenticatedProfile}
              isProjectOwner={isProjectOwner}
            />
          )
        })}
      </ProfileInfoContainer>

      <Divider />

      <DetailsBox>
        <Typography
          tabIndex={0}
          style={{
            fontSize: "18px",
            lineHeight: "22px",
            paddingBottom: "16px",
            paddingTop: "20px",
          }}
        >
          Project description
        </Typography>

        <Box style={{ paddingBottom: "20px" }}>
          <Description value={project.description} />
        </Box>
      </DetailsBox>

      <Divider />

      {/** Details */}
      <Grid style={{ padding: "20px 0" }}>
        {featured && (
          <Box marginLeft={2} height={32}>
            <FeaturedIndicator src={FeaturedIcon} aria-label="featured tag" />
          </Box>
        )}

        <Typography
          tabIndex={0}
          style={{
            fontSize: "18px",
            lineHeight: "22px",
            paddingBottom: "16px",
          }}
        >
          Project details
        </Typography>

        {project.rewards && project.rewards?.length > 0 && (
          <>
            {project.rewards.map((reward) => {
              return (
                <div className="projectDetailsWinnerBadge" key={reward.id}>
                  <ProjectWinnerBadge variant="horizontal" reward={reward} />
                </div>
              )
            })}
          </>
        )}

        <Box>
          <Box>
            {stateName && (
              <Typography>
                <b>Project status:</b> {stateName}
              </Typography>
            )}

            {project.stateDate && (
              <Typography>
                <b>
                  {isProjectComplete
                    ? "Date completed"
                    : "Estimated completion date"}
                  :
                </b>{" "}
                {moment(project.stateDate).format("MMM[,] YYYY")}
              </Typography>
            )}

            {project.published && (
              <Typography>
                <b>Published:</b> {publishedDate}
              </Typography>
            )}
          </Box>

          {project.location && (
            <Box style={{ marginTop: "16px" }}>
              <Typography>
                <b>Project location:</b>{" "}
              </Typography>
              {project.location}
            </Box>
          )}

          {project.type === "firm" && project.firmName && (
            <Box style={{ marginTop: "16px" }}>
              <Typography>
                <b>Company:</b> {project.firmName}
              </Typography>
            </Box>
          )}

          {project.type === "school" && project.firmName && (
            <Box style={{ marginTop: "16px" }}>
              <Typography>
                <b>School:</b> {project.firmName}
              </Typography>
            </Box>
          )}

          {project.role || project.responsibilities ? (
            <Box style={{ marginTop: "16px" }}>
              {project.role ? (
                <Typography style={{ marginBottom: ".5em" }}>
                  <b>My role:</b> {project.role}
                </Typography>
              ) : null}
              {project.responsibilities ? (
                <Description value={project.responsibilities || ""} />
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Grid>

      <Divider />

      <ProjectDetailsContainer>
        <Typography style={{ paddingBottom: "12px" }} tabIndex={0} variant="h5">
          Typology
        </Typography>

        <Typography>
          {project.category!.map((category: string, idx: number) => {
            let categoryMatch = projectCategories.find(
              (option) => option.value === category
            )
            if (categoryMatch == null && foundOtherCategory !== null) {
              const addOther = {
                name: foundOtherCategory,
                value: foundOtherCategory,
              }
              categoryMatch = addOther
            }

            return (
              <>
                <SlateUnderLineLink
                  href={`/projects?typology=${JSON.stringify([category])}`}
                >
                  {categoryMatch?.name}
                </SlateUnderLineLink>
                {idx === project.category!.length - 1 ? "" : ", "}
              </>
            )
          })}
        </Typography>

        <Box
          display="block"
          alignItems="flex-start"
          style={{ paddingBottom: "20px", paddingTop: "20px" }}
        >
          <Typography
            style={{ paddingBottom: "12px" }}
            tabIndex={0}
            variant="h5"
          >
            Tools
          </Typography>

          <Typography>
            {project.tools!.map((tool: string, idx: number) => {
              let toolMatch = options.find((option) => option.value === tool)
              if (toolMatch == null && foundOtherTool !== null) {
                const addOther = {
                  name: foundOtherTool,
                  value: foundOtherTool,
                }
                toolMatch = addOther
              }

              return (
                <>
                  <SlateUnderLineLink
                    href={`/projects?tools=${JSON.stringify([tool])}`}
                  >
                    {toolMatch?.name}
                  </SlateUnderLineLink>
                  {idx === project.tools!.length - 1 ? " " : ", "}
                </>
              )
            })}
          </Typography>
        </Box>

        <Box display="block" alignItems="flex-start">
          <Typography
            style={{ paddingBottom: "12px" }}
            tabIndex={0}
            variant="h5"
          >
            Key Materials
          </Typography>

          <Typography>
            {keyMaterials.map((material, idx) => (
              <>
                <SlateUnderLineLink
                  href={`/projects?keyMaterials=${JSON.stringify([material])}`}
                >
                  {material}
                </SlateUnderLineLink>
                {idx === keyMaterials.length - 1 ? " " : ", "}
              </>
            ))}
          </Typography>
        </Box>
      </ProjectDetailsContainer>

      <Divider />

      {/** TAGS */}
      {project.tags && project.tags.length > 0 ? (
        <>
          <ProjectDetailsContainer>
            <Typography variant="h5" tabIndex={0}>
              Tags
            </Typography>

            <Box
              display="block"
              alignItems="flex-start"
              style={{ marginTop: "16px" }}
            >
              {project.tags!.map((tag: string, index: number) => (
                <Box
                  key={index}
                  marginRight={1}
                  marginBottom={1}
                  display="inline-block"
                >
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      goToUrl(`/projects?tags=${JSON.stringify([tag])}`)
                    }
                  >
                    {tag}
                  </Button>
                </Box>
              ))}
            </Box>
          </ProjectDetailsContainer>

          <Divider />
        </>
      ) : null}

      <Box style={{ paddingTop: "20px" }} id="comments">
        {isAuthenticated && <ProjectPostComment id={project.id!} />}
        <ProjectComments project={project} />
      </Box>
    </Container>
  )
}

export default MobileView
