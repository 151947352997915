import { Auth0ContextInterface } from "@auth0/auth0-react"
import environment from "../environment"

export const useApi = async (
  auth: Auth0ContextInterface | null,
  url: string,
  options: any = {}
) => {
  const checkStatus = async (res: Response) => {
    if (res.ok) {
      return res
    } else {
      const { error } = await res.json()
      if (error && error.message) {
        throw new Error(error.message)
      } else {
        throw new Error(res.statusText)
      }
    }
  }

  options.headers = options.headers || {}
  if (options.headers && options.headers["Content-Type"] === "Automatic") {
    delete options.headers["Content-Type"]
  } else {
    options.headers["Content-Type"] = "application/json"
  }

  let baseUrl = `${environment.apiUrl}${url}`

  if (auth) {
    const { getAccessTokenSilently } = auth

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: environment.auth0Audience,
      },
    })

    return fetch(baseUrl, {
      ...options,
      headers: {
        // Add the Authorization header to the existing headers
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      },
    }).then(checkStatus)
  } else {
    return fetch(baseUrl, {
      ...options,
      headers: {
        ...options.headers,
      },
    }).then(checkStatus)
  }
}
