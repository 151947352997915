import styled from "styled-components"
import { Button } from "@material-ui/core"

import theme from "../../theme"

export const ConnectButton = styled(Button)`
  font-weight: 700;
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  height: 33px;
  border-radius: 1px;

  ${theme.breakpoints.down("xs")} {
    width: 67px;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
  }
`
