import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type ProjectFeatured = {
  id?: string
  startDate: string
  endDate: string
  createdAt?: string
  projectId?: string
}
//#endregion

//#region api
type CreateProjectFeaturedPayload = {
  auth: Auth0ContextInterface
  projectFeatured: ProjectFeatured
}
export const createProjectFeatured = createAsyncThunk<
  any,
  CreateProjectFeaturedPayload
>("admin/projectFeatured/create", async ({ auth, projectFeatured }) => {
  const { id, ...projectFeaturedData } = projectFeatured
  return useApi(auth, "/admin/project_featured", {
    method: "POST",
    body: JSON.stringify(projectFeaturedData),
  }).then((res) => res.json())
})

type UpdateProjectFeaturedPayload = {
  auth: Auth0ContextInterface
  projectFeatured: ProjectFeatured
}
export const updateProjectFeatured = createAsyncThunk<
  any,
  UpdateProjectFeaturedPayload
>("admin/projectFeatured/update", async ({ auth, projectFeatured }) => {
  return useApi(auth, `/admin/project_featured/${projectFeatured.id}`, {
    method: "PATCH",
    body: JSON.stringify(projectFeatured),
  }).then((res) => res.json())
})

type DeleteProjectFeaturedPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteProjectFeatured = createAsyncThunk<
  any,
  DeleteProjectFeaturedPayload
>("admin/projectFeatured/delete", async ({ auth, id }) => {
  return useApi(auth, `/admin/project_featured/${id}`, {
    method: "DELETE",
  }).then((res) => res.json())
})
//#endregion

//#region slice
//#endregion

//#region selectors
//#endregion
