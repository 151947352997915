import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Comment, Project } from "../../redux/projects"
import ProjectCommentsBox from "../ProjectCommentsBox"

export type Props = {
  comments: Comment[]
  project: Project
}

const View = ({ comments, project }: Props) => {
  const pluralComments = project.commentsCount === 1 ? "Comment" : "Comments"

  return (
    <Box
      style={{ marginTop: "16px" }}
      role="group"
      aria-label="Project comments"
    >
      <Box>
        <Typography tabIndex={0} variant="h5">
          {project.commentsCount} {pluralComments}
        </Typography>
      </Box>
      {comments.map((comment) => {
        return <ProjectCommentsBox comment={comment} />
      })}
    </Box>
  )
}

export default View
