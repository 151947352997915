import React, { useState } from "react"

import {
  SuccessDialogArea,
  SuccessDialogBox,
  CloseIconImg,
  MarketingDialogTitle,
  TextfieldBox,
  MarketingTitleBox,
  SuccessSubscriptionIconImg,
  MarketingDialogText,
} from "../InnovationAwardPage/styles"

import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"

import { Button } from "../../components"

import SuccessIcon from "../../images/innovation-awards/subscription-success-icon.svg"
import CloseIcon from "../../images/innovation-awards/close-icon.png"
import { useAppDispatch } from "../../redux/configureStore"
import { validateEmail } from "../../redux/profile"
import { useAuth0 } from "@auth0/auth0-react"
import {
  inviteCollaborator,
  validateInvitationEmail,
} from "../../redux/projects"
import { DialogBgBox } from "./styles"
import TextFieldLabelAbove from "../../components/TextFieldLabelAbove"
import ProjectDiscipline from "../../components/ProjectDiscipline"

export type Props = {
  onClose: () => void
  open: boolean
  projectId?: string
  onSubmit?: (email: string, discipline?: string, company?: string) => void
}

export type FormValues = {
  email: string
  discipline?: string
  company?: string
}

const InviteCollaboratorDialog = ({
  projectId,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [emailSent, setEmailSent] = useState(false)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required.")
      .email("You must enter a valid email.")
      .test(
        `test-email-address-already-exists`,
        "You've already sent an invitation to this email address",
        function (value) {
          return checkIfEmailAlreadyExists(value!)
        }
      )
      .test(
        `test-email-address`,
        "The email already belongs to a Spectacular account",
        function (value) {
          return checkIfEmailIsValid(value!)
        }
      ),
  })

  const checkIfEmailIsValid = async (email?: string) => {
    try {
      if (!email) return false

      const payloadEmail = email.toLowerCase()

      const { payload } = await dispatch(validateEmail({ email: payloadEmail }))

      return payload === "Invalid"
    } catch (error) {
      return false
    }
  }

  const checkIfEmailAlreadyExists = async (email?: string) => {
    try {
      if (!email) return false
      if (!projectId) return true

      const payloadEmail = email.toLowerCase()

      const { payload } = await dispatch(
        validateInvitationEmail({ auth, projectId, email: payloadEmail })
      )

      return payload === "Valid"
    } catch (error) {
      return false
    }
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    if (projectId) {
      try {
        const { type } = await dispatch(
          inviteCollaborator({
            auth,
            email: values.email,
            discipline: values.discipline,
            company: values.company,
            projectId,
          })
        )
        if (inviteCollaborator.fulfilled.type === type) {
          actions.setSubmitting(false)
          setEmailSent(true)
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      if (onSubmit) onSubmit(values.email, values.discipline, values.company)
      actions.setSubmitting(false)
      setEmailSent(true)
    }
  }

  const initialValues: FormValues = {
    email: "",
    discipline: "",
    company: "",
  }

  const handleClose = () => {
    setEmailSent(false)
    onClose()
  }

  return (
    <SuccessDialogArea
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      scroll={"body"}
    >
      <SuccessDialogBox>
        <DialogBgBox>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={handleClose} />
          {!emailSent ? (
            <>
              <MarketingTitleBox style={{ margin: 0 }}>
                <MarketingDialogTitle
                  style={{
                    textAlign: "left",
                    fontFamily: "Public-Sans-Medium",
                  }}
                >
                  Let's invite your collaborator to join your project on
                  Spectacular
                </MarketingDialogTitle>
              </MarketingTitleBox>
              <Formik
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions)
                }}
                initialValues={initialValues}
                validationSchema={ValidationSchema}
              >
                {(formik) => (
                  <Form style={{ width: "100%" }}>
                    <TextfieldBox style={{ padding: 0 }}>
                      <ProjectDiscipline
                        onChange={(value) =>
                          formik.setFieldValue(`discipline`, value)
                        }
                      />
                    </TextfieldBox>

                    <TextfieldBox style={{ padding: 0, marginTop: "16px" }}>
                      <TextFieldLabelAbove
                        fullWidth
                        name="company"
                        placeholder="Type in company name"
                        displayTitle="Company name"
                        style={{ height: "48px" }}
                      />
                    </TextfieldBox>

                    <TextfieldBox style={{ padding: 0, marginTop: "16px" }}>
                      <TextFieldLabelAbove
                        fullWidth
                        name="email"
                        asterisk={true}
                        placeholder="john.doe@example.com"
                        displayTitle="Email"
                        style={{ height: "48px" }}
                      />
                    </TextfieldBox>

                    <TextfieldBox style={{ marginTop: "35px", padding: 0 }}>
                      <Button
                        type={"submit"}
                        children={"Send email invitation"}
                        color={"secondary"}
                        size={"medium"}
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.dirty
                        }
                        style={{
                          width: "100%",
                          height: "56px",
                          fontSize: "16px",
                        }}
                      />
                    </TextfieldBox>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <SuccessSubscriptionIconImg src={SuccessIcon} alt={"success"} />
              <MarketingDialogTitle style={{ marginTop: "35px" }}>
                Your invitation has been sent
              </MarketingDialogTitle>
              <MarketingDialogText style={{ marginTop: "12px" }}>
                You will be notified when your Collaborator has accepted your
                invitation.
              </MarketingDialogText>
            </>
          )}
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default InviteCollaboratorDialog
