import styled from "styled-components"
import { Dialog, Typography, Box } from "@material-ui/core"
import theme, { colors } from "../../theme"

export const RequestDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 0;
`

export const RequestDialogTitle = styled(Typography)`
  font-size: 20px;
  color: ${colors.dark};
  border-bottom: 0.5px solid #dbdbdb;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    padding: 0 20px;
  }
`

export const RequestDialogBox = styled(Box)`
  padding: 24px;
  width: 562px;
  overflow-x: hidden;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: auto;
    padding: 24px 20px 20px 20px;
  }
`

export const RequestDialogText = styled(Typography)`
  font-size: 16px;
  color: ${colors.dark};

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`
