import geometric1 from "../images/profile-placeholders/Placeholder_1@2x.png"
import geometric2 from "../images/profile-placeholders/Placeholder_2@2x.png"
import geometric3 from "../images/profile-placeholders/Placeholder_3@2x.png"
import geometric4 from "../images/profile-placeholders/Placeholder_4@2x.png"
import geometric5 from "../images/profile-placeholders/Placeholder_5@2x.png"
import geometric6 from "../images/profile-placeholders/Placeholder_6@2x.png"
import geometric7 from "../images/profile-placeholders/Placeholder_7@2x.png"
import geometric8 from "../images/profile-placeholders/Placeholder_8@2x.png"
import geometric9 from "../images/profile-placeholders/Placeholder_9@2x.png"
import geometric10 from "../images/profile-placeholders/Placeholder_10@2x.png"

export const profilePlaceHolders = [
  geometric1,
  geometric2,
  geometric3,
  geometric4,
  geometric5,
  geometric6,
  geometric7,
  geometric8,
  geometric9,
  geometric10,
]
