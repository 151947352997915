import styled from "styled-components"

import theme from "../../theme"

export const NotificationBox = styled.div`
  height: 54px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-flow: row;
  color: #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    height: auto;
  }
`

export const AvatarBox = styled.div`
  cursor: pointer;
  width: 52px;

  ${theme.breakpoints.down("xs")} {
    width: 39px;
  }
`

export const DescriptionBox = styled.div`
  padding: 0 16px 0 22px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    font-size: 12px;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;

  ${theme.breakpoints.down("xs")} {
    flex-flow: wrap;
    width: 67px;
  }
`

export const AvatarIcon = styled.img`
  float: right;
  margin-top: -14px;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: -10px;

  ${theme.breakpoints.down("xs")} {
    width: 14px;
    height: 14px;
    margin-top: -12px;
    margin-right: -6px;
  }
`

export const NotificationAvatar = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;

  ${theme.breakpoints.down("xs")} {
    width: 32px;
    height: 32px;
  }
`

export const NotificationProjectImage = styled.img`
  width: 59px;
  height: 44px;

  ${theme.breakpoints.down("xs")} {
    width: 43px;
    height: 32px;
  }
`
export const NotificationProjectImagePlaceholder = styled.div`
  width: 59px;
  height: 44px;
  background: #f7f7f7;

  ${theme.breakpoints.down("xs")} {
    width: 43px;
    height: 32px;
  }
`

export const ProjectBox = styled.div`
  width: 59px;
  cursor: pointer;

  ${theme.breakpoints.down("xs")} {
    width: 43px;
  }
`
