import React from "react"
import _ from "lodash"
import { Project } from "../../redux/projects"
import View from "./View"

type Props = {
  project: Project
}

const ProjectComments = ({ project }: Props) => {
  const orderedComments = _.sortBy(project?.comments || [], [
    (comment) => new Date(comment.createdAt!),
  ]).reverse()

  return <View comments={orderedComments} project={project} />
}

export default ProjectComments
