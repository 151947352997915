import React, { useState } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { Image } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Post,
  PostMention,
  createPost,
  getPostsByProfile,
  uploadImages,
} from "../../redux/posts"
import { useHistory } from "react-router-dom"
import { updatePostsProfileState } from "../../redux/profile"
import NavView from "./NavView"

interface Props {
  origin: "profile" | "homepage" | "projects" | "update-nav" | "image-nav"
  closeNav?: () => void
}

const NewPostButton = ({ origin, closeNav }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [uploadedImages, setUploadedImages] = useState<any[]>([])
  const [currentFile, setCurrentFile] = useState<string>("")
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isPosting, setIsPosting] = useState<boolean>(false)

  const profile = useSelector((state: AppState) => state.profile.profile)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    if (closeNav) closeNav()
  }

  const handleUploadImages = async (images: Image[], post: Post) => {
    for (const key in images) {
      setIsUploading(true)

      const image = images[key as any]
      if (uploadedImages.findIndex((x) => x === image.file.name) === -1) {
        setUploadedImages([image.file.name, ...uploadedImages])
        setCurrentFile(`file ${parseInt(key) + 1} of ${images.length}`)

        const newImage = await dispatch(
          uploadImages({
            postId: post.id as string,
            fileType: image.file.type,
            order: image.order,
            width: image.dimensions?.width,
            height: image.dimensions?.height,
            auth,
          })
        )

        //Upload the image to the S3 bucket
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", images[key as any].file.type)

        await fetch(newImage.payload.signedUrl, {
          method: "PUT",
          headers: myHeaders,
          body: images[key as any].file,
        })
      }
    }
  }

  const handleSave = async (
    content: string,
    images: Image[],
    removedImages: string[],
    mentions?: PostMention[]
  ) => {
    try {
      setIsPosting(true)

      const { payload, type } = await dispatch(
        createPost({
          auth,
          content,
          mentions,
        })
      )
      if (createPost.fulfilled.type === type) {
        //UPLOAD IMAGES
        await handleUploadImages(images, payload.post)
        //FETCH POSTS
        if (profile) {
          await dispatch(
            getPostsByProfile({
              profileId: profile.connectionId!,
              email: profile.email,
            })
          )
        }

        await dispatch(updatePostsProfileState({ action: "create" }))

        if (["homepage", "update-nav", "image-nav"].includes(origin)) {
          history.push(`/posts/${payload.post.id}`)
        }

        setIsUploading(false)
        setIsPosting(false)
        setIsOpen(false)
        if (closeNav) closeNav()
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <NavView
      profile={profile!}
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onSubmit={handleSave}
      currentFile={currentFile}
      isPosting={isPosting}
      isUploading={isUploading}
      origin={origin}
    />
  )
}

export default NewPostButton
