import React from "react"
import { Container, Typography } from "@material-ui/core"

const View = () => {
  return (
    <Container>
      <Typography variant="h3">Admin Dashboard</Typography>
    </Container>
  )
}

export default View
