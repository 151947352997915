import styled from "styled-components"
import { Button } from "@material-ui/core"

import theme from "../../theme"

export const FollowButton = styled(Button)`
  height: 32px;
  width: 100px;
  border-radius: 1px;
  font-weight: 700;

  ${theme.breakpoints.down("xs")} {
    width: 78px;
    height: 28px;
    font-size: 12px;
  }
`

export const FollowButtonNoPadding = styled(Button)`
  height: 33px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 1px;

  ${theme.breakpoints.down("xs")} {
    width: 67px;
    height: 20px;
    font-size: 12px;
  }
`
