import React from "react"
import { useSelector } from "react-redux"
import { useHistory, match, Redirect } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { Profile } from "../../redux/profile"
import {
  updateJobPosition,
  deleteJobPosition,
  JobPosition,
} from "../../redux/jobPositions"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

interface MatchParams {
  id: string
}
export interface Props {
  match: match<MatchParams>
}

const JobPositionEditPage = (props: Props) => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profile = useSelector((state: any): Profile => state.profile.profile)
  const jobPosition: JobPosition | undefined = (
    profile?.jobPositions || []
  ).find((job) => job.id === props.match.params.id)

  const handleJobPositionUpdate = async (jobPosition: JobPosition) => {
    try {
      const { type } = await dispatch(updateJobPosition({ jobPosition, auth }))
      if (updateJobPosition.fulfilled.type === type) {
        history.push(`/experiences/edit`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteJobPosition = async (jobPosition?: JobPosition) => {
    if (jobPosition?.id) {
      try {
        const { type } = await dispatch(
          deleteJobPosition({ id: jobPosition.id, auth })
        )
        if (deleteJobPosition.fulfilled.type === type) {
          history.push(`/experiences/edit`)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  if (!jobPosition) {
    return <Redirect to="/experiences/edit" />
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Edit Job Position`}
        classTitle="EditJobPosition"
      />
      <Helmet>
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Edit Job Position - Spectacular`} />
        <meta name="title" content={`Edit Job Position - Spectacular`} />
        <title>Edit Job Position - Spectacular</title>
      </Helmet>
      <View
        jobPosition={jobPosition}
        onSubmit={handleJobPositionUpdate}
        onDelete={handleDeleteJobPosition}
      />
    </>
  )
}

export default JobPositionEditPage
