import React, { useState } from "react"
import {
  RequestDialog,
  RequestDialogTitle,
  RequestDialogBox,
  RequestDialogText,
  DialogCloseButton,
  DialogButtonBox,
} from "./Styles"
import { Box } from "@material-ui/core"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"
import { Button } from "../Button"

import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"

import "./fix-dialog.css"

export type Props = {
  onSubmit: () => void
  onClose: () => void
  open: boolean
}

const View = ({ open, onClose, onSubmit }: Props) => {
  const [hover, setHover] = useState(false)

  const { width } = useWindowDimensions()

  return (
    <RequestDialog
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      className={"ConnectionRequestDialog"}
    >
      <RequestDialogTitle>
        <Box>Invalid Link</Box>
        {width > theme.breakpoints.values.sm && (
          <DialogCloseButton
            src={hover ? CloseIconHover : CloseIcon}
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseLeave={() => {
              setHover(false)
            }}
            alt={"close"}
            onClick={onClose}
          />
        )}
      </RequestDialogTitle>
      <RequestDialogBox>
        <Box>
          <RequestDialogText>
            The job link you entered is invalid
          </RequestDialogText>
        </Box>
        <DialogButtonBox>
          <Button
            onClick={onClose}
            children={"Try Again"}
            color={"red"}
            size={"medium"}
            style={{
              width: width > theme.breakpoints.values.sm ? "95px" : "100%",
            }}
          />
          <Button
            onClick={onSubmit}
            children={"Ignore"}
            color={"whiteGreyBorder"}
            size={"medium"}
            style={{
              width: width > theme.breakpoints.values.sm ? "95px" : "100%",
              marginLeft: width > theme.breakpoints.values.sm ? "16px" : "12px",
            }}
          />
        </DialogButtonBox>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
