import React from "react"
import { Box, IconButton } from "@material-ui/core"

import {
  Button,
  Divider,
  ContentText,
  PopoverTitle,
  PopoverHeader,
  ContentPadding,
  PopoverContainer,
} from "./styles"

import { ReactComponent as CloseHoverIcon } from "../../../images/x-hover.svg"
import { ReactComponent as CloseDefaultIcon } from "../../../images/x-default.svg"

export type JobDialogType =
  | "delete"
  | "created"
  | "close"
  | "promote"
  | "promoted"
  | "unpromote"

interface Props {
  onClose: () => void
  onConfirm: () => void
  type: JobDialogType
}

const PopoverContent: React.FC<Props> = ({ type, onClose, onConfirm }) => {
  const [isHovering, setIsHovering] = React.useState(false)

  return (
    <PopoverContainer>
      <PopoverHeader>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <PopoverTitle>
            {type.replace(/^\w/, (c) => c.toUpperCase())} job
          </PopoverTitle>
        </Box>

        <Box>
          <IconButton
            onClick={onClose}
            aria-label="close"
            style={{ padding: "0" }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {isHovering ? <CloseHoverIcon /> : <CloseDefaultIcon />}
          </IconButton>
        </Box>
      </PopoverHeader>

      <Divider />

      <ContentPadding>
        <ContentText>
          Are you sure you want to {type} this job listing?
        </ContentText>

        <div>
          <Button
            color="primary"
            onClick={onConfirm}
            style={{ marginRight: "16px" }}
          >
            {type.replace(/^\w/, (c) => c.toUpperCase())}
          </Button>
          <Button
            color="default"
            variant="outlined"
            onClick={onClose}
            style={{ color: "#979797" }}
          >
            Cancel
          </Button>
        </div>
      </ContentPadding>
    </PopoverContainer>
  )
}

export default PopoverContent
