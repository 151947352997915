import React, { useState } from "react"
import {
  Typography,
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"
import ListItem from "./ListItem"
import { News } from "../../../redux/adminNews"
import { FormBox } from "./Styles"
import { Pagination } from "@material-ui/lab"

export type Props = {
  newsList: News[]
  status: string
  onChangeStatus: (value: string) => void
  onChangeSearch: (value: string) => void
}

const View = ({ newsList, status, onChangeStatus, onChangeSearch }: Props) => {
  const [page, setPage] = useState(1)

  const offset = 25 * (page - 1)
  const totalPages = Math.ceil(newsList.length / 25)
  const paginatedItems = newsList.slice(offset, 25 * page)

  const handlePagination = (_: any, page: number) => {
    setPage(page)
    document.getElementById("NewsList")?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Container>
      <Box marginBottom={2} id="NewsList">
        <Typography variant="h2">News List</Typography>
      </Box>
      <FormBox component={Paper}>
        <TextField
          label="Search"
          onChange={(e) => onChangeSearch(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="form-status">Status</InputLabel>
          <Select
            labelId="form-status"
            value={status}
            label="Status"
            onChange={(event: any) => onChangeStatus(event.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"published"}>Published</MenuItem>
            <MenuItem value={"not-published"}>Not Published</MenuItem>
          </Select>
        </FormControl>
      </FormBox>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={"20%"}>News Outlet</TableCell>
              <TableCell width={"10%"}>Date</TableCell>
              <TableCell width={"45%"}>Article Title</TableCell>
              <TableCell width={"15%"}>Status</TableCell>
              <TableCell width={"10%"}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((news) => {
              return <ListItem news={news} key={news.id} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box paddingTop={5} paddingBottom={8}>
        {totalPages > 1 && (
          <Pagination
            variant="outlined"
            page={page}
            onChange={handlePagination}
            count={totalPages}
            data-testid="load-more-news-pagination"
          />
        )}
      </Box>
    </Container>
  )
}

export default View
