import React, { MouseEvent, useState } from "react"
import { DotButton, Triangle, MenuItem, MenuBox } from "./Styles"
import { Popover } from "@material-ui/core"
import DotIcon from "../../images/post-icon-menu.svg"
import EditIcon from "../../images/post-icon-edit.svg"
import DeleteIcon from "../../images/post-icon-delete.svg"
import ReportIcon from "../../images/post-icon-flag.svg"
import { Profile } from "../../redux/profile"
import { Project } from "../../redux/projects"

export type Props = {
  onDelete: () => void
  onEdit: () => void
  onFlag: () => void
  source?: string
  authenticatedProfile?: Profile
  project: Project
}

const View = ({
  onDelete,
  onEdit,
  onFlag,
  source,
  authenticatedProfile,
  project,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const deleteProject = () => {
    onDelete()
    handleClose()
  }

  const editProject = () => {
    onEdit()
    handleClose()
  }

  const flagProject = () => {
    onFlag()
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DotButton onClick={handleClick}>
        <img src={DotIcon} alt={"dot menu"} />
      </DotButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            marginLeft: "15px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          {source === "profiles-page" &&
            authenticatedProfile?.connectionId ===
              project.profile?.connectionId && (
              <>
                <MenuItem onClick={editProject}>
                  <img src={EditIcon} alt={"Edit"} />
                  <span>Edit Project</span>
                </MenuItem>

                <MenuItem onClick={deleteProject}>
                  <img src={DeleteIcon} alt={"Delete"} />
                  <span>Delete Project</span>
                </MenuItem>
              </>
            )}
          {project.profile?.connectionId !==
            authenticatedProfile?.connectionId && (
            <MenuItem onClick={flagProject}>
              <img src={ReportIcon} alt={"Report"} />
              <span>Report Project</span>
            </MenuItem>
          )}
        </MenuBox>
      </Popover>
    </>
  )
}

export default View
