import React from "react"

import View from "./View"

type Props = {
  onSubmit: () => void
  onClose: () => void
  open: boolean
}

const UrlValidationDialog = ({ open, onClose, onSubmit }: Props) => {
  const handleClose = () => {
    onClose()
  }

  return <View onSubmit={onSubmit} onClose={handleClose} open={open} />
}

export default UrlValidationDialog
