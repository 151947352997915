import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState, useCallback } from "react"
import View from "./View"
import { Helmet } from "react-helmet"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  LoadingStatuses,
  getCompetition,
  selectCompetition,
  selectCompetitionStatus,
} from "../../redux/competition"
import LoadingPage from "../LoadingPage"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import camelize from "../../utils/camelize"

export interface InnovationAwardsCoAuthor {
  name: string
  profileLink: string
}

export interface InnovationAwardsForm {
  name: string
  category: string
  email: string
  projectLink: string
  projectTitle: string
  updated: boolean
  singleAuthor: boolean
  groupProject: boolean
  coAuthors?: InnovationAwardsCoAuthor[]
}

interface Params {
  slug: string
}

const StudioAwardPage = () => {
  const { slug } = useParams<Params>()

  const competition = useSelector(selectCompetition)
  const status = useSelector(selectCompetitionStatus)

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()

  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false)

  const goToForm = () => {
    document.getElementById("register")?.scrollIntoView({ behavior: "smooth" })
  }

  const goToUrl = (url: string) => history.push(url)

  const goToProjects = () => {
    goToUrl(`/projects?competition=${competition?.code}`)
  }

  const handleCloseRegisterDialog = () => {
    setIsRegisterDialogOpen(false)
  }

  const handleOpenRegisterDialog = async (type: string) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    setIsRegisterDialogOpen(true)
  }

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  const loadCompetition = useCallback(async () => {
    try {
      const { type } = await dispatch(getCompetition({ slug }))

      if (type === getCompetition.fulfilled.type) {
        // @ts-ignore
        window.prerenderReady = true
      }
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, slug])

  useEffect(() => {
    loadCompetition()
  }, [loadCompetition])

  if (status === LoadingStatuses.Loading || status === LoadingStatuses.Idle) {
    return <LoadingPage />
  }

  const screenTitle = competition?.title!

  return (
    <>
      <RouteChangeTracker
        screenTitle={screenTitle}
        classTitle={camelize(screenTitle)!}
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={competition?.logoUrl} />
        <meta property="og:title" content={competition?.title} />
        <meta property="og:description" content={competition?.description} />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta name="title" content={competition?.title} />
        <meta name="description" content={competition?.description} />
        <title>{competition?.title}</title>
      </Helmet>
      <View
        goToForm={goToForm}
        isAuthenticated={isAuthenticated}
        isRegisterDialogOpen={isRegisterDialogOpen}
        onCloseRegisterDialog={handleCloseRegisterDialog}
        onOpenRegisterDialog={handleOpenRegisterDialog}
        goToProjects={goToProjects}
        competition={competition}
      />
    </>
  )
}

export default StudioAwardPage
