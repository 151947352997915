import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import BigCross from "../../images/post-big-cross.png"
import SmallCross from "../../images/post-small-cross.png"
import BigCrossMobile from "../../images/post-big-cross-mobile.png"
import SmallCrossMobile from "../../images/post-small-cross-mobile.png"

export const PostDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 5px !important;
  width: 100% !important;
`

export const PostDialogTitle = styled(Box)`
  font-size: 20px;
  color: #2d2d2d;
  border-bottom: 0.5px solid #dbdbdb;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down("xs")} {
    min-height: auto;
    font-size: 16px;
    padding: 18px 20px;
    padding-top: calc(18px + env(safe-area-inset-top));
  }
`

export const PostDialogBox = styled(Box)`
  width: 516px;
  box-sizing: border-box;

  && .MuiDialog-paperWidthMd {
    max-width: 516px !important;
  }

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: auto;
  }
`

export const PostDialogText = styled(Typography)`
  font-size: 16px;
  color: #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 24px;
`

export const ProfileBox = styled(Box)`
  padding: 24px 24px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  && img {
    border-radius: 50%;
  }
`

export const ProfileTitle = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
`

export const ImagesBox = styled(Box)`
  padding: 8px 24px 0;
  box-sizing: border-box;
`

export const ImageThumbnailContainer = styled(Box)`
  width: 100%;
  height: 0;
  padding-bottom: 66.66%;
  position: relative;
  background: #c4c4c4;
  border-radius: 5px;
`

export const ImageThumbnail = styled.img<{ $isHeightBigger?: boolean }>`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: ${({ $isHeightBigger }) =>
    $isHeightBigger ? "contain" : "cover"};
  border-radius: 5px;
`

export const TextBox = styled(Box)<{ $isSmallPost: boolean }>`
  margin: 32px 0 40px;
  padding: 0 24px;
  box-sizing: border-box;

  && textarea {
    font-family: Public-Sans !important;
    font-size: 16px !important;
    line-height: 24px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    border: 0 !important;
    resize: none !important !important;

    &:focus {
      border: 0;
      outline: none;
    }
  }

  ${({ $isSmallPost }) =>
    $isSmallPost
      ? `
		background-color: #2d2d2d;
		background-image: url(${SmallCross}), url(${BigCross});
		background-position: 16px 16px, right 16px bottom 16px;
		background-repeat: no-repeat, no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 315px;

		&& textarea {
			color: #fff !important;
			font-size: 20px !important;
			background-color: #2d2d2d !important;
      padding: 0 50px;
		}

	`
      : ""}

  ${theme.breakpoints.down("xs")} {
    ${({ $isSmallPost }) =>
      $isSmallPost
        ? `
      background-image: url(${SmallCrossMobile}), url(${BigCrossMobile});
      background-position: 8px 8px, right 8px bottom 8px;

      && textarea {
        font-size: 18px !important;
        width: 100%;
        padding: 0 40px;
      }

    `
        : ""}
  }
`

export const StyledTextArea = styled.textarea`
  font-family: Public-Sans;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  resize: none !important;

  &:focus {
    border: 0;
    outline: none;
  }
`

export const CounterBox = styled(Box)`
  font-size: 12px;
  color: #979797;
  width: 100%;
  text-align: right;
  padding: 0 24px;
  box-sizing: border-box;
`

export const AddonsBox = styled(Box)`
  margin-top: 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  padding: 20px 24px;
`

export const Addon = styled(Typography)`
  font-size: 12px;
  color: #2d2d2d;
  font-family: Public-Sans-Bold;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  && span {
    color: #979797;
    font-family: Public-Sans;
    cursor: auto;
  }
`

export const ErrorBox = styled(Box)`
  padding: 24px 24px 0;
`

export const ErrorItem = styled(Box)`
  color: #ff0000;
  font-size: 14px;
`
