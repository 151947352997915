import React from "react"
import { Link } from "react-router-dom"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import BigCross from "../../images/post-big-cross.png"
import SmallCross from "../../images/post-small-cross.png"

import BigCrossMobile from "../../images/post-big-cross-mobile.png"
import SmallCrossMobile from "../../images/post-small-cross-mobile.png"

export const CardBox = styled(Box)`
  flex: 40%;
  background: #ffffff;
  border: 0.5px solid #ebeffc;
  border-radius: 5px;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
    border: 0;
    border-radius: 0;
  }
`

export const PostCardHeader = styled(Box)`
  padding: 16px 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;

  && img {
    border-radius: 50%;
    display: flex;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 8px 12px;
  }
`

export const HeaderBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const SpectacularAvatar = styled.img`
  width: 36px;
  height: 36px;
`

export const ProfileTitle = styled(Typography)`
  font-size: 16px;
  font-family: PUblic-Sans-Medium;

  &:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const PostCardContentSmallText = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  background-color: #2d2d2d;
  background-image: url(${SmallCross}), url(${BigCross});
  background-position: 8px 8px, right 8px bottom 8px;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  && a {
    color: #fff !important;
  }

  @media only screen and (max-width: 1190px) {
    background-image: url(${SmallCrossMobile}), url(${BigCrossMobile});
  }

  ${theme.breakpoints.down("sm")} {
    background-image: url(${SmallCross}), url(${BigCross});
  }

  ${theme.breakpoints.down("xs")} {
    background-image: url(${SmallCrossMobile}), url(${BigCrossMobile});
  }
`

export const PostCardSmallText = styled(Box)`
  color: #fff;
  font-family: Public-Sans-Bold;
  max-width: 312px;
  font-size: 20px;

  @media only screen and (max-width: 1190px) {
    padding: 0 50px;
    font-size: 18px;
  }

  @media only screen and (max-width: 1070px) {
    padding: 0 50px;
    font-size: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    max-width: 312px;
    font-size: 20px;
    padding: 0;
  }

  ${theme.breakpoints.down("xs")} {
    max-width: 100%;
    padding: 0 50px;
    font-size: 18px;
  }
`

export const PostCardContentBigText = styled(Box)<{
  $isOversized: boolean
  $isShowingMore: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  padding: 20px 20px 24px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  overflow: hidden;
  white-space: pre-line;
  position: relative;

  ${({ $isOversized, $isShowingMore }) =>
    $isOversized && !$isShowingMore
      ? `
    align-items: inherit;
    overflow: hidden;
  `
      : `
    align-items: center;
    overflow: initial;
  `}

  ${theme.breakpoints.down("xs")} {
    padding: 20px 12px 24px;
  }
`

export const PostCardBigText = styled(Box)<{
  $isOversized: boolean
  $isShowingMore: boolean
}>`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;

  ${({ $isOversized, $isShowingMore }) =>
    $isOversized && !$isShowingMore
      ? `
    overflow: hidden;
  `
      : null}

  && a {
    color: #3057e1;
    font-family: Public-Sans-Bold;
    text-decoration: none;
    cursor: pointer;
  }
`

export const PostCardBigTextBelow = styled(Box)<{
  $isOversized: boolean
  $isShowingMore: boolean
}>`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;

  ${({ $isOversized, $isShowingMore }) =>
    $isOversized && !$isShowingMore
      ? `
    max-height: 64px;
    overflow: hidden;
  `
      : null}
`

export const PostCardContentImage = styled(Box)`
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  position: relative;
  background-color: #f7f7f7;
`

export const CommentsBelowBox = styled(Box)`
  padding: 16px 24px 0;
  position: relative;

  ${theme.breakpoints.down("xs")} {
    padding: 0px 12px 20px;
  }
`

export const ImageThumbnail = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  height: auto;
  object-fit: contain;
  display: flex;
`

export const PostCardActions = styled(Box)`
  padding: 12px 0;

  ${theme.breakpoints.down("xs")} {
    padding: 12px;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 0 12px;
    display: none;
  }
`

export const DividerBorder = styled(Box)`
  width: 100%;
  border-bottom: 0.5px solid #d8d8d8;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const PostCardFooter = styled(Box)``

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const BlockingBox = styled(Box)<{ $isPosting?: boolean }>`
  pointer-events: ${({ $isPosting }) => ($isPosting ? "none" : "auto")};
  filter: ${({ $isPosting }) => ($isPosting ? "opacity(0.4)" : "none")};
`

export const CommentsBox = styled(Box)`
  ${theme.breakpoints.down("sm")} {
    border: 0;
  }
`

export const CommentsInnerBox = styled(Box)`
  padding: 12px 20px 24px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0 14px 12px;
  }
`

export const CommentsLabel = styled(Typography)`
  font-size: 14px;
  cursor: pointer;
  color: #979797;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 8px;
  }
`

export const CommentsAreaBox = styled(Box)`
  font: initial;
  width: 100%;
`

export const CommentsFlexBox = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`

export const CommentsLinksBox = styled(Box)`
  margin-top: 10px;
`

export const CommentsActions = styled(Box)`
  display: flex;
  gap: 16px;
`

export const CommentsListBox = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
`

export const CommentsNameFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 5px;
`

export const CommentTextBox = styled(Box)`
  margin-top: 8px;
`

export const NameBoxComment = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-family: Public-Sans-Bold;

  && a {
    color: #2d2d2d;
    text-decoration: none;
  }

  && a:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const ReplyBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  flex-flow: column;
`

export const ReplyLink = styled(Typography)<{
  $isActive?: boolean
  $replyRow?: number
  $currentRow?: number
}>`
  cursor: pointer;
  color: ${({ $isActive, $replyRow, $currentRow }) =>
    $isActive && $replyRow === $currentRow ? "#3057E1" : "#C4C4C4"};
  font-family: Public-Sans-Bold;
  font-size: 14px;

  &:hover {
    color: #3057e1;
  }
`

export const TimeBox = styled(Typography)`
  color: #717171;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 7px;

  && span {
    font-size: 16px;
  }
`

export const RoundImages = styled(Box)`
  && img {
    border-radius: 50%;
  }
`

export const SeeMoreButton = styled.span<{ $isBelow?: boolean }>`
  cursor: pointer;
  color: #3057e1;
  font-family: Public-Sans-Bold;
  font-size: 14px;
  position: absolute;
  right: 24px;
  bottom: ${({ $isBelow }) => ($isBelow ? "-15px" : "12px")};

  ${theme.breakpoints.down("xs")} {
    bottom: ${({ $isBelow }) => ($isBelow ? "6px" : "12px")};
  }
`

export const WhiteGradient = styled(Box)`
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 53%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
  box-sizing: border-box;
`
