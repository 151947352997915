import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import { fetchProfiles, selectAllProfiles } from "../../../redux/adminProfiles"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

const AdminProfilesPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profiles = useSelector(selectAllProfiles) || []

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchProfiles({ auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth])

  return <View profiles={profiles} />
}

export default AdminProfilesPage
