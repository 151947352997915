import React from "react"
import { useHistory } from "react-router-dom"

import CarouselView from "./CarouselView"
import GridView from "./GridView"
import { Competition } from "../../redux/competition"

interface Props {
  carousel?: boolean
  competition: Competition
}

const InnovationAwardsAnnouncement = ({ carousel, competition }: Props) => {
  const history = useHistory()

  const goToUrl = (url: string) => {
    history.push(url)
  }

  if (carousel)
    return <CarouselView goToUrl={goToUrl} competition={competition} />

  return <GridView competition={competition} />
}

export default InnovationAwardsAnnouncement
