import React from "react"
import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import theme from "../../theme"
import { Link } from "react-router-dom"

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #2d2d2d;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const StyledA = styled.a`
  text-decoration: none;
  &:active {
    user-select: none;
  }
  a {
    text-decoration: none;
  }
`

export const CardBox = styled.div`
  flex: 40%;
  background: #ffffff;
  border: 0.5px solid #ebeffc;
  border-radius: 5px;
  width: 40%;
  position: relative;
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
    border: 0;
    border-radius: 0;
  }
`

export const CardHeader = styled(Box)`
  height: 70px;
  padding: 0 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;

  && img {
    border-radius: 50%;
    display: flex;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 8px 12px;
  }
`

export const HeaderBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const HeaderTitle = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
`

export const CardContent = styled.div<{ $isSingleJob: boolean }>`
  display: flex;
  padding: 48px 20px 40px;
  gap: 16px;
  overflow: hidden;
  position: relative;
  height: -webkit-fill-available;

  ${({ $isSingleJob }) =>
    $isSingleJob
      ? `
    justify-content: center;
  `
      : ""}

  ${theme.breakpoints.down("xs")} {
    height: auto;
  }
`

export const JobCard = styled(Box)`
  min-width: 254px;
  border: 1px solid #ebeffc;
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 550px;

  ${theme.breakpoints.down("xs")} {
    max-height: 400px;
    min-width: 188px;
    padding: 16px;
  }
`

export const JobCardHeader = styled(Box)``

export const JobCardAvatar = styled(Box)`
  && img {
    border-radius: 50%;
  }

  ${theme.breakpoints.down("xs")} {
  }
`

export const JobCardBody = styled(Box)`
  margin-top: 24px;
`

export const JobName = styled(Typography)`
  color: #2d2d2d;
  font-size: 16px;
  font-family: Public-Sans-Medium;

  &:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`

export const JobLocation = styled(Typography)`
  font-size: 14px;
  color: #717171;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`
export const JobTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 18px;
  font-family: Public-Sans-Medium;
  margin-top: 16px;

  &:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`

export const JobDescription = styled(Typography)`
  margin-top: 8px;
  font-size: 16px;
  color: #717171;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const JobHint = styled(Typography)`
  margin-top: auto;
  font-size: 14px;
  color: #717171;
  display: flex;
  gap: 2px;
  align-items: center;

  && span {
    font-size: 24px;
  }

  ${theme.breakpoints.down("xs")} {
    && span {
      font-size: 20px;
    }

    font-size: 12px;
  }
`

export const ArrowLeft = styled.div`
  position: absolute;
  left: 24px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #5a5a5b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;

  && img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`

export const ArrowRight = styled.div`
  position: absolute;
  right: 24px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #5a5a5b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;
`

export const WhiteGradient = styled(Box)`
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 1) 90%
  );
  position: absolute;
  right: 0;
  width: 15%;
  box-sizing: border-box;
  height: calc(100% - 140px);
  top: 70px;
`

export const WhiteGradientLeft = styled(Box)`
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 1) 90%
  );
  position: absolute;
  left: 0;
  width: 15%;
  box-sizing: border-box;
  height: calc(100% - 140px);
  top: 70px;
`

export const CardFooter = styled(Box)`
  margin-top: auto;
  border-top: 1px solid #c4c4c4;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  && span {
    color: #2d2d2d;
    font-family: Public-Sans-Bold;
  }

  && img {
    margin-left: 16px;
    vertical-align: bottom;
  }
`
