import React from "react"

import { Box } from "@material-ui/core"

import CareerFairLogo from "../../images/career-fair/career-fair-logo.svg"

import HeaderImageMobile from "../../images/career-fair/spotlight-header-mobile.png"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
  HeaderDescription,
  HeaderFlexBox,
  HeaderBoxMobile,
} from "./styles"

const Header = () => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <HeaderFlexBox>
            <Box>
              <Box>
                <StyledLogo src={CareerFairLogo} alt={"Logo"} />
              </Box>
              <HeaderDescription>
                Elevate your firm’s brand and attract top talent with
                Spectacular’s Firm Spotlight program, providing exclusive access
                to our homepage, social media channels, and email list.
                <br />
                <br />
                Don’t miss out on this exceptional opportunity to stand out from
                the competition and achieve your firm’s goals!
              </HeaderDescription>
            </Box>
          </HeaderFlexBox>
        </HeaderContainer>
      </HeaderInnerBox>
      <HeaderBoxMobile>
        <img src={HeaderImageMobile} alt="Spotlight" />
      </HeaderBoxMobile>
    </HeaderBox>
  )
}

export default Header
