import React from "react"
import { makeStyles } from "@material-ui/core"
import copyToClipboard from "copy-to-clipboard"

import env from "../../environment"
import { CopyButton, CustomInput } from "./styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    maxHeight: "40px",
    alignItems: "center",
    background: "#F7F7F7",
    borderRadius: "1px 0px 0px 1px",
    borderTop: "0.5px solid #d8d8d8",
    borderLeft: "0.5px solid #d8d8d8",
    borderBottom: "0.5px solid #d8d8d8",
  },
}))

export const ReferraulURLField: React.FC<{ referralCode: string }> = ({
  referralCode,
}) => {
  const classes = useStyles()
  const [copied, setCopied] = React.useState(false)

  const referralURL = `${env.registerUrl}?referralCode=${referralCode}`

  const handleCopy = () => {
    copyToClipboard(referralURL)

    setCopied(true)

    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className={classes.root}>
      <CustomInput
        value={referralURL}
        placeholder="Referral code URL"
        inputProps={{ "aria-label": "referral code url" }}
      />
      <CopyButton onClick={handleCopy}>{copied ? "Copied" : "Copy"}</CopyButton>
    </div>
  )
}
