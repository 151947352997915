import React, { useRef, useState } from "react"
import {
  CardBox,
  CardHeader,
  CardContent,
  HeaderBox,
  HeaderTitle,
  ProfileCard,
  ProfileCardHeader,
  ProfileCardBanner,
  ProfileCardAvatar,
  ProfileCardBody,
  ProfileName,
  ProfileDescription,
  ProfileCardButtonsBox,
  LinkBox,
  WhiteGradient,
  ArrowRight,
  ArrowLeft,
  WhiteGradientLeft,
  CardFooter,
} from "./styles"
import { Profile } from "../../redux/profile"
import Avatar from "../../components/Avatar"
import SimpleAddress from "../../components/SimpleAddress"
import FollowButtonList from "../../components/FollowButtonList"
import ConnectButton from "../../components/ConnectButton"

import ArrowRightIcon from "../../images/new-homepage/arrow-right.svg"
import SuggestionsArrow from "../../images/new-homepage/suggestions-arrow.svg"
import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import { Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  profiles: Profile[]
  authenticatedProfile?: Profile
  isFiltered?: boolean
}

const View = ({ profiles, authenticatedProfile, isFiltered }: Props) => {
  const { width } = useWindowDimensions()
  const history = useHistory()

  const isMobile = width < theme.breakpoints.values.sm

  const [showRightGradient, setShowRightGradient] = useState(true)
  const [showLeftGradient, setShowLeftGradient] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const scroll = (scrollOffset: number, orientation: string) => {
    let scrollAmount = 0
    const sliderSmooth = setInterval(function () {
      if (ref.current) {
        if (orientation === "right") {
          ref.current.scrollLeft += 10
        } else {
          ref.current.scrollLeft -= 10
        }

        scrollAmount += 10
        if (scrollAmount >= scrollOffset) {
          window.clearInterval(sliderSmooth)
        }
      }
    }, 5)
  }

  const scrollRight = () => {
    scroll(500, "right")
    setShowLeftGradient(true)
    setShowRightGradient(false)
  }

  const scrollLeft = () => {
    scroll(500, "left")
    setShowLeftGradient(false)
    setShowRightGradient(true)
  }

  const goToEditProfile = () => {
    history.push("/profile/edit")
  }

  return (
    <CardBox>
      {!isFiltered && (
        <CardHeader>
          <HeaderBox>
            <HeaderTitle>Networking Suggestions</HeaderTitle>
          </HeaderBox>
        </CardHeader>
      )}
      <CardContent ref={ref} $isSingleProfile={profiles.length === 1}>
        {profiles.map((profile) => {
          let jobTitle = profile?.jobTitle
          if (profile?.jobTitle && profile.jobTitle.includes("Other: ")) {
            jobTitle = profile.jobTitle.replace("Other: ", "")
          }

          let location = SimpleAddress({
            profileType: profile!.type,
            location: profile!.location,
          })

          const profileName =
            profile.type === "group"
              ? profile.groupName
              : `${profile.firstName} ${profile.lastName}`

          return (
            <ProfileCard>
              <ProfileCardHeader>
                <ProfileCardBanner>
                  {profile.projects && profile.projects[0] && (
                    <>
                      <img
                        src={profile.projects[0].smallImageUrl}
                        alt="Banner"
                      />
                    </>
                  )}
                </ProfileCardBanner>
                <ProfileCardAvatar>
                  <LinkBox to={`/sp/${profile.username}`}>
                    <Avatar
                      profile={profile}
                      width={isMobile ? 74 : 100}
                      height={isMobile ? 74 : 100}
                    />
                  </LinkBox>
                </ProfileCardAvatar>
              </ProfileCardHeader>
              <ProfileCardBody>
                <ProfileName>
                  <LinkBox to={`/sp/${profile.username}`}>
                    {profileName}
                    {profile.isAmbassador && (
                      <img
                        src={AmbassadorIcon}
                        alt="Student Ambassador"
                        className="StudentAmbassadorIcon"
                      />
                    )}
                  </LinkBox>
                </ProfileName>
                <ProfileDescription>{jobTitle}</ProfileDescription>
                <ProfileDescription>
                  <span>{location}</span>
                </ProfileDescription>
              </ProfileCardBody>
              <ProfileCardButtonsBox>
                {authenticatedProfile?.connectionId === profile.connectionId ? (
                  <Button
                    onClick={goToEditProfile}
                    children={"Edit profile"}
                    color={"primary"}
                  />
                ) : (
                  <>
                    <FollowButtonList profile={profile} />
                    {profile.type !== "group" && (
                      <ConnectButton
                        profile={profile}
                        action={"connect"}
                        isSuggestion={true}
                      />
                    )}
                  </>
                )}
              </ProfileCardButtonsBox>
            </ProfileCard>
          )
        })}
      </CardContent>
      {showRightGradient && profiles.length > 1 && (
        <>
          <ArrowRight onClick={scrollRight}>
            <img src={ArrowRightIcon} alt="Arrow Right" />
          </ArrowRight>
          <WhiteGradient />
        </>
      )}
      {showLeftGradient && profiles.length > 1 && (
        <>
          <ArrowLeft onClick={scrollLeft}>
            <img src={ArrowRightIcon} alt="Arrow Right" />
          </ArrowLeft>
          <WhiteGradientLeft />
        </>
      )}
      {!isFiltered && (
        <CardFooter>
          <LinkBox to={`/network/suggestions`}>
            <span>See more suggestions</span>
            <img src={SuggestionsArrow} alt="See more" />
          </LinkBox>
        </CardFooter>
      )}
    </CardBox>
  )
}

export default View
