import React, { ChangeEvent } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  MenuItem,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core"

import { NewMainContainer } from "./../HomePage/styles"
import { MainSearchBar } from "../../components/Styles/MainSearchBar"
import { MainSelect } from "../../components/Styles/MainSelect"
import { MainPagination } from "../../components/Styles/MainPagination"
import { Profile } from "../../redux/profile"
import { DarkUnderLineLink, Divider } from "../../components/Styles/Global"
import SearchIcon from "@material-ui/icons/Search"
import ProfileListingCard from "../../cards/ProfileListingCard"

export type Props = {
  profiles: Profile[]
  filterBy: string
  location?: string
  perPage: number
  page: number
  profilesCount: number
  onlyFollowing: boolean
  handleChangeOnlyFollowing: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeFilterBy: (event: ChangeEvent<{ value: unknown }>) => void
  handleChangePagination: (event: ChangeEvent<unknown>, value: number) => void
  onTermChanged: (value: string) => void
  onSearch: () => void
}

const View = ({
  profiles,
  location,
  filterBy,
  perPage,
  page,
  profilesCount,
  onlyFollowing,
  onSearch,
  onTermChanged,
  handleChangeFilterBy,
  handleChangePagination,
  handleChangeOnlyFollowing,
}: Props) => {
  const paginationCount = Math.ceil(profilesCount / perPage)
  const handleCancelSearch = () => {
    onTermChanged("")
    onSearch()
  }

  const handleSearch = () => {
    onSearch()
  }

  return (
    <NewMainContainer>
      {!location ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <MainSearchBar
                placeholder="Search profiles"
                onChange={(text) => onTermChanged(text)}
                onRequestSearch={handleSearch}
                onCancelSearch={handleCancelSearch}
                searchIcon={<SearchIcon data-testid="search-icon" />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MainSelect
                disableUnderline
                value={filterBy}
                onChange={handleChangeFilterBy}
              >
                <MenuItem value="createdAt">
                  <span>By recently created</span>
                </MenuItem>
                <MenuItem value="recentlyUpdated">
                  <span>By recently updated</span>
                </MenuItem>
              </MainSelect>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box paddingBottom={1}>
          <Box paddingBottom={2}>
            <DarkUnderLineLink
              component={RouterLink}
              to={`/profiles`}
              data-testid="see-all-profiles-top"
            >
              See all profiles
            </DarkUnderLineLink>
          </Box>
          {location && (
            <Typography variant="h5">Location: {location}</Typography>
          )}
        </Box>
      )}

      <Box paddingTop={2} paddingBottom="4px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={onlyFollowing}
                  onChange={handleChangeOnlyFollowing}
                  name="toggleFollowingProfiles"
                  color="secondary"
                />
              }
              label="Show following profiles only"
            />
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={6}>
        {profiles.map((profile, index) => (
          <React.Fragment key={profile.username!}>
            <ProfileListingCard profile={profile} />
            {profiles.length - 1 !== index && (
              <>
                <Divider />
              </>
            )}
          </React.Fragment>
        ))}
      </Box>

      {paginationCount > 0 && (
        <Box paddingTop={10} paddingBottom={10}>
          <MainPagination
            data-testid="load-more-projects-pagination"
            count={paginationCount}
            variant="outlined"
            page={page}
            onChange={handleChangePagination}
          />
        </Box>
      )}

      {location && (
        <Box marginTop={-5} paddingBottom={10}>
          <DarkUnderLineLink
            component={RouterLink}
            to={`/profiles`}
            data-testid="see-all-profiles-bottom"
          >
            See all profiles
          </DarkUnderLineLink>
        </Box>
      )}
    </NewMainContainer>
  )
}

export default View
