import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { flagPost } from "../../redux/posts"
import { useAuth0 } from "@auth0/auth0-react"
import "./fix-dialog.css"
import { flagProject } from "../../redux/projects"

type Props = {
  isOpen: boolean
  isHomepage?: boolean
  handleClose: () => void
  id: string
  origin: "post" | "project"
}

const FlagContentDialog = ({
  isOpen,
  isHomepage,
  handleClose,
  id,
  origin,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState("")
  const [otherDescription, setOtherDescription] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [wasSubmitted, setWasSubmitted] = useState(false)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const handleUpdateSelectedValue = (value: string) => {
    setSelectedValue(value)
  }

  const handleUpdateOtherDescription = (value: string) => {
    setOtherDescription(value)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const { type } =
        origin === "post"
          ? await dispatch(
              flagPost({
                auth,
                postId: id,
                reason: selectedValue,
                otherDescription,
              })
            )
          : await dispatch(
              flagProject({
                auth,
                projectId: id,
                reason: selectedValue,
                otherDescription,
              })
            )

      if (flagPost.fulfilled.type || flagProject.fulfilled.type === type) {
        setIsSubmitting(false)
        setWasSubmitted(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const onClose = async () => {
    handleClose()
  }

  return (
    <View
      isOpen={isOpen}
      origin={origin}
      handleClose={onClose}
      selectedValue={selectedValue}
      handleUpdateSelectedValue={handleUpdateSelectedValue}
      handleUpdateOtherDescription={handleUpdateOtherDescription}
      otherDescription={otherDescription}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      wasSubmitted={wasSubmitted}
    />
  )
}

export default FlagContentDialog
