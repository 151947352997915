export const SEOTags = [
  "architect forums",
  "architecture forums",
  "design forum architects",
  "young architect forum",
  "architecture & design forum",
  "architect students forum",
  "architectural design projects",
  "architectural project",
  "landscape architecture projects",
  "landscape architect projects",
  "architect design projects",
  "new architecture projects",
  "architectural student",
  "architecture students",
  "architecture websites for students",
  "architecture student portfolio",
  "architecture student competitions",
  "architect student internship",
  "student architecture projects",
  "architecture student materials",
  "architectural student jobs",
  "architecture student portfolio examples",
  "architecture student portfolio sample",
  "sites for architecture students",
  "architecture student tools",
  "architecture student website",
  "architecture student work",
  "architecture student resume examples",
  "architecture student blog",
  "architecture mentors",
  "architecture mentorship",
  "architecture mentorship program",
  "architecture mentoring program",
  "architect mentoring program",
  "architecture networking",
  "architecture network",
  "architecture networking events",
  "architect networking events",
  "architecture online portfolio",
  "architecture portfolio examples",
  "website for architecture portfolio",
  "online architecture portfolio",
  "online portfolio for architects",
  "online portfolio for architecture",
  "digital architecture portfolio",
  "architecture digital portfolio",
  "best architecture portfolio websites",
  "create architecture portfolio online",
  "professional architecture portfolio",
  "architecture professional portfolio",
  "architectural professional portfolio",
  "architecture portfolio pdf examples",
  "examples of architecture portfolios for undergraduates",
  "professional architecture portfolio examples",
  "what is an architecture portfolio",
  "architecture portfolio professional work",
  "architecture portfolio",
  "architecture portfolio samples",
  "architecture portfolio format",
  "student architecture portfolio",
  "undergraduate architecture portfolio",
  "architecture portfolio for internship",
  "architecture portfolio website",
  "architecture portfolio for job",
  "professional art portfolio examples",
  "architecture student website portfolio",
  "architecture student portfolios",
  "architecture website portfolio",
  "architecture student portfolio website",
  "architecture portfolio website examples",
  "architecture website",
  "website for architecture",
  "top architecture website",
  "professional network for architects",
  "professional organizations for architects",
  "architect professional organizations",
  "architecture professional organizations",
  "architectural network",
  "architectural professional",
  "social network for architects",
  "architect social media",
  "architecture social media",
  "social network architecture",
]

export const SEOTagsJobs = [
  "student architecture internships",
  "architecture jobs for students",
  "architect student jobs",
  "part time jobs for architecture students",
  "architecture jobs for graduates",
  "architecture assistant jobs",
  "designer assistant",
  "interior designer assistant",
  "architecture career",
  "architecture career path",
  "architecture career pathway",
  "architecture internships",
  "architecture internship portfolio",
  "architecture internships jobs",
  "architecture internships for students",
  "internships for architecture students",
  "summer architecture internships",
  "architecture intern resume",
  "interior architecture internships",
  "paid architecture internships",
  "architecture internships chicago",
  "architecture internships in chicago",
  "architecture internships los angeles",
  "architecture design internships",
  "architecture jobs",
  "architecture jobs near me",
  "remote architecture jobs",
  "entry level architecture jobs",
  "architecture jobs entry level",
  "architecture jobs new york city",
  "entry level jobs architecture",
  "types of architecture jobs",
  "architecture jobs in los angeles",
  "architecture jobs los angeles",
  "network architecture jobs",
  "architecture jobs types",
  "interior architecture jobs",
  "design architecture jobs",
  "internship architecture jobs",
  "job architecture design",
  "part time architecture jobs",
  "architecture jobs chicago",
  "teaching architecture jobs",
  "project manager architecture jobs",
  "boston architecture jobs",
  "boston architecture firms",
  "architecture jobs boston ma",
  "top boston architecture firms",
  "boston architecture internships",
  "architecture jobs - chicago",
  "architecture firms chicago il",
  "interior architecture jobs chicago",
  "chicago architecture jobs",
  "architect jobs in chicago",
  "entry level architecture jobs chicago",
  "architecture jobs chicago entry level",
  "architecture jobs - los angeles",
  "architecture firms los angeles",
  "la architecture jobs",
  "entry level architecture jobs los angeles",
  "architecture jobs - new york",
  "architecture jobs new york",
  "architect jobs in new york city",
  "landscape architecture firms new york city",
  "junior architect jobs new york",
  "entry level architecture jobs new york",
  "architecture jobs in seattle",
  "architecture firms in seattle wa",
  "architecture firms in seattle",
  "seattle architect jobs",
  "architect jobs in seattle",
  "seattle architecture jobs",
  "junior architect",
  "junior architectural designer",
  "junior architect designer",
  "frank lloyd wright junior",
  "jr architect",
  "fresh graduate architect",
  "junior interior architect",
  "junior technical architect",
  "junior architect jobs",
  "junior architect hiring",
  "junior architect vacancy",
  "junior architect job description",
  "junior architect salary",
  "junior architect resume",
  "junior architect cover letter",
  "junior architect portfolio",
  "junior architect cv",
  "junior architect resume sample",
  "junior architect position",
  "hiring junior architect",
  "junior architect description",
  "junior architect requirements",
  "junior designer architecture",
  "junior architectural designer jobs",
  "architectural designer junior architect",
  "junior architect designer jobs",
]
