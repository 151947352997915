import { TextField, Box } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const CommentField = styled(TextField)`
  && {
    background-color: ${colors.gray};
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiInputBase-root {
    border-radius: 0;
    border: none;

    ${theme.breakpoints.down("xs")} {
      padding: 11px 16px;
    }
  }

  textarea {
    font-size: 14px;
    color: #717171;

    ::placeholder {
      opacity: 0.8;
    }
  }

  fieldset {
    display: none;
  }
`

export const Avatar = styled.img`
  height: 56px;
  width: 56px;
`

export const ErrorBox = styled(Box)`
  margin-left: 74px;
`

export const PostCommentBox = styled(Box)`
  display: flex;
  width: 100%;
  gap: 16px;
`
