import React, { useState } from "react"
import { Box, Popover } from "@material-ui/core"

import uploadIcon from "../../images/innovation-awards/upload-icon.svg"
import shareIcon from "../../images/innovation-awards/share-icon.svg"
import submitIcon from "../../images/innovation-awards/submit-icon.svg"

import facebook from "../../images/innovation-awards/inactive-facebook.svg"
import x from "../../images/innovation-awards/inactive-x.svg"
import linkedin from "../../images/innovation-awards/inactive-linkedin.svg"
import pinterest from "../../images/innovation-awards/inactive-pinterest.svg"

import {
  StepBox,
  StepTitle,
  StepText,
  StepImageBox,
  StyledUploadIcon,
  StyledShareIcon,
  StyledSubmitIcon,
  InactiveShareBox,
  Triangle,
  InactiveShareImages,
} from "./styles"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

interface Props {
  number: number
  text: string
  icon: "upload" | "share" | "submit"
  handleClick?: () => void
}

const getIcon = (icon: "upload" | "share" | "submit") => {
  if (icon === "upload") {
    return <StyledUploadIcon src={uploadIcon} alt={"upload"} />
  }

  if (icon === "share") {
    return <StyledShareIcon src={shareIcon} alt={"share"} />
  }

  if (icon === "submit") {
    return <StyledSubmitIcon src={submitIcon} alt={"submit"} />
  }
}

const Step = ({ number, text, icon, handleClick }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = async (event: any) => {
    setAnchorEl(event.currentTarget)
    setMouseX(event.clientX)
    setMouseY(event.clientY)
  }

  const isOpen = Boolean(anchorEl)

  const id = `anchor-${icon}`

  return (
    <>
      <StepBox
        number={number}
        onClick={(e) => (handleClick ? handleClick() : handleOpen(e))}
        style={isOpen ? { backgroundColor: "#3057e1" } : {}}
      >
        <Box>
          <StepTitle>{number}</StepTitle>
          <StepText>{text}</StepText>
        </Box>
        <StepImageBox aria-describedby={id}>{getIcon(icon)}</StepImageBox>
      </StepBox>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference={isMobile ? undefined : "anchorPosition"}
        anchorPosition={isMobile ? undefined : { top: mouseY, left: mouseX }}
        anchorOrigin={
          isMobile
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: "center",
              }
        }
        transformOrigin={
          isMobile
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : {
                vertical: "top",
                horizontal: "center",
              }
        }
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            boxSizing: "border-box",
          },
        }}
      >
        <Triangle />
        <InactiveShareBox>
          Promote Your Work to Win the
          <br />
          People’s Choice Award!
          <InactiveShareImages>
            <img src={facebook} alt={"facebook"} />
            <img src={x} alt={"x"} />
            <img src={linkedin} alt={"linkedin"} />
            <img src={pinterest} alt={"pinterest"} />
          </InactiveShareImages>
        </InactiveShareBox>
      </Popover>
    </>
  )
}

export default Step
