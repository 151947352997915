import React from "react"

import { useHistory } from "react-router-dom"

import Logo from "../../images/student-ambassador-logo-white.svg"
import SPLogo from "../../images/sp-logo-white.svg"

import { Button } from "../../components"

import {
  Container,
  ContentBox,
  Description,
  FlexBox,
  InnerBox,
  StyledLogo,
  Title,
} from "./styles/StudentAmbassador"

const StudentAmbassador = () => {
  const history = useHistory()

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <Container>
      <InnerBox>
        <FlexBox>
          <ContentBox>
            <img src={SPLogo} alt="Spectacular" />
            <Title variant="h2">Student Ambassador Program</Title>
            <Description>
              Calling all architecture students! If you're ready to build
              meaningful connections and shine a spotlight on the incredible
              work of your architecture community, this opportunity is for you.
            </Description>
            <Button
              onClick={() => goToUrl("/student-ambassador")}
              children={"Learn more"}
              color={"secondary"}
              size={"medium"}
              style={{
                width: "194px",
                height: "48px",
                fontSize: "16px",
              }}
            />
          </ContentBox>
          <StyledLogo src={Logo} alt={"Student Ambassador Program"} />
        </FlexBox>
      </InnerBox>
    </Container>
  )
}

export default StudentAmbassador
