import React from "react"

import { Box } from "@material-ui/core"

import { Button } from "../../components"

import {
  HeaderBox,
  HeaderContainer,
  HeaderButtonBox,
  HeaderInnerBox,
  StyledLogo,
} from "./styles"
import { Competition } from "../../redux/competition"

interface Props {
  goToForm: () => void
  competition?: Competition
}

const Header = ({ goToForm, competition }: Props) => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <Box>
            <StyledLogo src={competition?.logoUrl} alt={"Logo"} />
          </Box>
          <HeaderButtonBox>
            <Button
              onClick={goToForm}
              children={
                competition?.status === "closed"
                  ? "All Submissions"
                  : "Submit Your Work"
              }
              color={"secondary"}
              size={"medium"}
              style={{
                width: "194px",
                height: "48px",
                fontSize: "16px",
              }}
            />
          </HeaderButtonBox>
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
