import React from "react"
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
  ResponderProvided,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd"

import ConfirmDialog from "../ConfirmDialog"

import {
  IconOverlay,
  ListContainer,
  ListItemImage,
  IconContainer,
  ListItemOverlay,
  ListItemContainer,
  DragContainer,
  DragOverlay,
} from "./styles"
import { Image as ImageType } from "../../redux/projects"
import { ReactComponent as DragIcon } from "../../images/icon-drag.svg"
import DeleteIcon from "../../images/delete-icon.svg"

const grid = 4

interface Props {
  images: Array<ImageType>
  rows: Array<ImageType[]>
  onClick: (index: number) => void
  onChange: (rows: Array<ImageType[]>, removed?: string[]) => void
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
}

const DraggableImage: React.FC<{
  images: ImageType[]
  index: number
  rowIndex: number
  item: ImageType
  rows: Array<ImageType[]>
  onChange: (rows: Array<ImageType[]>, removed?: string[]) => void
  onClick: (index: number) => void
}> = ({ item, index, rowIndex, onChange, rows, onClick, images }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)

  return (
    <>
      <ConfirmDialog
        title="Delete image"
        message="Are you sure you want to delete this image?"
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          const newState = [...rows]
          const removedIds: string[] = []
          const removed = newState[rowIndex].splice(index, 1)

          for (const item of removed) {
            if (item.id) removedIds.push(item.id)
          }

          onChange(
            newState.filter((row) => row.length),
            removedIds
          )
          setIsDeleteDialogOpen(false)
        }}
      />

      <Draggable
        index={index}
        key={item.url ? item.url : item.thumbnailImageUrl}
        draggableId={(item.url ? item.url : item.thumbnailImageUrl) as string}
      >
        {(provided, snapshot) => (
          <ListItemContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            draggableStyle={provided.draggableProps.style}
            onClick={() => onClick(item.order)}
          >
            <ListItemOverlay isDragging={snapshot.isDragging}>
              <IconOverlay>
                <IconContainer>
                  <img
                    src={DeleteIcon}
                    alt={"delete"}
                    onClick={() => setIsDeleteDialogOpen(!isDeleteDialogOpen)}
                  />
                </IconContainer>
              </IconOverlay>
              {snapshot.isDragging ? (
                <DragOverlay>
                  <DragContainer>
                    <DragIcon />
                  </DragContainer>
                </DragOverlay>
              ) : null}
            </ListItemOverlay>
            <ListItemImage
              src={item.url ? item.url : item.thumbnailImageUrl}
              alt={`img-${item.order}`}
              $isHeightBigger={
                item.dimensions?.height! > item.dimensions?.width!
              }
            />
          </ListItemContainer>
        )}
      </Draggable>
    </>
  )
}

const Row: React.FC<{
  index: number
  images: ImageType[]
  rowImages: ImageType[]
  rows: Array<ImageType[]>
  provided: DroppableProvided
  snapshot?: DroppableStateSnapshot
  onClick: (index: number) => void
  onChange: (rows: Array<ImageType[]>, removed?: string[]) => void
}> = ({ images, provided, index, onChange, rows, onClick, rowImages }) => {
  return (
    <ListContainer
      grid={grid}
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {rowImages.map((item, idx) => (
        <DraggableImage
          rows={rows}
          item={item}
          index={idx}
          images={images}
          rowIndex={index}
          onClick={onClick}
          onChange={onChange}
        />
      ))}
      {provided.placeholder}
    </ListContainer>
  )
}

const View: React.FC<Props> = ({
  rows,
  images,
  onClick,
  onChange,
  onDragEnd,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {rows.map((rowImages, idx) => (
        <Droppable key={idx} droppableId={`${idx}`} direction="horizontal">
          {(provided, snapshot) => (
            <Row
              rows={rows}
              index={idx}
              images={images}
              rowImages={rowImages}
              provided={provided}
              snapshot={snapshot}
              onClick={onClick}
              onChange={onChange}
            />
          )}
        </Droppable>
      ))}
    </DragDropContext>
  )
}

export default View
