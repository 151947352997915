import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { RouteComponentProps, useHistory } from "react-router-dom"
import LoadingPage from "../LoadingPage"

import View from "./View"

import {
  getJobListingById,
  applyToJobListingById,
  sendEmailToJobListingById,
} from "../../redux/jobListings"
import { selectProfile } from "../../redux/profile"
import { useAppDispatch, AppState } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { SegmentContext } from "../../context"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import camelize from "../../utils/camelize"

interface Props extends RouteComponentProps<{ id: string }> {}

const JobListingDetailsPage = ({ match }: Props) => {
  const auth = useAuth0()
  const { isAuthenticated } = useAuth0()
  let id = match.params.id
  const history = useHistory()
  const { analytics: segment } = React.useContext(SegmentContext)
  const dispatch = useAppDispatch()
  const profile = useSelector(selectProfile)
  const job = useSelector((state: AppState) => state.jobListings.selectedJob)

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const getJobListingDetails = useCallback(async () => {
    const { type } = await dispatch(getJobListingById({ auth, id }))
    if (getJobListingById.fulfilled.type === type) {
      setTimeout(() => {
        // @ts-ignore
        window.prerenderReady = true
      }, 0)
    }
  }, [dispatch, auth, id])

  React.useEffect(() => {
    getJobListingDetails()
  }, [getJobListingDetails])

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  React.useEffect(() => {
    if (job && segment) {
      segment.track({
        type: "track",
        event: "Job Viewed",
        userId: isAuthenticated ? profile?.connectionId : undefined,
        properties: {
          jobId: job.id as string,
          jobTitle: job.title as string,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, job])

  const goToUrl = (url: string) => history.push(url)

  const handleApply = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      window.open(job!.jobUrl, "_blank")
      await dispatch(applyToJobListingById({ auth, id }))
    } catch (error) {
      console.log(`error`, error)
    }
  }

  const handleSendEmail = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      await dispatch(sendEmailToJobListingById({ auth, id }))
    } catch (error) {
      console.log(`error`, error)
    }

    window.location.href = `mailto:${job?.profile?.contactEmail}`
  }

  if (!job) return <LoadingPage />

  const isProfileOwner =
    authenticatedProfile?.username === job.profile?.username

  if (job.status === "closed" && !isProfileOwner) {
    if (profile?.connectionId !== job.profile?.id) goToUrl("/")
  }

  const spProfileLocation = `/profiles`

  const screenTitle = `Job Details - ${job.title}`

  return (
    <>
      <RouteChangeTracker
        screenTitle={screenTitle}
        classTitle={camelize(screenTitle)}
      />
      <Helmet>
        <meta name="keywords" content="Recruiting architects" />
        <meta name="title" content={`${job.title} - Spectacular`} />
        <meta name="description" content={job.description} />
        <meta property="og:title" content={`${job.title} - Spectacular`} />
        <meta property="og:type" content="job" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={job.profile?.smallImageUrl} />
        <meta property="og:description" content={job.description} />
        <meta property="og:site_name" content={"Spectacular Jobs"} />
        <meta property="og:locale" content="en_US" />
        <title>{`${job.title} - Spectacular`}</title>
      </Helmet>
      <View
        job={job}
        goToUrl={goToUrl}
        author={job.profile!}
        onApply={handleApply}
        onSendEmail={handleSendEmail}
        isProfileOwner={isProfileOwner}
        spProfileLocation={spProfileLocation}
      />
    </>
  )
}

export default JobListingDetailsPage
