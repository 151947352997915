import React, { useState } from "react"

import IconActive from "../../images/new-homepage/icons/like-active.svg"
import IconDefault from "../../images/new-homepage/icons/like-default.svg"
import IconHover from "../../images/new-homepage/icons/like-hover.svg"

import { Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { ActionButton, Counter, Icon } from "./styles"

export type Props = {
  dispatchComplete?: boolean
  project: Project
  onLike: () => Promise<void>
  onDislike: () => Promise<void>
}

const View = ({ dispatchComplete, project, onLike, onDislike }: Props) => {
  const { isAuthenticated } = useAuth0()
  const [like, setLike] = useState(project.liked)
  const [hover, setHover] = React.useState(false)

  const handleClick = () => {
    if (!disableLike) {
      if (like) {
        if (isAuthenticated) {
          setLike(false)
        }
        onDislike()
      } else {
        if (isAuthenticated) {
          setLike(true)
        }
        onLike()
      }
    }
  }

  let disableLike = false
  if (!project.canBeLiked || !dispatchComplete) disableLike = true

  const renderIcon = (liked?: boolean) => {
    if (liked) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  return (
    <ActionButton
      onClick={handleClick}
      data-testid="likes-button"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon src={renderIcon(like)} alt={"comment"} />
      <Counter $isActive={like || false}>
        {project.likes} {project.likes === 1 ? "like" : "likes"}
      </Counter>
    </ActionButton>
  )
}

export default View
