import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import { fetchNewsList, selectNewsList } from "../../../redux/adminNews"

const AdminNewsPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const newsList = useSelector(selectNewsList) || []

  const [status, setStatus] = useState("all")
  const [search, setSearch] = useState("")

  const getNewsList = useCallback(async () => {
    try {
      await dispatch(fetchNewsList({ auth, status, search }))
    } catch (error) {
      console.error(error)
    }
  }, [status, search, auth, dispatch])

  useEffect(() => {
    getNewsList()
  }, [getNewsList])

  return (
    <View
      newsList={newsList}
      status={status}
      onChangeStatus={setStatus}
      onChangeSearch={setSearch}
    />
  )
}

export default AdminNewsPage
