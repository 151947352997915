import React from "react"
import Wizard from "./Wizard"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import { Project } from "../../redux/projects"
import { Profile } from "../../redux/profile"

export type Props = {
  isUploading: boolean
  typeSelected: string
  currentFile: string
  error?: string
  profile: Profile
  project: Project
  onSubmit: Function
  inviteCollaboratorDialogIsOpen: boolean
  onCancel: () => void
  onTypeSelected: (typeSelected: string) => void
  onChangeInviteCollaboratorDialog: (value: boolean) => void
}
const View = ({
  onSubmit,
  onCancel,
  error,
  profile,
  project,
  isUploading,
  currentFile,
  typeSelected,
  onTypeSelected,
  inviteCollaboratorDialogIsOpen,
  onChangeInviteCollaboratorDialog,
}: Props) => {
  if (project.collaborators?.length === 0) {
    project.collaborators = [
      {
        discipline: "",
        company: "",
        name: "",
        profileId: "",
        id: "",
      },
    ]
  }

  return (
    <div>
      <Wizard
        initialValues={project}
        onSubmit={async (values: Project, _: any, published: boolean) =>
          onSubmit(values, published)
        }
        onCancel={onCancel}
        profile={profile}
        error={error}
      >
        <Step1 project={project} onCancel={onCancel} />
        <Step2
          project={project}
          profile={profile}
          onCancel={onCancel}
          currentFile={currentFile}
          isUploading={isUploading}
          typeSelected={typeSelected}
          onTypeSelected={onTypeSelected}
        />
        <Step3
          profile={profile}
          onCancel={onCancel}
          currentFile={currentFile}
          isUploading={isUploading}
          inviteCollaboratorDialogIsOpen={inviteCollaboratorDialogIsOpen}
          onChangeInviteCollaboratorDialog={onChangeInviteCollaboratorDialog}
        />
      </Wizard>
    </div>
  )
}

export default View
