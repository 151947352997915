import React from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import { Education } from "../../redux/educations"
import EducationForm from "../../components/EducationForm"

export type Props = {
  onSubmit: (values: Education) => void
}

const View = ({ onSubmit }: Props) => {
  return (
    <Box paddingLeft={2} paddingRight={2}>
      <Grid container justify="center">
        <Grid item lg={3} sm={6}>
          <Box marginTop={2}>
            <Typography tabIndex={0} variant="h5">
              Add education
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <EducationForm onSubmit={onSubmit} />
    </Box>
  )
}

export default View
