import React from "react"
import View from "./View"
import { News } from "../../redux/adminNews"
import { useAppDispatch } from "../../redux/configureStore"
import { viewNews } from "../../redux/news"
import { useAuth0 } from "@auth0/auth0-react"

export type Props = {
  news: News
}

const NewsCard = ({ news }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleNewsVisit = async () => {
    try {
      await dispatch(viewNews({ auth, id: news.id! }))
    } catch (e) {
      console.error(e)
    }
  }

  return <View news={news} onNewsVisit={handleNewsVisit} />
}

export default NewsCard
