import React, { useState } from "react"
import {
  Box,
  Link,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core"

const defaultOptions = [
  "Project Management",
  "Public Speaking",
  "Rhino",
  "AutoCAD",
  "Grasshopper",
  "SketchUp",
  "ArchiCAD",
  "Revit",
  "Vector",
  "3D-printed models",
  "Physical models",
  "3Ds Max",
  "After Effects",
  "Dynamo",
  "InDesign",
  "Maxwell Render",
  "Photoshop",
  "Python",
  "Unity",
  "V-Ray",
  "Augmented Reality",
  "Other",
]

export type Props = {
  skills: string[]
  displayError: boolean
  onChange: (jobTag: string) => void
}

const MAX_ALLOWED_SKILLS = 10

const View = ({ skills, onChange }: Props) => {
  const [showAll, setShowAll] = useState(false)
  const [options, setOptions] = useState(defaultOptions.slice(0, 4))
  const [showLink, setShowLink] = useState("Show all...")

  const onShowAll = () => {
    if (!showAll) {
      setOptions(defaultOptions)
      setShowLink("Show less...")
    } else {
      setOptions(defaultOptions.slice(0, 4))
      setShowLink("Show all...")
    }

    setShowAll(!showAll)
  }

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(target.value)

  const onBlur = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onChange(target.value)
  const isDisabled = skills.length === MAX_ALLOWED_SKILLS

  const isSkillChecked = (option: string) =>
    skills.find((skill) => skill === option) ? true : false

  return (
    <Box marginBottom={3}>
      <FormHelperText>Select no more than 10 options</FormHelperText>
      <FormControl component="fieldset">
        <Box marginBottom={1}>
          <FormGroup>
            {options.map((option) => {
              const otherSkill = skills.filter((e) => !options.includes(e))

              return option === "Other" ? (
                <FormControlLabel
                  label=""
                  control={
                    <TextField
                      name={option}
                      defaultValue={otherSkill}
                      label="Other"
                      data-testid="other"
                      onBlur={onBlur}
                    />
                  }
                />
              ) : (
                <FormControlLabel
                  label={option}
                  control={
                    <Checkbox
                      value={option}
                      onChange={handleChange}
                      checked={isSkillChecked(option)}
                      disabled={isDisabled && !isSkillChecked(option)}
                    />
                  }
                />
              )
            })}
          </FormGroup>
        </Box>

        <Link onClick={onShowAll}>{showLink}</Link>
      </FormControl>
    </Box>
  )
}

export default View
