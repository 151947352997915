type SelectOption = {
  value: string
  name: string
}

export const projectCategories: SelectOption[] = [
  { value: "aviation", name: "Aviation" },
  { value: "civic-government", name: "Civic/Government" },
  { value: "competition-entry", name: "Competition Entry" },
  { value: "educational", name: "Educational" },
  { value: "entertainment", name: "Entertainment" },
  { value: "healthcare", name: "Healthcare" },
  { value: "hospitality", name: "Hospitality" },
  { value: "multi-family-residential", name: "Multi-Family Housing" },
  { value: "mixed-use", name: "Mixed-Use" },
  { value: "museums", name: "Museum" },
  { value: "office-building", name: "Office Building" },
  { value: "professional-services", name: "Professional Services" },
  { value: "religious", name: "Religious" },
  { value: "retail", name: "Retail" },
  { value: "senior-living", name: "Senior Living" },
  { value: "single-family-residential", name: "Single Family Housing" },
  { value: "science-technology", name: "Science/Technology" },
  { value: "speculative", name: "Speculative" },
  { value: "sports", name: "Sports" },
  { value: "student-project", name: "Student Project" },
  { value: "urban-design", name: "Urban Design" },
  { value: "other", name: "Other" },
]

export default projectCategories
