import React from "react"
import { Box, makeStyles, Popover } from "@material-ui/core"
import Carousel from "react-material-ui-carousel"
import { CommentMention, Project } from "../../redux/projects"

import ProjectCardIconActions from "../../components/ProjectCardIconActions"
import DefaultImage from "../../images/default-image.png"
import ArrowRightDesktop from "../../images/new-homepage/carousel-arrow-right.svg"
import ArrowLeftDesktop from "../../images/new-homepage/carousel-arrow-left.svg"
import ArrowRightMobile from "../../images/new-homepage/carousel-arrow-right-mobile.svg"
import ArrowLeftMobile from "../../images/new-homepage/carousel-arrow-left-mobile.svg"
import MoreImages from "../../images/new-homepage/icons/more-images-icon.svg"

import {
  Avatar,
  CoverPojectAuthor,
  ImageThumbnailContainer,
  ImageThumbnail,
  ActionsBox,
  StyledLink,
  NameBox,
  LinkBox,
  LargeImageThumbnailContainer,
  CardFooter,
  CommentsBox,
  CommentsInnerBox,
  CommentsLabel,
  CardBox,
  BlockingBox,
  ProjectTitle,
  OneLiner,
  MoreImagesIcon,
  PopoverContainerCollab,
  FirmBadge,
  ProjectCardHeader,
} from "./styles"

import { normalizeProjectTitle } from "../../utils"
import * as RouteConstants from "../../constants/routes"
import CommentsList from "./CommentsList"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import ConfirmDialog from "../../components/ConfirmDialog"
import { useHistory } from "react-router-dom"
import CommentTextField from "../../components/CommentTextField"
import { Collaborator } from "./Collaborator"
import ProjectWinnerBadge from "../../components/ProjectWinnerBadge"

import "./styles.css"
import { DividerBorder } from "../PostCard/styles"
import ProjectDotMenu from "../../components/ProjectDotMenu"
import FlagContentDialog from "../../components/FlagContentDialog"
import { Profile } from "../../redux/profile"
import Zoomable from "react-instagram-zoom"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"
import { logEvent } from "../../utils/analytics"

export type Props = {
  project: Project
  goToProfile: () => string
  handlePostComment: (
    text: string,
    parentCommentId?: string,
    mentions?: CommentMention[]
  ) => void
  handleDeleteProject: () => void
  handleDialogOpen: (value: boolean) => void
  handleFlagDialogOpen: (value: boolean) => void
  source?: string
  large?: boolean
  isPostingComment?: boolean
  awards?: boolean
  hideComments?: boolean
  isDialogOpen: boolean
  isFlagDialogOpen: boolean
  showFirmBadge?: boolean
  isAuthenticated?: boolean
  isPublicProfile?: boolean
  authenticatedProfile?: Profile
  isFeaturedProject?: boolean
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}))

const View = ({
  project,
  goToProfile,
  source,
  large,
  isPostingComment,
  handlePostComment,
  awards,
  hideComments,
  isDialogOpen,
  isFlagDialogOpen,
  handleDialogOpen,
  handleFlagDialogOpen,
  handleDeleteProject,
  showFirmBadge,
  isAuthenticated,
  isPublicProfile,
  authenticatedProfile,
  isFeaturedProject,
}: Props) => {
  const [anchorElCollab, setAnchorElCollab] =
    React.useState<HTMLDivElement | null>(null)

  const [showNavigation, setShowNavigation] = React.useState(false)

  const [commentsQuantity, setCommentsQuantity] = React.useState(1)

  const { width } = useWindowDimensions()

  const isMobile = width < theme.breakpoints.values.sm

  const history = useHistory()

  const classes = useStyles()
  const openCollab = Boolean(anchorElCollab)
  const popoverIDCollab = openCollab ? "simple-popover-collab" : undefined

  const handleSowMoreComments = () => {
    if (project.comments && project.comments?.length > commentsQuantity)
      setCommentsQuantity(commentsQuantity + 3)
    else setCommentsQuantity(1)
  }

  let name = project.profile?.groupName
  if (project.profile?.type !== "group")
    name = project.profile?.firstName + " " + project.profile?.lastName

  const projectURL = `${RouteConstants.PROFILE_CONTAINER}/${
    project.username
  }/projects/${project.id!}/${normalizeProjectTitle(project.name)}`

  const handleLogEvent = async () => {
    await logEvent(
      isFeaturedProject ? "featured_project_click" : "project_click"
    )
  }

  const ImageThumbnailComponent = large
    ? LargeImageThumbnailContainer
    : ImageThumbnailContainer

  const getCommentLabel = () => {
    if (project.comments?.length === 0) return <>Be the first to comment</>

    if (
      project.comments &&
      project.comments?.length > 1 &&
      project.comments?.length <= commentsQuantity
    )
      return <>View less</>

    if (project.comments?.length === 1) return <>&nbsp;</>

    return <>View more comments</>
  }

  const postComment = (comment: string, mentions?: CommentMention[]) => {
    if (!isPostingComment && comment) {
      handlePostComment(comment, undefined, mentions)
    }
  }

  const projectImage = large ? project.imageUrl : project.imageUrl

  let navButtonStyled = {
    backgroundColor: "rgba(255,255,255, 0.9)",
    zIndex: 5,
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "-10px 20px 0",
  }

  let ArrowRight = ArrowRightDesktop
  let ArrowLeft = ArrowLeftDesktop

  if (width <= theme.breakpoints.values.md) {
    navButtonStyled = {
      backgroundColor: "rgba(255,255,255, 0.9)",
      zIndex: 5,
      width: "27px",
      height: "27px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 10px 0",
    }

    ArrowRight = ArrowRightMobile
    ArrowLeft = ArrowLeftMobile
  }

  const collaborators = project.collaborators?.filter(
    (collaborator) => collaborator.status !== "pending"
  )

  const handleEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isMobile) setAnchorElCollab(event.currentTarget as any)
  }

  const handleLeave = () => {
    if (!isMobile) setAnchorElCollab(null)
  }

  const RenderImageThumbnail = (
    key?: number,
    imageUrl?: string,
    fileWidth?: number,
    fileHeight?: number
  ) => {
    const heightBigger =
      fileHeight && fileWidth ? fileHeight > fileWidth : false

    return (
      <ImageThumbnailComponent key={key}>
        {project.rewards &&
          project.rewards.length > 0 &&
          project.rewards.map((reward) => {
            return (
              <div
                className={`projectCardWinnerBadge ${
                  source === "profiles-page" || large
                    ? "projectCardWinnerBadgeProfile"
                    : "projectCardWinnerBadgeProject"
                }`}
              >
                <ProjectWinnerBadge variant="vertical" reward={reward} />
              </div>
            )
          })}

        {showFirmBadge && (
          <FirmBadge>
            <img
              src={
                project.profile?.smallImageUrl ||
                getRandomPlaceholder(project.profile?.placeholderAvatar!)
              }
              alt={name}
            />
          </FirmBadge>
        )}

        {project.images && project.images?.length > 1 && !awards && !large && (
          <MoreImagesIcon src={MoreImages} alt={"More Images"} />
        )}
        {awards ? (
          <StyledLink to={"/projects?innovationAwards=true"}>
            <ImageThumbnail
              $isDiffSize={heightBigger}
              alt={`Online architect portfolio ${project.name!}`}
              src={imageUrl || DefaultImage}
              $isLarge={large}
            />
          </StyledLink>
        ) : (
          <StyledLink to={projectURL} onClick={handleLogEvent}>
            <ImageThumbnail
              $isDiffSize={heightBigger}
              alt={`Online architect portfolio ${project.name!}`}
              src={imageUrl || DefaultImage}
              $isLarge={large}
            />
          </StyledLink>
        )}
      </ImageThumbnailComponent>
    )
  }

  return (
    <CardBox
      $isLarge={large}
      $isAwards={awards}
      onMouseEnter={() =>
        setShowNavigation(() =>
          width <= theme.breakpoints.values.md ? false : true
        )
      }
      onMouseLeave={() => setShowNavigation(false)}
    >
      {isFlagDialogOpen && (
        <FlagContentDialog
          handleClose={() => handleFlagDialogOpen(false)}
          isOpen={isFlagDialogOpen}
          id={project.id!}
          origin={"project"}
          isHomepage={source === "homepage"}
        />
      )}

      <ConfirmDialog
        title="Delete project"
        message="Are you sure you want to delete this project?"
        isOpen={isDialogOpen}
        onConfirm={handleDeleteProject}
        onClose={() => handleDialogOpen(false)}
      />
      {!large && !awards && (
        <ProjectCardHeader>
          <CoverPojectAuthor>
            {collaborators && collaborators?.length > 0 ? (
              <>
                <NameBox
                  $isLarge={large}
                  onMouseEnter={handleEnter}
                  onMouseLeave={handleLeave}
                >
                  <StyledLink to={projectURL} onClick={handleLogEvent}>
                    <OneLiner>Multiowner</OneLiner>
                  </StyledLink>

                  <ProjectTitle>
                    <StyledLink to={projectURL} onClick={handleLogEvent}>
                      {project.name}
                    </StyledLink>
                  </ProjectTitle>

                  <Popover
                    className={classes.popover}
                    classes={{
                      paper: classes.popoverContent,
                    }}
                    open={openCollab}
                    id={popoverIDCollab}
                    anchorEl={anchorElCollab}
                    onClose={handleLeave}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    disableRestoreFocus
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0,
                        paddingLeft: "22px",
                        paddingBottom: "31px",
                        paddingRight: "22px",
                        boxSizing: "border-box",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <PopoverContainerCollab>
                      <Box
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          gap: "16px",
                        }}
                      >
                        <Collaborator
                          profile={project.profile}
                          project={project}
                        />
                        {collaborators.map((collaborator) => {
                          return (
                            <>
                              <Collaborator
                                collaborator={collaborator}
                                project={project}
                              />
                            </>
                          )
                        })}
                      </Box>
                    </PopoverContainerCollab>
                  </Popover>
                </NameBox>
              </>
            ) : (
              <>
                <Box>
                  <LinkBox to={goToProfile()}>
                    <Avatar
                      src={
                        project.profile?.smallImageUrl ||
                        getRandomPlaceholder(
                          project.profile?.placeholderAvatar!
                        )
                      }
                      alt={"avatar"}
                      $isLarge={large}
                    />
                  </LinkBox>
                </Box>

                <NameBox $isLarge={large}>
                  <StyledLink to={goToProfile()}>
                    <OneLiner style={{ lineHeight: "22px" }}>
                      {name}
                      {project.profile?.isAmbassador && (
                        <img
                          src={AmbassadorIcon}
                          alt="Student Ambassador"
                          className="StudentAmbassadorIcon"
                        />
                      )}
                    </OneLiner>
                  </StyledLink>
                  <ProjectTitle>
                    <StyledLink to={projectURL} onClick={handleLogEvent}>
                      {project.name}
                    </StyledLink>
                  </ProjectTitle>
                </NameBox>
              </>
            )}
          </CoverPojectAuthor>
          {isAuthenticated &&
            (source !== "homepage" ||
              (source === "homepage" && isPublicProfile)) && (
              <ProjectDotMenu
                project={project}
                onEdit={() => history.push(`/projects/${project.id!}/edit`)}
                onDelete={() => handleDialogOpen(!isDialogOpen)}
                onFlag={() => handleFlagDialogOpen(!isFlagDialogOpen)}
                source={source}
              />
            )}
        </ProjectCardHeader>
      )}
      {large || awards || hideComments ? (
        <Zoomable>
          {RenderImageThumbnail(
            0,
            projectImage,
            project.thumbnailWidth,
            project.thumbnailHeight
          )}
        </Zoomable>
      ) : (
        <Zoomable>
          {project.images && project.images.length > 1 ? (
            <>
              <Carousel
                navButtonsAlwaysVisible={true}
                animation={"slide"}
                interval={0}
                NextIcon={<img src={ArrowRight} alt={"Arrow"} />}
                PrevIcon={<img src={ArrowLeft} alt={"Arrow"} />}
                indicatorIconButtonProps={{
                  style: {
                    color: "#D8D8D8",
                    visibility: !showNavigation ? "hidden" : "visible",
                    opacity: !showNavigation ? "0" : "1",
                    transition: "all 0.5s",
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: "#3057E1",
                    visibility: !showNavigation ? "hidden" : "visible",
                    opacity: !showNavigation ? "0" : "1",
                    transition: "all 0.5s",
                  },
                }}
                indicatorContainerProps={{
                  style: {
                    marginTop: "-40px",
                    position: "absolute",
                    zIndex: 5,
                  },
                }}
                navButtonsProps={{
                  style: {
                    ...navButtonStyled,
                    visibility: !showNavigation ? "hidden" : "visible",
                    opacity: !showNavigation ? "0" : "1",
                    transition: "all 0.5s",
                  },
                }}
                autoPlay={false}
              >
                {project.images?.map((image, key) =>
                  RenderImageThumbnail(
                    key,
                    image.largeImageUrl,
                    image.width,
                    image.height
                  )
                )}
              </Carousel>
            </>
          ) : (
            <>
              {project.images && project.images.length === 1
                ? RenderImageThumbnail(
                    0,
                    project.images[0].largeImageUrl,
                    project.images[0].width,
                    project.images[0].height
                  )
                : RenderImageThumbnail(0, projectImage)}
            </>
          )}
        </Zoomable>
      )}
      <CardFooter $isLarge={large} $isAwards={awards}>
        {large && (
          <CoverPojectAuthor>
            {collaborators && collaborators?.length > 0 ? (
              <>
                <NameBox
                  $isLarge={large}
                  onMouseEnter={handleEnter}
                  onMouseLeave={handleLeave}
                >
                  <StyledLink to={projectURL} onClick={handleLogEvent}>
                    <OneLiner>Multiowner</OneLiner>
                  </StyledLink>
                  <ProjectTitle $isLarge={large}>
                    <StyledLink to={projectURL} onClick={handleLogEvent}>
                      {project.name}
                    </StyledLink>
                  </ProjectTitle>

                  <Popover
                    className={classes.popover}
                    classes={{
                      paper: classes.popoverContent,
                    }}
                    open={openCollab}
                    id={popoverIDCollab}
                    anchorEl={anchorElCollab}
                    onClose={handleLeave}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    disableRestoreFocus
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0,
                        paddingLeft: "22px",
                        paddingBottom: "31px",
                        paddingRight: "22px",
                        boxSizing: "border-box",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <PopoverContainerCollab>
                      <Box
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          gap: "16px",
                        }}
                      >
                        <Collaborator
                          profile={project.profile}
                          project={project}
                        />
                        {collaborators.map((collaborator) => {
                          return (
                            <>
                              <Collaborator
                                collaborator={collaborator}
                                project={project}
                              />
                            </>
                          )
                        })}
                      </Box>
                    </PopoverContainerCollab>
                  </Popover>
                </NameBox>
              </>
            ) : (
              <>
                <Box>
                  <LinkBox to={goToProfile()}>
                    <Avatar
                      src={
                        project.profile?.smallImageUrl ||
                        getRandomPlaceholder(
                          project.profile?.placeholderAvatar!
                        )
                      }
                      alt={"avatar"}
                      $isLarge={large}
                    />
                  </LinkBox>
                </Box>

                <NameBox $isLarge={large}>
                  <StyledLink to={goToProfile()}>
                    <OneLiner style={{ lineHeight: "22px" }}>
                      {name}
                      {project.profile?.isAmbassador && (
                        <img
                          src={AmbassadorIcon}
                          alt="Student Ambassador"
                          className="StudentAmbassadorIcon"
                        />
                      )}
                    </OneLiner>
                  </StyledLink>
                  <ProjectTitle $isLarge={large}>
                    <StyledLink to={projectURL} onClick={handleLogEvent}>
                      {project.name}
                    </StyledLink>
                  </ProjectTitle>
                </NameBox>
              </>
            )}
          </CoverPojectAuthor>
        )}
        <ActionsBox $isLarge={large}>
          <ProjectCardIconActions project={project} isLarge={large} />
        </ActionsBox>
      </CardFooter>
      {!large && !awards && !hideComments && (
        <>
          <BlockingBox $isPosting={isPostingComment}>
            <CommentsBox>
              <DividerBorder />
              <CommentsInnerBox>
                {(!isMobile ||
                  (project.comments && project.comments?.length > 1)) && (
                  <CommentsLabel onClick={handleSowMoreComments}>
                    {getCommentLabel()}
                  </CommentsLabel>
                )}
                <CommentTextField
                  handleSave={postComment}
                  oneLine={true}
                  focus={false}
                />
                {project.comments && project.comments?.length > 0 && (
                  <CommentsList
                    comments={project.comments}
                    quantity={commentsQuantity!}
                    handlePostComment={handlePostComment}
                    isPostingComment={isPostingComment}
                    authenticatedProfile={authenticatedProfile}
                    isHomepage={source === "homepage"}
                  />
                )}
              </CommentsInnerBox>
            </CommentsBox>
          </BlockingBox>
        </>
      )}
    </CardBox>
  )
}

export default View
