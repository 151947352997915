import React from "react"
import { StyledLinkTag } from "./styles"
import JsxParser from "react-jsx-parser"
import { Project } from "../../redux/projects"

export type Props = {
  project: Project
  small?: boolean
}

const View = ({ project }: Props) => {
  let display = `${project.description}`

  const tags = display.match(/#\w+/g)

  tags?.forEach((tag) => {
    const cleanTag = tag.replace("#", "")
    const regex = new RegExp(`(?<!\\w)${tag}(?!\\w)`, "g")
    display = display.replace(
      regex,
      `<StyledLinkTag id={'${cleanTag}'} to={'/?hashtag=${cleanTag}'}>${tag}</StyledLinkTag>`
    )
  })

  return (
    <JsxParser
      components={{
        StyledLinkTag,
      }}
      jsx={display}
    />
  )
}

export default View
