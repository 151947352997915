import React from "react"
import { Profile } from "../../redux/profile"

import Dialog from "./Dialog"
import { Image } from "../../redux/projects"
import { PostMention } from "../../redux/posts"
import "./fix-dialog.css"
import { useAuth0 } from "@auth0/auth0-react"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"

import PostUpdateIcon from "../../images/app-navbar/post-update.svg"
import PostImageIcon from "../../images/app-navbar/post-image.svg"
import { Description, Item, Title } from "../NewPostNavIcon/styles"
import { Box } from "@material-ui/core"

type Props = {
  profile: Profile
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (
    content: string,
    images: Image[],
    removedImages: string[],
    mentions?: PostMention[]
  ) => void
  currentFile: string
  isPosting: boolean
  isUploading: boolean
  origin: "profile" | "homepage" | "projects" | "update-nav" | "image-nav"
}

const NavView = ({
  profile,
  isOpen,
  onOpen,
  onClose,
  onSubmit,
  currentFile,
  isPosting,
  isUploading,
  origin,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()

  const handleOpenDialog = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    onOpen()
  }

  return (
    <>
      {origin === "image-nav" && (
        <Item onClick={handleOpenDialog}>
          <img src={PostImageIcon} alt="Post" />
          <Box>
            <Title>Images</Title>
            <Description>
              Get people’s attention with model photos, WIPs, sketches, &
              one-off renderings
            </Description>
          </Box>
        </Item>
      )}

      {origin === "update-nav" && (
        <Item onClick={handleOpenDialog}>
          <img src={PostUpdateIcon} alt="Post Update" />
          <Box>
            <Title>Update</Title>
            <Description>
              Connect with your network by sharing updates, announcements, or
              questions (just for text, no image uploads)
            </Description>
          </Box>
        </Item>
      )}
      {isOpen && (
        <Dialog
          profile={profile}
          open={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          currentFile={currentFile}
          isPosting={isPosting}
          isUploading={isUploading}
          isImage={origin === "image-nav"}
        />
      )}
    </>
  )
}

export default NavView
