import React from "react"
import ModalVideo from "react-modal-video"
import { Grid, Typography, Button, Box } from "@material-ui/core"

import { RegisterForm, SubmitHandler } from "./RegisterForm"

import "./modal-video.css"
import HeroImage1x from "../../images/home-building.jpg"
import PlayIcon from "../../images/play-button-register.svg"

import { HomePage, HeroGrid, HeroSection, HeroImage, LoginSpan } from "./Styles"
import { useAuth0 } from "@auth0/auth0-react"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"

const introVideoURL = `https://players.brightcove.net/5454530067001/xGo0KqA5Q_default/index.html?videoId=6252923902001`

const View: React.FC<{ onSubmit: SubmitHandler; showSSOText: boolean }> = ({
  onSubmit,
  showSSOText,
}) => {
  const { loginWithRedirect } = useAuth0()
  const [isOpen, setOpen] = React.useState(false)

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  return (
    <HomePage aria-label="main container">
      <>
        <ModalVideo
          autoplay
          isOpen={isOpen}
          channel="custom"
          url={introVideoURL}
          onClose={() => setOpen(false)}
        />
      </>

      <HeroSection>
        <HeroGrid container spacing={0} alignItems="flex-start">
          <Grid item xs={7} sm={7} style={{ alignSelf: "stretch" }}>
            <HeroImage
              style={{
                display: "flex",
                alignItems: "center",
                backgroundImage: "url(" + HeroImage1x + ")",
                minHeight: "100%",
              }}
            >
              <Box
                style={{
                  padding: "40px",
                  background: "white",
                  margin: "0px 80px 0px 80px",
                }}
              >
                <Box style={{ paddingBottom: "24px" }}>
                  <Typography variant="h1" style={{ marginTop: "-7px" }}>
                    The professional network for architecture and design.
                  </Typography>
                </Box>
                <Box style={{ paddingBottom: "29px" }}>
                  <Typography
                    variant="body1"
                    style={{ fontSize: "16px", color: "#2d2d2d" }}
                  >
                    Spectacular is built by architects for architects. Elevate
                    your profile. Find inspiration through our daily featured
                    projects from around the world. Make your next career move.
                    Hire your next great talent.
                  </Typography>
                </Box>

                <Box>
                  <Button
                    color="primary"
                    onClick={() => setOpen(true)}
                    style={{
                      height: "48px",
                      maxWidth: "140px",
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={PlayIcon}
                      alt="play-icon"
                      style={{ paddingRight: "7px" }}
                    />
                    Learn more
                  </Button>
                </Box>
              </Box>
            </HeroImage>
          </Grid>

          <Grid item xs={5} sm={5} style={{ padding: "80px 80px 160px" }}>
            <Box style={{ paddingBottom: "8px" }}>
              <Typography
                variant="h3"
                style={{
                  fontSize: "25px",
                  color: "#2d2d2d",
                  marginTop: "-7px",
                }}
              >
                Create your account
              </Typography>
            </Box>
            <Box style={{ paddingBottom: "32px" }}>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  color: "#2d2d2d",
                  marginBottom: "-3px",
                }}
              >
                Already have an account?{" "}
                <LoginSpan onClick={login}>Login</LoginSpan>
              </Typography>
            </Box>
            <RegisterForm onSubmit={onSubmit} showSSOText={showSSOText} />
          </Grid>
        </HeroGrid>
      </HeroSection>
    </HomePage>
  )
}

export default View
