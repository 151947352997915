import { Box, Dialog } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../../theme"

export const PostDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 5px !important;
  width: 100% !important;
`

export const PostDialogTitle = styled(Box)`
  font-size: 20px;
  color: #2d2d2d;
  border-bottom: 0.5px solid #dbdbdb;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    padding: 0 20px;
  }
`

export const PostDialogBox = styled(Box)`
  width: 800px;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 24px;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: auto;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 16px;
`

export const ImageBox = styled(Box)`
  && img {
    height: 250px;
    margin-top: 8px;
  }
`
