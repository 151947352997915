import React from "react"
import {
  StyledLink,
  StyledLinkBox,
  StyledLinkBoxSmall,
  StyledLinkSmall,
  StyledLinkTag,
} from "./styles"
import JsxParser from "react-jsx-parser"
import { Post } from "../../redux/posts"

export type Props = {
  post: Post
  small?: boolean
}

const View = ({ post, small }: Props) => {
  let display = `${post.content}`

  const tags = display.match(/#\w+/g)

  tags?.forEach((tag) => {
    const cleanTag = tag.replace("#", "")
    const regex = new RegExp(`(?<!\\w)${tag}(?!\\w)`, "g")
    display = display.replace(
      regex,
      small
        ? `<StyledLinkBoxSmall><StyledLinkSmall id={'${cleanTag}'} to={'/?hashtag=${cleanTag}'}>${tag}</StyledLinkSmall></StyledLinkBoxSmall>`
        : `<StyledLinkTag id={'${cleanTag}'} to={'/?hashtag=${cleanTag}'}>${tag}</StyledLinkTag>`
    )
  })

  // eslint-disable-next-line
  post.mentions?.map((mention) => {
    const output = small
      ? `<StyledLinkBoxSmall><StyledLinkSmall id={'${mention.id}'} to={'/sp/${mention.username}'}>@${mention.name}</StyledLinkSmall></StyledLinkBoxSmall>`
      : `<StyledLinkBox><StyledLink id={'${mention.id}'} to={'/sp/${mention.username}'}>@${mention.name}</StyledLink></StyledLinkBox>`

    display = display.replace(mention.fullString!, output)
  })

  return (
    <JsxParser
      components={{
        StyledLink,
        StyledLinkBox,
        StyledLinkBoxSmall,
        StyledLinkSmall,
        StyledLinkTag,
      }}
      jsx={display}
    />
  )
}

export default View
