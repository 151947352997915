import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"
import bgHeader from "../../images/career-fair/bg-header.png"
import ulIcon from "../../images/innovation-awards/ul-icon.png"

import headerImage from "../../images/career-fair/spotlight-header.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};
  margin-bottom: -64px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const HeaderBoxMobile = styled(Box)`
  display: none;

  ${theme.breakpoints.down("sm")} {
    display: flex;
    justify-content: center;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 48px;
    && img {
      width: 100%;
    }
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background: url(${headerImage}), url(${bgHeader});
  background-repeat: no-repeat;
  background-size: auto, cover;
  background-position: right, center;
  background-color: #2d2d2d;
  display: flex;
  justify-content: center;
  height: 606px;
  overflow: hidden;
  border-bottom: 2px solid #000000;

  @media only screen and (max-width: 1325px) {
    background-size: 50%, cover;
  }

  @media only screen and (max-width: 1120px) {
    background-size: 40%, cover;
  }

  ${theme.breakpoints.down("sm")} {
    height: auto;
    align-items: center;
    box-sizing: border-box;

    background: url(${bgHeader});
    background-size: cover;
    background-position: center;

    flex-flow: column;
    align-items: center;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 20px;
  }
`

export const HeaderDescription = styled(Typography)`
  padding-top: 48px;
  max-width: 530px;
  color: #fff;
  font-size: 20px;
  line-height: 31.2px;

  @media only screen and (max-width: 1325px) {
    max-width: 530px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 24px;
    padding-top: 32px;
    max-width: 100%;
    text-align: left;
  }
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 64px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    padding: 0;
  }
`

export const HeaderFlexBox = styled(Box)`
  display: flex;
`

export const LottieBox = styled(Box)`
  width: 410px;

  @media only screen and (max-width: 1075px) {
    margin-left: -90px;
  }

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const StyledLogo = styled.img`
  @media only screen and (max-width: 1075px) {
    max-width: 560px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    max-width: 560px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    max-width: 100%;
  }
`

export const RegistrationBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    background: #ffffff;
  }
`

export const RegistrationContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const RegistrationTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  padding-top: 68px;
  padding-bottom: 64px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding-top: 44px;
    padding-bottom: 42px;
    font-size: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 44px 20px 42px;
  }
`

export const RegistrationFlexBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    display: initial;
  }
`

export const RegistrationLeftBox = styled(Box)`
  box-sizing: border-box;
  border-left: 2px solid #2d2d2d;
  border-right: 2px solid #2d2d2d;
  width: 100%;
  padding: 32px 40px 64px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 64px;
  }

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0 20px 48px;
    margin-top: 32px;
  }
`

export const RegistrationRightBox = styled(Box)`
  box-sizing: border-box;
  border-right: 2px solid #2d2d2d;
  border-left: 2px solid #2d2d2d;
  width: 100%;
  padding: 40px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 64px;
  }

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 32px 20px 0;
    margin-top: 32px;
    padding-bottom: 48px;
    border-top: 2px solid #2d2d2d;
  }
`

export const RegistrationInnerTitle = styled(Typography)`
  font-size: 40px;

  && img {
    margin-right: 10px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;

    && img {
      height: 24px;
      margin-right: 5px;
    }
  }
`

export const RegistrationSubTitle = styled(Typography)`
  font-family: Public-Sans-Medium;
  font-size: 24px;

  && a {
    color: #2d2d2d;
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    text-align: center;
  }
`

export const RegistrationItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 17px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const RegistrationItemNoIcon = styled(Box)`
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const RegistrationDivider = styled(Box)`
  border-top: 1px solid #c4c4c4;
  width: 100%;
  margin: 48px 0;

  ${theme.breakpoints.down("sm")} {
    margin: 32px 0;
  }

  ${theme.breakpoints.down("xs")} {
    margin: 20px 0;
  }
`

export const RegistrationButtonBox = styled(Box)`
  margin-top: 32px;

  && a {
    text-decoration: none;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 24px;
    && button {
      width: 100% !important;
    }
  }
`
