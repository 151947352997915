import React, { useState, useEffect, useCallback } from "react"
import { Profile, fetchDetailedConnections } from "../../redux/profile"
import { useAppDispatch } from "../../redux/configureStore"
import { useSelector } from "react-redux"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  profile: Profile
  onClose: () => void
  open: boolean
  type: string
}

const ConnectionListDialog = ({ profile, open, type, onClose }: Props) => {
  const [currentTab, setCurrentTab] = useState(type)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const connectionsList = useSelector(
    (state: any) => state.profile.selectedProfileDetailedConnections
  )

  const handleTabChanged = (_event: React.ChangeEvent<{}>, tab: string) =>
    setCurrentTab(tab)

  const handleClose = () => {
    onClose()
  }

  const loadDetailedConnections = useCallback(
    async () => {
      try {
        await dispatch(
          fetchDetailedConnections({
            auth,
            username: profile?.username,
          })
        )
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [dispatch]
  )

  useEffect(() => {
    if (open) {
      setCurrentTab(type)
    }
  }, [type, open])

  useEffect(() => {
    loadDetailedConnections()
  }, [loadDetailedConnections])

  return (
    <View
      onClose={handleClose}
      currentTab={currentTab}
      onTabChanged={handleTabChanged}
      connectionsList={connectionsList}
      open={open}
      profile={profile}
    />
  )
}

export default ConnectionListDialog
