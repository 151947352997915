import React from "react"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { Profile } from "../../redux/profile"
import View from "./View"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

interface Props {
  profile: Profile
  authenticatedProfile?: Profile
  isPublicProfile: boolean
}

const ExperienceDetailPage = ({
  profile,
  isPublicProfile,
  authenticatedProfile,
}: Props) => {
  let history = useHistory()

  const handleNewExperience = () => {
    history.push("/experiences/edit")
  }
  const goToUrl = (url: string) => history.push(url)

  const orderedJobPositions = _.sortBy(profile.jobPositions || [], [
    "endDate",
    "startDate",
  ]).reverse()

  const orderedEducations = _.sortBy(profile.educations || [], [
    "endYear",
    "startYear",
  ]).reverse()

  const skills = (profile?.skills || []).filter((skill) => skill.trim())
  const credentials = (profile?.credentials || []).filter((credential) =>
    credential.trim()
  )

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Experience Details`}
        classTitle="ExperienceDetails"
      />
      <Helmet>
        <title>Experience Details - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={`Experience Details - Spectacular`}
        />
        <meta name="title" content={`Experience Details - Spectacular`} />
      </Helmet>
      <View
        awards={profile?.awards || []}
        groups={profile?.groups || []}
        skills={skills}
        credentials={credentials}
        isPublicProfile={isPublicProfile}
        educations={orderedEducations}
        jobPositions={orderedJobPositions}
        newExperienceClicked={handleNewExperience}
        goToUrl={goToUrl}
        authenticatedProfile={authenticatedProfile}
      />
    </>
  )
}

export default ExperienceDetailPage
