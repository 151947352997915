import React from "react"
import IconLiked from "../../images/icon-liked.svg"
import IconNominated from "../../images/innovation-awards/feature-icon.svg"
import { Profile } from "../../redux/profile"
import {
  ProfileCardIcon,
  IconLikeProfile,
  IconNominationProfile,
  DarkText,
} from "../Styles/Global"

type Props = {
  profile?: Profile
  source?: string
}

const View = ({ profile, source }: Props) => {
  return (
    <ProfileCardIcon
      display="flex"
      alignItems="center"
      marginTop={"5px"}
      style={
        source !== "card"
          ? {
              marginLeft: `20px`,
              paddingLeft: `20px`,
              borderLeft: `0.5px solid #D8D8D8`,
            }
          : {}
      }
    >
      <IconLikeProfile src={IconLiked} alt="" />
      <DarkText ml={1} marginRight={2}>
        {profile?.likes} {profile?.likes === 1 ? "Like" : "Likes"}
      </DarkText>

      <IconNominationProfile src={IconNominated} alt="" />
      <DarkText ml={1}>
        {profile?.nominations}{" "}
        {profile?.nominations === 1 ? "Nomination" : "Nominations"}
      </DarkText>
    </ProfileCardIcon>
  )
}

export default View
