import { Button } from "@material-ui/core"
import styled from "styled-components"

import { colors } from "../../theme"

export const BellContainer = styled.div<{
  hover?: any
  isNav: boolean
  width: number
  height: number
}>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ hover }) => (hover ? `cursor: pointer;` : "")}
  background-color: ${colors.white};
  white-space: nowrap;

  @media only screen and (max-width: 1230px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media only screen and (max-width: 1130px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  && img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
`

export const PopoverContainer = styled.div`
  padding: 1.2rem 1.8rem;
`

export const InviteFriendButton = styled(Button)`
  width: 100%;
  margin-top: 0.8rem;
`

export const NotificationPopoverContainer = styled.div`
  width: 500px;
  max-height: calc(80vh - 95px);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);
  background-color: #fff;
  border-radius: 1px;
  margin-top: 20px;
  overflow: overlay;

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
  }
`

export const NotificationPopoverInternalContainer = styled.div`
  padding: 1rem 20px 4px 1rem;
`

export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 15px;
    right: 19px;
    box-sizing: border-box;
    border: 0.6em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const RedDot = styled.div`
  position: absolute;
  background-color: #f44c49;
  min-width: 14px;
  margin-left: -16px;
  color: #fff;
  border-radius: 8px;
  font-size: 10px;
  height: 14px;
  margin-top: -37px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  border: 1px solid #ffffff;
  word-spacing: -1px;
`
