import React, { useState } from "react"
import { Box } from "@material-ui/core"

import {
  Counter,
  DialogButtonBox,
  DialogCloseButton,
  FlagDialog,
  FlagDialogBox,
  FlagDialogTitle,
  ItensTitle,
  StyledTextarea,
  SuccessMessage,
  TextBox,
} from "./Styles"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import Item from "./Item"
import { Button } from "../Button"

type Props = {
  isOpen: boolean
  origin: "project" | "post"
  handleClose: () => void
  handleSubmit: () => void
  selectedValue: string
  otherDescription: string
  handleUpdateSelectedValue: (value: string) => void
  handleUpdateOtherDescription: (value: string) => void
  wasSubmitted: boolean
  isSubmitting: boolean
}

const View = ({
  isOpen,
  origin,
  handleClose,
  handleSubmit,
  selectedValue,
  otherDescription,
  wasSubmitted,
  isSubmitting,
  handleUpdateSelectedValue,
  handleUpdateOtherDescription,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false)
  const [isNextSetep, setIsNextStep] = useState<boolean>(false)

  const handleClick = () => {
    if (selectedValue === "other" && !isNextSetep) setIsNextStep(true)
    else if (wasSubmitted) handleClose()
    else handleSubmit()
  }

  const MAX_CHAR_COUNT = 150

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)

    handleUpdateOtherDescription(e.target.value)
  }

  return (
    <FlagDialog
      maxWidth={"md"}
      open={isOpen}
      onClose={handleClose}
      className={"FlagDialog"}
    >
      <FlagDialogTitle>
        <Box>{isNextSetep ? "Tell us more" : "Report"}</Box>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={handleClose}
        />
      </FlagDialogTitle>
      {isNextSetep && !wasSubmitted ? (
        <FlagDialogBox>
          <TextBox>
            <StyledTextarea
              rows={5}
              onInput={(e) => characterCounter(e)}
              placeholder="Your message"
            />
          </TextBox>
          <Counter>
            {otherDescription.length}/{MAX_CHAR_COUNT}
          </Counter>
        </FlagDialogBox>
      ) : (
        <>
          {wasSubmitted ? (
            <FlagDialogBox>
              <SuccessMessage>
                Thank you for reporting this. We are reviewing this {origin},
                and may follow up with you by email if necessary.
              </SuccessMessage>
            </FlagDialogBox>
          ) : (
            <FlagDialogBox>
              <ItensTitle>Why are you reporting this {origin}?</ItensTitle>
              <Item
                value="spam"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
              <Item
                value="offensive"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
              <Item
                value="violent"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
              <Item
                value="copyright"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
              <Item
                value="obscene"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
              <Item
                value="other"
                selectedValue={selectedValue}
                handleUpdateSelectedValue={handleUpdateSelectedValue}
              />
            </FlagDialogBox>
          )}
        </>
      )}

      <DialogButtonBox>
        <Button
          onClick={handleClick}
          disabled={selectedValue === "" || isSubmitting}
          children={
            selectedValue === "other" && !isNextSetep
              ? "Next"
              : wasSubmitted
              ? "Done"
              : "Submit"
          }
          color={"secondary"}
          size={"medium"}
          style={{
            width: "100%",
          }}
        />
      </DialogButtonBox>
    </FlagDialog>
  )
}

export default View
