import React from "react"

import Step1 from "./Step1"
import Step2 from "./Step2"
import Wizard from "./Wizard"

import { JobListing } from "../../redux/jobListings"
import { Profile } from "../../redux/profile"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { JobFormType } from "../../enums/JobFormType"

export type Props = {
  profile: Profile
  jobListing?: JobListing
  onSubmit: Function
  onCancel: () => void
  type: JobFormType
}

const initialValues: JobListing = {
  title: "",
  description: "",
  descriptionRtf: "",
  workplaceType: "",
  employmentType: "",
  experienceLevel: "",
  location: "",
  jobUrl: "",
  summary: "",
  status: "open",
  tags: [],
}

const View = ({ onSubmit, onCancel, profile, jobListing, type }: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  let job = Object.assign(jobListing || initialValues, {})

  return (
    <Wizard
      jobListing={job}
      onSubmit={async (values: JobListing, published: boolean) =>
        onSubmit(values, published)
      }
      onCancel={onCancel}
      profile={profile}
      type={type}
      isMobile={isMobile}
    >
      <Step1 type={type} isMobile={isMobile} />
      <Step2 />
    </Wizard>
  )
}

export default View
