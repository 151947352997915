import React, { useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../../redux/configureStore"
import { fetchNetworkPage } from "../../../../redux/connections"
import View from "./View"

const SuggestionsTab = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const suggestions = useSelector((state: any) => state.connections.suggestions)
  const suggestionsCount = useSelector(
    (state: any) => state.connections.suggestionsCount
  )

  const requests = useSelector((state: any) => state.connections.requests)
  const requestsCount = useSelector(
    (state: any) => state.connections.requestsCount
  )

  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const loadRequests = useCallback(async () => {
    try {
      const { payload, type } = await dispatch(
        fetchNetworkPage({ auth, perPage: 8, page, type: "requests" })
      )

      if (fetchNetworkPage.fulfilled.type === type) {
        setIsLoading(false)
        if (payload.data.length >= payload.count || payload.data.length === 0)
          setHasMore(false)
        else setHasMore(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, page, auth])

  const loadConnections = useCallback(async () => {
    try {
      const { payload, type } = await dispatch(
        fetchNetworkPage({
          auth,
          perPage: 8,
          page,
          type: "suggestions",
        })
      )

      if (fetchNetworkPage.fulfilled.type === type) {
        setIsLoading(false)
        if (payload.data.length >= payload.count || payload.data.length === 0)
          setHasMore(false)
        else setHasMore(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, page, auth])

  const handleUpdateHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangePage = (value: number) => {
    setPage(value)
  }

  useEffect(() => {
    loadConnections()
    loadRequests()
  }, [loadRequests, loadConnections])

  return (
    <View
      suggestions={suggestions}
      suggestionsCount={suggestionsCount}
      requests={requests}
      requestsCount={requestsCount}
      hasMore={hasMore}
      onUpdateHasMore={handleUpdateHasMore}
      page={page}
      onUpdatePage={handleChangePage}
      isLoading={isLoading}
    />
  )
}

export default SuggestionsTab
