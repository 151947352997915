import React from "react"
import { Box } from "@material-ui/core"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import {
  PopUp,
  PopUpTitle,
  PopUpButton,
  PopUpHeader,
  PopUpCloseIcon,
} from "./styles"

interface Props {
  open: boolean
  onClose: () => void
  onDiscover: () => void
}

const View: React.FC<Props> = ({ open, onClose, onDiscover }) => {
  const [hover, setHover] = React.useState(false)

  return (
    <PopUp
      style={{
        opacity: open ? 1 : 0,
        visibility: open ? "visible" : "hidden",
      }}
    >
      <PopUpHeader>
        <PopUpTitle>Discover more projects</PopUpTitle>
        <PopUpCloseIcon
          alt={"close"}
          onClick={onClose}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          src={hover ? CloseIconHover : CloseIcon}
        />
      </PopUpHeader>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
        }}
      />
      <Box style={{ padding: "24px 24px" }}>
        <div
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontFamily: "Public-Sans",
            paddingBottom: "24px",
          }}
        >
          <div style={{ margin: "-4px 0px" }}>
            Curate your Spectacular experience further by liking, commenting,
            and nominating your favorite projects and posts. Make sure to
            connect and follow profiles you enjoy to ensure a unique and
            customized Spectacular feed!
          </div>
        </div>
        <PopUpButton color="primary" onClick={onDiscover}>
          Discover more projects here
        </PopUpButton>
      </Box>
    </PopUp>
  )
}

export default View
