import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import {
  Grid,
  Box,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { GroupProfileSignUp } from "../../redux/profile"
import TextField from "../TextField"
import { passwordHintMessage } from "../../constants/signup"
import { Link } from "react-router-dom"

export type Props = {
  values: GroupProfileSignUp
  onSubmit: (values: GroupProfileSignUp) => void
  error?: string | null
}

const FormSchema = Yup.object().shape({
  groupName: Yup.string().required("Required"),
  email: Yup.string().required("Required").email(),
  password: Yup.string()
    .required(passwordHintMessage)
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      passwordHintMessage
    ),
})

const View = ({ values, onSubmit, error }: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={FormSchema}
    >
      {(formik) => (
        <Form>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField name="groupName" label="Group Name" fullWidth />
              <TextField
                name="email"
                label="Email"
                fullWidth
                autoComplete="username"
              />
              <TextField
                name="password"
                label="Password"
                fullWidth
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box marginRight={1} marginTop={4}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  data-testid="signUpButton"
                  disabled={
                    !formik.dirty || formik.isSubmitting || !formik.isValid
                  }
                >
                  Create
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box marginRight={1} marginTop={4}>
                <Button component={Link} to={"/admin/profiles"} fullWidth>
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default View
