import React, { useState } from "react"
import { ActionsType } from "../DropDownMultiselect/View"
import {
  FilterDialog,
  FilterDialogBox,
  FilterDialogTitle,
  DialogOption,
  FilterDialogHeader,
  DialogCloseButton,
  FilterTitle,
  Divider,
  ClearFilters,
  ClearFiltersActive,
} from "./Styles"
import { Box, Button } from "@material-ui/core"

import Active from "../../images/dialog-checked.svg"
import Inactive from "../../images/dialog-not-checked.svg"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import "./fix-dialog.css"

export type Props = {
  onClose: () => void
  onSubmit: () => void
  handleChangeDefaultActions: (value: any) => void
  handleChangeStateActions: (value: any) => void
  open: boolean
  stateActions: any
  defaultActions: any
}

const View = ({
  open,
  onClose,
  onSubmit,
  stateActions,
  defaultActions,
  handleChangeDefaultActions,
  handleChangeStateActions,
}: Props) => {
  const [hover, setHover] = useState(false)

  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

  const handleClose = () => {
    onClose()
    handleChangeStateActions(JSON.parse(JSON.stringify(defaultActions)))
  }

  const setSelected = (type: string, action: ActionsType, key: number) => {
    const tempStateActions = { ...stateActions }
    const tempAction = { ...action }
    tempAction.active = !tempAction.active

    if (type === "workplace-type") {
      const workplaceType = [...stateActions.workplaceType]
      workplaceType[key] = tempAction
      tempStateActions.workplaceType = workplaceType
      handleChangeStateActions(tempStateActions)
    } else if (type === "employment-type") {
      const employmentType = [...stateActions.employmentType]
      employmentType[key] = tempAction
      tempStateActions.employmentType = employmentType
      handleChangeStateActions(tempStateActions)
    } else if (type === "experience-level") {
      const experienceLevel = [...stateActions.experienceLevel]
      experienceLevel[key] = tempAction
      tempStateActions.experienceLevel = experienceLevel
      handleChangeStateActions(tempStateActions)
    }
  }

  const clearFilters = () => {
    const tempStateActions = { ...stateActions }
    tempStateActions.workplaceType = tempStateActions.workplaceType.map(
      (action: ActionsType) => ({ ...action, active: false })
    )
    tempStateActions.experienceLevel = tempStateActions.experienceLevel.map(
      (action: ActionsType) => ({ ...action, active: false })
    )
    tempStateActions.employmentType = tempStateActions.employmentType.map(
      (action: ActionsType) => ({ ...action, active: false })
    )
    handleChangeStateActions(tempStateActions)
  }

  const handleApply = () => {
    onSubmit()
    handleChangeDefaultActions(JSON.parse(JSON.stringify(stateActions)))
  }

  return (
    <FilterDialog
      open={open}
      onClose={handleClose}
      fullScreen={true}
      className={"FilterJobDialog"}
    >
      <FilterDialogHeader>
        {stateActions.workplaceType.find(
          (value: ActionsType) => value.active
        ) ||
        stateActions.employmentType.find(
          (value: ActionsType) => value.active
        ) ||
        stateActions.experienceLevel.find(
          (value: ActionsType) => value.active
        ) ? (
          <ClearFiltersActive onClick={clearFilters}>
            Clear all
          </ClearFiltersActive>
        ) : (
          <ClearFilters>Clear all</ClearFilters>
        )}
        <FilterDialogTitle>Filter</FilterDialogTitle>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={handleClose}
        />
      </FilterDialogHeader>
      <FilterDialogBox>
        <FilterTitle>Workplace type</FilterTitle>
        <Box paddingRight={2.5} paddingLeft={2.5}>
          {stateActions.workplaceType.map(
            (action: ActionsType, key: number) => (
              <DialogOption
                onClick={() => setSelected("workplace-type", action, key)}
              >
                {action.display}
                <img src={action.active ? Active : Inactive} alt={"checked"} />
              </DialogOption>
            )
          )}
        </Box>
        <Divider />
        <FilterTitle>Employment type</FilterTitle>
        <Box paddingRight={2.5} paddingLeft={2.5}>
          {stateActions.employmentType.map(
            (action: ActionsType, key: number) => (
              <DialogOption
                onClick={() => setSelected("employment-type", action, key)}
              >
                {action.display}
                <img src={action.active ? Active : Inactive} alt={"checked"} />
              </DialogOption>
            )
          )}
        </Box>
        <Divider />
        <FilterTitle>Experience Level</FilterTitle>
        <Box paddingRight={2.5} paddingLeft={2.5}>
          {stateActions.experienceLevel.map(
            (action: ActionsType, key: number) => (
              <DialogOption
                onClick={() => setSelected("experience-level", action, key)}
              >
                {action.display}
                <img src={action.active ? Active : Inactive} alt={"checked"} />
              </DialogOption>
            )
          )}
        </Box>

        <Box
          paddingRight={2.5}
          paddingLeft={2.5}
          paddingTop={3}
          style={{ marginTop: "auto", paddingBottom: iOSSafari ? "65px" : "" }}
        >
          <Button
            style={{
              width: "100%",
              height: "48px",
              fontSize: "14px",
              fontWeight: 700,
            }}
            color="primary"
            size="small"
            onClick={handleApply}
          >
            Show results
          </Button>
        </Box>
      </FilterDialogBox>
    </FilterDialog>
  )
}

export default View
