import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import bgHeader from "../../images/student-ambassador/sa-header.jpg"
import bgBody from "../../images/student-ambassador/body-bg.png"
import Quotes from "../../images/new-homepage/quotes.png"

import ulIcon from "../../images/innovation-awards/ul-icon.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};
  margin-bottom: -64px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background: url(${bgHeader});
  background-size: cover;
  background-position: center;
  background-color: #000000;
  display: flex;
  justify-content: center;
  height: 415px;
  overflow: hidden;

  ${theme.breakpoints.down("sm")} {
    height: auto;
  }

  ${theme.breakpoints.down("xs")} {
    height: 300px;
    align-items: center;
    background-repeat: no-repeat;
    background-color: #000000;
  }
`

export const HeaderTitle = styled(Typography)`
  font-size: 70px;
  color: #fff;
  margin-top: 20px;

  ${theme.breakpoints.down("xs")} {
    font-size: 40px;
  }
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 100px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;

    && button {
      width: 90% !important;
    }
  }

  @media only screen and (max-width: 429px) {
    padding: 0 20px;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;
  text-align: center;
`

export const StyledLogo = styled.img`
  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const DescriptionBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border:box;
  background-image: url(${bgBody});
  background-color: #F5F5F5;
  background-repeat: no-repeat;
  background-size: cover 100%;    
  background-position: center;
  background-size: cover;
  padding-bottom: 120px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 64px;
  }
`

export const DescriptionContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;
  padding-right: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const DescriptionFlexBox = styled(Box)`
  display: flex;
  gap: 100px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    gap: 0;
  }
`

export const DescriptionLeftBox = styled(Box)`
  padding: 64px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 0;
  }
`

export const DescriptionTitle = styled(Typography)`
  font-size: 40px;
  line-height: 48px;

  ${theme.breakpoints.down("xs")} {
    font-size: 32px;
    line-height: 38px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const DescriptionFont = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;

  ${theme.breakpoints.down("sm")} {
    padding: 16px 20px 0;
  }
`

export const DescriptionButtonBox = styled(Box)`
  margin-top: 16px;

  ${theme.breakpoints.down("sm")} {
    && button {
      width: 100% !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const DescriptionIconBox = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 520px;

  ${theme.breakpoints.down("sm")} {
    min-width: auto;
  }
`

export const DescriptionQuotes = styled(Box)`
  background-color: #3f3e3e;
  background-image: url(${Quotes});
  background-repeat: no-repeat;
  background-position: 190px 64px;
  background-size: 166px;
  width: 520px;
  height: 240px;
  display: flex;
  box-sizing: border-box;
  padding: 0 32px;
  gap: 48px;

  ${theme.breakpoints.down("xs")} {
    flex-flow: column-reverse;
    gap: 16px;
    padding: 20px;
    width: 100%;
    height: 300px;
    justify-content: center;
    background-position: 190px 124px;
  }
`

export const QuotesLeftBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;

  ${theme.breakpoints.down("xs")} {
    justify-content: normal;
    height: auto;
  }
`

export const QuotesRightBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;

  && img {
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    ${theme.breakpoints.down("xs")} {
      width: 64px;
      height: 64px;
      justify-content: normal;
    }
  }

  ${theme.breakpoints.down("xs")} {
    height: auto;
  }
`

export const QuotesDescription = styled(Typography)`
  color: #fff;
  font-size: 14px;
  line-height: 20px;
`

export const QuotesName = styled(Typography)`
  color: #fff;
  font-size: 16px
  font-family: Public-Sans-Bold;
  margin-top: 16px;
  
  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    margin-top: 8px;
  }
`

export const QuotesHint = styled(Typography)`
  color: #fff;
  font-size: 12px;
`

export const FormSpacingBox = styled(Box)`
  padding-top: 27px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 20px;

    && button {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
`

export const OverviewBox = styled(Box)`
  background-color: #2d2d2d;
  width: 100%;
  max-width: 1285px;
  box-sizing: border-box;
  padding: 64px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 32px;
    padding: 64px 0;
  }
`

export const OverviewTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 40px;
  line-height: 29px;
`

export const OverviewItemList = styled(Box)`
  display: flex;
  gap: 40px;
  margin-top: 40px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    margin-top: 48px;
    padding: 0 88px;
  }
`

export const OverviewItem = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;
  width: 25%;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const OverviewItemIcon = styled(Box)`
  && img {
    height: 48px;
  }
`

export const OverviewItemTitle = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 16px;
  color: #fff;
`

export const OverviewItemText = styled(Typography)`
  font-size: 16px;
  color: #fff;
`

export const ResponsibilitiesBox = styled(Box)`
  width: 100%;
  max-width: 1285px;
  margin-top: 80px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 40px;
  }
`

export const ResponsibilitiesTitle = styled(Typography)`
  text-align: center;
  font-size: 40px;
  line-height: 29px;
`

export const ResponsibilitiesItemList = styled(Box)`
  display: flex;
  gap: 24px;
  margin-top: 48px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    padding: 0 20px;
  }
`

export const ResponsibilitiesItem = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  width: 100%;
  max-width: 33%;

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
  }
`

export const ResponsibilitiesItemImage = styled(Box)`
  && img {
    border-radius: 6px 6px 0 0;
    width: 100%;
  }
`

export const ResponsibilitiesItemContent = styled(Box)`
  padding: 8px 24px 24px;
`

export const ResponsibilitiesItemTitle = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 20px;
`

export const ResponsibilitiesItemText = styled(Typography)`
  font-size: 16px;
`

export const RoundImage = styled.img`
  position: absolute;
  top: -38px;
`

export const PerksBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border:box;
  background-color: #fff;
  position: relative;
  width: 100%;
`

export const PerksContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;
  padding-right: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const PerksTitle = styled(Typography)`
  text-align: center;
  font-size: 40px;
  text-align: center;
  padding-top: 120px;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    padding-top: 64px;
  }
`

export const Divider = styled(Box)`
  height: 1px;
  background-color: #b5b5b5;
  width: 100%;
  max-width: 1285px;
  margin: 48px 0;

  ${theme.breakpoints.down("sm")} {
    margin: 24px 0;
  }
`

export const PerksFlexBox = styled(Box)`
  display: flex;
  gap: 24px;
  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    gap: 0px;
  }
`

export const PerksLeftBox = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const PerksRightBox = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const PerksItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 17px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }
`

export const PerksButtonBox = styled(Box)`
  margin-top: 64px;
  width: 100%;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    margin-top: 32px;
    && button {
      width: 100%;
    }
  }
`

export const AmbassadorsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border:box;
  background-color: #000;
  position: relative;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 64px;
  
  ${theme.breakpoints.down("sm")} {
    margin-top: 48px;
  }
`

export const AmbassadorsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;
  padding-right: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const AmbassadorsTitle = styled(Typography)`
  text-align: center;
  font-size: 40px;
  text-align: center;
  padding: 80px 0;
  width: 100%;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 0;
  }
`

export const AmbassadorsList = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;

  @media only screen and (max-width: 1180px) {
    gap: 50px;
  }
`

export const AmbassadorItem = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 120px;

  && img {
    filter: grayscale(100%);
    border-radius: 50%;
    transition: 0.3s;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      filter: grayscale(0%);
    }
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      filter: grayscale(0%);
    }
  }
`

export const AmbassadorContent = styled(Box)`
  position: relative;
  width: 100%;
  text-align: center;
`

export const AmbassadorItemName = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Bold;
  color: #fff;
  position: absolute;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
`

export const AmbassadorItemSchool = styled(Typography)`
  font-size: 12px;
  color: #fff;
  margin-top: 28px;
`

export const PhotosBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`

export const PhotoBox = styled.div`
  flex: 0 1 calc(33.333%);
  min-width:33.333%;
  position: relative;
}
`
export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  &:hover {
    background: initial;
  }

  ${theme.breakpoints.down("sm")} {
    background: initial;
  }
`

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
`
