import React from "react"
import { Notification } from "../../redux/bellNotifications"

import View from "./View"

type Props = {
  notification: Notification
  onNotificationClick: (notificationId?: string) => void
  goToNotifications?: boolean
  hideButton?: boolean
}

const NotificationSourceProfile = ({
  notification,
  onNotificationClick,
  goToNotifications,
  hideButton,
}: Props) => {
  return (
    <View
      notification={notification}
      onNotificationClick={onNotificationClick}
      goToNotifications={goToNotifications}
      hideButton={hideButton}
    />
  )
}

export default NotificationSourceProfile
