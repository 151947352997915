import React, { MouseEvent, useState } from "react"
import { DropDownBox, MenuItem, MenuItemArea, MenuBox } from "./Styles"
import DDDown from "../../images/new-homepage/dd-down.svg"
import DDUp from "../../images/new-homepage/dd-up.svg"
import { Popover } from "@material-ui/core"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import Dialog from "./Dialog"
import { DropDownItem } from "../HomeSearchBar/project-search"

export type Props = {
  title: string
  values: DropDownItem[]
  selected?: string[]
  onSelect: (value: string) => void
}

const DropDownMultiselect = ({ title, values, selected, onSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { width } = useWindowDimensions()

  const handleClose = () => {
    setAnchorEl(null)
    setDialogOpen(false)
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setDialogOpen(true)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DropDownBox
        onClick={handleClick}
        style={open ? { color: "#C4C4C4" } : {}}
      >
        {title}
        <img src={open ? DDUp : DDDown} alt={"drop-down"} />
      </DropDownBox>
      {width <= theme.breakpoints.values.sm ? (
        <Dialog open={dialogOpen} onClose={handleClose} />
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              borderRadius: 0,
              background: "transparent",
              boxShadow: "none",
              overflow: "hidden",
              paddingLeft: "15px",
              paddingBottom: "15px",
              paddingRight: "15px",
              marginLeft: "-15px",
            },
          }}
        >
          <MenuBox>
            <MenuItemArea>
              {values.map((value) => {
                return (
                  <MenuItem
                    key={value.value}
                    onClick={() => onSelect(value.value)}
                    $isActive={selected?.includes(value.value)}
                  >
                    {value.display}
                  </MenuItem>
                )
              })}
            </MenuItemArea>
          </MenuBox>
        </Popover>
      )}
    </>
  )
}

export default DropDownMultiselect
