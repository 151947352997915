import React, { useState } from "react"
import { Grid, Box } from "@material-ui/core"
import {
  ConnectionListDialog,
  ConnectionListDialogHeader,
  ConnectionListDialogBox,
  DialogCloseButton,
  Divider,
} from "./Styles"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import "./fix-dialog.css"

import ProfileListingCard from "../../cards/ProfileListingCard"

import LoadingPage from "../../pages/LoadingPage"
import { Applicant } from "../../redux/jobListings"

export type Props = {
  onClose: () => void
  open: boolean
  jobApplicants: Applicant[]
}

const View = ({ open, onClose, jobApplicants }: Props) => {
  const [hover, setHover] = useState(false)

  return (
    <ConnectionListDialog
      maxWidth={"lg"}
      fullWidth={true}
      open={open}
      onClose={onClose}
      className={"JobApplicantsListDialog"}
    >
      <ConnectionListDialogHeader>
        <Box>Applicants</Box>

        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </ConnectionListDialogHeader>
      <ConnectionListDialogBox>
        {jobApplicants ? (
          <Grid container direction="column" style={{ flexWrap: "nowrap" }}>
            {jobApplicants.map((applicant, key) => (
              <React.Fragment key={`applicant-${key}`}>
                <ProfileListingCard profile={applicant.profile} />
                {jobApplicants.length - 1 !== key && (
                  <>
                    <Divider />
                  </>
                )}
              </React.Fragment>
            ))}
          </Grid>
        ) : (
          <LoadingPage />
        )}
      </ConnectionListDialogBox>
    </ConnectionListDialog>
  )
}

export default View
