import React from "react"

import IconCommented from "../../images/new-homepage/icons/comment-active.svg"
import IconNoComment from "../../images/new-homepage/icons/comment-default.svg"
import IconHover from "../../images/new-homepage/icons/comment-hover.svg"

import { PostCardActionsBox } from "../../components/Styles/Global"
import {
  IconContainer,
  Icon,
  CountLabel,
  IconFlexBox,
  CounterFlexBox,
} from "./styles"
import { Typography } from "@material-ui/core"
import { Post } from "../../redux/posts"
import PostLikeIcon from "../PostLikesIcon"
import PostShareIcon from "../PostShareIcon"

type Props = {
  post: Post
  goToPost: (post: Post) => void
}

const View = ({ post, goToPost }: Props) => {
  const [hover, setHover] = React.useState(false)

  const getCommentIcon = (commented?: boolean) => {
    if (commented) return IconCommented

    if (hover) return IconHover

    return IconNoComment
  }

  return (
    <PostCardActionsBox>
      <PostLikeIcon post={post} />
      <IconContainer
        $isActive={post.commented || false}
        onClick={() => goToPost(post)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconFlexBox>
          <CounterFlexBox>
            <Icon src={getCommentIcon(post.commented)} alt="Comment" />
            <CountLabel>{post.commentCount}</CountLabel>
          </CounterFlexBox>
          <Typography>Comment</Typography>
        </IconFlexBox>
      </IconContainer>
      <PostShareIcon post={post} />
    </PostCardActionsBox>
  )
}

export default View
