import React from "react"
import { useDropzone, DropzoneOptions } from "react-dropzone"
import { compressAccurately } from "image-conversion"

import View from "./View"
import CompactView from "./CompactView"

import { Image as ImageType } from "../../redux/projects"
import { minResY, minResX } from "../../constants/img-resolutions"

import { convertImages } from "../../utils/imagesConverter"

function fileDimensionsValidator(file: any) {
  // You can access width/height properties
  if (file.width < minResX || file.height < minResY)
    return {
      code: "resolution-too-low",
      message: `File: ${file.name}, resolution lower than ${minResX}x${minResY}.`,
    }

  return null
}

async function processRawImageFiles(files: any[]) {
  const promises: Promise<any>[] = []
  for (let index = 0; index < files.length; index++) {
    const file = files[index]
    const promise = new Promise((resolve) => {
      const image = new Image()
      let url: string
      image.onload = () => {
        file.width = image.width
        file.height = image.height
        resolve(file)
      }
      url = URL.createObjectURL(file)
      image.src = url
    })

    promises.push(promise)
  }

  return await Promise.all(promises)
}

// const MAX_FILE_SIZE = 10000000 // 10mb in bytes
const dropzoneOptions: DropzoneOptions = {
  multiple: true,
  accept: ["image/*", ".pdf"],
  // maxSize: MAX_FILE_SIZE,
}

export interface Props {
  compact?: boolean
  images: ImageType[]
  onChange?: (files: ImageType[], errors?: any[]) => void
  onCompress?: (isCompressing: boolean) => void
  onConvert?: (isConverting: boolean) => void
}

export interface DropzoneProps {
  isDragAccept: boolean
  isDragReject: boolean
  isDragActive: boolean
}

type RejectedFileError = {
  code: string
  message: string
}

export type RejectedFile = {
  file: File
  errors: RejectedFileError[]
}

export const StyledDropzone: React.FC<Props> = ({
  compact,
  images,
  onChange,
  onCompress,
  onConvert,
}) => {
  const onDrop = React.useCallback(
    async (acceptedFiles: any[], rejectedFiles: RejectedFile[]) => {
      const filteredFiles: any[] = []
      const newImages = new Array<ImageType>(...images)

      const allFiles = await convertImages(acceptedFiles, onConvert)
      if (onConvert) onConvert(false)

      await processRawImageFiles(allFiles)

      for (let idx = 0; idx < allFiles.length; idx++) {
        const file = allFiles[idx]
        const error = fileDimensionsValidator(file)
        if (error) {
          rejectedFiles.push({ file, errors: [error] })
        } else filteredFiles.push(file)
      }

      for (let idx = 0; idx < filteredFiles.length; idx++) {
        let file = filteredFiles[idx]

        if (file.size > 10000000) {
          if (onCompress) onCompress(true)
          const convertedFileObj = await compressAccurately(file, 10000) //conversion size must be passed in kb
          const convertedFile = new File([convertedFileObj], file.name, {
            type: convertedFileObj.type,
          }) as any
          convertedFile.width = file.width
          convertedFile.height = file.height
          file = convertedFile
          if (onCompress) onCompress(false)
        }

        newImages.push({
          file,
          url: URL.createObjectURL(file),
          order: idx + images.length,
          dimensions: {
            width: file.width,
            height: file.height,
          },
        })
      }

      if (onChange) onChange(newImages, rejectedFiles)
    },
    [images, onChange, onCompress, onConvert]
  )

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({ onDrop, ...dropzoneOptions })

  return compact ? (
    <CompactView
      isDragAccept={isDragAccept}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
    />
  ) : (
    <View
      isDragAccept={isDragAccept}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
    />
  )
}
