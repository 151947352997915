import React from "react"
import { Box, TableRow, TableCell } from "@material-ui/core"
import Dialog from "./Dialog"
import { News } from "../../../redux/adminNews"

export type Props = {
  news: News
}

const ListItem = ({ news }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <TableRow key={news.title}>
      <TableCell>{news.origin.name}</TableCell>
      <TableCell>{new Date(news.date!).toLocaleDateString("en-us")}</TableCell>
      <TableCell>{news.title}</TableCell>
      <TableCell>{news.published ? "Published" : "Not Published"}</TableCell>
      <TableCell>
        <Box
          paddingRight={1}
          display="inline"
          onClick={() => setIsOpen(true)}
          style={{ cursor: "pointer" }}
        >
          Review
        </Box>
      </TableCell>
      <Dialog news={news} open={isOpen} onClose={() => setIsOpen(false)} />
    </TableRow>
  )
}

export default ListItem
