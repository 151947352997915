import React from "react"

import { Dialog } from "./styles"
import PopoverContent from "./PopoverContent"

export type JobDialogType =
  | "delete"
  | "created"
  | "close"
  | "promote"
  | "promoted"
  | "unpromote"

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  type: JobDialogType
}

export const JobDialog: React.FC<Props> = ({
  type,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby={`job-${type}-dialog`}
    >
      <PopoverContent type={type} onClose={onClose} onConfirm={onConfirm} />
    </Dialog>
  )
}
