import styled from "styled-components"
import theme, { colors } from "../../theme"

export const ValidationErrorList = styled.li`
  color: red;
  padding: 0.2rem;
`

export const PlaceholderImage = styled.img`
  height: 108px;
  width: 108px;
  margin-top: ${theme.spacing(3)}px;
`

export const ImageInput = styled.input`
  display: none;
`

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 18px;
  max-width: 100%;
`

export const ImageCell = styled.div<{ fullWidth?: any }>`
  height: 108px;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "108px")};
  margin-top: ${theme.spacing(2)}px;
  background-color: ${colors.white};

  &:hover .overlay {
    opacity: 1;
  }
`

export const ImageCellImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`

export const ImageCellAction = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  cursor: pointer;
`

export const ImageCellOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.2);
`
