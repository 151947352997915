import React from "react"

import MobileView from "./MobileView"
import TabletView from "./TabletView"
import DesktopView from "./DesktopView"

import theme from "../../../../theme"
import { Profile } from "../../../../redux/profile"
import { useWindowDimensions } from "../../../../hooks"

export interface Props {
  profile: Profile
  isPublicProfile: boolean
  goToUrl: (url: string) => void
}

const ProfileHeader: React.FC<Props> = (props) => {
  const { width } = useWindowDimensions()

  return width >= theme.breakpoints.values.lg ? (
    <DesktopView {...props} />
  ) : width >= theme.breakpoints.values.sm ? (
    <TabletView {...props} />
  ) : (
    <MobileView {...props} />
  )
}

export default ProfileHeader
