import React, { useState } from "react"
import { Formik, FormikHelpers } from "formik"
import { JobListing } from "../../redux/jobListings"
import { Profile } from "../../redux/profile"
import {
  ButtonContainer,
  FormStyled,
  FormStyled2,
  Step2RightBox,
  Step2LeftBox,
  JobContainer,
  RightTitle,
  GreyBall,
  GreenBall,
  RedBall,
  StatusText,
  DescriptionText,
  AdBox,
  AdText,
} from "./styles"
import { Typography, Box } from "@material-ui/core"
import { Button } from "../../components"
import { JobFormType } from "../../enums/JobFormType"

export interface StepProps {
  validationSchema?: any
  groupValidationSchema?: any
  onCancel?: () => void
  onSubmit?: (values: any, bag: any) => void
}

type WizardProps = {
  children: React.ReactNode[]
  jobListing: JobListing
  onSubmit: Function
  onCancel: () => void
  error?: string
  profile: Profile
  type: JobFormType
  isMobile: boolean
}
const Wizard = ({
  children,
  jobListing,
  onSubmit,
  onCancel,
  error,
  profile,
  type,
  isMobile,
}: WizardProps) => {
  const steps = React.Children.toArray(children)
  const [stepNumber, setStepNumber] = useState(0)
  const [snapshot, setSnapshot] = useState(jobListing)

  const formRef = React.useRef<any>()
  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isFirstStep = stepNumber === 0
  const isLastStep = stepNumber === totalSteps - 1

  const scrollTop = () => window.scroll(0, 0)

  const next = (values: JobListing) => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
    scrollTop()
  }

  const goToStep = (step: number, values: JobListing) => {
    setSnapshot(values)
    setStepNumber(step)
    scrollTop()
  }

  const handleSubmit = async (
    values: JobListing,
    bag: FormikHelpers<JobListing>
  ) => {
    if ((step as any).props.onSubmit) (step as any).props.onSubmit(values, bag)

    if (isLastStep) {
      window.scrollTo(0, 0)
      return onSubmit(values, true)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  const handleDialogSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const getButtonLabel = () => {
    if (type === JobFormType.REPOST) return "Republish job posting"

    if (type === JobFormType.EDIT) return "Update job posting"

    return "Create job posting"
  }

  const FormComponent = isFirstStep ? FormStyled : FormStyled2
  const GridComponent = isFirstStep ? Box : Step2LeftBox
  const ContainerComponent = !isFirstStep && isMobile ? Box : JobContainer

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={(step as any).props.validationSchema}
      innerRef={formRef}
    >
      {(formik) => (
        <ContainerComponent>
          <FormComponent>
            {error && (
              <Typography tabIndex={0} color="error">
                {error}
              </Typography>
            )}
            <GridComponent>
              {step}
              <ButtonContainer isFirstStep={isFirstStep}>
                <Button
                  style={{
                    height: "40px",
                    fontSize: "14px",
                    width: isFirstStep || isMobile ? "100%" : "126px;",
                  }}
                  color="secondary"
                  size="medium"
                  data-testid="job-create-button"
                  onClick={handleDialogSubmit}
                >
                  {isLastStep ? getButtonLabel() : "Continue job post"}
                </Button>

                <Button
                  style={{
                    height: "40px",
                    fontSize: "14px",
                    width: isFirstStep || isMobile ? "100%" : "145px;",
                  }}
                  onClick={() => {
                    isFirstStep
                      ? onCancel()
                      : goToStep(
                          Math.min(stepNumber - 1, totalSteps - 1),
                          formik.values
                        )
                  }}
                  color="whiteGreyBorder"
                  size="medium"
                  data-testid="cancel-job-create-button"
                >
                  {isLastStep ? "Back to previous" : "Cancel"}
                </Button>
              </ButtonContainer>
            </GridComponent>
            {!isMobile && !isFirstStep && (
              <Step2RightBox>
                <Box paddingBottom={5}>
                  <RightTitle>{formik.values.title}</RightTitle>
                  <Box
                    paddingTop={2}
                    paddingBottom={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    {type === "new" ? (
                      <GreyBall />
                    ) : formik.values.status === "open" ? (
                      <GreenBall />
                    ) : (
                      <RedBall />
                    )}
                    <StatusText>
                      {type === "new" ? (
                        <>Draft</>
                      ) : formik.values.status === "open" ? (
                        <>Open</>
                      ) : (
                        <>Closed</>
                      )}
                    </StatusText>
                  </Box>
                  <DescriptionText>
                    {profile.groupName}{" "}
                    {profile.location && (
                      <>
                        <span style={{ fontSize: "10px" }}>•</span>{" "}
                        {profile.location}
                      </>
                    )}
                  </DescriptionText>
                </Box>
                <AdBox>
                  <RightTitle>Ensure your jobs are searchable</RightTitle>
                  <AdText>
                    Include a detailed job description and job tags to target
                    the right candidates by making your job easy to search for.
                  </AdText>
                </AdBox>
              </Step2RightBox>
            )}
          </FormComponent>
        </ContainerComponent>
      )}
    </Formik>
  )
}

export type WizardStepProps = {
  children: JSX.Element
}
export const WizardStep: any = ({ children }: WizardStepProps) => children

export default Wizard
