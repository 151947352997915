import React from "react"
import { useHistory } from "react-router-dom"
import * as RouteConstants from "../../constants/routes"
import { normalizeProjectTitle } from "../../utils"
import { Project } from "../../redux/projects"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useAuth0 } from "@auth0/auth0-react"
type Props = {
  project: Project
  isLarge?: boolean
}

const ProjectCardIconActions = (props: Props) => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const goToProject = async (project: Project, section?: string) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    history.push(
      `${RouteConstants.PROFILE_CONTAINER}/${
        project.profile?.username
      }/projects/${project.id!}/${normalizeProjectTitle(project.name)}${
        section ? `#${section}` : ""
      }`
    )
  }

  return <View {...props} goToProject={goToProject} />
}

export default ProjectCardIconActions
