import React from "react"
import { Typography } from "@material-ui/core"

import { useWindowDimensions } from "../../hooks"

export const Description: React.FC<{ value: string }> = ({ value }) => {
  const { width } = useWindowDimensions()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {width <= 550 ? (
        <Typography style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
          {open || value.length < 200 ? value : value.slice(0, 200) + "..."}
        </Typography>
      ) : (
        <Typography style={{ whiteSpace: "pre-line" }}>{value}</Typography>
      )}
      {value.length >= 200 && width <= 550 ? (
        <div
          style={{ color: "#3057E1", cursor: "pointer", paddingTop: "16px" }}
          onClick={() => setOpen(!open)}
        >
          <b>{!open ? "Read more" : "Show less"}</b>
        </div>
      ) : null}
    </>
  )
}
