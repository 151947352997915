import React from "react"
import styled from "styled-components"
import {
  Box,
  Button,
  Container,
  Link,
  Drawer as MUIDrawer,
} from "@material-ui/core"
import theme, { colors } from "../../theme"
import LoginButton from "../LoginButton"

const NavbarHeight = "66px"

export const Drawer = styled(MUIDrawer)`
  && {
    .MuiDrawer-paper {
      width: 180px !important;

      ${theme.breakpoints.down("xs")} {
        padding-top: env(safe-area-inset-top);
      }
    }
  }
`

export const Actions = styled.div`
  display: flex;
`

export const AvatarButton = styled.div`
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1070px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const Avatar = styled.img`
  height: ${NavbarHeight};
  width: ${NavbarHeight};
`

export const NavbarContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.primary.main};
    max-width: 100%;
    padding: 0;
    position: sticky;
    top: 0;
    background-color: ${colors.white};
    z-index: 10;

    ${theme.breakpoints.down("xs")} {
      padding-top: env(safe-area-inset-top);
      border-bottom: 0.5px solid ${theme.palette.primary.main};
    }
  }
`

export const MenuContainer = styled(Box)`
  width: 100%;
`

export const LoginButtonStyled = styled(LoginButton)`
  height: ${NavbarHeight};

  ${theme.breakpoints.down("xs")} {
    width: 96px;
    font-size: 12px;
    height: 56px;
  }
`

export const NavBarButton = styled(Button)`
  height: ${NavbarHeight};
  width: 164px;
  padding-left: 0px;
  padding-right: 0px;

  ${theme.breakpoints.down("xs")} {
    width: 96px;
    font-size: 12px;
    height: 56px;
  }
`

export const NavBarCreateAccountButton = styled(Button)`
  height: ${NavbarHeight};
  width: 164px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: ${colors.white};
  color: ${colors.dark};

  ${theme.breakpoints.down("xs")} {
    width: 96px;
    font-size: 12px;
    height: 56px;
  }
`

export const LinkBar = styled(Link)`
  padding: 24px 30px;
  font-family: Public-Sans;
  font-weight: bold;
  border-left: 1px solid black;
  font-size: 14px;
  text-decoration: none;
`

export const Logo = styled.img`
  padding: 0 ${theme.spacing(2)}px;

  ${theme.breakpoints.down("xs")} {
    width: 120px;
    padding: 0 12px;
    margin-top: 5px;
  }
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  margin: 23px 20px;
  font-size: 14px;
  font-weight: bold;

  && img {
    vertical-align: middle;
    margin-left: 2px;
    width: 30px;
  }

  :hover {
    color: #717171;
  }

  @media only screen and (max-width: 1370px) {
    margin: 23px 10px;
  }

  @media only screen and (max-width: 1265px) {
    margin: 23px 6px;
  }

  @media only screen and (max-width: 1150px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 1030px) {
    margin: 23px 3px;
  }
  @media only screen and (max-width: 990px) {
    font-size: 10px;
  }
`

export const StyledA = styled.a`
  text-decoration: none;
  margin: 23px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2d2d2d;

  :hover {
    color: #717171;
  }

  @media only screen and (max-width: 1320px) {
    margin: 23px 12px;
  }

  @media only screen and (max-width: 1180px) {
    margin: 23px 6px;
  }

  @media only screen and (max-width: 1130px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 980px) {
    margin: 23px 3px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${theme.palette.primary.main};
  height: ${NavbarHeight};
  padding: 0px ${theme.spacing(1)}px;

  ${theme.breakpoints.down("xs")} {
    height: 56px;
    border-right: 0;
  }
`

export const ActionItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
`
