import styled from "styled-components"

import { colors } from "../../theme"

export const ScrollableContainer = styled.div`
  width: auto;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid ${colors.slate};

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
`
