import React from "react"
import View from "./View"
import { News } from "../../redux/adminNews"

type Props = {
  news: News
}

const NewsCardIconActions = (props: Props) => {
  return <View {...props} />
}

export default NewsCardIconActions
