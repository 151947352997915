import React from "react"
import { Box } from "@material-ui/core"
import { NullstateTab } from "./styles"
import { ProfileProject } from "../../redux/profile"
import ProjectCard from "../../cards/ProjectCard"
import LightButtressDivider from "../../components/LightButtressDivider"
import ProfileNullstate from "../../components/ProfileNullstate"
import PostButton from "../../components/PostButton"

interface Props {
  isPublicProfile: boolean
  onAddProject: () => void
  goToUrl: (url: string) => void
  projects: ProfileProject[]
}

export const ProjectsDetails = ({
  projects,
  onAddProject,
  goToUrl,
  isPublicProfile,
}: Props) => {
  return (
    <Box style={{ padding: projects.length === 0 ? "0" : "16px 0" }}>
      {!isPublicProfile && <PostButton origin="projects" />}

      {projects.map((project, index) => {
        if (isPublicProfile && project.published) {
          return (
            <Box marginTop={4}>
              <ProjectCard
                project={project as any}
                hideComments={true}
                source={"profiles-page"}
              />

              {index < projects.length - 1 && (
                <Box marginTop={4}>
                  <LightButtressDivider />
                </Box>
              )}
            </Box>
          )
        } else if (!isPublicProfile) {
          return (
            <Box marginTop={4}>
              <ProjectCard
                project={project as any}
                hideComments={true}
                source={"profiles-page"}
              />

              {index < projects.length - 1 && (
                <Box marginTop={4}>
                  <LightButtressDivider />
                </Box>
              )}
            </Box>
          )
        } else {
          return ""
        }
      })}

      {projects.length === 0 && !isPublicProfile && (
        <NullstateTab>
          <ProfileNullstate
            onClick={onAddProject}
            type={"owner-projects-tab"}
          />
        </NullstateTab>
      )}

      {projects.length === 0 && isPublicProfile && (
        <NullstateTab>
          <ProfileNullstate
            onClick={() => goToUrl("/projects")}
            type={"projects-tab"}
          />
        </NullstateTab>
      )}
    </Box>
  )
}
