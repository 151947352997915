import React from "react"
import ProjectCommentsButton from "../ProjectCommentsButton"
import ProjectLikesButton from "../ProjectLikesButton"
import { ActionsBox } from "./styles"
import ProjectNominationCounter from "../ProjectNominationCounter"
import { Project } from "../../redux/projects"
import ProjectShareButton from "../ProjectShareButton"

export type Props = {
  project: Project
}

const View = ({ project }: Props) => {
  return (
    <ActionsBox>
      <ProjectLikesButton project={project} />
      <ProjectNominationCounter project={project} />
      <ProjectCommentsButton project={project} />
      <ProjectShareButton project={project} />
    </ActionsBox>
  )
}

export default View
