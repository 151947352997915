import styled from "styled-components"
import { Box } from "@material-ui/core"
import theme from "../../../../theme"

export const ConnectionsBox = styled(Box)`
  background-color: #fff;
  border-radius: 1px;
  width: 100%;
`

export const ConnectionsHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 0 40px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const ConnectionsList = styled(Box)`
  padding: 40px;

  ${theme.breakpoints.down("sm")} {
    padding: 24px 20px;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  border-bottom: 0.5px solid #d8d8d8;
  margin: 24px 0;
`

export const SubMenuBox = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  border-bottom: 0.5px solid #d8d8d8;
  padding-bottom: 16px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 16px;
  }
`

export const SubMenuItem = styled(Box)<{ $isActive: boolean }>`
  color: #979797;
  cursor: pointer;
  font-size: 20px;

  ${({ $isActive }) =>
    $isActive
      ? `color:#2d2d2d;
    font-family: Public-Sans-Bold;
    text-decoration-line: underline !important;
    text-decoration-thickness: 2px !important;
    text-decoration-color: #2d2d2d !important;
    text-underline-offset: 18px !important;`
      : ""}

  &:hover {
    color: #2d2d2d;
    font-family: Public-Sans-Bold;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #2d2d2d;
    text-underline-offset: 18px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`
