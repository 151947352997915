import React from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import throttle from "lodash/throttle"
import { convertToRaw } from "draft-js"
import { RawDraftContentState } from "draft-js"
import {
  Box,
  Grid,
  Typography,
  FormHelperText,
  TextField as MUITextField,
} from "@material-ui/core"
import parse from "autosuggest-highlight/parse"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import { RichTextEditorContainer, Alert } from "./styles"

import TextField from "../TextField"
import JobListingTags from "../JobListingTags"
import { JobListing } from "../../redux/jobListings"

import RichTextEditor from "../RichTextEditor"
import { Button } from "../Button"
import SelectField from "../SelectField"

import AlertIcon from "../../images/alert.svg"

import "./errors.css"
import UrlValidationDialog from "../UrlValidationDialog"

import {
  workplaceTypes,
  employmentTypes,
  experienceLevels,
} from "../../constants/jobs-constants"

const MAX_SUMMARY_CHAR_COUNT = 184

export type Props = {
  type: "new" | "edit" | "repost"
  values: JobListing
  isValidUrl: boolean
  onCancel: () => void
  onSubmit: (values: JobListing) => void
  checkIfUrlIsValid: (url: string) => void
}

const JobListingSchema = Yup.object().shape({
  title: Yup.string()
    .required("Required")
    .max(100, "Title must be less than 100 characters"),
  jobUrl: Yup.string().test("CheckDefaultValue", "Required", function (item) {
    return (
      this.parent.jobUrl &&
      this.parent.jobUrl !== "https://" &&
      this.parent.jobUrl !== "HTTPS://" &&
      this.parent.jobUrl !== "HTTP://" &&
      this.parent.jobUrl !== "http://"
    )
  }),
  location: Yup.string().required("Required"),
  summary: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  workplaceType: Yup.string().required("Required"),
  experienceLevel: Yup.string().required("Required"),
  employmentType: Yup.string().required("Required"),
  tags: Yup.array(Yup.string().required("Required")),
})

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

const View = ({
  values,
  onSubmit,
  onCancel,
  checkIfUrlIsValid,
  isValidUrl,
  type,
}: Props) => {
  let defaultLocation: PlaceType = {
    description: values?.location,
    structured_formatting: {
      main_text: "main text",
      secondary_text: values?.location,
      main_text_matched_substrings: [
        {
          offset: 1,
          length: 1,
        },
      ],
    },
  }
  const classes = useStyles()
  const [jobUrlField, setJobUrlField] = React.useState(values.jobUrl)
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<PlaceType | null>(defaultLocation)
  const [richTextValue, setRichTextValue] = React.useState(
    values.descriptionRtf
  )
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const loaded = React.useRef(false)
  const formRef = React.useRef<any>()

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB882GbnKnvMUITZPoLHSzr8No39YHUymE&libraries=places",
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string; types?: string[] },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google)
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()

    if (!autocompleteService.current) return undefined

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      { input: inputValue, types: ["geocode"] },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[]

          if (value) newOptions = [value]

          if (results) newOptions = [...newOptions, ...results]

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const maxLength = 6000

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_SUMMARY_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_SUMMARY_CHAR_COUNT)
  }

  const parseMaxLength = (raw: RawDraftContentState) => {
    const result = Object.assign(raw, {})

    let currentCount = 0

    result.blocks = raw.blocks.map((block, index) => {
      if (currentCount > maxLength) {
        return {
          ...block,
          text: "",
        }
      }

      const oldCount = currentCount
      const textLength = block.text.length
      currentCount += textLength
      let newText = block.text

      if (currentCount + index > maxLength) {
        const diff = maxLength - oldCount - index
        newText = block.text.substr(0, diff)
      }

      return {
        ...block,
        text: newText,
      }
    })

    result.blocks = result.blocks.filter((block) => {
      return block.text.length > 0
    })

    return result
  }

  const checkUrl = (e: any) => {
    checkIfUrlIsValid(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleDialogSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
      handleClose()
    }
  }

  const buttonLabel =
    (type === "repost" ? "Republish" : values.id ? "Update" : "Create new") +
    " posting"

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={values}
      enableReinitialize={true}
      validationSchema={JobListingSchema}
      innerRef={formRef}
    >
      {(formik) => (
        <Form>
          <Grid container justify="center">
            <Grid item md={8} lg={5} sm={6} style={{ maxWidth: "463px" }}>
              <Grid container>
                <Box marginTop={5}>
                  <Typography
                    tabIndex={0}
                    variant="h5"
                    style={{
                      fontSize: "36px",
                      lineHeight: "38px",
                      marginTop: "-6px",
                    }}
                  >
                    {type === "new"
                      ? "Create a new job posting"
                      : type === "edit"
                      ? "Edit job posting"
                      : "Repost job posting"}
                  </Typography>

                  <Box style={{ paddingTop: "20px", paddingBottom: "8px" }}>
                    * = Required
                  </Box>
                </Box>

                <Grid item xs={12}>
                  <TextField
                    name="title"
                    label="Job title*"
                    data-testid="jobTitle"
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    name="workplaceType"
                    label="Workplace type*"
                    options={workplaceTypes}
                    data-testid="workplaceType"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="google-map-demo"
                    freeSolo={true}
                    popupIcon={""}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    onBlur={() => formik.setFieldTouched("location")}
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(_: any, newValue: any) => {
                      setOptions(newValue ? [newValue, ...options] : options)
                      setValue(newValue)
                      formik.setFieldValue("location", newValue?.description)
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue)
                    }}
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        fullWidth
                        error={
                          formik.errors.location !== undefined &&
                          formik.touched.location
                        }
                        helperText={
                          formik.errors.location !== undefined &&
                          formik.touched.location
                            ? formik.errors.location
                            : ""
                        }
                        name="location"
                        data-testid="location"
                        label="Position location*"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          formik.setFieldValue("location", e.target.value)
                        }}
                      />
                    )}
                    renderOption={(option) => {
                      const matches =
                        option.structured_formatting
                          .main_text_matched_substrings
                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [
                          match.offset,
                          match.offset + match.length,
                        ])
                      )

                      return (
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon className={classes.icon} />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    name="employmentType"
                    label="Employment type*"
                    options={employmentTypes}
                    data-testid="employmentType"
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    name="experienceLevel"
                    label="Experience level*"
                    options={experienceLevels}
                    data-testid="experienceLevel"
                  />
                </Grid>

                <Grid item xs={12}>
                  <MUITextField
                    name="jobUrl"
                    data-testid="jobUrl"
                    label="Link to job posting*"
                    inputProps={{ maxLength: 250 }}
                    onBlur={(e: any) => checkUrl(e)}
                    value={jobUrlField}
                    error={
                      (formik.errors.jobUrl !== undefined &&
                        formik.touched.jobUrl) ||
                      !isValidUrl
                    }
                    helperText={
                      !isValidUrl
                        ? "This is an invalid link"
                        : formik.errors.jobUrl !== undefined &&
                          formik.touched.jobUrl
                        ? formik.errors.jobUrl
                        : ""
                    }
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      formik.setFieldValue("jobUrl", e.target.value)
                      setJobUrlField(e.target.value)
                    }}
                  />
                  {!isValidUrl ? <Alert src={AlertIcon} alt="alert" /> : null}
                </Grid>
                <Grid item xs={12} aria-label="credentials list">
                  <Box marginTop={4} marginBottom={0.5}>
                    <Typography
                      tabIndex={0}
                      variant="h5"
                      style={{ fontSize: "25px", lineHeight: "26px" }}
                    >
                      Job tags
                    </Typography>
                  </Box>
                  <JobListingTags
                    initialTags={values.tags}
                    onBlur={() => formik.setSubmitting(false)}
                    onFocus={() => formik.setSubmitting(true)}
                    onChange={(values) => formik.setFieldValue("tags", values)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={4} marginBottom={2}>
                    <Typography
                      style={{
                        fontSize: "25px",
                        lineHeight: "27px",
                        marginBottom: "4px",
                        color:
                          formik.errors.summary && formik.touched.summary
                            ? "#f44336"
                            : "",
                      }}
                      tabIndex={0}
                      variant="h5"
                    >
                      Job summary *
                    </Typography>
                    <TextField
                      name="summary"
                      label=""
                      multiline={true}
                      rows={3}
                      rowsMax={3}
                      data-testid="project-summary-field"
                      value={formik.values.summary}
                      onChange={formik.handleChange}
                      onInput={characterCounter}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        float: "right",
                        lineHeight: "14px",
                        marginTop: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      {formik.values.summary?.length}/
                      <span
                        style={{
                          color:
                            formik.values.summary?.length === 184
                              ? "red"
                              : "black",
                        }}
                      >
                        {MAX_SUMMARY_CHAR_COUNT} characters
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={4}>
                    <Typography
                      style={{
                        fontSize: "25px",
                        lineHeight: "27px",
                        marginBottom: "20px",
                        color:
                          formik.errors.description &&
                          formik.touched.description
                            ? "#f44336"
                            : "",
                      }}
                      tabIndex={0}
                      variant="h5"
                    >
                      Job description *
                    </Typography>
                    <RichTextEditorContainer
                      error={
                        formik.errors.description && formik.touched.description
                      }
                    >
                      <RichTextEditor
                        onBlur={() => formik.setFieldTouched("description")}
                        label="Type job description here..."
                        defaultValue={richTextValue}
                        data-testid="description"
                        maxLength={
                          formik?.values?.description?.length >= maxLength
                            ? maxLength
                            : Infinity
                        }
                        onChange={(plainText, richText, event) => {
                          if (plainText.length > maxLength) {
                            formik.setFieldValue(
                              "description",
                              plainText.substr(0, maxLength)
                            )
                            formik.setFieldValue(
                              "descriptionRtf",
                              JSON.stringify(
                                parseMaxLength(
                                  convertToRaw(event.getCurrentContent())
                                )
                              )
                            )
                            setRichTextValue(
                              JSON.stringify(
                                parseMaxLength(
                                  convertToRaw(event.getCurrentContent())
                                )
                              )
                            )
                          } else {
                            formik.setFieldValue("description", plainText)
                            formik.setFieldValue("descriptionRtf", richText)
                          }
                        }}
                      />
                    </RichTextEditorContainer>
                    <Typography
                      variant="body2"
                      style={{
                        float: "right",
                        lineHeight: "14px",
                        marginTop: "16px",
                      }}
                    >
                      {formik?.values?.description?.length}/
                      <span
                        style={{
                          color:
                            formik?.values?.description?.length === 6000
                              ? "red"
                              : "black",
                        }}
                      >
                        {maxLength} characters
                      </span>
                    </Typography>
                    {formik.errors.description &&
                      formik.touched.description && (
                        <FormHelperText error>
                          {formik.errors.description}
                        </FormHelperText>
                      )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    paddingTop: "40px",
                    marginBottom: "96px",
                  }}
                >
                  <Box>
                    {!isValidUrl ? (
                      <Button
                        onClick={handleOpen}
                        children={buttonLabel}
                        color={"primary"}
                        data-testid="submit"
                        type="button"
                        style={{
                          width: "168px",
                          height: "48px",
                        }}
                      />
                    ) : (
                      <Button
                        children={buttonLabel}
                        color={"primary"}
                        data-testid="submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        style={{
                          width: "168px",
                          height: "48px",
                        }}
                      />
                    )}
                  </Box>
                  <Box>
                    <Button
                      onClick={onCancel}
                      children={"Cancel"}
                      color={"whiteGreyBorder"}
                      style={{
                        width: "98px",
                        height: "48px",
                        marginLeft: "16px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <UrlValidationDialog
            open={open}
            onClose={handleClose}
            onSubmit={handleDialogSubmit}
          />
        </Form>
      )}
    </Formik>
  )
}

export default View
