import React from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { archiveProfile } from "../../redux/adminProfiles"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

interface Props {
  profileId: string
}

const AdminProfileArchiveLink = ({ profileId }: Props) => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleOnConfirm = async () => {
    try {
      await dispatch(archiveProfile({ auth, profileId }))
      history.push(`/admin/profiles`)
    } catch (e) {
      console.error(e)
    }
  }

  return <View onConfirmClicked={handleOnConfirm} />
}

export default AdminProfileArchiveLink
