import React from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { Box, Typography, Link } from "@material-ui/core"

import PhoneIcon from "../../images/ico-phone-black.svg"
import EmailIcon from "../../images/ico-email-black.svg"

import ConfirmConnectionDialog from "../ConfirmConnectionDialog"

import { TitleLabel } from "../../pages/ProfileDetailPage/styles"
import { Profile } from "../../redux/profile"

import { ContactBox } from "./styles"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  profile: Profile
  isConnected?: boolean
}

const ContactInformationCard = ({ profile, isConnected }: Props) => {
  const { isAuthenticated } = useAuth0()

  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
      return
    }
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <ContactBox>
      <Typography
        style={{
          fontSize: "20px",
          color: "#2D2D2D",
          marginBottom: "24px",
        }}
      >
        Contact information
      </Typography>

      {isConnected ? (
        <>
          {profile.phoneNumber && (
            <Typography
              tabIndex={0}
              variant="body2"
              style={{ marginBottom: "20px", display: "flex" }}
            >
              <Box>
                <img
                  src={PhoneIcon}
                  alt="phone"
                  style={{ marginRight: "20px" }}
                />
              </Box>
              <Box style={{ marginTop: "-2px" }}>
                <TitleLabel>Phone</TitleLabel>
                {profile.phoneNumber}
              </Box>
            </Typography>
          )}
          <Typography tabIndex={0} variant="body2" style={{ display: "flex" }}>
            <Box>
              <img
                src={EmailIcon}
                alt="email"
                style={{ marginRight: "20px" }}
              />
            </Box>
            <Box style={{ marginTop: "-2px", wordBreak: "break-all" }}>
              <TitleLabel>Email</TitleLabel>
              {profile.email}
            </Box>
          </Typography>
        </>
      ) : (
        <Typography tabIndex={0} variant="body2">
          <Link style={{ color: "#3057E1" }} onClick={handleOpen}>
            Connect
          </Link>{" "}
          to view contact information.
        </Typography>
      )}

      <ConfirmConnectionDialog
        open={open}
        onClose={handleClose}
        profile={profile}
      />
    </ContactBox>
  )
}

export default ContactInformationCard
