import React, { useEffect, useState } from "react"
import View from "./View"
import { JobListing, validateJobUrl } from "../../redux/jobListings"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"

export type Props = {
  type: "new" | "edit" | "repost"
  jobListing?: JobListing
  onSubmit: (jobListing: JobListing) => void
  onCancel: () => void
}

const emptyJobListing: JobListing = {
  title: "",
  tags: [],
  jobUrl: "",
  location: "",
  summary: "",
  description: "",
  descriptionRtf: "",
  employmentType: "",
  workplaceType: "",
  experienceLevel: "",
  status: "open",
}

const JobListingForm = ({ jobListing, onSubmit, onCancel, type }: Props) => {
  const auth = useAuth0()
  const [isValidUrl, setIsValidUrl] = useState(true)

  const dispatch = useAppDispatch()

  let job = Object.assign(jobListing || emptyJobListing, {})

  const handleOnSubmitForm = async (jobListing: JobListing) =>
    onSubmit(jobListing)

  const validateJobURL = (url: string) =>
    url?.search("http://") === 0 || url?.search("https://") === 0

  const jobSummary = job.summary ? job.summary : ""

  const initialValues: JobListing = {
    ...job,
    jobUrl: validateJobURL(job.jobUrl!) ? job.jobUrl : "https://" + job.jobUrl,
    summary: jobSummary,
  }

  const checkIfUrlIsValid = async (url: string) => {
    if (!url) {
      //SETS TO TRUE SO IT CAN BE BLOCKED BY YUP
      setIsValidUrl(true)
      return true
    }

    if (url === "https://") {
      //SETS TO TRUE SO IT CAN BE BLOCKED BY YUP
      setIsValidUrl(true)
      return true
    }

    if (url.length <= 4) {
      setIsValidUrl(false)
      return false
    }

    const validateUrl = validateJobURL(url) ? url : "https://" + url
    const result = await dispatch(validateJobUrl({ auth, url: validateUrl! }))

    setIsValidUrl(result.payload)
  }

  useEffect(() => {
    if (type !== "new" && jobListing?.jobUrl) {
      checkIfUrlIsValid(jobListing?.jobUrl)
    }

    //eslint-disable-next-line
  }, [])

  return (
    <View
      type={type}
      onCancel={onCancel}
      values={initialValues}
      onSubmit={handleOnSubmitForm}
      checkIfUrlIsValid={checkIfUrlIsValid}
      isValidUrl={isValidUrl}
    />
  )
}

export default JobListingForm
