import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0;
`

export const Buttress = styled.img`
  position: absolute;
  top: -8px;
  left: 0;
`
