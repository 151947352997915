import React from "react"
import { Box, Button, Link } from "@material-ui/core"
import { Form } from "formik"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const IconOverlayRight = styled.div`
  top: ${theme.spacing(1)}px;
  right: ${theme.spacing(1)}px;
  z-index: 4;
  position: absolute;
`

export const IconOverlayLeft = styled.div`
  top: ${theme.spacing(1)}px;
  left: ${theme.spacing(1)}px;
  z-index: 4;
  position: absolute;
`

export const TextOverlayContainer = styled.div`
  height: 24px;
  display: flex;
  text-align: center;
  padding: 0.25em 0.5em;
  font-size: 0.875em;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const ListItemOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.666667%;
  position: relative;
  background: ${colors.lightGray};
  border-color: ${colors.gray};
  border-width: 1px;
  border-style: solid;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
`

export const ImageThumbnail = styled.img<{ auto: boolean }>`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
`

export const ImageErrorList = styled.li`
  color: red;
  padding: 0.2rem;
`

export const ProjectForm = styled(Form)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`

export const FormStyled = styled(Form)`
  max-width: 362px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.8rem;
  ${theme.breakpoints.up("sm")} {
    max-width: 800px;
  }
`

export const ButtonContainer = styled.div<{ isFirstStep: boolean }>`
  margin-top: 2.5rem;
  display: flex;
  justify-content: ${({ isFirstStep }) =>
    isFirstStep ? "flex-end" : "space-between"};
  align-items: center;
`

export const ActionButton = styled(Button)`
  && {
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
  }
`

export const SlateLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  padding-bottom: 2px;
  color: ${colors.slate};
  cursor: text;
`

export const StyledLinkBox = styled(Box)`
  font-family: Public-Sans;
  margin-top: ${theme.spacing(2)}px;
  a {
    padding-bottom: 2px;
    border-bottom: solid 0.5px ${colors.dark};
  }
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: ${colors.dark};
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`

export const SaveIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
`
