import React from "react"
import { Project } from "../../redux/projects"
import {
  CardBox,
  CardImageThumbnail,
  CardImageThumbnailContainer,
  CardNames,
  CardProfileName,
  CardProjectName,
  StyledLink,
} from "./styles"

import ProjectWinnerBadge from "../../components/ProjectWinnerBadge"

import "../../cards/ProjectCard/styles.css"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { normalizeProjectTitle } from "../../utils"

export type Props = {
  project: Project
  singleRow?: boolean
  showSchool?: boolean
}

const WinnersProjectCard = ({ project, singleRow, showSchool }: Props) => {
  let name = project.profile?.groupName
  if (project.profile?.type !== "group")
    name = project.profile?.firstName + " " + project.profile?.lastName

  const projectURL = `/sp/${
    project.username
  }/projects/${project.id!}/${normalizeProjectTitle(project.name)}`

  const collaborators = project.collaborators?.filter(
    (collab) => collab.status !== "pending"
  )

  return (
    <CardBox>
      <LinkBox to={projectURL}>
        <CardImageThumbnailContainer $isSingleRow={singleRow}>
          {project.rewards &&
            project.rewards.length > 0 &&
            project.rewards.map((reward) => {
              return (
                <div
                  className={`projectCardWinnerBadge projectCardWinnerBadgeProject`}
                >
                  <ProjectWinnerBadge variant="vertical" reward={reward} />
                </div>
              )
            })}
          <CardImageThumbnail
            $isSingleRow={singleRow}
            src={project.imageUrl}
            alt={project.name}
          />
        </CardImageThumbnailContainer>
      </LinkBox>
      <CardNames>
        {showSchool && project.firmName && (
          <CardProjectName style={{ fontSize: "24px" }}>
            {project.firmName}
          </CardProjectName>
        )}
        <CardProjectName>
          <StyledLink to={projectURL}>{project.name}</StyledLink>
        </CardProjectName>
        <CardProfileName>
          {collaborators && collaborators?.length > 0 ? (
            <>
              Multiowner (
              <StyledLink to={`/sp/${project.profile?.username}`}>
                {name}
              </StyledLink>
              ,&nbsp;
              {collaborators.map((collaborator, key) => {
                let collaboratorName = collaborator.profile?.groupName
                if (collaborator.profile?.type !== "group")
                  collaboratorName =
                    collaborator.profile?.firstName +
                    " " +
                    collaborator.profile?.lastName

                if (collaborator.status === "pending") return null

                return (
                  <StyledLink to={`/sp/${collaborator.profile?.username}`}>
                    {collaboratorName}
                    {collaborators && key < collaborators.length - 1 && ", "}
                  </StyledLink>
                )
              })}
              )
            </>
          ) : (
            <>
              <StyledLink to={`/sp/${project.profile?.username}`}>
                {name}
              </StyledLink>
            </>
          )}
        </CardProfileName>
      </CardNames>
    </CardBox>
  )
}

export default WinnersProjectCard
