import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useAuth0 } from "@auth0/auth0-react"
import { Share } from "@capacitor/share"
import environment from "../../environment"
import { updateFeedState } from "../../redux/feed"
import { News } from "../../redux/adminNews"
import { shareNews } from "../../redux/news"
import { logEvent } from "../../utils/analytics"

type Props = {
  news: News
}

const NewsShareIcon = ({ news }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleShareClick = async (wasShared: boolean): Promise<void> => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    if (!wasShared) {
      handleNewsShare()
    }
  }

  const handleNewsShare = async () => {
    setDispatchComplete(false)
    try {
      const { type } = await dispatch(shareNews({ auth, id: news.id! }))
      if (type === shareNews.fulfilled.type) {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "news",
            id: news.id!,
            action: "share",
          })
        )

        await logEvent("news_share")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false)
  }

  const handleOpenDialog = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    if (environment.device !== "desktop") {
      const shareUrl = news.url

      await Share.share({
        url: shareUrl,
      })

      handleNewsShare()
    } else {
      setDialogIsOpen(true)
    }
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      news={news}
      handleShareClick={handleShareClick}
      dialogIsOpen={dialogIsOpen}
      handleCloseDialog={handleCloseDialog}
      handleOpenDialog={handleOpenDialog}
    />
  )
}

export default NewsShareIcon
