import React from "react"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"

import View from "./View"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const AboutPage = () => {
  const history = useHistory()

  setTimeout(() => {
    // @ts-ignore
    window.prerenderReady = true
  }, 0)

  const goToMembersBenefits = () => {
    history.push("/member_benefits")
  }
  return (
    <>
      <RouteChangeTracker screenTitle={`About Us`} classTitle="AboutUs" />
      <Helmet>
        <title>About Us - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`About Us - Spectacular`} />
        <meta name="title" content={`About Us - Spectacular`} />
      </Helmet>
      <View goToMembersBenefits={goToMembersBenefits} />
    </>
  )
}

export default AboutPage
