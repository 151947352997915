import React from "react"

import SPLogoWhite from "../../images/student-ambassador/sa-white-logo.svg"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
} from "./styles"

const Header = () => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <StyledLogo src={SPLogoWhite} alt={"Logo"} />
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
