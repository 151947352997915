import React from "react"
import styled from "styled-components"
import { Box, Grid, Typography } from "@material-ui/core"
import theme, { colors } from "../../theme"
import { Link } from "react-router-dom"

export const TextBox = styled(Box)`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.dark};
  text-decoration: none;

  && span {
    font-weight: bold;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const Avatar = styled.img`
  width: 124px;
  height: 124px;
  object-fit: cover;

  ${theme.breakpoints.down("xs")} {
    width: 40.97px;
    height: 40.97px;
  }
`

export const ProjectThumbnail = styled.img`
  width: 165.33px;
  height: 124px;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    aspect-ratio: 4/3;
    height: auto;
  }
`

export const ProjectThumbnailPlaceholder = styled.div`
  width: 165.33px;
  height: 124px;
  background-color: #f7f7f7;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    aspect-ratio: 4/3;
    height: auto;
  }
`

export const CardBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    max-height: initial;
  }
`

export const AvatarBox = styled(Box)`
  height: 124px;

  ${theme.breakpoints.down("xs")} {
    height: auto;
  }
`

export const ContentBox = styled(Box)`
  display: flex;
  flex-flow: column;
  padding-left: 24px;
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    padding-left: 8px;
  }
`

export const NetworkingBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  height: -webkit-fill-available;
  gap: 24px;

  && button {
    width: 108px !important;
    height: 32px !important;
  }

  ${theme.breakpoints.down("xs")} {
    height: initial;
    display: initial;
    margin-top: 10px;
  }
`

export const ImagesBox = styled(Grid)`
  ${theme.breakpoints.down("xs")} {
    margin-top: 12px;
  }
`

export const TimeBox = styled(Typography)`
  color: #979797;
  line-height: 22px;
  margin-top: 8px;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const ProjectsBox = styled(Box)`
  height: 124px;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`
