import { combineReducers } from "@reduxjs/toolkit"
import projects from "./projects"
import profile from "./profile"
import jobPositions from "./jobPositions"
import educations from "./educations"
import jobListings from "./jobListings"
import adminProjects from "./adminProjects"
import adminProfiles from "./adminProfiles"
import bellNotifications from "./bellNotifications"
import config from "./config"
import configurations from "./configurations"
import connections from "./connections"
import posts from "./posts"
import adminPosts from "./adminPosts"
import feed from "./feed"
import competition from "./competition"
import adminNews from "./adminNews"
import news from "./news"
import product from "./product"
import home from "./home"
import ambassador from "./ambassador"

const rootReducer = combineReducers({
  projects: projects.reducer,
  profile: profile.reducer,
  jobPositions: jobPositions.reducer,
  educations: educations.reducer,
  jobListings: jobListings.reducer,
  adminProjects: adminProjects.reducer,
  adminPosts: adminPosts.reducer,
  adminProfiles: adminProfiles.reducer,
  adminNews: adminNews.reducer,
  bellNotifications: bellNotifications.reducer,
  config: config.reducer,
  configurations: configurations.reducer,
  connections: connections.reducer,
  posts: posts.reducer,
  feed: feed.reducer,
  competition: competition.reducer,
  news: news.reducer,
  product: product.reducer,
  home: home.reducer,
  ambassador: ambassador.reducer,
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
