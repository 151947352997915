import React, { useState } from "react"
import { Link } from "@material-ui/core"
import ConfirmDialog from "../ConfirmDialog"

export type Props = {
  onDeleteClicked: () => void
}

const View = ({ onDeleteClicked }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <Link onClick={() => setIsDialogOpen(true)} color="error">
        Delete project
      </Link>
      <ConfirmDialog
        title="Delete project"
        message="Are you sure you want to delete this project?"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={onDeleteClicked}
      />
    </>
  )
}

export default View
