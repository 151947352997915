import React from "react"
import { Box } from "@material-ui/core"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import {
  PopUp,
  PopUpTitle,
  PopUpButton,
  PopUpHeader,
  PopUpCloseIcon,
} from "./styles"
import { useHistory } from "react-router-dom"

interface Props {
  open: boolean
  onClose: () => void
}

const View: React.FC<Props> = ({ open, onClose }) => {
  const [hover, setHover] = React.useState(false)
  const history = useHistory()

  const onJoinNow = () => {
    onClose()
    history.push("/register")
  }

  return (
    <PopUp
      style={{
        opacity: open ? 1 : 0,
        visibility: open ? "visible" : "hidden",
      }}
    >
      <PopUpHeader>
        <PopUpTitle>Welcome to Spectacular</PopUpTitle>
        <PopUpCloseIcon
          alt={"close"}
          onClick={onClose}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          src={hover ? CloseIconHover : CloseIcon}
        />
      </PopUpHeader>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
        }}
      />
      <Box style={{ padding: "24px 24px" }}>
        <div
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontFamily: "Public-Sans",
            paddingBottom: "24px",
          }}
        >
          <div style={{ margin: "-4px 0px" }}>
            Seek inspiration. Find jobs. Hire talent. Spectacular is built by
            architects for architects.
          </div>
        </div>
        <PopUpButton color="primary" onClick={onJoinNow}>
          Sign Up
        </PopUpButton>
      </Box>
    </PopUp>
  )
}

export default View
