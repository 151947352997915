import React, { useState } from "react"
import View from "./View"
import * as RouteConstants from "../../constants/routes"
import {
  confirmConnectionRequest,
  denyConnectionRequest,
  Profile,
  sendNotificationEmailAndRemoveSuggestion,
} from "../../redux/profile"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import {
  deleteSuggestion,
  updateProfileConnetionRequestState,
} from "../../redux/connections"

export type Props = {
  profile: Profile
  confirmButtons?: boolean
  isSuggestion?: boolean
}

const GrowNetworkCard = ({ profile, confirmButtons, isSuggestion }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const handleConfirmConnection = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        confirmConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (confirmConnectionRequest.fulfilled.type === type) {
        await dispatch(
          updateProfileConnetionRequestState({
            profileId: connectionId!,
            type: "requests",
          })
        )

        await dispatch(
          sendNotificationEmailAndRemoveSuggestion({
            auth,
            profileId: connectionId!,
            templateName: "PROFILE_CONNECTION_ACCEPTED",
          })
        )
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleDenyConnection = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        denyConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (denyConnectionRequest.fulfilled.type === type) {
        await dispatch(
          updateProfileConnetionRequestState({
            profileId: connectionId!,
            type: "requests",
          })
        )

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleDeleteSuggestion = async () => {
    try {
      const { type } = await dispatch(
        deleteSuggestion({ auth, profileId: profile.connectionId! })
      )
      if (deleteSuggestion.fulfilled.type === type) {
        setIsDeleteDialogOpen(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteDialogOpen = (value: boolean) => {
    setIsDeleteDialogOpen(value)
  }

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${profile.username}`
  }

  return (
    <View
      profile={profile}
      goToProfile={goToProfile}
      confirmButtons={confirmButtons}
      isLoading={isLoading}
      onConfirmConnection={handleConfirmConnection}
      onDenyConnection={handleDenyConnection}
      isSuggestion={isSuggestion}
      handleDeleteSuggestion={handleDeleteSuggestion}
      handleDeleteDialogOpen={handleDeleteDialogOpen}
      isDeleteDialogOpen={isDeleteDialogOpen}
    />
  )
}

export default GrowNetworkCard
