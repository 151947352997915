import React, { useState, ChangeEvent } from "react"
import _ from "lodash"
import {
  Box,
  Grid,
  Checkbox,
  TextField,
  FormGroup,
  Typography,
  FormHelperText,
} from "@material-ui/core"

import projectCategories from "../../constants/project-categories"

import { ScrollableContainer, StyledFormControlLabel } from "./styles"
import { SelectOption } from "../SelectField"

type Props = {
  error?: string
  typology: string[]
  onChange: (category: string) => void
}

const MAX_ALLOWED = 5

const NewView = ({ typology, onChange, error }: Props) => {
  const [options] = useState(projectCategories)
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    onChange(target.value)

  const onBlur = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onChange(target.value)

  let otherCategory = typology
  options.map((option) => {
    otherCategory = otherCategory.filter(
      (e) => !Object.values(option).includes(e)
    )
    return otherCategory
  })

  const chunks = _.chunk(options, 3)

  const isDisabled = typology.length === MAX_ALLOWED

  function FormRow(options: SelectOption[]) {
    return (
      <>
        {options.map((option, idx) => {
          return option.value === "other" ? (
            <Grid item xs={12}>
              <TextField
                label="Other"
                onBlur={onBlur}
                data-testid="other"
                name={option.value}
                defaultValue={otherCategory}
              />
            </Grid>
          ) : (
            <Grid item md={4} xs={6} style={{ padding: "4px 12px" }}>
              <StyledFormControlLabel
                key={idx}
                label={option.name}
                control={
                  <Checkbox
                    value={option.value}
                    onChange={handleChange}
                    checked={
                      typology.find((category) => category === option.value)
                        ? true
                        : false
                    }
                    disabled={
                      isDisabled &&
                      !(typology.find((tool) => tool === option.value)
                        ? true
                        : false)
                    }
                  />
                }
              />
            </Grid>
          )
        })}
      </>
    )
  }

  return (
    <Box marginTop={3} marginBottom={5}>
      <Typography style={error ? { color: "#f44336" } : {}} variant="h5">
        Project Typology <span style={{ color: "#ff0000" }}>*</span>
      </Typography>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <Box paddingLeft={1.5} marginTop={2}>
        <FormGroup>
          <ScrollableContainer>
            {chunks.map((chunk, idx) => {
              return FormRow(chunk)
            })}
          </ScrollableContainer>
        </FormGroup>
      </Box>
    </Box>
  )
}

export default NewView
