import theme, { colors } from "../../theme"
import styled from "styled-components"
import { Link, Box } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"

export const AddLink = styled(Link)`
  font-weight: bold;
`
export const ExperienceBox = styled(Box)`
  background-color: ${colors.gray};
  position: relative;
`
export const EditLink = styled(RouterLink)`
  position: absolute;
  right: ${theme.spacing(4)}px;
  font-weight: bold;
  text-decoration: none;
  color: ${colors.accent};
`
