import React from "react"
import { Formik, Form, FormikHelpers } from "formik"
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@material-ui/core"
import * as Yup from "yup"
import { Education } from "../../redux/educations"

import TextField from "../../components/TextField"
import SelectField from "../../components/SelectField"
import RichTextEditor from "../../components/RichTextEditor"
import { availableYears } from "../../constants/months-years"
import degree from "../../constants/degree"
import { ActionButton, RichTextEditorContainer } from "./styles"

export type Props = {
  values: Education
  onSubmit: (values: Education, helpers: FormikHelpers<Education>) => void
  onCancel: () => void
}

const EducationSchema = Yup.object().shape({
  school: Yup.string().required("Required"),
  field: Yup.string().required("Required"),
  degree: Yup.string().required("Required"),
  startYear: Yup.number().moreThan(0, "Required").required("Required"),
  endYear: Yup.number()
    .when("current", {
      is: false,
      then: Yup.number().moreThan(0, "Required").required("Required"),
    })
    .transform((value) => (isNaN(value) ? 0 : value)),
})

const View = ({ values, onSubmit, onCancel }: Props) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={values}
      enableReinitialize={true}
      validationSchema={EducationSchema}
    >
      {(formik) => (
        <Form>
          <Grid container justify="center">
            <Grid item lg={3} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="school"
                    label="School"
                    placeholder="Ex: Boston University"
                    data-testid="school"
                    inputProps={{ maxLength: 80 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    name="degree"
                    label="Degree"
                    options={degree}
                    blank="Select degree"
                    data-testid="degree-field"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="field"
                    label="Field of study"
                    placeholder="Ex: Architecture"
                    data-testid="field"
                    inputProps={{ maxLength: 80 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box marginBottom={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.current}
                          name="current"
                          onChange={formik.handleChange}
                          data-testid="currentEducation"
                        />
                      }
                      label="I am currently studying"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <SelectField
                      name="startYear"
                      label="Start year"
                      options={availableYears}
                      blank="Select a year"
                      data-testid="startYear"
                      margin="none"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginLeft={1} marginBottom={1}>
                    <SelectField
                      name="endYear"
                      label="End year"
                      options={availableYears}
                      blank="Select a year"
                      data-testid="endYear"
                      margin="none"
                      disabled={formik.values.current}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={4} marginBottom={12}>
                    <Typography tabIndex={0} variant="h5">
                      Additional Information
                    </Typography>
                    <RichTextEditorContainer>
                      <RichTextEditor
                        label="e.g. Study abroad experience ('Study Abroad - Australia'), Courses ('Cabinetry making'), Notable studios, etc"
                        defaultValue={
                          values.additionalInfoRTF
                            ? values.additionalInfoRTF
                            : ""
                        }
                        data-testid="additionalInfo"
                        maxLength={500}
                        onChange={(plainText, richText) => {
                          formik.setFieldValue("additionalInfo", plainText)
                          formik.setFieldValue("additionalInfoRTF", richText)
                        }}
                      />
                    </RichTextEditorContainer>
                    <Typography tabIndex={0} style={{ float: "right" }}>
                      {formik.values.additionalInfo!.length} / 500
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginTop={4} marginRight={1}>
                    <ActionButton
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={formik.isSubmitting}
                      data-testid={"addEducationButton"}
                    >
                      {values.id ? "Update" : "Add"} education
                    </ActionButton>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginTop={4} marginLeft={1}>
                    <ActionButton fullWidth onClick={onCancel}>
                      Cancel
                    </ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default View
