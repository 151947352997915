import React, { useState } from "react"
import { Grid, Box } from "@material-ui/core"

import { useHistory } from "react-router-dom"
import LightButtressDivider from "../../components/LightButtressDivider"
import { Button } from "../../components/"
import JobRequestDialog from "../../components/JobRequestDialog"
import Ads from "../../components/Ads"
import FilterJobDialog from "../../components/FilterJobDialog"
import { MobileSearchBar } from "../../components/Styles/MainSearchBar"
import { JobListing } from "../../redux/jobListings"
import SearchIcon from "../../images/search.svg"
import ClearFilter from "../../images/clear-filter.svg"
import ClearFilterActive from "../../images/clear-filter-active.svg"

import LoadingPage from "../LoadingPage"
import InfiniteScroll from "react-infinite-scroll-component"

import FilterIcon from "../../images/mobile-filter-icon.svg"
import MobileSearchIcon from "../../images/mobile-search.svg"

import { Profile } from "../../redux/profile"
import { JobCard } from "../../cards/JobCard"

import {
  NewMainContainer,
  JobMainGrid,
  CounterText,
  CounterArea,
  JobAdArea,
  DividerBox,
  ListingTitle,
  SearchBoxArea,
  SearchBar,
  VerticalDivider,
  FilterBox,
  ClearFilterBox,
  ClearFilterBoxActive,
  Search,
  MobileFilterBox,
  MobileBox,
} from "./styles"

import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import DropDownMultiselect from "../../components/JobsDropDownMultiselect"
import { ActionsType as MultiActionsType } from "../../components/JobsDropDownMultiselect/View"

export type Props = {
  dataBound: boolean
  term: string
  jobs: JobListing[]
  promotedJobs: JobListing[]
  filterBy: string
  location?: string
  perPage: number
  page: number
  fetchedPages: number[]
  totalPages?: number
  jobsCount: number
  profile?: Profile
  workplaceType: MultiActionsType[]
  experienceLevel: MultiActionsType[]
  employmentType: MultiActionsType[]
  resetedValues: boolean
  hasMore: boolean
  onTermChanged: (value: string) => void
  onLocationChanged: (value: string) => void
  handleChangeFilterBy: (value: string) => void
  handleSearchSubmit: () => void
  handleClearFilters: () => void
  handleChangePagination: (value: number) => void
  handleChangeWorkplaceType: (value: MultiActionsType[]) => void
  handleChangeExperienceLevel: (value: MultiActionsType[]) => void
  handleChangeEmploymentType: (value: MultiActionsType[]) => void
  handleChangeHasMore: (value: boolean) => void
}

const View = ({
  dataBound,
  term,
  jobs,
  promotedJobs,
  jobsCount,
  location,
  filterBy,
  perPage,
  page,
  fetchedPages,
  totalPages,
  profile,
  workplaceType,
  experienceLevel,
  employmentType,
  resetedValues,
  hasMore,
  onTermChanged,
  onLocationChanged,
  handleChangeFilterBy,
  handleClearFilters,
  handleSearchSubmit,
  handleChangePagination,
  handleChangeWorkplaceType,
  handleChangeExperienceLevel,
  handleChangeEmploymentType,
  handleChangeHasMore,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [openMobileFilter, setOpenMobileFilter] = useState(false)

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const fetchMoreData = () => {
    if (jobs.length >= jobsCount) {
      handleChangeHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  const history = useHistory()

  const toggleOpen = (toggle: boolean) => setOpen(toggle)

  const goToUrl = (url: string) => history.push(url)

  const handleSearch = (e: any) => {
    handleSearchSubmit()
    e.preventDefault()
  }

  const handleClose = () => {
    setOpenMobileFilter(false)
  }

  const handleOpen = () => {
    setOpenMobileFilter(true)
  }

  const handleApplyMobileFilters = (value: any) => {
    handleChangeWorkplaceType(value.workplaceType)
    handleChangeEmploymentType(value.employmentType)
    handleChangeExperienceLevel(value.experienceLevel)
  }

  const handleApplyFilters = (type: string, value: MultiActionsType[]) => {
    if (type === "workplace-type") handleChangeWorkplaceType(value)
    else if (type === "employment-type") handleChangeEmploymentType(value)
    else if (type === "experience-level") handleChangeExperienceLevel(value)
  }

  const MobileDivider = (index: number) => {
    if (index % 5 === 0 && index % 10 !== 0 && profile?.type === "group") {
      return (
        <Box paddingTop={3} paddingBottom={3}>
          <Ads type={"promote-job"} onClick={() => goToUrl("/dashboard")} />
        </Box>
      )
    } else if (
      index % 5 === 0 &&
      index % 10 !== 0 &&
      (!profile || profile?.type === "individual")
    ) {
      return (
        <Box paddingTop={3} paddingBottom={3}>
          <Ads type={"promote-job"} onClick={() => goToUrl("/pricing")} />
        </Box>
      )
    } else if (index % 10 === 0 && profile?.type === "group") {
      return (
        <Box paddingTop={3} paddingBottom={3}>
          <Ads
            type={"recruitment"}
            onClick={() =>
              window.open("https://www.spectacular.design/recruitment")
            }
          />
        </Box>
      )
    } else if (
      index % 10 === 0 &&
      (!profile || profile?.type === "individual")
    ) {
      return (
        <Box paddingTop={3} paddingBottom={3}>
          <Ads
            type={"recruitment"}
            onClick={() =>
              window.open("https://www.spectacular.design/recruitment")
            }
          />
        </Box>
      )
    } else {
      return (
        <DividerBox>
          <LightButtressDivider
            style={{
              backgroundColor: "rgb(216, 216, 216)",
            }}
          />
        </DividerBox>
      )
    }
  }

  const chunkArray = () => {
    const result = []
    for (let i = 0; i < promotedJobs.length; i += 2) {
      result.push(promotedJobs.slice(i, i + 2))
    }
    return result
  }

  const chunkedPromotions = chunkArray()
  let lastPromotionIndex = 0

  return (
    <>
      <h1 style={{ position: "absolute", right: 9000 }}>
        Spectacular’s Architectural job board is the largest collection of
        Architecture jobs in the world
      </h1>
      <p style={{ position: "absolute", right: 9000 }}>
        Find your dream job on the largest job board in Architecture. New
        architecture jobs added daily including internships, entry-level
        architects, Project Architects, Building Information Modeling (BIM)
        designers, and more!
      </p>
      {isMobile ? (
        <>
          <MobileBox>
            <Box>
              <ListingTitle tabIndex={0} variant="h1">
                Architecture Job Openings
              </ListingTitle>
            </Box>
          </MobileBox>
          <Box
            style={{
              position: "sticky",
              zIndex: 100,
              boxShadow: "none",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              top: "56px",
              padding: "20px",
              backgroundColor: "#F7F7F7",
              boxSizing: "border-box",
            }}
          >
            <form onSubmit={handleSearch} style={{ display: "contents" }}>
              <Search
                src={MobileSearchIcon}
                alt={"search-icon"}
                onClick={handleSearch}
                data-testid="search-icon"
              />
              <MobileSearchBar
                style={{ background: "#fff" }}
                onChange={(e) => onTermChanged(e.target.value)}
                data-testid="search-bar"
                placeholder="Search jobs by keyword..."
              />
              <MobileFilterBox onClick={handleOpen}>
                <img src={FilterIcon} alt={"filter"} />
              </MobileFilterBox>
              <input
                type="submit"
                name="submit"
                data-testid="search-button"
                style={{ position: "fixed", marginLeft: "-10000px" }}
              />

              <FilterJobDialog
                open={openMobileFilter}
                onClose={handleClose}
                // onSetHasMore={handleChangeHasMore}
                // onChangeAction={onChangeAction}
                handleApply={handleApplyMobileFilters}
                workplaceType={workplaceType}
                employmentType={employmentType}
                experienceLevel={experienceLevel}
              />
            </form>
          </Box>
        </>
      ) : (
        <NewMainContainer
          $hasMargin={true}
          style={{
            backgroundColor: "#F7F7F7",
            paddingTop: "80px",
            paddingBottom: "40px",
          }}
        >
          <form onSubmit={handleSearch} style={{ display: "contents" }}>
            <Box>
              <ListingTitle tabIndex={0} variant="h1">
                Architecture Job Openings
              </ListingTitle>
            </Box>
            <SearchBoxArea>
              <img src={SearchIcon} alt={"search"} data-testid="search-icon" />
              <SearchBar
                value={term}
                placeholder={"Search for jobs, keywords, companies"}
                data-testid="search-bar"
                onChange={(e) => onTermChanged(e.target.value)}
              />
              <VerticalDivider />
              <SearchBar
                value={location}
                placeholder={"Location"}
                data-testid="search-bar-location"
                onChange={(e) => onLocationChanged(e.target.value)}
              />
              <Button
                type={"submit"}
                children={"Search"}
                color={"secondary"}
                size={"medium"}
                data-testid="search-button"
                style={{
                  minWidth: "126px",
                  height: "48px",
                  marginLeft: "24px",
                }}
              />
            </SearchBoxArea>
          </form>
          <FilterBox paddingTop={2}>
            <DropDownMultiselect
              actions={workplaceType}
              onApply={handleApplyFilters}
              title={"Workplace type"}
              type={"workplace-type"}
              reset={resetedValues}
            />

            <DropDownMultiselect
              actions={employmentType}
              onApply={handleApplyFilters}
              title={"Employment type"}
              type={"employment-type"}
              reset={resetedValues}
            />

            <DropDownMultiselect
              actions={experienceLevel}
              onApply={handleApplyFilters}
              title={"Experience level"}
              type={"experience-level"}
              reset={resetedValues}
            />
            {term ||
            location ||
            workplaceType.find((value) => value.active) ||
            employmentType.find((value) => value.active) ||
            experienceLevel.find((value) => value.active) ? (
              <ClearFilterBoxActive onClick={handleClearFilters}>
                <img src={ClearFilterActive} alt={"clear-filter"} />
                Clear Filters
              </ClearFilterBoxActive>
            ) : (
              <ClearFilterBox>
                <img src={ClearFilter} alt={"clear-filter"} />
                Clear Filters
              </ClearFilterBox>
            )}
          </FilterBox>
        </NewMainContainer>
      )}
      <NewMainContainer $hasMargin={false}>
        <JobMainGrid container spacing={0} style={{ paddingBottom: 0 }}>
          <Grid
            item
            style={
              isMobile
                ? { width: "100%" }
                : { width: "100%", paddingRight: "40px" }
            }
          >
            <CounterArea paddingTop={isMobile ? 2.5 : 5}>
              <CounterText>
                {`Showing ${jobsCount} ${
                  jobs?.length === 1 ? "result" : "results"
                }`}
              </CounterText>
            </CounterArea>
          </Grid>
          {!isMobile && (
            <Grid item style={{ minWidth: "368px", maxWidth: "368px" }}></Grid>
          )}
        </JobMainGrid>

        <JobMainGrid container spacing={0}>
          <Grid
            container
            direction="row"
            style={
              isMobile
                ? { width: "100%", flexDirection: "column", paddingTop: "40px" }
                : { width: "100%", paddingRight: "40px", paddingTop: "40px" }
            }
          >
            <InfiniteScroll
              dataLength={jobs.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<LoadingPage />}
              style={{ overflow: "hidden" }}
            >
              <Box style={{ width: "100%" }}>
                {jobs.map((job, index) => {
                  if (index % 10 === 0 && index > 0) {
                    lastPromotionIndex = lastPromotionIndex + 1
                  }
                  return (
                    <React.Fragment key={job.id!}>
                      {index % 10 === 0 && (
                        <>
                          {chunkedPromotions[lastPromotionIndex]?.map((job) => {
                            return (
                              <Box
                                key={`promoted-${job.id!}`}
                                style={{ width: "100%" }}
                              >
                                <JobCard
                                  job={job}
                                  profile={job.profile!}
                                  origin={"jobs-listing"}
                                />
                                <DividerBox>
                                  <LightButtressDivider
                                    style={{
                                      backgroundColor: "rgb(216, 216, 216)",
                                    }}
                                  />
                                </DividerBox>
                              </Box>
                            )
                          })}
                        </>
                      )}
                      <JobCard
                        job={job}
                        profile={job.profile!}
                        origin={"jobs-listing"}
                      />
                      {jobs.length - 1 !== index && (
                        <>
                          {isMobile ? (
                            MobileDivider(index + 1)
                          ) : (
                            <DividerBox>
                              <LightButtressDivider
                                style={{
                                  backgroundColor: "rgb(216, 216, 216)",
                                }}
                              />
                            </DividerBox>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </Box>
            </InfiniteScroll>
          </Grid>
          {!isMobile && (
            <JobAdArea item>
              {profile?.type === "group" ? (
                <>
                  <div style={{ paddingBottom: isMobile ? "16px" : "20px" }}>
                    <Ads
                      type={"promote-job"}
                      onClick={() => goToUrl("/dashboard")}
                    />
                  </div>
                  <Ads
                    type={"recruitment"}
                    onClick={() =>
                      window.open("https://www.spectacular.design/recruitment")
                    }
                  />
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: isMobile ? "16px" : "20px" }}>
                    <Ads
                      type={"promote-job"}
                      onClick={() => goToUrl("/pricing")}
                    />
                  </div>
                  <Ads
                    type={"recruitment"}
                    onClick={() =>
                      window.open("https://www.spectacular.design/recruitment")
                    }
                  />
                </>
              )}
            </JobAdArea>
          )}
        </JobMainGrid>
        <JobRequestDialog open={open} onClose={() => toggleOpen(false)} />
      </NewMainContainer>
    </>
  )
}

export default View
