import React, { useState } from "react"
import MUIRichTextEditor from "mui-rte"
import { Box, Grid } from "@material-ui/core"
import { useHistory } from "react-router-dom"

import RichTextEditor from "../../components/RichTextEditor"

import { Profile } from "../../redux/profile"

import {
  NullstateTab,
  ActionButton,
  RichTextEditorContainer,
  TabTitleText,
  AboutText,
  StyledLink,
} from "./styles"
import ContactInformationCard from "../../components/ContactInformationCard"
import AboutSelfSection from "./AboutSelfSection"
import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import ProfileNullstate from "../../components/ProfileNullstate"

interface EditModeProps {
  profile: Profile
  onEdit: (plainText: string, richText: string) => void
}
const EditMode = ({ profile, onEdit }: EditModeProps) => {
  const { width } = useWindowDimensions()
  const [richText, setRichText] = useState("")
  const [plainText, setPlainText] = useState("")

  return (
    <Box
      marginBottom={2}
      style={{
        paddingTop: width >= theme.breakpoints.values.lg ? "40px" : "24px",
        paddingBottom: width >= theme.breakpoints.values.lg ? "" : "60px",
        paddingLeft: 0,
      }}
    >
      <TabTitleText>About</TabTitleText>

      <Box
        marginTop={width >= theme.breakpoints.values.md ? 2.5 : "15px"}
        style={{
          width: "100%",
          color: "#2d2d2d",
          lineHeight: "1.375",
        }}
      >
        <RichTextEditorContainer>
          <RichTextEditor
            label="Type bio here..."
            defaultValue={profile.aboutRft!}
            data-testid="description"
            onChange={(plain, rich) => {
              setRichText(rich)
              setPlainText(plain)
            }}
          />
        </RichTextEditorContainer>
      </Box>
      <Box paddingTop={2.5}>
        <ActionButton
          color="primary"
          onClick={() => onEdit(plainText, richText)}
          data-testid="edit-about-section"
        >
          Save about
        </ActionButton>
      </Box>
    </Box>
  )
}

interface Props {
  profile: Profile
  authenticatedProfile: Profile
  isPublicProfile: boolean
  isGroupProfile?: boolean
  isConnected?: boolean
  isOfflineUser?: boolean
  onEditAbout: (plainText: string, richText: string) => Promise<void>
}

const AboutDetails = ({
  profile,
  authenticatedProfile,
  onEditAbout,
  isPublicProfile,
  isGroupProfile,
  isConnected,
  isOfflineUser,
}: Props) => {
  const [isEditMode, setEditMode] = useState(false)
  const [displayText, setDisplayText] = useState(profile.about?.substr(0, 450))

  const { width } = useWindowDimensions()

  const history = useHistory()

  const toggleEditMode = () => setEditMode(!isEditMode)

  const onEdit = async (plainText: string, richText: string) => {
    await onEditAbout(plainText, richText)
    toggleEditMode()
  }

  const handleReadMore = () => {
    setDisplayText(profile.about)
  }

  const goToUrl = (url: string) => history.push(url)

  const nullstateAction = () => {
    authenticatedProfile
      ? goToUrl(`/sp/${authenticatedProfile.username}`)
      : goToUrl("/register")
  }

  const aboutRft =
    profile.aboutRft && JSON.parse(profile.aboutRft)?.blocks.length > 1
      ? "filled"
      : (profile.aboutRft && JSON.parse(profile.aboutRft)?.blocks[0].text) || ""

  if (
    (!profile.about || aboutRft === "") &&
    !profile.tempAbout &&
    !isEditMode
  ) {
    return (
      <Box style={{ padding: 0 }}>
        {!isPublicProfile ? (
          <NullstateTab
            profileType={profile.type}
            style={{
              paddingBottom: width >= theme.breakpoints.values.sm ? "22px" : 0,
            }}
          >
            <ProfileNullstate
              onClick={toggleEditMode}
              type={
                profile.type === "group"
                  ? "group-owner-about-tab"
                  : "owner-about-tab"
              }
            />
          </NullstateTab>
        ) : (
          <NullstateTab
            profileType={profile.type}
            style={{
              paddingBottom: width >= theme.breakpoints.values.sm ? "22px" : 0,
            }}
          >
            <ProfileNullstate
              onClick={nullstateAction}
              type={profile.type === "group" ? "group-about-tab" : "about-tab"}
            />
          </NullstateTab>
        )}

        {width < theme.breakpoints.values.md && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: width >= theme.breakpoints.values.sm ? "10px" : "",
            }}
          >
            <AboutSelfSection profile={profile} isConnected={isConnected} />
          </Grid>
        )}
      </Box>
    )
  }

  if (isEditMode) return <EditMode profile={profile} onEdit={onEdit} />

  return (
    <Box
      style={{
        padding: 0,
        paddingTop: width >= theme.breakpoints.values.lg ? "40px" : "24px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={width <= 1070 || profile.type === "group" ? 12 : 7}
        >
          <Box
            style={{
              paddingRight:
                width >= theme.breakpoints.values.md ? "32px" : "0px",
            }}
          >
            <TabTitleText>About</TabTitleText>

            {profile.tempAbout ? (
              profile.tempAbout
            ) : (
              <>
                {width >= theme.breakpoints.values.sm ? (
                  <Box
                    marginTop={
                      width >= theme.breakpoints.values.md ? 2.5 : "15px"
                    }
                    style={{
                      width: "100%",
                      color: "#2d2d2d",
                      lineHeight: "1.375",
                    }}
                  >
                    <MUIRichTextEditor
                      controls={[]}
                      readOnly={true}
                      defaultValue={
                        profile.aboutRft === null ||
                        profile.aboutRft === "null" ||
                        profile.aboutRft === undefined
                          ? ""
                          : profile.aboutRft
                      }
                    />
                  </Box>
                ) : (
                  <>
                    <AboutText>{displayText}</AboutText>
                    {profile.about?.length! > displayText?.length! ? (
                      <>
                        <StyledLink onClick={handleReadMore}>
                          Read More
                        </StyledLink>
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}

            {isPublicProfile ? null : (
              <Box
                paddingTop={2.5}
                style={{
                  paddingBottom:
                    width >= theme.breakpoints.values.sm ? "22px" : 0,
                }}
              >
                <ActionButton
                  color="primary"
                  onClick={toggleEditMode}
                  data-testid="edit-about-section"
                >
                  Edit about
                </ActionButton>
              </Box>
            )}
          </Box>
        </Grid>

        {width < theme.breakpoints.values.md && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: width >= theme.breakpoints.values.sm ? "10px" : "",
            }}
          >
            <AboutSelfSection profile={profile} isConnected={isConnected} />
          </Grid>
        )}

        {profile.type !== "group" &&
          isPublicProfile &&
          !isGroupProfile &&
          !isOfflineUser &&
          width >= theme.breakpoints.values.md && (
            <Grid item xs={12} md={width <= 1070 ? 12 : 5}>
              <ContactInformationCard
                profile={profile}
                isConnected={isConnected}
              />
            </Grid>
          )}
      </Grid>
    </Box>
  )
}

export default AboutDetails
