import React from "react"
import Image from "material-ui-image"
import { Box, Grid, Typography } from "@material-ui/core"

import CoverSrc from "../../images/nominated-cover.jpeg"
import OrthoSrc from "../../images/ortho.jpg"
import {
  MainContainer,
  TextContainer,
  Text,
} from "../../components/Styles/StaticPages"

const View = () => (
  <MainContainer>
    <Image src={CoverSrc} aspectRatio={16 / 9} disableSpinner />
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <Box pb={4}>
            <Typography variant="h1">Featured Projects</Typography>
          </Box>
          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            Spectacular’s featured projects are crowdsourced from architects and
            designers around the world. Our team of curators, led by Marc Teer,
            FAIA, review each and every nomination weekly. We take your
            nominations seriously and encourage you to nominate the work you
            most admire.
          </Text>

          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            Along with being showcased on our homepage, featured projects will
            also be included in our daily and weekly newsletters. There is no
            limit to how many projects you can nominate, and you can even
            nominate your own project. We’re looking for projects that are
            striking and original.
          </Text>

          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            Our curation team strives to create a collection of the most
            inspiring projects from architects and designers in all stages of
            their careers. We feature student, conceptual and built
            projects.While we don’t have rigid criteria for what gets featured
            on Spectacular, there are a few requirements a project must meet:
          </Text>

          <div>
            <ul>
              <li>
                The project must be uploaded to a completed Spectacular profile.
                Double check that the experience and bio tabs are filled out on
                your profile.
              </li>
              <li>
                A featured project must include a minimum of five stunning
                images.
              </li>
              <li>
                Image credit must be provided where relevant. Be sure to credit
                photographers and firms involved in your project.
              </li>
            </ul>
          </div>

          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            Projects that are featured on Spectacular should have orthogonal
            drawings that follow these standards:
          </Text>

          <div>
            <ul>
              <li>
                Provide a hierarchy of line weights that create depth within the
                drawing.
              </li>
              <li>
                Include adequate white space around the drawing, creating a well
                proportioned composition.
              </li>
              <li>
                Provide drawings that aren't inundated with annotations. Use a
                number labeling system when applicable. See our example{" "}
                <a href={OrthoSrc} target="_blank" rel="noreferrer">
                  here
                </a>
              </li>
              <li>
                Strategic use of color or greyscale is encouraged but not
                necessary.
              </li>
              <li>
                Drawings should feature crisp lines; use high-quality files that
                haven't been reduced in size.
              </li>
            </ul>
          </div>

          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            We generally prefer presentation drawings as opposed to contract
            document style drawings.
          </Text>

          <Text py={2} style={{ fontFamily: "Public-Sans" }}>
            All nominations will be considered. The more nominations a project
            receives, the better. If your project gets selected, we’ll be in
            touch. If you have any questions, please contact:{" "}
            <a href="mailto:hello@spectacular.design">
              hello@spectacular.design
            </a>
            .
          </Text>

          <Box py={2}></Box>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
