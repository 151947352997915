import React, { useState } from "react"

import View from "./View"

type Props = {
  initialAwards?: string[]
  onChange: (values: string[]) => void
}

const ProfileAwards = ({ initialAwards, onChange }: Props) => {
  const [value, setValue] = useState<string>("")
  const [displayError, setDisplayError] = useState<boolean>(false)
  const [awards, setAwards] = useState<string[]>(initialAwards || [])

  const saveSkill = async (awards: string[]) => {
    setAwards(awards)
    onChange(awards)
    setDisplayError(false)
  }

  const onInputChange = (value: string) => setValue(value)

  const onAdd = (newSkill: string) => {
    saveSkill([...awards, newSkill])
    setValue("")
  }

  const onDelete = (groupToDelete: string) =>
    saveSkill(awards.filter((group: string) => group !== groupToDelete))

  return (
    <View
      value={value}
      awards={awards}
      suggestions={[]}
      onEnteredPressed={onAdd}
      onDeletedPressed={onDelete}
      displayError={displayError}
      onInputChange={onInputChange}
    />
  )
}

export default ProfileAwards
