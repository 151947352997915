import React, { useCallback, useEffect, useState } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { useSelector } from "react-redux"
import {
  DropDownBox,
  NoResultsBox,
  NoResultsShortcut,
  NoResultsShortcuts,
  NoResultsText,
  NoResultsTitle,
  PlaceholderProjectCard,
  ProjectsListBox,
  ProjectsListCardBox,
  ProjectsListContainer,
} from "./styles"
import LoadingPage from "../LoadingPage"
import { Post, getPosts } from "../../redux/posts"
import PostCard from "../../cards/PostCard"
import InfiniteScroll from "react-infinite-scroll-component"
import DropDownHomepage from "../../components/DropDownHomepage"

interface Props {
  email?: string
  textFilter: string
  myNetworkFilter: boolean
  page: number
  onChangePage: (value: number) => void
  onShortcutClick: (value: string) => void
  onUpdateSortBy: (value: string) => void
  sortBy: string
}

const PostsList = ({
  email,
  textFilter,
  myNetworkFilter,
  page,
  onChangePage,
  onShortcutClick,
  onUpdateSortBy,
  sortBy,
}: Props) => {
  const dispatch = useAppDispatch()

  const posts = useSelector((state: any) => state.posts.allPosts) as Post[]
  const postsCount = useSelector((state: any) => state.posts.count)
  const newPostsCount = useSelector((state: any) => state.posts.newCount)

  const checkCount = page === 1 ? 0 : newPostsCount

  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const loadProjects = useCallback(async () => {
    try {
      setIsLoading(true)
      const search = textFilter
      const { type, payload } = await dispatch(
        getPosts({
          email,
          page,
          search,
          myNetwork: myNetworkFilter,
          sortBy,
        })
      )

      if (getPosts.fulfilled.type === type) {
        if (checkCount >= payload.count) setHasMore(false)
        setIsLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [dispatch, email, page, textFilter, myNetworkFilter, sortBy])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  const sortActions = [
    {
      action: "recent",
      display: "Most Recent",
    },
    {
      action: "oldest",
      display: "Oldest",
    },
  ]

  return (
    <ProjectsListContainer>
      <ProjectsListBox>
        <DropDownBox>
          <DropDownHomepage
            actions={sortActions}
            onClick={onUpdateSortBy}
            title={"Sort"}
          />
        </DropDownBox>
        <InfiniteScroll
          dataLength={posts.length}
          next={() => onChangePage(page + 1)}
          hasMore={hasMore}
          loader={<LoadingPage />}
          style={{ overflow: "hidden" }}
          scrollThreshold={0.8}
        >
          <ProjectsListCardBox>
            {posts.map((post) => {
              return <PostCard post={post} isHomepage={true} key={post.id} />
            })}
            {!isEven(postsCount) && <PlaceholderProjectCard />}
          </ProjectsListCardBox>
        </InfiniteScroll>

        {!isLoading && postsCount === 0 && (
          <NoResultsBox>
            <NoResultsTitle variant="h2">No results found.</NoResultsTitle>
            <NoResultsText>
              Sorry, we couldn’t find any results for this search.
              <br />
              Try shortening or rephrasing your search.
            </NoResultsText>
            <NoResultsShortcuts>
              <NoResultsShortcut onClick={() => onShortcutClick("Concrete")}>
                Concrete
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Photography")}>
                Photography
              </NoResultsShortcut>
              <NoResultsShortcut
                onClick={() => onShortcutClick("Interior Design")}
              >
                Interior Design
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Chicago")}>
                Chicago
              </NoResultsShortcut>
            </NoResultsShortcuts>
          </NoResultsBox>
        )}
      </ProjectsListBox>
    </ProjectsListContainer>
  )
}

export default PostsList
