import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { Profile, ProfileProject } from "./profile"
import { Post } from "./posts"

//#region types
export type Notification = {
  id: string
  profileId: string
  sourceProfileId?: string
  sourceOriginId?: string
  origin: string
  state: string
  description?: string
  comment: string
  createdAt?: string
  updatedAt?: string
  profile?: Profile
  project?: ProfileProject
  post?: Post
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  allNotifications?: Notification[]
  notifications?: Notification[]
  notificationsCount: number
  notificationsUnreadCount: number
  connectionsUnderadCount: number
  notificationStatus: LoadingStatuses
  notificationCurrentPage?: number
  allRequests?: Notification[]
  requests?: Notification[]
  requestsCount: number
  requestStatus: LoadingStatuses
  requestCurrentPage?: number
}
//#endregion

//#region api
type FetchNotificationsPayload = {
  auth: Auth0ContextInterface
  currentPage?: number
  reset?: boolean
  notificationState?: string
}
export const fetchNotifications = createAsyncThunk<
  any,
  FetchNotificationsPayload
>(
  "profile/fetchNotifications",
  async ({ auth, currentPage, reset, notificationState }) => {
    const skip = reset ? 0 : currentPage! * 10
    return useApi(
      auth,
      `/profile/notifications?skip=${skip}&limit=10&state=${notificationState}`,
      {
        method: "GET",
      }
    ).then(async (res) => {
      return await res.json()
    })
  }
)
type updateNotificationsStatePayload = {
  auth: Auth0ContextInterface
  profileId?: string
  state?: string
  type?: string
}
export const updateNotificationsState = createAsyncThunk<
  any,
  updateNotificationsStatePayload
>(
  "notifications/updateNotificationsState",
  async ({ auth, profileId, state, type }) => {
    return useApi(auth, `/notifications-bulk-update/${profileId}`, {
      method: "PATCH",
      body: JSON.stringify({
        state,
        type,
      }),
    }).then((res) => res.text())
  }
)
type UpdateNotificationStateByIdStatePayload = {
  auth: Auth0ContextInterface
  notificationId?: string
  state?: string
}
export const updateNotificationStateById = createAsyncThunk<
  any,
  UpdateNotificationStateByIdStatePayload
>(
  "notifications/updateNotificationStateById",
  async ({ auth, notificationId, state }) => {
    return useApi(auth, `/notifications/${notificationId}`, {
      method: "PATCH",
      body: JSON.stringify({
        state,
      }),
    }).then((res) => res.text())
  }
)

type FetchRequestsPayload = {
  auth: Auth0ContextInterface
  currentPage?: number
  notificationState?: string
}
export const fetchRequests = createAsyncThunk<any, FetchRequestsPayload>(
  "profile/fetchRequests",
  async ({ auth, currentPage, notificationState }) => {
    const skip = currentPage! * 10

    return useApi(
      auth,
      `/profile/requests?skip=${skip}&limit=10&state=${notificationState}`,
      {
        method: "GET",
      }
    ).then(async (res) => {
      return await res.json()
    })
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  notifications: [],
  allNotifications: [],
  notificationsCount: 0,
  notificationsUnreadCount: 0,
  connectionsUnderadCount: 0,
  notificationStatus: LoadingStatuses.Idle,
  notificationCurrentPage: 0,
  requests: [],
  allRequests: [],
  requestsCount: 0,
  requestStatus: LoadingStatuses.Idle,
  requestCurrentPage: 0,
}

export default createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.data
      state.allNotifications =
        action.meta.arg.reset || action.meta.arg.currentPage === 0
          ? action.payload.data
          : state.allNotifications?.concat(action.payload.data)
      state.notificationsCount = action.payload.count
      state.notificationsUnreadCount = action.payload.unreadCount
      state.connectionsUnderadCount = action.payload.connectionsUnderadCount
      state.notificationStatus = LoadingStatuses.Succeeded
      state.notificationCurrentPage =
        action.meta.arg.reset || action.meta.arg.currentPage === 0
          ? 0
          : action.meta.arg.currentPage
    })
    builder.addCase(updateNotificationsState.fulfilled, (state, action) => {
      if (state.notifications) {
        if (action.meta.arg.type !== "connection") {
          const updatedNotifications = [...state.notifications]
          updatedNotifications.forEach((notification) => {
            if (notification.state !== "clicked") notification.state = "read"
          })
          state.notifications = updatedNotifications
          state.notificationsUnreadCount = 0
        } else {
          state.connectionsUnderadCount = 0
        }
      }

      if (state.allNotifications) {
        const updatedAllNotifications = [...state.allNotifications]
        updatedAllNotifications.forEach((notification) => {
          if (notification.state !== "clicked") notification.state = "read"
        })
        state.allNotifications = updatedAllNotifications
      }
    })
    builder.addCase(fetchRequests.fulfilled, (state, action) => {
      state.requests = action.payload.data
      state.allRequests =
        action.meta.arg.currentPage === 0
          ? action.payload.data
          : state.allRequests?.concat(action.payload.data)
      state.requestsCount = action.payload.count
      state.requestStatus = LoadingStatuses.Succeeded
      state.requestCurrentPage =
        action.meta.arg.currentPage === 0 ? 0 : action.meta.arg.currentPage
    })
  },
})
//#endregion
