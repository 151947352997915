import React, { MouseEvent, useState } from "react"
import { DotButton, Triangle, MenuItem, MenuBox } from "./Styles"
import { Popover } from "@material-ui/core"
import DotIcon from "../../images/post-icon-menu.svg"
import EditIcon from "../../images/post-icon-edit.svg"
import DeleteIcon from "../../images/post-icon-delete.svg"

export type Props = {
  onDelete: () => void
  onEdit: () => void
}

const View = ({ onDelete, onEdit }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const deletePost = () => {
    onDelete()
    handleClose()
  }

  const editPost = () => {
    onEdit()
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DotButton onClick={handleClick}>
        <img src={DotIcon} alt={"dot menu"} />
      </DotButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            marginLeft: "15px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          <MenuItem onClick={editPost}>
            <img src={EditIcon} alt={"Edit"} />
            <span>Edit Comment</span>
          </MenuItem>

          <MenuItem onClick={deletePost}>
            <img src={DeleteIcon} alt={"Delete"} />
            <span>Delete Comment</span>
          </MenuItem>
        </MenuBox>
      </Popover>
    </>
  )
}

export default View
