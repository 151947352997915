import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
//#region types

export interface News {
  id: string
  title: string
  description: string
  url: string
  author: string
  imageUrl?: string
  date?: Date
  origin: NewsOrigin
  published?: boolean
  liked?: boolean
  shared?: boolean
  likeCount?: number
  shareCount?: number
}

export interface NewsOrigin {
  name: string
  url: string
  logo: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  newsList: News[]
  news: News | null | undefined
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetNewsListPayload = {
  auth: Auth0ContextInterface
  status?: string
  search?: string
}
export const fetchNewsList = createAsyncThunk<any, GetNewsListPayload>(
  "news/admin/list",
  async ({ auth, status, search }) => {
    let endpoint = `/admin/news?status=${status}`
    if (search) {
      endpoint += `&search=${search}`
    }

    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type GetNewsPayload = {
  id: string
  auth: Auth0ContextInterface
}
export const fetchNews = createAsyncThunk<any, GetNewsPayload>(
  "news/admin/get",
  async ({ auth, id }) => {
    return useApi(auth, `/admin/news/${id}`).then((res) => res.json())
  }
)

type UpdateNewsPayload = {
  auth: Auth0ContextInterface
  newsId: string
  published?: boolean
  imageUrl?: string
  description?: string
  title?: string
}
export const updateNews = createAsyncThunk<any, UpdateNewsPayload>(
  "news/admin/update",
  async ({ auth, newsId, published, imageUrl, description, title }) => {
    const body = {
      published,
      imageUrl,
      description,
      title,
    }

    return useApi(auth, `/admin/news/${newsId}`, {
      method: "PATCH",
      body: JSON.stringify(body),
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  newsList: [],
  news: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "adminNews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsList.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.newsList = action.payload
        state.error = null
      })
      .addCase(fetchNewsList.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(fetchNewsList.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.newsList = []
        state.error = null
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.news = action.payload
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.news = null
        state.error = action.error.message
      })
      .addCase(fetchNews.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.news = null
        state.error = null
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.error = null
        let newsList = [...state.newsList]
        newsList = newsList.map((news) => {
          if (news.id === action.meta.arg.newsId) {
            news.imageUrl = action.meta.arg.imageUrl
            news.published = action.meta.arg.published

            if (action.meta.arg.title) news.title = action.meta.arg.title

            if (action.meta.arg.description)
              news.description = action.meta.arg.description
          }

          return news
        })

        state.newsList = newsList
      })
      .addCase(updateNews.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion

//#region selectors
export const selectNewsList = ({ adminNews }: RootState) => adminNews.newsList
export const selectNews = ({ adminNews }: RootState) => adminNews.news
//#endregion
