export const getStyles = (type?: string, disabled?: boolean) => {
  if (disabled) return disabledStyle
  if (type === "white") return white
  else if (type === "whiteGreyBorder") return whiteGreyBorder
  else if (type === "lightGrey") return lightGrey
  else if (type === "mediumGrey") return mediumGrey
  else if (type === "secondary") return secondary
  else if (type === "secondaryHover") return secondaryHover
  else if (type === "primary") return primary
  else if (type === "primaryHover") return primaryHover
  else if (type === "lightBlue") return lightBlue
  else if (type === "lightBlueHover") return lightBlueHover
  else if (type === "red") return red
  else if (type === "redHover") return redHover
  else if (type === "whiteBlueBorder") return whiteBlueBorder
  else if (type === "whiteBlueBorderHover") return whiteBlueBorderHover
  else if (type === "darkGrey") return darkGrey
  else if (type === "darkGreyHover") return darkGreyHover
  else if (type === "blueBorder") return blueBorder
  else if (type === "blueBorderHover") return blueBorderHover
  else if (type === "darkWhiteBorder") return darkWhiteBorder
  else if (type === "darkWhiteBorderHover") return darkWhiteBorderHover

  return { border: "1px solid black" }
}

export const getHoverClass = (type?: string) => {
  if (type === "white") return "whiteGreyBorder"
  else if (type === "whiteGreyBorder") return "white"
  else if (type === "lightGrey") return "mediumGrey"
  else if (type === "secondary") return "secondaryHover"
  else if (type === "primary") return "primaryHover"
  else if (type === "lightBlue") return "lightBlueHover"
  else if (type === "red") return "redHover"
  else if (type === "whiteBlueBorder") return "whiteBlueBorderHover"
  else if (type === "darkGrey") return "darkGreyHover"
  else if (type === "blueBorder") return "blueBorderHover"
  else if (type === "darkWhiteBorder") return "darkWhiteBorderHover"
}

type ButtonColorProperties = {
  backgroundColor?: string
  color?: string
  border?: string
  fontFamily?: string
  background?: string
}

const red: ButtonColorProperties = {
  backgroundColor: "#F44C49",
  color: "#fff",
  fontFamily: "Public-Sans-Bold",
}

const redHover: ButtonColorProperties = {
  backgroundColor: "#E8403D",
  color: "#fff",
  fontFamily: "Public-Sans-Bold",
}

const white: ButtonColorProperties = {
  backgroundColor: "white",
  border: "1px solid #2D2D2D",
  fontFamily: "Public-Sans-Bold",
}

const whiteGreyBorder: ButtonColorProperties = {
  backgroundColor: "white",
  border: "1px solid #D8D8D8",
  color: "#717171",
  fontFamily: "Public-Sans-Bold",
}

const whiteBlueBorder: ButtonColorProperties = {
  backgroundColor: "#F2F2F2",
  border: "1px solid #3057E1",
  color: "#3057E1",
  fontFamily: "Public-Sans-Bold",
}

const whiteBlueBorderHover: ButtonColorProperties = {
  backgroundColor: "#FFFFFF",
  border: "1px solid #1C43CD",
  color: "#1C43CD",
  fontFamily: "Public-Sans-Bold",
}

const lightGrey: ButtonColorProperties = {
  color: "#979797",
  backgroundColor: "#f7f7f7",
  fontFamily: "Public-Sans-Bold",
}

const mediumGrey: ButtonColorProperties = {
  color: "#979797",
  backgroundColor: "#eaeaea",
  fontFamily: "Public-Sans-Bold",
}

const secondary: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#3057E1",
  fontFamily: "Public-Sans-Bold",
}

const secondaryHover: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#1C43CD",
  fontFamily: "Public-Sans-Bold",
}

const primary: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#2D2D2D",
  border: "0",
  fontFamily: "Public-Sans-Bold",
}

const primaryHover: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#000000",
  border: "0",
  fontFamily: "Public-Sans-Bold",
}

const darkWhiteBorder: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#2D2D2D",
  border: "1px solid #fff",
  fontFamily: "Public-Sans-Bold",
}

const darkWhiteBorderHover: ButtonColorProperties = {
  color: "#FFFFFF",
  backgroundColor: "#000000",
  border: "1px solid #fff",
  fontFamily: "Public-Sans-Bold",
}

const lightBlue: ButtonColorProperties = {
  color: "#2D2D2D",
  backgroundColor: "#EBEFFC",
  fontFamily: "Public-Sans-Bold",
}

const lightBlueHover: ButtonColorProperties = {
  color: "#2D2D2D",
  backgroundColor: "#DDE3F8",
  fontFamily: "Public-Sans-Bold",
}

const disabledStyle: ButtonColorProperties = {
  background: "white",
  color: "rgba(0, 0, 0, 0.26)",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  fontFamily: "Public-Sans-Bold",
}

const darkGrey: ButtonColorProperties = {
  color: "#fff",
  backgroundColor: "#C4C4C4",
  fontFamily: "Public-Sans-Bold",
}

const darkGreyHover: ButtonColorProperties = {
  color: "#fff",
  backgroundColor: "#2d2d2d",
  fontFamily: "Public-Sans-Bold",
}

const blueBorder: any = {
  color: "#3057E1",
  border: "1px solid #3057E1",
  backgroundColor: "#FFFFFF",
  fontWeight: 700,
}

const blueBorderHover: any = {
  color: "#1C43CD",
  border: "1px solid #1C43CD",
  backgroundColor: "#FFFFFF",
  fontWeight: 700,
}
