import React from "react"

import {
  WinnersBox,
  WinnersBoxBordered,
  WinnersContainer,
  WinnersHeader,
  WinnersProjectsBox,
  WinnersProjectsBoxSingleRow,
  WinnersTitle,
} from "./styles"

import StudentIcon from "../../images/innovation-awards/ia-winners-student.png"
import ProfessionalIcon from "../../images/innovation-awards/ia-winners-professional.png"
import WinnersProjectCard from "./WinnersProjectCard"
import { Competition } from "../../redux/competition"

interface Props {
  competition: Competition
}

const GridView = ({ competition }: Props) => {
  return (
    <>
      <WinnersBoxBordered id={"jury"}>
        <WinnersContainer>
          <WinnersTitle variant={"h1"}>
            Winners | {competition.date}
          </WinnersTitle>
        </WinnersContainer>
      </WinnersBoxBordered>
      <WinnersBox>
        {competition.title.includes("Weekend Competition") ||
        competition.title.includes("Studio Awards") ? (
          <>
            <WinnersContainer>
              <WinnersProjectsBoxSingleRow $hasPaddingTop={true}>
                {competition.winners.winners.map((project) => {
                  return (
                    <WinnersProjectCard
                      project={project}
                      singleRow={true}
                      showSchool={competition.title.includes("Studio Awards")}
                    />
                  )
                })}
              </WinnersProjectsBoxSingleRow>
            </WinnersContainer>
          </>
        ) : (
          <>
            <WinnersContainer>
              <WinnersHeader>
                <img src={StudentIcon} alt={"Category Student"} />
              </WinnersHeader>
              <WinnersProjectsBox>
                {competition.winners.student.map((project) => {
                  return <WinnersProjectCard project={project} />
                })}
              </WinnersProjectsBox>
              <WinnersHeader>
                <img src={ProfessionalIcon} alt={"Category Student"} />
              </WinnersHeader>
              <WinnersProjectsBox>
                {competition.winners.professional.map((project) => {
                  return <WinnersProjectCard project={project} />
                })}
              </WinnersProjectsBox>
            </WinnersContainer>
          </>
        )}
      </WinnersBox>
    </>
  )
}

export default GridView
