import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../../redux/configureStore"
import { match } from "react-router-dom"
import { resolveImageDimensions } from "../../../utils"
import {
  clearState,
  fetchProfile,
  selectProfile,
  updateProfile,
} from "../../../redux/adminProfiles"
import {
  availableEmailCheck,
  authorizedUserNameCheck,
  Profile,
} from "../../../redux/profile"
import { getS3SignedURL } from "../../../redux/projects"
import View from "./View"
import LoadingPage from "../../LoadingPage"
import { useAuth0 } from "@auth0/auth0-react"
import { EditableSpotlight } from "../../../components/AdminFirmSpotlightRow"

import {
  createSpotlight,
  updateSpotlight,
  deleteSpotlight,
} from "../../../redux/adminSpotlight"

interface MatchParams {
  username: string
}
export interface Props {
  match: match<MatchParams>
}

const AdminProfileEditPage = ({ match }: Props) => {
  const auth = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const profile = useSelector(selectProfile)
  const username = match.params.username
  const email = profile?.contactEmail!
  let message = ""
  const [mounted, setMounted] = useState(false)
  const [usernameValue, setUsernameValue] = useState(username)
  const [emailValue, setEmailValue] = useState(email)
  const [usernameCheck, setUsernameCheck] = useState(message)
  const [emailCheck, setEmailCheck] = useState(message)
  const [disableSubmit, setDisableSubmit] = useState(false)

  let isGroup = false
  if (profile?.type === "group") isGroup = true
  const [groupProfile, setGroupProfile] = useState(isGroup)
  const handleCheckUsernameAvailability = async (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const updatedUsernameValue = event.target.value
    setUsernameValue(updatedUsernameValue)
    if (updatedUsernameValue) {
      const minChar = 4
      const maxChar = 50
      let profileType = "Group sub-domain"
      if (profile?.type !== "group") profileType = "Username"
      if (
        minChar <= updatedUsernameValue.length &&
        updatedUsernameValue.length <= maxChar
      ) {
        const usernameRegex = /^[a-z0-9-_]+$/
        if (usernameRegex.test(updatedUsernameValue)) {
          try {
            const userid = profile?.connectionId
            const { payload } = await dispatch(
              authorizedUserNameCheck({
                auth,
                id: userid as string,
                username: updatedUsernameValue,
              })
            )
            if (payload.error) {
              setUsernameCheck(
                "This " + profileType.toLowerCase() + " is unavailable"
              )
              setDisableSubmit(true)
            } else {
              setUsernameCheck("")
              setDisableSubmit(false)
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          setUsernameCheck(
            profileType +
              " must contain only lowercase alphanumeric characters with hyphen or underscores."
          )
          setDisableSubmit(true)
        }
      } else {
        setUsernameCheck(
          profileType + " should be between 4 and 50 characters."
        )
        setDisableSubmit(true)
      }
    }
  }

  const handleCheckEmailAvailability = async (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const updatedEmailValue = event.target.value
    setEmailValue(updatedEmailValue)
    if (updatedEmailValue) {
      try {
        const { payload } = await dispatch(
          availableEmailCheck({ auth, email: updatedEmailValue })
        )
        if (payload.error) {
          setEmailCheck((message = payload.error))
          setDisableSubmit(true)
        } else {
          setEmailCheck("")
          setDisableSubmit(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const [spotlightList, setSpotlightList] = useState<EditableSpotlight[]>([])

  const handleAddSpotlight = () => {
    const newSpotlight: EditableSpotlight = {
      id: `new-${spotlightList.length}`,
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      profileId: profile?.id,
      editing: true,
    }
    setSpotlightList([newSpotlight, ...spotlightList])
  }

  const handleSaveSpotlight = async (spotlight: EditableSpotlight) => {
    const reduxThunk = spotlight.id!.startsWith("new-")
      ? createSpotlight
      : updateSpotlight
    try {
      const { type, payload } = await dispatch(reduxThunk({ auth, spotlight }))
      if (reduxThunk.fulfilled.type === type) {
        const updatedSpotlight = spotlightList.map((profileSpotlight) =>
          profileSpotlight.id === spotlight.id ? payload : profileSpotlight
        )
        setSpotlightList(updatedSpotlight)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleDeleteSpotlight = async (spotlight: EditableSpotlight) => {
    try {
      const { type } = await dispatch(
        deleteSpotlight({ auth, id: spotlight.id! })
      )
      if (deleteSpotlight.fulfilled.type === type) {
        const updatedSpotlight = spotlightList.filter(
          (profileSpotlight) => profileSpotlight.id !== spotlight.id
        )
        setSpotlightList(updatedSpotlight)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnCancel = () => {
    history.push("/admin/profiles")
  }

  const handleOnSubmit = async (editedProfile: Profile) => {
    //Send a request to the API to get a signed S3 url for the image
    if (editedProfile.file) {
      const file = editedProfile.file
      const { width, height } = await resolveImageDimensions(file)

      const signedURL = await dispatch(
        getS3SignedURL({
          auth,
          width,
          height,
          order: 0,
          contentId: "",
          uploadType: "profile",
          fileName: editedProfile.file.name,
          fileType: editedProfile.file.type,
          profileId: editedProfile.id,
        })
      )

      //Upload the image to the S3 bucket
      const myHeaders = new Headers()
      myHeaders.append("Content-Type", editedProfile.file.type)

      await fetch(signedURL.payload, {
        method: "PUT",
        headers: myHeaders,
        body: editedProfile.file,
      })
    }

    if (editedProfile.bannerFile) {
      const file = editedProfile.bannerFile
      const { width, height } = await resolveImageDimensions(file)

      const signedURL = await dispatch(
        getS3SignedURL({
          auth,
          width,
          height,
          order: 0,
          contentId: "",
          uploadType: "banner",
          fileName: editedProfile.bannerFile.name,
          fileType: editedProfile.bannerFile.type,
          profileId: editedProfile.id,
        })
      )

      //Upload the image to the S3 bucket
      const myHeaders = new Headers()
      myHeaders.append("Content-Type", editedProfile.bannerFile.type)

      await fetch(signedURL.payload, {
        method: "PUT",
        headers: myHeaders,
        body: editedProfile.bannerFile,
      })
    }

    let writeableProfile = JSON.parse(JSON.stringify(editedProfile))
    writeableProfile.username = usernameValue
    writeableProfile.contactEmail = emailValue
    const id = writeableProfile?.connectionId
    //One final check in case someone took the username while updating
    try {
      const { payload } = await dispatch(
        authorizedUserNameCheck({ auth, id, username: usernameValue })
      )
      if (payload.error) {
        setUsernameCheck((message = payload.error))
        setDisableSubmit(true)
      } else {
        setUsernameCheck("")
        setDisableSubmit(false)
      }
    } catch (error) {
      console.error(error)
    }
    if (groupProfile) writeableProfile.type = "group"
    else writeableProfile.type = "individual"

    try {
      const resultAction = await dispatch(
        updateProfile({ profile: writeableProfile, auth })
      )
      if (updateProfile.fulfilled.type === resultAction.type) {
        history.push("/admin/profiles")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleConvertProfile = async (type: boolean) => {
    setGroupProfile(type)
  }
  useEffect(() => {
    if (!mounted) {
      dispatch(clearState())
      setMounted(true)
    }
    setUsernameValue(usernameValue)
    setEmailValue(email)
    ;(async () => {
      try {
        await dispatch(fetchProfile({ username, auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth, username, usernameValue, email, mounted])

  useEffect(() => {
    if (profile?.spotlights) setSpotlightList(profile.spotlights)
  }, [profile])

  if (!profile) {
    return <LoadingPage />
  }

  return (
    <View
      profile={profile}
      onSubmit={handleOnSubmit}
      onCancel={handleOnCancel}
      disableSubmit={disableSubmit}
      usernameCheck={usernameCheck}
      emailCheck={emailCheck}
      usernameValue={usernameValue}
      emailValue={emailValue}
      onCheckUsernameAvailability={handleCheckUsernameAvailability}
      onCheckEmailAvailability={handleCheckEmailAvailability}
      onConvertProfile={handleConvertProfile}
      spotlightList={spotlightList}
      onAddNewSpotlight={handleAddSpotlight}
      onDeleteSpotlight={handleDeleteSpotlight}
      onSaveSpotlight={handleSaveSpotlight}
    />
  )
}

export default AdminProfileEditPage
