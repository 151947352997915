import React from "react"
import View from "./view"
import { useAuth0 } from "@auth0/auth0-react"
import { Browser } from "@capacitor/browser"
import environment from "../../environment"

type Props = {
  style?: React.CSSProperties
}

const LoginButton = (props: Props) => {
  const { loginWithRedirect } = useAuth0()

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  return <View {...props} onLogin={login} />
}

export default LoginButton
