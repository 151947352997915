import React, { useState, useEffect } from "react"

import IconActive from "../../images/new-homepage/icons/share-active.svg"
import IconDefault from "../../images/new-homepage/icons/share-default.svg"
import IconHover from "../../images/new-homepage/icons/share-hover.svg"

import { Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { ActionButton, Counter, Icon } from "./styles"
import Dialog from "../ProjectShareIcon/dialog"

export type Props = {
  dispatchComplete?: boolean
  project: Project
  handleShareClick: (like: boolean) => Promise<void>
  dialogIsOpen: boolean
  handleCloseDialog: () => void
  handleOpenDialog: () => void
}

const View = ({
  dispatchComplete,
  project,
  handleShareClick,
  dialogIsOpen,
  handleCloseDialog,
  handleOpenDialog,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const [wasShared, setWasShared] = useState(false)
  const [hover, setHover] = React.useState(false)

  useEffect(() => {
    setWasShared(project.shared!)
  }, [project])

  const handleShare = () => {
    if (!disableShare) {
      if (!wasShared) {
        if (isAuthenticated) {
          setWasShared(true)
        }
      }
      handleShareClick(wasShared)
    }
  }

  const handleClick = () => {
    handleOpenDialog()
  }

  let disableShare = false
  if (!project.canBeShared || !dispatchComplete) disableShare = true

  const renderIcon = (shared?: boolean) => {
    if (shared) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  return (
    <>
      <ActionButton
        onClick={handleClick}
        data-testid="likes-button"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Icon src={renderIcon(wasShared)} alt={"comment"} />
        <Counter $isActive={wasShared || false}>
          {project.shares} {project.shares === 1 ? "share" : "shares"}
        </Counter>
      </ActionButton>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        onClose={handleCloseDialog}
        handleShare={handleShare}
        project={project}
      />
    </>
  )
}

export default View
