import styled from "styled-components"
import theme from "../../theme"

export const CheckboxLabel = styled.span`
  // padding-top: 1rem;
  display: inline;
`

export const CheckboxContainer = styled.div<{ marginTop: string }>`
  display: inline-flex;

  && {
    .PrivateSwitchBase-root-21 {
      margin-left: -9px !important;
      padding-right: 16px !important;

      ${theme.breakpoints.down("md")} {
        padding: 0px !important;
        padding-right: 8px !important;
        margin-top: -12px;
      }
    }

    .MuiCheckbox-root {
      padding-right: 16px !important;

      ${theme.breakpoints.up("md")} {
        margin-top: ${({ marginTop }) => marginTop} !important;
        margin-left: -9px !important;
      }

      ${theme.breakpoints.down("sm")} {
        padding: 0px !important;
        margin-top: -12px !important;
        padding-right: 8px !important;
      }

      ${theme.breakpoints.between(theme.breakpoints.values.md, 1117)} {
        margin-top: -29px !important;
        margin-left: -9px !important;
      }
    }

    .PrivateSwitchBase-root-13 {
      padding: 0px !important;
      padding-right: 8px !important;
      margin-top: -12px;
    }
  }
`
