import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import {
  BlockingBox,
  CommentsActions,
  CommentsAreaBox,
  CommentsFlexBox,
  CommentsLinksBox,
  CommentsListBox,
  CommentsNameFlexBox,
  CommentTextBox,
  LinkBox,
  NameBoxComment,
  ReplyBox,
  ReplyLink,
  RoundImages,
  StyledLink,
  TimeBox,
} from "./styles"
import moment from "moment"
import { Profile } from "../../redux/profile"
import CommentContentText from "../../components/CommentContentText"
import CommentTextField from "../../components/CommentTextField"
import {
  PostComment,
  PostMention,
  likeComment,
  dislikeComment,
  deleteComment,
} from "../../redux/posts"
import Avatar from "../../components/Avatar"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import CommentDotMenu from "../../components/CommentDotMenu"
import ConfirmDialog from "../../components/ConfirmDialog"
import { deleteCommentState } from "../../redux/feed"
import EditCommentDialog from "../../components/EditCommentDialog"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  quantity: number
  comments: PostComment[]
  handlePostComment: (
    text: string,
    parentCommentId?: string,
    mentions?: PostMention[]
  ) => void
  isHomepage?: boolean
  isPostingComment?: boolean
  authenticatedProfile?: Profile
}

const CommentsList = ({
  quantity,
  handlePostComment,
  isPostingComment,
  comments,
  isHomepage,
  authenticatedProfile,
}: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [selectedComment, setSelectedComment] = useState<PostComment>()
  const [isReplyMode, setReplyMode] = useState(false)
  const [replyRow, setReplyRow] = useState(Infinity)
  const [tempLiked, setTempLiked] = useState<string[]>([])
  const [tempRemoved, setTempRemoved] = useState<string[]>([])

  const handleSetReplyMode = (key: number) => {
    setReplyMode(replyRow === key ? false : true)
    setReplyRow(replyRow === key ? Infinity : key)
  }

  const getName = (profile: Profile) => {
    let name = profile.groupName
    if (profile.type !== "group")
      name = profile.firstName + " " + profile.lastName

    return name
  }

  const postComment = (
    comment: string,
    mentions?: PostMention[],
    parentCommentId?: string
  ) => {
    if (!isPostingComment && comment) {
      handlePostComment(comment, parentCommentId, mentions)
    }
  }

  useEffect(() => {
    if (isPostingComment) {
      setReplyMode(false)
    }
  }, [isPostingComment])

  const handleLike = async (comment: PostComment) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      const { type } = await dispatch(likeComment({ auth, id: comment.id! }))
      if (type === likeComment.fulfilled.type) {
        setTempLiked([...tempLiked, comment.id!])
        const newTempRemoved = tempRemoved.filter(
          (liked) => liked !== comment.id!
        )
        setTempRemoved(newTempRemoved)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleDislike = async (comment: PostComment) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      const { type } = await dispatch(dislikeComment({ auth, id: comment.id! }))
      if (type === dislikeComment.fulfilled.type) {
        const newTempLiked = tempLiked.filter((liked) => liked !== comment.id!)
        setTempLiked(newTempLiked)
        setTempRemoved([...tempRemoved, comment.id!])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleDeleteComment = async () => {
    if (selectedComment) {
      try {
        const { type } = await dispatch(
          deleteComment({
            auth,
            commentId: selectedComment.id!,
            postId: selectedComment.postId!,
            parentCommentId: selectedComment.parentCommentId,
            profileId: authenticatedProfile?.connectionId!,
          })
        )
        if (type === deleteComment.fulfilled.type) {
          if (isHomepage) {
            await dispatch(
              deleteCommentState({
                type: "post",
                profileId: authenticatedProfile?.connectionId!,
                postId: selectedComment.postId,
                commentId: selectedComment.id!,
                parentCommentId: selectedComment.parentCommentId,
              })
            )
          }

          setSelectedComment(undefined)
          setIsDeleteDialogOpen(false)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleDeleteDialogOpen = (comment: PostComment, value: boolean) => {
    setSelectedComment(comment)
    setIsDeleteDialogOpen(value)
  }

  const handleEditDialogOpen = (comment: PostComment, value: boolean) => {
    setSelectedComment(comment)
    setIsEditDialogOpen(value)
  }

  const handleCloseEditDialog = () => {
    setSelectedComment(undefined)
    setIsEditDialogOpen(false)
  }

  const RenderList = (comments: PostComment[]) => {
    const rows = []
    for (let i = 0; i < quantity; i++) {
      if (comments[i]) {
        rows.push(
          <CommentsFlexBox key={comments[i].id}>
            <ConfirmDialog
              title="Delete comment"
              message="Are you sure you want to delete this comment?"
              isOpen={isDeleteDialogOpen}
              onConfirm={handleDeleteComment}
              onClose={() => setIsDeleteDialogOpen(false)}
            />

            {selectedComment && (
              <EditCommentDialog
                origin={"post"}
                isOpen={isEditDialogOpen}
                onClose={handleCloseEditDialog}
                isHomepage={isHomepage}
                comment={selectedComment}
              />
            )}

            <Box>
              <LinkBox to={`sp/${comments[i].profile?.username}`}>
                <RoundImages>
                  <Avatar
                    profile={comments[i].profile!}
                    width={32}
                    height={32}
                  />
                </RoundImages>
              </LinkBox>
            </Box>
            <CommentsAreaBox>
              <CommentsNameFlexBox>
                <NameBoxComment>
                  <StyledLink to={`sp/${comments[i].profile?.username}`}>
                    {getName(comments[i].profile!)}
                    {comments[i].profile?.isAmbassador && (
                      <img
                        src={AmbassadorIcon}
                        alt="Student Ambassador"
                        className="StudentAmbassadorIcon"
                      />
                    )}
                  </StyledLink>
                </NameBoxComment>
                <TimeBox>
                  <span>•</span> {moment(comments[i].createdAt).fromNow()}
                </TimeBox>
                {authenticatedProfile?.username ===
                  comments[i].profile?.username && (
                  <Box style={{ marginLeft: "auto" }}>
                    <CommentDotMenu
                      comment={comments[i]}
                      handleDeleteDialogOpen={handleDeleteDialogOpen}
                      handleEditDialogOpen={handleEditDialogOpen}
                    />
                  </Box>
                )}
              </CommentsNameFlexBox>
              <CommentTextBox>
                <CommentContentText comment={comments[i]} />
              </CommentTextBox>
              <CommentsLinksBox>
                <CommentsActions>
                  <ReplyLink
                    $isActive={
                      (comments[i].liked &&
                        !tempRemoved.includes(comments[i].id!)) ||
                      tempLiked.includes(comments[i].id!)
                    }
                    onClick={() =>
                      (comments[i].liked &&
                        !tempRemoved.includes(comments[i].id!)) ||
                      tempLiked.includes(comments[i].id!)
                        ? handleDislike(comments[i])
                        : handleLike(comments[i])
                    }
                  >
                    Like
                  </ReplyLink>

                  <ReplyLink
                    $isActive={isReplyMode}
                    $replyRow={replyRow}
                    $currentRow={i}
                    onClick={() => handleSetReplyMode(i)}
                  >
                    Reply
                  </ReplyLink>
                </CommentsActions>

                {isReplyMode && replyRow === i && (
                  <BlockingBox $isPosting={isPostingComment}>
                    <CommentTextField
                      handleSave={postComment}
                      oneLine={true}
                      focus={true}
                      parentCommentId={comments[i].id}
                    />
                  </BlockingBox>
                )}
                {comments[i].replies && comments[i]?.replies?.length! > 0 && (
                  <ReplyBox>
                    {comments[i].replies?.map((reply, key) => {
                      return (
                        <CommentsFlexBox key={`post-comment-${key}`}>
                          <Box>
                            <LinkBox to={`sp/${reply.profile?.username}`}>
                              <RoundImages>
                                <Avatar
                                  profile={reply.profile!}
                                  width={32}
                                  height={32}
                                />
                              </RoundImages>
                            </LinkBox>
                          </Box>
                          <CommentsAreaBox>
                            <CommentsNameFlexBox>
                              <NameBoxComment>
                                <StyledLink
                                  to={`sp/${reply?.profile?.username}`}
                                >
                                  {getName(reply.profile!)}
                                  {reply.profile?.isAmbassador && (
                                    <img
                                      src={AmbassadorIcon}
                                      alt="Student Ambassador"
                                      className="StudentAmbassadorIcon"
                                    />
                                  )}
                                </StyledLink>
                              </NameBoxComment>
                              <TimeBox>
                                <span>•</span>{" "}
                                {moment(reply.createdAt).fromNow()}
                              </TimeBox>
                              {authenticatedProfile?.username ===
                                reply.profile?.username && (
                                <Box style={{ marginLeft: "auto" }}>
                                  <CommentDotMenu
                                    comment={reply}
                                    handleDeleteDialogOpen={
                                      handleDeleteDialogOpen
                                    }
                                    handleEditDialogOpen={handleEditDialogOpen}
                                  />
                                </Box>
                              )}
                            </CommentsNameFlexBox>
                            <CommentTextBox>
                              <CommentContentText comment={reply} />
                            </CommentTextBox>

                            <CommentsActions style={{ marginTop: "10px" }}>
                              <ReplyLink
                                $isActive={
                                  (reply.liked &&
                                    !tempRemoved.includes(reply.id!)) ||
                                  tempLiked.includes(reply.id!)
                                }
                                onClick={() =>
                                  (reply.liked &&
                                    !tempRemoved.includes(reply.id!)) ||
                                  tempLiked.includes(reply.id!)
                                    ? handleDislike(reply)
                                    : handleLike(reply)
                                }
                              >
                                Like
                              </ReplyLink>
                            </CommentsActions>
                          </CommentsAreaBox>
                        </CommentsFlexBox>
                      )
                    })}
                  </ReplyBox>
                )}
              </CommentsLinksBox>
            </CommentsAreaBox>
          </CommentsFlexBox>
        )
      }
    }

    return rows
  }

  return <CommentsListBox>{RenderList(comments)}</CommentsListBox>
}

export default CommentsList
