import React from "react"
import { Post } from "../../redux/posts"
import PostCard from "../../cards/PostCard"
import { PostsBox, PostsContainer } from "./styles"
import BackButton from "../../components/BackButton"
import environment from "../../environment"

export type Props = {
  post: Post
}

const View = ({ post }: Props) => {
  return (
    <>
      <BackButton style={{ marginLeft: "20px" }} />
      <PostsContainer
        style={{ marginTop: environment.device === "ios-app" ? "35px" : "" }}
      >
        <PostsBox>
          <PostCard post={post} fullView={true} isAds={post.isAds} />
        </PostsBox>
      </PostsContainer>
    </>
  )
}

export default View
