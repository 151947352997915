import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"

import { colors } from "../../theme"

export const ScrollableContainer = styled(({ ...props }) => (
  <Grid container spacing={3} {...props} />
))`
  width: calc(100% + 12px);
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid ${colors.slate};

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
`
