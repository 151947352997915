import React, { MouseEventHandler } from "react"
import { Grid } from "@material-ui/core"
import {
  MainContainer,
  TextContainer,
  Text,
  TextTitle,
  Logo,
  LogoContainer,
  ResendButton,
} from "./styles"

type Props = {
  signIn: MouseEventHandler
}

const View = ({ signIn }: Props) => (
  <MainContainer>
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <LogoContainer>
            <Logo
              id="prompt-logo-center"
              src="https://spectacular.design/static/media/logo-charcoal.fff63924.svg"
              alt="Spectacular"
            />
          </LogoContainer>
          <TextTitle>Thank you for verifying your email</TextTitle>
          <Text py={2}>You will be redirected in 5 seconds.</Text>
          <Text>If you are not redirected, please click "Sign in" below.</Text>

          <ResendButton color="primary" data-testid="signin" onClick={signIn}>
            Sign in
          </ResendButton>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
