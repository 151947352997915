import React, { MouseEvent, useState } from "react"
import { DropDownBox, MenuItem, MenuBox } from "./Styles"
import DDDown from "../../images/dd-down.svg"
import DDUp from "../../images/dd-up.svg"
import { Popover } from "@material-ui/core"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import Dialog from "./Dialog"

export type ActionsType = {
  display: string
  action: string
}

export type Props = {
  onClick: (value: string) => void
  actions: ActionsType[]
  title: string
  defaultValue?: string
}

const View = ({ onClick, actions, title, defaultValue }: Props) => {
  const defaultDisplay = actions.find((value) => value.action === defaultValue)

  const [active, setActive] = useState(
    defaultDisplay ? defaultDisplay?.display : actions[0].display
  )
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { width } = useWindowDimensions()

  const handleClose = () => {
    setAnchorEl(null)
    setDialogOpen(false)
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setDialogOpen(true)
  }

  const handleSelect = (value: ActionsType) => {
    setActive(value.display)
    onClick(value.action)
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DropDownBox
        onClick={handleClick}
        style={open ? { border: "0.5px solid #2D2D2D" } : {}}
      >
        {active}
        <img src={open ? DDUp : DDDown} alt={"drop-down"} />
      </DropDownBox>
      {width <= theme.breakpoints.values.sm ? (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          onClick={handleSelect}
          title={title}
          actions={actions}
        />
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              borderRadius: 0,
              background: "transparent",
              boxShadow: "none",
              overflow: "hidden",
              paddingLeft: "15px",
              paddingBottom: "15px",
              paddingRight: "15px",
              marginLeft: "-15px",
            },
          }}
        >
          <MenuBox>
            {actions.map((action) => (
              <MenuItem onClick={() => handleSelect(action)}>
                {action.display}
              </MenuItem>
            ))}
          </MenuBox>
        </Popover>
      )}
    </>
  )
}

export default View
