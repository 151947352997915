import React from "react"

import DefaultImage from "../../images/default-image.png"

import {
  ImageThumbnailContainer,
  ImageThumbnail,
  LargeImageThumbnailContainer,
  CardFooter,
  CardBox,
} from "./styles"

export type Props = {
  large?: boolean
  awards?: boolean
}

const View = ({ large, awards }: Props) => {
  const ImageThumbnailComponent = large
    ? LargeImageThumbnailContainer
    : ImageThumbnailContainer

  return (
    <CardBox $isLarge={large} $isAwards={awards}>
      <ImageThumbnailComponent>
        <ImageThumbnail alt={`Loading project`} src={DefaultImage} />
      </ImageThumbnailComponent>
      <CardFooter $isLarge={large} $isAwards={awards}></CardFooter>
    </CardBox>
  )
}

export default View
