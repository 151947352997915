import React, { useEffect, useCallback } from "react"

import { useSelector } from "react-redux"

import { useAppDispatch } from "../../redux/configureStore"
import { updateNotificationsState } from "../../redux/bellNotifications"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

const NotificationsPage = () => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const profile = useSelector((state: any) => state.profile.profile)
  const profileId = profile.connectionId

  const handleAllViewed = useCallback(
    async () => {
      try {
        const state = "read"
        const { type } = await dispatch(
          updateNotificationsState({ auth, profileId, state })
        )
        if (updateNotificationsState.fulfilled.type === type) {
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    handleAllViewed()
  }, [handleAllViewed])

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Notifications`}
        classTitle="Notifications"
      />
      <Helmet>
        <title>Notifications - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Notifications - Spectacular`} />
        <meta name="title" content={`Notifications - Spectacular`} />
      </Helmet>
      <View profile={profile} />
    </>
  )
}

export default NotificationsPage
