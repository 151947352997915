import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"

export const VerticalBox = styled(Box)`
  width: 92px;
  height: 142px;
  padding: 8px;
  background-color: #2d2d2d;
  color: #fff;
  box-sizing: border-box;
  padding: 8px;
  text-align: center;
`

export const HorizontalBox = styled(Box)`
  width: 100%;
  max-width: 264px;
  height: 90px;
  padding: 8px;
  background-color: #2d2d2d;
  color: #fff;
  box-sizing: border-box;
  padding: 16px 0 16px 16px;
  text-align: center;
  display: flex;
`

export const WeekendCompetitionImage = styled.img<{ variant: string }>`
  ${({ variant }) =>
    variant === "vertical"
      ? `
  	width: 100% !important;
	`
      : `
  	width: 58px !important;
	`};
`

export const StudioAwardsImage = styled.img<{ variant: string }>`
  ${({ variant }) =>
    variant === "vertical"
      ? `
		width: 100% !important;
		padding: 24px 0;
	`
      : `
  	width: 115px !important;
	`};
`

export const InnovationAwardsImage = styled.img<{ variant: string }>`
  ${({ variant }) =>
    variant === "vertical"
      ? `
  	width: 60px !important;
	`
      : `
  	width: 46px !important;
	`};
`

export const Label = styled(Typography)<{ $isPeopleChoice: boolean }>`
  font-size: 14px;
  color: #fff;
  text-align: center;
  && sup {
    font-size: 8px;
  }

  && img {
    width: 14px !important;
  }

  ${({ $isPeopleChoice }) =>
    $isPeopleChoice
      ? ""
      : `
		margin-top: 6px;
	`};
`

export const DateLabel = styled(Typography)<{
  $isPeopleChoice: boolean
  $isBig: boolean
}>`
  color: #fff;
  text-align: center;

  ${({ $isPeopleChoice }) =>
    $isPeopleChoice
      ? ""
      : `
		margin-top: 6px;
	`};

  ${({ $isBig }) => ($isBig ? "font-size: 14px;" : "font-size: 10px;")};
`

export const LabelsBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`

export const HorizontalLabel = styled(Typography)<{
  $isPeopleChoice: boolean
  $isBig: boolean
}>`
  color: #fff;
  text-align: center;
  && sup {
    font-size: 12px;
  }

  && img {
    width: 14px !important;
  }

  ${({ $isPeopleChoice, $isBig }) =>
    $isPeopleChoice || $isBig
      ? "font-size: 14px;"
      : `
		font-size: 24px;
	`};
`

export const DateLabelHorizontal = styled(Typography)<{
  $isPeopleChoice: boolean
}>`
  font-size: 14px;
  color: #fff;
  text-align: center;
`
