import React from "react"
import { Divider } from "@material-ui/core"
import { Container } from "./styles"

const View: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <Container aria-hidden>
      <Divider style={{ ...style }} light={true} />
    </Container>
  )
}

export default View
