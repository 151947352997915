import React, {
  PropsWithChildren,
  ButtonHTMLAttributes,
  useState,
  ReactNode,
} from "react"
import { useSelector } from "react-redux"
import { Analytics } from "@segment/analytics-next"
import { Button as MUIButton } from "@material-ui/core"
import { getStyles, getHoverClass } from "./buttonStyles"
import { useAuth0 } from "@auth0/auth0-react"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

interface Props extends ButtonHTMLAttributes<{}> {
  event?: string
  eventData?: any
  segment?: Analytics
  size?: "small" | "medium" | "large"
  onClick?: React.MouseEventHandler<{}>
  onMouseEnter?: React.MouseEventHandler<{}>
  onMouseLeave?: React.MouseEventHandler<{}>
  variant?: "text" | "outlined" | "contained"
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "default"
    | "white"
    | "lightGrey"
    | "lightBlue"
    | "mediumGray"
    | "whiteGreyBorder"
    | "red"
    | "whiteBlueBorder"
    | "darkGrey"
    | "blueBorder"
    | "darkWhiteBorder"
  hoverColor?:
    | "default"
    | "primary"
    | "whiteRedBorder"
    | "mediumGrey"
    | "whiteGreyBorder"
    | "primaryHover"
    | "secondaryHover"
    | "lightBlueHover"
    | "whiteBlueBorderHover"
    | "blueBorderHover"
    | "darkWhiteBorderHover"
  hoverChildren?: ReactNode
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
  type,
  size,
  style,
  color,
  event,
  segment,
  onClick,
  onMouseEnter,
  onMouseLeave,
  variant,
  children,
  hoverColor,
  hoverChildren,
  eventData,
  ...rest
}) => {
  const [hover, setHover] = useState(false)
  const { isAuthenticated } = useAuth0()
  const profile = useSelector(({ profile }: any) => profile.profile)

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const displayHoverColor = hoverColor ? hoverColor : getHoverClass(color)

  const buttonStyles = getStyles(
    hover ? displayHoverColor : color,
    rest.disabled
  )

  let styles = {
    borderRadius: "1px",
    boxSizing: "border-box",
    ...buttonStyles,
    ...style,
  }

  if (size === "medium" && !style?.height) {
    styles = { ...styles, height: "40px" }
  }

  const handleClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    if (segment) {
      console.log("segment detected...")
      segment.track({
        userId: isAuthenticated ? profile?.connectionId : undefined,
        event,
        type: "track",
        properties: {
          ...eventData,
        },
      })
    }

    if ((event || eventData) && !segment)
      console.warn("WARNING: Passed segment data without passing segment prop")

    if (onClick) onClick(e)
  }

  const handleMouseEnter = (e: React.MouseEvent<{}, MouseEvent>) => {
    setHover(isMobile ? false : true)
    if (onMouseEnter) onMouseEnter(e)
  }

  const handleMouseleave = (e: React.MouseEvent<{}, MouseEvent>) => {
    setHover(false)
    if (onMouseLeave) onMouseLeave(e)
  }

  return (
    <MUIButton
      {...rest}
      size={size}
      type={type}
      style={styles}
      variant={variant}
      color={color as any}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseleave}
    >
      {hover && hoverChildren ? <>{hoverChildren}</> : <>{children}</>}
    </MUIButton>
  )
}
