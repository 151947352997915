import styled from "styled-components"

export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0.9px;
    right: 0px;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const MenuItem = styled.div`
  color: #2d2d2d;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`

export const MenuBox = styled.div`
  width: 108px;
  padding: 10px 0;
  font-size: 12px;
  box-shadow: 0px -2px 15px rgba(45, 45, 45, 0.1);
  background-color: #fff;
`
