import React from "react"
import View from "./View"
import { ProjectReward } from "../../redux/adminProjectReward"

interface Props {
  reward: ProjectReward
  variant: "vertical" | "horizontal"
}

const ProjectWinnerBadge = ({ reward, variant }: Props) => {
  return <View reward={reward} variant={variant} />
}

export default ProjectWinnerBadge
