import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Box, Chip, FormHelperText } from "@material-ui/core"

export type Props = {
  value: string
  groups: string[]
  suggestions: string[]
  displayError: boolean
  onInputChange: (value: string) => void
  onEnteredPressed: (jobTag: string) => void
  onDeletedPressed: (jobTag: string) => void
}

const View = ({
  groups,
  value,
  suggestions,
  displayError,
  onInputChange,
  onEnteredPressed,
  onDeletedPressed,
}: Props) => {
  const onKeyPress = (event: any) => {
    if (event.key === "Enter" && value.trim().length > 0)
      onEnteredPressed(value)
  }

  const handleDelete = (tagToDelete: string) => () =>
    onDeletedPressed(tagToDelete)

  return (
    <>
      <Box marginBottom={2}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value}
          id="profile-groups-autocomplete"
          options={suggestions}
          onInputChange={(_, newValue) => onInputChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              onKeyPress={onKeyPress}
              placeholder="e.g: AIA, CAF, NOMA, etc."
              InputProps={{ ...params.InputProps }}
            />
          )}
        />

        <FormHelperText error={false}>
          Add groups by pressing enter...
        </FormHelperText>
        {displayError && (
          <Box marginBottom={2}>
            <FormHelperText error={true}>
              There was an error saving the profile group. Please try again!
            </FormHelperText>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        {groups.map((group: string, index: number) => (
          <Box key={index} marginRight={1} marginTop={1} display="flex">
            <Chip
              label={group}
              onDelete={handleDelete(group)}
              color="primary"
              data-testid="profle-group-item"
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default View
