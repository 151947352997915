import React from "react"
import { Box, IconButton } from "@material-ui/core"

import {
  Divider,
  ContentText,
  PopoverTitle,
  PopoverHeader,
  ContentPadding,
  PopoverContainer,
} from "./styles"

import { ReferraulURLField } from "./ReferralURLField"

import { ReactComponent as CloseHoverIcon } from "../../images/x-hover.svg"
import { ReactComponent as CloseDefaultIcon } from "../../images/x-default.svg"

interface Props {
  onClose: () => void
  referralCode: string
}

const PopoverContent: React.FC<Props> = ({ onClose, referralCode }) => {
  const [isHovering, setIsHovering] = React.useState(false)

  return (
    <PopoverContainer>
      <PopoverHeader>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <PopoverTitle>Invite a friend</PopoverTitle>
        </Box>

        <Box>
          <IconButton
            onClick={onClose}
            aria-label="close"
            style={{ padding: "0" }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {isHovering ? <CloseHoverIcon /> : <CloseDefaultIcon />}
          </IconButton>
        </Box>
      </PopoverHeader>

      <Divider />

      <ContentPadding>
        <ContentText>
          Grow your Spectacular network by sharing this link with your friends
          and colleagues.
        </ContentText>

        <div>
          <ReferraulURLField referralCode={referralCode} />
        </div>
      </ContentPadding>
    </PopoverContainer>
  )
}

export default PopoverContent
