import React from "react"
import { ConnectListBox } from "./styles"

import ConnectionRequestList from "../../components/ConnectionRequestList"

const View = () => (
  <ConnectListBox>
    <ConnectionRequestList />
  </ConnectListBox>
)

export default View
