export const initialEmploymentType = [
  {
    action: "internship",
    display: "Internship",
    active: false,
  },
  {
    action: "contract",
    display: "Contract",
    active: false,
  },
  {
    action: "part-time",
    display: "Part-time",
    active: false,
  },
  {
    action: "full-time",
    display: "Full-time",
    active: false,
  },
]

export const initialWorkplaceType = [
  {
    action: "onsite",
    display: "Onsite",
    active: false,
  },
  {
    action: "hybrid",
    display: "Hybrid",
    active: false,
  },
  {
    action: "remote",
    display: "Remote",
    active: false,
  },
]

export const initialExperienceLevel = [
  {
    action: "entry-level",
    display: "Entry-level",
    active: false,
  },
  {
    action: "mid-level",
    display: "Mid-level",
    active: false,
  },
  {
    action: "senior-level",
    display: "Senior-level",
    active: false,
  },
  {
    action: "executive-level",
    display: "Executive-level",
    active: false,
  },
]
