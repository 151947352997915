import styled from "styled-components"
import { Dialog, Box, Typography } from "@material-ui/core"

import { colors } from "../../theme"

export const FilterDialog = styled(Dialog)`
  min-height: 100vh;
  z-index: 2147483098 !important;
`

export const FilterDialogBox = styled(Box)`
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-flow: column;
`

export const FilterDialogHeader = styled(Box)`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #d8d8d8;
  min-height: 68px;
  padding: 0 20px;
`
export const FilterDialogTitle = styled(Typography)`
  font-size: 16px;
  color: ${colors.dark};
  line-height: 16px;
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

export const DialogOption = styled(Box)`
  box-sizing: border-box;
  border-radius: 1px;
  color: #2d2d2d;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;
  justify-content: space-between;
  width: 100%;
`

export const FilterTitle = styled(Box)`
  font-size: 14px;
  color: #717171;
  padding: 0 0 16px 20px;
  margin-top: -2px;
`

export const Divider = styled(Box)`
  border-bottom: 0.5px solid #d8d8d8;
  margin: 4px 20px 20px;
`

export const ClearFilters = styled(Box)`
  font-size: 14px;
  color: #979797;
`

export const ClearFiltersActive = styled(Box)`
  font-size: 14px;
  cursor: pointer;
  color: #3057e1;
`
