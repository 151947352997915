import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { useHistory } from "react-router-dom"

import { useSelector } from "react-redux"
import { selectProfile } from "../../redux/profile"

import View from "./view"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"

interface MatchParams {
  tab: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const DashboardPage: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useState(props.match.params.tab || "jobs")

  let history = useHistory()
  const profile = useSelector(selectProfile)

  const handleTabChanged = (_event: React.ChangeEvent<{}>, tab: string) =>
    setCurrentTab(tab)

  const handleTabChangedMobile = (tab: string, url: string) => {
    setCurrentTab(tab)
    window.history.replaceState(null, "Spectacular", url)
  }

  const handleTabClicked = (url: string) =>
    window.history.replaceState(null, "Spectacular", url)

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Jobs Dashboard`}
        classTitle="JobsDashboard"
      />
      <Helmet>
        <title>Jobs Dashboard - Spectacular</title>
      </Helmet>
      <View
        profile={profile}
        currentTab={currentTab}
        onTabChanged={handleTabChanged}
        onTabChangedMobile={handleTabChangedMobile}
        onTabClicked={handleTabClicked}
        goToUrl={goToUrl}
      />
    </>
  )
}

export default DashboardPage
