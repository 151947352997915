import styled from "styled-components"
import { Box } from "@material-ui/core"
import theme from "../../../../theme"

export const ConnectionsBox = styled(Box)`
  background-color: #fff;
  border-radius: 1px;
  width: 100%;
`

export const ConnectionsHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 0 40px;
  border-bottom: 1px solid #dbdbdb;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const ConnectionsList = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 32px;
  padding: 40px;
  box-sizing: border-box;
  justify-content: center;

  ${theme.breakpoints.down("xs")} {
    padding: 24px 20px;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  border-bottom: 0.5px solid #d8d8d8;
  margin: 24px 0;
`
