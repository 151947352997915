import React, { useEffect, useCallback, useState } from "react"
import { useHistory } from "react-router-dom"

import { useSelector } from "react-redux"
import { Popover, Box } from "@material-ui/core"
import { useAppDispatch } from "../../redux/configureStore"

import {
  BellContainer,
  NotificationPopoverContainer,
  NotificationPopoverInternalContainer,
  RedDot,
} from "./styles"
import {
  updateNotificationsState,
  fetchNotifications,
} from "../../redux/bellNotifications"

import NotificationsBell from "../NotificationsBell"
import NotificationDotMenu from "../NotificationDotMenu"

import { Profile } from "../../redux/profile"

import Bell from "../../images/new-header/header-notification-icon.svg"
import BellActive from "../../images/new-header/header-notification-icon-active.svg"

import { useAuth0 } from "@auth0/auth0-react"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { logEvent } from "../../utils/analytics"

export type Props = {
  isNav?: any
  hover?: any
  width?: number
  height?: number
  profile: Profile
}

const InviteBell = ({ profile, width, height, hover, isNav }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [notificationState, setNotificationState] = useState("all")
  const [bellIcon, setBellIcon] = useState(Bell)
  const [active, setActive] = useState(false)

  const window = useWindowDimensions()

  const isMobile = window.width <= theme.breakpoints.values.md

  const currentPage = useSelector(
    (state: any) => state.bellNotifications?.notificationCurrentPage
  )

  const unreadCount = useSelector(
    (state: any) => state.bellNotifications?.notificationsUnreadCount
  )

  const [page, setPage] = React.useState(currentPage > 0 ? currentPage : 0)

  const profileId = profile.connectionId

  const handleChangePagination = (value: number) => {
    setPage(value)
  }

  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const anchorRef = React.useRef(null)

  const handleClick = async () => {
    setAnchorEl(anchorRef.current)
    setBellIcon(BellActive)
    setActive(true)

    try {
      await logEvent("navbar_notifications_click")
      const state = "read"
      const { type } = await dispatch(
        updateNotificationsState({ auth, profileId, state })
      )
      if (updateNotificationsState.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleAllRead = async () => {
    try {
      const state = "clicked"
      const { type } = await dispatch(
        updateNotificationsState({ auth, profileId, state })
      )
      if (updateNotificationsState.fulfilled.type === type) {
        await dispatch(
          fetchNotifications({
            currentPage: 0,
            auth,
            reset: true,
            notificationState,
          })
        )

        handleChangeHasMore(true)
        handleChangePagination(0)

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleChangeState = async (value: string) => {
    setNotificationState(value)
    await dispatch(
      fetchNotifications({
        currentPage: 0,
        auth,
        reset: true,
        notificationState: value,
      })
    )

    handleChangeHasMore(true)
    handleChangePagination(0)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setBellIcon(Bell)
    setActive(false)
  }

  const notificationsOpen = Boolean(anchorEl)

  const loadNotifications = useCallback(
    async (reset) => {
      try {
        const currentPage = 0
        await dispatch(
          fetchNotifications({ currentPage, auth, reset, notificationState })
        )

        handleChangeHasMore(true)
        handleChangePagination(0)
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [dispatch, auth]
  )

  useEffect(
    () => {
      return history.listen((location) => {
        loadNotifications(true)
        setNotificationState("all")
      })
    },
    // eslint-disable-next-line
    [history, loadNotifications]
  )

  useEffect(
    () => {
      if (!profile.firstLoginDate && !isMobile) {
        setAnchorEl(anchorRef.current)
        setBellIcon(BellActive)
        setActive(true)
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <>
      <BellContainer
        hover={hover}
        isNav={isNav}
        onClick={handleClick}
        ref={anchorRef}
        width={width ? width : 56}
        height={height ? height : 56}
        style={{ color: active ? "#717171" : "#2d2d2d" }}
      >
        {unreadCount > 0 && (
          <RedDot
            style={{
              minWidth: unreadCount > 9 ? "19px" : "14px",
              marginLeft: unreadCount > 9 ? "24px" : "19px",
            }}
          >
            {unreadCount > 9 ? "9 +" : unreadCount}
          </RedDot>
        )}
        <img
          src={bellIcon}
          style={{ paddingBottom: "6px", width: "24px" }}
          alt="bell-icon"
        />
        Notifications
      </BellContainer>

      <Popover
        open={notificationsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            paddingLeft: "35px",
            paddingBottom: "46px",
            marginLeft: "35px",
            paddingRight: "35px",
            overflow: "hidden",
          },
          className: "popover-fix",
        }}
      >
        <NotificationPopoverContainer id={"notificationsContainer"}>
          <Box
            style={{
              fontSize: "25px",
              height: "64px",
              alignContent: "center",
              display: "flex",
              flexFlow: "wrap",
              padding: "0 32px 0 16px",
              justifyContent: "space-between",
            }}
          >
            <Box>Notifications</Box>
            <NotificationDotMenu
              handleAllRead={handleAllRead}
              handleChangeState={handleChangeState}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              borderTop: "0.5px solid #d8d8d8",
            }}
          />
          <NotificationPopoverInternalContainer>
            <NotificationsBell
              onClose={handleClose}
              handleChangeHasMore={handleChangeHasMore}
              handleChangePagination={handleChangePagination}
              notificationState={notificationState}
              hasMore={hasMore}
              page={page}
            />
          </NotificationPopoverInternalContainer>
        </NotificationPopoverContainer>
      </Popover>
    </>
  )
}

export default InviteBell
