import React from "react"
import { Profile } from "../../redux/profile"
import View from "./View"

interface Props {
  profile: Profile
}

const SpotlightedFirm = ({ profile }: Props) => {
  return <View profile={profile} />
}

export default SpotlightedFirm
