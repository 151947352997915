import React from "react"
import { Box, TableRow, TableCell } from "@material-ui/core"
import { Post } from "../../../redux/posts"
import moment from "moment"
import Dialog from "./Dialog"
import { useAppDispatch } from "../../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { updatePost } from "../../../redux/adminPosts"

export type Props = {
  post: Post
}

const ListItem = ({ post }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const handleSubmit = async (status: string) => {
    try {
      const { type } = await dispatch(
        updatePost({
          auth,
          id: post.id!,
          status,
        })
      )
      if (updatePost.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  let lastReason = ""
  let lastDate = undefined
  let lastStatus = ""

  const name =
    post.profile?.type === "group"
      ? post.profile.groupName
      : post.profile?.firstName + " " + post.profile?.lastName

  if (post.flags && post.flags.length > 0) {
    lastReason = post.flags?.[post.flags?.length - 1].reason
    lastDate = moment(post.flags?.[post.flags?.length - 1].createdAt!).format(
      "MMMM d, YYYY"
    )
    lastStatus = post.flags?.[post.flags?.length - 1].status
  }

  const getLabel = (reason: string) => {
    if (reason === "spam") return "It is spam."

    if (reason === "offensive")
      return "It is offensive, discriminatory, or threatening."

    if (reason === "violent") return "It is violent or incites violence."

    if (reason === "copyright") return "It violates copyright."

    if (reason === "obscene") return "It contains obscene or lewd content."

    if (reason === "other") return "Other"
  }

  return (
    <TableRow key={post.profile?.username}>
      <TableCell>{name}</TableCell>
      <TableCell>{moment(post.createdAt).format("MMMM d, YYYY")}</TableCell>
      <TableCell>{post.flags?.length}</TableCell>
      <TableCell>{getLabel(lastReason)}</TableCell>
      <TableCell>{lastDate}</TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {lastStatus}
      </TableCell>
      <TableCell>
        <Box
          paddingRight={1}
          display="inline"
          onClick={() => setIsOpen(true)}
          style={{ cursor: "pointer" }}
        >
          Review
        </Box>
      </TableCell>
      <Dialog
        post={post}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={handleSubmit}
      />
    </TableRow>
  )
}

export default ListItem
