import React, { useState, ChangeEvent } from "react"
import _ from "lodash"
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  TextField,
  FormGroup,
  FormHelperText,
} from "@material-ui/core"

import { ScrollableContainer } from "./styles"
import { StyledFormControlLabel } from "../ProjectTypology/styles"

const suggestions = [
  "Aluminum",
  "Concrete",
  "EIFs",
  "Glass",
  "Heavy Timber",
  "Light Frame Timber",
  "Masonry - Brick",
  "Masonry - Stone",
  "Steel",
  "Stucco",
  "Terra Cotta",
  "Other",
]

type Props = {
  error?: string
  keyMaterials: string[]
  onChange: (tool: string) => void
}

const MAX_ALLOWED = 5

const View = ({ keyMaterials, onChange, error }: Props) => {
  const [options] = useState(suggestions)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    onChange(target.value)

  const onBlur = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onChange(target.value)

  const isDisabled = keyMaterials.length === MAX_ALLOWED

  const isChecked = (option: string) =>
    keyMaterials.find((material) => material === option) ? true : false

  let otherMaterial = keyMaterials

  options.map((option) => {
    otherMaterial = otherMaterial.filter((e) => !Object(option).includes(e))
    return otherMaterial
  })

  const chunks = _.chunk(options, 3)

  function FormRow(options: string[]) {
    return (
      <>
        {options.map((option, idx) => {
          return option === "Other" ? (
            <Grid item xs={12} style={{ padding: "6px 12px" }}>
              <TextField
                name={option}
                label="Other"
                onBlur={onBlur}
                data-testid="other"
                defaultValue={otherMaterial}
              />
            </Grid>
          ) : (
            <Grid item md={4} xs={6} style={{ padding: "4px 12px" }}>
              <StyledFormControlLabel
                key={idx}
                label={option}
                control={
                  <Checkbox
                    value={option}
                    onChange={handleChange}
                    checked={isChecked(option)}
                    disabled={isDisabled && !isChecked(option)}
                  />
                }
              />
            </Grid>
          )
        })}
      </>
    )
  }

  return (
    <Box marginTop={3} marginBottom={5}>
      <Typography style={error ? { color: "#f44336" } : {}} variant="h5">
        Key Materials <span style={{ color: "#ff0000" }}>*</span>
      </Typography>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <FormHelperText style={{ margin: ".5em 0 0" }}>
        Select no more than 5 options
      </FormHelperText>
      <Box paddingLeft={1.5} marginTop={2}>
        <FormGroup>
          <ScrollableContainer>
            {chunks.map((chunk) => {
              return FormRow(chunk)
            })}
          </ScrollableContainer>
        </FormGroup>
      </Box>
    </Box>
  )
}

export default View
