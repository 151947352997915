import styled from "styled-components"
import theme, { colors } from "../../theme"
import { Box } from "@material-ui/core"

export const FooterContainer = styled.footer`
  background-color: ${colors.dark};
  color: ${colors.white};
  font-size: 14px;
  padding: ${theme.spacing(2.5)}px ${theme.spacing(5)}px;
  margin-top: ${theme.spacing(8)}px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`

export const SocialLinksContainer = styled(Box)`
  text-align: center;
  a {
    padding: 0 16px;

    ${theme.breakpoints.down("xs")} {
      padding: 0 8px;
    }
  }

  img {
    width: 40px;
    height: 40px;

    ${theme.breakpoints.down("xs")} {
      width: 32px;
      height: 32px;
    }
  }
`

export const PlusIcon = styled.img`
  height: 64px;
  width: 64px;
  padding-top: ${theme.spacing(5)}px;
  padding-bottom: ${theme.spacing(5)}px;
`

export const Text = styled(Box)`
  text-align: center;
`

export const TextCopyright = styled(Box)`
  text-align: center;
  font-size: 12px;

  ${theme.breakpoints.down("xs")} {
    margin-bottom: 120px;
  }
`

export const StyledLinkBox = styled(Box)`
  a {
    margin: 0 !important;
    font-weight: normal;
    color: ${colors.white};
  }
`

export const StyledLinkEmailBox = styled(Box)`
  a {
    margin: 0 !important;
    font-weight: normal;
    color: ${colors.white};
    padding-bottom: 4px;
    border-bottom: solid 0.5px ${colors.slate};
  }
`

export const StyledXIcon = styled.img`
  padding: 8px 0;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 6px 0;
  }
`
