import React from "react"
import { Box, Button, Divider, Grid, Tab, Typography } from "@material-ui/core"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import TabPanel from "@material-ui/lab/TabPanel"
import { Profile } from "../../../redux/profile"
import GroupProfileForm from "../../../components/GroupProfileForm"
import IndividualProfileForm from "../../../components/IndividualProfileForm"
import { Jobs } from "./Tabs/Jobs"
import { BackLink } from "../../../components/Styles/Global"
import { ProfileContainer } from "./styles"
import AdminProfileArchiveLink from "../../../components/AdminProfileArchiveLink"
import AdminFirmSpotlightRow, {
  EditableSpotlight,
} from "../../../components/AdminFirmSpotlightRow"

export type Props = {
  profile: Profile
  disableSubmit: boolean
  usernameCheck: string
  emailCheck: string
  usernameValue: string
  emailValue: string
  onCheckUsernameAvailability: (
    _event: React.ChangeEvent<{ value: any }>
  ) => void
  onCheckEmailAvailability: (_event: React.ChangeEvent<{ value: any }>) => void
  onSubmit: Function
  onCancel: Function
  onConvertProfile: Function
  spotlightList: EditableSpotlight[]
  onAddNewSpotlight: () => void
  onSaveSpotlight: (spotlight: EditableSpotlight) => void
  onDeleteSpotlight: (spotlight: EditableSpotlight) => void
}

const View = ({
  profile,
  disableSubmit,
  usernameCheck,
  emailCheck,
  usernameValue,
  emailValue,
  onCheckUsernameAvailability,
  onCheckEmailAvailability,
  onSubmit,
  onCancel,
  onConvertProfile,
  spotlightList,
  onAddNewSpotlight,
  onSaveSpotlight,
  onDeleteSpotlight,
}: Props) => {
  let groupType = false
  if (profile.type === "group") groupType = true

  const [value, setValue] = React.useState("1")
  const [isGroup, setIsGroup] = React.useState(groupType)
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }
  const handleClick = (event: any) => {
    if (isGroup) {
      setIsGroup(false)
      onConvertProfile(false)
    } else {
      setIsGroup(true)
      onConvertProfile(true)
    }
  }
  return (
    <ProfileContainer>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box marginBottom={3}>
            <BackLink to={"/admin/profiles"}>Back to Profiles</BackLink>
          </Box>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="profile tabs">
          <Tab label="Edit Profile" value="1" />
          <Tab label="Projects" value="2" />
          <Tab label="Jobs" value="3" />
          <Tab label="Archive Profile" value="4" />
        </TabList>
        <TabPanel value="1">
          <Button onClick={handleClick}>
            Convert Profile from{" "}
            {isGroup ? "Group to Individual" : "Individual to Group"}
          </Button>
          {isGroup ? (
            <>
              <GroupProfileForm
                profile={profile}
                disableSubmit={disableSubmit}
                usernameCheck={usernameCheck}
                emailCheck={emailCheck}
                usernameValue={usernameValue}
                emailValue={emailValue}
                onCheckUsernameAvailability={onCheckUsernameAvailability}
                onCheckEmailAvailability={onCheckEmailAvailability}
                onSubmit={onSubmit}
                onCancel={onCancel}
                adminEditing={true}
              />
              <Box marginTop={5} marginBottom={1}>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="h5">
                      Set as Spotlighted firm on Homepage
                    </Typography>
                  </Grid>
                  <Grid item xs container justify="flex-end">
                    <Button
                      color="primary"
                      size="small"
                      onClick={onAddNewSpotlight}
                    >
                      + Schedule for Spotlight
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {spotlightList.map((spotlight, index) => (
                  <>
                    <Box
                      style={{
                        borderTop: "0.5px solid #d8d8d8",
                        margin: "24px 0",
                        width: "100%",
                      }}
                    />
                    <AdminFirmSpotlightRow
                      key={`${spotlight.id}${spotlight.startDate}${spotlight.endDate}`}
                      profileSpotlight={spotlight}
                      onSave={onSaveSpotlight}
                      onDelete={onDeleteSpotlight}
                    />
                  </>
                ))}
              </Box>
            </>
          ) : (
            <IndividualProfileForm
              profile={profile}
              disableSubmit={disableSubmit}
              usernameCheck={usernameCheck}
              usernameValue={usernameValue}
              onCheckUsernameAvailability={onCheckUsernameAvailability}
              onSubmit={onSubmit}
              onCancel={onCancel}
              adminEditing={true}
            />
          )}
        </TabPanel>
        <TabPanel value="2">Profile Projects tab. To be built.</TabPanel>
        <TabPanel value="3">
          <Jobs profile={profile} />
        </TabPanel>
        <TabPanel value="4">
          <Typography style={{ marginBottom: 16 }} tabIndex={0} variant="h3">
            Archive profile
          </Typography>
          <Divider
            style={{ width: "85%", marginBottom: 26, backgroundColor: "gray" }}
          />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              Temporarily archive profile.
            </Grid>
            <Grid item xs={4}>
              <AdminProfileArchiveLink profileId={profile.id!} />
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </ProfileContainer>
  )
}

export default View
