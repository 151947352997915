import * as Yup from "yup"

import { ProfileType } from "../../redux/profile"
import { passwordHintMessage } from "../../constants/signup"

export const RegisterFormSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  firstName: Yup.string()
    .ensure()
    .when("type", {
      is: (type) =>
        type === undefined || type === "" || type === ProfileType.INDIVIDUAL,
      then: Yup.string().required("Required"),
    })
    .typeError("Required"),
  lastName: Yup.string()
    .ensure()
    .when("type", {
      is: (type) =>
        type === undefined || type === "" || type === ProfileType.INDIVIDUAL,
      then: Yup.string().required("Required"),
    })
    .typeError("Required"),
  groupName: Yup.string()
    .ensure()
    .when("type", {
      is: ProfileType.GROUP,
      then: Yup.string().required("Required"),
    })
    .typeError("Required"),
  email: Yup.string().required("Required").email(),
  password: Yup.string()
    .required(passwordHintMessage)
    .min(8, passwordHintMessage)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]/,
      passwordHintMessage
    ),
  termAcceptance: Yup.bool().oneOf([true], "Must Accept Terms and Conditions"),
})
