import React from "react"
import { Box } from "@material-ui/core"

import { Button } from "../../components/Button"

import Avatar from "../../components/Avatar"

import {
  DashboardContainer,
  TabsBox,
  TabItem,
  TabLabel,
  Tabs,
  ProfileBox,
  ProfilePicture,
  ProfileLabel,
  ContentBox,
  ButtonBox,
  MobileTab,
  MobileTabActive,
  MobileTabArea,
} from "./styles"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Profile } from "../../redux/profile"

import JobsTab from "./Tabs/JobsTab"

export type Props = {
  profile?: Profile
  currentTab: string
  onTabClicked: (url: string) => void
  onTabChangedMobile: (tab: string, url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  goToUrl: (url: string) => void
}

const View = ({
  profile,
  currentTab,
  onTabChanged,
  onTabChangedMobile,
  onTabClicked,
  goToUrl,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  return (
    <DashboardContainer>
      <TabsBox>
        {!isMobile ? (
          <>
            <ProfileBox>
              <ProfilePicture>
                <Avatar profile={profile!} />
              </ProfilePicture>
              <ProfileLabel>{profile?.groupName}</ProfileLabel>
            </ProfileBox>
            <Box style={{ paddingTop: "40px" }}>
              <Tabs
                value={currentTab}
                orientation="vertical"
                variant="fullWidth"
                onChange={onTabChanged}
                indicatorColor="secondary"
              >
                <TabItem
                  label={<TabLabel>Jobs</TabLabel>}
                  value="jobs"
                  onClick={onTabClicked.bind(this, `/dashboard/jobs`)}
                />

                {/* <TabItem
                  label={<TabLabel>Analytics</TabLabel>}
                  value="analytics"
                  onClick={onTabClicked.bind(this, `/dashboard/analytics`)}
                />

                <TabItem
                  label={<TabLabel>Applicants</TabLabel>}
                  value="applicants"
                  onClick={onTabClicked.bind(this, `/dashboard/applicants`)}
                /> */}
              </Tabs>
            </Box>
          </>
        ) : (
          <MobileTabArea>
            {currentTab === "jobs" ? (
              <MobileTabActive>Jobs</MobileTabActive>
            ) : (
              <MobileTab
                onClick={() => onTabChangedMobile("jobs", "/dashboard/jobs")}
              >
                Jobs
              </MobileTab>
            )}
            {/* {currentTab === "analytics" ? (
              <MobileTabActive>Analytics</MobileTabActive>
            ) : (
              <MobileTab
                onClick={() =>
                  onTabChangedMobile("analytics", "/dashboard/analytics")
                }
              >
                Analytics
              </MobileTab>
            )}
            {currentTab === "applicants" ? (
              <MobileTabActive>Applicants</MobileTabActive>
            ) : (
              <MobileTab
                onClick={() =>
                  onTabChangedMobile("applicants", "/dashboard/applicants")
                }
              >
                Applicants
              </MobileTab>
            )} */}
          </MobileTabArea>
        )}
        <ButtonBox>
          <Button
            onClick={() => goToUrl("/job_listings/new")}
            children={isMobile ? "Post a job" : "Post a new job"}
            color={"secondary"}
            size={"medium"}
            style={{
              display: isMobile ? "flex" : undefined,
              fontFamily: "Public-Sans-Bold",
              height: isMobile ? "32px" : "49px",
              letterSpacing: "0.005em",
              marginLeft: isMobile ? "auto" : undefined,
              width: isMobile ? "88px" : "134px",
            }}
          />
        </ButtonBox>
      </TabsBox>
      <ContentBox>
        {currentTab === "jobs" && <JobsTab profile={profile} />}
      </ContentBox>
    </DashboardContainer>
  )
}

export default View
