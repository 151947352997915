import styled from "styled-components"

export const AvatarImg = styled.img<{
  border?: any
  hover?: any
  isNav: boolean
  isTempImage?: boolean
  plain: boolean
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  max-width: ${(props) => props.width}px;
  max-height: ${(props) => props.height}px;
  ${({ hover }) => (hover ? `cursor: pointer;` : "")}
  ${({ isNav, plain, border }) =>
    isNav
      ? `border-left: none;`
      : plain
      ? "border: none;"
      : border
      ? "border: 4px solid #F7F7F7;"
      : "border: none;"}
      
  ${({ isTempImage }) =>
    isTempImage ? `object-fit: contain;` : "object-fit: cover;"}
  background-color: #fff;
`
