import React, { useEffect, useState } from "react"

import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { fetchProfileSuggestion, Profile } from "../../redux/profile"
import { Collaborator } from "../../redux/projects"

type Props = {
  initialCollaborators?: Collaborator[]
  onChange: (values: Profile | null) => void
  initialCollaborator?: Collaborator
  index: number
}

const ProjectCollaborator = ({
  initialCollaborators,
  initialCollaborator,
  index,
  onChange,
}: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const initialProfile = {
    display: initialCollaborator?.name,
    cleanId: initialCollaborator?.profileId,
  } as Profile

  const [suggestions, setSuggestions] = useState<Profile[]>([])
  const [textValue, setTextValue] = React.useState<string>("")
  const [value, setValue] = React.useState<Profile | null>(
    initialProfile || null
  )

  const disabled = initialCollaborator && initialCollaborator.id ? true : false

  useEffect(() => {
    ;(async () => {
      try {
        let profiles: any = []
        if (initialCollaborators) {
          profiles = initialCollaborators.map((collaborator) => {
            return collaborator.profileId!
          })
        }

        const { payload } = await dispatch(
          fetchProfileSuggestion({ auth, query: textValue, profiles })
        )
        setSuggestions(payload)
      } catch (error) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line
  }, [dispatch, textValue, auth])

  const onInputChange = (newValue: string) => {
    setTextValue(newValue)
  }

  const handleSetValue = (newValue: Profile | null) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <View
      onInputChange={onInputChange}
      suggestions={suggestions || []}
      onSetValue={handleSetValue}
      value={value}
      disabled={disabled}
      index={index}
    />
  )
}

export default ProjectCollaborator
