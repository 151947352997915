import React from "react"
import styled from "styled-components"
import { motion, AnimatePresence, PanInfo } from "framer-motion"

import {
  LeftArrowIcon,
  RightArrowIcon,
  IconOverlayLeft,
  IconOverlayRight,
  ClearOverlayContainer,
} from "./Styles"

import { useWindowDimensions } from "../../hooks"
import { VideoType } from "."
import ReactPlayer from "react-player"

import PlayIcon from "../../images/play-icon.png"
import theme from "../../theme"

const variants = {
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
}

const SWIPE_CONFIDENCE_THRESHOLD = 10000

const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity

const AnimatedContainer = styled(motion.div)<{}>`
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  padding: 0 80px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

interface Props {
  videos: VideoType[]
  selectedIndex: number
  onSelect: (img: VideoType, idx: number) => void
}

const Projector: React.FC<Props> = ({ videos, onSelect, selectedIndex }) => {
  const { width } = useWindowDimensions()
  const [[page, direction], setPage] = React.useState([0, 0])

  React.useEffect(() => setPage([selectedIndex, 1]), [selectedIndex])

  const nextIndex = (selectedIndex + 1) % videos.length
  const prevIndex = (selectedIndex + videos.length - 1) % videos.length

  const paginate = (newDirection: number) =>
    setPage([page + newDirection, newDirection])

  const next = () => {
    paginate(1)
    onSelect(videos[nextIndex], nextIndex)
  }

  const prev = () => {
    paginate(-1)
    onSelect(videos[prevIndex], prevIndex)
  }

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    { offset, velocity }: PanInfo
  ) => {
    const swipe = swipePower(offset.x, velocity.x)
    if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) next()
    else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) prev()
  }

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <AnimatedContainer
          drag="x"
          key={page}
          exit="exit"
          initial="enter"
          animate="center"
          dragElastic={1}
          custom={direction}
          variants={variants}
          onDragEnd={handleDragEnd}
          dragConstraints={{ left: 0, right: 0 }}
          transition={{
            opacity: { duration: 0.2 },
            x: { type: "spring", stiffness: 300, damping: 30 },
          }}
        >
          <ReactPlayer
            className="react-player fixed-bottom"
            url={videos[selectedIndex].url}
            width="100%"
            height="100%"
            controls={true}
            light={videos[selectedIndex].bigThumbnail}
            playing={true}
            playIcon={<img src={PlayIcon} alt={"play"} />}
          />
        </AnimatedContainer>
      </AnimatePresence>
      {width >= 960 ? (
        <>
          <IconOverlayLeft>
            <ClearOverlayContainer onClick={() => prev()}>
              <RightArrowIcon style={{ height: "100%", width: "100%" }} />
            </ClearOverlayContainer>
          </IconOverlayLeft>
          <IconOverlayRight>
            <ClearOverlayContainer onClick={() => next()}>
              <LeftArrowIcon style={{ height: "100%", width: "100%" }} />
            </ClearOverlayContainer>
          </IconOverlayRight>
        </>
      ) : null}
    </>
  )
}

export default Projector
