import React, { useState } from "react"
import { Grid, Box } from "@material-ui/core"

import LightButtressDivider from "../../../../components/LightButtressDivider"
import DropDown from "../../../../components/DropDown"
import FilterJobDialog from "../../../../components/FilterJobDialog"
import { MobileSearchBar } from "../../../../components/Styles/MainSearchBar"
import { JobListing } from "../../../../redux/jobListings"
import SearchIcon from "../../../../images/mobile-search.svg"
import ClearFilter from "../../../../images/clear-filter.svg"
import ClearFilterActive from "../../../../images/clear-filter-active.svg"

import LoadingPage from "../../../LoadingPage"
import InfiniteScroll from "react-infinite-scroll-component"

import FilterIcon from "../../../../images/mobile-filter-icon.svg"
import MobileSearchIcon from "../../../../images/mobile-search.svg"

import { JobCard } from "../../../../cards/JobCard"

import {
  JobMainGrid,
  CounterText,
  CounterArea,
  CounterFilterArea,
  DividerBox,
  FilterBox,
  ClearFilterBox,
  ClearFilterBoxActive,
  Search,
  MobileFilterBox,
} from "../../../JobsPage/styles"

import { SearchBoxArea, SearchBar, ListingTitle, FormBox } from "./styles"

import theme from "../../../../theme"
import { useWindowDimensions } from "../../../../hooks"
import DropDownMultiselect from "../../../../components/JobsDropDownMultiselect"
import { ActionsType as MultiActionsType } from "../../../../components/JobsDropDownMultiselect/View"
import { ActionsType } from "../../../../components/DropDown/View"

export type Props = {
  term: string
  jobs: JobListing[]
  filterBy: string
  status: string
  page: number
  jobsCount: number
  workplaceType: MultiActionsType[]
  experienceLevel: MultiActionsType[]
  employmentType: MultiActionsType[]
  resetedValues: boolean
  hasMore: boolean
  onTermChanged: (value: string) => void
  handleChangeStatus: (value: string) => void
  handleChangeFilterBy: (value: string) => void
  handleSearchSubmit: () => void
  handleClearFilters: () => void
  handleChangePagination: (value: number) => void
  handleChangeWorkplaceType: (value: MultiActionsType[]) => void
  handleChangeExperienceLevel: (value: MultiActionsType[]) => void
  handleChangeEmploymentType: (value: MultiActionsType[]) => void
  handleChangeHasMore: (value: boolean) => void
}

const View = ({
  term,
  jobs,
  jobsCount,
  filterBy,
  status,
  page,
  workplaceType,
  experienceLevel,
  employmentType,
  resetedValues,
  hasMore,
  onTermChanged,
  handleChangeFilterBy,
  handleChangeStatus,
  handleClearFilters,
  handleSearchSubmit,
  handleChangePagination,
  handleChangeWorkplaceType,
  handleChangeExperienceLevel,
  handleChangeEmploymentType,
  handleChangeHasMore,
}: Props) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false)

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const fetchMoreData = () => {
    if (jobs.length >= jobsCount) {
      handleChangeHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  const showActions = [
    {
      action: "all",
      display: "All",
    },
    {
      action: "open",
      display: "Open",
    },
    {
      action: "closed",
      display: "Closed",
    },
  ]

  const sortActions = [
    {
      action: "recent",
      display: "Most Recent",
    },
    {
      action: "oldest",
      display: "Oldest",
    },
    {
      action: "title",
      display: "Alphabetical",
    },
  ]

  const handleShow = (value: ActionsType) => {
    handleChangeStatus(value.action)
  }

  const handleSortBy = (value: ActionsType) => {
    handleChangeFilterBy(value.action)
  }

  const handleSearch = (e: any) => {
    handleSearchSubmit()
    e.preventDefault()
  }

  const handleClose = () => {
    setOpenMobileFilter(false)
  }

  const handleOpen = () => {
    setOpenMobileFilter(true)
  }

  const handleApplyMobileFilters = (value: any) => {
    handleChangeWorkplaceType(value.workplaceType)
    handleChangeEmploymentType(value.employmentType)
    handleChangeExperienceLevel(value.experienceLevel)
  }

  const handleApplyFilters = (type: string, value: MultiActionsType[]) => {
    if (type === "workplace-type") handleChangeWorkplaceType(value)
    else if (type === "employment-type") handleChangeEmploymentType(value)
    else if (type === "experience-level") handleChangeExperienceLevel(value)
  }

  return (
    <>
      {isMobile ? (
        <>
          <FormBox>
            <form onSubmit={handleSearch} style={{ display: "contents" }}>
              <Search
                src={MobileSearchIcon}
                alt={"search-icon"}
                onClick={handleSearch}
                style={{ marginTop: 0 }}
                data-testid="search-icon"
              />
              <MobileSearchBar
                onChange={(e) => onTermChanged(e.target.value)}
                data-testid="search-bar"
                placeholder="Search for jobs..."
              />
              <MobileFilterBox onClick={handleOpen}>
                <img src={FilterIcon} alt={"filter"} />
              </MobileFilterBox>
              <input
                type="submit"
                name="submit"
                data-testid="search-button"
                style={{ position: "fixed", marginLeft: "-10000px" }}
              />

              <FilterJobDialog
                open={openMobileFilter}
                onClose={handleClose}
                handleApply={handleApplyMobileFilters}
                workplaceType={workplaceType}
                employmentType={employmentType}
                experienceLevel={experienceLevel}
              />
            </form>
          </FormBox>
        </>
      ) : (
        <Box>
          <form onSubmit={handleSearch} style={{ display: "contents" }}>
            <FormBox>
              <Box>
                <ListingTitle tabIndex={0}>Jobs</ListingTitle>
              </Box>
              <SearchBoxArea>
                <img
                  src={SearchIcon}
                  alt={"search"}
                  data-testid="search-icon"
                  width={"20px"}
                />
                <SearchBar
                  value={term}
                  placeholder={"Search for jobs..."}
                  data-testid="search-bar"
                  onChange={(e) => onTermChanged(e.target.value)}
                />
              </SearchBoxArea>
            </FormBox>
          </form>
          <FilterBox paddingTop={5}>
            <DropDownMultiselect
              styles={{ width: "100%" }}
              actions={workplaceType}
              onApply={handleApplyFilters}
              title={"Workplace type"}
              type={"workplace-type"}
              reset={resetedValues}
            />

            <DropDownMultiselect
              styles={{ width: "100%" }}
              actions={employmentType}
              onApply={handleApplyFilters}
              title={"Employment type"}
              type={"employment-type"}
              reset={resetedValues}
            />

            <DropDownMultiselect
              styles={{ width: "100%" }}
              actions={experienceLevel}
              onApply={handleApplyFilters}
              title={"Experience level"}
              type={"experience-level"}
              reset={resetedValues}
            />
            {term ||
            workplaceType.find((value) => value.active) ||
            employmentType.find((value) => value.active) ||
            experienceLevel.find((value) => value.active) ? (
              <ClearFilterBoxActive
                onClick={handleClearFilters}
                style={{ minWidth: "111px" }}
              >
                <img src={ClearFilterActive} alt={"clear-filter"} />
                Clear Filters
              </ClearFilterBoxActive>
            ) : (
              <ClearFilterBox style={{ minWidth: "111px" }}>
                <img src={ClearFilter} alt={"clear-filter"} />
                Clear Filters
              </ClearFilterBox>
            )}
          </FilterBox>
        </Box>
      )}

      <Box style={isMobile ? { padding: "0 20px" } : {}}>
        <JobMainGrid container spacing={0} style={{ paddingBottom: 0 }}>
          <Grid item style={{ width: "100%" }}>
            <CounterArea paddingTop={isMobile ? 2.5 : 5}>
              <CounterText>
                Showing {jobsCount} {jobs?.length === 1 ? "result" : "results"}
              </CounterText>

              <CounterFilterArea>
                {!isMobile && <CounterText>Show:</CounterText>}

                <DropDown
                  defaultValue={status}
                  actions={showActions}
                  onClick={handleShow}
                  title={"Show"}
                />

                {!isMobile && (
                  <CounterText marginLeft={3}>Sort by:</CounterText>
                )}

                <DropDown
                  defaultValue={filterBy}
                  actions={sortActions}
                  onClick={handleSortBy}
                  title={"Sort"}
                />
              </CounterFilterArea>
            </CounterArea>
          </Grid>
        </JobMainGrid>

        <JobMainGrid container spacing={0}>
          <InfiniteScroll
            dataLength={jobs.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingPage />}
            style={{ overflow: "hidden" }}
          >
            <Grid
              container
              direction="row"
              style={
                isMobile
                  ? { width: "100%", flexDirection: "column" }
                  : { width: "100%" }
              }
            >
              <Box
                style={{
                  paddingTop: isMobile ? "20px" : "32px",
                  width: "100%",
                }}
              >
                {jobs.map((job, index) => (
                  <React.Fragment key={job.id!}>
                    <JobCard
                      job={job}
                      profile={job.profile!}
                      origin={"job-dashboard"}
                    />
                    {jobs.length - 1 !== index && (
                      <DividerBox>
                        <LightButtressDivider
                          style={{
                            backgroundColor: "rgb(216, 216, 216)",
                          }}
                        />
                      </DividerBox>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </InfiniteScroll>
        </JobMainGrid>
      </Box>
    </>
  )
}

export default View
