import React, { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import View from "./View"

import { Project, createProject, getS3SignedURL } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Camera } from "@capacitor/camera"

const ProjectWizardPage = () => {
  const auth = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = React.useState(false)
  const [typeSelected, setTypeSelected] = React.useState("individual")
  const [currentFile, setCurrentFile] = React.useState<string>("")
  const profile = useSelector((state: any) => state.profile.profile)
  const projectError = useSelector((state: any) => state.projects.error)
  const [uploadedImages, setUploadedImages] = React.useState<any[]>([])

  const [inviteCollaboratorDialogIsOpen, setinviteCollaboratorDialogIsOpen] =
    React.useState(false)

  const CheckGalleryAccess = useCallback(async () => {
    try {
      const permission = await Camera.checkPermissions()
      if (permission.camera !== "granted" || permission.photos !== "granted") {
        await Camera.requestPermissions({ permissions: ["camera", "photos"] })
      }
    } catch (error) {
      console.log("failed to load camera")
    }
  }, [])

  const handleChangeInviteCollaboratorDialog = (value: boolean) => {
    setinviteCollaboratorDialogIsOpen(value)
  }

  const handleCancel = () => {
    history.push("/")
  }

  const handleTypeSelected = (value: string) => {
    setTypeSelected(value)
  }

  const handleUploadFiles = async (project: Project, payload: Project) => {
    for (const key in project.images) {
      if ("file" in project.images[key as any]) {
        const projectImage = project.images[key as any]
        if (
          uploadedImages.findIndex((x) => x === projectImage.file.name) === -1
        ) {
          setUploadedImages([projectImage.file.name, ...uploadedImages])
          setCurrentFile(projectImage.file.name)

          const signedURL = await dispatch(
            getS3SignedURL({
              uploadType: "project",
              contentId: payload.id as string,
              fileName: projectImage.file.name,
              fileType: projectImage.file.type,
              order: projectImage.order,
              credits: projectImage.credits,
              caption: projectImage.caption,
              width: projectImage.dimensions?.width,
              height: projectImage.dimensions?.height,
              auth,
            })
          )

          //Upload the image to the S3 bucket
          const myHeaders = new Headers()
          myHeaders.append("Content-Type", project.images[key as any].file.type)

          await fetch(signedURL.payload, {
            method: "PUT",
            headers: myHeaders,
            body: project.images[key as any].file,
          })
        }
      }
    }
  }

  const handleProjectCreate = async (project: Project, published: boolean) => {
    try {
      if (project.type === "") {
        delete project.type
        delete project.firmName
      }

      if (!project.published) {
        project.name = project.name === "" ? "My Project" : project.name
        project.state = project.state === "" ? "unbuilt" : project.state
      }

      const { payload, type } = await dispatch(
        createProject({
          project: project,
          auth,
          published,
        })
      )

      if (createProject.fulfilled.type === type) {
        if (project.images && project.images?.length > 0) {
          setIsUploading(true)
          await handleUploadFiles(project, payload)
          setIsUploading(false)
        }

        if (!project.published) {
          project.name = ""
          project.state = ""
        }

        history.push(`/sp/${profile.username}/projects/${payload.id}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    CheckGalleryAccess()
  }, [CheckGalleryAccess])

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Create Project`}
        classTitle="CreateProject"
      />
      <Helmet>
        <title>Create project - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Create project - Spectacular`} />
        <meta name="title" content={`Create project - Spectacular`} />
      </Helmet>
      <View
        currentFile={currentFile}
        typeSelected={typeSelected}
        profile={profile}
        error={projectError}
        onCancel={handleCancel}
        isUploading={isUploading}
        inviteCollaboratorDialogIsOpen={inviteCollaboratorDialogIsOpen}
        onSubmit={handleProjectCreate}
        onTypeSelected={handleTypeSelected}
        onChangeInviteCollaboratorDialog={handleChangeInviteCollaboratorDialog}
      />
    </>
  )
}

export default ProjectWizardPage
