import React, { useState } from "react"
import { Box, Grid } from "@material-ui/core"

import { useHistory } from "react-router-dom"

import { Profile } from "../../../../redux/profile"
import { JobListing } from "../../../../redux/jobListings"
import JobRequestDialog from "../../../../components/JobRequestDialog"
import LightButtressDivider from "../../../../components/LightButtressDivider"
import DropDown from "../../../../components/DropDown"

import { paginate } from "../../../../utils"
import { useWindowDimensions } from "../../../../hooks"
import { useSelector } from "react-redux"

import * as RouteConstants from "../../../../constants/routes"

import { MainPagination } from "../Styles"
import {
  CounterText,
  CounterArea,
  CounterFilterArea,
  JobMainGrid,
  CounterAreaGroup,
  CounterFilterAreaGroup,
  JobAdArea,
} from "./Styles"
import { NullstateTab } from "../../styles"

import { JobCard } from "../../../../cards/JobCard"
import Ads from "../../../../components/Ads"
import ProfileNullstate from "../../../../components/ProfileNullstate"

interface Props {
  profile: Profile
  isPublicProfile: boolean
  jobListings: JobListing[]
  onAddJobListing: () => void
}

export const JobListingsDetails = ({
  profile,
  jobListings,
  isPublicProfile,
  onAddJobListing,
}: Props) => {
  const { width } = useWindowDimensions()
  const [open, setOpen] = useState(false)

  const [activeSort, setActiveSort] = useState({
    action: "recent",
    display: "Most Recent",
  })

  const [filteredJobListing, setFilteredJobListing] = useState(
    !isPublicProfile
      ? jobListings
      : jobListings.filter((job) => job.status === "open")
  )
  const [pagination, setPagination] = useState(
    paginate(
      !isPublicProfile
        ? jobListings
        : jobListings.filter((job) => job.status === "open")
    )
  )

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const history = useHistory()

  const goToUrl = (url: string) => history.push(url)

  const handleSortBy = (value: any, filteredListing?: JobListing[]) => {
    let sortedListing: any = []

    setActiveSort(value)

    const jobList = filteredListing ? filteredListing : filteredJobListing

    if (value.action === "recent") {
      sortedListing = [...jobList].sort(
        (a, b) =>
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
      )
    } else if (value.action === "oldest") {
      sortedListing = [...jobList].sort(
        (a, b) =>
          new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()
      )
    } else if (value.action === "title") {
      sortedListing = [...jobList].sort((a, b) =>
        a.title.localeCompare(b.title)
      )
    }

    setFilteredJobListing(sortedListing)

    setPagination(paginate(sortedListing, 1))
  }

  const handleShow = (value: any) => {
    let filteredListing: any = []
    if (value.action === "open") {
      filteredListing = jobListings.filter((job) => job.status === "open")
    } else if (value.action === "close") {
      filteredListing = jobListings.filter((job) => job.status === "closed")
    } else {
      filteredListing = jobListings
    }

    setFilteredJobListing(filteredListing)

    handleSortBy(activeSort, filteredListing)
  }

  const showActions = [
    {
      action: "all",
      display: "All",
    },
    {
      action: "open",
      display: "Open",
    },
    {
      action: "close",
      display: "Closed",
    },
  ]

  const sortActions = [
    {
      action: "recent",
      display: "Most Recent",
    },
    {
      action: "oldest",
      display: "Oldest",
    },
    {
      action: "title",
      display: "Alphabetical",
    },
  ]

  const isMobile = width < 960

  const CounterAreaComponent = !isPublicProfile ? CounterAreaGroup : CounterArea
  const CounterAreaFilterComponent = !isPublicProfile
    ? CounterFilterAreaGroup
    : CounterFilterArea

  const tab = authenticatedProfile?.type === "group" ? "/job_listings" : ""

  const toggleOpen = (toggle: boolean) => setOpen(toggle)

  const handlePagination = (_: any, page: number) =>
    setPagination(paginate(jobListings, page))

  const GroupView = () => (
    <Grid container direction="row">
      {isMobile && (
        <Grid item sm={12} style={{ paddingTop: "24px", width: "100%" }}>
          <div style={{ paddingBottom: "16px" }}>
            <Ads type={"post-job"} onClick={onAddJobListing} />
          </div>
          <Ads type={"request-help"} onClick={() => toggleOpen(true)} />
        </Grid>
      )}

      <Grid item sm={12} md={8}>
        <NullstateTab style={{ paddingBottom: "60px" }}>
          <ProfileNullstate onClick={onAddJobListing} type={"owner-jobs-tab"} />
        </NullstateTab>
      </Grid>

      {!isMobile && (
        <Grid item md={4} style={{ paddingTop: "40px", paddingBottom: "96px" }}>
          <div style={{ paddingBottom: "20px" }}>
            <Ads type={"post-job"} onClick={onAddJobListing} />
          </div>
          <Ads type={"request-help"} onClick={() => toggleOpen(true)} />
        </Grid>
      )}

      <JobRequestDialog open={open} onClose={() => toggleOpen(false)} />
    </Grid>
  )

  const IndividualView = () => (
    <Box style={{ padding: 0 }}>
      <NullstateTab>
        <ProfileNullstate
          onClick={() => goToUrl("/jobs")}
          type={"group-jobs-tab"}
        />
      </NullstateTab>
    </Box>
  )

  if (jobListings.length === 0 && !isPublicProfile) return <GroupView />

  if (jobListings.length === 0 && isPublicProfile) return <IndividualView />

  return (
    <>
      <JobMainGrid container spacing={0} style={{ paddingBottom: 0 }}>
        <Grid
          item
          style={
            isMobile
              ? { width: "100%" }
              : { width: "100%", paddingRight: "40px" }
          }
        >
          <CounterAreaComponent paddingTop={isMobile ? 2.5 : 5}>
            <CounterText>
              Showing {filteredJobListing?.length}{" "}
              {filteredJobListing?.length === 1 ? "result" : "results"}
            </CounterText>

            <CounterAreaFilterComponent>
              {!isPublicProfile && (
                <>
                  <CounterText>Show:</CounterText>

                  <DropDown
                    actions={showActions}
                    onClick={handleShow}
                    title={"Show"}
                  />
                </>
              )}

              <CounterText marginLeft={isPublicProfile ? 0 : 3}>
                Sort by:
              </CounterText>

              <DropDown
                actions={sortActions}
                onClick={handleSortBy}
                title={"Sort"}
              />
            </CounterAreaFilterComponent>
          </CounterAreaComponent>
        </Grid>
        {!isMobile && (
          <Grid item style={{ minWidth: "368px", maxWidth: "368px" }}></Grid>
        )}
      </JobMainGrid>

      <JobMainGrid container spacing={0}>
        <Grid
          item
          style={
            isMobile
              ? { width: "100%" }
              : { width: "100%", paddingRight: "40px" }
          }
        >
          {pagination.items.map((job, index) => (
            <Box style={{ paddingTop: isMobile ? "24px" : "32px" }}>
              <JobCard job={job} profile={profile} origin={"groups-page"} />
              {index < pagination.items.length - 1 && (
                <Box style={{ paddingTop: isMobile ? "24px" : "40px" }}>
                  <LightButtressDivider
                    style={{
                      backgroundColor: "rgb(216, 216, 216)",
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}

          {pagination.totalPages > 1 && (
            <Box
              paddingTop={8}
              paddingBottom={8}
              style={
                isMobile ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {pagination.totalPages > 1 && (
                <MainPagination
                  variant="outlined"
                  page={pagination.page}
                  onChange={handlePagination}
                  count={pagination.totalPages}
                  data-testid="load-more-projects-pagination"
                />
              )}
            </Box>
          )}
        </Grid>

        <JobAdArea
          item
          sm={12}
          style={{ paddingBottom: !isMobile ? "96px" : 0 }}
        >
          {!isPublicProfile && jobListings.length > 0 && (
            <>
              <div style={{ paddingBottom: isMobile ? "16px" : "20px" }}>
                <Ads type={"post-job"} onClick={onAddJobListing} />
              </div>
              <Ads type={"request-help"} onClick={() => toggleOpen(true)} />
            </>
          )}
          {isPublicProfile && (
            <Ads
              type={"edit-profile"}
              onClick={() =>
                authenticatedProfile
                  ? goToUrl(
                      `${RouteConstants.PROFILE_CONTAINER}/${authenticatedProfile?.username}${tab}`
                    )
                  : goToUrl("/register")
              }
            />
          )}
        </JobAdArea>
      </JobMainGrid>
      <JobRequestDialog open={open} onClose={() => toggleOpen(false)} />
    </>
  )
}
