import React from "react"
import "react-image-lightbox/style.css"
import { Box, Typography } from "@material-ui/core"

import Carousel from "./Carousel"
import {
  LargeImageContainer,
  ProjectContainer,
  TitleBox,
  VideoSubtitle,
  VideoTitle,
} from "./Styles"

import { useWindowDimensions } from "../../hooks"
import { VideoGalleryProps, VideoType } from "."
import Projector from "./Projector"

export const Description: React.FC<{ value: string }> = ({ value }) => {
  const { width } = useWindowDimensions()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {width <= 550 ? (
        <Typography style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
          {open || value.length < 200 ? value : value.slice(0, 200) + "..."}
        </Typography>
      ) : (
        <Typography style={{ whiteSpace: "pre-line" }}>{value}</Typography>
      )}
      {value.length >= 200 && width <= 550 ? (
        <div
          style={{ color: "#3057E1", cursor: "pointer", paddingTop: "16px" }}
          onClick={() => setOpen(!open)}
        >
          <b>{!open ? "Read more" : "Show less"}</b>
        </div>
      ) : null}
    </>
  )
}

interface Props extends VideoGalleryProps {
  selectedIndex: number
  onSelect: (img: VideoType, idx: number) => void
}

const View: React.FC<Props> = ({ videos, onSelect, selectedIndex }) => {
  const { width } = useWindowDimensions()

  const carouselRef = React.useRef<any | undefined>(undefined)

  return (
    <ProjectContainer>
      <Box
        style={{
          position: "relative",
          width: "100%",
          height: "0",

          paddingBottom: width <= 959 ? "54.6667%" : "47.6667%",
        }}
      >
        <LargeImageContainer>
          <Projector
            videos={videos}
            onSelect={onSelect}
            selectedIndex={selectedIndex}
          />
        </LargeImageContainer>
      </Box>

      <TitleBox>
        <VideoTitle>
          <span>{videos[selectedIndex].name} /</span>{" "}
          {videos[selectedIndex].title}
        </VideoTitle>
        <VideoSubtitle>{videos[selectedIndex].subTitle}</VideoSubtitle>
      </TitleBox>

      <Box style={{ marginTop: "16px" }}>
        <Carousel videos={videos} ref={carouselRef} onSelect={onSelect} />
      </Box>
    </ProjectContainer>
  )
}

export default View
