import React, { useState } from "react"
import { Box } from "@material-ui/core"
import {
  FilterDialog,
  FilterDialogBox,
  FilterDialogTitle,
  DialogOption,
  FilterDialogHeader,
  DialogCloseButton,
} from "./Styles"
import { Button } from "../Button"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import "./fix-dialog.css"

export type Props = {
  onClose: () => void
  open: boolean
}

const Dialog = ({ onClose, open }: Props) => {
  const [hover, setHover] = useState(false)

  return (
    <FilterDialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      className={"DropDownFilterDialog"}
    >
      <FilterDialogHeader>
        <FilterDialogTitle>TYpology</FilterDialogTitle>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </FilterDialogHeader>
      <FilterDialogBox>
        <Box paddingRight={2.5} paddingLeft={2.5}>
          <DialogOption>test</DialogOption>
        </Box>
        <Box
          paddingRight={2.5}
          paddingLeft={2.5}
          paddingTop={3}
          style={{ marginTop: "auto" }}
        >
          <Button
            style={{
              width: "100%",
              height: "48px",
              fontSize: "14px",
              fontWeight: 700,
            }}
            color="primary"
            size="small"
            type="submit"
            onClick={() => {}}
          >
            Show results
          </Button>
        </Box>
      </FilterDialogBox>
    </FilterDialog>
  )
}

export default Dialog
