import styled from "styled-components"
import theme, { colors } from "../../theme"
import { ReactComponent as ArrowIcon } from "../../images/video-gallery-left-arrow.svg"

import { ReactComponent as LeftArrowIconSVG } from "../../images/video-gallery-right-arrow.svg"
import { Box, Typography } from "@material-ui/core"

export const RightPaginationArrow = styled(ArrowIcon)`
  transform: rotate(180deg);
`
export const LeftPaginationArrow = ArrowIcon

export const RightArrowIcon = styled(LeftArrowIconSVG)`
  transform: rotate(180deg);
`

export const LeftArrowIcon = LeftArrowIconSVG

export const LoaderContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const CarouselContainer = styled.div`
  padding: 16px 62px;

  ${theme.breakpoints.down("sm")} {
    padding: ${theme.spacing(2)}px 0;
    justify-content: center;
  }

  && div {
    justify-content: center;
  }

  && div.rec-carousel-item {
    padding: 0;
  }

  && div.rec-slider-container {
    margin: 0 8px;
  }

  && figure {
    height: 0;
    padding: 0 0 66.6666667%;
    position: relative;
    margin: 0;
  }
`

export const ThumbnailContainer = styled.div`
  width: 220px;

  cursor: pointer;
  transition: border 0.23s ease-in-out;

  ${theme.breakpoints.down("sm")} {
    width: calc(85.45% + 0.14px);
    max-width: 200px;
  }
`

export const ImageThumbnail = styled.img`
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
`

export const CarouselFooter = styled.div`
  height: 24px;
  width: 100%;
  text-align: center;
  background: ${colors.dark};

  ${theme.breakpoints.down("sm")} {
    margin-left: -${theme.spacing(2)}px;
    margin-right: -${theme.spacing(2)}px;
    width: calc(100% + 32px);
  }
`

export const ArrowContainer = styled.div<{ disabled: boolean }>`
  color: gray;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: center;
  transition: color 0.23s ease-in-out;

  :first-of-type {
    padding: 0 ${theme.spacing(1)}px 0 ${theme.spacing(2)}px;
  }

  :last-of-type {
    padding: 0 ${theme.spacing(2)}px 0 ${theme.spacing(1)}px;
  }

  :hover {
    color: ${({ disabled }) => (disabled ? "gray" : colors.dark)};
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing(2)}px;
  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const Square = styled.div<{ isActive: boolean }>`
  height: 5px;
  width: 45px;
  cursor: pointer;
  margin-left: ${theme.spacing(0.5)}px;
  margin-right: ${theme.spacing(0.5)}px;
  transition: background 0.23s ease-in-out;
  background: ${(props) =>
    props.isActive ? "rgba(15, 15, 15, 0.666667)" : colors.white};
`

export const IconOverlayTopRight = styled.div`
  top: ${theme.spacing(1)}px;
  right: ${theme.spacing(1)}px;
  z-index: 2;
  position: absolute;
`

export const IconOverlayRight = styled.div`
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.spacing(2)}px;
  z-index: 2;
  position: absolute;
`

export const IconOverlayTopLeft = styled.div`
  top: ${theme.spacing(2)}px;
  left: ${theme.spacing(2)}px;
  z-index: 2;
  position: absolute;
  display: flex;
`

export const IconOverlayLeft = styled.div`
  top: 50%;
  transform: translateY(-50%);
  left: ${theme.spacing(2)}px;
  z-index: 2;
  position: absolute;
`

export const ClearOverlayContainer = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  font-size: small;
  align-items: center;
  justify-content: center;
`

export const OpaqueOverlayContainer = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 0 3px;
  display: flex;
  font-size: small;
  align-items: center;
  justify-content: center;
  background: rgba(250, 250, 250, 0.666667);

  transition: background 0.23s ease-in-out;

  :hover {
    background: rgba(250, 250, 250, 1);
  }
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const ListItemOverlay = styled.div`
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
`

export const LargeImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  height: 100%;
  justify-content: center;
`

export const ProjectContainer = styled.div`
  ${theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;

    && > {
      div:nth-child(1) {
        order: 0;
        margin-left: -${theme.spacing(2)}px;
        margin-right: -${theme.spacing(2)}px;
        padding-left: ${theme.spacing(2)}px;
        padding-right: ${theme.spacing(2)}px;
      }

      div:nth-child(2) {
        order: 1;
        padding-bottom: 0;
      }

      div:nth-child(3) {
        order: 2;
        margin-left: -${theme.spacing(2)}px;
        margin-right: -${theme.spacing(2)}px;
      }
    }
  }
`

export const LargeImage = styled.img`
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

export const ExpandedRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  && > div {
    width: 120px;
    height: 80px;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 1%;
  }
`

export const VideoPlayerBox = styled.div`
  width: 720px;
  margin-left: auto;
  margin-right: auto;

  && video {
    max-height: 405px;
  }

  @media only screen and (max-width: 1090px) {
    width: 650px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const TitleBox = styled(Box)`
  padding: 24px 80px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 20px 0;
  }
`

export const VideoTitle = styled(Typography)`
  font-size: 24px;
  line-height: 34px;
  color: #fff;

  && span {
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 24px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    line-height: 22px;
  }
`

export const VideoSubtitle = styled(Typography)`
  font-size: 20px;
  line-height: 34px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 20px;
  }
`
