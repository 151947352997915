import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
import { Profile } from "./profile"

//#region types
export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  ambassadors: Profile[]
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
export const getAmbassadors = createAsyncThunk<any>(
  "ambassadors/get",
  async () => {
    return useApi(null, "/ambassadors", {
      method: "GET",
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  ambassadors: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAmbassadors.fulfilled, (state, action) => {
        state.ambassadors = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(getAmbassadors.pending, (state, action) => {
        state.ambassadors = []
        state.status = LoadingStatuses.Loading
      })
  },
})
//#endregion

//#region selectors
export const selectAmbassadors = ({ ambassador }: RootState) =>
  ambassador.ambassadors
export const selectAmbassadorsStatus = ({ ambassador }: RootState) =>
  ambassador.status
//#endregion
