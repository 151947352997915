import { Button, Box } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const ActionButton = styled(Button)`
  && {
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
  }
`

export const RichTextEditorContainer = styled(Box)`
  padding-bottom: ${theme.spacing(10)}px;
  padding-right: ${theme.spacing(5)}px;
  border-top: solid 0.5px ${colors.slate};
  border-left: solid 0.5px ${colors.slate};
  border-right: solid 0.5px ${colors.slate};
  border-bottom: solid 0.5px ${colors.slate};
  && {
    #mui-rte-editor {
      padding: 10px;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
`
