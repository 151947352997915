import React from "react"

import { Box } from "@material-ui/core"

import WeibinarLogo from "../../images/career-fair/webinar-logo.svg"
import WebinarImage from "../../images/career-fair/webinar-image.svg"
import AIA from "../../images/career-fair/aias.svg"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
  HeaderDescription,
  HeaderFlexBox,
  LottieBox,
  AIABox,
  AIAText,
  AIAImage,
} from "./styles"

type Props = {
  currentView: string
}

const Header = ({ currentView }: Props) => {
  return (
    <HeaderBox $isHomepage={currentView === "home"}>
      <HeaderInnerBox>
        <HeaderContainer>
          <HeaderFlexBox>
            <Box>
              <Box>
                <StyledLogo src={WeibinarLogo} alt={"Logo"} />
              </Box>
              <AIABox>
                <AIAText>In Partnership with</AIAText>
                <AIAImage>
                  <img src={AIA} alt="AIAS" />
                </AIAImage>
              </AIABox>
              {currentView === "home" ? (
                <HeaderDescription>
                  Spectacular is hosting a live webinar on April 12th to help
                  our community of talented young designers get a foot in the
                  door to some of the most sought after summer internships in
                  Architecture. During the webinar you’ll have the opportunity
                  to hear from top firms and receive priority access to amazing
                  summer internships in your area!
                </HeaderDescription>
              ) : null}
            </Box>
            {currentView === "home" ? (
              <LottieBox>
                <img src={WebinarImage} alt="Webinar" />
              </LottieBox>
            ) : null}
          </HeaderFlexBox>
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
