import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { dislikeProject, likeProject, Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { updateFeedState } from "../../redux/feed"
import { logEvent } from "../../utils/analytics"

type Props = {
  project: Project
}

const ProjectLikesIcon = ({ project }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)

  const handleLikeClick = async (like: boolean): Promise<void> => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    if (like) {
      handleProjectDislike()
    } else {
      handleProjectLike()
    }
  }

  const handleProjectLike = async () => {
    setDispatchComplete(false)
    try {
      const likeStatus = await dispatch(likeProject({ auth, id: project.id! }))
      if (likeStatus.type === "projects/like/fulfilled") {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "project",
            id: project.id!,
            action: "like",
          })
        )

        await logEvent("project_like")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleProjectDislike = async () => {
    setDispatchComplete(false)
    try {
      const disLikeStatus = await dispatch(
        dislikeProject({ auth, id: project.id! })
      )
      if (disLikeStatus.type === "projects/dislike/fulfilled") {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "project",
            id: project.id!,
            action: "dislike",
          })
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      project={project}
      handleLikeClick={handleLikeClick}
    />
  )
}

export default ProjectLikesIcon
