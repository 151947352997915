import { useEffect } from "react"

export const useScript = (url: string, async: boolean = false) => {
  useEffect(() => {
    const placement = document.querySelector("body")
    const script = document.createElement("script")
    script.src = url
    script.async = typeof async === "undefined" ? true : async
    placement?.appendChild(script)
    return () => {
      placement?.removeChild(script)
    }
  }, [url, async])
}
