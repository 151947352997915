import React from "react"

import Countdown from "react-countdown"

import {
  JuryReviewContainer,
  JuryReviewBox,
  JuryBorder,
  JuryReviewTitle,
  JuryBorderBottom,
  JuryReviewList,
  JuryReviewCard,
  JuryReviewCardImage,
  JuryReviewCardDescription,
  JuryReviewCardTitle,
  JuryReviewCardText,
  JuryReviewCardHint,
  CountdownFlexBoxAnnouncement,
  CountdownItemAnnouncement,
  CountdownTextAnnouncement,
  CountdownLabelAnnouncement,
  JuryCountdown,
  JuryCountdownText,
  JuryCountdownBox,
  JuryCountdownTitle,
  JuryCountdownDescription,
  JuryCountdownBoxTitle,
} from "./styles"

import Winners from "../../images/innovation-awards/winners-logo.svg"
import { Button } from "../../components"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Competition } from "../../redux/competition"

interface Props {
  goToProjects: () => void
  competition: Competition
}

const renderer = ({ days, hours, minutes }: any) => {
  return (
    <CountdownFlexBoxAnnouncement>
      <CountdownItemAnnouncement>
        <CountdownTextAnnouncement>
          {String(days).padStart(2, "0")}
        </CountdownTextAnnouncement>
        <CountdownLabelAnnouncement>DAYS</CountdownLabelAnnouncement>
      </CountdownItemAnnouncement>
      <CountdownItemAnnouncement>
        <CountdownTextAnnouncement>
          {String(hours).padStart(2, "0")}
        </CountdownTextAnnouncement>
        <CountdownLabelAnnouncement>HOURS</CountdownLabelAnnouncement>
      </CountdownItemAnnouncement>
      <CountdownItemAnnouncement>
        <CountdownTextAnnouncement>
          {String(minutes).padStart(2, "0")}
        </CountdownTextAnnouncement>
        <CountdownLabelAnnouncement>MINUTES</CountdownLabelAnnouncement>
      </CountdownItemAnnouncement>
    </CountdownFlexBoxAnnouncement>
  )
}

const renderHTML = (rawHTML: string) => {
  return React.createElement("div", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  })
}

const JuryReview = ({ goToProjects, competition }: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  return (
    <JuryReviewContainer>
      <JuryReviewBox>
        <JuryReviewTitle variant={"h1"}>Project Review</JuryReviewTitle>
      </JuryReviewBox>
      <JuryBorder />
      <JuryReviewList>
        {competition.projectReview?.map((item) => {
          return (
            <JuryReviewCard oriented={item.orientation}>
              {item.orientation === "left" && (
                <JuryReviewCardImage>
                  <img src={item.picture} alt={"Jury"} />
                </JuryReviewCardImage>
              )}
              <JuryReviewCardDescription oriented={item.orientation}>
                <JuryReviewCardTitle variant={"h2"}>
                  What the Jury Said
                </JuryReviewCardTitle>
                <JuryReviewCardText oriented={item.orientation}>
                  “{item.description}”
                </JuryReviewCardText>
                <JuryReviewCardHint>{renderHTML(item.name)}</JuryReviewCardHint>
              </JuryReviewCardDescription>
              {item.orientation === "right" && (
                <JuryReviewCardImage>
                  <img src={item.picture} alt={"Jury"} />
                </JuryReviewCardImage>
              )}
            </JuryReviewCard>
          )
        })}
      </JuryReviewList>
      <JuryBorderBottom />
      <JuryReviewBox>
        <JuryCountdown>
          <JuryCountdownText>
            <JuryCountdownTitle variant={"h2"}>
              Winner Announcement
            </JuryCountdownTitle>
            <JuryCountdownDescription>
              {renderHTML(competition.winnerAnnouncement!)}
            </JuryCountdownDescription>

            {!isMobile && (
              <Button
                onClick={goToProjects}
                children={"All Submissions"}
                color={"secondary"}
                size={"medium"}
                style={{
                  width: "194px",
                  height: "48px",
                  fontSize: "16px",
                }}
              />
            )}
          </JuryCountdownText>
          <JuryCountdownBox>
            <img src={Winners} alt={"Winners"} />
            <JuryCountdownBoxTitle>
              Announcement Countdown
            </JuryCountdownBoxTitle>
            <Countdown
              date={competition.winnerAnnouncementDate}
              renderer={renderer}
            />
          </JuryCountdownBox>

          {isMobile && (
            <Button
              onClick={goToProjects}
              children={"All Submissions"}
              color={"secondary"}
              size={"medium"}
              style={{
                width: "194px",
                height: "48px",
                fontSize: "16px",
              }}
            />
          )}
        </JuryCountdown>
      </JuryReviewBox>
    </JuryReviewContainer>
  )
}

export default JuryReview
