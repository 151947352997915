import { Box } from "@material-ui/core"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const Divider = styled(Box)`
  height: 25px;
`

export const BoxPaddingLeft = styled(Box)`
  ${theme.breakpoints.down("xs")} {
    padding: 0;
  }
  ${theme.breakpoints.up("sm")} {
    margin-left: 18px;
  }
`

export const BoxPaddingRightSpacing2 = styled(Box)`
  min-width: 48px;
  margin-right: ${theme.spacing(2)}px;

  ${theme.breakpoints.down("sm")} {
    margin-right: ${theme.spacing(2)}px;
  }
`
export const BoxPaddingRight = styled(Box)`
  min-width: 48px;
  margin-right: ${theme.spacing(3)}px;

  ${theme.breakpoints.down("sm")} {
    margin-right: ${theme.spacing(2)}px;
  }
`

export const TitleLink = styled(Link)`
  font-size: 25px;
  color: ${colors.dark};
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const BoxTitle = styled(Link)`
  font-size: 28px;
  cursor: pointer;
  padding-bottom: 8px;

  color: #2d2d2d;
  text-decoration: none;

  &:active {
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const BoxBody = styled(Box)`
  font-size: 14px;
`

export const BoxLocation = styled(Box)`
  font-size: 14px;
  color: ${colors.slate};
`

export const BoxGroupLocation = styled(Box)`
  font-size: 14px;
  span {
    color: ${colors.slate};
    border-bottom: solid 0.5px;
    border-color: ${colors.slate};
  }
`
