import React from "react"
import { Grid } from "@material-ui/core"

import {
  MainContainer,
  TextContainer,
  Text,
  Logo,
  LogoContainer,
  TextTitle,
} from "./styles"

const View = () => (
  <MainContainer>
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <LogoContainer>
            <Logo
              id="prompt-logo-center"
              src="https://spectacular.design/static/media/logo-charcoal.fff63924.svg"
              alt="Spectacular"
            />
          </LogoContainer>
          <TextTitle>You have been unsubscribed</TextTitle>
          <Text py={2}>You have been unsubscribed from these emails.</Text>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
