import React from "react"
import { Typography, Tabs, Grid, Hidden, Divider } from "@material-ui/core"

import { ProjectsTab } from "./ProjectsTab"

import AboutDetails from "../AboutDetails"
import { Profile } from "../../../redux/profile"
import { JobListingsDetails } from "./JobsTab"
import * as RouteConstants from "../../../constants/routes"

import AboutSelfSection from "../AboutSelfSection"

import { TabItem, ProfileDivider } from "../styles"

import theme from "../../../theme"
import { useWindowDimensions } from "../../../hooks"
import { PostsDetails } from "../PostsDetails"

interface Props {
  profile: Profile
  authenticatedProfile: Profile
  currentTab: string
  isPublicProfile: boolean
  onAddProject: () => void
  onAddJobListing: () => void
  goToUrl: (value: string) => void
  onTabClicked: (url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  onEditAbout: (plainText: string, richText: string) => Promise<void>
}

const GroupTabView = ({
  profile,
  authenticatedProfile,
  currentTab,
  onEditAbout,
  onAddProject,
  goToUrl,
  onTabChanged,
  onTabClicked,
  isPublicProfile,
  onAddJobListing,
}: Props) => {
  const { width } = useWindowDimensions()

  const projects = profile.projects ?? []

  const jobListings = profile.jobListings ?? []
  let projectCount = projects.length
  let jobsCount = jobListings.length

  if (isPublicProfile) {
    projectCount = projects.filter(
      (project) => project.published === true
    ).length

    jobsCount = jobListings.filter((job) => job.status === "open").length
  }

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={onTabChanged}
        indicatorColor="primary"
        style={{ borderBottom: "0.5px solid #D8D8D8", padding: 0 }}
        TabIndicatorProps={{ style: { height: "1px" } }}
      >
        <TabItem
          label={
            <Typography variant="h6" display="inline">
              Projects ({projectCount})
            </Typography>
          }
          value="projects"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}`
          )}
        />
        <TabItem
          label={
            <Typography tabIndex={0} variant="h6" display="inline">
              Jobs ({jobsCount})
            </Typography>
          }
          value="job_listings"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/job_listings`
          )}
        />
        <TabItem
          label={
            <Typography tabIndex={0} variant="h6" display="inline">
              About
            </Typography>
          }
          value="about"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/about`
          )}
        />
        <TabItem
          label={
            <Typography tabIndex={0} variant="h6" display="inline">
              Posts ({profile.postsQuantity})
            </Typography>
          }
          value="posts"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/posts`
          )}
        />
      </Tabs>
      {currentTab === "projects" && (
        <ProjectsTab
          projects={projects}
          featuredProject={profile.featuredProject!}
          onAddProject={onAddProject}
          goToUrl={goToUrl}
          isPublicProfile={isPublicProfile}
        />
      )}
      {currentTab === "job_listings" && (
        <JobListingsDetails
          profile={profile}
          jobListings={jobListings}
          isPublicProfile={isPublicProfile}
          onAddJobListing={onAddJobListing}
        />
      )}
      {currentTab === "about" && (
        <Grid
          container
          style={{
            paddingBottom: width >= theme.breakpoints.values.md ? "96px" : "",
          }}
        >
          <Hidden smDown>
            <Grid
              item
              md={3}
              aria-label="author details"
              style={{ paddingTop: "32px", paddingRight: "32px" }}
            >
              <AboutSelfSection profile={profile} />
            </Grid>
            <ProfileDivider item style={{ marginTop: "32px" }}>
              <Divider orientation="vertical" light={true} />
            </ProfileDivider>
          </Hidden>
          <Grid
            item
            md={9}
            sm={12}
            aria-label="about details"
            style={{
              width: "100%",
              marginLeft: width >= theme.breakpoints.values.md ? "-1px" : "",
              paddingLeft: width >= theme.breakpoints.values.md ? "32px" : "",
            }}
          >
            <AboutDetails
              profile={profile}
              authenticatedProfile={authenticatedProfile}
              onEditAbout={onEditAbout}
              isPublicProfile={isPublicProfile}
            />
          </Grid>
        </Grid>
      )}
      {currentTab === "posts" && (
        <PostsDetails
          isPublicProfile={isPublicProfile}
          profile={profile}
          authenticatedProfile={authenticatedProfile}
        />
      )}
    </>
  )
}

export default GroupTabView
