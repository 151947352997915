import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"

import {
  Profile,
  followProfile,
  unfollowProfile,
  sendNotificationEmailAndRemoveSuggestion,
} from "../../redux/profile"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  profile: Profile
  size?: string
  color?: string
  style?: React.CSSProperties
}

const FollowButton = ({ profile, size, color, style }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )
  const [hover, setHover] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onMouseOver = (value: boolean) => {
    setHover(value)
  }

  useEffect(() => {
    if (authenticatedProfile?.connections?.following) {
      const connected = authenticatedProfile?.connections?.following.filter(
        (connection) => {
          return connection.connectionId === profile.connectionId
        }
      )

      if (connected?.length! > 0) setIsFollowing(true)
    }
  }, [isFollowing, authenticatedProfile, profile])

  const handleFollow = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId
      const isAdmin = authenticatedProfile?.isAdmin

      const { type } = await dispatch(
        followProfile({ auth, profileId, connectionId, isAdmin })
      )

      setIsLoading(false)

      if (followProfile.fulfilled.type === type) {
        setIsFollowing(true)
        await dispatch(
          sendNotificationEmailAndRemoveSuggestion({
            auth,
            profileId: connectionId!,
            templateName: "PROFILE_FOLLOW",
          })
        )
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleUnfollow = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        unfollowProfile({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (unfollowProfile.fulfilled.type === type) {
        setIsFollowing(false)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  return (
    <>
      {authenticatedProfile &&
      authenticatedProfile?.connectionId !== profile.connectionId ? (
        <View
          style={style}
          onMouseOver={onMouseOver}
          hover={hover}
          isFollowing={isFollowing}
          isLoading={isLoading}
          onFollow={handleFollow}
          onUnfollow={handleUnfollow}
          size={size}
          color={color}
        />
      ) : !authenticatedProfile ? (
        <View
          style={style}
          onMouseOver={onMouseOver}
          hover={hover}
          isFollowing={isFollowing}
          isLoading={isLoading}
          onFollow={handleFollow}
          onUnfollow={handleUnfollow}
          size={size}
          color={color}
        />
      ) : null}
    </>
  )
}

export default FollowButton
