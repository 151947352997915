import styled from "styled-components"
import { Box, Typography, Button } from "@material-ui/core"

import theme from "../../theme"

export const AdButton = styled(Button)`
  height: 48px;
  width: 100%;
  padding: 8px 22px;
  border-radius: 1px;
  font-size: 16px;
  font-family: "Public-Sans";
  font-weight: 700 !important;

  && {
    font-weight: 700 !important;

    MuiButton-label {
      font-weight: 700 !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    max-height: 32px;
  }
`

export const RequestBoxWithJobs = styled(Box)`
  padding: 40px 32px 32px;
  background-color: #f7f7f7;

  && ul {
    padding: 0 20px;
    margin: 0;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const RequestBoxTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 40px;
  line-height: 45px;
  margin-top: -9px;

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 28px;
    margin-top: 0;
    padding-right: 20px;
  }
`

export const RequestButtonBox = styled(Box)`
  padding-top: 32px;
  margin-top: -3px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 0;
    margin-top: 0;
  }
`

export const RequestBoxText = styled(Typography)`
  font-family: "Public-Sans";
  font-size: 16px;
  color: #2d2d2d;
  padding-top: 20px;
`
