import React from "react"

import { IconContainer } from "./styles"

import JobsIcon from "../../images/app-navbar/jobs-icon.svg"
import JobsIconActive from "../../images/app-navbar/jobs-icon-active.svg"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { useLocation } from "react-router-dom"
import { logEvent } from "../../utils/analytics"

export const JobsNavIcon = () => {
  const location = useLocation()
  const active = location.pathname.includes("/jobs")

  const handleClick = async () => {
    await logEvent("navbar_jobs_click")
  }

  return (
    <LinkBox to={`/jobs`} onClick={handleClick}>
      <IconContainer $isActive={active}>
        <img alt="Jobs" src={active ? JobsIconActive : JobsIcon} />
        Jobs
      </IconContainer>
    </LinkBox>
  )
}
