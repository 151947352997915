import React from "react"
import { AvailableAds } from "../../constants/ads"

import { Box } from "@material-ui/core"
import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"

import {
  AdButton,
  RequestBoxText,
  RequestBoxTitle,
  RequestBoxWithJobs,
  RequestButtonBox,
} from "./styles"

export type Props = {
  onClick: () => void
  type:
    | "post-job"
    | "request-help"
    | "edit-profile"
    | "promote-job"
    | "recruitment"
}

const View = ({ onClick, type }: Props) => {
  const { width } = useWindowDimensions()
  const currentAd = AvailableAds.find((value) => value.type === type)

  return (
    <RequestBoxWithJobs>
      <Box>
        <RequestBoxTitle tabIndex={0} variant="h2">
          {currentAd?.title}
        </RequestBoxTitle>
      </Box>

      {width >= theme.breakpoints.values.md && (
        <>
          {currentAd && currentAd.text.length > 1 ? (
            <ul>
              {currentAd.text.map((row) => {
                return (
                  <li>
                    <RequestBoxText>{row}</RequestBoxText>
                  </li>
                )
              })}
            </ul>
          ) : (
            <RequestBoxText>{currentAd?.text}</RequestBoxText>
          )}
        </>
      )}

      <RequestButtonBox>
        <AdButton color="secondary" onClick={onClick}>
          {currentAd?.buttonText}
        </AdButton>
      </RequestButtonBox>
    </RequestBoxWithJobs>
  )
}

export default View
