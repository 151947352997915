import styled from "styled-components"
import { Box, Typography, Grid } from "@material-ui/core"
import theme from "../../theme"

export const NewMainContainer = styled(Box)<{ $hasMargin?: boolean }>`
  margin-top: ${({ $hasMargin }) => ($hasMargin ? "-40px" : "0")};

  @media only screen and (min-width: 1500px) {
    padding: 0 160px;
  }

  @media only screen and (max-width: 1500px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;
    margin-top: 0;
  }
`

export const MobileBox = styled(Box)`
  background-color: #f7f7f7;
  padding: 40px 20px 0;
  ${theme.breakpoints.down("sm")} {
    margin-top: -40px;
  }
  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
  }
`

export const DividerBox = styled(Box)`
  padding-top: 40px;
  padding-bottom: 32px;
  margin-top: -2px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: -5px;
  }
`
export const Search = styled.img`
  position: absolute;
  margin-top: 16px;
  margin-left: 16px;
`

export const ListingTitle = styled(Typography)`
  font-size: 50px;
  line-height: 61px;
  margin-top: -14px;
  margin-left: 2px;
  padding-bottom: 40px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    line-height: 48px;
    margin-top: -11px;
    padding-bottom: 0px;
  }
`
export const SearchBoxArea = styled(Box)`
  height: 80px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 1px;
  border: 0.5px solid #d8d8d8;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 24px;

  && img {
    margin-right: 16px;
  }
`

export const SearchBar = styled.input`
  border: 0;
  font-size: 20px;
  width: 100%;
  color: #2d2d2d;

  &:placeholder {
    color: #2d2d2d;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: #2d2d2d;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`

export const VerticalDivider = styled.div`
  border-right: 1px solid #d8d8d8;
  height: 100%;
  margin: 0 24px;
`

export const FilterBox = styled(Box)`
  display: flex;
  gap: 16px;
`

export const ClearFilterBox = styled(Box)`
  display: flex;
  align-items: center;
  color: #979797;
  margin-left: 8px;

  && img {
    margin-right: 6px;
  }
`

export const ClearFilterBoxActive = styled(Box)`
  display: flex;
  align-items: center;
  color: #2d2d2d;
  cursor: pointer;
  margin-left: 8px;

  && img {
    margin-right: 6px;
  }
`

export const MobileFilterBox = styled(Box)`
  min-width: 48px;
  height: 48px;
  margin-left: 8px;
  cursor: pointer;
  border: 0.5px solid #d8d8d8;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const JobMainGrid = styled(Grid)`
  padding-bottom: 160px;
  flex-wrap: initial;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 80px;
    flex-wrap: wrap;
  }
`

export const CounterText = styled(Box)`
  font-size: 16px;
  color: #979797;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const CounterArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 340px) {
    flex-flow: column;
    align-items: flex-start;
  }
`

export const CounterFilterArea = styled(Box)`
  display: flex;
  align-items: center;

  @media (max-width: 340px) {
    margin-top: 20px;
  }
`

export const JobAdArea = styled(Grid)`
  padding-top: 32px;
  min-width: 368px;
  max-width: 368px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
    min-width: 100%;
    max-width: 100%;
  }
`
