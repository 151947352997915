import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { AwardsWinners } from "./projects"
import { RootState } from "./rootReducer"

//#region types
export type HowItWorksItem = {
  item: string
  subItem: string[]
}

export type HowItWorks = {
  title: string
  subTitle?: string
  items?: HowItWorksItem[]
  step?: number
  icon?: "upload" | "submit" | "share"
}

export type RequirementItem = {
  item: string
  subItems: string[]
}

export type RequirementSide = {
  title: string
  items: RequirementItem[]
}

export type Requirements = {
  left: RequirementSide[]
  right: RequirementSide[]
}

export type ProjectReview = {
  description: string
  name: string
  picture: string
  orientation: string
}

export type JuryReview = {
  image: string
  projectImage: string
  projectName: string
  projectLink: string
  name: string
  quote: string
  description: string
  collaborators: JuryCollaborator[]
}

export type JuryCollaborator = {
  link: string
  name: string
}

export type WinnerVideos = {
  order: number
  url: string
  title: string
  name: string
  subTitle: string
  smallThumbnail: string
  bigThumbnail: string
}

export type HomeBanner = {
  logo: string
  logoMobile: string
}

export type JuryDetails = {
  image: string
  name: string
  description: string
}

export type CompetitionProjects = {
  title: string
  copy: string
  image: string
}

export type Competition = {
  description: string
  slug: string
  title: string
  date: string
  status: string
  code: string
  howItWorks: HowItWorks[]
  jury?: JuryDetails[]
  winners: AwardsWinners
  awardsDescription?: string
  showEntries: boolean
  logoUrl?: string
  dates?: string[]
  entriesDeadline?: Date
  requirements?: Requirements
  projectReview?: ProjectReview[]
  winnerAnnouncement?: string
  winnerAnnouncementDate?: Date
  juryReview?: JuryReview[]
  winnerVideos?: WinnerVideos[]
  showHomeBanner?: boolean
  homeBanner?: HomeBanner
  showOnNavbar?: boolean
  lastCompetitionUrl?: string
  projects?: CompetitionProjects
  url?: string
  displayTitle?: string
  registerOpen: boolean
  isAIA?: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  competition?: Competition
  competitions?: Competition[]
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetCompetitionPayload = {
  slug: string
}
export const getCompetition = createAsyncThunk<any, GetCompetitionPayload>(
  "competitions/get",
  async ({ slug }) => {
    let endpoint = `/competitions/${slug}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

export const listCompetitions = createAsyncThunk<any>(
  "competitions/list",
  async () => {
    let endpoint = `/competitions/list`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  competition: undefined,
  competitions: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "competition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompetition.pending, (state, action) => {
      state.competition = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(getCompetition.fulfilled, (state, action) => {
      state.competition = action.payload
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(listCompetitions.pending, (state, action) => {
      state.competitions = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(listCompetitions.fulfilled, (state, action) => {
      state.competitions = action.payload
      state.status = LoadingStatuses.Succeeded
    })
  },
})
//#endregion

//#region selectors
export const selectCompetition = ({ competition }: RootState) =>
  competition.competition
export const selectCompetitions = ({ competition }: RootState) =>
  competition.competitions
export const selectCompetitionStatus = ({ competition }: RootState) =>
  competition.status
//#endregion
