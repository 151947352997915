import React, { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { fetchHomeHeroProject } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import { fetchSpotlightedProfile } from "../../redux/profile"
import { selectCompetitions } from "../../redux/competition"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { SEOTags } from "../../constants/seo-tags"
import { getHomeData } from "../../redux/home"

type Props = {
  perPageHeroProject?: number
  perPageSpotlightedProfile?: number
}

const HomePage = ({
  perPageHeroProject = 1,
  perPageSpotlightedProfile = 1,
}: Props) => {
  const { user } = useAuth0()
  const email = user?.email
  const dispatch = useAppDispatch()
  const heroProject = useSelector(
    (state: any) => state.projects.homeHeroProject
  )

  const spotlightedProfile = useSelector(
    (state: any) => state.profile.homeSpotlightedProfile
  )

  const competitions = useSelector(selectCompetitions)

  const loadHeroProject = useCallback(async () => {
    try {
      await dispatch(
        fetchHomeHeroProject({ email, perPage: perPageHeroProject })
      )
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, email, perPageHeroProject])

  const loadSpotlightedProfile = useCallback(async () => {
    try {
      await dispatch(
        fetchSpotlightedProfile({
          email,
          perPage: perPageSpotlightedProfile,
          spotlight: true,
        })
      )
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, email, perPageSpotlightedProfile])

  const loadHomeData = useCallback(async () => {
    try {
      await dispatch(getHomeData())
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch])

  useEffect(() => {
    loadHeroProject()
    loadSpotlightedProfile()
    loadHomeData()
  }, [loadHeroProject, loadSpotlightedProfile, loadHomeData])

  return (
    <>
      <RouteChangeTracker screenTitle="Home" classTitle="Home" />
      <Helmet>
        <meta name="keywords" content={SEOTags.join(",")} />
        <title>
          Grow your professional network & discover architectural inspiration
          with your Spectacular Portfolio
        </title>
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={`Grow your professional network & discover architectural inspiration with your Spectacular Portfolio`}
        />
        <meta
          name="title"
          content={`Grow your professional network & discover architectural inspiration with your Spectacular Portfolio`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Show off your Spectacular portfolio to the largest community of Architects in the world! Network with Architects around the world, discover career opportunities, and exciting new design projects."
        />
        <meta
          name="description"
          content="Show off your Spectacular portfolio to the largest community of Architects in the world! Network with Architects around the world, discover career opportunities, and exciting new design projects."
        />
      </Helmet>
      <View
        heroProject={heroProject}
        spotlightedProfile={spotlightedProfile}
        email={email}
        competitions={competitions}
      />
    </>
  )
}

export default HomePage
