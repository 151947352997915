import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const NewPostBox = styled(Box)`
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #dfe5f8;
  width: 100%;
`

export const UpperBox = styled(Box)`
  display: flex;
  gap: 12px;
  padding: 24px 16px 12px;
  box-sizing: border-box;

  && img {
    border-radius: 50%;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 12px;
  }
`

export const PostButton = styled(Box)`
  border: 1px solid #dadada;
  border-radius: 45px;
  font-family: Public-Sans-Medium;
  font-size: 16px;
  color: #2d2d2d;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  gap: 12px;

  && img {
    border-radius: 0;
  }

  &: hover {
    border: 1px solid #979797;
    background-color: #f2f2f2;
  }
`

export const LowerBox = styled(Box)`
  border-top: 0.5px solid #dfe5f8;
  height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  justify-content: space-between;

  @media only screen and (max-width: 1280px) {
    gap: 8px;
    justify-content: space-evenly;
    flex-flow: wrap;
  }

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const SuggestedItem = styled(Typography)`
  font-size: 12px;
  color: #979797;
  && span {
    font-family: Public-Sans-Bold;
  }
`
