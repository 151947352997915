import React from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import JobPositionForm from "../../components/JobPositionForm"
import { JobPosition } from "../../redux/jobPositions"

export type Props = {
  onSubmit: (values: JobPosition) => void
}

const View = ({ onSubmit }: Props) => {
  return (
    <Box marginLeft={2} marginRight={2}>
      <Grid container justify="center">
        <Grid item lg={3} sm={6}>
          <Box marginTop={2}>
            <Typography tabIndex={0} variant="h5">
              Add job position
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <JobPositionForm onSubmit={onSubmit} />
    </Box>
  )
}

export default View
