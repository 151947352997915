import { Box } from "@material-ui/core"

import styled from "styled-components"
import theme from "../../theme"

export const CardBox = styled(Box)<{ $isLarge?: boolean; $isAwards?: boolean }>`
  background-color: ${({ $isLarge, $isAwards }) =>
    $isLarge || $isAwards ? "none" : "#F7F7F7"};
  border-radius: ${({ $isLarge }) => ($isLarge ? "none" : "0 0 4px 4px")};
  flex: ${({ $isLarge }) => ($isLarge ? "none" : "40%")};

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
  }
`

export const CardFooter = styled(Box)<{
  $isLarge?: boolean
  $isAwards?: boolean
}>`
  height: 36px;
  padding: ${({ $isLarge, $isAwards }) =>
    $isLarge ? "24px 0 0" : $isAwards ? "12px 0 0" : "12px 20px"};
`

export const LargeImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  background: #c4c4c4;
  border-radius: 4px;

  ${theme.breakpoints.down("xs")} {
    padding-bottom: 75%;
  }
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  background: #c4c4c4;
  border-radius: 4px;
`

export const ImageThumbnail = styled.img`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
`
