import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

//#region TYPES
export enum LoadingStatuses {
  Idle,
  Failed,
  Loading,
  Succeeded,
}

type SliceState = {
  isOpen: boolean
}
//#endregion

//#region API

export const toggleDialog = createAsyncThunk<
  { opened: boolean },
  { opened: boolean }
>("config/toggleDialog", async ({ opened }) => {
  return Promise.resolve({ opened })
})
//#endregion

//#region SLICE
const initialState: SliceState = {
  isOpen: false,
}

export default createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleDialog.fulfilled, (state, { payload }) => {
      state.isOpen = payload.opened
    })
  },
})
//#endregion
