import React from "react"
import {
  Typography,
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core"
import { Post } from "../../../redux/posts"
import ListItem from "./ListItem"

export type Props = {
  posts: Post[]
}

const View = ({ posts }: Props) => {
  return (
    <Container>
      <Box marginBottom={2}>
        <Typography variant="h2">Flagged Posts List</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Post Owner</TableCell>
              <TableCell>Post Date</TableCell>
              <TableCell>Number of Flags</TableCell>
              <TableCell>Last Flag Reason</TableCell>
              <TableCell>Last Flag Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => {
              return <ListItem post={post} key={post.id} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default View
