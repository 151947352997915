import Jury1 from "../../images/new-homepage/big-jury-1.jpg"
import Jury2 from "../../images/new-homepage/big-jury-2.jpg"
import Jury3 from "../../images/new-homepage/big-jury-3.jpg"

import Project1 from "../../images/innovation-awards/project-1.jpg"
import Project2 from "../../images/innovation-awards/project-2.jpg"
import Project3 from "../../images/innovation-awards/project-3.jpg"

type Collaborator = {
  name: string
  link: string
}

type JuryType = {
  image: string
  projectImage: string
  projectName: string
  projectLink: string
  name: string
  quote: string
  collaborators: Collaborator[]
  description?: string
}

export const JuryReviews: JuryType[] = [
  {
    image: Jury1,
    projectImage: Project1,
    projectName: "Almost Disappear",
    projectLink:
      "/sp/alvin/projects/f8a20e69-05e3-490c-a19e-6de7b7b830b0/almost-disappear",
    collaborators: [
      {
        name: "Haoyu Gu",
        link: "/sp/alvin",
      },
      {
        name: "Haipeng Lin",
        link: "/sp/haipeng-lin",
      },
      {
        name: "Yifan Li",
        link: "/sp/levan",
      },
    ],
    name: "Jeanne Gang",
    quote:
      "“An optimistic project and what we should be doing as a profession right now…such a good example for architects to follow”",
  },
  {
    image: Jury3,
    projectImage: Project2,
    projectName: "Design Thesis",
    projectLink:
      "/sp/roxy-kermani/projects/091eabb7-ccb8-40fa-9382-3f60fef3084b/design-thesis",
    collaborators: [
      {
        name: "Roxy Kermani",
        link: "/sp/roxy-kermani",
      },
    ],
    name: "Sou Fujimoto",
    quote: "“A Futuristic and dreamlike project”",
  },
  {
    image: Jury2,
    projectImage: Project3,
    projectName: "Digital Curvature",
    projectLink:
      "/sp/afireyang/projects/fac2ba47-f6c2-412f-9b38-e5e9a7b29e3f/digital-curvature-%E2%80%94-experimental-study-of-digital-wood-fabrication",
    collaborators: [
      {
        name: "Yang Zhao",
        link: "/sp/afireyang",
      },
    ],
    name: "Thom Mayne",
    quote:
      "“…extremely relevant at where we are today in the broader environment and how it affects architecture”",
  },
]
