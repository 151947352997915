import React, { useState } from "react"
import { addComment, Comment, CommentMention } from "../../redux/projects"
import View from "./View"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { updateComment } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { toggleDialog } from "../../redux/config"

type Props = {
  comment: Comment
}

const ProjectCommentsBox = ({ comment }: Props) => {
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated
  const dispatch = useAppDispatch()
  const [displayError, setDisplayError] = useState(false)
  const [isReplyMode, setReplyMode] = useState(false)
  const [isPostingComment, setIsPostingComment] = useState(false)

  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const handlePostComment = async (
    text: string,
    mentions?: CommentMention[],
    parentCommentId?: string
  ): Promise<void> => {
    try {
      setIsPostingComment(true)
      const { type } = await dispatch(
        addComment({
          id: comment.projectId!,
          auth,
          text,
          parentCommentId: parentCommentId,
          mentions,
        })
      )
      if (addComment.fulfilled.type === type) {
        if (displayError) setDisplayError(false)
        setIsPostingComment(false)
        setReplyMode(false)
        return Promise.resolve()
      } else {
        setDisplayError(true)
        setIsPostingComment(false)
        return Promise.reject()
      }
    } catch (error) {
      setDisplayError(true)
      console.error(error)
      return Promise.reject(error)
    }
  }

  const handleUpdateComment = async (
    text: string,
    mentions?: CommentMention[]
  ): Promise<void> => {
    try {
      const projectId = comment.projectId!
      const commentId = comment.id!
      const { type } = await dispatch(
        updateComment({
          auth,
          projectId,
          commentId,
          text,
          mentions,
          profileId: authenticatedProfile?.connectionId!,
        })
      )
      if (updateComment.fulfilled.type === type) {
        if (displayError) setDisplayError(false)
        return Promise.resolve()
      } else {
        setDisplayError(true)
        return Promise.reject()
      }
    } catch (error) {
      setDisplayError(true)
      return Promise.reject(error)
    }
  }

  const handleHideComment = async (hidden: boolean): Promise<void> => {
    try {
      const projectId = comment.projectId!
      const commentId = comment.id!
      const { type } = await dispatch(
        updateComment({
          auth,
          projectId,
          commentId,
          hidden,
          profileId: authenticatedProfile?.connectionId!,
        })
      )
      if (updateComment.fulfilled.type === type) {
        if (displayError) setDisplayError(false)
        return Promise.resolve()
      } else {
        setDisplayError(true)
        return Promise.reject()
      }
    } catch (error) {
      setDisplayError(true)
      return Promise.reject(error)
    }
  }

  const handleSetReplyMode = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    setReplyMode(!isReplyMode)
  }

  return (
    <View
      comment={comment}
      onUpdateComment={handleUpdateComment}
      onPostComment={handlePostComment}
      onHideComment={handleHideComment}
      onSetReplyMode={handleSetReplyMode}
      authenticatedProfile={authenticatedProfile}
      isPostingComment={isPostingComment}
      isReplyMode={isReplyMode}
    />
  )
}

export default ProjectCommentsBox
