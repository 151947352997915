import React from "react"
import { Comment } from "../../redux/projects"
import { CommentBox, StyledLink, StyledLinkBox } from "./styles"
import JsxParser from "react-jsx-parser"

export type Props = {
  comment: Comment
}

const View = ({ comment }: Props) => {
  let display = `<CommentBox>${comment.text}</CommentBox>`

  // eslint-disable-next-line
  comment.mentions?.map((mention) => {
    const output = `
			<StyledLinkBox><StyledLink id={'${mention.id}'} to={'/sp/${mention.username}'}>@${mention.name}</StyledLink></StyledLinkBox>
		`
    display = display.replace(mention.fullString!, output)
  })

  return (
    <JsxParser
      components={{ StyledLink, CommentBox, StyledLinkBox }}
      jsx={display}
    />
  )
}

export default View
