import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledLinkTag = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #3057e1 !important;
  font-family: Public-Sans !important;
  content: "\a";
  white-space: pre;

  &:active {
    user-select: none;
  }

  :hover {
    color: #3057e1;
    cursor: pointer;
    text-decoration: underline;
  }
`
