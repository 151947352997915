import React from "react"
import { Link } from "react-router-dom"
import { Box, Typography } from "@material-ui/core"

import styled from "styled-components"
import theme, { colors } from "../../theme"

export const CardBox = styled(Box)<{ $isLarge?: boolean; $isAwards?: boolean }>`
  background-color: ${({ $isLarge, $isAwards }) =>
    $isLarge || $isAwards ? "none" : "#ffffff"};
  border-radius: ${({ $isLarge }) => ($isLarge ? "none" : "5px")};
  flex: ${({ $isLarge }) => ($isLarge ? "none" : "40%")};
  border: ${({ $isLarge }) => ($isLarge ? "0" : "0.5px solid #EBEFFC")};

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
    border: 0;
    border-radius: 0;
  }
`

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const ProjectThumbnail = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  height: auto;
  border-radius: 4px;
`

export const ProjectThumbnailPlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 4/3;
  height: auto;
  border-radius: 4px;
  background-color: #f7f7f7;
`

export const OneLiner = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const Avatar = styled.img<{ $isLarge?: boolean }>`
  width: ${({ $isLarge }) => ($isLarge ? "41px" : "32px")};
  height: ${({ $isLarge }) => ($isLarge ? "41px" : "32px")};
  border-radius: 50%;
  display: flex;
  object-fit: cover;
  object-position: top;

  ${theme.breakpoints.down("xs")} {
    width: 26px;
    height: 26px;
  }
`

export const CoverPojectAuthor = styled(Box)`
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  color: ${colors.dark};
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: "Public-Sans-Bold";

  ${theme.breakpoints.down("sm")} {
    padding: 10px 12px;
  }
`
export const PopoverContainerCollab = styled.div`
  width: 559px;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const PopoverContainer = styled.div`
  width: 500px;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);
`

export const ProfileNameText = styled(Typography)`
  font-family: "Public-Sans-Bold";
  font-size: 16px;
  color: #2d2d2d;
`

export const LocationText = styled(Typography)`
  font-size: 14px;
  color: #717171;
  text-decoration: underline;
  text-underline-offset: 2px;
`

export const LargeImageThumbnailContainer = styled.div`
  position: relative;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: 4/2;

  ${theme.breakpoints.down("xs")} {
    aspect-ratio: 4/3;
  }
`

export const ImageThumbnailContainer = styled.div`
  aspect-ratio: 4/3;
  position: relative;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ImageThumbnail = styled.img<{
  $isDiffSize?: boolean
  $isLarge?: boolean
}>`
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  object-fit: ${({ $isDiffSize }) => ($isDiffSize ? "contain" : "cover")};
  aspect-ratio: ${({ $isLarge }) => ($isLarge ? "4/2" : "4/3")};
  height: -webkit-fill-available;

  ${theme.breakpoints.down("xs")} {
    aspect-ratio: 4/3;
  }
`
// export const ImageThumbnail = styled.img<{ $isDiffSize?: boolean }>`
//   z-index: 2;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   cursor: pointerz
//   object-fit: ${({ $isDiffSize }) => ($isDiffSize ? "contain" : "cover")};
// `

export const MouseOverBox = styled(Box)<{
  $mouseOver: boolean
  $isLarge?: boolean
  $isAwards?: boolean
}>`
  background: ${({ $isAwards }) =>
    $isAwards
      ? "none"
      : "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)"};
  color: #ffffff;
  font-size: ${({ $isLarge }) => ($isLarge ? "40px" : "14px")};
  position: absolute;
  z-index: 3;
  width: 100%;
  height: ${({ $isAwards }) => ($isAwards ? "100%" : "50%")};
  bottom: ${({ $isAwards }) => ($isAwards ? "auto" : "0")};
  justify-content: ${({ $isAwards }) => ($isAwards ? "center" : "flex-end")};
  display: flex;
  flex-flow: column;
  align-items: ${({ $isAwards }) => ($isAwards ? "center" : "initial")};
  font-family: "Public-Sans-Bold";
  padding: 24px;
  padding-bottom: ${({ $isLarge }) => ($isLarge ? "24px" : "40px")};
  box-sizing: border-box;
  opacity: ${({ $mouseOver }) => ($mouseOver ? "1" : "0")};
  transition: all 0.5s;
  visibility: ${({ $mouseOver }) => ($mouseOver ? "visible" : "hidden")};

  display: ${({ $isLarge }) => ($isLarge ? "" : "none")};

  && p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  && span {
    font-size: 24px;
    margin-top: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    ${({ $isLarge }) => ($isLarge ? "" : "background: none;")};
    display: none;
  }

  ${theme.breakpoints.down("xs")} {
    opacity: 1;
    visibility: visible;
    font-size: ${({ $isLarge }) => ($isLarge ? "20px" : "14px")};
    display: flex;
    padding: 16px;
    font-size: 16px;
  }
`

export const CardFooter = styled(Box)<{
  $isLarge?: boolean
  $isAwards?: boolean
}>`
  gap: 12px;
  display: flex;
  justify-content: space-between;
  padding: ${({ $isLarge, $isAwards }) =>
    $isLarge ? "24px 0 0" : $isAwards ? "12px 0 0" : "12px 20px"};

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    padding: 0;
    gap: 0;
  }
`

export const ActionsBox = styled(Box)<{ $isLarge?: boolean }>`
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    ${({ $isLarge }) =>
      $isLarge
        ? `
      border-top: 0.5px solid #d8d8d8;
      border-bottom: 0.5px solid #d8d8d8;
    `
        : null}

    padding: 10px 12px;
    box-sizing: border-box;
  }
`

export const NameBox = styled(Box)<{ $isLarge?: boolean }>`
  display: flex;
  align-items: center;

  font-size: ${({ $isLarge }) => ($isLarge ? "20px" : "16px")};
  flex-flow: column;
  align-items: flex-start;

  && a {
    color: #2d2d2d;
    text-decoration: none;
  }

  && a:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  ${theme.breakpoints.down("sm")} {
    ${({ $isLarge }) => ($isLarge ? "font-size: 16px;" : "")}
  }
`

export const ProjectsBox = styled(Box)`
  padding-top: 20px;
  display: flex;
  gap: 8px;
`

export const PopoverFlexBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const PopoverAvatar = styled.img`
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 4px;
`

export const FollowBox = styled(Box)`
  z-index: 3;
  position: absolute;
  padding: 16px;
`

export const CommentsBox = styled(Box)`
  padding: 0 20px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 12px;
  }
`

export const CommentsInnerBox = styled(Box)`
  padding: 12px 0 24px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0 0 12px;
  }
`

export const CommentsLabel = styled(Typography)`
  font-size: 14px;
  cursor: pointer;
  color: #979797;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 8px;
  }
`

export const InputBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

export const StyledInput = styled.input`
  border: 0;
  width: 100%;
  font-size: 14px;
  color: #858585;
  padding-left: 16px;

  &:focus {
    border: 0;
    outline: none;
  }
`

export const PostButton = styled(Typography)`
  color: rgba(48, 87, 225, 0.5);
  font-size: 14px;
  cursor: pointer;
  padding-right: 16px;
  font-family: Public-Sans-Bold;

  &:hover {
    color: #3057e1;
  }
`

export const CommentsAreaBox = styled(Box)`
  font: initial;
  width: 100%;
`

export const CommentsListBox = styled(Box)`
  padding-right: 20px;
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
`

export const TimeBox = styled(Typography)`
  color: #717171;
  font-size: 12px;
`

export const CommentsFlexBox = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`

export const CommentsNameFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 5px;
`

export const CommentTextBox = styled(Box)`
  margin-top: 8px;
`

export const NameBoxComment = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-family: Public-Sans-Bold;

  && a {
    color: #2d2d2d;
    text-decoration: none;
  }

  && a:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const ArrowBox = styled(Box)`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);

  ${theme.breakpoints.down("sm")} {
    width: 32px;
    height: 32px;

    && img {
      width: 10px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    width: 27px;
    height: 27px;

    && img {
      width: 10px;
    }
  }
`

export const BlockingBox = styled(Box)<{ $isPosting?: boolean }>`
  pointer-events: ${({ $isPosting }) => ($isPosting ? "none" : "auto")};
  filter: ${({ $isPosting }) => ($isPosting ? "opacity(0.4)" : "none")};
`

export const ProjectTitle = styled(Typography)<{ $isLarge?: boolean }>`
  font-size: ${({ $isLarge }) => ($isLarge ? "16px" : "14px")};
  font-weight: 400;
  margin-bottom: -3px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${theme.breakpoints.down("sm")} {
    ${({ $isLarge }) => ($isLarge ? "font-size: 14px;" : "")}
  }
`

export const MoreImagesIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;
  filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.5));

  ${theme.breakpoints.down("xs")} {
    right: 10px;
    top: 10px;
  }
`

export const CommentsLinksBox = styled(Box)`
  margin-top: 10px;
`

export const CommentsActions = styled(Box)`
  display: flex;
  gap: 16px;
`

export const ReplyBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  flex-flow: column;
`

export const ReplyLink = styled(Typography)<{
  $isActive?: boolean
  $replyRow?: number
  $currentRow?: number
}>`
  cursor: pointer;
  color: ${({ $isActive, $replyRow, $currentRow }) =>
    $isActive && $replyRow === $currentRow ? "#3057E1" : "#C4C4C4"};
  font-family: Public-Sans-Bold;
  font-size: 14px;

  &:hover {
    color: #3057e1;
  }
`

export const ListItemOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const IconOverlayLeft = styled.div`
  top: ${theme.spacing(1)}px;
  left: ${theme.spacing(1)}px;
  z-index: 4;
  position: absolute;
`

export const TextOverlayContainer = styled.div`
  height: 24px;
  display: flex;
  text-align: center;
  padding: 0.25em 0.5em;
  font-size: 0.875em;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const IconContainer = styled(Box)`
  display: flex;
  cursor: pointer;
  justify-content: center;
  background: #ffffff;
  color: #2d2d2d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 212px;
  height: 45px;
  align-items: center;

  && img {
    height: 20px;
  }

  && p {
    font-size: 16px;
    color: #2d2d2d;
    margin-left: 10px;
  }
`

export const FirmBadge = styled(Box)`
  padding: 24px;
  box-sizing: border-box;
  background-color: #f4f4f4;
  position: absolute;
  z-index: 5;
  box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.25);
  width: fit-content;
  right: 48px;

  && img {
    width: 68px;
    height: 68px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 16px;
    right: 32px;

    && img {
      width: 60px;
      height: 60px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    padding: 12px;
    right: 12px;

    && img {
      width: 40px;
      height: 40px;
    }
  }
`

export const ProjectCardHeader = styled(Box)`
  padding: 16px 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  && img {
    border-radius: 50%;
    display: flex;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0;
  }
`

export const HeaderBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`
