import React from "react"
import { Box, Container, CircularProgress, Grid } from "@material-ui/core"
import LogoImage from "../../images/logo-charcoal.svg"

export type Props = {
  message: string
}

const View = ({ message }: Props) => {
  return (
    <Container>
      <Box height="65vh">
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Box width={1} textAlign="center">
              <img src={LogoImage} alt="Spectacular" />
            </Box>
            <Box marginTop={3} marginBottom={1} width={1} textAlign="center">
              <CircularProgress />
            </Box>
            <Box width={1} textAlign="center">
              {message}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default View
