import React, { useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../../redux/configureStore"
import { fetchNetworkPage } from "../../../../redux/connections"
import View from "./View"

const FollowersTab = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const followers = useSelector((state: any) => state.connections.followers)
  const followersCount = useSelector(
    (state: any) => state.connections.followersCount
  )
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const loadConnections = useCallback(async () => {
    try {
      const { payload, type } = await dispatch(
        fetchNetworkPage({ auth, perPage: 10, page, search, type: "followers" })
      )

      if (fetchNetworkPage.fulfilled.type === type) {
        setIsLoading(false)
        if (payload.data.length >= payload.count || payload.data.length === 0)
          setHasMore(false)
        else setHasMore(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, page, search, auth])

  const handleUpdateHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangePage = (value: number) => {
    setPage(value)
  }

  const handleChangeSearch = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  useEffect(() => {
    loadConnections()
  }, [loadConnections])

  return (
    <View
      followers={followers}
      followersCount={followersCount}
      hasMore={hasMore}
      onUpdateHasMore={handleUpdateHasMore}
      page={page}
      onUpdatePage={handleChangePage}
      handleChangeSearch={handleChangeSearch}
      search={search}
      isLoading={isLoading}
    />
  )
}

export default FollowersTab
