import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import theme from "../../../../theme"

export const ListingTitle = styled(Typography)`
  font-size: 25px;

  ${theme.breakpoints.down("sm")} {
    line-height: 20px;
    margin-bottom: -2px;
  }
`

export const StickyBox = styled(Box)`
  position: sticky;
  z-index: 100;
  box-shadow: none;
  top: 56px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 12px solid #eeeeee;
`

export const FormBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    padding: 21px 20px 0;
  }
`

export const SearchBoxArea = styled(Box)`
  height: 48px;
  box-sizing: border-box;
  width: 50%;
  background: #f7f7f7;
  border-radius: 1px;
  border: 0.5px solid #d8d8d8;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 24px;

  && img {
    margin-right: 16px;
  }
`

export const SearchBar = styled.input`
  border: 0;
  font-size: 20px;
  width: 100%;
  color: #979797;
  background: #f7f7f7;

  &::placeholder {
    color: #979797;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: #979797;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`
