import React, { useCallback, useEffect, useState } from "react"
import { Project, newFetchProjects } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import { useSelector } from "react-redux"
import {
  DropDownBox,
  NoResultsBox,
  NoResultsShortcut,
  NoResultsShortcuts,
  NoResultsText,
  NoResultsTitle,
  PlaceholderProjectCard,
  ProjectsListBox,
  ProjectsListCardBox,
  ProjectsListContainer,
} from "./styles"
import ProjectCard from "../../cards/ProjectCard"
import LoadingPage from "../LoadingPage"
import InfiniteScroll from "react-infinite-scroll-component"
import DropDownHomepage from "../../components/DropDownHomepage"

interface Props {
  email?: string
  typologyFilter?: string[]
  toolsFilter?: string[]
  keyMaterialsFilter?: string[]
  textFilter: string
  myNetworkFilter: boolean
  page: number
  onChangePage: (value: number) => void
  onShortcutClick: (value: string) => void
  onUpdateSortBy: (value: string) => void
  sortBy: string
}

const ProjectsList = ({
  email,
  typologyFilter,
  toolsFilter,
  keyMaterialsFilter,
  textFilter,
  myNetworkFilter,
  page,
  onChangePage,
  onShortcutClick,
  onUpdateSortBy,
  sortBy,
}: Props) => {
  const dispatch = useAppDispatch()

  const projects = useSelector(
    (state: any) => state.projects.allProjects
  ) as Project[]
  const projectsCount = useSelector(
    (state: any) => state.projects.projectsCount
  )
  const newProjectsCount = useSelector(
    (state: any) => state.projects.newProjectsCount
  )

  const checkCount = page === 1 ? 0 : newProjectsCount

  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const loadProjects = useCallback(async () => {
    try {
      setIsLoading(true)
      const search = textFilter
      const { type, payload } = await dispatch(
        newFetchProjects({
          email,
          page,
          search,
          typology: typologyFilter,
          tools: toolsFilter,
          keyMaterials: keyMaterialsFilter,
          myNetwork: myNetworkFilter,
          sortBy,
        })
      )

      if (newFetchProjects.fulfilled.type === type) {
        if (checkCount >= payload.count) setHasMore(false)
        setIsLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    email,
    page,
    typologyFilter,
    toolsFilter,
    keyMaterialsFilter,
    textFilter,
    myNetworkFilter,
    sortBy,
  ])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  const sortActions = [
    {
      action: "engaging",
      display: "Most Appreciated",
    },
    {
      action: "recent",
      display: "Most Recent",
    },
    {
      action: "oldest",
      display: "Oldest",
    },
  ]

  return (
    <ProjectsListContainer>
      <ProjectsListBox>
        <DropDownBox>
          <DropDownHomepage
            actions={sortActions}
            onClick={onUpdateSortBy}
            title={"Sort"}
          />
        </DropDownBox>
        <InfiniteScroll
          dataLength={projects.length}
          next={() => onChangePage(page + 1)}
          hasMore={hasMore}
          loader={<LoadingPage />}
          style={{ overflow: "hidden" }}
          scrollThreshold={0.8}
        >
          <ProjectsListCardBox>
            {projects.map((project) => {
              return (
                <ProjectCard
                  project={project}
                  key={project.id}
                  source="homepage"
                />
              )
            })}
            {!isEven(projectsCount) && <PlaceholderProjectCard />}
          </ProjectsListCardBox>
        </InfiniteScroll>

        {!isLoading && projectsCount === 0 && (
          <NoResultsBox>
            <NoResultsTitle variant="h2">No results found.</NoResultsTitle>
            <NoResultsText>
              Sorry, we couldn’t find any results for this search.
              <br />
              Try shortening or rephrasing your search.
            </NoResultsText>
            <NoResultsShortcuts>
              <NoResultsShortcut onClick={() => onShortcutClick("Concrete")}>
                Concrete
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Photography")}>
                Photography
              </NoResultsShortcut>
              <NoResultsShortcut
                onClick={() => onShortcutClick("Interior Design")}
              >
                Interior Design
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Chicago")}>
                Chicago
              </NoResultsShortcut>
            </NoResultsShortcuts>
          </NoResultsBox>
        )}
      </ProjectsListBox>
    </ProjectsListContainer>
  )
}

export default ProjectsList
