import styled from "styled-components"
import theme from "../../theme"

export const Navbar = styled.div`
  display: none;
  ${theme.breakpoints.down("sm")} {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 8px;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 1010;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 0;
    flex-wrap: nowrap;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
`
