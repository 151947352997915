import React from "react"
import { Box, Grid, Link, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const Divider = styled.div`
  margin: 0;
  border-top: 0.5px solid #d8d8d8;
`

export const ImageGalleryContainer = styled(Box)`
  && {
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:hover,
    .image-gallery-thumbnail:focus {
      outline: none;
      border: 2px solid ${colors.dark};
    }

    .image-gallery-content .fullscreen {
      .image-gallery-thumbnails {
        padding-top: 0;
      }
    }

    .image-gallery-thumbnails {
      padding-top: ${theme.spacing(3)}px;

      .image-gallery-thumbnails-container {
        text-align: left;
      }
    }

    .image-gallery-icon {
      filter: none;
      color: ${colors.white};
      background-color: rgba(0, 0, 0, 0.3);
      top: 8px;
      right: 8px;
      bottom: unset;
      padding: 7px;
      line-height: 0;
    }

    .image-gallery-icon:hover {
      color: ${colors.white};
    }

    .image-gallery-content.fullscreen
      .image-gallery-slide
      .image-gallery-image {
      height: calc(100vh - 140px);
    }

    .image-gallery-thumbnail {
      width: 138px;
      height: 98px;
    }

    .image-gallery-thumbnail-image {
      width: 130px;
      height: 90px;
      object-fit: contain;
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      top: 48%;
    }

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      width: 30px;
      height: 60px;
    }
  }
`

export const RightColumn = styled(Grid)`
  .buttress-divider {
    flex: 1;
  }

  ${theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
  }
`

export const ProfileInfoContainer = styled(Box)`
  padding: 30px 24px;
  display: flex;
  flex-flow: column;
  gap: 32px;

  ${theme.breakpoints.down("sm")} {
    padding: 20px 0;
  }
`

export const ProfilePicture = styled.img`
  width: 100%;
  cursor: pointer;
`

export const ProjectDetailsContainer = styled(Box)`
  border-top: 0.5px solid #d8d8d8;
  padding: ${theme.spacing(5)}px ${theme.spacing(3)}px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    border-left: none;
    padding: 20px 0;
  }
`

export const DetailsBox = styled(Box)`
  ${theme.breakpoints.up("sm")} {
    margin-right: ${theme.spacing(3)}px;
  }
`

export const SocialBoxMobile = styled.div`
  paddint-top: 16px;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);
  margin-top: ${theme.spacing(5)}px;
  margin-bottom: ${theme.spacing(5)}px;

  ${theme.breakpoints.down("sm")} {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 0;
    flex-wrap: nowrap;
    padding: 16px;
    margin-bottom: 73px;
  }
`

export const SocialBox = styled.div<{ mobile?: any }>`
  ${({ mobile }) =>
    mobile ? "box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);" : ""}
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${theme.spacing(5)}px;
  margin-bottom: ${theme.spacing(5)}px;

  ${theme.breakpoints.down("sm")} {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: calc(100vw - 16px);
    background: #fff;
    margin-bottom: 0;
    flex-wrap: nowrap;
    padding: 0 ${theme.spacing(2)}px ${theme.spacing(2)}px;
  }
`

export const FeaturedIndicator = styled.img`
  width: 36px;
  height: 36px;
`

export const SlateUnderLineLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 0.875em;
  border-bottom: solid 0.5px ${colors.slate};
  color: ${colors.slate};
`

export const SlateLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 1em;
  padding-bottom: 2px;
  color: ${colors.slate};
  cursor: text;
`

export const FlexBox = styled(Box)`
  display: flex;
  width: 100%;
  border-top: 0.5px solid #d8d8d8;
  box-sizing: border-box;
`

export const LeftBox = styled(Box)`
  width: 100%;
  padding-top: 30px;
`

export const DescriptionTitle = styled(Typography)`
  font-size: 24px;
  line-height: 30px;
  font-family: Public-Sans-Medium;

  ${theme.breakpoints.down("sm")} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const DescriptionText = styled(Typography)`
  margin-top: 20px;
  white-space: pre-line;
`

export const RightBox = styled(Box)`
  max-width: 480px;
  width: 100%;
  border-left: 0.5px solid #d8d8d8;
`

export const CollaboratorBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const CollaboratorAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
`

export const NameBox = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100%;
`

export const CollaboratorName = styled(Typography)`
  color: #2d2d2d;
  font-size: 16px;
  line-height: 22px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const CollaboratorLocation = styled(Typography)`
  color: #717171;
  font-size: 14px;
  line-height: 22px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  && img {
    margin-right: 6px;
  }
`

export const ButtonsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 24px;
  margin-top: 20px;

  && button {
    width: 100% !important;
    max-width: 100% !important;
    height: 48px !important;
  }
`

export const ListButtonBox = styled(Box)`
  && button {
    width: 100% !important;
    max-width: 100% !important;
    height: 32px !important;
  }
`

export const PendingText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: #3057e1;
  white-space: pre;
  display: flex;
  width: 100%;

  && span {
    font-size: 35px;
  }
`

export const StatsBox = styled(Box)`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const StatsItem = styled(Box)`
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  flex-flow: column;
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: 100%;
  }
`

export const StatsCount = styled(Typography)`
  font-size: 20px;
  color: #2d2d2d;
`

export const StatsLabel = styled(Typography)`
  font-size: 14px;
  margin-top: -5px;
  color: #717171;
`

export const ProjectCardIconsBox = styled(Box)`
  margin: 0;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  padding: 16px 0;
`
