import React from "react"
import {
  TableRow,
  TableCell,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/DoneAllTwoTone"
import RevertIcon from "@material-ui/icons/NotInterestedOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import { EditableProjectReward } from "./index"
// import TextField from "../TextField"
import { ActionsTableCell } from "./styles"
import { useSelector } from "react-redux"
import { selectCompetitions } from "../../redux/competition"

export type Props = {
  error?: any
  reward: EditableProjectReward
  onEdit: () => void
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
  onChange: (date: any | null, property: keyof EditableProjectReward) => void
  onDateChange: (
    date: Date | null,
    property: keyof EditableProjectReward
  ) => void
}

const View = ({
  error,
  reward,
  onSave,
  onDelete,
  onCancel,
  onChange,
  onEdit,
  onDateChange,
}: Props) => {
  const getCampaign = (campaign: string) => {
    const result = competitions?.find((item) => item.code === campaign)
    return result?.title
  }

  const getPrize = (prize: string) => {
    if (prize === "1st-place") return "1st Place"

    if (prize === "2nd-place") return "2nd Place"

    if (prize === "3rd-place") return "3rd Place"

    if (prize === "winner") return "Winner"

    if (prize === "people-choice-award") return "People’s Choice Award"
  }

  const competitions = useSelector(selectCompetitions)

  if (!reward.editing) {
    return (
      <TableRow>
        <TableCell>
          {reward.campaign ? getCampaign(reward.campaign) : "No campaign set"}
        </TableCell>
        <TableCell>
          {reward.prize ? getPrize(reward.prize) : "No prize set"}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <ActionsTableCell>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </ActionsTableCell>
      </TableRow>
    )
  }

  return (
    <TableRow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TableCell component="th" scope="row">
          <Select
            name="campaign"
            label="Choose an option"
            style={{
              fontSize: "14px",
              maxHeight: "48px",
              fontFamily: "Public-Sans",
              width: "100%",
            }}
            onChange={(e) => onChange(e.target.value, "campaign")}
          >
            {competitions?.map((competition) => {
              return (
                <MenuItem value={competition.code}>
                  {competition.title}
                </MenuItem>
              )
            })}
          </Select>
        </TableCell>
        <TableCell component="th" scope="row">
          <Select
            name="prize"
            label="Choose an option"
            style={{
              fontSize: "14px",
              maxHeight: "48px",
              fontFamily: "Public-Sans",
              width: "100%",
            }}
            onChange={(e) => onChange(e.target.value, "prize")}
          >
            <MenuItem value={"1st-place"}>1st Place</MenuItem>
            <MenuItem value={"2nd-place"}>2nd Place</MenuItem>
            <MenuItem value={"3rd-place"}>3rd Place</MenuItem>
            <MenuItem value={"people-choice-award"}>
              People’s Choice Award
            </MenuItem>
            <MenuItem value={"winner"}>Winner</MenuItem>
          </Select>
        </TableCell>
        <TableCell></TableCell>
        <ActionsTableCell>
          <>
            <IconButton onClick={onSave}>
              <DoneIcon />
            </IconButton>
            {reward.id && (
              <IconButton onClick={onCancel}>
                <RevertIcon />
              </IconButton>
            )}
          </>
        </ActionsTableCell>
      </MuiPickersUtilsProvider>
    </TableRow>
  )
}

export default View
