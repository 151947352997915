import { Box, Button, Typography } from "@material-ui/core"
import Image from "material-ui-image"
import styled from "styled-components"

export const MainContainer = styled(Box)`
  top: 80px;
  padding-bottom: 100px;
  position: relative;
  * {
    text-align: initial;
  }
`

export const Text = styled(Box)`
  font-family: ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica,
    sans-serif;
  font-size: 14px;
  line-height: 1.5;
`

export const TextContainer = styled(Box)`
  margin: auto;
  width: 350px;
  padding: 0 20px 24px 48px;
  background-color: #ffffff;
  text-align: center;
  color: #2d333a;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: 0 solid transparent;
  z-index: 1;
`

export const Logo = styled(Image)`
  background-color: transparent !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
`

export const LogoContainer = styled(Box)`
  margin: auto;
  width: 133px;
  height: 133px;
`

export const TextTitle = styled(Typography)`
  margin: -10px auto 0;
  display: block;
  font-size: 24px;
`

export const TextResponse = styled(Typography)`
  font-family: ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica,
    sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: red;
`

export const Note = styled(Box)`
  font-size: 12px;
`

export const ResendButton = styled(Button)`
  margin: 6px 0;
  width: 100%;
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  :hover {
    color: #fff;
  }
`
