import React from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import JobListingForm from "../../../../../components/JobListingForm"
import { useAppDispatch } from "../../../../../redux/configureStore"
import {
  fetchProfileByUsernameWithAuthorization,
  Profile,
} from "../../../../../redux/profile"
import {
  adminCreateJobListing,
  JobListing,
  adminUpdateJobListing,
} from "../../../../../redux/jobListings"
import { useAuth0 } from "@auth0/auth0-react"

interface Props {
  profile: Profile
}
export const Jobs = ({ profile }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [showJobs, setShowJobs] = React.useState(true)
  const [jobs, setJobs] = React.useState(profile.jobListings)
  const [addJob, setAddJob] = React.useState(false)

  const [selectedJob, setSelectedJob] = React.useState(Object)
  //let selectedJob

  const handleAddNewClick = (event: any) => {
    setAddJob(true)
    setShowJobs(false)
  }

  const handleEditClick = (jobListing: JobListing) => {
    setSelectedJob(jobListing)
    setAddJob(false)
    setShowJobs(false)
  }
  const handleAddJob = async (jobListing: JobListing) => {
    jobListing.profile = profile
    try {
      const { type } = await dispatch(
        adminCreateJobListing({ jobListing, auth })
      )
      if (adminCreateJobListing.fulfilled.type === type) {
        const username = profile.username as string
        try {
          const result = await dispatch(
            fetchProfileByUsernameWithAuthorization({ auth, username })
          )
          setJobs(result.payload.jobListings)
          setShowJobs(true)
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditJob = async (jobListing: JobListing) => {
    jobListing.profile = profile
    try {
      const { type } = await dispatch(
        adminUpdateJobListing({ jobListing, auth })
      )
      if (adminUpdateJobListing.fulfilled.type === type) {
        const username = profile.username as string
        try {
          const result = await dispatch(
            fetchProfileByUsernameWithAuthorization({ auth, username })
          )
          setJobs(result.payload.jobListings)
          setShowJobs(true)
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onCancel = () => setShowJobs(true)

  let groupType = false
  if (profile.type === "group") groupType = true

  return (
    <>
      {showJobs ? (
        <>
          {groupType ? (
            <Button onClick={handleAddNewClick}>Add New Job</Button>
          ) : (
            ""
          )}
          <Typography tabIndex={0} variant="h3">
            Jobs
          </Typography>
          {jobs!.length > 0 ? (
            <>
              {jobs!.map((job, index) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <h2>{job.title}</h2>
                    </Grid>
                    <Grid item xs={4}>
                      <Button onClick={() => handleEditClick(job)}>Edit</Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      {job.summary}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      {job.location}
                    </Grid>
                  </Grid>
                </>
              ))}
            </>
          ) : (
            <Box>No jobs</Box>
          )}
        </>
      ) : (
        <>
          {addJob ? (
            <JobListingForm
              type="new"
              onSubmit={handleAddJob}
              onCancel={onCancel}
            />
          ) : (
            <JobListingForm
              jobListing={selectedJob}
              type="edit"
              onSubmit={handleEditJob}
              onCancel={onCancel}
            />
          )}
        </>
      )}
    </>
  )
}
