import React, { ChangeEvent, useEffect, useState } from "react"
import { MenuItem, Grid } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import {
  CompetitionProject,
  LoadingStatuses,
  Project,
  selectProjectsStatus,
} from "../../redux/projects"
import {
  PlaceholderProjectCard,
  ProjectsListCardBox,
} from "./../HomePage/styles"

import { MainSearchBar } from "../../components/Styles/MainSearchBar"
import { MainSelect } from "../../components/Styles/MainSelect"

import LoadingPage from "../LoadingPage"
import InfiniteScroll from "react-infinite-scroll-component"

import ProjectCard from "../../cards/ProjectCard"
import {
  ProjectListTitle,
  ProjectsListBox,
  ProjectsListContainer,
} from "./styles"
import { useSelector } from "react-redux"

export type Props = {
  projects: Project[]
  filterBy: string
  handleChangeFilterBy: (event: ChangeEvent<{ value: unknown }>) => void
  onSearch: (searchText: string) => void
  page: number
  projectsCount: number
  hasMore: boolean
  term: string
  handleChangePagination: (value: number) => void
  handleChangeHasMore: (value: boolean) => void
  competition: string
  competitionProjects: any
}

const View = ({
  projects,
  filterBy,
  handleChangeFilterBy,
  onSearch,
  page,
  projectsCount,
  handleChangePagination,
  handleChangeHasMore,
  term,
  hasMore,
  competition,
  competitionProjects,
}: Props) => {
  const [stickyStyle, setStickyStyle] = useState({})
  const [styleChange, setStyleChange] = useState(false)
  const [searchText, setSearchText] = useState("")

  const projectStatus = useSelector(selectProjectsStatus)

  const handleCancelSearch = () => {
    setSearchText("")
    onSearch("")
  }

  const handleSearch = () => {
    onSearch(searchText)
  }

  const fetchMoreData = () => {
    if (projects.length >= projectsCount) {
      handleChangeHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 67 && !styleChange) {
        setStickyStyle({
          position: "sticky",
          backgroundColor: "#fafcfe",
          top: "67px",
          zIndex: 100,
          borderBottom: "1px solid",
        })
        setStyleChange(true)
      } else if (window.scrollY < 67 && styleChange) {
        setStickyStyle({
          position: "sticky",
          backgroundColor: "#fafcfe",
          top: "67px",
          zIndex: 100,
        })
        setStyleChange(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [stickyStyle, styleChange])

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  return (
    <>
      <ProjectsListContainer style={stickyStyle}>
        <ProjectsListBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <MainSearchBar
                onChange={(text) => setSearchText(text)}
                onCancelSearch={handleCancelSearch}
                onRequestSearch={handleSearch}
                searchIcon={<SearchIcon data-testid="search-icon" />}
                data-testid="searchBar"
                value={term !== "" ? term : searchText}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MainSelect
                disableUnderline
                value={filterBy}
                onChange={handleChangeFilterBy}
                inputProps={{ "data-testid": "sortSearchResults" }}
              >
                <MenuItem value="score">
                  <span>Most appreciated</span>
                </MenuItem>
                <MenuItem value="createdAt">
                  <span>Recently created</span>
                </MenuItem>
                <MenuItem value="recentlyUpdated">
                  <span>Recently updated</span>
                </MenuItem>
                <MenuItem value="likes">
                  <span>Most liked</span>
                </MenuItem>
                <MenuItem value="comments">
                  <span>Most discussed</span>
                </MenuItem>
                <MenuItem value="nominations">
                  <span>Most nominated</span>
                </MenuItem>
              </MainSelect>
            </Grid>
          </Grid>
        </ProjectsListBox>
      </ProjectsListContainer>

      <ProjectsListContainer>
        <ProjectsListBox>
          {competition?.includes("studio-awards") ? (
            <>
              {projectStatus === LoadingStatuses.Idle ||
              projectStatus === LoadingStatuses.Loading ? (
                <LoadingPage />
              ) : (
                <>
                  {competitionProjects.map(
                    (row: CompetitionProject, key: number) => {
                      return (
                        <>
                          <ProjectListTitle
                            variant="h2"
                            style={key === 0 ? { marginTop: "-40px" } : {}}
                          >
                            {row.groupName}
                          </ProjectListTitle>
                          <ProjectsListCardBox>
                            {row.projects?.map((project) => (
                              <ProjectCard project={project} />
                            ))}
                            {!isEven(row.projects.length) && (
                              <PlaceholderProjectCard />
                            )}
                          </ProjectsListCardBox>
                        </>
                      )
                    }
                  )}
                </>
              )}
            </>
          ) : (
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<LoadingPage />}
              style={{ overflow: "hidden" }}
            >
              <ProjectsListCardBox>
                {projects?.map((project, idx) => (
                  <ProjectCard project={project} />
                ))}
                {!isEven(projectsCount) && <PlaceholderProjectCard />}
              </ProjectsListCardBox>
            </InfiniteScroll>
          )}
        </ProjectsListBox>
      </ProjectsListContainer>
    </>
  )
}

export default View
