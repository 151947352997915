import React from "react"
import {
  Box,
  Grid,
  Button,
  Slider,
  TextField,
  IconButton,
  Typography,
  withStyles,
  DialogTitle,
} from "@material-ui/core"

import {
  ImageCreditsDialogContent,
  StyledLink,
  LeftArrowIcon,
  RightArrowIcon,
  ImageCreditsDialog,
  LeftNavButtonOverlay,
  ImageErrorList,
  RightNavButtonOverlay,
  ImageThumbnailContainer,
  FlipVerticalButton,
  FlipHorizontalButton,
  RotateLeftButton,
  RotateRightButton,
  MinusButton,
  PlusButton,
  ActionsBox,
  ZoomBox,
  RotateBox,
  RotateTypography,
  FlipTypography,
  ZoomTypography,
} from "./styles"

import "./thumb.css"

import getCroppedImg from "../../utils/cropImage"

import { colors } from "../../theme"
import { useWindowDimensions } from "../../hooks"
import ConfirmDialog from "../ConfirmDialog"
import { Image as ImageType, updateImageDetail } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import { ReactComponent as CloseIcon } from "../../images/image-white-x.svg"

import Cropper from "react-easy-crop"
import { useAuth0 } from "@auth0/auth0-react"

const MAX_CHAR_COUNT = 1500

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const Title = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

export type Props = {
  index: number
  images: ImageType[]
  isOpen: boolean
  onEditImage: (removed: string[]) => void
  onClose: () => void
  onDelete: (imageOrder: number) => void
  onNav: (direction: "next" | "prev") => void
  onConfirm: (images: ImageType[]) => void
  onChange?: (images: ImageType[]) => void
}

const View = ({
  index,
  onNav,
  images,
  isOpen,
  onEditImage,
  onClose,
  onDelete,
  onChange,
  onConfirm,
}: Props) => {
  images.sort((a, b) => a.order - b.order)

  const { height } = useWindowDimensions()

  const creditRef = React.useRef<any>(null)
  const captionRef = React.useRef<any>(null)

  const [state, setState] = React.useState<any>()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const [countColor, setCountColor] = React.useState("#2d2d2d")
  const [displayImage, setDisplayImage] = React.useState("")
  const [invalidImage, setInvalidImage] = React.useState(false)
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [rotation, setRotation] = React.useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [flip, setFlip] = React.useState({ horizontal: false, vertical: false })
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    setState({ ...images[index] })

    setDisplayImage(images[index]?.url!)

    images[index]?.width! < 640 || images[index]?.width! < 480
      ? setInvalidImage(true)
      : setInvalidImage(false)

    resetEditor()
  }, [images, index])

  const resetEditor = () => {
    setCroppedAreaPixels(null)
    setRotation(0)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setFlip({ vertical: false, horizontal: false })
  }

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleConfirm = async () => {
    if (state?.id && (state?.credits || state?.caption)) {
      dispatch(
        updateImageDetail({
          auth,
          id: state?.id,
          credits: state?.credits,
          caption: state?.caption,
        })
      )
    }

    const newImages = Array.from(images)

    const fileName =
      state!.file && state!.file.name ? state!.file.name : "image"

    const croppedImage: any = await getCroppedImg(
      displayImage,
      croppedAreaPixels,
      rotation,
      flip
    )

    if (croppedImage) {
      const dimensions = { width: 0, height: 0 }

      const i = new Image()
      i.src = croppedImage

      i.onload = await function () {}

      const newFile = (await urltoFile(croppedImage, fileName)) as any

      dimensions.width = i.width
      dimensions.height = i.height

      newFile.width = i.width
      newFile.height = i.height

      const newImage = {
        file: newFile,
        url: croppedImage,
        dimensions: dimensions,
        order: state!.order,
        caption: state!.caption,
        credits: state!.credits,
        width: dimensions.width,
        height: dimensions.height,
      }

      if (dimensions.width < 640 || dimensions.height < 480) {
        setInvalidImage(true)
        return
      }

      if (state!.id!) onEditImage([state!.id!])

      newImages.splice(index, 1, newImage)
    } else {
      newImages.splice(index, 1, state!)
    }

    onConfirm(newImages)
    setInvalidImage(false)
    resetEditor()
  }

  const urltoFile = (url: string, filename: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: "image/jpeg" })
      })
  }

  const handleChange = () => {
    const newImages = Array.from(images)
    newImages.splice(index, 1, state!)
    if (onChange) onChange(newImages)
  }

  const nav = (dir: "next" | "prev") => {
    handleChange()
    cleanUpInputs()
    onNav(dir)
  }

  const cleanUpInputs = () => {
    setState({ ...state!, caption: "", credits: "" })
    creditRef.current.children[1].children[0].value = ""
    captionRef.current.children[1].children[0].value = ""
  }

  const handleClose = () => {
    onClose()
    resetEditor()
    setDisplayImage(images[index]?.url!)
  }

  const handleMinusZoom = () => {
    if (zoom > 1) setZoom(zoom - 0.1)
  }

  const handlePlusZoom = () => {
    if (zoom < 3) setZoom(zoom + 0.1)
  }

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT) {
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
      setCountColor("#f44336")
    } else {
      setCountColor("#2d2d2d")
    }
  }

  return (
    <ImageCreditsDialog
      fullWidth
      open={isOpen}
      maxWidth={"lg"}
      onClose={handleClose}
      style={{ minHeight: "95vh" }}
      aria-labelledby="confirm-dialog"
    >
      <ConfirmDialog
        title="Delete image"
        message="Are you sure you want to delete this image?"
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (onDelete) onDelete(state!.order)
          setIsDeleteDialogOpen(false)
          onClose()
        }}
      />

      <Title onClose={onClose}>Edit Image</Title>
      <ImageCreditsDialogContent>
        <Grid container justify="center">
          <LeftNavButtonOverlay onClick={() => nav("prev")}>
            <LeftArrowIcon />
          </LeftNavButtonOverlay>
          <RightNavButtonOverlay height={height} onClick={() => nav("next")}>
            <RightArrowIcon />
          </RightNavButtonOverlay>
          <ImageThumbnailContainer>
            <Cropper
              image={displayImage}
              aspect={3 / 2}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              transform={[
                `translate(${crop.x}px, ${crop.y}px)`,
                `rotateZ(${rotation}deg)`,
                `rotateY(${flip.horizontal ? 180 : 0}deg)`,
                `rotateX(${flip.vertical ? 180 : 0}deg)`,
                `scale(${zoom})`,
              ].join(" ")}
            />
          </ImageThumbnailContainer>
          <ActionsBox>
            <ZoomBox>
              <ZoomTypography>Zoom</ZoomTypography>
              <MinusButton onClick={handleMinusZoom} />
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e, zoom: any) => setZoom(zoom)}
              />
              <PlusButton onClick={handlePlusZoom} />
            </ZoomBox>
            <RotateBox>
              <FlipTypography>Flip</FlipTypography>

              <FlipVerticalButton
                onClick={() => {
                  setFlip((prev) => ({
                    horizontal: !prev.horizontal,
                    vertical: prev.vertical,
                  }))
                  setRotation((prev) => 360 - prev)
                }}
              />

              <FlipHorizontalButton
                onClick={() => {
                  setFlip((prev) => ({
                    horizontal: prev.horizontal,
                    vertical: !prev.vertical,
                  }))
                  setRotation((prev) => 360 - prev)
                }}
              />

              <RotateTypography>Rotate</RotateTypography>
              <RotateRightButton onClick={() => setRotation(rotation + 90)} />
              <RotateLeftButton onClick={() => setRotation(rotation - 90)} />
            </RotateBox>
          </ActionsBox>
        </Grid>

        <Box>
          {invalidImage ? (
            <ul>
              <ImageErrorList>Resolution lower than 640x480.</ImageErrorList>
            </ul>
          ) : null}
        </Box>

        <Box marginBottom={4}>
          <TextField
            ref={creditRef}
            label="Image Credit"
            value={state?.credits}
            name={`credits-${state?.order}`}
            onChange={(e) =>
              setState({ ...state!, credits: e.currentTarget.value })
            }
          />
          <TextField
            rows={4}
            rowsMax={24}
            ref={captionRef}
            multiline={true}
            label="Image Caption"
            value={state?.caption}
            name={`caption-${state?.order}`}
            onInput={characterCounter}
            onChange={(e) =>
              setState({ ...state!, caption: e.currentTarget.value })
            }
          />
          <Typography
            variant="body2"
            style={{ textAlign: "right", color: countColor }}
          >
            {state?.caption?.length ? state?.caption?.length : 0}/
            {MAX_CHAR_COUNT}
          </Typography>
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              border: "1px solid black",
              borderColor: colors.dark,
              width: "151px",
              height: "54px",
              fontSize: "16px",
            }}
            onClick={handleConfirm}
            color="primary"
            size="small"
          >
            Save
          </Button>
          <StyledLink
            onClick={() => setIsDeleteDialogOpen(!isDeleteDialogOpen)}
          >
            Delete photo
          </StyledLink>
        </Box>
      </ImageCreditsDialogContent>
    </ImageCreditsDialog>
  )
}

export default View
