import React from "react"
import View from "./View"
import { JobRequest } from "../../redux/jobListings"

export type Props = {
  onSubmit: (JobRequest: JobRequest) => void
  onClose: () => void
  requested: boolean
}

const emptyjobRequest: JobRequest = {
  website: "",
  informations: "",
  origin: "",
}

const JobRequestForm = ({ onSubmit, onClose, requested }: Props) => {
  let request = Object.assign(emptyjobRequest, {})

  const handleOnSubmitForm = async (JobRequest: JobRequest) =>
    onSubmit(JobRequest)

  const validateJobURL = (url: string) =>
    url?.search("http://") === 0 || url?.search("https://") === 0

  const initialValues: JobRequest = {
    ...request,
    website: validateJobURL(request.website!)
      ? request.website
      : "https://" + request.website,
  }

  return (
    <View
      values={initialValues}
      onSubmit={handleOnSubmitForm}
      onClose={onClose}
    />
  )
}

export default JobRequestForm
