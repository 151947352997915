import React, { useState } from "react"
import {
  ClearSearchElement,
  FilterItem,
  LeftBox,
  LowerBox,
  RightBox,
  RightBoxMobile,
  SearchBox,
  SearchCategories,
  SearchCategory,
  SearchIconElement,
  SearchInput,
  SearchLabel,
  SuggestedItem,
  UpperBox,
} from "./styles"
import SearchIcon from "../../images/new-homepage/icons/search.svg"
import ClearIcon from "../../images/new-homepage/icons/x-search.svg"
import FilterIcon from "../../images/mobile-filter-icon.svg"
import DropDownMultiselect from "../DropDownMultiselect/DropDownMultiselect"
import { keyMaterials, typology, tools } from "./project-search"
import { profileType } from "./profile-search"
import FilterHomepageDialog from "../FilterHomepageDialog"
import { useSelector } from "react-redux"
import { selectHomeData } from "../../redux/home"

type Props = {
  activeSearch: string
  onChangeActiveSearch: (value: string, reset: boolean) => void
  typologyFilter?: string[]
  toolsFilter?: string[]
  keyMaterialsFilter?: string[]
  myNetworkFilter: boolean
  profileTypeFilter?: string[]
  text: string
  onUpdateText: (value: string) => void
  onUpdateTypologyFilter: (value: string) => void
  onUpdateToolsFilter: (value: string) => void
  onUpdateKeyMaterialsFilter: (value: string) => void
  onUpdateNetworkFilter: (value: boolean) => void
  onUpdateTextFilter: (value: string) => void
  onUpdateProfileTypeFilter: (value: string) => void
  onShortcutClick: (value: string) => void
}

const View = ({
  activeSearch,
  onChangeActiveSearch,
  typologyFilter,
  toolsFilter,
  keyMaterialsFilter,
  myNetworkFilter,
  profileTypeFilter,
  text,
  onUpdateText,
  onUpdateTypologyFilter,
  onUpdateToolsFilter,
  onUpdateKeyMaterialsFilter,
  onUpdateNetworkFilter,
  onUpdateTextFilter,
  onUpdateProfileTypeFilter,
  onShortcutClick,
}: Props) => {
  const handleSearch = (e: any) => {
    onUpdateTextFilter(text)
    e.preventDefault()
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const trending = useSelector(selectHomeData)?.trending

  return (
    <SearchBox>
      <FilterHomepageDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        activeSearch={activeSearch}
        onChangeActiveSearch={onChangeActiveSearch}
        typologyFilter={typologyFilter}
        toolsFilter={toolsFilter}
        keyMaterialsFilter={keyMaterialsFilter}
        myNetworkFilter={myNetworkFilter}
        profileTypeFilter={profileTypeFilter}
        onUpdateTypologyFilter={onUpdateTypologyFilter}
        onUpdateToolsFilter={onUpdateToolsFilter}
        onUpdateKeyMaterialsFilter={onUpdateKeyMaterialsFilter}
        onUpdateNetworkFilter={onUpdateNetworkFilter}
        onUpdateProfileTypeFilter={onUpdateProfileTypeFilter}
      />

      <UpperBox>
        <LeftBox>
          <form onSubmit={handleSearch} style={{ display: "contents" }}>
            <SearchIconElement
              src={SearchIcon}
              alt={"Search"}
              onClick={() => onUpdateTextFilter(text)}
            />
            <ClearSearchElement
              src={ClearIcon}
              alt={"Clear"}
              onClick={() => onChangeActiveSearch("", true)}
            />
            <SearchInput
              value={text}
              placeholder="Search"
              onChange={(e) => onUpdateText(e.target.value)}
            />
            <input
              type="submit"
              name="submit"
              style={{ position: "fixed", marginLeft: "-10000px" }}
            />
          </form>
        </LeftBox>
        <RightBox>
          <SearchLabel>Filter by</SearchLabel>
          <SearchCategories>
            <SearchCategory
              onClick={() => onChangeActiveSearch("projects", true)}
              $isActive={activeSearch === "projects"}
            >
              Projects
            </SearchCategory>
            <SearchCategory
              onClick={() => onChangeActiveSearch("profiles", true)}
              $isActive={activeSearch === "profiles"}
            >
              People
            </SearchCategory>
            <SearchCategory
              onClick={() => onChangeActiveSearch("posts", true)}
              $isActive={activeSearch === "posts"}
            >
              Posts
            </SearchCategory>
            <SearchCategory
              onClick={() => onChangeActiveSearch("news", true)}
              $isActive={activeSearch === "news"}
            >
              News
            </SearchCategory>
          </SearchCategories>
        </RightBox>
        <RightBoxMobile onClick={() => setIsDialogOpen(true)}>
          <img src={FilterIcon} alt={"Filter"} />
        </RightBoxMobile>
      </UpperBox>
      <LowerBox>
        {activeSearch === "" && (
          <>
            {trending && trending.length > 0 && (
              <>
                <SuggestedItem>
                  <span>Trending:</span>
                </SuggestedItem>
                {trending.map((tag) => {
                  return (
                    <SuggestedItem
                      onClick={() => onShortcutClick(tag.tag.replace("#", ""))}
                    >
                      {tag.tag}
                    </SuggestedItem>
                  )
                })}
              </>
            )}
          </>
        )}
        {activeSearch === "projects" && (
          <>
            <DropDownMultiselect
              title={"Typology"}
              values={typology}
              selected={typologyFilter}
              onSelect={onUpdateTypologyFilter}
            />
            <DropDownMultiselect
              title={"Key Materials"}
              values={keyMaterials}
              selected={keyMaterialsFilter}
              onSelect={onUpdateKeyMaterialsFilter}
            />
            <DropDownMultiselect
              title={"Tools"}
              values={tools}
              selected={toolsFilter}
              onSelect={onUpdateToolsFilter}
            />
            <FilterItem
              onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
              $isActive={myNetworkFilter}
            >
              My Network
            </FilterItem>
          </>
        )}
        {activeSearch === "profiles" && (
          <>
            <DropDownMultiselect
              title={"Individual/Firm"}
              values={profileType}
              selected={profileTypeFilter}
              onSelect={onUpdateProfileTypeFilter}
            />
            <FilterItem
              onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
              $isActive={myNetworkFilter}
            >
              My Network
            </FilterItem>
          </>
        )}
        {activeSearch === "posts" && (
          <>
            <FilterItem
              onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
              $isActive={myNetworkFilter}
            >
              My Network
            </FilterItem>
          </>
        )}
      </LowerBox>
    </SearchBox>
  )
}

export default View
