import React, { useState, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { fetchJobListing, RequestOrigin } from "../../../../redux/jobListings"
import { useAppDispatch } from "../../../../redux/configureStore"
import { useHistory } from "react-router-dom"

import View from "./View"

import { ActionsType as MultiActionType } from "../../../../components/JobsDropDownMultiselect/View"

import {
  initialEmploymentType,
  initialExperienceLevel,
  initialWorkplaceType,
} from "../../../JobsPage/initialStates"
import { Profile } from "../../../../redux/profile"
import { LocationScrollState } from "../../../../types/historyLocationStates"

type DefaultFilters = {
  term: string
  location: string
  sortBy: string
  status: string
  tag: string
  workplaceType: MultiActionType[]
  employmentType: MultiActionType[]
  experienceLevel: MultiActionType[]
  experienceLevelFilter: string
  employmentTypeFilter: string
  workplaceTypeFilter: string
}

interface Props {
  profile?: Profile
}

const JobsTab = ({ profile }: Props) => {
  const [action, setAction] = useState("POP")
  const history = useHistory()

  useEffect(() => {
    const state = history.location.state as LocationScrollState
    if (state && state.scrollToTop) {
      window.scroll(0, 0)
    }
  }, [history])

  const currentFilter = useSelector(
    (state: any) => state.jobListings.dashboardPage.currentFilter
  )

  const initialFilters: DefaultFilters = {
    term: "",
    location: "",
    tag: "",
    sortBy: "recent",
    status: "all",
    workplaceType: JSON.parse(JSON.stringify(initialWorkplaceType)),
    employmentType: JSON.parse(JSON.stringify(initialEmploymentType)),
    experienceLevel: JSON.parse(JSON.stringify(initialExperienceLevel)),
    experienceLevelFilter: "",
    employmentTypeFilter: "",
    workplaceTypeFilter: "",
  }

  if (action === "POP" && history.action !== "PUSH") {
    initialFilters.term = currentFilter?.search
    initialFilters.location = currentFilter?.location
    initialFilters.tag = currentFilter?.tag
    initialFilters.sortBy = currentFilter?.sortBy
    initialFilters.status = currentFilter?.status
    initialFilters.experienceLevelFilter = currentFilter?.experienceLevelFilter
    initialFilters.employmentTypeFilter = currentFilter?.employmentTypeFilter
    initialFilters.workplaceTypeFilter = currentFilter?.workplaceTypeFilter
    initialFilters.workplaceType =
      currentFilter?.workplaceType?.length > 0
        ? currentFilter?.workplaceType
        : initialFilters.workplaceType
    initialFilters.employmentType =
      currentFilter?.employmentType?.length > 0
        ? currentFilter?.employmentType
        : initialFilters.employmentType
    initialFilters.experienceLevel =
      currentFilter?.experienceLevel?.length > 0
        ? currentFilter?.experienceLevel
        : initialFilters.experienceLevel
  }

  const dispatch = useAppDispatch()
  const tag = initialFilters.tag
  const [checkCount, setCheckCount] = useState(0)
  const [status, setStatus] = useState(initialFilters.status)
  const [filterBy, setFilterBy] = useState(initialFilters.sortBy)
  const [term, setTerm] = useState(initialFilters.term)
  const [location, setLocation] = useState(initialFilters.location)
  const [resetedValues, setResetedValues] = useState(false)
  const [workplaceType, setWorkplaceType] = useState(
    JSON.parse(JSON.stringify(initialFilters.workplaceType))
  )
  const [employmentType, setEmploymentType] = useState(
    JSON.parse(JSON.stringify(initialFilters.employmentType))
  )
  const [experienceLevel, setExperienceLevel] = useState(
    JSON.parse(JSON.stringify(initialFilters.experienceLevel))
  )
  const [experienceLevelFilter, setExperienceLevelFilter] = useState(
    initialFilters.experienceLevelFilter
  )
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState(
    initialFilters.employmentTypeFilter
  )
  const [workplaceTypeFilter, setWorkplaceTypeFilter] = useState(
    initialFilters.workplaceTypeFilter
  )

  const currentPage = useSelector(
    (state: any) => state.jobListings.dashboardPage.allJobListingsCurrentPage
  )

  const [page, setPage] = React.useState(
    currentPage > 1 &&
      ((action !== "POP" && history.action === "PUSH") ||
        (action === "POP" && history.action === "POP"))
      ? currentPage
      : 1
  )

  const jobs = useSelector(
    (state: any) => state.jobListings.dashboardPage.allJobListings
  )

  const jobsCount = useSelector(
    (state: any) => state.jobListings.dashboardPage.jobListingsCount
  )

  const hasMoreDefaultValue = jobs.length < jobsCount

  const [hasMore, setHasMore] = useState(hasMoreDefaultValue)

  const handleChangeAction = (action: string) => {
    setAction(action)
    if (action === "FETCH") {
      setPage(1)
    }
  }

  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangeFilterBy = async (value: string) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setFilterBy(value)
  }

  const handleChangeStatus = async (value: string) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setStatus(value)
  }

  const handleChangeWorkplaceType = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setWorkplaceType(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setWorkplaceTypeFilter(tempFilter)
    setPage(1)
  }

  const handleChangeExperienceLevel = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setExperienceLevel(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setExperienceLevelFilter(tempFilter)
    setPage(1)
  }

  const handleChangeEmploymentType = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setEmploymentType(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setEmploymentTypeFilter(tempFilter)
    setPage(1)
  }

  const handleTermChanged = (term: string) => setTerm(term)

  const handleSearchSubmit = async (): Promise<void> => {
    try {
      setAction("SEARCH")
      setHasMore(true)
      setPage(1)
      const { type, payload } = await dispatch(
        fetchJobListing({
          page: 0,
          perPage: 10,
          tag,
          location,
          search: term,
          sortBy: filterBy,
          status: status ? status : "all",
          workplaceType: workplaceType,
          employmentType: employmentType,
          experienceLevel: experienceLevel,
          experienceLevelFilter: experienceLevelFilter,
          employmentTypeFilter: employmentTypeFilter,
          workplaceTypeFilter: workplaceTypeFilter,
          profileId: profile?.connectionId,
          requestOrigin: RequestOrigin.DASHBOARD,
        })
      )
      if (fetchJobListing.fulfilled.type === type) {
        if (payload.data.length >= payload.count) setHasMore(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangePagination = (value: number) => {
    handleChangeAction("PAGINATE")
    setPage(value)
  }

  const handleClearFilters = () => {
    window.scrollTo(0, 0)
    handleChangeAction("FETCH")
    setCheckCount(0)
    setPage(1)
    setResetedValues(true)
    setHasMore(true)
    if (term) setTerm("")
    if (location) setLocation("")
    if (workplaceTypeFilter) {
      setWorkplaceType(JSON.parse(JSON.stringify(initialWorkplaceType)))
      setWorkplaceTypeFilter("")
    }
    if (experienceLevelFilter) {
      setExperienceLevel(JSON.parse(JSON.stringify(initialExperienceLevel)))
      setExperienceLevelFilter("")
    }
    if (employmentTypeFilter) {
      setEmploymentType(JSON.parse(JSON.stringify(initialEmploymentType)))
      setEmploymentTypeFilter("")
    }

    setTimeout(() => {
      setResetedValues(false)
    }, 1000)
  }

  const loadJobs = useCallback(async () => {
    try {
      const fulfilledCalls: boolean[] = []

      if (
        (history.action !== "POP" ||
          action === "FETCH" ||
          action === "PAGINATE" ||
          jobs.length === 0) &&
        action !== "SEARCH"
      ) {
        setHasMore(true)
        const { type, payload } = await dispatch(
          fetchJobListing({
            page,
            perPage: 10,
            location,
            search: term,
            tag,
            sortBy: filterBy,
            status: status ? status : "all",
            workplaceType: workplaceType,
            employmentType: employmentType,
            experienceLevel: experienceLevel,
            experienceLevelFilter: experienceLevelFilter,
            employmentTypeFilter: employmentTypeFilter,
            workplaceTypeFilter: workplaceTypeFilter,
            profileId: profile?.connectionId,
            requestOrigin: RequestOrigin.DASHBOARD,
          })
        )

        fulfilledCalls.push(fetchJobListing.fulfilled.type === type)
        if (fetchJobListing.fulfilled.type === type) {
          setCheckCount(checkCount + payload.data.length)
          if (payload.data.length + checkCount >= payload.count)
            setHasMore(false)
        }
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [page, filterBy, status, experienceLevel, employmentType, workplaceType])

  useEffect(() => {
    loadJobs()
  }, [loadJobs])

  return (
    <View
      jobs={jobs}
      term={term}
      filterBy={filterBy}
      status={status}
      jobsCount={jobsCount}
      page={page}
      workplaceType={workplaceType}
      experienceLevel={experienceLevel}
      employmentType={employmentType}
      resetedValues={resetedValues}
      hasMore={hasMore}
      onTermChanged={handleTermChanged}
      handleChangeFilterBy={handleChangeFilterBy}
      handleChangeStatus={handleChangeStatus}
      handleSearchSubmit={handleSearchSubmit}
      handleChangePagination={handleChangePagination}
      handleChangeWorkplaceType={handleChangeWorkplaceType}
      handleChangeExperienceLevel={handleChangeExperienceLevel}
      handleChangeEmploymentType={handleChangeEmploymentType}
      handleClearFilters={handleClearFilters}
      handleChangeHasMore={handleChangeHasMore}
    />
  )
}

export default JobsTab
