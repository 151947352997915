import React from "react"
import { Box, Grid, Divider } from "@material-ui/core"

import ProfileDetails from "./ProfileDetails"
import IndividualTabView from "./Tabs/IndividualTabView"
import { Profile, ProfileType } from "../../redux/profile"
import { ProfileDivider, RightColumn, ProfileContainer } from "./styles"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import BackButton from "../../components/BackButton"

export type Props = {
  profile: Profile
  authenticatedProfile: Profile
  currentTab: string
  isPublicProfile: boolean
  isGroupProfile: boolean
  isOfflineUser: boolean
  isConnected: boolean
  onAddProject: () => void
  onAddJobListing: () => void
  onTabClicked: (url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  onEditAbout: (plainText: string, richText: string) => Promise<void>
  goToUrl: (url: string) => void
}

const View = ({
  goToUrl,
  profile,
  authenticatedProfile,
  onEditAbout,
  onTabChanged,
  onTabClicked,
  onAddProject,
  isPublicProfile,
  isGroupProfile,
  isOfflineUser,
  isConnected,
  currentTab = "projects",
}: Props) => {
  const { width } = useWindowDimensions()
  const padding = width >= theme.breakpoints.values.md ? "32px" : "0"

  return (
    <>
      <BackButton style={{ marginLeft: "20px" }} />
      <ProfileContainer>
        <Grid
          container
          style={{
            paddingBottom: width >= theme.breakpoints.values.md ? "96px" : "",
          }}
        >
          <Grid item xs={12} sm={12} md={3} aria-label="author details">
            <Box className="profile-container">
              <ProfileDetails
                profile={profile}
                authenticatedProfile={authenticatedProfile}
                isPublicProfile={isPublicProfile}
                isConnected={isConnected}
                goToUrl={goToUrl}
              />
            </Box>
          </Grid>
          <ProfileDivider item>
            <Divider orientation="vertical" light={true} />
          </ProfileDivider>
          <RightColumn
            item
            sm={12}
            md={9}
            container
            style={{ marginLeft: "-1px", paddingLeft: padding }}
          >
            <Grid item xs>
              {profile.type === ProfileType.INDIVIDUAL && (
                <IndividualTabView
                  profile={profile}
                  authenticatedProfile={authenticatedProfile}
                  currentTab={currentTab}
                  onEditAbout={onEditAbout}
                  onAddProject={onAddProject}
                  onTabChanged={onTabChanged}
                  onTabClicked={onTabClicked}
                  goToUrl={goToUrl}
                  isPublicProfile={isPublicProfile}
                  isConnected={isConnected}
                  isGroupProfile={isGroupProfile}
                  isOfflineUser={isOfflineUser}
                />
              )}
            </Grid>
          </RightColumn>
        </Grid>
      </ProfileContainer>
    </>
  )
}

export default View
