import React from "react"

import { Project } from "../../redux/adminProjects"

import View from "./View"

type Props = {
  project: Project
  onSubmit: (project: Project) => Promise<void>
}

const ProjectEditForm = ({ project, onSubmit }: Props) => {
  return <View project={project} onSubmit={onSubmit} />
}

export default ProjectEditForm
