import React from "react"
import { FieldAttributes, useField } from "formik"
import View from "./view"

const TextField = (props: FieldAttributes<any>) => {
  const [field, meta] = useField(props)
  const error = meta.touched ? meta.error : null

  return <View {...props} {...field} errorMessage={error} />
}

export default TextField
