import React from "react"
import View from "./View"
import * as RouteConstants from "../../constants/routes"
import { Profile, removeConnection } from "../../redux/profile"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { updateProfileConnetionRequestState } from "../../redux/connections"

export type Props = {
  profile: Profile
}

const ConnectionCard = ({ profile }: Props) => {
  const [open, setOpen] = React.useState(false)

  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${profile?.username}`
  }

  const handleRemoveConnection = async (profileData: Profile) => {
    try {
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profileData.connectionId

      const { type } = await dispatch(
        removeConnection({ auth, profileId, connectionId })
      )

      if (removeConnection.fulfilled.type === type) {
        handleClose()

        await dispatch(
          updateProfileConnetionRequestState({
            profileId: connectionId!,
            type: "connections",
          })
        )

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <View
      profile={profile}
      goToProfile={goToProfile}
      onRemove={handleRemoveConnection}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}

export default ConnectionCard
