import React from "react"
import View from "./View"
import { Profile } from "../../redux/profile"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"

export type Props = {
  profiles: Profile[]
  isFiltered?: boolean
}

const SuggestionsCard = ({ profiles, isFiltered }: Props) => {
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  return (
    <View
      profiles={profiles}
      authenticatedProfile={authenticatedProfile}
      isFiltered={isFiltered}
    />
  )
}

export default SuggestionsCard
