import React, { useEffect, useState } from "react"

import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { getJobTagSuggestions } from "../../redux/jobListings"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/rootReducer"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  onBlur: () => void
  onFocus: () => void
  initialTags?: string[]
  onChange: (values: string[]) => void
}

const JobListingTags = ({ initialTags, onBlur, onFocus, onChange }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<string>("")
  const [tags, setTags] = useState<string[]>(initialTags || [])
  const [displayError, setDisplayError] = useState<boolean>(false)
  const suggestions = useSelector(
    (state: RootState) => state.jobListings.tagSuggestions
  )

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getJobTagSuggestions({ auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth])

  const saveTag = async (tags: string[]) => {
    setTags(tags)
    onChange(tags)
    setDisplayError(false)
  }

  const onInputChange = (value: string) => setValue(value)

  const onAdd = (newTag: string) => {
    const preSetTags = [
      "onsite",
      "remote",
      "hybrid",
      "internship",
      "contract",
      "part-time",
      "full-time",
      "entry-level",
      "mid-level",
      "senior-level",
      "executive-level",
    ]

    newTag = newTag.trim()
    const exists = tags.find((tag) => tag === newTag)
    const existsPreSet = preSetTags.find(
      (tag) => tag === newTag.toLocaleLowerCase()
    )

    if (!exists && !existsPreSet) saveTag([...tags, newTag])

    setValue(" ")
  }

  const onDelete = (tagToDelete: string) =>
    saveTag(tags.filter((credential: string) => credential !== tagToDelete))

  return (
    <View
      tags={tags}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onEnteredPressed={onAdd}
      onDeletedPressed={onDelete}
      displayError={displayError}
      onInputChange={onInputChange}
      suggestions={suggestions || []}
    />
  )
}

export default JobListingTags
