import React from "react"
import { Grid, TextField } from "@material-ui/core"

import {
  MainContainer,
  TextContainer,
  Text,
  Logo,
  LogoContainer,
  Note,
  ResendButton,
  TextResponse,
  TextTitle,
} from "./styles"

type Props = {
  cookieEmailAddress: string
  emailValue: string
  onEmailChange: (_event: React.ChangeEvent<{ value: any }>) => void
  handleSubmit: (email: string) => void
  sendStatus: string
}

const View = ({
  cookieEmailAddress,
  emailValue,
  onEmailChange,
  handleSubmit,
  sendStatus,
}: Props) => (
  <MainContainer>
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <LogoContainer>
            <Logo
              id="prompt-logo-center"
              src="https://spectacular.design/static/media/logo-charcoal.fff63924.svg"
              alt="Spectacular"
            />
          </LogoContainer>
          <TextTitle>Verify your email address</TextTitle>
          <Text py={2}>
            We sent an email to {cookieEmailAddress} with a confirmation link to
            get access to Spectacular.
          </Text>
          <form>
            <TextField
              name="email"
              label="Email"
              value={emailValue}
              onChange={(text) => onEmailChange(text)}
              fullWidth
              autoComplete="off"
            />
            <ResendButton
              variant="contained"
              color="primary"
              data-testid="resend"
              onClick={() => handleSubmit(emailValue)}
            >
              Resend Confirmation Link
            </ResendButton>
          </form>
          <TextResponse>{sendStatus}</TextResponse>
          <Note py={2}>
            Note: make sure to check your spam or junk folder too if you have
            trouble finding the confirmation link.
          </Note>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
