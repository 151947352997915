import React from "react"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import { deleteProfile } from "../../redux/profile"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const DeleteAccountPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleDelete = async () => {
    try {
      const { type } = await dispatch(
        deleteProfile({
          auth,
        })
      )

      if (type === deleteProfile.fulfilled.type) {
        auth.logout()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Helmet>
        <RouteChangeTracker
          screenTitle={`Delete Account`}
          classTitle="DeleteAccount"
        />
        <title>Spectacular</title>
      </Helmet>
      <View onDelete={handleDelete} />
    </>
  )
}

export default DeleteAccountPage
