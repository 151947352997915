import React from "react"
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core"
import { ConfirmDialog } from "./styles"

export type Props = {
  isOpen: boolean
  title: string
  message: string
  onClose: () => void
  onConfirm: () => void
}

const View = ({ isOpen, onClose, onConfirm, title, message }: Props) => {
  return (
    <ConfirmDialog
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      open={isOpen}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" size="small">
          Ok
        </Button>
      </DialogActions>
    </ConfirmDialog>
  )
}

export default View
