import React, { useState } from "react"
import { Box } from "@material-ui/core"

import {
  RequestDialog,
  RequestDialogTitle,
  RequestDialogBox,
  RequestDialogText,
  DialogCloseButton,
  DialogButtonBox,
} from "../ConfirmConnectionDialog/Styles"

import { useWindowDimensions } from "../../hooks"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"
import { Button } from "../Button"
import theme from "../../theme"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"

export type Props = {
  isOpen: boolean
  onClose: () => void
}

const View = ({ isOpen, onClose }: Props) => {
  const { loginWithRedirect } = useAuth0()
  const { width } = useWindowDimensions()
  const [hover, setHover] = useState(false)
  const history = useHistory()

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  return (
    <RequestDialog
      maxWidth={"md"}
      open={isOpen}
      onClose={onClose}
      className={"ConnectionRequestDialog"}
    >
      <RequestDialogTitle>
        <Box>This content is exclusive to members</Box>
        {width > theme.breakpoints.values.sm && (
          <DialogCloseButton
            src={hover ? CloseIconHover : CloseIcon}
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseLeave={() => {
              setHover(false)
            }}
            alt={"close"}
            onClick={onClose}
          />
        )}
      </RequestDialogTitle>
      <RequestDialogBox>
        <Box>
          <RequestDialogText>
            To engage with this content we invite you to create a profile.
          </RequestDialogText>
        </Box>
        <DialogButtonBox>
          <Button
            onClick={() => {
              history.push("/register")
              onClose()
            }}
            children={"Join now"}
            color={"primary"}
            size={"medium"}
            style={{
              width: width > theme.breakpoints.values.sm ? "95px" : "100%",
            }}
          />
          <Button
            onClick={login}
            children={"Sign in"}
            color={"whiteGreyBorder"}
            size={"medium"}
            style={{
              width: width > theme.breakpoints.values.sm ? "95px" : "100%",
              marginLeft: width > theme.breakpoints.values.sm ? "16px" : "12px",
            }}
          />
        </DialogButtonBox>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
