import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import bgHeader from "../../images/innovation-awards/header-bg-desktop.png"
import bgHeaderMobile from "../../images/innovation-awards/header-bg-mobile.png"

import BorderUpDialog from "../../images/innovation-awards/dialog-border-up.png"
import BorderDownDialog from "../../images/innovation-awards/dialog-border-down.png"

import ulIconWhite from "../../images/innovation-awards/ul-icon-white.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background: url(${bgHeader});
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  height: 457px;
  overflow: hidden;
  border-bottom: 2px solid #000000;

  ${theme.breakpoints.down("sm")} {
    height: auto;
  }

  ${theme.breakpoints.down("xs")} {
    height: 316px;
    align-items: center;
    background: url(${bgHeaderMobile});
    background-position: right;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
  }
`

export const HeaderButtonBox = styled(Box)`
  padding-top: 24px;
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 100px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;

    && button {
      width: 90% !important;
    }
  }

  @media only screen and (max-width: 429px) {
    padding: 0 20px;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const StyledLogo = styled.img`
  ${theme.breakpoints.down("xs")} {
    width: 100%;
    max-width: 320px;
  }
`

export const DescriptionBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #F5F5F5;
  border-bottom: 2px solid #000000;
  box-sizing: border:box;
`

export const DescriptionContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const DescriptionFlexBox = styled(Box)`
  display: flex;
  gap: 32px;
`

export const DescriptionLeftBox = styled(Box)`
  padding-bottom: 78px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 47px;
  }
`

export const DescriptionFont = styled(Typography)`
  padding-top: 96px;
  font-size: 20px;
  line-height: 36px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 50px;
  }
`

export const DescriptionIconBox = styled(Box)`
  border-left: 2px solid #000000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 418px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const PanelistsTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  padding-top: 60px;
  padding-bottom: 48px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    padding-top: 30px;
    padding-bottom: 32px;
  }
`

export const PendingJuryBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  border-top: 2px solid #000;
`

export const PendingJuryBox = styled(Box)`
  width: 100%;
  background: #f5f5f5;
  box-sizing: border-box;
  padding: 64px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 0;
  }
`

export const JuryBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  border-bottom: 2px solid #000;
`
export const PendingJuryContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px 64px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  && span {
    font-size: 125px;
    color: #979797;
    font-family: "FreightDispProBook";
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px 40px;

    && span {
      font-size: 100px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    && span {
      font-size: 80px;
    }
  }

  @media only screen and (max-width: 470px) {
    && span {
      font-size: 64px;
    }
  }

  @media only screen and (max-width: 385px) {
    && span {
      font-size: 48px;
    }
  }
`

export const JuryBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #000;
  padding-bottom: 66px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 56px;
  }
`

export const JuryBoxTemp = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #000;

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    border: 0;
    padding: 20px 0;
  }
`

export const JuryBorderedTemp = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
  }
`

export const JuryContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const JuryTempBox = styled(Box)`
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  padding-left: 80px;

  ${theme.breakpoints.down("sm")} {
    border: 0;
    padding: 0;
  }
`

export const PanelistsBox = styled(Box)`
  display: flex;
  margin-top: 55px;
  gap: 32px;
  width: 100%;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    gap: 32px;
    flex: 40%;
    justify-content: center;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    margin-top: 64px;
    flex: 100%;
  }
`

export const PanelistBox = styled(Box)`
  width: 100%;
  max-width: 350px;

  ${theme.breakpoints.down("sm")} {
    width: 226px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    max-width: 100%;
  }
`

export const PanelistIcon = styled.img`
  width: 100%;
  border-radius: 4px;
`

export const PanelistTextBox = styled(Box)`
  margin-top: 11px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 6px;
  }
`

export const PanelistTitle = styled(Typography)`
  font-size: 24px;
  font-family: Public-Sans-Medium;
`

export const PanelistText = styled(Typography)`
  font-size: 17px;
  line-height: 22px;
  color: #717171;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 17px;
  }
`

export const PanelistDescriptionBox = styled(Box)`
  margin-top: 16px;
`

export const PanelistDescription = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
`

export const RegisterBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  padding: 60px 0 0;

  ${theme.breakpoints.down("sm")} {
    padding: 30px 20px 32px;
  }
`

export const RegisterBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  border-bottom: 2px solid #fff;
  padding-top: 60px;
`

export const RegisterContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding-left: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const RegisterSubTitle = styled(Typography)`
  font-size: 40px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 24px;
  }
`

export const RegisterTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const RegisterContent = styled(Box)`
  padding-bottom: 64px;
  width: 100%;
  display: flex;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 48px;
  }
`

export const RegisterContentLeftBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 64px;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    gap: 32px;

    && button {
      width: 100% !important;
    }
  }
`

export const RegisterContentRightBox = styled(Box)`
  margin-top: auto;
  margin-left: auto;

  && img {
    height: 156px;
  }

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const HowItWorksBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  border-bottom: 2px solid #fff;
  padding: 60px 0 64px;

  ${theme.breakpoints.down("sm")} {
    padding: 30px 20px 32px;
  }
`

export const HowItWorksBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
`

export const HowItWorksContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  display: flex;
  gap: 32px;

  @media only screen and (max-width: 1420px) {
    padding-left: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    flex-flow: column;
    gap: 0;
  }
`

export const HowItWorksDescriptionBox = styled(Box)`
  width: 100%;
  padding: 48px 64px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 32px;
    flex-flow: column;
    width: auto;
    border-left: 0;
    border-right: 0;
  }
`

export const HowItWorksSubTitle = styled(Typography)`
  font-size: 16px;
  margin-top: 16px;
  line-height: 24px;
  color: #fff;

  && img {
    width: 19px;
    vertical-align: middle;
  }
`

export const HowItWorksTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const HowItWorksList = styled(Box)`
  color: #fff;
  font-size: 20px;
`

export const HowItWorksListItem = styled(Box)`
  color: #fff;
  font-size: 16px;
  background: url(${ulIconWhite}) no-repeat;
  background-position-y: 2px;
  padding-left: 36px;
  margin-top: 18px;

  && span {
    font-family: Public-Sans-Bold;
  }

  && a {
    color: #fff;
    font-family: Public-Sans-Black;
  }
`

export const HowItWorksInnerList = styled(Box)`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: 16px;
  && span {
    font-size: 24px;
    align-self: flex-start;
    margin-top: -5px;
  }
`

export const HowItWorksInnerTitle = styled(Typography)`
  font-size: 40px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
  }
`

export const FormSpacingBox = styled(Box)`
  padding-top: 27px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 20px;

    && button {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
`

export const SuccessDialogArea = styled(Dialog)`
  min-height: 100vh;
`

export const SuccessDialogBox = styled(Box)`
  overflow-x: hidden;
  width: 640px;
  height: fit-content;
  box-sizing: border-box;
  padding: 27px;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const DialogBgBox = styled(Box)`
  box-sizing: border-box;
  background: url(${BorderUpDialog}) top left no-repeat,
    url(${BorderDownDialog}) bottom right no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: 80px 0;
`

export const MarketingTitleBox = styled(Box)`
  margin-top: 30px;
  padding: 0 40px;
`

export const MarketingDialogTitle = styled(Typography)`
  font-size: 24px;
  line-height: 36px;
  color: #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const MarketingDialogText = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  color: #2d2d2d;
`

export const TextfieldBox = styled(Box)`
  text-align: left;
  margin-top: 48px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;

    && button {
      width: 100% !important;
    }
  }
`

export const SuccessDialogTitle = styled(Typography)`
  font-size: 32px;
  color: #2d2d2d;
  margin-top: 32px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 18px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 40px;
  }
`

export const SuccessDialogText = styled(Typography)`
  font-size: 16px;
  margin-top: 30px;

  && span {
    font-family: "Public-Sans-Bold";
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 16px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 20px;
  }
`

export const SuccessDialogHint = styled(Typography)`
  font-family: "Public-Sans-Bold";
  font-size: 18px;
  line-height: 36px;
  margin-top: 49px;

  @media only screen and (max-width: 390px) {
    font-size: 16px;
    line-height: 32px;
  }
`

export const DialogIconsBox = styled(Box)`
  margin-top: 26px;
  display: flex;
  gap: 21px;

  @media only screen and (max-width: 390px) {
    gap: 16px;

    && img {
      width: 40px;
    }
  }
`

export const DialogManualShareBox = styled(Box)`
  display: flex;
  gap: 55px;
  margin-top: 22px;

  @media only screen and (max-width: 390px) {
    flex-flow: column;
    gap: 12px;
  }
`

export const CopyLinkText = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const EmailText = styled.a`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;
  color: #2d2d2d;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const SpIconImg = styled.img`
  margin-top: 39px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 23px;
  }
`

export const CheckboxLabel = styled(Typography)`
  font-size: 16px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`

export const CheckboxLabelSmall = styled(Typography)`
  font-size: 12px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`
export const SuccessIconImg = styled.img`
  ${theme.breakpoints.down("xs")} {
    height: 70px;
  }

  @media only screen and (max-width: 390px) {
    height: 60px;
  }
`

export const SuccessSubscriptionIconImg = styled.img`
  height: 66px;
`

export const DialogSpIcon = styled.img`
  height: 72px;
`

export const CloseIconImg = styled.img`
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;
`

export const EmailBox = styled(Box)`
  background: #000;
  height: 239px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: -64px;
  border-bottom: 2px solid #ffffff;

  ${theme.breakpoints.down("xs")} {
    margin-bottom: -20px;
  }
`

export const EmailTitle = styled(Typography)`
  font-size: 48px;
  color: #fff;
  margin-bottom: 27px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const EmailLink = styled.a`
  font-size: 24px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #fff;
  }

  && img {
    margin-right: 16px;
    vertical-align: middle;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;

    && img {
      height: 20px;
    }
  }
`
export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0.9px;
    right: 138px;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const InactiveShareBox = styled(Box)`
  width: 320px;
  padding: 20px;
  font-size: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  font-family: Public-Sans-Bold;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    font-size: 14px;
  }
`

export const InactiveShareImages = styled(Box)`
  display: flex;
  gap: 30px;
  margin-top: 19px;
  justify-content: center;
`

export const AddAuthor = styled(Typography)`
  color: #3057e1;
  font-size: 16px;
  font-style: italic;
  cursor: pointer;
`

export const InlineLink = styled.span`
  color: #3057e1;
  font-style: italic;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled.a`
  font-family: Public-Sans;
  text-decoration: none;
  color: #3057e1;

  :hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const ProjectsBox = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: #000;
  border-bottom: 2px solid #fff;
  padding: 103px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 45px 0 50px;
  }
`

export const ProjectsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  display: flex;
  gap: 40px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
  }
`

export const ProjectsContent = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

export const ProjectsTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const ProjectsText = styled(Typography)`
  font-size: 16px;
  line-height: 26px;
  margin-top: 33px;
  color: #fff;

  && span {
    font-weight: bold;
  }

  && img {
    width: 19px;
    vertical-align: middle;
  }
`

export const ProjectsButtonBox = styled(Box)`
  margin-top: 29px;

  ${theme.breakpoints.down("sm")} {
    text-align: center;

    && button {
      width: 90% !important;
    }
  }
`

export const ProjectsImageBox = styled(Box)`
  && img {
    border-radius: 4px;
  }

  @media only screen and (max-width: 850px) {
    && img {
      width: 320px;
    }
  }

  @media only screen and (max-width: 680px) {
    && img {
      width: 300px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    && img {
      width: 100%;
    }
  }
`

export const CheckboxBox = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 24px;
  padding: 24px 40px 0;
  box-sizing: border-box;
`
