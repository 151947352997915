import React from "react"

import Header from "./Header"

import {
  MainContainer,
  HowItWorksBox,
  HowItWorksContainer,
  DescriptionFont,
  DescriptionBox,
  DescriptionContainer,
  DescriptionIconBox,
  JuryContainer,
  HowItWorksTitle,
  HowItWorksList,
  HowItWorksListItem,
  HowItWorksDescriptionBox,
  DescriptionFlexBox,
  DescriptionLeftBox,
  EmailBox,
  EmailTitle,
  EmailLink,
  JuryBoxBordered,
  PanelistsTitle,
  JuryBox,
  HowItWorksBoxBordered,
  HowItWorksInnerTitle,
  HowItWorksInnerList,
  HowItWorksSubTitle,
  PanelistsBox,
  ProjectsBox,
  ProjectsContainer,
  ProjectsContent,
  ProjectsTitle,
  ProjectsText,
  ProjectsButtonBox,
  ProjectsImageBox,
  HeaderButtonBox,
  PendingJuryBox,
  PendingJuryBoxBordered,
  PendingJuryContainer,
  RegisterContainer,
  RegisterTitle,
  RegisterSubTitle,
  RegisterBox,
  RegisterContent,
  RegisterContentLeftBox,
  RegisterContentRightBox,
} from "./styles"

import spBigIcon from "../../images/innovation-awards/sp-big-icon.svg"
import awardsIcon from "../../images/innovation-awards/awards-icon.svg"
import emailWhiteIcon from "../../images/innovation-awards/email-white-icon.svg"

import WeekendCompetitionLogoWhite from "../../images/innovation-awards/weekend-competition-logo-white.svg"

import RegisterDialog from "./RegisterDialog"
import Panelist from "./Panelist"
import { Button } from "../../components"
import InnovationAwardsAnnouncement from "../../components/InnovationAwardsAnnouncement"
import InnovationAwardsJury from "../../components/InnovationAwardsJury"
import BackButton from "../../components/BackButton"
import environment from "../../environment"
import { Competition } from "../../redux/competition"

import { TypeAnimation } from "react-type-animation"
import { Box } from "@material-ui/core"

interface Props {
  goToForm: () => void
  onCloseRegisterDialog: () => void
  onOpenRegisterDialog: () => void
  goToProjects: () => void
  isAuthenticated: boolean
  isRegisterDialogOpen: boolean
  competition?: Competition
}

const View = ({
  goToForm,
  onCloseRegisterDialog,
  onOpenRegisterDialog,
  goToProjects,
  isAuthenticated,
  isRegisterDialogOpen,
  competition,
}: Props) => {
  const renderHTML = (rawHTML: string) => {
    const html = rawHTML?.replace(
      "{awardsIcon}",
      `<img src='${awardsIcon}' alt='awards' />`
    )
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: html },
    })
  }

  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <BackButton
        style={{
          marginLeft: "20px",
          position: "fixed",
          marginTop: environment.device === "ios-app" ? "0" : "",
        }}
      />
      <Header competition={competition} />
      <DescriptionBox>
        <DescriptionContainer>
          <DescriptionFlexBox>
            <DescriptionLeftBox>
              <DescriptionFont variant={"body1"}>
                {renderHTML(competition?.description!)}
              </DescriptionFont>
              {competition?.registerOpen && (
                <HeaderButtonBox>
                  <Button
                    onClick={goToForm}
                    children={"Register now"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                </HeaderButtonBox>
              )}
            </DescriptionLeftBox>
            <DescriptionIconBox>
              <img src={spBigIcon} alt={"SP Icon"} />
            </DescriptionIconBox>
          </DescriptionFlexBox>
        </DescriptionContainer>
      </DescriptionBox>

      <div id="winners">
        {competition?.winners?.winners &&
          competition.winners?.winners?.length >= 3 && (
            <InnovationAwardsAnnouncement competition={competition} />
          )}
        {competition?.juryReview && (
          <InnovationAwardsJury
            origin={"landing-page"}
            juryReview={competition.juryReview}
          />
        )}
      </div>

      {competition?.projects && (
        <ProjectsBox>
          <ProjectsContainer>
            <ProjectsContent>
              <ProjectsTitle variant={"h1"}>
                {competition.projects.title}
              </ProjectsTitle>
              <ProjectsText>
                {renderHTML(competition.projects.copy)}
              </ProjectsText>
              <ProjectsButtonBox>
                <Button
                  onClick={goToProjects}
                  children={"All Submissions"}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    width: "194px",
                    height: "48px",
                    fontSize: "16px",
                  }}
                />
              </ProjectsButtonBox>
            </ProjectsContent>
            <ProjectsImageBox>
              <img src={competition.projects.image} alt={"Projects"} />
            </ProjectsImageBox>
          </ProjectsContainer>
        </ProjectsBox>
      )}

      <HowItWorksBoxBordered>
        <HowItWorksContainer>
          <HowItWorksTitle variant={"h1"}>How It Works</HowItWorksTitle>
        </HowItWorksContainer>
      </HowItWorksBoxBordered>
      {competition?.howItWorks?.map((row, key) => {
        return (
          <HowItWorksBox>
            <HowItWorksContainer>
              <HowItWorksDescriptionBox
                style={
                  key === competition.howItWorks.length - 1
                    ? { borderBottom: 0 }
                    : {}
                }
              >
                <HowItWorksList>
                  <HowItWorksInnerTitle variant={"h2"}>
                    {row.title}
                  </HowItWorksInnerTitle>
                  {row.subTitle && (
                    <HowItWorksSubTitle>
                      {renderHTML(row.subTitle)}
                    </HowItWorksSubTitle>
                  )}
                  {row.items?.map((item) => {
                    return (
                      <HowItWorksListItem>
                        {renderHTML(item.item)}
                        {item.subItem?.map((subItem) => {
                          return (
                            <HowItWorksInnerList>
                              <span>•</span> {renderHTML(subItem)}
                            </HowItWorksInnerList>
                          )
                        })}
                      </HowItWorksListItem>
                    )
                  })}
                </HowItWorksList>
              </HowItWorksDescriptionBox>
            </HowItWorksContainer>
          </HowItWorksBox>
        )
      })}

      {competition?.jury && competition.jury.length > 0 ? (
        <>
          <JuryBoxBordered id={"jury"}>
            <JuryContainer>
              <PanelistsTitle variant={"h1"}>Meet the Jury</PanelistsTitle>
            </JuryContainer>
          </JuryBoxBordered>
          <JuryBox>
            <JuryContainer>
              <PanelistsBox>
                {competition.jury.map((jury) => {
                  return <Panelist jury={jury} />
                })}
              </PanelistsBox>
            </JuryContainer>
          </JuryBox>
        </>
      ) : (
        <PendingJuryBox>
          <PendingJuryBoxBordered>
            <PendingJuryContainer>
              <PanelistsTitle variant={"h1"}>Jury</PanelistsTitle>
              <TypeAnimation
                sequence={["Coming soon", 500, "", 500]}
                cursor={true}
                repeat={Infinity}
              />
            </PendingJuryContainer>
          </PendingJuryBoxBordered>
        </PendingJuryBox>
      )}

      {competition?.registerOpen && (
        <>
          <RegisterBox id="register">
            <RegisterContainer>
              <RegisterContent>
                <RegisterContentLeftBox>
                  <Box>
                    <RegisterTitle variant={"h2"}>Register today</RegisterTitle>
                    <RegisterSubTitle variant={"h2"}>
                      to receive weekly competition ‘hints’
                    </RegisterSubTitle>
                    <HeaderButtonBox>
                      <Button
                        onClick={() => onOpenRegisterDialog()}
                        children={"Register now"}
                        color={"secondary"}
                        size={"medium"}
                        style={{
                          width: "220px",
                          height: "48px",
                          fontSize: "16px",
                        }}
                      />
                    </HeaderButtonBox>
                  </Box>
                </RegisterContentLeftBox>
                <RegisterContentRightBox>
                  <img src={WeekendCompetitionLogoWhite} alt={"Logo"} />
                </RegisterContentRightBox>
              </RegisterContent>
            </RegisterContainer>
          </RegisterBox>
        </>
      )}

      <EmailBox>
        <EmailTitle variant={"h1"}>For Questions Contact</EmailTitle>
        <EmailLink href="mailto:sethkopka@blackspectacles.com">
          <img src={emailWhiteIcon} alt={"email"} />
          sethkopka@blackspectacles.com
        </EmailLink>
      </EmailBox>

      <RegisterDialog
        open={isRegisterDialogOpen}
        onClose={onCloseRegisterDialog}
        campaign={competition?.code!}
      />
    </MainContainer>
  )
}

export default View
