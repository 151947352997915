import { Form } from "formik"
import styled from "styled-components"

export const FormStyled = styled(Form)`
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
`

export const SocialIcon = styled.img`
  width: 25px;
  height: 25px;
`

export const EmailLink = styled.span`
  display: inline;
  cursor: pointer;
  color: #717171;
  background: none !important;
  :hover {
    color: #2d2d2d;
  }
`
