import { Box } from "@material-ui/core"
import React, { useState } from "react"
import NotificationCard from "../../cards/NotificationCard"
import { PopUp, PopUpTitle, PopUpBox, PopUpCloseIcon } from "./styles"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

export type Props = {
  notification: any
  handleNotificationClick: () => void
  handleSetOpen: (value: boolean) => void
  open: boolean
}

const View = ({
  notification,
  open,
  handleNotificationClick,
  handleSetOpen,
}: Props) => {
  const [hover, setHover] = useState(false)

  const onClose = () => {
    handleSetOpen(false)
  }

  return (
    <PopUp
      style={{
        opacity: open ? 1 : 0,
        visibility: open ? "visible" : "hidden",
      }}
    >
      <PopUpBox>
        <PopUpTitle>New notification</PopUpTitle>
        <PopUpCloseIcon
          alt={"close"}
          onClick={onClose}
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        />
      </PopUpBox>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
        }}
      />
      <Box style={{ padding: "4px 16px" }}>
        <NotificationCard
          notification={notification}
          index={9999}
          handleNotificationClick={handleNotificationClick}
          hideButton={true}
          goToNotifications={true}
        />
      </Box>
    </PopUp>
  )
}

export default View
