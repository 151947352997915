import React from "react"
import {
  DateLabel,
  DateLabelHorizontal,
  HorizontalBox,
  HorizontalLabel,
  InnovationAwardsImage,
  Label,
  LabelsBox,
  StudioAwardsImage,
  VerticalBox,
  WeekendCompetitionImage,
} from "./Styles"

import WeekendCompetition from "../../images/innovation-awards/wk-badge.svg"
import StudioAwards from "../../images/innovation-awards/studio-badge.svg"
import InnovationAwards from "../../images/innovation-awards/ia-badge.svg"
import awardsIcon from "../../images/innovation-awards/awards-icon.svg"
import { ProjectReward } from "../../redux/adminProjectReward"

interface Props {
  reward: ProjectReward
  variant: "vertical" | "horizontal"
}

const View = ({ reward, variant }: Props) => {
  const formatDateToShortMonthYear = () => {
    if (reward.competition?.date) {
      const date = new Date(reward.competition?.date)
      return date
        .toLocaleDateString("en-US", { month: "short", year: "numeric" })
        .toUpperCase()
        .replace(" ", "/")
        .replace(".", "")
    }
  }

  const renderLabel = (prize?: string) => {
    if (prize === "1st-place")
      return (
        <>
          1<sup>st</sup> PLACE
        </>
      )

    if (prize === "2nd-place")
      return (
        <>
          2<sup>nd</sup> PLACE
        </>
      )

    if (prize === "3rd-place")
      return (
        <>
          3<sup>rd</sup> PLACE
        </>
      )

    if (prize === "people-choice-award")
      return (
        <>
          PEOPLE’S CHOICE <img src={awardsIcon} alt="Prople's choice" />
        </>
      )

    if (variant === "horizontal") return <>WINNER</>
  }

  const renderImage = () => {
    if (reward.competition?.displayTitle === "Weekend Competition") {
      return (
        <WeekendCompetitionImage
          src={WeekendCompetition}
          alt={reward.prize}
          variant={variant}
        />
      )
    }

    if (reward.competition?.displayTitle === "Studio Awards") {
      return (
        <StudioAwardsImage
          src={StudioAwards}
          alt={reward.prize}
          variant={variant}
        />
      )
    }

    if (reward.competition?.displayTitle === "Innovation Awards") {
      return (
        <InnovationAwardsImage
          src={InnovationAwards}
          alt={reward.prize}
          variant={variant}
        />
      )
    }
  }

  if (variant === "vertical") {
    return (
      <VerticalBox>
        {renderImage()}
        <Label $isPeopleChoice={reward.prize === "people-choice-award"}>
          {renderLabel(reward.prize)}
        </Label>
        <DateLabel
          $isPeopleChoice={reward.prize === "people-choice-award"}
          $isBig={reward.competition?.displayTitle === "Studio Awards"}
        >
          {formatDateToShortMonthYear()}
        </DateLabel>
      </VerticalBox>
    )
  } else {
    return (
      <HorizontalBox>
        {renderImage()}
        <LabelsBox>
          <HorizontalLabel
            $isPeopleChoice={reward.prize === "people-choice-award"}
            $isBig={reward.competition?.displayTitle === "Studio Awards"}
          >
            {renderLabel(reward.prize)}
          </HorizontalLabel>
          <DateLabelHorizontal
            $isPeopleChoice={reward.prize === "people-choice-award"}
          >
            {formatDateToShortMonthYear()}
          </DateLabelHorizontal>
        </LabelsBox>
      </HorizontalBox>
    )
  }
}

export default View
