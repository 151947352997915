import React from "react"
import Image from "material-ui-image"
import { Box, Grid, Typography } from "@material-ui/core"

import CoverSrc from "../../images/member-benefits-cover.jpg"
import {
  MainContainer,
  TextContainer,
  Text,
} from "../../components/Styles/StaticPages"

const View = () => (
  <MainContainer>
    <Image src={CoverSrc} aspectRatio={16 / 9} disableSpinner />
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <Typography variant="h1">Member benefits</Typography>
          <Text py={2}>
            Spectacular is free to join in the pursuit of democratizing how
            architects and firms connect with each other. Here are some benefits
            we offer our members:
          </Text>
          <Box pt={2}>
            <Typography variant="h4">Individuals</Typography>
          </Box>
          <Text py={2}>
            <ul>
              <li>
                Showcase your projects, background, and areas of expertise in
                your profile.
              </li>
              <li>Get discovered by top firms in the industry.</li>
              <li>
                Gain a competitive advantage in your career with the opportunity
                for your projects to be featured.
              </li>
              <li>
                Network and connect with other licensed architects and
                designers.
              </li>
              <li>
                Engage with individual and firm profiles to see the most
                captivating projects and up-and-coming designers.
              </li>
              <li>
                Explore our curated collection of crowdsourced projects from
                around the world on the Spectacular homepage.
              </li>
              <li>
                Search and filter the Spectacular database to seek inspiration,
                find job opportunities, and discover industry trends.
              </li>
            </ul>
          </Text>
          <Typography variant="h4">Groups</Typography>
          <Text py={2}>
            <ul>
              <li>
                Display your firm's growing portfolio of work on your profile.
              </li>
              <li>
                Show the journey to completion with drawings, renderings, and
                other behind-the-scene components of the project build.
              </li>
              <li>
                Gain exposure with the opportunity for your projects to be
                featured.
              </li>
              <li>
                Explore our curated collection of striking and original projects
                from around the world on the Spectacular homepage.
              </li>
              <li>
                Tell your firm's story with descriptions, location, mission
                statement, areas of focus, and awards.
              </li>
              <li>
                Recruit your ideal employee by posting job openings and
                specifics for candidates to easily apply.
              </li>
              <li>
                Search and filter the Spectacular database to find talent based
                on desired criteria and skills.
              </li>
            </ul>
          </Text>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
