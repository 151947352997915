import { SelectOption } from "../components/SelectField"

export const workplaceTypes: Array<SelectOption> = [
  { value: "onsite", name: "Onsite" },
  { value: "remote", name: "Remote" },
  { value: "hybrid", name: "Hybrid" },
  { value: "n/a", name: "N/A" },
]

export const employmentTypes: Array<SelectOption> = [
  { value: "internship", name: "Internship" },
  { value: "contract", name: "Contract" },
  { value: "part-time", name: "Part-time" },
  { value: "full-time", name: "Full-time" },
  { value: "n/a", name: "N/A" },
]

export const experienceLevels: Array<SelectOption> = [
  { value: "entry-level", name: "Entry-level" },
  { value: "mid-level", name: "Mid-level" },
  { value: "senior-level", name: "Senior-level" },
  { value: "executive-level", name: "Executive-level" },
  { value: "n/a", name: "N/A" },
]
