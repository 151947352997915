import React from "react"
import View from "./View"
import { JuryReview } from "../../redux/competition"

interface Props {
  origin: string
  competition?: string
  juryReview?: JuryReview[]
}

const InnovationAwardsJury = ({ origin, competition, juryReview }: Props) => {
  return (
    <View origin={origin} competition={competition} juryReview={juryReview} />
  )
}

export default InnovationAwardsJury
