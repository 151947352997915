/* eslint-disable */
import React from "react"
import { Checkbox as MUICheckbox } from "@material-ui/core"

import { CheckboxContainer, CheckboxLabel } from "./Styles"

import { ReactComponent as CheckboxActive } from "../../images/checkbox-active.svg"
import { ReactComponent as CheckboxDefault } from "../../images/checkbox-default.svg"

interface Props {
  checked?: boolean
  onChange: () => void
  label?: string | React.ReactNode
}

export const Checkbox: React.FC<Props> = ({ label, checked, onChange }) => {
  const labelRef = React.useRef<HTMLSpanElement>(null)
  const [marginTop, setMarginTop] = React.useState("-11px")

  React.useEffect(() => {
    if (labelRef.current) {
      if (labelRef.current.clientHeight < 54 && marginTop !== "-11px")
        setMarginTop("-11px")

      if (labelRef.current.clientHeight >= 54 && marginTop !== "-29px")
        setMarginTop("-29px")
    }
    // eslint-disable-next-line
  })

  return (
    <CheckboxContainer marginTop={marginTop}>
      <MUICheckbox
        icon={<CheckboxDefault />}
        checkedIcon={<CheckboxActive />}
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <CheckboxLabel ref={labelRef}>{label}</CheckboxLabel>
    </CheckboxContainer>
  )
}
