import styled from "styled-components"
import { colors } from "../../theme"
import SearchBar from "material-ui-search-bar"

export const MainSearchBar = styled(SearchBar)`
  box-shadow: none;
  border: solid 0.5px ${colors.dark};
  background-color: ${colors.lightGray};
  border-radius: 0;
  height: 56px;
  .MuiInputBase-input {
    font-size: 22px;
    color: ${colors.slate};
  }
`

export const MobileSearchBar = styled.input`
  box-shadow: none;
  background-color: #f7f7f7;
  border-radius: 0;
  border: 0.5px solid #d8d8d8;
  height: 48px;
  width: 100%;
  font-size: 14px;
  padding: 0 0 0 38px;
  box-sizing: border-box;
  color: ${colors.dark};

  &:focus {
    outline: none;
  }
`
