import React from "react"
import { Dialog, DialogContent, Link, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

import { ReactComponent as ArrowLeft } from "../../images/Nav-Arrow-Left.svg"
import FlipVertical from "../../images/image-flip-vertical.svg"
import FlipHorizontal from "../../images/image-flip-horizontal.svg"
import RotateLeft from "../../images/image-rotate-left.svg"
import RotateRight from "../../images/image-rotate-right.svg"
import Minus from "../../images/image-minus.svg"
import Plus from "../../images/image-plus.svg"

export const RightArrowIcon = styled(ArrowLeft)`
  width: 40px;
  height: 40px;
  color: white;
  transform: rotate(180deg);
`

export const LeftArrowIcon = styled(ArrowLeft)`
  color: white;
  width: 40px;
  height: 40px;
`

export const ImageCreditsDialog = styled(Dialog)`
  margin-bottom: ${theme.spacing(40)}px;
`

export const ImageCreditsDialogContent = styled(DialogContent)`
  padding: 0px 200px 80px 200px;
  margin-top: 56px;

  ${theme.breakpoints.down("sm")} {
    padding: 0px 80px 30px 80px;
    margin-top: 26px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0px 20px 30px 20px;
  }
`

export const LeftNavButtonOverlay = styled.div`
  width: 40px;
  height: 40px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  position: absolute;
  display: inline-flex;
  cursor: pointer;
  text-align: center;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const RightNavButtonOverlay = styled.div<{ height: number }>`
  width: 40px;
  height: 40px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  position: absolute;
  display: inline-flex;
  cursor: pointer;
  background: ${colors.dark};
  margin-right: ${({ height }) => (height <= 900 ? "10px" : "")};

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.666667%;
  position: relative;
  background: ${colors.lightGray};
  border-color: ${colors.gray};
  border-width: 1px;
  border-style: solid;
`

export const ImageThumbnail = styled.img<{ auto: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: ${colors.alert};
  margin-left: 24px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 0.5px solid;
  padding-bottom: 2px;
`

export const ImageErrorList = styled.li`
  color: red;
  padding: 0.2rem;
`

export const FlipVerticalButton = styled.div`
  min-width: 36.11px;
  height: 36.11px;
  border-radius: 50px;
  margin-right: 24px;
  background: url(${FlipVertical}) #2d2d2d no-repeat center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: #000000;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 32px;
    height: 32px;
    margin-right: 16px;
    background-size: 17px;
    background-position: 7px;
  }
`

export const FlipHorizontalButton = styled.div`
  min-width: 36.11px;
  height: 36.11px;
  border-radius: 50px;
  background: url(${FlipHorizontal}) #2d2d2d no-repeat center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: #000000;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 32px;
    height: 32px;
    background-size: 21px;
    background-position: 6px;
  }
`

export const RotateLeftButton = styled.div`
  min-width: 36.11px;
  height: 36.11px;
  border-radius: 50px;
  background: url(${RotateLeft}) #2d2d2d no-repeat;
  background-position: 6px 6px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: #000000;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 32px;
    height: 32px;
    background-position: 5px 5px;
    background-size: 23px;
  }
`

export const RotateRightButton = styled.div`
  min-width: 36.11px;
  height: 36.11px;
  border-radius: 50px;
  margin-right: 24px;
  background: url(${RotateRight}) #2d2d2d no-repeat;
  background-position: 5px 6px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: #000000;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 32px;
    height: 32px;
    margin-right: 16px;
    background-position: 5px 5px;
    background-size: 23px;
  }
`

export const MinusButton = styled.div`
  min-width: 16px;
  height: 16px;
  background: url(${Minus}) #2d2d2d no-repeat center;
  cursor: pointer;
  &:hover {
    background-color: #000000;
  }
`

export const PlusButton = styled.div`
  min-width: 16px;
  height: 16px;
  background: url(${Plus}) #2d2d2d no-repeat center;
  cursor: pointer;
  &:hover {
    background-color: #000000;
  }
`

export const ActionsBox = styled.div`
  padding-top: 32px;
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  align-items: center;

  ${theme.breakpoints.down("xs")} {
    flex-wrap: wrap;
  }
`

export const ZoomBox = styled.div`
  width: 64%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const RotateBox = styled.div`
  display: flex;
  align-items: center;
  flex-basis: content;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    justify-content: center;
    margin-top: 16px;
    flex-basis: auto;
  }
`

export const FlipTypography = styled(Typography)`
  font-size: 16px;
  padding-left: 40px;
  padding-right: 16px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 24px;
    padding-right: 8px;
  }

  ${theme.breakpoints.down("xs")} {
    padding-left: 0;
    padding-right: 8px;
  }
`

export const RotateTypography = styled(Typography)`
  font-size: 16px;
  padding-left: 40px;
  padding-right: 16px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 24px;
    padding-right: 8px;
  }
`

export const ZoomTypography = styled(Typography)`
  font-size: 16px;
  padding-right: 24px;

  ${theme.breakpoints.down("sm")} {
    padding-right: 8px;
  }
`
