import React, { useState, useEffect } from "react"
import { ActionButton, Counter, Icon } from "./Styles"
import { Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"

import IconActive from "../../images/new-homepage/icons/nominate-active.svg"
import IconDefault from "../../images/new-homepage/icons/nominate-default.svg"
import IconHover from "../../images/new-homepage/icons/nominate-hover.svg"
import Dialog from "../ProjectNominationIcon/dialog"
import { Profile } from "../../redux/profile"

export type Props = {
  dispatchComplete: boolean
  project: Project
  onNominate: (hide?: boolean) => Promise<void>
  onUnnominate: () => Promise<void>
  dialogIsOpen: boolean
  handleCloseDialog: () => void
  handleOpenDialog: () => void
  authenticatedProfile: Profile
}

const View = ({
  dispatchComplete,
  project,
  onNominate,
  onUnnominate,
  dialogIsOpen,
  handleCloseDialog,
  handleOpenDialog,
  authenticatedProfile,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const [nomination, setNomination] = useState(project.nominated)
  const [hover, setHover] = React.useState(false)

  useEffect(() => {
    setNomination(project.nominated!)
  }, [project])

  const handleNominate = (hide?: boolean) => {
    if (!disableNomination) {
      if (nomination) {
        if (isAuthenticated) {
          setNomination(false)
        }
        onUnnominate()
      } else {
        if (isAuthenticated) {
          setNomination(true)
        }
        onNominate(hide)
      }
    }
  }

  const handleClick = () => {
    if (nomination) {
      handleNominate()
    } else {
      const lastNominationDate = new Date(
        authenticatedProfile?.lastNominationDate as any
      )
      const now =
        new Date().getDate() + new Date().getMonth() + new Date().getFullYear()
      const lastNomination = authenticatedProfile?.lastNominationDate
        ? lastNominationDate.getDate() +
          lastNominationDate.getMonth() +
          lastNominationDate.getFullYear()
        : 0

      if (now !== lastNomination && authenticatedProfile?.hideNominationPopup) {
        handleNominate()
      } else {
        handleOpenDialog()
      }
    }
  }

  let disableNomination = false
  if (!project.canBeNominated || !dispatchComplete) disableNomination = true

  const renderIcon = (nominated?: boolean) => {
    if (nominated) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  return (
    <>
      <ActionButton
        onClick={handleClick}
        data-testid="nominate-button"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Icon src={renderIcon(nomination)} alt={"nominate"} />
        <Counter $isActive={nomination || false}>
          {project.nominations}{" "}
          {project.nominations === 1 ? "nomination" : "nominations"}
        </Counter>
      </ActionButton>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        onClose={handleCloseDialog}
        profile={authenticatedProfile}
        handleNominate={handleNominate}
      />
    </>
  )
}

export default View
