import React from "react"
import { Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #fff;
  content: "\a";
  white-space: pre;

  &:active {
    user-select: none;
  }

  :hover {
    color: #fff;
    cursor: pointer;
  }
`

export const CommentBox = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
`

export const StyledLinkBox = styled.span`
  background: #2d2d2d;
  padding: 0 8px;
  border-radius: 20px;
  box-sizing: border-box;

  :hover {
    background: #000;
    cursor: pointer;
  }
`
