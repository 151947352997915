import React from "react"
import { CircularProgress } from "@material-ui/core"
import theme, { colors } from "../../theme"
import {
  FollowButton,
  FollowButtonBig,
  FollowButtonBlue,
  FollowButtonBigBlue,
} from "./Styles"
import { useWindowDimensions } from "../../hooks"

export type Props = {
  onMouseOver: (value: boolean) => void
  hover: boolean
  isFollowing: boolean
  isLoading: boolean
  onFollow: () => void
  onUnfollow: () => void
  size?: string
  color?: string
  style?: React.CSSProperties
}

const View = ({
  onMouseOver,
  onFollow,
  onUnfollow,
  hover,
  isFollowing,
  isLoading,
  size,
  color,
  style,
}: Props) => {
  const BlueButtonComponent =
    size === "small" ? FollowButtonBlue : FollowButtonBigBlue
  const DefaultButtonComponent =
    size === "small" ? FollowButton : FollowButtonBig

  const ButtonComponent =
    color === "light-blue" ? BlueButtonComponent : DefaultButtonComponent

  const onMouseEnter = () => {
    onMouseOver(true)
  }

  const onMouseOut = () => {
    onMouseOver(false)
  }

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  return (
    <>
      {isFollowing ? (
        hover && !isMobile ? (
          <ButtonComponent
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseOut}
            style={{
              background: "white",
              border: "1px solid black",
              borderColor: "#F44C49",
              color: "#F44C49",
            }}
            disabled={isLoading}
            onClick={onUnfollow}
            variant="outlined"
            size="medium"
            data-testid="unfollow-button"
            type="button"
          >
            {isLoading ? (
              <CircularProgress size={16} style={{ color: "#F44C49" }} />
            ) : (
              <>Unfollow</>
            )}
          </ButtonComponent>
        ) : (
          <ButtonComponent
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseOut}
            style={{
              background: "white",
              border: "1px solid black",
              borderColor: colors.dark,
              color: colors.dark,
            }}
            disabled={isLoading}
            onClick={onUnfollow}
            variant="outlined"
            size="medium"
            data-testid="unfollow-button"
            type="button"
          >
            {isLoading ? <CircularProgress size={16} /> : <>Following</>}
          </ButtonComponent>
        )
      ) : (
        <ButtonComponent
          size="medium"
          data-testid="follow-button"
          type="button"
          disabled={isLoading}
          onClick={onFollow}
          style={{ ...style }}
        >
          {isLoading ? (
            <CircularProgress size={16} style={{ color: colors.white }} />
          ) : (
            <>Follow</>
          )}
        </ButtonComponent>
      )}
    </>
  )
}

export default View
