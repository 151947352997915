import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const EditCommentDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 5px !important;
  width: 100% !important;
`

export const EditCommentDialogTitle = styled(Box)`
  font-size: 20px;
  color: #2d2d2d;
  border-bottom: 0.5px solid #dbdbdb;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    padding: 0 20px;
  }
`

export const EditCommentDialogBox = styled(Box)`
  width: 516px;
  box-sizing: border-box;

  && .MuiDialog-paperWidthMd {
    max-width: 516px !important;
  }

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: auto;
  }
`

export const EditCommentDialogText = styled(Typography)`
  font-size: 16px;
  color: #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 24px;
`

export const ProfileBox = styled(Box)`
  padding: 24px 24px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  && img {
    border-radius: 50%;
  }
`

export const ProfileTitle = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
`

export const TextBox = styled(Box)`
  margin: 32px 0 40px;
  padding: 0 24px;
  box-sizing: border-box;

  && textarea {
    font-family: Public-Sans !important;
    font-size: 16px !important;
    line-height: 24px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    border: 0 !important;
    resize: none !important !important;

    &:focus {
      border: 0;
      outline: none;
    }
  }
`

export const StyledTextArea = styled.textarea`
  font-family: Public-Sans;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  resize: none !important;

  &:focus {
    border: 0;
    outline: none;
  }
`
