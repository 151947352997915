import React, { useState } from "react"

import {
  SuccessDialogArea,
  SuccessDialogBox,
  DialogBgBox,
  CloseIconImg,
  DialogSpIcon,
  MarketingDialogTitle,
  TextfieldBox,
  MarketingTitleBox,
  SuccessSubscriptionIconImg,
  SuccessDialogTitle,
  SuccessDialogText,
  FormSpacingBox,
  CheckboxLabelSmall,
  StyledLink,
} from "./styles"

import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"

import TextField from "./TextField"
import { Button } from "../../components"

import SPIcon from "../../images/innovation-awards/sp-big-icon.svg"
import SuccessIcon from "../../images/innovation-awards/subscription-success-icon.svg"
import CloseIcon from "../../images/innovation-awards/close-icon.png"
import { useAppDispatch } from "../../redux/configureStore"
import { subscribeToHubspotIA } from "../../redux/profile"
import { Checkbox } from "./Checkbox"
import { Competition } from "../../redux/competition"

export type Props = {
  onClose: () => void
  open: boolean
  competition?: Competition
}

export type FormValues = {
  email: string
  policy: boolean
}

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("This field is required.")
    .email("You must enter a valid email."),
  policy: Yup.bool().oneOf([true], "Must Accept Terms and Conditions"),
})

const MarketingDialog = ({ open, onClose, competition }: Props) => {
  const [emailSent, setEmailSent] = useState(false)

  const dispatch = useAppDispatch()

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      const { type } = await dispatch(subscribeToHubspotIA(values))
      if (subscribeToHubspotIA.fulfilled.type === type) {
        actions.setSubmitting(false)
        setEmailSent(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const initialValues: FormValues = {
    email: "",
    policy: false,
  }

  return (
    <SuccessDialogArea
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      scroll={"body"}
    >
      <SuccessDialogBox>
        <DialogBgBox $marketingDialog={true}>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={onClose} />
          {!emailSent ? (
            <>
              <DialogSpIcon src={SPIcon} alt={"success"} />
              <MarketingTitleBox>
                <MarketingDialogTitle>Get updates for the</MarketingDialogTitle>
                <MarketingDialogTitle>
                  {competition?.title}
                </MarketingDialogTitle>
              </MarketingTitleBox>
              <Formik
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions)
                }}
                initialValues={initialValues}
                validationSchema={ValidationSchema}
              >
                {(formik) => (
                  <Form style={{ width: "100%" }}>
                    <TextfieldBox>
                      <TextField
                        fullWidth
                        name="email"
                        placeholder="john.doe@example.com"
                        displayTitle="Email"
                        style={{ height: "48px" }}
                      />

                      <FormSpacingBox style={{ paddingTop: "18px" }}>
                        <Checkbox
                          checked={formik.values.policy}
                          onChange={() => {
                            formik.setFieldValue(
                              "policy",
                              !formik.values.policy
                            )
                          }}
                          label={
                            <CheckboxLabelSmall>
                              I’m also interested in receiveing news and updates
                              on Spectacular’s Innovation Awards projects, news,
                              jury, winners, and events.
                              <br />
                              <br />
                              By clicking 'Submit', you agree to the Spectacular{" "}
                              <StyledLink href="/terms_use" target="_blank">
                                User Agreement
                              </StyledLink>{" "}
                              and{" "}
                              <StyledLink
                                href="/privacy_policy"
                                target="_blank"
                              >
                                Privacy Policy
                              </StyledLink>
                              .
                            </CheckboxLabelSmall>
                          }
                        />
                      </FormSpacingBox>
                    </TextfieldBox>

                    <TextfieldBox style={{ marginTop: "35px" }}>
                      <Button
                        type={"submit"}
                        children={"Submit"}
                        color={"secondary"}
                        size={"medium"}
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.dirty
                        }
                        style={{
                          width: "203px",
                          height: "56px",
                          fontSize: "16px",
                        }}
                      />
                    </TextfieldBox>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <SuccessSubscriptionIconImg src={SuccessIcon} alt={"success"} />
              <SuccessDialogTitle variant="h2">Thank you!</SuccessDialogTitle>
              <SuccessDialogText>
                <span>Your subscription has been sent</span>
              </SuccessDialogText>
            </>
          )}
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default MarketingDialog
