import React from "react"
import { Box, CircularProgress } from "@material-ui/core"
import ProfileCardIcon from "../../components/ProfileCardIcon"
import { Profile } from "../../redux/profile"

import {
  Avatar,
  ProjectThumbnail,
  Author,
  Title,
  Location,
  CardBox,
  ContentBox,
  NetworkingBox,
  LinkBox,
  ProjectThumbnailPlaceholder,
} from "./styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import FollowButtonList from "../../components/FollowButtonList"
import { useWindowDimensions } from "../../hooks"
import { Button } from "../../components/Button"
import SimpleAddress from "../../components/SimpleAddress"
import ConnectButton from "../../components/ConnectButton"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  profile: Profile
  goToProject: (projectData: any) => string
  goToProfile: () => string
  goToEditProfile: () => void
  source?: string
  isCurrentProfile: boolean
  confirmButtons?: boolean
  isSuggestion?: boolean
  isLoading: boolean
  onConfirmConnection: () => void
  onDenyConnection: () => void
}

const View = ({
  profile,
  goToProject,
  goToProfile,
  goToEditProfile,
  source,
  isCurrentProfile,
  confirmButtons,
  isSuggestion,
  isLoading,
  onConfirmConnection,
  onDenyConnection,
}: Props) => {
  const { width } = useWindowDimensions()
  const name =
    profile.type === "group"
      ? profile.groupName
      : profile.firstName + " " + profile.lastName

  const alt =
    profile?.type === "individual"
      ? `Network for architects ${profile?.firstName} ${profile?.lastName}`
      : `Network for architects ${profile?.groupName}`

  let jobTitle = profile?.jobTitle
  if (profile?.jobTitle && profile.jobTitle.includes("Other: ")) {
    jobTitle = profile.jobTitle.replace("Other: ", "")
  }

  let location = SimpleAddress({
    profileType: profile!.type,
    location: profile!.location,
  })

  return (
    <CardBox>
      <Box style={{ height: "124px" }}>
        <LinkBox to={goToProfile}>
          <Avatar
            $isTempPicture={
              profile.smallImageUrl === undefined &&
              profile.tempPicture !== undefined
            }
            src={
              profile.smallImageUrl === undefined
                ? profile.tempPicture
                  ? profile.tempPicture
                  : getRandomPlaceholder(profile.placeholderAvatar!)
                : (profile.smallImageUrl as string)
            }
            alt={alt}
          />
        </LinkBox>
      </Box>
      <ContentBox paddingLeft={3} paddingRight={{ xs: 0, sm: 2 }}>
        <Author to={goToProfile}>
          {name}
          {profile?.isAmbassador && (
            <img
              src={AmbassadorIcon}
              alt="Student Ambassador"
              className="StudentAmbassadorIcon"
            />
          )}
        </Author>
        <Title>
          {profile.type === "group" ? (
            <>
              {profile.companySize && <>Company Size: {profile.companySize}</>}
            </>
          ) : (
            <>
              {jobTitle} {profile?.company ? "at " + profile?.company : ""}
            </>
          )}
        </Title>
        <Location to={"/profiles?location=" + profile.location}>
          {location}
        </Location>
        <ProfileCardIcon profile={profile} source={"card"} />
        <NetworkingBox $isGroup={profile.type === "group"}>
          {confirmButtons ? (
            <>
              <Button
                onClick={onDenyConnection}
                children={
                  isLoading ? (
                    <CircularProgress size={16} style={{ color: "#2d2d2d" }} />
                  ) : (
                    <>Ignore</>
                  )
                }
                color={"lightBlue"}
              />
              <Button
                onClick={onConfirmConnection}
                children={
                  isLoading ? (
                    <CircularProgress size={16} style={{ color: "#fff" }} />
                  ) : (
                    <>Accept</>
                  )
                }
                color={"secondary"}
              />
            </>
          ) : (
            <>
              {isCurrentProfile ? (
                <Button
                  onClick={goToEditProfile}
                  children={"Edit profile"}
                  color={"primary"}
                  style={{ width: "100px", height: "32px" }}
                />
              ) : (
                <>
                  <FollowButtonList profile={profile} />
                  {profile.type !== "group" && (
                    <ConnectButton
                      profile={profile}
                      action={"connect"}
                      isSuggestion={isSuggestion}
                    />
                  )}
                </>
              )}
            </>
          )}
        </NetworkingBox>
      </ContentBox>
      <Box style={{ height: "124px" }} marginTop={{ xs: 2, sm: 0 }}>
        {!profile.projects![0] ? (
          <ProjectThumbnailPlaceholder />
        ) : (
          <>
            {
              <LinkBox to={goToProject(profile.projects![0])}>
                <ProjectThumbnail
                  src={profile.projects![0].smallImageUrl}
                  alt={`Network for architects ${profile.projects![0].name}`}
                />
              </LinkBox>
            }
          </>
        )}
      </Box>
      {((width >= 1060 && source !== "homepage" && source !== "network") ||
        (width >= 1285 && source === "network")) && (
        <Box
          style={{ height: "124px" }}
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={2}
        >
          {!profile.projects![1] ? (
            <ProjectThumbnailPlaceholder />
          ) : (
            <>
              {
                <LinkBox to={goToProject(profile.projects![1])}>
                  <ProjectThumbnail
                    src={profile.projects![1].smallImageUrl}
                    alt={`Network for architects ${profile.projects![1].name}`}
                  />
                </LinkBox>
              }
            </>
          )}
        </Box>
      )}
      {((width >= 1270 && source !== "homepage" && source !== "network") ||
        (width >= 1500 && source === "network")) && (
        <Box
          style={{ height: "124px" }}
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={2}
        >
          {!profile.projects![2] ? (
            <ProjectThumbnailPlaceholder />
          ) : (
            <>
              {
                <LinkBox to={goToProject(profile.projects![2])}>
                  <ProjectThumbnail
                    src={profile.projects![2].smallImageUrl}
                    alt={`Network for architects ${profile.projects![2].name}`}
                  />
                </LinkBox>
              }
            </>
          )}
        </Box>
      )}
    </CardBox>
  )
}

export default View
