import React from "react"

import Header from "./Header"

import {
  MainContainer,
  DescriptionFont,
  DescriptionBox,
  DescriptionContainer,
  DescriptionIconBox,
  DescriptionFlexBox,
  DescriptionLeftBox,
  DescriptionTitle,
  DescriptionQuotes,
  QuotesLeftBox,
  QuotesRightBox,
  QuotesDescription,
  QuotesName,
  QuotesHint,
  DescriptionButtonBox,
  OverviewBox,
  OverviewTitle,
  OverviewItemList,
  OverviewItem,
  OverviewItemIcon,
  OverviewItemTitle,
  OverviewItemText,
  ResponsibilitiesBox,
  ResponsibilitiesTitle,
  ResponsibilitiesItemList,
  ResponsibilitiesItem,
  ResponsibilitiesItemTitle,
  ResponsibilitiesItemText,
  ResponsibilitiesItemImage,
  ResponsibilitiesItemContent,
  PerksBox,
  PerksContainer,
  RoundImage,
  PerksTitle,
  Divider,
  PerksFlexBox,
  PerksLeftBox,
  PerksRightBox,
  PerksItem,
  PerksButtonBox,
  AmbassadorsBox,
  AmbassadorsContainer,
  AmbassadorsTitle,
  AmbassadorsList,
  AmbassadorItem,
  AmbassadorItemName,
  AmbassadorItemSchool,
  AmbassadorContent,
  PhotosBox,
  Photo,
  PhotoBox,
  Overlay,
} from "./styles"

import BackButton from "../../components/BackButton"
import environment from "../../environment"
import FormBox from "./FormBox"
import { FormProps } from "."
import { Profile } from "../../redux/profile"
import { useSelector } from "react-redux"
import Avatar from "../../components/Avatar"
import { Button } from "../../components"

import RoundIcon from "../../images/student-ambassador/round-star-icon.svg"

import YearIcon from "../../images/student-ambassador/year-icon.svg"
import ShapeIcon from "../../images/student-ambassador/future-icon.svg"
import CareerIcon from "../../images/student-ambassador/career-icon.svg"
import PremiumIcon from "../../images/student-ambassador/premium-icon.svg"

import Resp1 from "../../images/student-ambassador/img-rally-the-crew.jpg"
import Resp2 from "../../images/student-ambassador/img-campus-advocate.jpg"
import Resp3 from "../../images/student-ambassador/img-student-voice-amplifier.jpg"
import { selectAmbassadors } from "../../redux/ambassador"
import Carousel from "react-material-ui-carousel"
import { ambassadorPhotos } from "../../constants/ambassador-photos"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { LinkBox } from "../../cards/ProjectCard/styles"

type ChunkArray = {
  even: Profile[]
  odd: Profile[]
}

interface Props {
  authenticatedProfile?: Profile
  isAuthenticated: boolean
  onClose: () => void
  onSubmit: (data: FormProps) => void
  showMessage: boolean
}

const View = ({
  authenticatedProfile,
  isAuthenticated,
  onClose,
  onSubmit,
  showMessage,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  console.log({ isMobile })

  const ambassadors = useSelector(selectAmbassadors)

  let mobileAmbassadorPhotos: string[] = []
  ambassadorPhotos.forEach(
    (photos) =>
      (mobileAmbassadorPhotos = [...mobileAmbassadorPhotos, ...photos])
  )

  const quotes = [
    {
      text: "“The student ambassador program has allowed me to connect with other students from all over the world. The program allows us to talk and push our designs further.”",
      ambassador: ambassadors.find(
        (ambassador) =>
          ambassador.connectionId === "dc880007-05cc-4529-b806-ebcc1f2a14bf"
      ),
    },
    {
      text: `“My favorite aspect of being a Student Ambassador with Spectacular Design is the opportunity to help my peers get their work out there through competitions and showcases. It's exciting to see students who might not have had a platform before now getting the chance to share their designs with a broader audience.”`,
      ambassador: ambassadors.find(
        (ambassador) =>
          ambassador.connectionId === "7e572e62-15cf-495d-9636-079ecd40c700"
      ),
    },
    {
      text: `“My favorite aspect of the Ambassador Program is connecting with like-minded students who are emerging in this industry. Being able to share and gather tips about workflow is very valuable.”`,
      ambassador: ambassadors.find(
        (ambassador) =>
          ambassador.connectionId === "bdf2acbb-fc59-4adf-9ac4-78d68812ac66"
      ),
    },
  ]

  const chunkAmbassadorsArray = () => {
    const result: ChunkArray[] = []
    const ambassadorArray = [...ambassadors]

    for (let i = 0; i < ambassadorArray.length; i += 9) {
      const chunk = ambassadorArray.slice(i, i + 9)

      const even: Profile[] = []
      const odd: Profile[] = []

      chunk.forEach((item, index) => {
        if (index % 2 === 0) {
          even.push(item)
        } else {
          odd.push(item)
        }
      })

      result.push({ even, odd })
    }

    return result
  }

  const goToForm = () => {
    document.getElementById("awardForm")?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <MainContainer
      $isAuthenticated={isAuthenticated}
      style={{ background: "#fff" }}
    >
      <BackButton
        style={{
          marginLeft: "20px",
          position: "fixed",
          marginTop: environment.device === "ios-app" ? "0" : "",
        }}
      />

      <Header />

      <DescriptionBox>
        <DescriptionContainer>
          <DescriptionFlexBox>
            <DescriptionLeftBox>
              <DescriptionTitle variant={"h1"}>
                Spectacular Student <br />
                Ambassador Program
              </DescriptionTitle>
              <DescriptionFont variant={"body1"}>
                Calling all architecture students! If you're ready to build
                meaningful connections and shine a spotlight on the incredible
                work of your architecture community, this opportunity is for
                you. 
                <br />
                <br />
                As a Student Ambassador, you’ll play a vital role in shaping the
                future of the Architectural profession. Join us and be a part of
                something spectacular!
              </DescriptionFont>
              <DescriptionButtonBox>
                <Button
                  color="secondary"
                  children={"Apply now"}
                  onClick={goToForm}
                />
              </DescriptionButtonBox>
            </DescriptionLeftBox>
            <DescriptionIconBox>
              <Carousel
                navButtonsAlwaysInvisible={true}
                animation={"fade"}
                interval={5000}
                autoPlay={true}
                indicatorContainerProps={{
                  style: {
                    marginTop: "8px",
                    zIndex: 5,
                  },
                }}
                indicatorIconButtonProps={{
                  style: {
                    padding: "0 5px",
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: "#3057E1",
                  },
                }}
              >
                {quotes.map((quote, key) => {
                  return (
                    <DescriptionQuotes key={`quote-${key}`}>
                      <QuotesLeftBox>
                        <QuotesDescription>{quote.text}</QuotesDescription>
                        <QuotesName>
                          {`${quote.ambassador?.firstName} ${quote.ambassador?.lastName}`}
                        </QuotesName>
                        <QuotesHint>
                          {quote.ambassador?.ambassador.program}
                        </QuotesHint>
                      </QuotesLeftBox>
                      <QuotesRightBox>
                        <Avatar profile={quote.ambassador!} />
                      </QuotesRightBox>
                    </DescriptionQuotes>
                  )
                })}
              </Carousel>
            </DescriptionIconBox>
          </DescriptionFlexBox>
        </DescriptionContainer>

        <OverviewBox>
          <OverviewTitle variant="h2">Overview Section:</OverviewTitle>
          <OverviewItemList>
            <OverviewItem>
              <OverviewItemIcon>
                <img src={YearIcon} alt="Year-Long Opportunity" />
              </OverviewItemIcon>
              <OverviewItemTitle>Year-Long Opportunity:</OverviewItemTitle>
              <OverviewItemText>
                Join the Spectacular Student Ambassador Program for a full
                school year
              </OverviewItemText>
            </OverviewItem>

            <OverviewItem>
              <OverviewItemIcon>
                <img src={ShapeIcon} alt="Shape the Future" />
              </OverviewItemIcon>
              <OverviewItemTitle>Shape the Future:</OverviewItemTitle>
              <OverviewItemText>
                Play a pivotal role in the development of Spectacular by sharing
                your ideas and feedback directly with our team.
              </OverviewItemText>
            </OverviewItem>

            <OverviewItem>
              <OverviewItemIcon>
                <img src={CareerIcon} alt="Career-Boosting Connections" />
              </OverviewItemIcon>
              <OverviewItemTitle>
                Career-Boosting Connections:
              </OverviewItemTitle>
              <OverviewItemText>
                Join the Spectacular Student Ambassador Program for a full
                school year
              </OverviewItemText>
            </OverviewItem>

            <OverviewItem>
              <OverviewItemIcon>
                <img src={PremiumIcon} alt="Premium Swag" />
              </OverviewItemIcon>
              <OverviewItemTitle>Premium Swag:</OverviewItemTitle>
              <OverviewItemText>
                Score exclusive Spectacular gear to showcase your ambassador
                pride and represent our brand wherever you go.
              </OverviewItemText>
            </OverviewItem>
          </OverviewItemList>
        </OverviewBox>

        <ResponsibilitiesBox>
          <ResponsibilitiesTitle variant="h2">
            Responsibilities:
          </ResponsibilitiesTitle>
          <ResponsibilitiesItemList>
            <ResponsibilitiesItem>
              <ResponsibilitiesItemImage>
                <img src={Resp1} alt="Rally the Crew" />
              </ResponsibilitiesItemImage>
              <ResponsibilitiesItemContent>
                <ResponsibilitiesItemTitle>
                  Rally the Crew
                </ResponsibilitiesItemTitle>
                <ResponsibilitiesItemText>
                  Sign up your friends and colleagues for Spectacular, becoming
                  the go-to liaison for student groups like NOMAS, AIAS, and
                  WIA. Foster community by initiating discussions, study groups,
                  and collaborative projects.
                </ResponsibilitiesItemText>
              </ResponsibilitiesItemContent>
            </ResponsibilitiesItem>

            <ResponsibilitiesItem>
              <ResponsibilitiesItemImage>
                <img src={Resp2} alt="Campus Advocate" />
              </ResponsibilitiesItemImage>
              <ResponsibilitiesItemContent>
                <ResponsibilitiesItemTitle>
                  Campus Advocate
                </ResponsibilitiesItemTitle>
                <ResponsibilitiesItemText>
                  Lead on-campus marketing by promoting Spectacular with flyers,
                  swag, and social media. Organize and promote events,
                  workshops, and networking opportunities to engage your peers.
                </ResponsibilitiesItemText>
              </ResponsibilitiesItemContent>
            </ResponsibilitiesItem>

            <ResponsibilitiesItem>
              <ResponsibilitiesItemImage>
                <img src={Resp3} alt="Student Voice Amplifier" />
              </ResponsibilitiesItemImage>
              <ResponsibilitiesItemContent>
                <ResponsibilitiesItemTitle>
                  Student Voice Amplifier
                </ResponsibilitiesItemTitle>
                <ResponsibilitiesItemText>
                  Help new students navigate Spectacular, offering insights and
                  support. Provide feedback to shape the platform, ensuring it
                  meets student needs.
                </ResponsibilitiesItemText>
              </ResponsibilitiesItemContent>
            </ResponsibilitiesItem>
          </ResponsibilitiesItemList>
        </ResponsibilitiesBox>
      </DescriptionBox>

      <PerksBox>
        <RoundImage src={RoundIcon} alt="Spectacular" />
        <PerksTitle variant="h2">Ambassador Perks</PerksTitle>
        <Divider />
        <PerksContainer>
          <PerksFlexBox>
            <PerksLeftBox>
              <PerksItem>
                <b>Mentorship Opportunities:</b> Pairing with a mentor from the
                Spectacular team or industry professionals to guide career path
                and provide personalized advice.
              </PerksItem>
              <PerksItem>
                <b>Early Access to New Features:</b> Get a first look at and
                provide feedback on new Spectacular products and features before
                they’re released to the public.
              </PerksItem>
              <PerksItem>
                <b>Networking Events:</b> Invitations to special networking
                events with industry leaders, potential employers, and other
                Spectacular ambassadors.
              </PerksItem>
              <PerksItem>
                <b>Certification:</b> A certification and digital badge you can
                add to your Spectacular and LinkedIn profile to highlight their
                participation and achievements in the program.
              </PerksItem>
            </PerksLeftBox>
            <PerksRightBox>
              <PerksItem>
                <b>Featured on Spectacular’s Platforms:</b> Recognition as an
                ambassador through features on Spectacular’s social media,
                website, or newsletters, boosting your personal brand.
              </PerksItem>
              <PerksItem>
                <b>Project Leadership Roles:</b> Opportunities to lead special
                projects or initiatives within the program, gaining leadership
                experience and demonstrating initiative.
              </PerksItem>
              <PerksItem>
                <b>Discounts on Black Spectacles ARE Test Prep:</b> Enjoy
                exclusive discounts on Black Spectacles ARE Study
                resources,supporting your path to architectural licensure.
              </PerksItem>
            </PerksRightBox>
          </PerksFlexBox>
          <PerksButtonBox>
            <Button
              color="secondary"
              children={"Apply now"}
              onClick={goToForm}
            />
          </PerksButtonBox>
        </PerksContainer>
      </PerksBox>
      <AmbassadorsBox>
        <AmbassadorsContainer>
          <AmbassadorsTitle variant="h2">Meet Our Ambassadors</AmbassadorsTitle>

          <Carousel
            navButtonsAlwaysInvisible={true}
            animation={"fade"}
            interval={5000}
            indicators={false}
            autoPlay={true}
          >
            {!isMobile ? (
              chunkAmbassadorsArray().map((row, key) => {
                return (
                  <React.Fragment key={`ambassador-list-${key}`}>
                    <AmbassadorsList>
                      {row.even.map((ambassador) => {
                        return (
                          <LinkBox to={`/sp/${ambassador.username}`}>
                            <AmbassadorItem>
                              <Avatar
                                profile={ambassador}
                                width={120}
                                height={120}
                              />
                              <AmbassadorContent>
                                <AmbassadorItemName>
                                  {`${ambassador.firstName} ${ambassador.lastName}`}
                                </AmbassadorItemName>
                                <AmbassadorItemSchool>
                                  {ambassador.ambassador.program}
                                </AmbassadorItemSchool>
                              </AmbassadorContent>
                            </AmbassadorItem>
                          </LinkBox>
                        )
                      })}
                    </AmbassadorsList>
                    <AmbassadorsList style={{ marginTop: "24px" }}>
                      {row.odd.map((ambassador) => {
                        return (
                          <LinkBox to={`/sp/${ambassador.username}`}>
                            <AmbassadorItem>
                              <Avatar
                                profile={ambassador}
                                width={120}
                                height={120}
                              />
                              <AmbassadorContent>
                                <AmbassadorItemName>
                                  {`${ambassador.firstName} ${ambassador.lastName}`}
                                </AmbassadorItemName>
                                <AmbassadorItemSchool>
                                  {ambassador.ambassador.program}
                                </AmbassadorItemSchool>
                              </AmbassadorContent>
                            </AmbassadorItem>
                          </LinkBox>
                        )
                      })}
                    </AmbassadorsList>
                  </React.Fragment>
                )
              })
            ) : (
              <AmbassadorsList>
                {ambassadors.map((ambassador) => {
                  return (
                    <LinkBox to={`/sp/${ambassador.username}`}>
                      <AmbassadorItem>
                        <Avatar profile={ambassador} width={120} height={120} />
                        <AmbassadorContent>
                          <AmbassadorItemName>
                            {`${ambassador.firstName} ${ambassador.lastName}`}
                          </AmbassadorItemName>
                          <AmbassadorItemSchool>
                            {ambassador.ambassador.program}
                          </AmbassadorItemSchool>
                        </AmbassadorContent>
                      </AmbassadorItem>
                    </LinkBox>
                  )
                })}
              </AmbassadorsList>
            )}
          </Carousel>
        </AmbassadorsContainer>
      </AmbassadorsBox>

      <Carousel
        navButtonsAlwaysInvisible={true}
        animation={"fade"}
        autoPlay={true}
        indicatorContainerProps={{
          style: {
            marginTop: "8px",
            zIndex: 5,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "0 5px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#3057E1",
          },
        }}
      >
        {isMobile
          ? mobileAmbassadorPhotos.map((photo) => {
              return <Photo src={photo} />
            })
          : ambassadorPhotos.map((photos) => {
              return (
                <PhotosBox>
                  {photos.map((photo) => {
                    return (
                      <PhotoBox>
                        <Photo src={photo} />
                        <Overlay />
                      </PhotoBox>
                    )
                  })}
                </PhotosBox>
              )
            })}
      </Carousel>

      <FormBox
        onClose={onClose}
        onSubmit={onSubmit}
        showMessage={showMessage}
        authenticatedProfile={authenticatedProfile}
      />
    </MainContainer>
  )
}

export default View
