import React, { useState, useEffect, useCallback } from "react"
import { RouteComponentProps } from "react-router-dom"

import { useSelector } from "react-redux"
import { selectProfile } from "../../redux/profile"

import View from "./view"
import { Helmet } from "react-helmet"
import { useAppDispatch } from "../../redux/configureStore"
import { updateNotificationsState } from "../../redux/bellNotifications"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"

interface MatchParams {
  tab: string
  type: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const NetworkPage: React.FC<Props> = (props) => {
  const profile = useSelector(selectProfile)
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const [currentTab, setCurrentTab] = useState(
    props.match.params.tab ||
      (profile && profile.type === "group" ? "followers" : "suggestions")
  )

  const [currentCollaboratorsTab, setCurrentCollaboratorsTab] = useState(
    props.match.params.type || "all"
  )

  const handleAllRead = useCallback(async () => {
    try {
      const state = "clicked"
      const { type } = await dispatch(
        updateNotificationsState({
          auth,
          profileId: profile?.connectionId,
          state,
          type: "connection",
        })
      )
      if (updateNotificationsState.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }, [dispatch, profile, auth])

  useEffect(() => {
    handleAllRead()
  }, [handleAllRead])

  const handleTabChanged = (_event: React.ChangeEvent<{}>, tab: string) =>
    setCurrentTab(tab)

  const handleTabChangedMobile = (tab: string, url: string) => {
    setCurrentTab(tab)
    window.history.replaceState(null, "Spectacular", url)
  }

  const handleTabClicked = (url: string) =>
    window.history.replaceState(null, "Spectacular", url)

  const handleChangeCurrentCollaboratorsTab = (type: string, url: string) => {
    setCurrentCollaboratorsTab(type)
    window.history.replaceState(null, "Spectacular", url)
  }

  return (
    <>
      <RouteChangeTracker screenTitle={`Network`} classTitle="Network" />
      <Helmet>
        <title>Network - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Network - Spectacular`} />
        <meta name="title" content={`Network - Spectacular`} />
      </Helmet>
      <View
        profile={profile}
        currentTab={currentTab}
        currentCollaboratorsTab={currentCollaboratorsTab}
        onTabChanged={handleTabChanged}
        onTabChangedMobile={handleTabChangedMobile}
        onTabClicked={handleTabClicked}
        onChangeCurrentCollaboratorsTab={handleChangeCurrentCollaboratorsTab}
      />
    </>
  )
}

export default NetworkPage
