import React from "react"

import View from "./View"

type Props = {
  isOpen: boolean
}

const DiscoverProjectsPopUp = ({ isOpen }: Props) => {
  const [open, setOpen] = React.useState(isOpen)
  const handleClose = () => {
    setOpen(false)
  }

  const handleDiscover = () => {
    document
      .getElementById("homeSearch")
      ?.scrollIntoView({ behavior: "smooth" })
    setOpen(false)
  }

  return <View open={open} onClose={handleClose} onDiscover={handleDiscover} />
}

export default DiscoverProjectsPopUp
