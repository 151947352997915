export type DropDownItem = {
  value: string
  display: string
}

export const keyMaterials: DropDownItem[] = [
  { value: "Steel", display: "Steel" },
  { value: "Concrete", display: "Concrete" },
  { value: "Aluminum", display: "Aluminum" },
  { value: "Masonry - Brick", display: "Masonry - Brick" },
  { value: "Masonry - Stone", display: "Masonry - Stone" },
  { value: "Glass", display: "Glass" },
  { value: "Heavy Timber", display: "Heavy Timber" },
  { value: "Light Frame Timber", display: "Light Frame Timber" },
  { value: "Terra Cotta", display: "Terra Cotta" },
  { value: "EIFs", display: "EIFs" },
  { value: "Stucco", display: "Stucco" },
  { value: "Glass", display: "Glass" },
]

export const typology: DropDownItem[] = [
  { value: "aviation", display: "Aviation" },
  { value: "civic-government", display: "Civic/Government" },
  { value: "competition-entry", display: "Competition Entry" },
  { value: "educational", display: "Educational" },
  { value: "entertainment", display: "Entertainment" },
  { value: "healthcare", display: "Healthcare" },
  { value: "hospitality", display: "Hospitality" },
  { value: "multi-family-residential", display: "Multi-Family Housing" },
  { value: "mixed-use", display: "Mixed-Use" },
  { value: "museums", display: "Museum" },
  { value: "office-building", display: "Office Building" },
  { value: "professional-services", display: "Professional Services" },
  { value: "religious", display: "Religious" },
  { value: "retail", display: "Retail" },
  { value: "senior-living", display: "Senior Living" },
  { value: "single-family-residential", display: "Single Family Housing" },
  { value: "science-technology", display: "Science/Technology" },
  { value: "speculative", display: "Speculative" },
  { value: "sports", display: "Sports" },
  { value: "student-project", display: "Student Project" },
  { value: "urban-design", display: "Urban Design" },
]

export const tools: DropDownItem[] = [
  { display: "V-Ray", value: "vray" },
  { display: "Unity", value: "unity" },
  { display: "Rhino", value: "rhino" },
  { display: "Revit", value: "revit" },
  { display: "Vector", value: "vector" },
  { display: "Python", value: "python" },
  { display: "Dynamo", value: "dynamo" },
  { display: "3ds Max", value: "3ds_max" },
  { display: "AutoCAD", value: "autocad" },
  { display: "InDesign", value: "indesign" },
  { display: "SketchUp", value: "sketchup" },
  { display: "ArchiCAD", value: "archicad" },
  { display: "Photoshop", value: "photoshop" },
  { display: "Grasshopper", value: "grasshopper" },
  { display: "After Effects", value: "after_effects" },
  { display: "Physical model", value: "physical_model" },
  { display: "Maxwell Render", value: "maxwell_render" },
  { display: "3D-printed models", value: "3d_printed_models" },
  { display: "Augmented Reality", value: "augmented_reality" },
  { display: "SOLIDWORKS", value: "solidworks" },
  { display: "Laser cutting", value: "laser_cutting" },
  { display: "Illustrator", value: "illustrator" },
]
