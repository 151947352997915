import React from "react"
import ProjectLikesIcon from "../ProjectLikesIcon"
import ProjectNominationIcon from "../ProjectNominationIcon"

import IconCommented from "../../images/new-homepage/icons/comment-active.svg"
import IconNoComment from "../../images/new-homepage/icons/comment-default.svg"
import IconHover from "../../images/new-homepage/icons/comment-hover.svg"

import { Project } from "../../redux/projects"
import { ProjectCardActionsBox } from "../../components/Styles/Global"
import {
  IconContainer,
  Icon,
  CountLabel,
  IconFlexBox,
  CounterFlexBox,
} from "./styles"
import { Typography } from "@material-ui/core"
import ProjectShareIcon from "../ProjectShareIcon"
// import ProjectShareIcon from "../ProjectShareIcon"

type Props = {
  project: Project
  goToProject: (project: Project, section?: string) => void
  isLarge?: boolean
}

const View = ({ project, goToProject, isLarge }: Props) => {
  const [hover, setHover] = React.useState(false)

  const getCommentIcon = (commented?: boolean) => {
    if (commented) return IconCommented

    if (hover) return IconHover

    return IconNoComment
  }

  return (
    <ProjectCardActionsBox $isLarge={isLarge}>
      <ProjectLikesIcon project={project} />
      <ProjectNominationIcon project={project} />
      <IconContainer
        $isActive={project.commented || false}
        onClick={() => goToProject(project, "comments")}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconFlexBox>
          <CounterFlexBox>
            <Icon src={getCommentIcon(project.commented)} alt="Comment" />
            <CountLabel>{project.commentsCount}</CountLabel>
          </CounterFlexBox>
          <Typography>Comment</Typography>
        </IconFlexBox>
      </IconContainer>
      <ProjectShareIcon project={project} />
    </ProjectCardActionsBox>
  )
}

export default View
