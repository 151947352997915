import styled from "styled-components"
import { Box } from "@material-ui/core"

export const ContactBox = styled(Box)`
  padding: 32px;
  background: #f9f9f9;

  @media only screen and (max-width: 1070px) {
    max-width: 303px;
  }
`
