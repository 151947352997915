import React from "react"
import Cookies from "universal-cookie"
import { useHistory, useLocation } from "react-router"

import View from "./View"
import MobileView from "./MobileView"
import { SubmitHandler } from "./RegisterForm"

import {
  ProfileType,
  createGroupProfile,
  createIndividualProfile,
} from "../../redux/profile"
import { useWindowDimensions } from "../../hooks"
import { useAppDispatch } from "../../redux/configureStore"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const referralCodeKey = "SP_REFERRAL_CODE"

const GatedPage = () => {
  const search = useLocation().search
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { width } = useWindowDimensions()
  const [referral, setReferral] = React.useState("")
  const [showSSOText, setShowSSOText] = React.useState(false)

  // @ts-ignore
  window.prerenderReady = true

  React.useEffect(() => {
    const referralCode = new URLSearchParams(search).get("referralCode")

    if (referralCode !== null) {
      setReferral(referralCode)
      localStorage.setItem(referralCodeKey, JSON.stringify(referralCode))
    } else setReferral(getStorageValue(referralCodeKey, ""))
  }, [search, setReferral])

  const getStorageValue = (key: string, defaultValue: any) => {
    if (typeof window !== "undefined")
      return localStorage.getItem(key) !== null
        ? JSON.parse(localStorage.getItem(key)!)
        : defaultValue
  }

  const handleSubmit: SubmitHandler = async (formValues, actions) => {
    try {
      const values = Object.assign({}, formValues)

      values.referralCode = referral

      values.origin = window.location.origin

      const action =
        values.type === ProfileType.GROUP
          ? createGroupProfile
          : createIndividualProfile

      const { type, payload } = await dispatch(action({ values }))
      if (action.fulfilled.type === type) {
        setShowSSOText(payload.skipEmailValidation)
        redirectNewUser(values.email, payload.skipEmailValidation)
        actions.setSubmitting(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const redirectNewUser = (email: string, skipEmailValidation: boolean) => {
    if (!skipEmailValidation) {
      new Cookies().set("new_reg_email", email, { path: "/" })

      localStorage.removeItem(referralCodeKey)

      history.push("/new_user")
    }
  }

  return (
    <>
      <RouteChangeTracker screenTitle={`Register`} classTitle="Register" />
      <Helmet>
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Register - Spectacular`} />
        <meta name="title" content={`Register - Spectacular`} />
        <title>Register - Spectacular</title>
      </Helmet>
      {width <= 960 ? (
        <MobileView onSubmit={handleSubmit} showSSOText={showSSOText} />
      ) : (
        <View onSubmit={handleSubmit} showSSOText={showSSOText} />
      )}
    </>
  )
}

export default GatedPage
