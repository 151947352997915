import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  Profile,
  selectProfile,
  updateProfile,
  availableEmailCheck,
  availableUserNameCheck,
} from "../../redux/profile"
import { resolveImageDimensions } from "../../utils"
import { getS3SignedURL } from "../../redux/projects"
import * as RouteConstants from "../../constants/routes"
import { useAppDispatch } from "../../redux/configureStore"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const EmailSchema = Yup.string()
  .email("Must be a valid email")
  .max(255)
  .required("Required")
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Must be a valid email"
  )

const ProfileEditPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const profile = useSelector(selectProfile)
  const auth = useAuth0()
  let message = ""
  const [usernameValue, setUsernameValue] = useState(profile?.username || "")
  const [emailValue, setEmailValue] = useState(profile?.contactEmail || "")
  const [usernameCheck, setUsernameCheck] = useState(message)
  const [emailCheck, setEmailCheck] = useState(message)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleCheckUsernameAvailability = async (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const updatedUsernameValue = event.target.value
    setUsernameValue(updatedUsernameValue)
    if (updatedUsernameValue) {
      const minChar = 4
      const maxChar = 50
      let profileType = "Group sub-domain"
      if (profile?.type !== "group") profileType = "Username"
      if (
        minChar <= updatedUsernameValue.length &&
        updatedUsernameValue.length <= maxChar
      ) {
        const usernameRegex = /^[a-z0-9-_]+$/
        if (usernameRegex.test(updatedUsernameValue)) {
          try {
            const { payload } = await dispatch(
              availableUserNameCheck({ username: updatedUsernameValue })
            )
            if (payload.error) {
              setUsernameCheck(
                "This " + profileType.toLowerCase() + " is unavailable"
              )
              setDisableSubmit(true)
            } else {
              setUsernameCheck("")
              setDisableSubmit(false)
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          setUsernameCheck(
            profileType +
              " must contain only lowercase alphanumeric characters with hyphen or underscores."
          )
          setDisableSubmit(true)
        }
      } else {
        setUsernameCheck(
          profileType + " should be between 4 and 50 characters."
        )
        setDisableSubmit(true)
      }
    } else {
      setUsernameCheck("Required")
      setDisableSubmit(true)
    }
  }

  const handleCheckEmailAvailability = async (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const updatedEmailValue = event.target.value
    setEmailValue(updatedEmailValue)

    try {
      EmailSchema.validateSync(updatedEmailValue)
    } catch (error) {
      setEmailCheck((message = (error as any).message))
      setDisableSubmit(true)
      return
    }

    if (updatedEmailValue !== "") {
      try {
        const { payload } = await dispatch(
          availableEmailCheck({ auth, email: updatedEmailValue })
        )
        if (payload.error) {
          setEmailCheck((message = payload.error))
          setDisableSubmit(true)
        } else {
          setEmailCheck("")
          setDisableSubmit(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onSubmit = async (editedProfile: Profile) => {
    try {
      //Send a request to the API to get a signed S3 url for the image
      if (editedProfile.file) {
        const file = editedProfile.file
        const { width, height } = await resolveImageDimensions(file)

        const signedURL = await dispatch(
          getS3SignedURL({
            auth,
            width,
            height,
            order: 0,
            contentId: "",
            uploadType: "profile",
            fileName: editedProfile.file.name,
            fileType: editedProfile.file.type,
          })
        )

        //Upload the image to the S3 bucket
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", editedProfile.file.type)

        await fetch(signedURL.payload, {
          method: "PUT",
          headers: myHeaders,
          body: editedProfile.file,
        })
      }

      if (editedProfile.bannerFile) {
        const file = editedProfile.bannerFile
        const { width, height } = await resolveImageDimensions(file)

        const signedURL = await dispatch(
          getS3SignedURL({
            auth,
            width,
            height,
            order: 0,
            contentId: "",
            uploadType: "banner",
            fileName: editedProfile.bannerFile.name,
            fileType: editedProfile.bannerFile.type,
          })
        )

        //Upload the image to the S3 bucket
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", editedProfile.bannerFile.type)

        await fetch(signedURL.payload, {
          method: "PUT",
          headers: myHeaders,
          body: editedProfile.bannerFile,
        })
      }

      let writeableProfile = JSON.parse(JSON.stringify(editedProfile))
      writeableProfile.username = usernameValue
      writeableProfile.contactEmail = emailValue

      //One final check in case someone took the username while updating
      try {
        const { payload } = await dispatch(
          availableUserNameCheck({ username: usernameValue })
        )
        if (payload.error) {
          setUsernameCheck((message = payload.error))
          setDisableSubmit(true)
        } else {
          setUsernameCheck("")
          setDisableSubmit(false)
        }
      } catch (error) {
        console.error(error)
      }

      const resultAction = await dispatch(
        updateProfile({ profile: writeableProfile, auth })
      )

      if (updateProfile.fulfilled.type === resultAction.type)
        history.push(
          `${RouteConstants.PROFILE_CONTAINER}/${writeableProfile.username!}`
        )
    } catch (error) {
      console.error(error)
    }
  }

  const onCancel = () =>
    history.push(`${RouteConstants.PROFILE_CONTAINER}/${profile?.username}`)

  useEffect(() => {
    setEmailValue(emailValue)
    setUsernameValue(usernameValue)
  }, [usernameValue, emailValue])

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Edit Profile`}
        classTitle="EditProfile"
      />
      <Helmet>
        <title>Edit profile - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Edit profile - Spectacular`} />
        <meta name="title" content={`Edit profile - Spectacular`} />
      </Helmet>
      <View
        profile={profile!}
        onSubmit={onSubmit}
        onCancel={onCancel}
        disableSubmit={disableSubmit}
        usernameCheck={usernameCheck}
        usernameValue={usernameValue}
        emailValue={emailValue}
        emailCheck={emailCheck}
        onCheckUsernameAvailability={handleCheckUsernameAvailability}
        onCheckEmailAvailability={handleCheckEmailAvailability}
      />
    </>
  )
}

export default ProfileEditPage
