import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Box, Chip, FormHelperText } from "@material-ui/core"

export type Props = {
  value: string
  awards: string[]
  suggestions: string[]
  displayError: boolean
  onInputChange: (value: string) => void
  onEnteredPressed: (jobTag: string) => void
  onDeletedPressed: (jobTag: string) => void
}

const View = ({
  awards,
  value,
  suggestions,
  displayError,
  onInputChange,
  onEnteredPressed,
  onDeletedPressed,
}: Props) => {
  const onKeyPress = (event: any) => {
    if (event.key === "Enter" && value.trim().length > 0)
      onEnteredPressed(value)
  }

  const handleDelete = (tagToDelete: string) => () =>
    onDeletedPressed(tagToDelete)

  return (
    <>
      <Box marginBottom={2}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value}
          id="profile-awards-autocomplete"
          options={suggestions}
          onInputChange={(_, newValue) => onInputChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              onKeyPress={onKeyPress}
              placeholder="e.g: Progressive Architecture Award"
              InputProps={{ ...params.InputProps }}
            />
          )}
        />

        <FormHelperText error={false}>
          Add awards by pressing enter...
        </FormHelperText>
        {displayError && (
          <Box marginBottom={2}>
            <FormHelperText error={true}>
              There was an error saving the profile award. Please try again!
            </FormHelperText>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        {awards.map((award: string, index: number) => (
          <Box key={index} marginRight={1} marginTop={1} display="flex">
            <Chip
              label={award}
              onDelete={handleDelete(award)}
              color="primary"
              data-testid="profile-award-item"
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default View
