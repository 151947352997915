import React, { useEffect, useCallback } from "react"
import NewPostButton from "../../components/NewPostButton"
import { NullstateTab, PostCardBox, PostTabBox } from "./styles"
import { Profile } from "../../redux/profile"
import { useAppDispatch } from "../../redux/configureStore"
import { LoadingStatuses, Post, getPostsByProfile } from "../../redux/posts"
import { useSelector } from "react-redux"
import LoadingPage from "../LoadingPage"
import PostCard from "../../cards/PostCard"
import { PlaceholderProjectCard } from "../HomePage/styles"
import ProfileNullstate from "../../components/ProfileNullstate"

interface Props {
  isPublicProfile: boolean
  profile: Profile
  authenticatedProfile: Profile
}

export const PostsDetails = ({
  isPublicProfile,
  profile,
  authenticatedProfile,
}: Props) => {
  const posts: Post[] = useSelector((state: any) => state.posts.posts)
  const status = useSelector((state: any) => state.posts.status)

  const dispatch = useAppDispatch()

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  const email = authenticatedProfile ? authenticatedProfile.email : undefined

  const loadPosts = useCallback(async () => {
    try {
      const { type } = await dispatch(
        getPostsByProfile({ profileId: profile.connectionId!, email })
      )

      if (getPostsByProfile.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }, [dispatch, email, profile.connectionId])

  useEffect(() => {
    loadPosts()
  }, [loadPosts])

  return (
    <PostTabBox paddingTop={profile.postsQuantity === 0 ? "" : "16px"}>
      {profile.postsQuantity === 0 && !isPublicProfile && (
        <NullstateTab>
          <ProfileNullstate type={"owner-posts-tab"} />
        </NullstateTab>
      )}

      {profile.postsQuantity === 0 && isPublicProfile && (
        <NullstateTab>
          <ProfileNullstate
            type={profile.type === "group" ? "posts-tab-group" : "posts-tab"}
          />
        </NullstateTab>
      )}

      {!isPublicProfile && <NewPostButton origin="profile" />}
      {status === LoadingStatuses.Loading ? (
        <LoadingPage />
      ) : (
        <PostCardBox>
          {posts.map((post) => {
            return <PostCard key={post.id} post={post} />
          })}
          {!isEven(posts.length) && <PlaceholderProjectCard />}
        </PostCardBox>
      )}
    </PostTabBox>
  )
}
