import React from "react"

import View from "./View"

type Props = {
  error?: string
  groupType?: string
  onChange: (newValue: string) => void
}

const suggestions = ["Firm", "School", "Other: "]

const GroupType = ({ groupType, onChange, error }: Props) => {
  const [value, setValue] = React.useState<string>(groupType || "")

  const onInputChange = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <View
      error={error}
      value={value}
      suggestions={suggestions}
      onInputChange={onInputChange}
    />
  )
}

export default GroupType
