import React from "react"
import styled from "styled-components"
import { Box, Typography, Link } from "@material-ui/core"

import theme from "../../theme"

export const JobContainer = styled(Box)`
  margin-top: -40px;
  margin-bottom: -64px;
  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
  ${theme.breakpoints.down("sm")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const ArrowBox = styled(Box)`
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 24px 20px 0;
`

export const JobHeader = styled(Box)`
  background: #fff;
  padding: 40px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    height: auto;
    padding: 20px;
    display: flex;
    gap: 20px;
  }
`

export const JobContent = styled(Box)`
  background: #f7f7f7;
  box-sizing: border-box;
  display: flex;
  gap: 40px;

  @media only screen and (min-width: 1500px) {
    padding: 80px 160px 160px;
  }

  @media only screen and (max-width: 1500px) {
    padding: 80px 80px 160px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 24px 20px 80px;
    flex-flow: column;
  }
`

export const JobSpacing = styled(Box)`
  padding: 0 40px 40px;
  box-sizing: border-box;
  margin-top: -38px;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 20px 24px;
    margin-top: 0;
  }
`

export const JobBanner = styled(Box)<{ bannerimage: string }>`
  width: 100%;
  border-radius: 3px 3px 0 0;
  background: url("${(props) => props.bannerimage}") center top / cover
    no-repeat;
  height: 124px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const JobDetails = styled(Box)`
  width: 100%;
  border-radius: 3px;
  background-color: #fff;

  ${theme.breakpoints.down("sm")} {
    background: #fff;
    box-sizing: border-box;
  }
`

export const JobDetailsBox = styled(Box)`
  display: flex;
  gap: 40px;
`

export const JobDetailsRightBox = styled(Box)`
  min-width: 157px;
  display: flex;
  flex-flow: column;
  gap: 40px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const MuiBox = styled(Box)`
  width: 100%;
  && {
    #mui-rte-container {
      padding: 0 !important;
      margin: 0 !important;
      margin-top: -5px !important;
      line-height: 22px !important;
    }
    #mui-rte-editor-container {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    && {
      #mui-rte-container {
        font-size: 14px !important;
      }
    }
  }
`

export const JobTagsBox = styled(Box)`
  padding-top: 40px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 32px;
  }
`

export const JobTag = styled(Box)`
  background: #f7f7f7;
  border-radius: 1px;
  color: #2d2d2d;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const JobTagsArea = styled(Box)`
  display: flex;
  gap: 8px;
  padding-top: 24px;
  flex-flow: wrap;

  ${theme.breakpoints.down("sm")} {
    padding-top: 20px;
  }
`

export const RightTitle = styled(Typography)`
  color:#2d2d2d;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-top: -2px;
  
  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 16px;
    margin-top: -3px;
  }
}
`

export const RightText = styled(Typography)`
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: -5px;

  && a {
    color: #3057e1 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
`

export const Divider = styled(Box)`
  border-top: 0.5px solid #d8d8d8;
`

export const ProfileAvatar = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;

  img {
    border: 2px solid #f7f7f7;
    background-color: #fff;
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    box-sizing: border-box;
  }

  ${theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`

export const ProfileDetails = styled(Box)`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;

  ${theme.breakpoints.down("sm")} {
    text-align: center;
    padding-bottom: 32px;
  }
`

export const ProfileNameBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;

  ${theme.breakpoints.down("sm")} {
    justify-content: center;
    height: auto;
  }
`

export const ProfileName = styled(Typography)`
  font-size: 25px;
  line-height: 25px;
  margin-top: -2px;
`

export const EditProfileBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const EditProfile = styled(Typography)`
  color: #3057e1;
  font-size: 16px;
  line-height: 15px;
  font-family: "Public-Sans-Bold";
  font-weight: 400;
`

export const ProfileDetailsText = styled(Typography)`
  line-height: 16px;
  font-size: 16px;
  margin-top: -2px;
`

export const RouterLinkBox = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const LinkBox = styled.a`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const LocationBox = styled(Box)`
  padding-top: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  ${theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`

export const LocationText = styled(Typography)`
  line-height: 16px;
  font-size: 14px;
  color: #717171;
  text-decoration: underline;
`

export const AdsArea = styled(Box)`
  min-width: 369px;

  @media only screen and (max-width: 1100px) {
    min-width: 280px;
  }
`

export const PosterBox = styled(Box)`
  background: #fff;
  padding-top: 20px;
  border-radius: 3px;

  img {
    max-height: 65px;
    max-width: 65px;
  }
`

export const PosterTitle = styled(Typography)`
  font-size: 20px;
  line-height: 20px;
  padding: 0 20px;
  margin-top: -2px;
`

export const PosterDetails = styled(Box)`
  display: flex;
  padding: 0 20px 20px;
  gap: 16px;
`

export const PosterName = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #2d2d2d;
  line-height: 22px;
  margin-top: -4px;
`

export const PosterCompany = styled(Typography)`
  font-size: 16px;
  color: #2d2d2d;
  line-height: 22px;
`

export const PosterLocation = styled(Typography)`
  font-size: 14px;
  color: #717171;
  text-decoration: underline;
  line-height: 22px;
`

export const JobTitle = styled(Typography)`
  font-size: 32px;
  font-family: Public-Sans-Medium;
  margin-top: -5px;
  margin-bottom: 24px;

  ${theme.breakpoints.down("sm")} {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 0px;
  }
`
