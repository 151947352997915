import React from "react"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"
import { Link } from "react-router-dom"

import bgHeader from "../../images/innovation-awards/header-bg-desktop.png"
import Quotes from "../../images/new-homepage/quotes.png"
import MobileQuotes from "../../images/new-homepage/quotes-mobile.png"
import ulIcon from "../../images/innovation-awards/ul-icon.png"

export const MainContainer = styled(Box)`
  margin-top: ${theme.spacing(10)}px;

  ${theme.breakpoints.down("xs")} {
    padding: 0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px;
    margin-top: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.between("sm", "md")} {
    padding: 0 100px 0 100px;
  }
  ${theme.breakpoints.up("lg")} {
    padding: 0 157px 0 157px;
  }
`

export const NewMainContainer = styled(Box)`
  margin-top: ${theme.spacing(10)}px;

  @media only screen and (min-width: 1500px) {
    padding: 0 160px;
  }

  @media only screen and (max-width: 1500px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;
    margin-top: 48px;
  }
`

export const New1440Container = styled(Box)`
  margin-top: ${theme.spacing(10)}px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    margin-top: 40px;
  }
  ${theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`

export const New1440Box = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;
  }
`

export const FeaturedProjectContainer = styled(Box)<{
  $isAuthenticated: boolean
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
`

export const FeaturedProjectBox = styled(Box)<{ $isAuthenticated: boolean }>`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "40px" : "56px")};

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
    margin-top: ${({ $isAuthenticated }) =>
      $isAuthenticated ? "0px" : "32px"};
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0;
    margin-top: 16px;
  }
`

export const FeaturedProjectTitle = styled(Typography)`
  font-size: 25px;

  && img {
    vertical-align: middle;
    margin-right: 12px;
    width: 38px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;
    line-height: 20px;
    padding: 0 8px;

    && img {
      vertical-align: middle;
      margin-right: 12px;
      width: 25px;
    }
  }
`

export const FeaturedProjectDivider = styled(Box)`
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  margin: 24px 0 27px;

  ${theme.breakpoints.down("xs")} {
    border: 0;
    margin: 10px 0;
  }
`

export const FeaturedProjectCard = styled(Box)``

export const InnovationAwardsContainer = styled(Box)<{
  $showProjects?: boolean
  $isHeader?: boolean
  $isAuthenticated?: boolean
}>`
  margin-top: ${({ $isAuthenticated, $showProjects, $isHeader }) =>
    $isHeader && $isAuthenticated ? "-40px" : $showProjects ? "0" : "40px"};
  width: 100%;
  display: flex;
  justify-content: center;
  background: url(${bgHeader});
  background-position: top right;
  background-repeat: repeat-y;
  background-color: #f2f2f2;

  ${theme.breakpoints.down("sm")} {
    background: #f2f2f2;
  }
`

export const InnovationAwardsBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 56px 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 20px;
  }
`

export const InnovationAwardsBoxHader = styled(Box)<{ $showBorder?: boolean }>`
  border-bottom: ${({ $showBorder }) =>
    $showBorder ? "1px solid #000000;" : "none"};
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    gap: 32px;
  }
`

export const InnovationAwardsLogoBox = styled(Box)`
  padding-top: 0px;

  max-width: 629px;

  @media only screen and (max-width: 1350px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 1270px) {
    max-width: 500px;
  }

  @media only screen and (max-width: 1120px) {
    max-width: 450px;
  }

  && button {
    margin-top: 32px !important;
  }

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
    padding-top: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    && button {
      width: 100% !important;
    }
  }
`

export const InnovationAwardsCounter = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  && button {
    width: 100% !important;
  }
`

export const InnovationAwardsCounterImage = styled(Box)`
  width: 100%;
  text-align: center;

  && img {
    width: 126.5px;
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      width: 100%;
    }
  }
`

export const InnovationAwardsCounterCountdown = styled(Box)`
  text-align: center;
  margin-top: 42px;

  && h3 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }
`

export const InnovationAwardsJury = styled(Box)<{ $isJuryAtBottom?: boolean }>`
  padding-top: 80px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 80px;
  }
  ${theme.breakpoints.down("xs")} {
    padding-top: 40px;
    order: ${({ $isJuryAtBottom }) => ($isJuryAtBottom ? "4" : "1")};
  }
`

export const InnovationAwardsDescription = styled(Typography)`
  margin-top: 16px;
  font-size: 16px;

  && span {
    font-family: Public-Sans-Bold;
  }
`

export const JuryTitle = styled(Typography)`
  font-size: 20px;
  font-family: Public-Sans-Bold;

  ${theme.breakpoints.down("sm")} {
    text-align: center;
  }

  ${theme.breakpoints.down("xs")} {
    text-align: left;
  }
`

export const JuryBox = styled(Box)`
  display: flex;
  gap: 40px;
  margin-top: 10px;
  cursor: pointer;

  @media only screen and (max-width: 1220px) {
    gap: 20px;
  }

  @media only screen and (max-width: 1120px) {
    gap: 10px;
  }

  ${theme.breakpoints.down("sm")} {
    justify-content: center;
    gap: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    justify-content: space-between;
    gap: 16px;
  }
`

export const Jury = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;

  && img {
    border-radius: 4px;
    width: 100%;
  }

  @media only screen and (max-width: 1220px) {
    && img {
      width: 130px;
    }
  }

  @media only screen and (max-width: 1120px) {
    && img {
      width: 110px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      width: 100%;
    }
  }
`

export const JuryName = styled(Typography)`
  font-size: 16px;

  ${theme.breakpoints.down("xs")} {
    font-size: 12px;
  }
`

export const InnovationAwardsLogo = styled.img`
  width: 550px;
  cursor: pointer;

  @media only screen and (max-width: 1350px) {
    width: 550px;
  }

  @media only screen and (max-width: 1270px) {
    width: 500px;
  }

  @media only screen and (max-width: 1120px) {
    max-width: 450px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 90%;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const InnovationAwardsPlaceholder = styled(Box)`
  margin-top: 0px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 32px;
  }
`

export const InnovationAwardsText = styled(Typography)`
  font-size: 25px;
  line-height: 30px;

  && span {
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;
    line-height: 25px;
  }
`

export const InnovationProjectsButtonBox = styled(Box)`
  width: 100%;
  text-align: center;
  padding-top: 26px;
  padding-bottom: 16px;

  && p {
    font-size: 17px;
    font-family: Public-Sans-Bold;
    margin-top: 24px;
  }

  ${theme.breakpoints.down("xs")} {
    && button {
      width: 100% !important;
    }
  }
`

export const LottieFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
`

export const LottieBox = styled(Box)`
  width: 25px;
  height: 25px;
`
export const InnovationAwardsProjectsBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  && .react-horizontal-scrolling-menu--wrapper {
    width: 100%;
  }

  && .react-horizontal-scrolling-menu--scroll-container {
    width: 100%;
    gap: 16px;
    overflow-x: overlay;

    &::-webkit-scrollbar {
      visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden;
    }
  }

  && .react-horizontal-scrolling-menu--item {
    min-width: calc(50% - 16px);
  }

  ${theme.breakpoints.down("xs")} {
    && .react-horizontal-scrolling-menu--item {
      min-width: 100%;
    }

    && .react-horizontal-scrolling-menu--scroll-container {
      gap: 0px;
    }
  }
`

export const InnovationAwardsButtonBox = styled(Box)`
  margin-top: 24px;

  ${theme.breakpoints.down("xs")} {
    padding-bottom: 45px;
  }
`

export const FloatingIcon = styled(Box)`
  text-align: center;
  margin-top: -39px;

  && img {
    width: 76px;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: -22px;

    && img {
      width: 43px;
    }
  }
`

export const StickyListContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 28px;
  position: sticky;
  padding: 10px 0;
  top: 67px;
  z-index: 1000;
  background-color: #fafcfe;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0px;
    top: 56px;
  }
`

export const ProjectsListContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0px;
  }
`

export const ProjectsListBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0;
  }
`

export const ProjectsListTitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProjectsListTitleText = styled(Typography)`
  font-size: 25px;
`

export const ProjectsListTitleLink = styled(({ ...props }) => (
  <Link {...props} />
))`
  font-size: 16px;
  color: #3057e1;
  text-decoration: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const ProjectsListDivider = styled(Box)`
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  margin: 24px 0 32px;
`

export const ProjectsListCardBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 32px;

  ${theme.breakpoints.down("sm")} {
    gap: 16px;
  }
`

export const ProfilesListCardBox = styled(Box)`
  width: 100%;
`

export const PlaceholderProjectCard = styled(Box)`
  width: 100%;
  flex: 40%;
`

export const ArrowRight = styled(Box)`
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 60px;
  height: 60px;
  z-index: 6;
  border-radius: 50%;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -40px;
  margin-right: -30px;
  position: absolute;

  ${theme.breakpoints.down("xs")} {
    width: 27px;
    height: 27px;
    margin-top: -60px;
    margin-right: -12px;

    && img {
      width: 10px;
    }
  }
`

export const ArrowLeft = styled(Box)`
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 60px;
  height: 60px;
  z-index: 6;
  border-radius: 50%;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -40px;
  margin-left: -30px;
  position: absolute;

  ${theme.breakpoints.down("xs")} {
    width: 27px;
    height: 27px;
    margin-top: -60px;
    margin-left: -12px;

    && img {
      width: 10px;
    }
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 560px;
  overflow: hidden;
  background: #f7f7f7;

  @media only screen and (max-width: 1270px) {
    height: 479px;
  }

  @media only screen and (max-width: 1130px) {
    height: 421px;
  }

  @media only screen and (max-width: 780px) {
    height: auto;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
  }
`

export const HeaderTitle = styled(Typography)`
  font-size: 65px;
  line-height: 70px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 1270px) {
    font-size: 50px;
    line-height: 55px;
  }

  @media only screen and (max-width: 890px) {
    font-size: 45px;
    line-height: 50px;
  }

  @media only screen and (max-width: 780px) {
    max-width: 100%;
    text-align: center;
    font-size: 25px;
    line-height: 30px;
    font-family: Public-Sans-Medium;
    display: block;
  }
`

export const HeaderCopy = styled(Typography)`
  font-size: 20px;
  line-height: 28px;
  margin-top: 24px;
  max-width: 590px;

  @media only screen and (max-width: 1270px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media only screen and (max-width: 1130px) {
    max-width: 460px;
  }

  @media only screen and (max-width: 890px) {
    max-width: 400px;
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (max-width: 780px) {
    max-width: 100%;
    text-align: center;
  }
`

export const HeaderButtonBox = styled(Box)`
  padding-top: 24px;
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 80px;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }

  @media only screen and (max-width: 780px) {
    flex-flow: column;

    && button {
      width: 100% !important;
    }

    padding-top: 48px;
    padding-bottom: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 48px 20px 40px;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    text-align: initial;
  }
`

export const HeaderImages = styled(Box)`
  width: fit-content;
  display: flex;
  flex-flow: column;
  position: absolute;
  right: 0;
  top: 152px;

  ${theme.breakpoints.down("sm")} {
    top: 127px;
  }

  @media only screen and (max-width: 780px) {
    display: none;
    position: initial;
    padding-top: 100px;
  }
`

export const FirstImagesBox = styled(Box)`
  display: flex;
  gap: 20px;
  align-self: flex-end;

  @media only screen and (max-width: 1270px) {
    gap: 20px;
  }
`

export const SecondImagesBox = styled(Box)`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-self: flex-end;

  @media only screen and (max-width: 1270px) {
    gap: 20px;
    margin-top: 20px;
  }
`

export const SecondImagesInnerBox = styled(Box)`
  display: flex;
  gap: 20px;
  flex-flow: column;

  @media only screen and (max-width: 1270px) {
    gap: 20px;
  }
`

export const StyledImage1 = styled.img`
  width: 398px;
  height: 237px;
  display: flex;
  border-radius: 0px 0px 4px 4px;

  @media only screen and (max-width: 1370px) {
    width: 378px;
  }

  @media only screen and (max-width: 1270px) {
    width: 327px;
    height: 192px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1130px) {
    width: 275px;
    height: 164px;
    object-fit: cover;
  }

  @media only screen and (max-width: 840px) {
    width: 246px;
  }
`

export const StyledImage2 = styled.img`
  width: 224px;
  height: 237px;
  display: flex;
  border-radius: 0px 0px 0px 4px;

  @media only screen and (max-width: 1370px) {
    width: 204px;
  }

  @media only screen and (max-width: 1270px) {
    width: 90px;
    height: 192px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1130px) {
    width: 90px;
    height: 164px;
    object-fit: cover;
  }
`

export const StyledImage3 = styled.img`
  display: flex;
  height: 127px;
  width: 197px;
  border-radius: 4px;
  align-self: flex-end;

  @media only screen and (max-width: 1370px) {
    width: 177px;
  }

  @media only screen and (max-width: 1270px) {
    width: 186px;
    height: 127px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1130px) {
    width: 141px;
    height: 107px;
    object-fit: cover;
  }

  @media only screen and (max-width: 840px) {
    width: 131px;
  }
`

export const StyledImage4 = styled.img`
  display: flex;
  height: 156px;
  width: 315px;
  border-radius: 4px 4px 0px 0px;

  @media only screen and (max-width: 1370px) {
    width: 295px;
  }

  @media only screen and (max-width: 1270px) {
    width: 271px;
    height: 120px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1130px) {
    width: 215px;
    height: 110px;
    object-fit: cover;
  }

  @media only screen and (max-width: 840px) {
    width: 195px;
  }
`

export const StyledImage5 = styled.img`
  display: flex;
  height: 303px;
  width: 375px;
  border-radius: 4px 0px 0px 0px;

  @media only screen and (max-width: 1370px) {
    width: 336px;
  }

  @media only screen and (max-width: 1270px) {
    width: 204px;
    height: 268px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1130px) {
    width: 186px;
    height: 237px;
    object-fit: cover;
  }

  @media only screen and (max-width: 840px) {
    width: 171px;
  }
`

export const CountdownFlexBox = styled(Box)`
  display: flex;
  gap: 32px;
  margin-top: 10px;
  margin-bottom: 21px;
  justify-content: center;

  ${theme.breakpoints.down("sm")} {
    gap: 24px;
  }
`

export const CountdownItem = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

export const CountdownText = styled(Typography)`
  color: #2d2d2d;
  font-size: 52px;
  text-align: center;
`

export const CountdownLabel = styled(Typography)`
  color: #2d2d2d;
  font-size: 14px;
  text-align: center;
`

export const JuryBorder = styled(Box)`
  width: 100%;
  border-bottom: 2px solid #ffffff;
  padding-top: 110px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 64px;
  }
`

export const JuryBorderBottom = styled(Box)`
  width: 100%;
  border-top: 2px solid #ffffff;
  padding-bottom: 192px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 64px;
  }
`

export const JuryReviewContainer = styled(Box)`
  background-color: #2d2d2d;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`

export const JuryReviewBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0px 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0px 20px;
  }
`

export const JuryReviewTitle = styled(Typography)`
  padding-top: 60px;
  font-size: 25px;
  font-family: Public-Sans-Bold;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    padding-top: 42px;
  }
`

export const JuryReviewList = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 64px;
  margin-top: -32px;
  margin-bottom: -64px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
    gap: 32px;
    margin-bottom: -32px;
  }
`

export const JuryReviewCard = styled(Box)<{ oriented?: string }>`
  width: 850px;
  height: 460px;
  filter: drop-shadow(-10px 11px 66px rgba(0, 0, 0, 0.25));
  display: flex;
  box-sizing: border-box;
  padding: 56px 64px;
  background: url(${Quotes}) #3f3e3e;
  background-position: ${({ oriented }) =>
    oriented === "left" ? "227px 184px" : "362px 184px"};
  background-repeat: no-repeat;
  border-radius: 1px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    height: auto;
    background: #3f3e3e;
    padding: 0;
  }
`

export const JuryReviewCardDescription = styled(Box)<{ oriented?: string }>`
  width: 100%;
  padding-left: ${({ oriented }) => (oriented === "left" ? "75px" : "0")};

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    padding: 24px;
    box-sizing: border-box;

    background: url(${MobileQuotes}) #3f3e3e;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 10px;
  }
`

export const JuryReviewCardImage = styled(Box)`
  && img {
    height: 283px;
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      height: 235px;
      display: flex;
    }
  }
`

export const JuryReviewCardTitle = styled(Typography)`
  color: #fff;
  font-size: 64px;
  line-height: 55.42px;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    line-height: 35.78px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
    line-height: 20.78px;
  }
`

export const JuryReviewCardText = styled(Typography)<{ oriented?: string }>`
  color: #fff;
  font-size: 24px;
  line-height: 32.78px;
  margin-top: 50px;
  padding-right: ${({ oriented }) => (oriented === "left" ? "0" : "80px")};

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 24.12px;
    padding-right: 0;
    margin-top: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 19.12px;
    margin-top: 12px;
  }
`

export const JuryReviewCardHint = styled(Typography)`
  color: #fff;
  font-size: 16px;
  margin-top: 20px;

  && span {
    font-family: "Public-Sans-Bold";
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 19.12px;
    margin-top: 16px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 19.12px;
    margin-top: 16px;
  }
`

export const ShowMoreBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
`

export const ShowMoreBorder = styled(Box)`
  position: absolute;
  width: 100%;
  height: 0.5px;
  background: #d8d8d8;
  margin-top: 30px;
`

export const ShowMoreButton = styled(Box)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #5a5a5b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  && img {
    margin-top: 10px;
  }
`

export const ShowMoreLabel = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 14px;
  color: #2d2d2d;
  margin-top: 8px;
  cursor: pointer;
`

export const PostSearchBox = styled(Box)`
  display: flex;
  gap: 32px;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    gap: 16px;
  }
`
export const MobileStickyBar = styled(Box)`
  display: flex;
  gap: 32px;
  width: 100%;
  transition: 0.5s;
  padding: 0 10px;
  box-sizing: border-box;
  gap: 16px;
  align-items: center;
`

export const AvatarBox = styled(Box)`
  && img {
    border-radius: 50%;
    width: 38px !important;
    height: 38px !important;
  }
`

export const MobileStickyIcon = styled.img`
  width: 24px;
`

export const NoResultsBox = styled(Box)`
  text-align: center;
`

export const NoResultsTitle = styled(Typography)`
  font-size: 48px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const NoResultsText = styled(Typography)`
  font-size: 24px;
  color: #979797;
  line-height: 37.5px;
  margin-top: 24px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 28px;
  }
`

export const NoResultsShortcuts = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  margin-top: 32px;
`

export const NoResultsShortcut = styled(Box)`
  font-family: Public-Sans;
  text-decoration: underline;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const DropDownBox = styled(Box)`
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
`

export const CompetitionBox = styled(Box)`
  display: flex;
  gap: 96px;
  align-items: center;

  @media only screen and (max-width: 1360px) {
    gap: 80px;
  }

  @media only screen and (max-width: 1250px) {
    gap: 64px;
  }

  @media only screen and (max-width: 1190px) {
    gap: 48px;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }

  ${theme.breakpoints.down("xs")} {
    align-items: flex-start;
  }
`

export const StyledComptitionLogo = styled.img`
  height: 200px;

  @media only screen and (max-width: 1190px) {
    height: 180px;
  }

  ${theme.breakpoints.down("sm")} {
    height: 200px;
  }

  ${theme.breakpoints.down("xs")} {
    height: auto;
    width: 100%;
    max-width: 300px;
  }
`

export const CompetitionBorder = styled(Box)<{ $isHomepage?: boolean }>`
  width: 1px;
  background-color: #000;
  height: 540px;
  height: ${({ $isHomepage }) => ($isHomepage ? "340px" : "340px")};

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const CompetitionContentBox = styled(Box)`
  display: flex;
  gap: 40px;
  flex-flow: column;

  ${theme.breakpoints.down("sm")} {
    && button {
      width: 100% !important;
    }

    gap: 32px;
  }
`

export const CompetitionContentItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 6px;
  padding-left: 36px;
  font-size: 24px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }

  && span {
    font-family: Public-Sans-Bold;
  }

  @media only screen and (max-width: 1360px) {
    font-size: 20px;
    background-position-y: 4px;
  }

  @media only screen and (max-width: 1190px) {
    font-size: 18px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    background-position-y: 4px;
  }
`

export const CompetitionTitle = styled(Typography)`
  font-size: 48px;
  line-height: 57.6px;
  width: 500px;

  && img {
    width: 40px;
  }

  @media only screen and (max-width: 1080px) {
    width: 370px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    font-size: 36px;
    line-height: 45.6px;

    && img {
      width: 32px;
    }
  }
`
