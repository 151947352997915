import React from "react"
import View from "./View"

export type Props = {
  large?: boolean
  awards?: boolean
}

const PlaceholderCard = ({ large, awards }: Props) => {
  return <View large={large} awards={awards} />
}

export default PlaceholderCard
