import styled from "styled-components"
import { Box, Typography, Grid } from "@material-ui/core"
import { Link } from "react-router-dom"

import theme from "../../theme"

export const LocationSpan = styled(Link)`
  cursor: pointer;
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
    color: #5d5d5d;
  }
`

export const Divider = styled.hr`
  margin: 0;
  border-top: 0.5px solid rgb(216, 216, 216;
`

export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0.9px;
    right: 0px;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const MenuItem = styled.div`
  color: #2d2d2d;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`

export const MenuBox = styled.div`
  width: 108px;
  padding: 10px 0;
  font-size: 12px;
  box-shadow: 0px -2px 15px rgba(45, 45, 45, 0.1);
  background-color: #fff;
`

export const DotBox = styled.div`
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;
  background: #ffffff;
  box-sizing: border-box;
  height: 100%;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${theme.breakpoints.down("sm")} {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
  }
`

export const JobSummary = styled(Typography)`
  color: #2d2d2d;
  margin-top: -5px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${theme.breakpoints.down("md")} {
    font-size: 14px;
  }
`

export const LocationArea = styled.div`
  color: #717171;
  line-height: 20px;
  font-size: 16px;
  margin-top: -5px;

  ${theme.breakpoints.down("md")} {
    font-size: 14px;
  }
`

export const JobTag = styled(Box)`
  background: #f7f7f7;
  border-radius: 1px;
  color: #2d2d2d;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`

export const JobTagsArea = styled(Box)`
  display: flex;
  gap: 8px;
  margin-top: -3px;
  padding-top: 16px;
  flex-flow: wrap;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const JobMainGrid = styled(Grid)`
  padding-bottom: 96px;
  flex-wrap: initial;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 16px;
    flex-wrap: wrap;
  }

  ${theme.breakpoints.down("xs")} {
    padding-bottom: 60px;
  }
`

export const JobTitle = styled(Link)`
  color: #2d2d2d;
  cursor: pointer;
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const DropDownBox = styled.div`
  min-width: 40px;
  padding-left: 16px;
  display: flex;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    min-width: 24px;
    padding-left: 0;
  }
`

export const ApplicantsText = styled.div<{ $isClickable?: boolean }>`
  color: rgb(48, 87, 225);
  margin-top: 4px;
  ${({ $isClickable }) => ($isClickable ? "cursor: pointer;" : "")};
`
