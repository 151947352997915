import React from "react"
import { Project } from "../../redux/projects"
import View from "./View"

export type Props = {
  project: Project
}

const ProjectCardActions = ({ project }: Props) => {
  return <View project={project} />
}

export default ProjectCardActions
