import React, { useState } from "react"

import {
  Close,
  Description,
  IconContainer,
  Item,
  PostButton,
  PostButtonContainer,
  PostOptionsBox,
  Title,
} from "./styles"

import PostIcon from "../../images/app-navbar/post-icon.svg"
import PostIconActive from "../../images/app-navbar/post-icon-active.svg"

import PostProjectIcon from "../../images/app-navbar/post-project.svg"
import SPIcon from "../../images/white-cross.svg"
import XIcon from "../../images/app-navbar/x-icon.svg"
import { Box } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import NewPostButton from "../NewPostButton"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import PostOptionsDialog from "../PostOptionsDialog"
import { logEvent } from "../../utils/analytics"

export const NewPostNavIcon = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const history = useHistory()

  const openOptions = async () => {
    setIsOpen(true)
    await logEvent("navbar_post_button_click")
  }

  const closeOptions = () => {
    setIsOpen(false)
  }

  const goToProject = () => {
    closeOptions()
    history.push("/projects/new")
  }

  return (
    <>
      {isOpen && isMobile ? (
        <PostOptionsBox>
          <Item onClick={goToProject}>
            <img src={PostProjectIcon} alt="Project" />
            <Box>
              <Title>Project</Title>
              <Description>
                Get discovered by uploading your studio or professional work
                here
              </Description>
            </Box>
          </Item>
          <NewPostButton origin="image-nav" closeNav={closeOptions} />
          <NewPostButton origin="update-nav" closeNav={closeOptions} />
          <Close onClick={closeOptions}>
            <img src={XIcon} alt="Close" />
          </Close>
        </PostOptionsBox>
      ) : null}
      {!isMobile && (
        <PostOptionsDialog open={isOpen} onClose={() => setIsOpen(false)} />
      )}
      {isMobile ? (
        <IconContainer
          $isActive={isOpen}
          onClick={isOpen ? closeOptions : openOptions}
        >
          <img alt="Post" src={isOpen ? PostIconActive : PostIcon} />
          Post
        </IconContainer>
      ) : (
        <PostButtonContainer>
          <PostButton onClick={isOpen ? closeOptions : openOptions}>
            <img src={SPIcon} alt="Post" />
            <span>Post</span>
          </PostButton>
        </PostButtonContainer>
      )}
    </>
  )
}
