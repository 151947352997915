type Ad = {
  type:
    | "post-job"
    | "request-help"
    | "edit-profile"
    | "promote-job"
    | "recruitment"
  title: string
  text: string[]
  buttonText: string
}

export const AvailableAds: Ad[] = [
  {
    type: "post-job",
    title: "Hiring?",
    text: ["Looking to hire talent? Post your open roles on Spectacular."],
    buttonText: "Add a new job",
  },
  {
    type: "request-help",
    title: "Want us to publish your jobs for you?",
    text: [
      "Don't have time to post your firm's jobs? We're happy to help; let us do the heavy lifting and publish your jobs for you. Click the button below to fill out a few details and our team will work with you to get your jobs live.",
    ],
    buttonText: "Hire now",
  },
  {
    type: "edit-profile",
    title: "Elevate your profile",
    text: [
      "Ensure your profile stands out by uploading projects and adding your experience.",
    ],
    buttonText: "Update profile",
  },
  {
    type: "promote-job",
    title: "Boost your job posts",
    text: [
      "Remain at the top of our job board for 90 days",
      "Gain 2-3X more applicants",
      "Highlighted on our homepage & weekly newsletters",
    ],
    buttonText: "Start now",
  },
  {
    type: "recruitment",
    title: "Spectacular Architect Recruiting",
    text: [
      "Leverage our team of Architects to find you a perfect match for your firm",
      "Tap into the world’s largest network of Architects",
      "Secure peace of mind with our 4-Step proven process",
    ],
    buttonText: "Start your search today!",
  },
]
