import React from "react"

import {
  PanelistBox,
  PanelistTextBox,
  PanelistTitle,
  PanelistIcon,
  PanelistDescriptionBox,
  PanelistDescription,
} from "./styles"
import { JuryDetails } from "../../redux/competition"

interface Props {
  jury: JuryDetails
}

const Panelist = ({ jury }: Props) => {
  const renderHTML = (rawHTML: string) => {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    })
  }

  return (
    <PanelistBox>
      <PanelistIcon src={jury.image} alt={jury.name} />
      <PanelistTextBox>
        <PanelistTitle>{jury.name}</PanelistTitle>
      </PanelistTextBox>
      <PanelistDescriptionBox>
        <PanelistDescription>
          {renderHTML(jury.description)}
        </PanelistDescription>
      </PanelistDescriptionBox>
    </PanelistBox>
  )
}

export default Panelist
