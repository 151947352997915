import React, { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import TagManager from "react-gtm-module"
import { useHistory } from "react-router-dom"

import LoadingPage from "./pages/LoadingPage"
import { LoggedOutDialog } from "./components"
import { toggleDialog } from "./redux/config"
import { useAppDispatch, AppState } from "./redux/configureStore"
import {
  clearProfileState,
  createPlayerId,
  fetchProfile,
  LoadingStatuses,
} from "./redux/profile"
import { UnauthenticatedRoutes, AuthenticatedRoutes } from "./routes"

import { fetchNotifications } from "./redux/bellNotifications"
import { SegmentContext } from "./context"
import { useAuth0 } from "@auth0/auth0-react"
import Cookies from "universal-cookie"
import env from "./environment"

import { App as CapApp } from "@capacitor/app"
import { Browser } from "@capacitor/browser"
import OneSignal from "onesignal-cordova-plugin"

import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from "capacitor-plugin-app-tracking-transparency"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"
import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update"

import environment from "./environment"
import { setSettings } from "./redux/configurations"
import { listCompetitions } from "./redux/competition"

const tagManagerArgs = {
  gtmId: "GTM-MW7K4TJ",
}

TagManager.initialize(tagManagerArgs)

export default function App() {
  const cookies = new Cookies()
  const { analytics } = React.useContext(SegmentContext)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const auth = useAuth0()

  const isOpen = useSelector((state: AppState) => state.config.isOpen)
  const profile = useSelector((state: AppState) => state.profile.profile)
  const profileStatus = useSelector((state: AppState) => state.profile.status)
  const profileLoaded =
    profileStatus !== LoadingStatuses.Idle &&
    profileStatus !== LoadingStatuses.Loading

  const CheckAvailableUpdate = useCallback(async () => {
    if (env.device !== "desktop") {
      const result = await AppUpdate.getAppUpdateInfo()
      if (
        result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return
      }

      if (env.device === "android-app") {
        if (result.immediateUpdateAllowed) {
          await AppUpdate.performImmediateUpdate()
        }
      } else {
        await AppUpdate.openAppStore()
      }
    }
  }, [])

  const FirebaseInit = useCallback(async () => {
    if (env.device !== "desktop") {
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      })
    }
  }, [])

  const OneSignalInit = useCallback(async () => {
    if (env.device !== "desktop") {
      OneSignal.setAppId("a7fb79df-6828-4739-99c8-2b71c51098d1")
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        console.log("notificationOpenedCallback: " + JSON.stringify(jsonData))
      })

      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        console.log("User accepted notifications: " + accepted)
      })

      const OSwindow = window as any
      OSwindow.plugins.OneSignal.getDeviceState(async function (
        stateChanges: any
      ) {
        if (stateChanges.userId) {
          await dispatch(
            createPlayerId({ auth, playerId: stateChanges.userId })
          )

          cookies.set("sp_player_id", stateChanges.userId, {
            path: "/",
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const requestPermission = async (): Promise<AppTrackingStatusResponse> => {
    const response = await AppTrackingTransparency.requestPermission()
    return response
  }

  const initApp = useCallback(
    async (mobileLogin?: boolean) => {
      if (env.device === "ios-app") {
        await requestPermission()
      }

      if ((!auth.isLoading && !profileLoaded) || mobileLogin) {
        try {
          const { payload, type } = await dispatch(fetchProfile({ auth }))
          if (fetchProfile.fulfilled.type === type) {
            if (
              (payload.type === "individual" && !payload?.firstName) ||
              (payload.type === "group" && !payload?.groupName)
            )
              history.push("/profile/edit")

            await dispatch(fetchNotifications({ auth, currentPage: 0 }))

            if (env.device !== "desktop") {
              await auth.getAccessTokenSilently({
                cacheMode: "off",
              })
            }

            await dispatch(setSettings({ profileId: payload.connectionId }))
          } else if (fetchProfile.rejected.type === type) {
            if (auth.isAuthenticated) {
              auth.logout()
            }

            await dispatch(setSettings({}))
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, auth.isAuthenticated, dispatch, profileLoaded]
  )

  const loadCompetition = useCallback(async () => {
    try {
      await dispatch(listCompetitions())
    } catch (e) {
      console.error(e)
    }
  }, [dispatch])

  useEffect(() => {
    initApp()
    OneSignalInit()
    FirebaseInit()
    CheckAvailableUpdate()
    loadCompetition()
  }, [
    initApp,
    OneSignalInit,
    FirebaseInit,
    CheckAvailableUpdate,
    loadCompetition,
  ])

  useEffect(() => {}, [isOpen])

  useEffect(() => {
    if (env.device !== "desktop") {
      CapApp.addListener("appUrlOpen", async ({ url }) => {
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          await auth.handleRedirectCallback(url)

          if (!url.includes("error")) {
            await initApp(true)
          }
        }

        if (url === environment.redirectUri && !url.includes("state")) {
          await dispatch(clearProfileState())
        }
        // No-op on Android
        await Browser.close()
      })
    }
  }, [auth, initApp, dispatch])

  if (auth.isLoading || !profileLoaded) {
    return <LoadingPage />
  }

  if (profile && analytics) {
    analytics.identify(profile.connectionId, {
      email: profile?.email!,
      username: profile?.username!,
    })
  }

  return (
    <>
      <LoggedOutDialog
        isOpen={isOpen}
        onClose={async () => await dispatch(toggleDialog({ opened: false }))}
      />
      {auth.isAuthenticated && profile ? (
        <AuthenticatedRoutes profile={profile} />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </>
  )
}
