import React, { useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import { JobsCardDropdown } from "./JobsCardDropdown"

import Avatar from "../../components/Avatar"
import { Profile } from "../../redux/profile"
import { normalizeProjectTitle } from "../../utils"

import JobOpenTagIcon from "../../images/open-tag.svg"
import JobClosedTagIcon from "../../images/closed-tag.svg"

import * as RouteConstants from "../../constants/routes"
import { useAppDispatch } from "../../redux/configureStore"
import { deleteJobListing, closeJobListingById } from "../../redux/jobListings"
import { JobListing as Job } from "../../redux/jobListings"
import {
  LocationSpan,
  JobSummary,
  LocationArea,
  JobTag,
  JobTagsArea,
  JobTitle,
  DropDownBox,
  ApplicantsText,
} from "./Styles"
import { useWindowDimensions } from "../../hooks"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "../../components"
import JobApplicantsListDialog from "../../components/JobApplicantsListDialog"
import { logEvent } from "../../utils/analytics"

interface Props {
  job: Job
  profile: Profile
  origin: "groups-page" | "jobs-listing" | "job-details" | "job-dashboard"
}

export const JobCard: React.FC<Props> = ({ job, profile, origin }) => {
  const auth = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { width } = useWindowDimensions()

  const [isApplicantsDialogOpen, setIsApplicantsDialogOpen] = useState(false)

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const isPublicProfile = authenticatedProfile?.username !== profile?.username

  const isMobile = width < 960

  const jobURL = `${RouteConstants.PROFILE_CONTAINER}/${
    profile?.username
  }/job_listings/${job.id}/${normalizeProjectTitle(job.title)}`

  const handleDelete = async () => {
    try {
      const { type } = await dispatch(deleteJobListing({ auth, id: job.id! }))
      if (deleteJobListing.fulfilled.type === type) {
        history.push(`/dashboard`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = async () => {
    try {
      const { type } = await dispatch(
        closeJobListingById({ auth, id: job.id! })
      )

      if (closeJobListingById.fulfilled.type === type) {
        history.push(jobURL)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const renderStatus = () => {
    if (isPublicProfile) return null
    return (
      <span>
        <img
          alt="job-status-icon"
          style={{
            marginBottom:
              isMobile &&
              (origin === "job-details" || origin === "job-dashboard")
                ? 0
                : "-3px",
            paddingLeft: isMobile ? "0px" : "16px",
            display: isMobile ? "flex" : "",
          }}
          src={job.status === "open" ? JobOpenTagIcon : JobClosedTagIcon}
        />
      </span>
    )
  }

  const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  const customTags = []

  if (job?.employmentType?.toLowerCase() !== "n/a")
    customTags.push(capitalize(job.employmentType))
  if (job?.workplaceType?.toLowerCase() !== "n/a")
    customTags.push(capitalize(job.workplaceType))
  if (job?.experienceLevel?.toLowerCase() !== "n/a")
    customTags.push(capitalize(job.experienceLevel))

  const allTags = [...customTags, ...job.tags]

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          wordBreak: "keep-all",
          alignItems: origin === "job-details" ? "center" : "",
        }}
      >
        {origin !== "job-details" && (
          <div
            style={{
              minWidth: isMobile ? "40px" : "56px",
              paddingRight: isMobile ? "12px" : "16px",
              cursor: "pointer",
            }}
          >
            <Avatar
              plain
              width={isMobile ? 40 : 56}
              height={isMobile ? 40 : 56}
              aria-hidden
              profile={profile!}
              onClick={() => history.push(`/sp/${profile!.username}`)}
            />
          </div>
        )}

        <div style={{ flex: 2 }}>
          <div
            style={{
              marginTop: "-4px",
              paddingBottom: isMobile ? "12px" : "16px",
            }}
          >
            <JobTitle
              to={jobURL}
              style={{
                fontSize: isMobile ? "18px" : "20px",
              }}
            >
              {job.title}
              {isMobile || origin === "jobs-listing" ? null : renderStatus()}
            </JobTitle>
          </div>

          {isMobile &&
          !isPublicProfile &&
          (origin === "groups-page" ||
            origin === "job-details" ||
            origin === "job-dashboard") ? (
            <div
              style={{
                paddingBottom: origin === "job-details" ? 0 : "12px",
                marginTop: "-5px",
              }}
            >
              {renderStatus()}
            </div>
          ) : null}

          {isMobile && origin === "job-details" ? null : (
            <LocationArea
              style={{
                paddingBottom:
                  isMobile ||
                  origin === "job-details" ||
                  origin === "job-dashboard"
                    ? 0
                    : "16px",
              }}
            >
              <LocationSpan to={`/jobs?search=${profile.groupName}`}>
                {profile.groupName!}
              </LocationSpan>{" "}
              <span style={{ fontSize: "10px" }}>•</span>{" "}
              <LocationSpan to={`/jobs?location=${job.location}`}>
                {job.location}
              </LocationSpan>{" "}
              <span style={{ fontSize: "10px" }}>•</span> Posted{" "}
              {moment(new Date(job.createdAt!)).format("MM/DD/YYYY")}
              {["job-dashboard", "jobs-listing", "job-details"].includes(
                origin
              ) &&
                job.isPromoted && (
                  <>
                    <span style={{ fontSize: "10px" }}> • </span>
                    <span style={{ color: "#3057e1", fontWeight: "bold" }}>
                      Promoted
                    </span>
                  </>
                )}
              {!isMobile &&
              (origin === "job-details" || origin === "job-dashboard") &&
              job.profile?.firstName ? (
                <div>
                  <strong>Job poster</strong>: {job.profile?.firstName}{" "}
                  {job.profile?.lastName}
                </div>
              ) : null}
              {!isMobile &&
                (origin === "job-details" || origin === "job-dashboard") && (
                  <ApplicantsText
                    $isClickable={job.applies > 0}
                    onClick={() =>
                      setIsApplicantsDialogOpen(job.applies > 0 ? true : false)
                    }
                  >
                    {job.applies}{" "}
                    {job.applies === 1 ? "Applicant" : "Applicants"}
                  </ApplicantsText>
                )}
            </LocationArea>
          )}

          {!isMobile &&
          origin !== "job-details" &&
          origin !== "job-dashboard" ? (
            <JobSummary>{job.summary}</JobSummary>
          ) : null}

          {allTags.length > 0 &&
            origin !== "job-details" &&
            origin !== "job-dashboard" && (
              <JobTagsArea>
                {allTags.map((tag) => (
                  <JobTag key={tag}>{tag}</JobTag>
                ))}
              </JobTagsArea>
            )}
        </div>

        <DropDownBox>
          {!isPublicProfile &&
            ["groups-page", "job-dashboard", "job-details"].includes(
              origin
            ) && (
              <>
                {!job.isPromoted && (
                  <Button
                    color="secondary"
                    children="Promote"
                    onClick={async () => {
                      history.push(`/job_listings/${job.id!}/promotion/start`)
                      await logEvent("promote_job_click")
                    }}
                    style={{ height: "40px" }}
                  />
                )}
                <JobsCardDropdown
                  job={job}
                  onClose={handleClose}
                  onDelete={handleDelete}
                />
              </>
            )}
        </DropDownBox>

        {isApplicantsDialogOpen && (
          <JobApplicantsListDialog
            open={isApplicantsDialogOpen}
            onClose={() => setIsApplicantsDialogOpen(false)}
            job={job}
          />
        )}
      </div>
    </>
  )
}
