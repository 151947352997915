import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { createJobPosition, JobPosition } from "../../redux/jobPositions"
import View from "./View"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

const JobPositionNewPage = () => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleJobPositionCreate = async (jobPosition: JobPosition) => {
    try {
      const { type } = await dispatch(createJobPosition({ jobPosition, auth }))
      if (createJobPosition.fulfilled.type === type) {
        history.push(`/experiences/edit`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`New Job Position`}
        classTitle="NewJobPosition"
      />
      <Helmet>
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`New Job Position - Spectacular`} />
        <meta name="title" content={`New Job Position - Spectacular`} />
        <title>New Job Position - Spectacular</title>
      </Helmet>
      <View onSubmit={handleJobPositionCreate} />
    </>
  )
}

export default JobPositionNewPage
