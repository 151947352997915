import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { Profile } from "./profile"
import { Image } from "./projects"

//#region types
export type Post = {
  id?: string
  content: string
  profile?: Profile
  origin?: string
  images?: PostImage[]
  createdAt: Date
  isAds?: boolean
  adsUrl?: string
  liked?: boolean
  commented?: boolean
  shared?: boolean
  likeCount?: number
  commentCount?: number
  shareCount?: number
  comments?: PostComment[]
  mentions?: PostMention[]
  flags?: PostFlag[]
}

export type PostFlag = {
  id: string
  reason: string
  status: string
  otherDescription: string
  createdAt: Date
  profile: Profile
  reviewer: Profile
  reviewedAt: Date
}

export type PostImage = {
  id?: string
  order: number
  url: string
  imageUrl: string
  largeImageUrl: string
  width: number
  height: number
}

export type PostComment = {
  id?: string
  profileId?: string
  postId?: string
  parentCommentId?: string
  text: string
  profile?: Profile
  createdAt?: Date
  replies?: PostComment[]
  mentions?: PostMention[]
  likes?: number
  liked?: boolean
}

export type PostMention = {
  id?: string
  profileId?: string
  username?: string
  name?: string
  fullString?: string
  createdAt?: Date
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  posts: Post[]
  post?: Post
  allPosts: Post[]
  count: number
  newCount: number
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type UploadImagesPayload = {
  auth: Auth0ContextInterface
  postId: string
  fileType: string
  order: number
  width?: number
  height?: number
  image?: Image
}

export const uploadImages = createAsyncThunk<any, UploadImagesPayload>(
  "posts/uploadImages",
  async ({ auth, postId, fileType, order, width, height, image }) => {
    const upload = {
      postId,
      fileType,
      order,
      width,
      height,
    }

    if (Object.keys(upload).length > 0 && upload.constructor === Object) {
      return useApi(auth, "/posts/sign_s3", {
        method: "POST",
        body: JSON.stringify(upload),
      }).then((res) => res.json())
    }
  }
)

type CreatePostPayload = {
  auth: Auth0ContextInterface
  content: string
  mentions?: PostMention[]
}
export const createPost = createAsyncThunk<any, CreatePostPayload>(
  "posts/create",
  async ({ auth, content, mentions }) => {
    const form = {
      origin,
      content,
      showTo: "all",
      mentions,
    }

    return useApi(auth, "/posts", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => res.json())
  }
)

type UpdatePostPayload = {
  auth: Auth0ContextInterface
  postId: string
  content: string
  mentions?: PostMention[]
  removedImages?: string[]
}
export const updatePost = createAsyncThunk<any, UpdatePostPayload>(
  "posts/update",
  async ({ auth, content, mentions, postId, removedImages }) => {
    const form = {
      origin,
      removedImages,
      content,
      showTo: "all",
      mentions,
    }

    return useApi(auth, `/posts/${postId}`, {
      method: "PATCH",
      body: JSON.stringify(form),
    }).then((res) => res.json())
  }
)

type GetPostsPayload = {
  page?: number
  perPage?: number
  myNetwork?: boolean
  search?: string
  email?: string
  sortBy: string
}
export const getPosts = createAsyncThunk<any, GetPostsPayload>(
  "posts/fetchAll",
  async ({ email, page = 1, perPage = 8, search, myNetwork, sortBy }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/posts?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    if (myNetwork) endpoint += `&myNetwork=${myNetwork}`
    if (email) endpoint += `&email=${email}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type GetPostsByProfilePayload = {
  profileId: string
  email?: string
}
export const getPostsByProfile = createAsyncThunk<
  any,
  GetPostsByProfilePayload
>("posts/profile", async ({ profileId, email }) => {
  let endpoint = `/posts/profile/${profileId}`
  if (email) endpoint += `?email=${email}`

  return useApi(null, endpoint, {
    method: "GET",
  }).then((res) => res.json())
})

type DeleteCommentPayload = {
  auth: Auth0ContextInterface
  postId: string
  commentId: string
  profileId: string
  parentCommentId?: string
}
export const deleteComment = createAsyncThunk<any, DeleteCommentPayload>(
  "posts/comments/delete",
  async ({ postId, commentId, parentCommentId, profileId, auth }) => {
    const endpoint = `/posts/${postId}/comments/${commentId}`
    return useApi(auth, endpoint, {
      method: "DELETE",
    }).then((res) => res.text())
  }
)

type EditCommentPayload = {
  auth: Auth0ContextInterface
  postId: string
  commentId: string
  text: string
  mentions: PostMention[]
}
export const editComment = createAsyncThunk<any, EditCommentPayload>(
  "posts/comments/edit",
  async ({ text, mentions, postId, commentId, auth }) => {
    const endpoint = `/posts/${postId}/comments/${commentId}`

    const form = { text, mentions }

    return useApi(auth, endpoint, {
      method: "PATCH",
      body: JSON.stringify(form),
    }).then((res) => res.json())
  }
)

type GetPostPayload = {
  postId: string
  email?: string
}
export const getPost = createAsyncThunk<any, GetPostPayload>(
  "posts/get",
  async ({ postId, email }) => {
    let endpoint = `/posts/${postId}`
    if (email) endpoint += `?email=${email}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type LikePostPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likePost = createAsyncThunk<any, LikePostPayload>(
  "posts/like",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikePostPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikePost = createAsyncThunk<any, DislikePostPayload>(
  "posts/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type LikeCommentPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likeComment = createAsyncThunk<any, LikeCommentPayload>(
  "posts/comments/like",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/comments/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikeCommentPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikeComment = createAsyncThunk<any, DislikeCommentPayload>(
  "posts/comments/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/comments/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type SharePostPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const sharePost = createAsyncThunk<any, SharePostPayload>(
  "posts/share",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/${id}/share`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type AddCommentPayload = {
  auth: Auth0ContextInterface
  id: string
  text: string
  parentCommentId?: string
  mentions?: PostMention[]
}
export const addComment = createAsyncThunk<any, AddCommentPayload>(
  "posts/addComment",
  async ({ auth, id, text, parentCommentId, mentions }) => {
    return useApi(auth, `/posts/${id}/comments`, {
      method: "POST",
      body: JSON.stringify({
        text,
        parentCommentId,
        mentions,
        origin: window.location.origin,
      }),
    }).then((res) => res.json())
  }
)

type DeletePostPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deletePost = createAsyncThunk<any, DeletePostPayload>(
  "posts/delete",
  async ({ auth, id }) => {
    return useApi(auth, `/posts/${id}`, {
      method: "DELETE",
    }).then((res) => res.text())
  }
)

type FlagPostPayload = {
  auth: Auth0ContextInterface
  postId: string
  reason: string
  otherDescription?: string
}
export const flagPost = createAsyncThunk<any, FlagPostPayload>(
  "posts/flag",
  async ({ auth, postId, reason, otherDescription }) => {
    const data = {
      postId,
      reason,
      otherDescription,
      origin: window.location.origin,
    }

    return useApi(auth, `/flags`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((res) => res.text())
  }
)

type UpdatePostFlagStatePayload = {
  postId: string
}

export const updatePostFlagState = createAsyncThunk<
  any,
  UpdatePostFlagStatePayload
>("flags/updatePostFlagState", async ({ postId }) => {
  return ""
})

//#endregion

//#region slice
const initialState: SliceState = {
  posts: [],
  allPosts: [],
  post: undefined,
  count: 0,
  newCount: 0,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPost.fulfilled, (state, action) => {})
      .addCase(getPostsByProfile.pending, (state, action) => {
        state.posts = []
        state.status = LoadingStatuses.Loading
      })
      .addCase(getPostsByProfile.fulfilled, (state, action) => {
        state.posts = action.payload.data
        state.status = LoadingStatuses.Succeeded
        state.count = action.payload.count
      })

      .addCase(getPosts.fulfilled, (state, action) => {
        state.count = action.payload.count
        state.status = LoadingStatuses.Succeeded

        state.newCount = state.newCount + action.payload.data.length

        state.allPosts =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.allPosts?.concat(action.payload.data)

        state.error = null
      })
      .addCase(getPosts.pending, (state, action) => {
        state.status = LoadingStatuses.Loading

        if (action.meta.arg.page === 0 || action.meta.arg.page === 1) {
          state.allPosts = []
          state.newCount = 0
        }
      })

      .addCase(getPost.pending, (state, action) => {
        state.post = undefined
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.post = action.payload
      })
      .addCase(likePost.fulfilled, (state, action) => {
        if (state.post) {
          const post = { ...state.post, liked: true } as Post
          post.likeCount!++
          state.post = post
        }
        if (state.posts) {
          const updatedAllPosts = [...state.posts]
          updatedAllPosts.forEach((post) => {
            if (post.id! === action.meta.arg.id) {
              post.likeCount!++
              post.liked = true
            }
          })

          state.posts = updatedAllPosts
        }
      })
      .addCase(dislikePost.fulfilled, (state, action) => {
        if (state.post) {
          const post = { ...state.post, liked: false } as Post
          post.likeCount!--
          state.post = post
        }

        if (state.posts) {
          const updatedAllPosts = [...state.posts]
          updatedAllPosts.forEach((post) => {
            if (post.id! === action.meta.arg.id) {
              post.likeCount!--
              post.liked = false
            }
          })

          state.posts = updatedAllPosts
        }
      })
      .addCase(sharePost.fulfilled, (state, action) => {
        if (state.post) {
          const post = { ...state.post, shared: true } as Post
          post.shareCount!++
          state.post = post
        }
        if (state.posts) {
          const updatedAllPosts = [...state.posts]
          updatedAllPosts.forEach((post) => {
            if (post.id! === action.meta.arg.id) {
              post.shareCount!++
              post.shared = true
            }
          })

          state.posts = updatedAllPosts
        }
      })
      .addCase(addComment.fulfilled, (state, action) => {
        if (state.post) {
          if (action.meta.arg.parentCommentId && state.post.comments) {
            const updatedCommentsReplies = [...state.post.comments]
            updatedCommentsReplies.forEach((comment) => {
              if (comment.id === action.meta.arg.parentCommentId) {
                const newReply = action.payload
                if (action.meta.arg.mentions) {
                  newReply.mentions = action.meta.arg.mentions
                }
                comment.replies!.unshift(newReply)
              }
            })

            state.post.commentCount!++
            state.post.commented = true
          } else {
            const newComments = [...state.post!.comments!, action.payload]
            const post = {
              ...state.post,
              comments: newComments,
            } as Post
            state.post = post
            state.post.commentCount!++
            state.post.commented = true
          }
        }

        if (state.posts) {
          const updatedPosts = [...state.posts]
          updatedPosts.forEach((post) => {
            if (post.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && post.comments) {
                const updatedCommentsRepliesAllPosts = [...post.comments]
                updatedCommentsRepliesAllPosts.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                post.comments = updatedCommentsRepliesAllPosts
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                post.comments!.unshift(newComment)
              }

              post.commentCount!++
              post.commented = true
            }
          })

          state.posts = updatedPosts
        }

        if (state.allPosts) {
          const updatedAllPosts = [...state.allPosts]
          updatedAllPosts.forEach((post) => {
            if (post.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && post.comments) {
                const updatedCommentsRepliesAllPosts = [...post.comments]
                updatedCommentsRepliesAllPosts.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                post.comments = updatedCommentsRepliesAllPosts
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                post.comments!.unshift(newComment)
              }

              post.commentCount!++
              post.commented = true
            }
          })

          state.allPosts = updatedAllPosts
        }
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        if (state.post) {
          state.post = action.payload
        }

        if (state.posts) {
          const updatedPosts = [...state.posts]
          updatedPosts.forEach((post, key) => {
            if (post.id! === action.meta.arg.postId) {
              updatedPosts[key] = action.payload
            }
          })
          state.posts = updatedPosts
        }
      })
      .addCase(uploadImages.fulfilled, (state, action) => {
        const newImage = {
          id: action.payload.id,
          url: action.meta.arg.image?.url,
          imageUrl: action.meta.arg.image?.url,
          largeImageUrl: action.meta.arg.image?.url,
          width: action.meta.arg.width,
          height: action.meta.arg.height,
          order: action.meta.arg.order,
        } as PostImage

        if (state.post) {
          state.post.images?.push(newImage)
        }

        if (state.posts) {
          const updatedPosts = [...state.posts]
          updatedPosts.forEach((post, key) => {
            if (post.id! === action.meta.arg.postId) {
              updatedPosts[key].images?.push(newImage)
            }
          })
          state.posts = updatedPosts
        }
      })
      .addCase(likeComment.fulfilled, (state, action) => {})
      .addCase(dislikeComment.fulfilled, (state, action) => {})
      .addCase(deleteComment.fulfilled, (state, action) => {
        const commentId = action.meta.arg.commentId
        const profileId = action.meta.arg.profileId
        const postId = action.meta.arg.postId
        const parentCommentId = action.meta.arg.parentCommentId

        if (state.post) {
          const comments = [...state.post.comments!]
          if (action.meta.arg.parentCommentId) {
            const updatedComments = comments.map((comment) => {
              if (comment.id === action.meta.arg.parentCommentId) {
                comment.replies = comment.replies?.filter(
                  (reply) => reply.id !== commentId
                )
              }
              return comment
            })
            state.post.commentCount!--
            state.post.comments = updatedComments
          } else {
            const updatedComments = comments.filter(
              (comment) => comment.id !== commentId
            )
            state.post.commentCount!--
            state.post.comments = updatedComments
          }
        }

        if (state.posts) {
          const updatedPosts = [...state.posts]
          updatedPosts.forEach((post) => {
            if (post.id! === postId && post.comments) {
              if (parentCommentId) {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                post.commentCount!--
                post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                post.comments = updatedComments
              } else {
                const comments = [...post.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                post.commentCount!--
                post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                post.comments = updatedComments
              }
            }
          })
          state.posts = updatedPosts
        }

        if (state.allPosts) {
          const updatedAllPosts = [...state.allPosts]
          updatedAllPosts.forEach((post) => {
            if (post.id! === postId && post.comments) {
              if (parentCommentId) {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                post.commentCount!--
                post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                post.comments = updatedComments
              } else {
                const comments = [...post.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                post.commentCount!--
                post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                post.comments = updatedComments
              }
            }
          })
          state.allPosts = updatedAllPosts
        }
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const editedComment = action.payload
        if (state.post) {
          const comments = [...state.post.comments!]
          if (editedComment.parentCommentId) {
            const updatedComments = comments.map((comment) => {
              if (comment.id === editedComment.parentCommentId) {
                comment.replies = comment.replies?.map((reply) => {
                  if (reply.id === editedComment.id) return editedComment
                  return reply
                })
              }
              return comment
            })
            state.post.comments = updatedComments
          } else {
            const updatedComments = comments.map((comment) => {
              if (comment.id === editedComment.id) return editedComment
              return comment
            })
            state.post.comments = updatedComments
          }
        }

        if (state.posts) {
          const updatedPosts = [...state.posts]
          updatedPosts.forEach((post) => {
            if (post.comments && post.id! === action.meta.arg.postId) {
              if (editedComment.parentCommentId) {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                post.comments = updatedComments
              } else {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                post.comments = updatedComments
              }
            }
          })
          state.posts = updatedPosts
        }

        if (state.allPosts) {
          const updatedAllPosts = [...state.allPosts]
          updatedAllPosts.forEach((post) => {
            if (post.comments && post.id! === action.meta.arg.postId) {
              if (editedComment.parentCommentId) {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                post.comments = updatedComments
              } else {
                const comments = [...post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                post.comments = updatedComments
              }
            }
          })
          state.allPosts = updatedAllPosts
        }
      })
  },
})
//#endregion

//#region selectors
//#endregion
