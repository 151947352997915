import React, { useState } from "react"

import {
  SuccessDialogArea,
  SuccessDialogBox,
  DialogBgBox,
  CloseIconImg,
  DialogSpIcon,
  MarketingDialogTitle,
  TextfieldBox,
  MarketingTitleBox,
  SuccessDialogTitle,
  SuccessDialogText,
  FormSpacingBox,
  AddAuthor,
  CheckboxBox,
} from "./styles"

import * as Yup from "yup"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"

import TextField from "./TextField"
import { Button } from "../../components"

import SPIcon from "../../images/innovation-awards/sp-big-icon.svg"
import CloseIcon from "../../images/innovation-awards/close-icon.png"
import { useAppDispatch } from "../../redux/configureStore"
import { compettitionApplication } from "../../redux/profile"
import { useAuth0 } from "@auth0/auth0-react"
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"

export type Props = {
  onClose: () => void
  open: boolean
  campaign: string
}

export interface Collaborator {
  name: string
}

export type FormValues = {
  email: string
  collaborators?: Collaborator[]
}
const RegisterDialog = ({ open, onClose, campaign }: Props) => {
  const auth = useAuth0()
  const [registerType, setRegisterType] = useState("")
  const [selectedType, setSelectedType] = useState("")

  const handleClose = () => {
    onClose()
    setRegisterType("")
    setSelectedType("")
  }

  const ValidationSchema =
    registerType === "individual"
      ? Yup.object().shape({
          email: Yup.string()
            .required("This field is required.")
            .email("You must enter a valid email."),
        })
      : Yup.object().shape({
          email: Yup.string()
            .required("This field is required.")
            .email("You must enter a valid email."),
          collaborators: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("This field is required."),
            })
          ),
        })

  const [registered, setRegistered] = useState(false)

  const dispatch = useAppDispatch()

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      const { type } = await dispatch(
        compettitionApplication({
          ...values,
          auth,
          campaign,
        })
      )
      if (compettitionApplication.fulfilled.type === type) {
        actions.setSubmitting(false)
        setRegistered(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const initialValues: FormValues = {
    email: "",
    collaborators: [
      {
        name: "",
      },
    ],
  }

  return (
    <SuccessDialogArea
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      scroll={"body"}
    >
      <SuccessDialogBox>
        <DialogBgBox>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={handleClose} />
          {!registerType ? (
            <>
              <DialogSpIcon src={SPIcon} alt={"success"} />
              <CheckboxBox>
                <RadioGroup>
                  <FormControlLabel
                    onChange={() => setSelectedType("individual")}
                    value="individual"
                    control={<Radio />}
                    label="Individual"
                  />
                  <FormControlLabel
                    onChange={() => setSelectedType("group")}
                    value="group"
                    control={<Radio />}
                    label="Group"
                  />
                </RadioGroup>
              </CheckboxBox>

              <TextfieldBox style={{ marginTop: "35px" }}>
                <Button
                  type={"submit"}
                  children={"Continue"}
                  color={"secondary"}
                  size={"medium"}
                  onClick={() => setRegisterType(selectedType)}
                  disabled={selectedType === ""}
                  style={{
                    width: "100%",
                    height: "56px",
                    fontSize: "16px",
                  }}
                />
              </TextfieldBox>
            </>
          ) : (
            <>
              {!registered ? (
                <>
                  <DialogSpIcon src={SPIcon} alt={"success"} />
                  <MarketingTitleBox>
                    <MarketingDialogTitle>
                      {registerType === "group" ? (
                        <>
                          Type in your email to receive weekly ‘hints’ about the
                          competition prompt! Make sure to add all team members
                          as collaborators.
                        </>
                      ) : (
                        <>
                          Type in your email to receive weekly ‘hints’ about the
                          competition prompt!
                        </>
                      )}
                    </MarketingDialogTitle>
                  </MarketingTitleBox>
                  <Formik
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions)
                    }}
                    initialValues={initialValues}
                    validationSchema={ValidationSchema}
                  >
                    {(formik) => (
                      <Form style={{ width: "100%" }}>
                        <TextfieldBox>
                          <TextField
                            fullWidth
                            name="email"
                            placeholder="john.doe@example.com"
                            displayTitle="Email"
                            style={{ height: "48px" }}
                          />

                          {registerType === "group" && (
                            <FieldArray name="collaborators">
                              {({ push, remove }) => (
                                <>
                                  {formik.values.collaborators?.map(
                                    (collaborator, index) => (
                                      <>
                                        <FormSpacingBox key={index}>
                                          <TextField
                                            fullWidth
                                            name={`collaborators.${index}.name`}
                                            displayTitle="Add Collaborator's name"
                                            placeholder="John Doe"
                                            style={{ height: "48px" }}
                                          />
                                        </FormSpacingBox>
                                        {index > 0 && (
                                          <FormSpacingBox key={index}>
                                            <AddAuthor
                                              onClick={() => remove(index)}
                                            >
                                              - Remove Collaborator
                                            </AddAuthor>
                                          </FormSpacingBox>
                                        )}
                                      </>
                                    )
                                  )}
                                  <FormSpacingBox>
                                    <AddAuthor
                                      onClick={() =>
                                        push({ name: "", profileLink: "" })
                                      }
                                    >
                                      + Add Collaborator
                                    </AddAuthor>
                                  </FormSpacingBox>
                                </>
                              )}
                            </FieldArray>
                          )}
                        </TextfieldBox>

                        <TextfieldBox style={{ marginTop: "35px" }}>
                          <Button
                            type={"submit"}
                            children={"Register"}
                            color={"secondary"}
                            size={"medium"}
                            disabled={
                              formik.isSubmitting ||
                              !formik.isValid ||
                              !formik.dirty
                            }
                            style={{
                              width: "100%",
                              height: "56px",
                              fontSize: "16px",
                            }}
                          />
                        </TextfieldBox>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <DialogSpIcon src={SPIcon} alt={"success"} />
                  <MarketingTitleBox>
                    <SuccessDialogTitle variant="h2">
                      Get Pumped!
                    </SuccessDialogTitle>
                    <SuccessDialogText>
                      You’re officially registered to receive weekly ‘hints’ for
                      the Weekend Competition. Your next step is to register for
                      the live webinar announcement on Friday July, 26th at 6pm
                      CST.
                    </SuccessDialogText>
                    <Button
                      type={"submit"}
                      children={"Webinar Registration"}
                      color={"secondary"}
                      size={"medium"}
                      onClick={() =>
                        window.open(
                          "https://attendee.gotowebinar.com/register/1849219568266683488"
                        )
                      }
                      style={{
                        width: "100%",
                        height: "56px",
                        fontSize: "16px",
                        marginTop: "32px",
                      }}
                    />
                  </MarketingTitleBox>
                </>
              )}
            </>
          )}
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default RegisterDialog
