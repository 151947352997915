import React from "react"
import { TableRow, TableCell, IconButton } from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/DoneAllTwoTone"
import RevertIcon from "@material-ui/icons/NotInterestedOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import { EditableProjectFeatured } from "./index"
import { ActionsTableCell } from "./styles"
import moment from "moment"

export type Props = {
  featured: EditableProjectFeatured
  onEdit: () => void
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
  onChange: (date: Date | null, property: keyof EditableProjectFeatured) => void
}

const View = ({
  featured,
  onSave,
  onDelete,
  onCancel,
  onChange,
  onEdit,
}: Props) => {
  if (!featured.editing) {
    return (
      <TableRow>
        <TableCell>{moment(featured.startDate).format("MMM Do YY")}</TableCell>
        <TableCell>{moment(featured.endDate).format("MMM Do YY")}</TableCell>
        <ActionsTableCell>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </ActionsTableCell>
      </TableRow>
    )
  }

  return (
    <TableRow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TableCell component="th" scope="row">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={featured.startDate}
            onChange={(date) => onChange(date, "startDate")}
            autoOk={true}
          />
        </TableCell>
        <TableCell>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={featured.endDate}
            onChange={(date) => onChange(date, "endDate")}
            autoOk={true}
          />
        </TableCell>
        <ActionsTableCell>
          <>
            <IconButton onClick={onSave}>
              <DoneIcon />
            </IconButton>
            {featured.id && (
              <IconButton onClick={onCancel}>
                <RevertIcon />
              </IconButton>
            )}
          </>
        </ActionsTableCell>
      </MuiPickersUtilsProvider>
    </TableRow>
  )
}

export default View
