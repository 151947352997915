import React, { useState, MouseEvent } from "react"
import DotMenu from "../../images/dot-menu.svg"
import { Popover, Box } from "@material-ui/core"
import { Triangle, MenuItem, MenuBox } from "./styles"

export type Props = {
  handleAllRead: () => void
  handleChangeState: (value: string) => void
}

const View = ({ handleAllRead, handleChangeState }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const markAllAsRead = () => {
    handleAllRead()
    handleClose()
  }

  const setUnread = () => {
    handleChangeState("unread")
    handleClose()
  }

  const setAll = () => {
    handleChangeState("all")
    handleClose()
  }

  const dotMenuOpen = Boolean(anchorEl)

  return (
    <>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img
          src={DotMenu}
          alt={"dot-menu"}
          style={{ width: "14px", height: "3.5px" }}
        />
      </Box>
      <Popover
        open={dotMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            marginLeft: "30px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          <MenuItem onClick={markAllAsRead}>Mark all as read</MenuItem>
          <MenuItem onClick={setUnread}>View unread</MenuItem>
          <MenuItem onClick={setAll}>View all</MenuItem>
        </MenuBox>
      </Popover>
    </>
  )
}

export default View
