import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { deleteProject, Project } from "../../redux/projects"
import View from "./View"
import * as RouteConstants from "../../constants/routes"
import { useAuth0 } from "@auth0/auth0-react"
type Props = {
  project: Project
}

const ProjectDeleteLink = ({ project }: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const profile = useSelector((state: any) => state.profile.profile)

  const handleDeleteProject = async () => {
    try {
      const { type } = await dispatch(deleteProject({ auth, id: project.id! }))
      if (deleteProject.fulfilled.type === type) {
        history.push(`${RouteConstants.PROFILE_CONTAINER}/${profile?.username}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return <View onDeleteClicked={handleDeleteProject} />
}

export default ProjectDeleteLink
