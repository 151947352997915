import React from "react"
import View from "./View"

export type Props = {
  currentStepNumber: number
  steps: string[]
  goToStep: Function
}

const StepNavigation = (props: Props) => {
  return <View {...props} />
}

export default StepNavigation
