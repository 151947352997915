import React from "react"

import {
  NetworkContainer,
  TabsBox,
  TabItem,
  TabLabel,
  Tabs,
  NetworkTitle,
  ContentBox,
  MobileTab,
  MobileTabArea,
  Divider,
} from "./styles"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Profile } from "../../redux/profile"

import ConnectionsTab from "./Tabs/ConnectionsTab"

import ConnectionsIcon from "../../images/network/icon-connections.svg"
import ConnectionsIconActive from "../../images/network/icon-connections-active.svg"
import CollaboratorsIcon from "../../images/network/icon-collaborators.svg"
import CollaboratorsIconActive from "../../images/network/icon-collaborators-active.svg"
import FollowingIcon from "../../images/network/icon-following.svg"
import FollowingIconActive from "../../images/network/icon-following-active.svg"
import FollowersIcon from "../../images/network/icon-followers.svg"
import FollowersIconActive from "../../images/network/icon-followers-active.svg"
import SuggestionsIcon from "../../images/network/icon-suggestions.svg"
import SuggestionsIconActive from "../../images/network/icon-suggestions-active.svg"
import FollowingTab from "./Tabs/FollowingTab"
import FollowersTab from "./Tabs/FollowersTab"
import CollaboratorsTab from "./Tabs/CollaboratorsTab"
import SuggestionsTab from "./Tabs/SuggestionsTab"
import IndividualsGroupsPage from "../IndividualsGroupsPage"

export type Props = {
  profile?: Profile
  currentTab: string
  onTabClicked: (url: string) => void
  onTabChangedMobile: (tab: string, url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  onChangeCurrentCollaboratorsTab: (tab: string, url: string) => void
  currentCollaboratorsTab: string
}

const View = ({
  profile,
  currentTab,
  currentCollaboratorsTab,
  onTabChanged,
  onTabChangedMobile,
  onTabClicked,
  onChangeCurrentCollaboratorsTab,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  return (
    <NetworkContainer>
      <TabsBox>
        {!isMobile ? (
          <>
            <NetworkTitle>Network</NetworkTitle>
            <Divider />
            <Tabs
              value={currentTab}
              orientation="vertical"
              variant="fullWidth"
              onChange={onTabChanged}
              indicatorColor="secondary"
            >
              {profile?.type === "individual" && (
                <TabItem
                  $isActive={currentTab === "suggestions"}
                  label={
                    <TabLabel $isActive={currentTab === "suggestions"}>
                      <img
                        src={
                          currentTab === "suggestions"
                            ? SuggestionsIconActive
                            : SuggestionsIcon
                        }
                        alt={"Suggestions"}
                      />
                      Grow Your Network
                    </TabLabel>
                  }
                  value="suggestions"
                  onClick={onTabClicked.bind(this, `/network/suggestions`)}
                />
              )}

              <TabItem
                $isActive={currentTab === "profiles"}
                label={
                  <TabLabel $isActive={currentTab === "profiles"}>
                    <img
                      src={
                        currentTab === "profiles"
                          ? SuggestionsIconActive
                          : SuggestionsIcon
                      }
                      alt={"Profiles"}
                    />
                    Profiles
                  </TabLabel>
                }
                value="profiles"
                onClick={onTabClicked.bind(this, `/network/profiles`)}
              />

              {profile?.type === "individual" && (
                <TabItem
                  $isActive={currentTab === "connections"}
                  label={
                    <TabLabel $isActive={currentTab === "connections"}>
                      <img
                        src={
                          currentTab === "connections"
                            ? ConnectionsIconActive
                            : ConnectionsIcon
                        }
                        alt={"Connections"}
                      />
                      Connections ({profile?.networkCount?.connections})
                    </TabLabel>
                  }
                  value="connections"
                  onClick={onTabClicked.bind(this, `/network/connections`)}
                />
              )}

              <TabItem
                $isActive={currentTab === "followers"}
                label={
                  <TabLabel $isActive={currentTab === "followers"}>
                    <img
                      src={
                        currentTab === "followers"
                          ? FollowersIconActive
                          : FollowersIcon
                      }
                      alt={"Followers"}
                    />
                    Followers ({profile?.networkCount?.followers})
                  </TabLabel>
                }
                value="followers"
                onClick={onTabClicked.bind(this, `/network/followers`)}
              />

              <TabItem
                $isActive={currentTab === "following"}
                label={
                  <TabLabel $isActive={currentTab === "following"}>
                    <img
                      src={
                        currentTab === "following"
                          ? FollowingIconActive
                          : FollowingIcon
                      }
                      alt={"Following"}
                    />
                    Following ({profile?.networkCount?.following})
                  </TabLabel>
                }
                value="following"
                onClick={onTabClicked.bind(this, `/network/following`)}
              />
              <TabItem
                $isActive={currentTab === "collaborators"}
                label={
                  <TabLabel $isActive={currentTab === "collaborators"}>
                    <img
                      src={
                        currentTab === "collaborators"
                          ? CollaboratorsIconActive
                          : CollaboratorsIcon
                      }
                      alt={"Collaborators"}
                    />
                    Collaborators ({profile?.networkCount?.collaborators})
                  </TabLabel>
                }
                value="collaborators"
                onClick={onTabClicked.bind(this, `/network/collaborators`)}
              />
            </Tabs>
          </>
        ) : (
          <MobileTabArea>
            {profile?.type === "individual" && (
              <>
                <MobileTab
                  $isActive={currentTab === "suggestions"}
                  onClick={() =>
                    onTabChangedMobile("suggestions", "/network/suggestions")
                  }
                >
                  <img
                    src={
                      currentTab === "suggestions"
                        ? SuggestionsIconActive
                        : SuggestionsIcon
                    }
                    alt={"suggestions"}
                  />
                </MobileTab>
              </>
            )}
            {profile?.type === "individual" && (
              <MobileTab
                $isActive={currentTab === "connections"}
                onClick={() =>
                  onTabChangedMobile("connections", "/network/connections")
                }
              >
                <img
                  src={
                    currentTab === "connections"
                      ? ConnectionsIconActive
                      : ConnectionsIcon
                  }
                  alt={"Connections"}
                />
              </MobileTab>
            )}
            <MobileTab
              $isActive={currentTab === "followers"}
              onClick={() =>
                onTabChangedMobile("followers", "/network/followers")
              }
            >
              <img
                src={
                  currentTab === "followers"
                    ? FollowersIconActive
                    : FollowersIcon
                }
                alt={"followers"}
              />
            </MobileTab>
            <MobileTab
              $isActive={currentTab === "following"}
              onClick={() =>
                onTabChangedMobile("following", "/network/following")
              }
            >
              <img
                src={
                  currentTab === "following"
                    ? FollowingIconActive
                    : FollowingIcon
                }
                alt={"following"}
              />
            </MobileTab>
            <MobileTab
              $isActive={currentTab === "collaborators"}
              onClick={() =>
                onTabChangedMobile("collaborators", "/network/collaborators")
              }
            >
              <img
                src={
                  currentTab === "collaborators"
                    ? CollaboratorsIconActive
                    : CollaboratorsIcon
                }
                alt={"collaborators"}
              />
            </MobileTab>
          </MobileTabArea>
        )}
      </TabsBox>
      <ContentBox>
        {currentTab === "suggestions" && <SuggestionsTab />}
        {currentTab === "profiles" && <IndividualsGroupsPage />}
        {currentTab === "connections" && <ConnectionsTab />}
        {currentTab === "followers" && <FollowersTab />}
        {currentTab === "following" && <FollowingTab />}
        {currentTab === "collaborators" && (
          <CollaboratorsTab
            onChangeCurrentCollaboratorsTab={onChangeCurrentCollaboratorsTab}
            currentCollaboratorsTab={currentCollaboratorsTab}
          />
        )}
      </ContentBox>
    </NetworkContainer>
  )
}

export default View
