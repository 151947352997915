import React, { MouseEventHandler, useEffect, useRef } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import {
  Hidden,
  List,
  ListItem,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  Box,
} from "@material-ui/core"

import {
  NavbarContainer,
  Logo,
  LogoContainer,
  Actions,
  ActionItem,
  NavBarButton,
  AvatarButton,
  StyledLink,
  MenuContainer,
  Drawer,
} from "./styles"

import { CommonNavbar } from "./common-navbar"

import LogoImage from "../../images/logo-charcoal.svg"
import Avatar from "../Avatar"
import InviteBell from "../InviteBell"
import { Profile, ProfileType } from "../../redux/profile"
import * as RouteConstants from "../../constants/routes"

import { ReferralDialog } from "../InviteFriendNavIcon/Dialog"
import { NetworkNavIcon } from "../NetworkNavIcon"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { AwardsNavItem } from "../AwardsNavItem"
import LiveIcon from "../../images/new-header/live-icon.svg"
import { useSelector } from "react-redux"
import { selectCompetitions } from "../../redux/competition"
import { JobsNavIcon } from "../JobsNavIcon"
import { NewPostNavIcon } from "../NewPostNavIcon"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"
import { ServicesNavItem } from "../ServicesNavItem"

export type Props = {
  profile?: Profile
  menuOpen: boolean
  onMenuClicked: MouseEventHandler
  goToUrl: Function
  logOut: MouseEventHandler
  onMenuClose: MouseEventHandler
  isDrawerOpen: boolean
  onDrawerOpen: () => void
  onDrawerClose: () => void
}

const View = ({
  profile,
  menuOpen,
  onMenuClicked,
  goToUrl,
  logOut,
  onMenuClose,
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
}: Props) => {
  const userButtonRef = useRef() as React.RefObject<HTMLDivElement>

  const [open, setOpen] = React.useState(false)

  const handleCloseDialog = () => setOpen(false)
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  useEffect(() => {
    if (!isMobile) {
      if (!document.getElementById("common-navigation-main")) {
        CommonNavbar()
      }
    }
  }, [isMobile])

  const competitions = useSelector(selectCompetitions)
  const liveCompetition = competitions?.find(
    (competition) => competition.status === "open"
  )

  return (
    <>
      <NavbarContainer aria-label="navigation bar">
        <ReferralDialog
          isOpen={open}
          onClose={handleCloseDialog}
          referralCode={profile?.referralCode || ""}
        />
        <LogoContainer>
          <RouterLink to="/">
            <>
              <Hidden smDown>
                <Logo
                  src={LogoImage}
                  alt="Spectacular"
                  aria-label="specatular logo"
                />
              </Hidden>
              <Hidden mdUp>
                <Logo
                  src={LogoImage}
                  alt="Spectacular"
                  aria-label="specatular logo"
                />
              </Hidden>
            </>
          </RouterLink>
        </LogoContainer>
        <MenuContainer>
          <Hidden smDown implementation="css">
            <Box display="flex" flexDirection="row">
              <AwardsNavItem />
              {liveCompetition && (
                <StyledLink
                  component={RouterLink}
                  to={liveCompetition.url}
                  aria-label="studio-awards"
                  activeClassName="link-active"
                  exact={true}
                >
                  {liveCompetition.displayTitle}{" "}
                  <img src={LiveIcon} alt="Live" />
                </StyledLink>
              )}
              <ServicesNavItem />
              <StyledLink
                component={RouterLink}
                to="/about"
                aria-label="about"
                activeClassName="link-active"
                exact={true}
              >
                About
              </StyledLink>
              <StyledLink
                component={RouterLink}
                to="/student-ambassador"
                aria-label="student-ambassador"
                activeClassName="link-active"
                exact={true}
              >
                Student Ambassador <img src={LiveIcon} alt="Live" />
              </StyledLink>
            </Box>
          </Hidden>
        </MenuContainer>
        <Hidden smDown implementation="css">
          <Actions>
            <ActionItem>
              <NewPostNavIcon />
            </ActionItem>

            <ActionItem>
              <NetworkNavIcon />
            </ActionItem>

            <ActionItem>
              <JobsNavIcon />
            </ActionItem>

            <ActionItem>
              <InviteBell
                hover
                isNav
                width={66}
                height={66}
                profile={profile!}
              />
            </ActionItem>

            <AvatarButton
              aria-controls="user-menu"
              aria-haspopup="true"
              aria-label="user menu"
              onClick={onMenuClicked}
              ref={userButtonRef}
            >
              <Avatar isNav width={32} height={32} profile={profile!} />
              My Profile
            </AvatarButton>
            <Menu
              id="user-menu"
              anchorEl={userButtonRef.current}
              keepMounted
              open={menuOpen}
              onClose={onMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              getContentAnchorEl={null}
              data-testid="user-menu"
              aria-label="user menu"
            >
              {profile?.isAdmin && (
                <MenuItem onClick={() => goToUrl("/admin")}>Admin</MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  goToUrl(
                    `${RouteConstants.PROFILE_CONTAINER}/${profile?.username}`
                  )
                }
              >
                {profile?.type === ProfileType.GROUP
                  ? "Firm profile"
                  : "My profile"}
              </MenuItem>
              {profile?.type === ProfileType.GROUP && (
                <MenuItem onClick={() => goToUrl("/dashboard")}>
                  Jobs Dashboard
                </MenuItem>
              )}
              <MenuItem
                onClick={() => goToUrl(`/sp/${profile?.username}/posts`)}
              >
                My Posts
              </MenuItem>
              <MenuItem
                onClick={() => goToUrl(`/sp/${profile?.username}/projects`)}
              >
                My Projects
              </MenuItem>
              <MenuItem onClick={() => goToUrl("/profile/edit")}>
                Edit profile
              </MenuItem>
              <MenuItem onClick={logOut}>Sign out</MenuItem>
            </Menu>
          </Actions>
        </Hidden>
        <Hidden mdUp implementation="css">
          <NavBarButton color="primary" onClick={onDrawerOpen}>
            Menu
          </NavBarButton>
        </Hidden>
        <Drawer anchor="right" open={isDrawerOpen} onClose={onDrawerClose}>
          <List style={{ width: "180px" }}>
            <ListItem>
              <Avatar isNav width={48} height={48} profile={profile!} />
            </ListItem>
            {profile?.isAdmin && (
              <>
                <ListItem button onClick={() => goToUrl("/admin")}>
                  <ListItemText primary="Admin" />
                </ListItem>
                <Divider />
              </>
            )}
            <ListItem button onClick={() => goToUrl("/")}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => goToUrl("/about")}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button onClick={() => goToUrl("/jobs")}>
              <ListItemText primary="Jobs" />
            </ListItem>
            <ListItem button onClick={() => goToUrl("/student-ambassador")}>
              <ListItemText
                primary={
                  <>
                    Student Ambassador <img src={LiveIcon} alt="Live" />
                  </>
                }
              />
            </ListItem>
            {liveCompetition && (
              <ListItem button onClick={() => goToUrl(liveCompetition.url)}>
                <ListItemText
                  primary={
                    <>
                      {liveCompetition.displayTitle}{" "}
                      <img src={LiveIcon} alt="Live" />
                    </>
                  }
                />
              </ListItem>
            )}
            <ListItem button onClick={() => goToUrl("/firm-spotlight")}>
              <ListItemText primary="Firm Spotlight" />
            </ListItem>

            {environment.device === "desktop" ? (
              <ListItem
                button
                onClick={() =>
                  window.open("https://www.spectacular.design/recruitment")
                }
              >
                <ListItemText primary="Recruitment" />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={async () => {
                  await Browser.open({
                    url: "https://www.spectacular.design/recruitment",
                    windowName: "_self",
                  })
                }}
              >
                <ListItemText primary="Recruitment" />
              </ListItem>
            )}

            {environment.device === "desktop" ? (
              <ListItem
                button
                onClick={() =>
                  window.open("https://www.spectacular.design/advertising")
                }
              >
                <ListItemText primary="Advertising" />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={async () => {
                  await Browser.open({
                    url: "https://www.spectacular.design/advertising",
                    windowName: "_self",
                  })
                }}
              >
                <ListItemText primary="Advertising" />
              </ListItem>
            )}

            <Divider />
            <ListItem
              button
              onClick={() =>
                goToUrl(
                  `${RouteConstants.PROFILE_CONTAINER}/${profile?.username}`
                )
              }
            >
              <ListItemText
                primary={
                  profile?.type === ProfileType.GROUP
                    ? "Firm profile"
                    : "My profile"
                }
              />
            </ListItem>
            {profile?.type === ProfileType.GROUP && (
              <ListItem button onClick={() => goToUrl("/dashboard")}>
                <ListItemText primary="Jobs Dashboard" />
              </ListItem>
            )}
            <ListItem button onClick={() => goToUrl("/profile/edit")}>
              <ListItemText primary="Edit profile" />
            </ListItem>
            <ListItem
              button
              onClick={() => goToUrl(`/sp/${profile?.username}/posts`)}
            >
              <ListItemText primary="My Posts" />
            </ListItem>
            <ListItem
              button
              onClick={() => goToUrl(`/sp/${profile?.username}/projects`)}
            >
              <ListItemText primary="My Projects" />
            </ListItem>
            <ListItem button onClick={() => goToUrl(`/network`)}>
              <ListItemText primary="Network" />
            </ListItem>
            <ListItem button onClick={() => setOpen(true)}>
              <ListItemText primary="Invite" />
            </ListItem>
            <ListItem button onClick={() => goToUrl("/notifications")}>
              <ListItemText primary="Notifications" />
            </ListItem>
            <ListItem button onClick={logOut}>
              <ListItemText primary="Sign out" />
            </ListItem>
          </List>
        </Drawer>
      </NavbarContainer>
    </>
  )
}

export default View
