import React, { useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../../redux/configureStore"
import { fetchNetworkCollaboratorsPage } from "../../../../redux/connections"
import View from "./View"

type Props = {
  onChangeCurrentCollaboratorsTab: (tab: string, url: string) => void
  currentCollaboratorsTab: string
}

const CollaboratorsTab = ({
  onChangeCurrentCollaboratorsTab,
  currentCollaboratorsTab,
}: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const collaborators = useSelector(
    (state: any) => state.connections.collaborators
  )
  const collaboratorsCount = useSelector(
    (state: any) => state.connections.collaboratorsCount
  )
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const loadCollaborators = useCallback(async () => {
    try {
      const { payload, type } = await dispatch(
        fetchNetworkCollaboratorsPage({
          auth,
          perPage: 10,
          page,
          type: currentCollaboratorsTab,
          search,
        })
      )

      if (fetchNetworkCollaboratorsPage.fulfilled.type === type) {
        setIsLoading(false)
        if (payload.data.length >= payload.count || payload.data.length === 0)
          setHasMore(false)
        else setHasMore(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, page, currentCollaboratorsTab, search, auth])

  const handleUpdateHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangePage = (value: number) => {
    setPage(value)
  }

  const handleChangeType = (value: string, url: string) => {
    onChangeCurrentCollaboratorsTab(value, url)
    setPage(1)
  }

  const handleChangeSearch = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  useEffect(() => {
    loadCollaborators()
  }, [loadCollaborators])

  return (
    <View
      collaborators={collaborators}
      collaboratorsCount={collaboratorsCount}
      hasMore={hasMore}
      type={currentCollaboratorsTab}
      onChangeType={handleChangeType}
      onUpdateHasMore={handleUpdateHasMore}
      page={page}
      onUpdatePage={handleChangePage}
      handleChangeSearch={handleChangeSearch}
      search={search}
      isLoading={isLoading}
    />
  )
}

export default CollaboratorsTab
