import React from "react"

import View from "./NewView"

type Props = {
  error?: string
  keyMaterials: string[]
  onChange: (newValues: string[]) => void
}

const suggestions = [
  "Steel",
  "Concrete",
  "Aluminum",
  "Masonry - Brick",
  "Masonry - Stone",
  "Glass",
  "Heavy Timber",
  "Light Frame Timber",
  "Terra Cotta",
  "EIFs",
  "Stucco",
  "Other",
]

const ProjectKeyMaterials = ({ keyMaterials, onChange, error }: Props) => {
  const handleChange = (newMaterial: string) => {
    let noOtherMaterial = false
    for (let item in suggestions) {
      if (suggestions[item] === newMaterial) noOtherMaterial = true
    }

    if (noOtherMaterial) {
      if (keyMaterials.find((material) => material === newMaterial)) {
        onChange(keyMaterials.filter((material) => material !== newMaterial))
      } else {
        onChange([...keyMaterials, newMaterial])
      }
    } else {
      const existingOther = keyMaterials.filter((e) => {
        let currMaterial = true
        for (let item in suggestions) {
          if (suggestions[item] === e) currMaterial = false
        }
        return currMaterial
      })

      const existingOtherStr = existingOther.toString()

      let updatedMaterials = keyMaterials.filter(
        (item) => !item.includes(existingOtherStr)
      )

      if (!existingOtherStr)
        updatedMaterials = keyMaterials.filter((item) => item)

      onChange(
        [...updatedMaterials, newMaterial].filter(
          (material) => material.length > 0
        )
      )
    }
  }

  return (
    <View error={error} keyMaterials={keyMaterials} onChange={handleChange} />
  )
}

export default ProjectKeyMaterials
