import React from "react"
import styled from "styled-components"
import { Box, Grid } from "@material-ui/core"
import theme, { colors } from "../../theme"
import { Link } from "react-router-dom"

export const Author = styled(({ ...props }) => <Link {...props} />)`
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  line-height: 22px;
  color: ${colors.dark};
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: inherit;
  }
`

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const Title = styled(Box)`
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: inherit;
  }
`

export const Location = styled(({ ...props }) => <Link {...props} />)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.mediumLightGray};
  text-decoration: underline;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
    color: ${colors.mediumLightGray};
  }
`

export const Avatar = styled.img<{ $isTempPicture?: boolean }>`
  width: 124px;
  height: 124px;
  object-fit: ${({ $isTempPicture }) => ($isTempPicture ? "contain" : "cover")};

  ${theme.breakpoints.down("xs")} {
    width: 40.97px;
    height: 40.97px;
  }
`

export const ProjectThumbnail = styled.img`
  width: 165.33px;
  height: 124px;
  object-fit: cover;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    aspect-ratio: 4/3;
    height: auto;
  }
`

export const ProjectThumbnailPlaceholder = styled.div`
  width: 165.33px;
  height: 124px;
  background-color: #f7f7f7;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    aspect-ratio: 4/3;
    height: auto;
  }
`

export const CardBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    flex-flow: wrap;
    max-height: initial;
  }
`

export const ContentBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.down("xs")} {
    flex-flow: initial;
    align-items: center;
  }
`

export const NetworkingBox = styled(Box)<{ $isGroup?: boolean }>`
  display: flex;
  gap: 24px;
  margin-top: auto;

  && button {
    width: 108px !important;
    height: 32px !important;
  }

  ${theme.breakpoints.down("xs")} {
    height: initial;
    margin-left: auto;
    display: initial;
    min-width: ${({ $isGroup }) => ($isGroup ? "78px" : "164px")};
    justify-content: flex-end;
    display: flex;
    gap: 0;

    && button {
      width: 78px !important;
      height: 30px !important;
      font-size: 12px !important;
    }
  }
`

export const ImagesBox = styled(Grid)`
  ${theme.breakpoints.down("xs")} {
    margin-top: 12px;
  }
`
