import { Box } from "@material-ui/core"
import React from "react"
import { Button } from "../../components"
import { Profile } from "../../redux/profile"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { LinkBox } from "../ProjectCard/styles"
import {
  Avatar,
  ButtonBox,
  ButtonIcon,
  CardBox,
  LocationText,
  ProfileSubTitle,
  ProfileTitle,
} from "./styles"

import TrashIcon from "../../images/network/icon-trash.svg"
import TrashIconHover from "../../images/network/icon-trash-hover.svg"
import RemoveConnectionDialog from "../../components/RemoveConnectionDialog"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

export type Props = {
  profile: Profile
  goToProfile: () => string
  onRemove: (data: Profile) => void
  open: boolean
  onOpen: () => void
  onClose: () => void
}

const View = ({
  profile,
  goToProfile,
  onRemove,
  open,
  onOpen,
  onClose,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const [hover, setHover] = React.useState(false)

  let name = profile?.groupName
  if (profile?.type !== "group")
    name = profile?.firstName + " " + profile?.lastName

  let jobTitle = profile?.jobTitle
  if (profile?.jobTitle && profile.jobTitle.includes("Other: ")) {
    jobTitle = profile.jobTitle.replace("Other: ", "")
  }

  return (
    <CardBox>
      <LinkBox to={goToProfile}>
        <Avatar
          src={
            profile.smallImageUrl
              ? profile.smallImageUrl
              : getRandomPlaceholder(profile.placeholderAvatar!)
          }
          alt={"Avatar"}
        />
      </LinkBox>
      <Box width={"100%"}>
        <LinkBox to={goToProfile}>
          <ProfileTitle>{name}</ProfileTitle>
        </LinkBox>
        <ProfileSubTitle>
          {profile.type === "group" ? (
            <>Company Size: {profile.companySize}</>
          ) : (
            <>
              {isMobile ? (
                jobTitle
              ) : (
                <>
                  {jobTitle} {profile?.company ? "at " + profile?.company : ""}
                </>
              )}
            </>
          )}
        </ProfileSubTitle>
        {profile.location && !isMobile && (
          <LinkBox to={"/profiles?location=" + profile.location}>
            <LocationText>{profile.location}</LocationText>
          </LinkBox>
        )}
      </Box>
      <ButtonBox>
        <Button
          onClick={onOpen}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          children={
            <>
              <ButtonIcon
                src={hover ? TrashIconHover : TrashIcon}
                alt={"Remove Connection"}
              />{" "}
              Remove Connection
            </>
          }
          color={"whiteGreyBorder"}
          hoverColor={"primary"}
          size={"medium"}
          style={{
            width: "220px",
            height: "48px",
            fontSize: "14px",
          }}
        />
      </ButtonBox>
      <RemoveConnectionDialog
        open={open}
        onClose={onClose}
        profile={profile}
        onRemove={onRemove}
      />
    </CardBox>
  )
}

export default View
