import styled from "styled-components"
import { Typography, Link } from "@material-ui/core"
import { colors } from "../../theme"

export const RequestDialogText = styled(Typography)`
  font-size: 16px;
  color: ${colors.dark};
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #717171;
  margin-left: 24px;
  font-size: 14px;
  font-weight: normal;
  border-bottom: 0.5px solid;
  padding-bottom: 2px;
`
