import React from "react"

import { useHistory } from "react-router-dom"
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next"

import env from "../../environment"
import { SegmentContext } from "./Context"

interface Props {
  children: React.ReactNode
}

export const SegmentProvider: React.FC<Props> = ({ children }) => {
  const { listen } = useHistory()
  const [analytics, setAnalytics] = React.useState<Analytics | undefined>(
    undefined
  )

  const title =
    window.location.pathname === "/"
      ? "Home"
      : window.location.pathname.charAt(1).toUpperCase() +
        window.location.pathname.slice(2).replace("_", " ")

  React.useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey: env.segmentKey })
      setAnalytics(response)
    }
    loadAnalytics()
  }, [])

  React.useEffect(() => {
    const unlisten = listen((_) => {
      if (!analytics) return

      console.log(`tracking...`)

      analytics.page()
      analytics.track("page_view", {
        page_title: title,
      })
    })

    return unlisten
  }, [listen, title, analytics])

  return (
    <SegmentContext.Provider value={{ analytics }}>
      {children}
    </SegmentContext.Provider>
  )
}
