import React from "react"
import { Box, Typography } from "@material-ui/core"

import { Props } from "./"

import { LocationText, AboutSelfText } from "../Styles"
import Avatar from "../../../../components/Avatar"
import FollowButton from "../../../../components/FollowButton"
import LocationIcon from "../../../../images/location-icon.svg"
import SimpleAddress from "../../../../components/SimpleAddress"

const DesktopView: React.FC<Props> = ({ profile, goToUrl }) => {
  const isPlural = (value: number | undefined) => {
    if (value !== undefined && (value === 0 || value > 1)) return true
    else return false
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          maxWidth: "242px",
          minWidth: "242px",
        }}
      >
        <Avatar border width={232} height={232} profile={profile} />
      </div>
      <div style={{ maxWidth: "46vw", marginTop: "98px" }}>
        <Box style={{ paddingLeft: "40px" }}>
          <Typography
            variant="h3"
            style={{
              fontSize: "25px",
              lineHeight: "30px",
              paddingBottom: "16px",
            }}
          >
            {profile.groupName}
          </Typography>

          <Typography
            style={{
              fontSize: "16px",
              marginTop: "-4px",
              marginBottom: "-4px",
              paddingBottom: "16px",
            }}
          >
            {profile.companySize && (
              <>
                {profile.companySize} employees{" "}
                <span style={{ fontSize: "10px" }}>• </span>
              </>
            )}
            {profile.followersCount} follower
            {isPlural(profile.followersCount) ? "s " : " "}
            {profile.location && (
              <>
                <span style={{ fontSize: "10px" }}>•</span>
                <div style={{ display: "inline" }}>
                  <img
                    alt="location"
                    src={LocationIcon}
                    style={{
                      height: "11px",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                  <LocationText
                    tabIndex={0}
                    onClick={() =>
                      goToUrl(`/profiles?location=${profile.location}`)
                    }
                  >
                    {SimpleAddress({
                      profileType: profile!.type,
                      location: profile.location,
                    })}
                  </LocationText>
                </div>
              </>
            )}
          </Typography>

          {profile.aboutSelf && (
            <Box>
              <AboutSelfText tabIndex={0} variant="body2" style={{}}>
                {profile.aboutSelf}
              </AboutSelfText>
            </Box>
          )}
        </Box>
      </div>
      <div style={{ alignSelf: "flex-end", marginLeft: "auto" }}>
        <div style={{ marginTop: "106px", float: "right" }}>
          <FollowButton
            profile={profile}
            size="small"
            style={{ color: "white", margin: "0", minWidth: "99px" }}
          />
        </div>
      </div>
    </div>
  )
}

export default DesktopView
