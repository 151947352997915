export const phoneValidation = (value: string) => {
  let newValue = value
  if (newValue.length <= 20) {
    newValue = newValue
      // eslint-disable-next-line
      .replace(/[^0-9\+]/g, "")
      .replace(/^(\+)|\++/g, "$1")
      .replace(/^([^.]*\.)|\.+/g, "$1")
  } else {
    newValue = newValue.substr(0, 20)
  }

  return newValue
}
