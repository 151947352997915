import React from "react"
import { Helmet } from "react-helmet"
import View from "./View"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const PressPage = () => {
  setTimeout(() => {
    // @ts-ignore
    window.prerenderReady = true
  }, 0)

  return (
    <>
      <RouteChangeTracker screenTitle={`Press`} classTitle="Press" />
      <Helmet>
        <title>Press - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Press - Spectacular`} />
        <meta name="title" content={`Press - Spectacular`} />
      </Helmet>
      <View />
    </>
  )
}

export default PressPage
