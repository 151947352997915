import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { useAuth0 } from "@auth0/auth0-react"
import { updateFeedState } from "../../redux/feed"
import { News } from "../../redux/adminNews"
import { dislikeNews, likeNews } from "../../redux/news"
import { logEvent } from "../../utils/analytics"

type Props = {
  news: News
}

const NewsLikeIcon = ({ news }: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)

  const handleLikeClick = async (like: boolean): Promise<void> => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    if (like) {
      handleNewsDislike()
    } else {
      handleNewsLike()
    }
  }

  const handleNewsLike = async () => {
    setDispatchComplete(false)
    try {
      const likeStatus = await dispatch(likeNews({ auth, id: news.id! }))
      if (likeStatus.type === likeNews.fulfilled.type) {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "news",
            id: news.id!,
            action: "like",
          })
        )

        await logEvent("news_like")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleNewsDislike = async () => {
    setDispatchComplete(false)
    try {
      const disLikeStatus = await dispatch(dislikeNews({ auth, id: news.id! }))
      if (disLikeStatus.type === dislikeNews.fulfilled.type) {
        setDispatchComplete(true)

        await dispatch(
          updateFeedState({
            type: "news",
            id: news.id!,
            action: "dislike",
          })
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      news={news}
      handleLikeClick={handleLikeClick}
    />
  )
}

export default NewsLikeIcon
