import styled from "styled-components"
import { Box } from "@material-ui/core"

export const DotButton = styled(Box)`
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background-color: #d9d9d9;
  }

  && img {
    border-radius: 0 !important;
    display: flex;
    width: 18px;
  }
`
export const Triangle = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 6px;
    right: -2px;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const MenuItem = styled.div`
  color: #717171;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  && img {
    width: 16px;
  }
  &:hover {
    background-color: #d8d8d8;
    font-family: Public-Sans-Bold;
  }
`

export const MenuBox = styled.div`
  width: 133px;
  padding: 10px 0;
  font-size: 12px;
  box-shadow: 0px -2px 15px rgba(45, 45, 45, 0.1);
  background-color: #fff;
  margin-top: 16px;
  border-radius: 5px;
`
