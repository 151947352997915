import React from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { Notification } from "../../redux/bellNotifications"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import NotificationLikeIcon from "../../images/notify-heart.svg"
import NotificationCommentIcon from "../../images/notify-comment.svg"
import NotificationShareIcon from "../../images/notify-link.svg"
import NewNotificationIcon from "../../images/new-notification.svg"
import ReadNotificationIcon from "../../images/new-notification-white.svg"
import * as RouteConstants from "../../constants/routes"
import moment from "moment"
import {
  NotificationBox,
  AvatarBox,
  DescriptionBox,
  AvatarIcon,
  NotificationAvatar,
} from "../NotificationsBell/styles"
import theme from "../../theme"
import getFromBetween from "../../utils/getStringFromBetween"

export type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  goToNotifications?: boolean
}

const View = ({
  notification,
  goToNotifications,
  onNotificationClick,
}: Props) => {
  const profileLink = `${RouteConstants.PROFILE_CONTAINER}/${notification?.profile?.username}`
  const postLink = `/posts/${notification?.post?.id}`

  const getCommentText = () => {
    let newText = notification.comment
    if (notification.comment) {
      const separatedMentions = getFromBetween.get(
        notification.comment,
        "@{{",
        ")}}"
      )
      if (separatedMentions.length > 0) {
        separatedMentions.forEach((value: string) => {
          const arr1 = value.split("}}")
          const mention = "@" + arr1[0]
          const fullString = "@{{" + value + ")}}"
          newText = newText.replace(fullString, mention)
        })
      }
    }
    return newText ? newText : ""
  }

  const STR_SIZE = theme.breakpoints.down("xs") ? 55 : 70
  const STR_SIZE_REPLY = theme.breakpoints.down("xs") ? 44 : 59

  const history = useHistory()

  const goToURL = (url: string) => {
    if (goToNotifications) {
      history.push("/notifications")
    } else {
      onNotificationClick(notification.id)
      history.push(url)
    }
  }

  const getNotificationIcon = (origin?: string) => {
    if (
      origin === "postComment" ||
      origin === "postCommentReply" ||
      origin === "postCommentMention" ||
      origin === "postLikeComment" ||
      origin === "postMention"
    )
      return NotificationCommentIcon
    if (origin === "postLike") return NotificationLikeIcon
    if (origin === "postShare") return NotificationShareIcon
  }

  const getNotificationDescription = (origin?: string) => {
    if (origin === "postComment")
      return getCommentText().length! > STR_SIZE
        ? `commented: ${getCommentText().substr(0, STR_SIZE)}...`
        : `commented: ${getCommentText()}.`
    if (origin === "postCommentReply")
      return getCommentText().length! > STR_SIZE_REPLY
        ? `replied your comment: ${getCommentText().substr(
            0,
            STR_SIZE_REPLY
          )}...`
        : `replied your comment: ${getCommentText()}.`
    if (origin === "postShare") return "shared your post."
    if (origin === "postLike") return "liked your post."
    if (origin === "postCommentMention") return "mentioned you in a comment."
    if (origin === "postMention") return "mentioned you in a post."
    if (origin === "postLikeComment") return "liked your comment."
  }

  return (
    <NotificationBox>
      <AvatarBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <NotificationAvatar
          src={
            notification?.profile?.smallImageUrl === undefined
              ? getRandomPlaceholder(notification?.profile?.placeholderAvatar!)
              : (notification?.profile?.smallImageUrl as string)
          }
          alt={"notification"}
        />

        <AvatarIcon
          src={getNotificationIcon(notification?.origin)}
          alt={"notification"}
        />
      </AvatarBox>
      <DescriptionBox
        onClick={() => {
          goToURL(postLink)
        }}
      >
        <Box style={{ marginTop: "-10px" }}>
          <b>
            {notification?.profile?.type === "group" ? (
              <>{notification?.profile?.groupName}</>
            ) : (
              <>
                {notification?.profile?.firstName}{" "}
                {notification?.profile?.lastName}
              </>
            )}
          </b>
          &nbsp;
          {getNotificationDescription(notification?.origin)}
          &nbsp;
          <span style={{ color: "#717171" }}>
            {moment(notification?.createdAt).fromNow(true).replace(" ", "")}
          </span>
        </Box>
      </DescriptionBox>
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
        <img
          alt={"notification-state"}
          src={
            notification?.state !== "clicked"
              ? NewNotificationIcon
              : ReadNotificationIcon
          }
          width={"8px"}
          height={"8px"}
          style={{ marginTop: "-10px" }}
        />
      </Box>
    </NotificationBox>
  )
}

export default View
