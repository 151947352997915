import React from "react"
import { Popover } from "@material-ui/core"

import { JobDialog } from "./Dialog"

import DotMenu from "../../images/dot-menu.svg"
import { useWindowDimensions } from "../../hooks"
import { JobListing } from "../../redux/jobListings"

import { Triangle, MenuBox, MenuItem, DotBox } from "./Styles"
import { useHistory } from "react-router-dom"

export const JobsCardDropdown: React.FC<{
  job: JobListing
  onClose: () => void
  onDelete: () => void
}> = ({ job, onDelete, onClose }) => {
  const { width } = useWindowDimensions()

  const history = useHistory()

  const isMobile = width < 960

  const [deleteDialog, setDeleteDialog] = React.useState(false)

  const [closeDialog, setCloseDialog] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const dotMenuOpen = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget)

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <>
      <DotBox onClick={handleClick}>
        <img
          src={DotMenu}
          alt={"dot-menu"}
          style={{ width: "14px", height: "3.5px" }}
        />
      </DotBox>

      <Popover
        open={dotMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "4px",
            paddingRight: "15px",
            paddingBottom: "15px",
            background: "transparent",
            marginLeft: isMobile ? "25px" : "18px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          {job.status === "open" ? (
            <>
              <MenuItem
                onClick={() => goToUrl(`/job_listings/${job.id!}/edit`)}
              >
                Edit job
              </MenuItem>
              <MenuItem onClick={() => setCloseDialog(true)}>
                Close job
              </MenuItem>{" "}
            </>
          ) : (
            <MenuItem
              onClick={() => goToUrl(`/job_listings/${job.id!}/repost`)}
            >
              Repost job
            </MenuItem>
          )}
          <MenuItem onClick={() => setDeleteDialog(true)}>Delete job</MenuItem>
        </MenuBox>
      </Popover>

      <JobDialog
        type="close"
        onConfirm={onClose}
        isOpen={closeDialog}
        onClose={() => setCloseDialog(false)}
      />

      <JobDialog
        type="delete"
        onConfirm={onDelete}
        isOpen={deleteDialog}
        onClose={() => setDeleteDialog(false)}
      />
    </>
  )
}
