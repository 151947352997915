import React from "react"

import { ActionButton, Counter, Icon } from "./styles"

import IconActive from "../../images/new-homepage/icons/comment-active.svg"
import IconDefault from "../../images/new-homepage/icons/comment-default.svg"
import IconHover from "../../images/new-homepage/icons/comment-hover.svg"

import { Project } from "../../redux/projects"

export type Props = {
  project: Project
  onCommentsClicked: () => void
}

const View = ({ project, onCommentsClicked }: Props) => {
  const [hover, setHover] = React.useState(false)

  const renderIcon = (commented?: boolean) => {
    if (commented) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  return (
    <ActionButton
      onClick={onCommentsClicked}
      data-testid="commentsButton"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon src={renderIcon(project.commented)} alt={"comment"} />
      <Counter $isActive={project.commented || false}>
        {project.commentsCount}{" "}
        {project.commentsCount === 1 ? "comment" : "comments"}
      </Counter>
    </ActionButton>
  )
}

export default View
