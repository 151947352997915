import React from "react"

import Header from "./Header"

import {
  MainContainer,
  RegistrationBox,
  RegistrationContainer,
  RegistrationFlexBox,
  RegistrationLeftBox,
  RegistrationItem,
  RegistrationSubTitle,
  RegistrationDivider,
  RegistrationButtonBox,
  RegistrationItemNoIcon,
} from "./styles"

import { Button } from "../../components"

interface Props {
  isAuthenticated: boolean
}

const View = ({ isAuthenticated }: Props) => {
  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <Header />

      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <RegistrationLeftBox>
              <RegistrationItem>
                <b>Increase</b> profile traffic & job applications by featuring
                your firm at the top of Spectacular’s homepage.
              </RegistrationItem>
              <RegistrationItem>
                <b>Attract</b> op talent and boost your following through
                targeted email marketing.
              </RegistrationItem>
              <RegistrationItem>
                <b>Enhance</b> your brand’s visibility through Spectacular’s
                powerful social media channels.
              </RegistrationItem>
              <RegistrationItemNoIcon>
                The Spectacular Firm Spotlight is currently in beta and will be
                offered complimentarily through the end of 2023. This is a
                limited time offer, so take advantage of this opportunity while
                it lasts!
              </RegistrationItemNoIcon>
              <RegistrationDivider />
              <RegistrationSubTitle>
                Email{" "}
                <a href="mailto:sethkopka@blackspectacles.com">
                  sethkopka@blackspectacles.com
                </a>{" "}
                to learn more
              </RegistrationSubTitle>

              <RegistrationButtonBox>
                <a href="mailto:sethkopka@blackspectacles.com">
                  <Button
                    children={"Email Seth"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                </a>
              </RegistrationButtonBox>
            </RegistrationLeftBox>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>
    </MainContainer>
  )
}

export default View
