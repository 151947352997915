import React from "react"
import { Box, Typography } from "@material-ui/core"

import { Props } from "./"
import { LocationText } from "../../styles"

import Avatar from "../../../../components/Avatar"
import FollowButton from "../../../../components/FollowButton"
import LocationIcon from "../../../../images/location-icon.svg"
import SimpleAddress from "../../../../components/SimpleAddress"

const MobileView: React.FC<Props> = ({ profile, goToUrl }) => {
  const isPlural = (value: number | undefined) => {
    if (value !== undefined && (value === 0 || value > 1)) return true
    else return false
  }

  return (
    <>
      <Box
        marginTop={3}
        style={{
          display: "flex",
          textAlign: "center",
          alignSelf: "flex-end",
          justifyContent: "center",
        }}
      >
        <Avatar border width={164} height={164} profile={profile} />
      </Box>

      <Box style={{ textAlign: "center", paddingTop: "20px" }}>
        <Box>
          <Typography
            variant="h3"
            style={{
              fontSize: "25px",
              marginTop: "-10px",
              marginBottom: "-6px",
            }}
          >
            {profile.groupName}
          </Typography>
        </Box>

        {profile.companySize && (
          <Box style={{ paddingTop: "16px" }}>
            <Typography
              style={{
                fontSize: "16px",
                color: "#2d2d2d",
                marginTop: "-5px",
                marginBottom: "-5px",
                lineHeight: "22px",
              }}
            >
              {profile.companySize} employees
            </Typography>
          </Box>
        )}

        <Box style={{ paddingTop: "8px" }}>
          <Typography
            style={{
              fontSize: "16px",
              color: "#2d2d2d",
              marginTop: "-5px",
              marginBottom: "-5px",
              lineHeight: "22px",
            }}
          >
            {profile.followersCount} follower
            {isPlural(profile.followersCount) ? "s " : " "}
          </Typography>
        </Box>

        {profile.location && (
          <Box
            style={{
              display: "flex",
              paddingTop: "8px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LocationText
              tabIndex={0}
              style={{ fontSize: "14px" }}
              onClick={() => goToUrl(`/profiles?location=${profile.location}`)}
            >
              <img
                alt="location"
                src={LocationIcon}
                style={{ height: "11px", marginTop: "3px", marginRight: "6px" }}
              />
              {SimpleAddress({
                profileType: profile!.type,
                location: profile.location,
              })}
            </LocationText>
          </Box>
        )}
      </Box>

      <Box
        style={{
          width: "100%",
          display: "flex",
          paddingTop: "24px",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FollowButton
          profile={profile}
          style={{ width: "100%", maxWidth: "100%", height: "48px" }}
        />
      </Box>
    </>
  )
}

export default MobileView
