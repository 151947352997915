import React from "react"
import { Box } from "@material-ui/core"
import CloseIcon from "../../../images/x-default.svg"
import CloseIconHover from "../../../images/x-hover.svg"
import ArrowRightDesktop from "../../../images/new-homepage/carousel-arrow-right.svg"
import ArrowLeftDesktop from "../../../images/new-homepage/carousel-arrow-left.svg"

import {
  DialogButtonBox,
  DialogCloseButton,
  FlagBox,
  FlagItem,
  FlagItemBox,
  ImageThumbnail,
  PostDialog,
  PostDialogBox,
  PostDialogTitle,
} from "./DialogStyles"

import "./fix-dialog.css"
import moment from "moment"
import Carousel from "react-material-ui-carousel"
import { Button } from "../../../components"
import { Project, ProjectFlag } from "../../../redux/projects"

export type Props = {
  project: Project
  onClose: () => void
  open: boolean
  onSubmit: (value: string) => void
}

const Dialog = ({ project, onClose, open, onSubmit }: Props) => {
  const [hover, setHover] = React.useState(false)

  const name =
    project.profile?.type === "group"
      ? project.profile.groupName
      : project.profile?.firstName + " " + project.profile?.lastName

  let navButtonStyled = {
    backgroundColor: "rgba(255,255,255, 0.9)",
    zIndex: 5,
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "-10px 20px 0",
  }

  let ArrowRight = ArrowRightDesktop
  let ArrowLeft = ArrowLeftDesktop

  const getLabel = (flag: ProjectFlag) => {
    if (flag.reason === "spam") return "It is spam."

    if (flag.reason === "offensive")
      return "It is offensive, discriminatory, or threatening."

    if (flag.reason === "violent") return "It is violent or incites violence."

    if (flag.reason === "copyright") return "It violates copyright."

    if (flag.reason === "obscene") return "It contains obscene or lewd content."

    if (flag.reason === "other") return flag.otherDescription
  }

  const handleReprove = () => {
    onSubmit("reproved")
    onClose()
  }

  const handleApprove = () => {
    onSubmit("approved")
    onClose()
  }

  return (
    <PostDialog
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
      className={"AdminProjectReviewDialog"}
    >
      <PostDialogTitle>
        <Box>Review Project Flag</Box>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </PostDialogTitle>
      <PostDialogBox>
        <Box>
          <b>Author:</b> {name}
        </Box>
        <Box paddingTop={1}>
          <b>Created date:</b>{" "}
          {moment(project.createdAt).format("MMMM d, YYYY")}
        </Box>

        <Box paddingTop={1} paddingBottom={2}>
          <b>Flags:</b> {project.flags?.length}
          <FlagBox>
            {project?.flags?.map((flag) => {
              let reviewerName = ""
              let reviewDate = ""
              if (flag.reviewer) {
                reviewerName =
                  flag.reviewer.type === "group"
                    ? flag.reviewer.groupName!
                    : flag.reviewer.firstName + " " + flag.reviewer.lastName

                reviewDate = moment(flag.reviewedAt).format("MMMM d, YYYY")
              }

              const reporterName =
                flag.profile.type === "group"
                  ? flag.profile.groupName!
                  : flag.profile.firstName + " " + flag.profile.lastName

              return (
                <FlagItemBox>
                  <FlagItem>
                    <span>
                      <b>Reason: </b>
                      {getLabel(flag)}
                    </span>
                    <span>
                      <b>Date: </b>
                      {moment(flag.createdAt).format("MMMM d, YYYY")}
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      <b>Status: </b>
                      {flag.status}
                    </span>
                  </FlagItem>
                  <FlagItem>
                    <span>
                      <b>Reported by:</b> {reporterName}
                    </span>
                    {reviewerName && reviewDate && (
                      <>
                        <span>
                          <b>Reviewer: </b>
                          {reviewerName}
                        </span>
                        <span>
                          <b>Reviewed At: </b>
                          {reviewDate}
                        </span>
                      </>
                    )}
                  </FlagItem>
                </FlagItemBox>
              )
            })}
          </FlagBox>
        </Box>

        {project.images && project.images.length > 0 && (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Box width={"500px"} paddingTop={1} paddingBottom={2}>
              <Carousel
                navButtonsAlwaysVisible={true}
                animation={"slide"}
                interval={0}
                NextIcon={<img src={ArrowRight} alt={"Arrow"} />}
                PrevIcon={<img src={ArrowLeft} alt={"Arrow"} />}
                indicatorIconButtonProps={{
                  style: {
                    color: "#D8D8D8",
                    visibility: "visible",
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: "#3057E1",
                    visibility: "visible",
                  },
                }}
                indicatorContainerProps={{
                  style: {
                    marginTop: "-40px",
                    position: "absolute",
                    zIndex: 5,
                  },
                }}
                navButtonsProps={{
                  style: {
                    ...navButtonStyled,
                    visibility: "visible",
                  },
                }}
                autoPlay={false}
              >
                {project.images.map((image) => {
                  return (
                    <div key={image.id}>
                      <ImageThumbnail
                        src={image.thumbnailImageUrl}
                        alt={"Project"}
                      />
                    </div>
                  )
                })}
              </Carousel>
            </Box>
          </Box>
        )}

        {project.description && (
          <Box paddingTop={2} paddingBottom={2} whiteSpace={"pre-line"}>
            <b>Content: </b>
            <br />
            {project.description}
          </Box>
        )}

        <DialogButtonBox>
          <Button
            onClick={handleReprove}
            children={"Remove Project"}
            color={"red"}
            size={"medium"}
            style={{
              width: "100%",
            }}
          />
          <Button
            onClick={handleApprove}
            children={"Restore Project"}
            color={"secondary"}
            size={"medium"}
            style={{
              width: "100%",
            }}
          />
        </DialogButtonBox>
      </PostDialogBox>
    </PostDialog>
  )
}

export default Dialog
