import React, { useState } from "react"
import { Link } from "@material-ui/core"
import ConfirmDialog from "../ConfirmDialog"

export type Props = {
  onConfirmClicked: () => void
}

const View = ({ onConfirmClicked }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onConfirm = () => {
    onConfirmClicked()
    setIsDialogOpen(false)
  }

  return (
    <>
      <Link onClick={() => setIsDialogOpen(true)} color="error">
        Continue
      </Link>
      <ConfirmDialog
        title="Archive profile"
        message="Are you sure you want to archive this profile?"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={onConfirm}
      />
    </>
  )
}

export default View
