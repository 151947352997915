import React, { useState, ChangeEvent, useCallback, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { fetchProfiles } from "../../redux/profile"
import { LoadingStatuses } from "../../redux/profile"
import LoadingPage from "../LoadingPage"
import View from "./view"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"

type Props = {
  perPage?: number
  sortBy?: string
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const IndividualsGroupsPage = ({
  perPage = 10,
  sortBy = "createdAt",
}: Props) => {
  const { user, isAuthenticated } = useAuth0()
  const email = user?.email
  const query = useQuery()
  const history = useHistory()
  const location = query.get("location") || undefined
  const dispatch = useAppDispatch()
  const profiles = useSelector((state: any) => state.profile.profiles)
  const profilesCount = useSelector((state: any) => state.profile.profilesCount)
  const profilesStatus = useSelector(
    (state: any) => state.profile.profilesStatus
  )

  const [term, setTerm] = useState("")

  const [onlyFollowing, setOnlyFollowing] = useState(
    query.get("onlyFollowing") === "true" ? true : false
  )

  const [skills] = useState<string[]>(
    query.get("skills") ? (query.get("skills") as any) : []
  )
  const [filterBy, setFilterBy] = useState("createdAt")

  const [page, setPage] = React.useState(1)

  useEffect(() => {
    setPage(query.get("page") === null ? 1 : Number(query.get("page")))
  }, [query])

  const handleTermChanged = (term: string) => setTerm(term)

  const handleChangeFilterBy = async (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    setFilterBy(event.target.value as string)
    sortBy = event.target.value as string
    try {
      const search = term
      await dispatch(
        fetchProfiles({ email, page, perPage, search, onlyFollowing, sortBy })
      )
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangePagination = async (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    const queryString = `/profiles?${
      location ? `location=${location}&` : ""
    }page=${value}`
    history.push(queryString)
    window.scrollTo(0, 0)
  }

  const handleChangeOnlyFollowing = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
    } else {
      setOnlyFollowing(event.target.checked)
    }
  }

  const handleSearch = async () => {
    try {
      const search = term
      await dispatch(
        fetchProfiles({
          email,
          page: 0,
          perPage,
          search,
          onlyFollowing,
          sortBy,
        })
      )
    } catch (e) {
      console.error(e)
    }
  }

  const loadProfiles = useCallback(async () => {
    try {
      const search = term
      const { type } = await dispatch(
        fetchProfiles({
          email,
          page,
          perPage,
          location,
          skills,
          search,
          onlyFollowing,
          sortBy,
        })
      )

      if (fetchProfiles.fulfilled.type === type) {
        setTimeout(() => {
          // @ts-ignore
          window.prerenderReady = true
        }, 0)
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    dispatch,
    email,
    page,
    perPage,
    location,
    onlyFollowing,
    term,
    skills,
    sortBy,
  ])

  useEffect(() => {
    loadProfiles()
  }, [loadProfiles])

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        const params = new URLSearchParams(location.search.substring(1))
        const currentPage = params.get("page") ? params.get("page") : "1"
        if (currentPage) {
          setPage(parseInt(currentPage))
          window.scrollTo(0, 0)
        }
      }
    })
  }, [history])

  if (profilesStatus === LoadingStatuses.Idle) {
    return <LoadingPage />
  }

  return (
    <>
      <RouteChangeTracker screenTitle="Profiles" classTitle="Profiles" />
      <Helmet>
        <title>Profiles - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Profiles - Spectacular`} />
        <meta name="title" content={`Profiles - Spectacular`} />
      </Helmet>
      <View
        profiles={profiles}
        onlyFollowing={onlyFollowing}
        handleChangeFilterBy={handleChangeFilterBy}
        filterBy={filterBy}
        location={location}
        onTermChanged={handleTermChanged}
        onSearch={handleSearch}
        profilesCount={profilesCount}
        perPage={perPage}
        page={page}
        handleChangePagination={handleChangePagination}
        handleChangeOnlyFollowing={handleChangeOnlyFollowing}
      />
    </>
  )
}

export default IndividualsGroupsPage
