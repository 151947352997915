import React from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { Notification } from "../../redux/bellNotifications"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { normalizeProjectTitle } from "../../utils"
import NotificationNominationIcon from "../../images/notify-nominate.svg"
import NotificationLikeIcon from "../../images/notify-heart.svg"
import NotificationCommentIcon from "../../images/notify-comment.svg"
import NotificationShareIcon from "../../images/notify-link.svg"
import NotificationCollaboratorIcon from "../../images/notify-person.svg"
import NewNotificationIcon from "../../images/new-notification.svg"
import ReadNotificationIcon from "../../images/new-notification-white.svg"
import * as RouteConstants from "../../constants/routes"
import moment from "moment"
import {
  NotificationBox,
  AvatarBox,
  DescriptionBox,
  ProjectBox,
  AvatarIcon,
  NotificationAvatar,
  NotificationProjectImage,
  NotificationProjectImagePlaceholder,
} from "../NotificationsBell/styles"
import theme from "../../theme"
import getFromBetween from "../../utils/getStringFromBetween"

export type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  goToNotifications?: boolean
  hideButton?: boolean
}

const View = ({
  notification,
  goToNotifications,
  hideButton,
  onNotificationClick,
}: Props) => {
  const profileLink = `${RouteConstants.PROFILE_CONTAINER}/${notification?.profile?.username}`
  let projectLink = ""

  const getCommentText = () => {
    let newText = notification.comment
    if (notification.comment) {
      const separatedMentions = getFromBetween.get(
        notification.comment,
        "@{{",
        ")}}"
      )
      if (separatedMentions.length > 0) {
        separatedMentions.forEach((value: string) => {
          const arr1 = value.split("}}")
          const mention = "@" + arr1[0]
          const fullString = "@{{" + value + ")}}"
          newText = newText.replace(fullString, mention)
        })
      }
    }
    return newText ? newText : ""
  }

  if (notification?.project) {
    projectLink =
      notification?.origin !== "comment" &&
      notification?.origin !== "commentReply" &&
      notification?.origin !== "commentMention" &&
      notification?.origin !== "likeComment"
        ? `${RouteConstants.PROFILE_CONTAINER}/${
            notification?.profile?.username
          }/projects/${notification?.project?.id}/${normalizeProjectTitle(
            notification?.project?.name!
          )}`
        : `${RouteConstants.PROFILE_CONTAINER}/${
            notification?.profile?.username
          }/projects/${notification?.project?.id}/${normalizeProjectTitle(
            notification?.project?.name!
          )}#${notification?.sourceOriginId}`
  }

  const STR_SIZE = theme.breakpoints.down("xs") ? 55 : 70
  const STR_SIZE_REPLY = theme.breakpoints.down("xs") ? 44 : 59

  const history = useHistory()

  const goToURL = (url: string) => {
    if (goToNotifications) {
      history.push("/notifications")
    } else {
      onNotificationClick(notification.id)
      history.push(url)
    }
  }

  const getNotificationIcon = (origin?: string) => {
    if (
      origin === "comment" ||
      origin === "commentReply" ||
      origin === "commentMention" ||
      origin === "likeComment"
    )
      return NotificationCommentIcon
    if (origin === "like") return NotificationLikeIcon
    if (origin === "nomination") return NotificationNominationIcon
    if (origin === "share") return NotificationShareIcon
    if (origin === "collaborator") return NotificationCollaboratorIcon
    if (origin === "confirmCollaborator") return NotificationCollaboratorIcon
  }

  const getNotificationDescription = (origin?: string) => {
    if (origin === "comment")
      return getCommentText().length! > STR_SIZE
        ? `commented: ${getCommentText().substr(0, STR_SIZE)}...`
        : `commented: ${getCommentText()}.`
    if (origin === "commentReply")
      return getCommentText().length! > STR_SIZE_REPLY
        ? `replied your comment: ${getCommentText().substr(
            0,
            STR_SIZE_REPLY
          )}...`
        : `replied your comment: ${getCommentText()}.`
    if (origin === "share") return "shared your project."
    if (origin === "like") return "liked your project."
    if (origin === "nomination") return "nominated your project."
    if (origin === "commentMention") return "mentioned you in a comment."
    if (origin === "likeComment") return "liked your comment."
    if (origin === "collaborator")
      return "tagged you as a collaborator on a project."
    if (origin === "confirmCollaborator")
      return "has accepted their role as a collaborator on your project."
  }

  return (
    <NotificationBox>
      <AvatarBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <NotificationAvatar
          src={
            notification?.profile?.smallImageUrl === undefined
              ? getRandomPlaceholder(notification?.profile?.placeholderAvatar!)
              : (notification?.profile?.smallImageUrl as string)
          }
          alt={"notification"}
        />

        <AvatarIcon
          src={getNotificationIcon(notification?.origin)}
          alt={"notification"}
        />
      </AvatarBox>
      <DescriptionBox
        onClick={() => {
          goToURL(projectLink)
        }}
      >
        <Box style={{ marginTop: "-10px" }}>
          <b>
            {notification?.profile?.type === "group" ? (
              <>{notification?.profile?.groupName}</>
            ) : (
              <>
                {notification?.profile?.firstName}{" "}
                {notification?.profile?.lastName}
              </>
            )}
          </b>
          &nbsp;
          {getNotificationDescription(notification?.origin)}
          &nbsp;
          <span style={{ color: "#717171" }}>
            {moment(notification?.createdAt).fromNow(true).replace(" ", "")}
          </span>
        </Box>
      </DescriptionBox>
      {!hideButton && (
        <ProjectBox
          onClick={() => {
            goToURL(projectLink)
          }}
        >
          {notification?.project?.smallImageUrl ? (
            <NotificationProjectImage
              src={notification.project.smallImageUrl}
              alt={"notification"}
            />
          ) : (
            <NotificationProjectImagePlaceholder />
          )}
        </ProjectBox>
      )}
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
        <img
          alt={"notification-state"}
          src={
            notification?.state !== "clicked"
              ? NewNotificationIcon
              : ReadNotificationIcon
          }
          width={"8px"}
          height={"8px"}
          style={{ marginTop: "-10px" }}
        />
      </Box>
    </NotificationBox>
  )
}

export default View
