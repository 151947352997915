import React, { useState } from "react"

import { ItemBox } from "./Styles"

import RadioIcon from "../../images/radio-icon.svg"
import RadioIconHover from "../../images/radio-icon-hover.svg"
import RadioIconActive from "../../images/radio-icon-active.svg"

type Props = {
  value: string
  selectedValue: string
  handleUpdateSelectedValue: (value: string) => void
}

const Item = ({ value, selectedValue, handleUpdateSelectedValue }: Props) => {
  const [hover, setHover] = useState<boolean>(false)

  const getIcon = () => {
    if (value === selectedValue) return RadioIconActive

    if (hover) return RadioIconHover

    return RadioIcon
  }

  const getLabel = () => {
    if (value === "spam") return "It is spam."

    if (value === "offensive")
      return "It is offensive, discriminatory, or threatening."

    if (value === "violent") return "It is violent or incites violence."

    if (value === "copyright") return "It violates copyright."

    if (value === "obscene") return "It contains obscene or lewd content."

    return "Other"
  }

  return (
    <ItemBox
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={() => handleUpdateSelectedValue(value)}
      $isActive={value === selectedValue}
    >
      <img src={getIcon()} alt={"Item"} />
      <span>{getLabel()}</span>
    </ItemBox>
  )
}

export default Item
