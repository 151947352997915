import React from "react"
import "react-image-lightbox/style.css"
import Lightbox from "react-image-lightbox"
import { Box, Typography } from "@material-ui/core"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

import Carousel from "./Carousel"
import Projector from "./Projector"
import { CarouselFooter, LargeImageContainer, ProjectContainer } from "./Styles"

import { useWindowDimensions } from "../../hooks"
import { Image as ImageType } from "../../redux/projects"
import { ImageGalleryProps, defaultBreakpoints, getBreakpoint } from "./"

export const Description: React.FC<{ value: string }> = ({ value }) => {
  const { width } = useWindowDimensions()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {width <= 550 ? (
        <Typography style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
          {open || value.length < 200 ? value : value.slice(0, 200) + "..."}
        </Typography>
      ) : (
        <Typography style={{ whiteSpace: "pre-line" }}>{value}</Typography>
      )}
      {value.length >= 200 && width <= 550 ? (
        <div
          style={{ color: "#3057E1", cursor: "pointer", paddingTop: "16px" }}
          onClick={() => setOpen(!open)}
        >
          <b>{!open ? "Read more" : "Show less"}</b>
        </div>
      ) : null}
    </>
  )
}

interface Props extends ImageGalleryProps {
  expanded: boolean
  selected: ImageType
  selectedIndex: number
  toggleExpanded: () => void
  onSelect: (img: ImageType, idx: number) => void
}

const View: React.FC<Props> = ({
  images,
  projectName,
  onSelect,
  selected,
  expanded,
  selectedIndex,
  toggleExpanded,
}) => {
  const { width } = useWindowDimensions()
  const isMobile = width < 690
  const breakpoints = defaultBreakpoints(expanded)

  const [isOpen, setIsOpen] = React.useState(false)
  const carouselRef = React.useRef<any | undefined>(undefined)

  const nextIndex = (selectedIndex + 1) % images.length
  const prevIndex = (selectedIndex + images.length - 1) % images.length

  let mainImage = images[selectedIndex].url!
  if (isMobile) mainImage = images[selectedIndex].largeImageUrl!

  return (
    <ProjectContainer>
      {isOpen && (
        <Lightbox
          nextSrc={images[nextIndex].url!}
          prevSrc={images[prevIndex].url!}
          mainSrc={mainImage}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setTimeout(function () {
              onSelect(images[prevIndex], prevIndex)
            }, 500)
          }}
          onMoveNextRequest={() => {
            setTimeout(function () {
              onSelect(images[nextIndex], nextIndex)
            }, 500)
          }}
          animationDisabled={true}
        />
      )}

      <Box
        style={{
          position: "relative",
          width: "100%",
          height: "0",

          paddingBottom: "66.666667%",
        }}
      >
        <LargeImageContainer>
          <Projector
            images={images}
            projectName={projectName}
            onSelect={onSelect}
            selectedIndex={selectedIndex}
            onExpand={() => setIsOpen(!isOpen)}
          />
        </LargeImageContainer>
      </Box>

      <Box
        style={
          isMobile
            ? {
                paddingTop: "16px",
                marginLeft: "-16px",
                marginRight: "-16px",
              }
            : { paddingTop: "16px" }
        }
      >
        {selected.credits ? (
          <Typography style={{ marginBottom: "16px", fontSize: "12px" }}>
            <i>Image Courtesy {selected.credits}</i>
          </Typography>
        ) : null}

        {selected.credits || selected.caption ? (
          <Description value={selected.caption || ""} />
        ) : null}
      </Box>

      <Box style={{ marginTop: "16px" }}>
        <Carousel
          images={images}
          projectName={projectName}
          ref={carouselRef}
          onSelect={onSelect}
          expanded={expanded}
          selected={selectedIndex}
        />
      </Box>

      <Box>
        {images.length > getBreakpoint(breakpoints, width).itemsToShow && (
          <CarouselFooter
            onClick={() => {
              carouselRef.current.goTo(0)
              toggleExpanded()
            }}
          >
            {expanded ? (
              <ArrowDropUpIcon style={{ color: "white", cursor: "pointer" }} />
            ) : (
              <ArrowDropDownIcon
                style={{ color: "white", cursor: "pointer" }}
              />
            )}
          </CarouselFooter>
        )}
      </Box>
    </ProjectContainer>
  )
}

export default View
