import { Box, Container, Grid } from "@material-ui/core"
import styled, { keyframes } from "styled-components"
import theme, { colors } from "../../theme"

export const LoginSpan = styled.span`
  cursor: pointer;
  color: #3057e1;

  :hover {
    color: #1c43cd;
  }
`

export const StyledLink = styled.a`
  font-family: Public-Sans;
  text-decoration: none;
  color: ${colors.slate};
`

export const HomeContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
`

export const HomePage = styled(Container)`
  overflow-x: hidden;
  padding: 0;
  margin-bottom: -64px;
  max-width: none;
`

export const HeroSection = styled.div`
  // padding-bottom: 10%;
`

export const HeroImage = styled.figure`
  margin: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 67px);

  ${theme.breakpoints.down("sm")} {
    width: 100vw;
    height: calc(100vh - 371.5px);
  }

  ${theme.breakpoints.down("xs")} {
    width: 100vw;
    height: calc(100vh - 383.5px);
  }
`

export const Logo = styled.img`
  width: 100%;
`

export const RequestAccessSection = styled.div`
  padding-bottom: ${theme.spacing(5)}px;
`

export const RequestAccessFormContainer = styled(Container)`
  && {
    width: 100%;
    max-width: 500px;
    position: relative;
    text-align: center;
  }
`

export const RequestAccessForm = styled.div`
  height: 380px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`

export const HeroGrid = styled(Grid)`
  ${theme.breakpoints.down("xs")} {
    && {
      flex-direction: column-reverse;
    }
  }
`

const pulse = keyframes`
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.7);
    opacity: 0;
  }
`

export const VideoPlay = styled.button`
    border: 0;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    z-index: 0;
    // text-align: center;
    width: 40px;
    height: 40px;
    display: block;
    background: #2d2d2d;
    color: #fff;
    // -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transition: background 0.25s;
    // left: 50%;
    // top: 30%;
    // transform: translate(-50%, -50%);

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: .625em 0 .625em 1em;
      border-color: transparent transparent transparent #fff;
      display: block;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: calc(50% + .15em);
      transform: translate(-50%, -50%);
    }

    &::before {
      content: '';
      background: #2d2d2d;
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: ${pulse} 1500ms ease-out infinite;
      transition: all 0.3s linear;
    }
}

  ${theme.breakpoints.down("md")} {
  }
`

export const VideoPlayText = styled.text`
  && {
    font-size: 0;
    visibility: hidden;
  }
`

export const ProfessionalNetworkSection = styled.div``

export const ProfessionalNetworkSectionContainer = styled(HomeContainer)`
  padding: 0 10%;

  ${theme.breakpoints.down("xs")} {
    flex-direction: column;
    padding: 0;
  }
`

export const TextBox = styled(Box)`
  margin: 0;
  padding: 10%;

  button.MuiButton-root {
    margin: 0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0;
  }

  ${theme.breakpoints.down("xs")} {
    position: initial;
  }
`

export const BrowserImage = styled.img`
  margin-top: -5%;
  margin-bottom: -5%;
  width: 552px;
  max-width: 100%;
  box-shadow: 0px 16px 24px 0px rgba(12, 0, 51, 0.1);

  ${theme.breakpoints.down("xs")} {
    margin: 0 10% 10%;
    max-width: 80%;
  }
`

export const CommunitySection = styled.div`
  padding: 10% 0;

  ${theme.breakpoints.down("xs")} {
    padding: 10% 0 0;
  }
`

export const MobileImage = styled.img`
  max-width: 552px;
  width: 100%;
  max-width: 100%;

  ${theme.breakpoints.down("xs")} {
    margin: 0 10% 10%;
    max-width: 80%;
  }
`

export const MobileHeroGrid = styled(Grid)<{ step: number }>`
  height: ${({ step }) =>
    step === 2 ? "calc(100vh - 68px)" : "calc(100vh - 80px)"};
  align-items: ${({ step }) => (step === 2 ? "" : "flex-end")};

  ${theme.breakpoints.down("xs")} {
    height: calc(100vh - 56px);
  }
`
