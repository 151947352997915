import React from "react"

import {
  SuccessDialogArea,
  SuccessDialogTitle,
  SuccessDialogBox,
  SuccessDialogText,
  SuccessDialogHint,
  DialogBgBox,
  DialogIconsBox,
  DialogManualShareBox,
  CopyLinkText,
  EmailText,
  SuccessIconImg,
  CloseIconImg,
} from "./styles"

import SuccessIcon from "../../images/innovation-awards/modal-success.png"

import FacebookIcon from "../../images/innovation-awards/active-facebook.svg"
import LinkedinIcon from "../../images/innovation-awards/active-linkedin.svg"
import PinterestIcon from "../../images/innovation-awards/active-pinterest.svg"
import XIcon from "../../images/innovation-awards/active-x.svg"

import CopyIcon from "../../images/innovation-awards/copy-icon.svg"
import EmailIcon from "../../images/innovation-awards/email-icon.svg"

import CloseIcon from "../../images/innovation-awards/close-icon.png"

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"
import { Tooltip } from "@material-ui/core"
import { Project } from "../../redux/projects"

export type Props = {
  onClose: () => void
  open: boolean
  projectLink: string
  project?: Project
}

const SuccessDialog = ({ open, onClose, projectLink, project }: Props) => {
  const [tooltipOpened, setTooltipOpened] = React.useState(false)

  const copyLink = () => {
    navigator.clipboard.writeText(projectLink)
    handleTooltipOpen()

    setTimeout(handleTooltipClose, 1000)
  }

  const handleTooltipClose = () => {
    setTooltipOpened(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpened(true)
  }

  return (
    <SuccessDialogArea maxWidth={"md"} open={open} onClose={onClose}>
      <SuccessDialogBox>
        <DialogBgBox>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={onClose} />
          <SuccessIconImg src={SuccessIcon} alt={"success"} />
          <SuccessDialogTitle variant="h2">Success</SuccessDialogTitle>
          <SuccessDialogText>
            <span>Thank you, your project was received.</span>
          </SuccessDialogText>
          <SuccessDialogHint>
            Promote Your Work to Win the People’s Choice Award!
          </SuccessDialogHint>
          <DialogIconsBox>
            <FacebookShareButton
              url={projectLink}
              quote={project?.name}
              hashtag={"innovationawards"}
            >
              <img src={FacebookIcon} alt={"Facebook"} />
            </FacebookShareButton>

            <TwitterShareButton
              url={projectLink}
              hashtags={["innovationawards", "spectacular"]}
            >
              <img src={XIcon} alt={"X"} />
            </TwitterShareButton>

            <LinkedinShareButton url={projectLink} title={project?.name!}>
              <img src={LinkedinIcon} alt={"LinkedIn"} />
            </LinkedinShareButton>

            <PinterestShareButton
              url={projectLink}
              media={project?.medImageUrl!}
            >
              <img src={PinterestIcon} alt={"Pinterest"} />
            </PinterestShareButton>
          </DialogIconsBox>
          <DialogManualShareBox>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpened}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link copied"
            >
              <CopyLinkText onClick={copyLink}>
                <img src={CopyIcon} alt={"Copy Link"} />
                Copy link
              </CopyLinkText>
            </Tooltip>
            <EmailText href={"mailto:awards@spectacular.design"}>
              <img src={EmailIcon} alt={"Email"} />
              Email
            </EmailText>
          </DialogManualShareBox>
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default SuccessDialog
