import { Select, Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"
import SearchBar from "material-ui-search-bar"
import { Pagination } from "@material-ui/lab"

export const InnovationAwardsContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
`

export const ProjectsListContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 44px;
  }
`

export const ProjectsListBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;
  }
`

export const ProjectListTitle = styled(Typography)`
  font-size: 40px;
  margin-bottom: 24px;
  margin-top: 40px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 24px;
    font-size: 32px;
    margin-bottom: 16px;
  }
`

export const ImageFrame = styled(Box)`
  position: relative;
  cursor: pointer;
`

export const FeaturedIcon = styled(Box)`
  z-index: 1;
  position: absolute;
  bottom: -4px;
  left: 0;
  img {
    width: 24px;
    height: 24px;
  }
`

export const StyledSelect = styled(Select)`
  background-color: ${colors.white};
  text-align: center;
  border: solid 0.5px ${colors.dark};
  border-radius: 0;
  height: 58px;
  .MuiSelect-select:focus {
    background-color: ${colors.white};
  }
  .MuiSelect-select {
    padding-right: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledSearchBar = styled(SearchBar)`
  box-shadow: none;
  border: solid 0.5px ${colors.dark};
  background-color: ${colors.lightGray};
  border-radius: 0;
  height: 56px;
  .MuiInputBase-input {
    font-size: 22px;
    color: ${colors.slate};
  }
`

export const CoverPojectName = styled(Box)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${theme.breakpoints.down("xs")} {
    font-size: 38px;
  }
  ${theme.breakpoints.up("md")} {
    font-size: 28px;
  }
`

export const CoverPojectAuthor = styled(Box)`
  cursor: pointer;
  font-size: 14px;
  color: ${colors.slate};
  span {
    border-bottom: solid 0.5px ${colors.slate};
  }
`

export const Text = styled(Box)`
  font-size: 12px;
  color: ${colors.slate};
`

export const SytledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    width: 56px;
    height: 56px;
    border-radius: 0;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: ${colors.dark};
    color: ${colors.white};
  }
`

export const Search = styled.img`
  position: absolute;
  margin-left: 50px;
`

export const FilterBox = styled(Box)`
  min-width: 48px;
  height: 48px;
  margin-left: 8px;
  cursor: pointer;
  border: 0.5px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const Divider = styled.div`
  border-top: 0.5px solid #d8d8d8;
  margin: 24px 0;
  width: 100%;
`
