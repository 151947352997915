import React from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { Notification } from "../../redux/bellNotifications"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import NotificationLinkIcon from "../../images/notify-link.svg"
import NotificationInviteIcon from "../../images/notify-person.svg"
import * as RouteConstants from "../../constants/routes"
import ConnectButtonConfirm from "../ConnectButtonConfirm"
import NewNotificationIcon from "../../images/new-notification.svg"
import ReadNotificationIcon from "../../images/new-notification-white.svg"
import moment from "moment"
import {
  NotificationBox,
  AvatarBox,
  DescriptionBox,
  AvatarIcon,
  NotificationAvatar,
  ButtonBox,
} from "../NotificationsBell/styles"

export type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  goToNotifications?: boolean
  hideButton?: boolean
}

const View = ({
  notification,
  goToNotifications,
  hideButton,
  onNotificationClick,
}: Props) => {
  const profileLink = `${RouteConstants.PROFILE_CONTAINER}/${notification?.profile?.username}`

  const history = useHistory()

  const goToURL = (url: string) => {
    if (goToNotifications) {
      history.push("/notifications")
    } else {
      onNotificationClick(notification.id)
      history.push(url)
    }
  }

  const getNotificationIcon = (origin?: string) => {
    if (origin === "connectionAccepted") return NotificationLinkIcon
    if (origin === "connectionDenied") return NotificationLinkIcon
    if (origin === "inviteAccepted") return NotificationInviteIcon
  }

  const getNotificationDescription = (origin?: string) => {
    if (origin === "connectionDenied") return "request denied."
    if (origin === "connectionAccepted") return "is now a connection."
    if (origin === "inviteAccepted") return "created a Spectacular account."
  }

  return (
    <NotificationBox>
      <AvatarBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <NotificationAvatar
          src={
            notification?.profile?.smallImageUrl === undefined
              ? getRandomPlaceholder(notification?.profile?.placeholderAvatar!)
              : (notification?.profile?.smallImageUrl as string)
          }
          alt={"notification"}
        />

        <AvatarIcon
          src={getNotificationIcon(notification?.origin)}
          alt={"notification"}
        />
      </AvatarBox>
      <DescriptionBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <Box style={{ marginTop: "-10px" }}>
          <b>
            {notification?.profile?.type === "group" ? (
              <>{notification?.profile?.groupName}</>
            ) : (
              <>
                {notification?.profile?.firstName}{" "}
                {notification?.profile?.lastName}
              </>
            )}
          </b>
          &nbsp;
          {getNotificationDescription(notification?.origin)}
          &nbsp;
          <span style={{ color: "#717171" }}>
            {moment(notification?.createdAt).fromNow(true).replace(" ", "")}
          </span>
        </Box>
      </DescriptionBox>
      {notification?.origin === "connectionDenied" && !hideButton ? (
        <ButtonBox>
          <ConnectButtonConfirm
            profile={notification?.profile!}
            type={"undo"}
          />
        </ButtonBox>
      ) : null}
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
        <img
          alt={"notification-state"}
          src={
            notification?.state !== "clicked"
              ? NewNotificationIcon
              : ReadNotificationIcon
          }
          width={"8px"}
          height={"8px"}
          style={{ marginTop: "-10px" }}
        />
      </Box>
    </NotificationBox>
  )
}

export default View
