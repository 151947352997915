import React from "react"
import styled from "styled-components"
import { Button, Box, Link } from "@material-ui/core"

import { colors } from "../../theme"

export const ActionButton = styled(Button)`
  max-height: 48px;
  max-width: 140px;
  padding: 8px 22px;
  border-radius: 1px;
  font-family: "Public-Sans";
  font-weight: 400 !important;

  && {
    font-weight: 400 !important;

    MuiButton-label {
      font-weight: 400 !important;
    }
  }
`

export const RichTextEditorContainer = styled(Box)<{ error?: any }>`
  min-height: 195px;
  border-left: solid 0.5px
    ${({ error }) => (error ? colors.alert : colors.darkerGray)};
  border-right: solid 0.5px
    ${({ error }) => (error ? colors.alert : colors.darkerGray)};
  border-bottom: solid 0.5px
    ${({ error }) => (error ? colors.alert : colors.darkerGray)};
  border-top: solid 0.5px
    ${({ error }) => (error ? colors.alert : colors.darkerGray)};
  && {
    #mui-rte-editor {
      padding: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #717171;
`

export const StyledLinkBox = styled(Box)`
  display: inline;
  font-family: Public-Sans;
  a {
    border-bottom: solid 0.5px #717171;
  }
`

export const Alert = styled.img`
  position: absolute;
  margin-left: -36px;
  margin-top: 35px;
`
