import React from "react"
import GA4React from "ga-4-react"
import environment from "../../environment"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

type Props = {
  screenTitle: string
  classTitle: string
}

const RouteChangeTracker = ({ screenTitle, classTitle }: Props) => {
  const ga4react = new GA4React(process.env.REACT_APP_GA4_ID as any)

  const title =
    window.location.pathname === "/"
      ? "Home"
      : window.location.pathname.charAt(1).toUpperCase() +
        window.location.pathname.slice(2).replace("_", " ")

  if (process.env.NODE_ENV !== "test") {
    ga4react.gtag("event", "page_view", screenTitle ?? title)
  }

  if (environment.device !== "desktop") {
    FirebaseAnalytics.setScreenName({
      screenName: screenTitle ?? title,
      nameOverride: classTitle,
    })
  }

  return <div></div>
}

export default RouteChangeTracker
