import { Box, Grid } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const MainContainer = styled(Box)`
  ${theme.breakpoints.down("xs")} {
    margin-top: -40px;
  }
  ${theme.breakpoints.up("md")} {
    margin-top: -400px;
  }
  position: relative;
  * {
    text-align: initial;
  }
`

export const Text = styled(Box)`
  font-family: Times New Roman;
  font-size: 18px;
  line-height: 1.5;
`

export const TextContainer = styled(Box)`
  padding: 33px 48px 16px 48px;
  background-color: #ffffff;
  text-align: center;
  z-index: 1;
  ${theme.breakpoints.down("xs")} {
    padding: 24px 16px 60px 16px;
  }
  ${theme.breakpoints.up("md")} {
    position: relative;
    top: -200px;
  }
  font-family: Public-Sans;
`

export const ColumnWithLeftPadding = styled(Grid)`
  ${theme.breakpoints.up("sm")} {
    padding-left: ${theme.spacing(3)}px;
  }
  padding-bottom: ${theme.spacing(4)}px;
`
