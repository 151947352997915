import styled from "styled-components"
import MUIEditIcon from "@material-ui/icons/Edit"
import MUIDeleteIcon from "@material-ui/icons/Delete"
import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd"

import { colors } from "../../theme"

export const DragOverlay = styled.div`
  z-index: 2;
  position: relative;
`

export const DragContainer = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 65%);
`

export const IconOverlay = styled.div`
  top: 0;
  right: 0;
  z-index: 2;
  position: absolute;
  display: inline-flex;
`

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  text-align: center;
  border-radius: 2px;
  margin-top: 0.2rem;
  align-items: center;
  margin-right: 0.2rem;
  justify-content: center;
  background: ${colors.lightGray};
`

export const EditIcon = styled(MUIEditIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: ${colors.dark};
`

export const DeleteIcon = styled(MUIDeleteIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: ${colors.dark};
`

export const ListItemContainer = styled.div<{
  grid: number
  isDragging: boolean
  draggableStyle?: DraggingStyle | NotDraggingStyle
}>`
  position: relative;
  width: 120px;
  flex: 0 1 120px;
  height: 80px;
  border-width: 1px;
  border-style: dashed;
  text-align: center;
  border-color: ${(props) =>
    props.isDragging ? "rgba(15, 15, 15, 0.7)" : "#858585"};
  transition: border 0.5s ease-in-out;
  margin: ${(props) => `${props.grid * 1}px`};
  background: ${(props) => (props.isDragging ? "white" : "lightgrey")};
`

export const ListItemImage = styled.img<{ auto: boolean }>`
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const ListItemOverlay = styled.div<{
  isDragging: boolean
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(15, 15, 15, ${(props) => (props.isDragging ? "0.7" : "0")});
`

export const ListContainer = styled.div<{ grid: number }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
