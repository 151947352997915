import React from "react"
import { FormHelperText, Box } from "@material-ui/core"
import { Profile } from "../../redux/profile"
import CommentTextField from "../CommentTextField"
import { ProfileAvatar } from "../ProjectCommentsBox/styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { useHistory } from "react-router-dom"
import { PostCommentBox } from "./styles"
import { CommentMention } from "../../redux/projects"

export type Props = {
  onPostComment: (comment: string, mentions?: CommentMention[]) => Promise<void>
  profile?: Profile
  displayError: boolean
}

const View = ({ onPostComment, profile, displayError }: Props) => {
  const history = useHistory()

  const handleSave = async (comment: string, mentions?: CommentMention[]) => {
    if (comment.trim().length > 0) {
      try {
        await onPostComment(comment.trim(), mentions)
      } catch (_error) {}
    }
  }

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <PostCommentBox>
      <ProfileAvatar
        onClick={() => goToUrl(`/sp/${profile?.username}`)}
        src={
          profile?.smallImageUrl ||
          getRandomPlaceholder(profile?.placeholderAvatar!)
        }
      />

      <Box width={"100%"}>
        <CommentTextField handleSave={handleSave} />
        {displayError && (
          <FormHelperText error={true}>
            There was an error posting your comment.
          </FormHelperText>
        )}
      </Box>
    </PostCommentBox>
  )
}

export default View
