import React from "react"
import { Container } from "@material-ui/core"

import { Profile, ProfileType } from "../../redux/profile"
import GroupProfileForm from "../../components/GroupProfileForm"
import IndividualProfileForm from "../../components/IndividualProfileForm"

export type Props = {
  profile: Profile
  onSubmit: Function
  onCancel: Function
  disableSubmit: boolean
  usernameCheck: string
  emailCheck: string
  usernameValue: string
  emailValue: string
  onCheckUsernameAvailability: (
    _event: React.ChangeEvent<{ value: any }>
  ) => void
  onCheckEmailAvailability: (_event: React.ChangeEvent<{ value: any }>) => void
}

const View = ({
  profile,
  onSubmit,
  onCancel,
  disableSubmit,
  usernameCheck,
  emailCheck,
  usernameValue,
  emailValue,
  onCheckUsernameAvailability,
  onCheckEmailAvailability,
}: Props) => {
  return (
    <Container>
      {profile.type === ProfileType.GROUP ? (
        <GroupProfileForm
          profile={profile}
          onSubmit={onSubmit}
          onCancel={onCancel}
          disableSubmit={disableSubmit}
          usernameCheck={usernameCheck}
          emailCheck={emailCheck}
          usernameValue={usernameValue}
          emailValue={emailValue}
          onCheckUsernameAvailability={onCheckUsernameAvailability}
          onCheckEmailAvailability={onCheckEmailAvailability}
        />
      ) : (
        <IndividualProfileForm
          profile={profile}
          onSubmit={onSubmit}
          onCancel={onCancel}
          disableSubmit={disableSubmit}
          usernameCheck={usernameCheck}
          usernameValue={usernameValue}
          onCheckUsernameAvailability={onCheckUsernameAvailability}
        />
      )}
    </Container>
  )
}

export default View
