type Nullstate = {
  type:
    | "owner-projects-tab"
    | "group-owner-projects-tab"
    | "group-projects-tab"
    | "projects-tab"
    | "owner-jobs-tab"
    | "group-jobs-tab"
    | "jobs-tab"
    | "owner-experience-tab"
    | "experience-tab"
    | "group-owner-about-tab"
    | "owner-about-tab"
    | "group-about-tab"
    | "about-tab"
    | "profile-in-progress"
    | "posts-tab"
    | "owner-posts-tab"
    | "posts-tab-group"
  title: string
  text: string
  buttonText?: string
  iconSize: "32px" | "48px"
}

export const AvailableNullstates: Nullstate[] = [
  {
    type: "group-owner-projects-tab",
    title: "Build out your portfolio",
    text: "Upload your projects and your work could be featured on our homepage!",
    buttonText: "Add a project",
    iconSize: "32px",
  },
  {
    type: "owner-projects-tab",
    title: "Build out your portfolio",
    text: "Upload your projects and your work could be featured on our homepage!",
    buttonText: "Add a project",
    iconSize: "32px",
  },
  {
    type: "owner-jobs-tab",
    title: "Your firm has no published jobs",
    text: "Post your job openings on Spectacular to find your next great talent!",
    buttonText: "Add a job",
    iconSize: "32px",
  },
  {
    type: "group-owner-about-tab",
    title: "Finish your profile by adding additional details about your firm",
    text: "Write a brief summary or position statement.",
    buttonText: "Add details",
    iconSize: "32px",
  },
  {
    type: "owner-about-tab",
    title: "Finish your profile by adding a bio",
    text: "Use this section to tell the community all about yourself.",
    buttonText: "Add bio",
    iconSize: "32px",
  },
  {
    type: "owner-experience-tab",
    title: "Finish your profile by adding your experience",
    text: "Use this section to add your resume and past experiences.",
    buttonText: "Add experience",
    iconSize: "32px",
  },
  {
    type: "group-projects-tab",
    title: "There are currently no published projects",
    text: "Click the blue follow button above to stay up-to-date on activity from this firm.",
    buttonText: "Discover projects",
    iconSize: "32px",
  },
  {
    type: "group-jobs-tab",
    title: "There are currently no open jobs",
    text: "This firm doesn’t have any open roles right now, but head to our job board to see who’s hiring.",
    buttonText: "Discover jobs",
    iconSize: "32px",
  },
  {
    type: "group-about-tab",
    title: "Under construction",
    text: "This firm hasn’t filled out their bio yet. Have you completed your profile?",
    buttonText: "My profile",
    iconSize: "32px",
  },
  {
    type: "projects-tab",
    title: "There are currently no published projects",
    text: "Click the blue follow button to stay up-to-date on projects from this individual.",
    buttonText: "Discover projects",
    iconSize: "32px",
  },
  {
    type: "experience-tab",
    title: "Under construction",
    text: "This individual hasn’t filled out their experience yet. Have you completed your profile?",
    buttonText: "My profile",
    iconSize: "32px",
  },
  {
    type: "about-tab",
    title: "Under construction",
    text: "This individual hasn’t filled out their bio yet. Have you completed your profile?",
    buttonText: "My profile",
    iconSize: "32px",
  },
  {
    type: "owner-posts-tab",
    title: "Update your community",
    text: "Share a vignette, photo, question, or career update",
    iconSize: "32px",
  },
  {
    type: "posts-tab",
    title: "There are currently no posts available",
    text: "Click the blue follow button to stay up-to-date on this individual’s posts",
    iconSize: "32px",
  },
  {
    type: "posts-tab-group",
    title: "There are currently no posts available",
    text: "Click the blue follow button to stay up-to-date on this firm’s posts",
    iconSize: "32px",
  },
]
