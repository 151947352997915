import React from "react"
import { Box, Typography } from "@material-ui/core"

import { Props } from "./"

import { LocationText } from "../../styles"

import Avatar from "../../../../components/Avatar"
import FollowButton from "../../../../components/FollowButton"
import LocationIcon from "../../../../images/location-icon.svg"
import SimpleAddress from "../../../../components/SimpleAddress"

const TabletView: React.FC<Props> = ({ profile, goToUrl }) => {
  const isPlural = (value: number | undefined) => {
    if (value !== undefined && (value === 0 || value > 1)) return true
    else return false
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "flex", minWidth: "233px", alignSelf: "flex-end" }}
        >
          <Avatar border width={164} height={164} profile={profile} />
        </div>

        <div style={{ alignSelf: "flex-end", marginLeft: "auto" }}>
          <div style={{ marginTop: "106px", float: "right" }}>
            <FollowButton
              size="small"
              profile={profile}
              style={{ color: "white", margin: "0", minWidth: "99px" }}
            />
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "790px", paddingTop: "20px" }}>
        <Box>
          <Typography
            variant="h3"
            style={{
              fontSize: "25px",
              lineHeight: "30px",
              marginBottom: "-3px",
            }}
          >
            {profile.groupName}
          </Typography>
        </Box>

        <Box style={{ paddingTop: "16px" }}>
          <Typography
            style={{
              fontSize: "16px",
              marginTop: "-3px",
              marginBottom: "-3px",
            }}
          >
            {profile.companySize && (
              <>
                {profile.companySize} employees{" "}
                <span style={{ fontSize: "10px" }}>• </span>
              </>
            )}
            {profile.followersCount} follower
            {isPlural(profile.followersCount) ? "s " : " "}
          </Typography>
        </Box>

        {profile.location && (
          <Box
            style={{ paddingTop: "8px", display: "flex", alignItems: "center" }}
          >
            <img
              alt="location"
              src={LocationIcon}
              style={{ marginRight: "6px", height: "11px" }}
            />
            <LocationText
              tabIndex={0}
              onClick={() => goToUrl(`/profiles?location=${profile.location}`)}
            >
              {SimpleAddress({
                profileType: profile!.type,
                location: profile.location,
              })}
            </LocationText>
          </Box>
        )}
      </div>
    </>
  )
}

export default TabletView
