import React, { useState } from "react"
import { Box } from "@material-ui/core"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"
import { MentionsInput, Mention } from "react-mentions"
import {
  DialogButtonBox,
  DialogCloseButton,
  EditCommentDialog,
  EditCommentDialogBox,
  EditCommentDialogTitle,
  ProfileBox,
  ProfileTitle,
  TextBox,
} from "./Styles"
import { Profile, fetchProfileSuggestion } from "../../redux/profile"
import { Comment, CommentMention } from "../../redux/projects"
import { Button } from "../Button"
import Avatar from "../Avatar"
import inputStyle from "./inputStyle"
import defaultMentionStyle from "../CommentTextField/defaultMentionStyle"
import { DescriptionBox, NameTitle, UserBox } from "../CommentTextField/styles"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { PostComment, PostMention } from "../../redux/posts"
import getFromBetween from "../../utils/getStringFromBetween"
import UpLoadingPage from "../../pages/UpLoadingPage"
import "./fix-dialog.css"

export type Props = {
  comment: PostComment | Comment
  profile: Profile
  onClose: () => void
  onSubmit: (
    content: string,
    mentions?: PostMention[] | CommentMention[]
  ) => void
  open: boolean
  isSubmitting: boolean
}

const Dialog = ({
  comment,
  profile,
  open,
  onClose,
  onSubmit,
  isSubmitting,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false)
  const [text, setText] = useState<string>(comment.text)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  let inputEl = React.createRef<HTMLTextAreaElement>()

  const handleGetSuggestions = async (query: string, callback: any) => {
    try {
      const { type, payload } = await dispatch(
        fetchProfileSuggestion({ auth, query })
      )
      if (fetchProfileSuggestion.fulfilled.type === type) {
        callback(payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    onClose()
    setText("")
  }

  const handleSubmit = () => {
    const mentions: PostMention[] = []
    const separatedMentions = getFromBetween.get(text, "@{{", ")}}")
    separatedMentions.forEach((value: string) => {
      const arr1 = value.split("}}")
      const arr2 = arr1[1].replace("{{(", "").split("|")

      mentions.push({
        name: arr1[0],
        profileId: arr2[0],
        username: arr2[1],
        fullString: "@{{" + value + ")}}",
      })
    })

    onSubmit(text, mentions)
    setText("")
  }

  return (
    <EditCommentDialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      className={"EditCommentDialog"}
      disableBackdropClick={true}
    >
      {isSubmitting ? (
        <EditCommentDialogBox>
          <UpLoadingPage message={"Updating comment"} />
        </EditCommentDialogBox>
      ) : (
        <>
          <EditCommentDialogTitle>
            <Box>Edit comment</Box>
            <DialogCloseButton
              src={hover ? CloseIconHover : CloseIcon}
              onMouseEnter={() => {
                setHover(true)
              }}
              onMouseLeave={() => {
                setHover(false)
              }}
              alt={"close"}
              onClick={handleClose}
            />
          </EditCommentDialogTitle>
          <EditCommentDialogBox>
            <ProfileBox>
              <Avatar profile={profile} width={32} height={32} />
              <ProfileTitle>
                {profile.type === "group"
                  ? profile.groupName
                  : profile.firstName + " " + profile.lastName}
              </ProfileTitle>
            </ProfileBox>
            <TextBox>
              <MentionsInput
                inputRef={inputEl}
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={inputStyle}
                allowSpaceInQuery
              >
                <Mention
                  trigger={"@"}
                  markup={"@{{__display__}}{{(__id__)}}"}
                  displayTransform={(id, name) => `@${name}`}
                  data={handleGetSuggestions}
                  style={defaultMentionStyle}
                  appendSpaceOnAdd
                  renderSuggestion={(suggestion) => {
                    const newData = { ...suggestion } as any
                    return (
                      <UserBox>
                        <Avatar profile={newData} width={32} height={32} />
                        <DescriptionBox>
                          <NameTitle>{newData.name}</NameTitle>
                        </DescriptionBox>
                      </UserBox>
                    )
                  }}
                />
              </MentionsInput>
            </TextBox>
            <DialogButtonBox>
              <Button
                onClick={handleSubmit}
                children={"Done"}
                color={"primary"}
                size={"medium"}
                style={{
                  width: "100%",
                }}
              />
            </DialogButtonBox>
          </EditCommentDialogBox>
        </>
      )}
    </EditCommentDialog>
  )
}

export default Dialog
