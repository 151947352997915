import React from "react"
import { useHistory } from "react-router-dom"
import { Button } from "./styles"
import BackIcon from "../../images/new-homepage/carousel-arrow-left.svg"

type Props = {
  style?: any
}

const BackButton = ({ style }: Props) => {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <Button onClick={goBack} style={style}>
      <img src={BackIcon} alt={"Go back"} />
    </Button>
  )
}

export default BackButton
