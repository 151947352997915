import React from "react"

import { Button } from "../../components"
import {
  BestOptionBox,
  BottomPlanOptionBox,
  ButtonsBox,
  PlanOptionBox,
  PlanOptionColumn,
  PlanOptionLi,
  PlanOptionsContainer,
  PlanOptionUl,
  WizardBgBox,
  WizardBodyContainer,
  WizardContentHeaderText,
  WizardContentText,
  WizardHeaderText,
  WizardPlanHeaderContainer,
} from "./styles"

import { WizardSteps } from "./Wizard"
import { useWindowDimensions } from "../../hooks"
import { Product, ProductBenefit } from "../../redux/product"
import { JobListing } from "../../redux/jobListings"
import { useHistory } from "react-router-dom"
import { normalizeProjectTitle } from "../../utils"
import { logEcommerceEvent, logEvent } from "../../utils/analytics"

type Props = {
  goToStep?: (step: WizardSteps) => void
  onSelectProduct?: (product: Product) => void
  products: Product[]
  job?: JobListing
}
const Plan = ({ goToStep, onSelectProduct, products, job }: Props) => {
  const history = useHistory()
  const { width } = useWindowDimensions()
  const isMobile = width <= 960

  const goToJob = async () => {
    await logEvent("skip_promotion_click")

    if (job) {
      history.push(
        `/sp/${job.profile?.username}/job_listings/${
          job.id
        }/${normalizeProjectTitle(job.title)}`
      )
    }
  }

  const renderBenefits = (benefits: ProductBenefit[]) => {
    return (
      <PlanOptionUl>
        {benefits.map((benefit, key) => (
          <PlanOptionLi key={`benefit-${key}`} $isActive={benefit.active}>
            {benefit.text}
          </PlanOptionLi>
        ))}
      </PlanOptionUl>
    )
  }

  return (
    <WizardBgBox>
      <WizardPlanHeaderContainer>
        <WizardHeaderText>Boost your job!</WizardHeaderText>
      </WizardPlanHeaderContainer>
      <WizardBodyContainer>
        <WizardContentHeaderText
          style={{
            margin: !isMobile ? "27px 32px 10px" : "25px 20px 4px",
          }}
        >
          63% more likely to find a match
        </WizardContentHeaderText>
        <WizardContentText
          style={{
            margin: !isMobile ? "0 32px 23px" : "0 20px 22px",
          }}
        >
          Boost your job listing for 30 days to gain premium exposure and
          connect with top talent faster!
          <ul>
            <li>
              <b>Top Placement:</b> Keep your job at the top of our job board.
            </li>
            <li>
              <b>Newsletter Spotlight:</b> Feature your job in our weekly
              newsletter.
            </li>
            <li>
              <b>Social Media Amplification:</b> Extend your reach with
              promotion across our social media channels.
            </li>
            <li>
              <b>Direct Match Delivery:</b> Get your job directly in front of
              architects who match your needs.
            </li>
          </ul>
        </WizardContentText>

        <PlanOptionsContainer>
          {products.map((product) => {
            return (
              <PlanOptionColumn
                style={{ marginBottom: isMobile ? "20px" : undefined }}
              >
                {product.bestOption && (
                  <BestOptionBox>Best value</BestOptionBox>
                )}
                <PlanOptionBox>
                  <WizardContentHeaderText
                    style={{
                      fontFamily: "Public-Sans-Bold",
                      fontWeight: 700,
                      marginBottom: "5px",
                      marginTop: !isMobile ? "-5px" : undefined,
                    }}
                  >
                    {product.title}
                  </WizardContentHeaderText>
                  <WizardContentHeaderText
                    style={{ marginBottom: "10px", fontSize: "42px" }}
                  >
                    ${product.amount / 100}
                  </WizardContentHeaderText>
                  <WizardContentText style={{ color: "#717171" }}>
                    Price/month
                  </WizardContentText>
                  {job ? (
                    <Button
                      children={"Start now"}
                      color={"secondary"}
                      onClick={() => {
                        if (onSelectProduct) onSelectProduct(product)
                        if (goToStep) goToStep(WizardSteps.PAYMENT)
                        logEcommerceEvent({ eventName: "add_to_cart", product })
                      }}
                      size={"medium"}
                      style={{ width: "100%", height: "48px" }}
                    />
                  ) : null}
                </PlanOptionBox>
                <BottomPlanOptionBox>
                  {renderBenefits(product.benefits)}
                </BottomPlanOptionBox>
              </PlanOptionColumn>
            )
          })}
        </PlanOptionsContainer>
        {job ? (
          <ButtonsBox>
            <Button
              children={"Skip for now"}
              color={"whiteGreyBorder"}
              onClick={goToJob}
              size={"medium"}
              style={{
                height: "48px",
                width: "138px",
              }}
            />
          </ButtonsBox>
        ) : null}
      </WizardBodyContainer>
    </WizardBgBox>
  )
}

export default Plan
