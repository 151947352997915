import styled from "styled-components"
import { Dialog, Box, Typography } from "@material-ui/core"

import theme, { colors } from "../../theme"

export const FilterDialog = styled(Dialog)`
  min-height: 100vh;
  z-index: 2147483098 !important;
`

export const FilterDialogBox = styled(Box)`
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-flow: column;
`

export const FilterDialogHeader = styled(Box)`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #d8d8d8;
  min-height: 64px;
  padding: 0 20px;
`
export const FilterDialogTitle = styled(Typography)`
  font-size: 16px;
  color: ${colors.dark};
  line-height: 16px;
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const DialogOption = styled(Box)`
  height: 48px;
  box-sizing: border-box;
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;
  color: #2d2d2d;
  cursor: pointer;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;

  && img {
    margin-right: 16px;
  }
`

export const DropDownBox = styled(Box)`
  height: 48px;
  background: #fff;
  color: #2d2d2d;
  font-size: 16px;
  border: 0.5px solid #d8d8d8;
  border-radius: 1px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 216px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  && img {
    width: 14px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;

    && img {
      display: none;
    }
  }
`

export const MenuItem = styled.div`
  color: #2d2d2d;
  cursor: pointer;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`

export const MenuBox = styled.div`
  box-sizing: border-box;
  width: 216px;
  font-size: 14px;
  box-shadow: 0px -2px 15px rgba(45, 45, 45, 0.1);
  background-color: #fff;
  margin-top: 16px;
`
export const MenuItemArea = styled.div`
  padding: 8px 16px;
`

export const Divider = styled.div`
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
`

export const ButtonsArea = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ClearFilter = styled(Box)`
  color: #979797;
  font-size: 14px;
`

export const ClearFilterActive = styled(Box)`
  cursor: pointer;
  color: #3057e1;
  font-size: 14px;
`
