import React from "react"
import { Box } from "@material-ui/core"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import {
  PopUp,
  PopUpTitle,
  PopUpHeader,
  PopUpCloseIcon,
  ButtonBox,
} from "./styles"
import { useHistory } from "react-router-dom"
import { Profile } from "../../redux/profile"
import { Button } from "../Button"

interface Props {
  profile: Profile
  open: boolean
  onClose: () => void
}

const View: React.FC<Props> = ({ profile, open, onClose }) => {
  const [hover, setHover] = React.useState(false)
  const history = useHistory()

  const onUpdate = () => {
    onClose()
    history.push("/sp/" + profile.username)
  }

  return (
    <PopUp
      style={{
        opacity: open ? 1 : 0,
        visibility: open ? "visible" : "hidden",
      }}
    >
      <PopUpHeader>
        <PopUpTitle>Update your profile</PopUpTitle>
        <PopUpCloseIcon
          alt={"close"}
          onClick={onClose}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          src={hover ? CloseIconHover : CloseIcon}
        />
      </PopUpHeader>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
        }}
      />
      <Box style={{ padding: "24px 24px" }}>
        <div
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontFamily: "Public-Sans",
            paddingBottom: "24px",
          }}
        >
          <div style={{ margin: "-4px 0px", color: "#fff" }}>
            Unlock 5x more networking & job opportunities on Spectacular by
            updating your education and work experience.
          </div>
        </div>
        <ButtonBox>
          <Button
            children="Update now"
            color="secondary"
            onClick={onUpdate}
            style={{ height: "48px" }}
          />
          <Button
            children="Maybe later"
            color="darkWhiteBorder"
            onClick={onClose}
            style={{ height: "48px" }}
          />
        </ButtonBox>
      </Box>
    </PopUp>
  )
}

export default View
