import React, { useState } from "react"
import { ProfileType } from "../../../redux/profile"
import View from "./View"

const ProfileNewPage = () => {
  const [profileType, setProfileType] = useState(ProfileType.INDIVIDUAL)

  const handleProfileTypeChanged = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setProfileType(event.target.value as ProfileType)
  }

  return (
    <View
      profileType={profileType}
      onProfileTypeChanged={handleProfileTypeChanged}
    />
  )
}

export default ProfileNewPage
