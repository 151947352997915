import styled from "styled-components"
import { Box } from "@material-ui/core"

import theme from "../../theme"

export const PopUp = styled(Box)`
  width: 400px;
  background: #2d2d2d;
  box-shadow: 0px 10px 40px rgba(113, 113, 113, 0.15);
  position: fixed;
  right: 20px;
  top: 170px;
  z-index: 999999999999;
  transition: all 0.4s;
  border-radius: 1px;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    top: auto;
    bottom: 64px;
    right: 0;
  }
`

export const PopUpTitle = styled(Box)`
  font-size: 20px;
  align-content: center;
  display: flex;
  flex-flow: wrap;
  color: #fff;
`

export const PopUpHeader = styled(Box)`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 24px;
  justify-content: space-between;
`

export const PopUpCloseIcon = styled.img`
  cursor: pointer;
`

export const ButtonBox = styled.div`
  display: flex;
  gap: 16px;

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;

    && button {
      width: 100% !important;
    }
  }
`
