import React from "react"
import { NavLink as RouterLink, useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import DefaultBannerImage from "../../images/banner_default.png"
import EditIcon from "../../images/jobs/edit-icon.svg"
import LocationIcon from "../../images/location-icon.svg"
import ArrowBack from "../../images/arrow-back.svg"
import MUIRichTextEditor from "mui-rte"

import {
  MuiBox,
  ArrowBox,
  JobSpacing,
  JobContainer,
  JobHeader,
  JobContent,
  JobDetails,
  JobBanner,
  JobTagsArea,
  JobTag,
  JobDetailsRightBox,
  JobDetailsBox,
  JobTagsBox,
  RightTitle,
  RightText,
  Divider,
  ProfileAvatar,
  ProfileDetails,
  ProfileNameBox,
  ProfileName,
  EditProfileBox,
  EditProfile,
  ProfileDetailsText,
  LocationBox,
  LocationText,
  AdsArea,
  PosterBox,
  PosterTitle,
  PosterDetails,
  PosterName,
  PosterCompany,
  PosterLocation,
  LinkBox,
  RouterLinkBox,
  JobTitle,
} from "./styles"

import { Profile } from "../../redux/profile"
import { JobListing } from "../../redux/jobListings"
import { JobCard } from "../../cards/JobCard"

import Avatar from "../../components/Avatar"
import { Button } from "../../components"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { capitalize } from "../../utils/capitalize"

interface Tags {
  type: string
  value: string
}

interface Props {
  job: JobListing
  author: Profile
  onApply: () => void
  onSendEmail: () => void
  isProfileOwner: boolean
  goToUrl: (url: string) => void
  spProfileLocation: string
}

const View = ({
  job,
  author,
  goToUrl,
  isProfileOwner,
  onApply,
  onSendEmail,
  spProfileLocation,
}: Props) => {
  const bannerImage = author.bannerImageUrl
    ? author.bannerImageUrl
    : DefaultBannerImage

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const tags: Tags[] = []
  const customTags: Tags[] = []

  if (job?.employmentType?.toLowerCase() !== "n/a")
    customTags.push({
      type: "employmentType",
      value: capitalize(job.employmentType),
    })
  if (job?.workplaceType?.toLowerCase() !== "n/a")
    customTags.push({
      type: "workplaceType",
      value: capitalize(job.workplaceType),
    })
  if (job?.experienceLevel?.toLowerCase() !== "n/a")
    customTags.push({
      type: "experienceLevel",
      value: capitalize(job.experienceLevel),
    })

  job.tags.forEach((value) => {
    tags.push({ type: "search", value: value })
  })

  const allTags = [...customTags, ...tags]

  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })

  return (
    <JobContainer>
      {isProfileOwner ? (
        <JobHeader>
          {isMobile && <img src={ArrowBack} alt={"back"} onClick={goBack} />}
          <JobCard profile={author} job={job} origin={"job-details"} />
        </JobHeader>
      ) : (
        <>
          {isMobile && (
            <ArrowBox>
              <img src={ArrowBack} alt={"back"} onClick={goBack} />
            </ArrowBox>
          )}
        </>
      )}
      <JobContent>
        <JobDetails>
          <JobBanner bannerimage={bannerImage}></JobBanner>
          <JobSpacing>
            <ProfileAvatar onClick={() => goToUrl("/sp/" + author.username)}>
              <Avatar profile={author} />
            </ProfileAvatar>
            <ProfileDetails>
              <ProfileNameBox>
                <ProfileName>{author.groupName}</ProfileName>
                {isProfileOwner ? (
                  <EditProfileBox
                    onClick={() =>
                      goToUrl(
                        `/job_listings/${job.id!}/${
                          job.status === "open" ? "edit" : "repost"
                        }`
                      )
                    }
                  >
                    <img src={EditIcon} alt={"edit-icon"} />
                    <EditProfile>
                      {job.status === "open" ? "Edit job" : "Repost Job"}
                    </EditProfile>
                  </EditProfileBox>
                ) : (
                  <>
                    {!isMobile && (
                      <Button
                        onClick={onApply}
                        color={"secondary"}
                        size={"medium"}
                        style={{ maxHeight: "40px", width: "101px" }}
                        children={"Apply"}
                      />
                    )}
                  </>
                )}
              </ProfileNameBox>
              <Box paddingTop={2}>
                <ProfileDetailsText>
                  {author.companySize && (
                    <>
                      {`${author.companySize} Employees `}{" "}
                      <span style={{ fontSize: "10px" }}>• </span>
                    </>
                  )}
                  {author.followersCount! > 0 && (
                    <>
                      {author.followersCount}{" "}
                      {author.followersCount === 1 ? "follower" : "followers"}
                    </>
                  )}
                </ProfileDetailsText>
              </Box>
              {author.location && (
                <LinkBox
                  href={`${spProfileLocation}/?location=${author.location}`}
                >
                  <LocationBox>
                    <img src={LocationIcon} alt={"location icon"} />
                    <LocationText>{author.location}</LocationText>
                  </LocationBox>
                </LinkBox>
              )}

              {isMobile && customTags.length > 0 && (
                <JobTagsArea paddingTop={2.5} justifyContent={"center"}>
                  {customTags.map((tag, index) => (
                    <RouterLinkBox
                      component={RouterLink}
                      to={`/?${tag.type}=${tag.value.toLowerCase()}`}
                    >
                      <JobTag key={`1-${index}-${tag.value}`}>
                        {tag.value}
                      </JobTag>
                    </RouterLinkBox>
                  ))}
                </JobTagsArea>
              )}

              {!isProfileOwner && isMobile && (
                <Button
                  onClick={onApply}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    maxHeight: "48px",
                    minHeight: "48px",
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "-8px",
                  }}
                  children={"Apply"}
                />
              )}
            </ProfileDetails>
            <Divider paddingBottom={isMobile ? 3 : 5} />
            <JobDetailsBox>
              <MuiBox>
                <JobTitle variant={"h1"}>{job.title}</JobTitle>
                <RightTitle>Description</RightTitle>
                <Box paddingTop={isMobile ? 2 : 3}>
                  {job.descriptionHtml ? (
                    renderHTML(job.descriptionHtml)
                  ) : (
                    <MUIRichTextEditor
                      defaultValue={job.descriptionRtf}
                      readOnly={true}
                      controls={[]}
                    />
                  )}
                </Box>
                <JobTagsBox>
                  {allTags.length > 0 && (
                    <>
                      <RightTitle>Keywords</RightTitle>
                      <JobTagsArea>
                        {allTags.map((tag, index) => (
                          <RouterLinkBox
                            component={RouterLink}
                            to={`/jobs?${tag.type}=${tag.value.toLowerCase()}`}
                          >
                            <JobTag key={`2-${index}-${tag.value}`}>
                              {tag.value}
                            </JobTag>
                          </RouterLinkBox>
                        ))}
                      </JobTagsArea>
                    </>
                  )}
                </JobTagsBox>
              </MuiBox>
              <JobDetailsRightBox>
                <Box>
                  <RightTitle>Location</RightTitle>
                  <RightText>
                    <RouterLinkBox
                      component={RouterLink}
                      to={`/jobs?location=${job.location}`}
                      style={{ color: "#2d2d2d" }}
                    >
                      {job.location}
                    </RouterLinkBox>
                  </RightText>
                </Box>
                {job.workplaceType !== "n/a" && (
                  <Box>
                    <RightTitle>Workplace type</RightTitle>
                    <RightText>
                      <RouterLinkBox
                        component={RouterLink}
                        to={`/jobs?workplaceType=${job.workplaceType}`}
                      >
                        {capitalize(job.workplaceType)}
                      </RouterLinkBox>
                    </RightText>
                  </Box>
                )}

                {job.employmentType !== "n/a" && (
                  <Box>
                    <RightTitle>Employment type</RightTitle>
                    <RightText>
                      <RouterLinkBox
                        component={RouterLink}
                        to={`/jobs?employmentType=${job.employmentType}`}
                      >
                        {capitalize(job.employmentType)}
                      </RouterLinkBox>
                    </RightText>
                  </Box>
                )}

                {job.experienceLevel !== "n/a" && (
                  <Box>
                    <RightTitle>Experience level</RightTitle>
                    <RightText>
                      <RouterLinkBox
                        component={RouterLink}
                        to={`/jobs?experienceLevel=${job.experienceLevel}`}
                      >
                        {capitalize(job.experienceLevel)}
                      </RouterLinkBox>
                    </RightText>
                  </Box>
                )}
              </JobDetailsRightBox>
            </JobDetailsBox>
          </JobSpacing>
        </JobDetails>
        <AdsArea>
          {!isProfileOwner && !isMobile && author.contactEmail && (
            <PosterBox>
              <PosterTitle>Contact the job poster</PosterTitle>
              <Divider marginTop={2.5} paddingBottom={3} />
              <PosterDetails>
                <Avatar profile={author} />
                <Box>
                  <PosterName>
                    {author.firstName} {author.lastName}
                  </PosterName>
                  <PosterCompany>{author.groupName}</PosterCompany>
                  <LinkBox
                    href={`${spProfileLocation}/?location=${author.location}`}
                  >
                    <PosterLocation>{author.location}</PosterLocation>
                  </LinkBox>
                </Box>
              </PosterDetails>
              <Box paddingBottom={2.5} paddingLeft={2.5}>
                <Button
                  onClick={onSendEmail}
                  color={"blueBorder"}
                  size={"medium"}
                  style={{ maxHeight: "32px" }}
                  children={"Send email"}
                />
              </Box>
            </PosterBox>
          )}
        </AdsArea>
      </JobContent>
    </JobContainer>
  )
}

export default View
