import React, { useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import { getPost } from "../../redux/posts"
import LoadingPage from "../LoadingPage"
import RouteChangeTracker from "../../components/RouteChangeTracker"

interface Params {
  id: string
}

const PostPage = () => {
  const { id } = useParams<Params>()
  const { user } = useAuth0()
  const email = user?.email
  const dispatch = useAppDispatch()

  const post = useSelector((state: any) => state.posts.post)

  const loadPost = useCallback(async () => {
    try {
      await dispatch(
        getPost({
          email,
          postId: id,
        })
      )
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, email, id])

  useEffect(() => {
    loadPost()
  }, [loadPost])

  if (!post) {
    return <LoadingPage />
  } else {
    return (
      <>
        <RouteChangeTracker
          screenTitle={`Post Details`}
          classTitle="PostDetails"
        />
        <Helmet>
          <title>Spectacular</title>
        </Helmet>
        <View post={post} />
      </>
    )
  }
}

export default PostPage
