import React from "react"

import View from "./NewView"
import { projectCategories } from "../../constants/project-categories"

type Props = {
  error?: string
  typology: string[]
  onChange: (typology: string[]) => void
}

const ProjectTypology = ({ typology, onChange, error }: Props) => {
  const handleChange = (category: string) => {
    let noOtherCategory = false
    for (let item in projectCategories) {
      if (projectCategories[item].value === category) noOtherCategory = true
    }

    if (noOtherCategory) {
      if (typology.find((tl) => tl === category)) {
        onChange(typology.filter((tl) => tl !== category))
      } else {
        onChange([...typology, category])
      }
    } else {
      const existingOther = typology.filter((e) => {
        let currCategory = true
        for (let item in projectCategories) {
          if (projectCategories[item].value === e) currCategory = false
        }
        return currCategory
      })

      const existingOtherStr = existingOther.toString()

      let updatedTopology = typology.filter(
        (item) => !item.includes(existingOtherStr)
      )

      if (!existingOtherStr) updatedTopology = typology.filter((item) => item)

      onChange(
        [...updatedTopology, category].filter((typology) => typology.length > 0)
      )
    }
  }

  return <View error={error} typology={typology} onChange={handleChange} />
}

export default ProjectTypology
