import styled from "styled-components"
import { Dialog, Typography, Box } from "@material-ui/core"
import theme from "../../theme"

export const RequestDialog = styled(Dialog)`
  min-height: 100vh;
`

export const RequestDialogTitle = styled(Typography)`
  font-size: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #dbdbdb;
  padding: 0 20px;
`

export const RequestDialogBox = styled(Box)`
  width: 700px;
  overflow-x: hidden;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
    padding: 36px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 12px;
    width: 250px;
    padding: 16px;
  }

  ${theme.breakpoints.down(350)} {
    font-size: 12px;
    width: 250px;
    padding: 16px;
  }
`
export const ButtonBox = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 0 20px 32px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    && button {
      width: 100% !important;
    }
  }
`

export const DialogBody = styled(Box)`
  padding: 32px 20px;
  display: flex;
  gap: 16px;
`

export const LeftBox = styled(Box)`
  && img {
    width: 56px;
    height: 56px;
    object-fit: cover;
  }
`

export const RightBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;

  && p {
    font-family: Public-Sans-Bold;
    font-size: 20px;
  }

  && span {
    font-size: 16px;
  }
`
