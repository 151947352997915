import React, { useState } from "react"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import CloseIcon from "../../../images/x-default.svg"
import CloseIconHover from "../../../images/x-hover.svg"
import {
  DialogButtonBox,
  DialogCloseButton,
  ImageBox,
  PostDialog,
  PostDialogBox,
  PostDialogTitle,
} from "./DialogStyles"

import "./fix-dialog.css"
import { Button } from "../../../components"
import { News, updateNews } from "../../../redux/adminNews"
import { useAppDispatch } from "../../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"

export type Props = {
  news: News
  onClose: () => void
  open: boolean
}

const Dialog = ({ news, onClose, open }: Props) => {
  const [hover, setHover] = useState(false)
  const [status, setStatus] = useState(news.published)
  const [imageUrl, setImageUrl] = useState(news.imageUrl)
  const [description, setDescription] = useState(news.description)
  const [title, setTitle] = useState(news.title)

  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const handleSubmit = async () => {
    try {
      const { type } = await dispatch(
        updateNews({
          auth,
          newsId: news.id!,
          imageUrl,
          published: status ?? false,
          title,
          description,
        })
      )
      if (updateNews.fulfilled.type === type) {
        onClose()
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <PostDialog
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
      className={"AdminNewsDialog"}
    >
      <PostDialogTitle>
        <Box>Review News Article</Box>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </PostDialogTitle>
      <PostDialogBox>
        <Box>
          <b>Title:</b> {news.title}
        </Box>
        <Box paddingTop={1}>
          <b>Author:</b> {news.author}
        </Box>
        <Box paddingTop={1}>
          <b>Article date:</b>{" "}
          {new Date(news.date!).toLocaleDateString("en-us")}
        </Box>
        <Box paddingTop={1}>
          <b>Article Link:</b>{" "}
          <a href={news.url} target="_blank" rel="noreferrer">
            {news.url}
          </a>
        </Box>

        <ImageBox paddingTop={1}>
          <b>Image:</b> <br />
          {news.imageUrl ? (
            <img src={news.imageUrl} alt={news.title} />
          ) : (
            "Image not found"
          )}
        </ImageBox>

        {news.description && (
          <Box paddingTop={2} paddingBottom={2} whiteSpace={"pre-line"}>
            <b>Content: </b>
            <br />
            {news.description}
          </Box>
        )}

        <Box display={"flex"} gridColumnGap={"8px"}>
          <TextField
            label="Title"
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
          />
        </Box>
        <Box display={"flex"} gridColumnGap={"8px"}>
          <TextField
            label="Content"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Box>
        <Box display={"flex"} gridColumnGap={"8px"}>
          <TextField
            label="Image URL"
            value={imageUrl}
            onChange={(e: any) => setImageUrl(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="form-status">Status</InputLabel>
            <Select
              labelId="form-status"
              value={status ? "published" : "not-published"}
              label="Status"
              onChange={(event: any) =>
                setStatus(event.target.value === "published")
              }
            >
              <MenuItem value={"published"}>Published</MenuItem>
              <MenuItem value={"not-published"}>Not Published</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <DialogButtonBox>
          <Button
            onClick={onClose}
            children={"Close"}
            color={"white"}
            size={"medium"}
            style={{
              width: "100%",
            }}
          />
          <Button
            onClick={handleSubmit}
            children={"Save"}
            color={"secondary"}
            size={"medium"}
            style={{
              width: "100%",
            }}
          />
        </DialogButtonBox>
      </PostDialogBox>
    </PostDialog>
  )
}

export default Dialog
