import React from "react"
import { useHistory } from "react-router-dom"

import { Button } from "../../components"

import headerImage1 from "../../images/new-homepage/header-01.jpg"
import headerImage2 from "../../images/new-homepage/header-02.jpg"
import headerImage3 from "../../images/new-homepage/header-03.jpg"
import headerImage4 from "../../images/new-homepage/header-04.jpg"
import headerImage5 from "../../images/new-homepage/header-05.jpg"

import {
  HeaderBox,
  HeaderContainer,
  HeaderButtonBox,
  HeaderImages,
  HeaderInnerBox,
  FirstImagesBox,
  SecondImagesBox,
  SecondImagesInnerBox,
  StyledImage1,
  StyledImage2,
  StyledImage3,
  StyledImage4,
  StyledImage5,
  HeaderTitle,
  HeaderCopy,
} from "./styles"

const Header = () => {
  const history = useHistory()

  const goToRegister = () => {
    history.push("/register")
  }

  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <HeaderTitle variant={"h1"}>
            The professional <span>network for design</span>{" "}
            <span>and architecture</span>
          </HeaderTitle>
          <HeaderCopy>
            Display your portfolio, elevate your profile and get your work
            featured in our curated selection of projects. Discover and connect
            with architects and firms around the globe. Seek inspiration. Find
            jobs. Hire talent. Spectacular is built by architects for
            architects.
          </HeaderCopy>
          <HeaderButtonBox>
            <Button
              onClick={goToRegister}
              children={"Sign up"}
              color={"secondary"}
              size={"medium"}
              style={{
                width: "116px",
                height: "48px",
                fontSize: "16px",
              }}
            />
          </HeaderButtonBox>
        </HeaderContainer>
        <HeaderImages>
          <FirstImagesBox>
            <StyledImage1 src={headerImage1} alt={"Header"} />
            <StyledImage2 src={headerImage2} alt={"Header"} />
          </FirstImagesBox>
          <SecondImagesBox>
            <SecondImagesInnerBox>
              <StyledImage3 src={headerImage3} alt={"Header"} />
              <StyledImage4 src={headerImage4} alt={"Header"} />
            </SecondImagesInnerBox>
            <StyledImage5 src={headerImage5} alt={"Header"} />
          </SecondImagesBox>
        </HeaderImages>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
