import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { ProjectMainFeature } from "./adminProjectMainFeature"
import { ProjectReward } from "./adminProjectReward"
import { Profile } from "./profile"
import { RootState } from "./rootReducer"

//#region types
export type Image = {
  id?: string
  url?: string
  file?: any
  order: number
  caption?: string
  credits?: string
  thumbnailImageUrl?: string
  largeImageUrl?: string
  width?: number
  height?: number
  dimensions?: { width: number; height: number }
}

export class ImageUtils {
  public static getSrc = (img?: Image) => {
    if (img) {
      if (img.url) return img.url
      else if (img.largeImageUrl) return img.largeImageUrl
      else return img.thumbnailImageUrl!
    } else return "" // TODO: need fallback image
  }

  public static getThumbnailSrc = (img?: Image) => {
    if (img) {
      if (img.thumbnailImageUrl) return img.thumbnailImageUrl
      else if (img.url) return img.url
      else return img.thumbnailImageUrl!
    } else return "" // TODO: need fallback image
  }

  public static getLargeThumbnailSrc = (img?: Image) => {
    if (img) {
      if (img.largeImageUrl) return img.largeImageUrl
      else if (img.url) return img.url
      else return img.thumbnailImageUrl!
    } else return "" // TODO: need fallback image
  }

  public static shouldAutoWidth = (img?: Image) => {
    if (!img) return true

    if (img.dimensions) {
      const { width, height } = img.dimensions
      if (height > 600) {
        if (width >= 1000) return true

        return false
      }
    }

    return true // default to true
  }
}

export type CommentMention = {
  id?: string
  profileId?: string
  username?: string
  name?: string
  fullString?: string
  createdAt?: Date
}

export type AwardSubmission = {
  campaign?: string
  type?: string
  category?: string
}

export type AwardsWinners = {
  student: Project[]
  professional: Project[]
  winners: Project[]
}

export type Comment = {
  id?: string
  authorId?: string
  projectId?: string
  parentCommentId?: string
  text: string
  author?: Profile
  createdAt?: Date
  updatedAt?: Date
  edited?: boolean
  hidden?: boolean
  replies?: Comment[]
  mentions?: CommentMention[]
  likes?: number
  liked?: boolean
}

export type Collaborator = {
  id?: string
  cleanId?: string
  profile?: Profile
  project?: Project
  status?: string
  discipline?: string
  createdAt?: string
  name?: string
  company?: string
  profileId?: string
  placeholderAvatar?: number
  invite?: Invite
  isAmbassador?: boolean
}

export type Invite = {
  email: string
  discipline?: string
  company?: string
}

export type Project = {
  id?: string
  name: string
  description: string
  responsibilities?: string
  location?: string
  category?: string[]
  state: string
  stateDate?: Date
  profile?: Profile
  images?: Array<Image>
  removedImages?: Array<string>
  createdAt?: string
  commentsCount?: number
  commented?: boolean
  likes?: number
  liked?: boolean
  canBeLiked?: boolean
  shares?: number
  shared?: boolean
  canBeShared?: boolean
  nominations?: number
  nominated?: boolean
  canBeNominated?: boolean
  role?: string
  comments?: Comment[]
  features?: number
  featured?: boolean
  featuredAt?: string
  tags?: string[]
  tools?: string[]
  keyMaterials?: string[]
  mainFeature?: ProjectMainFeature[]
  published?: boolean
  updatedAt?: Date
  publishedAt?: Date
  type?: string
  firmName?: string
  canBeEdited?: boolean
  username?: string
  imageUrl?: string
  medImageUrl?: string
  smallImageUrl?: string
  bluredImageUrl?: string
  collaborators?: Collaborator[]
  invites?: Invite[]
  rewards?: ProjectReward[]
  awardsSubmission?: AwardSubmission[]
  thumbnailWidth?: number
  thumbnailHeight?: number
  flags?: ProjectFlag[]
}

export type ProjectFlag = {
  id: string
  reason: string
  status: string
  otherDescription: string
  createdAt: Date
  profile: Profile
  reviewer: Profile
  reviewedAt: Date
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type HomeProjects = {
  projects: Project[]
  count: number
}

type HomeProjectsTypes = {
  popular: HomeProjects
  recent: HomeProjects
  following: HomeProjects
  awards: HomeProjects
}

export type CompetitionProject = {
  groupName: string
  projects: Project[]
}

type SliceState = {
  projects: Project[]
  allProjects: Project[]
  competitionProjects: CompetitionProject[]
  allProjectsCurrentPage: number
  currentFilter: any
  homeHeroProject: Project[]
  homeFeaturedProjects: Project[]
  homeLatestProjects: Project[]
  home: HomeProjectsTypes
  projectsCount: number
  newProjectsCount: number
  project: Project | null | undefined
  status: LoadingStatuses
  error: string | null | undefined
  tagSuggestions: string[]
  projectPlaceholder: string[]
  awardsWinners?: AwardsWinners
}
//#endregion

//#region api
type NewFetchProjectsPayload = {
  email?: string
  page?: number
  perPage?: number
  search?: string
  myNetwork?: boolean
  typology?: string[]
  tools?: string[]
  keyMaterials?: string[]
  sortBy: string
}
export const newFetchProjects = createAsyncThunk<any, NewFetchProjectsPayload>(
  "projects/newFetchProjects",
  async ({
    email,
    page = 1,
    perPage = 8,
    search = "",
    myNetwork,
    typology,
    tools,
    keyMaterials,
    sortBy,
  }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/newProjects?skip=${page * perPage}&limit=${perPage}`

    if (search) endpoint += `&search=${search}`
    if (myNetwork) endpoint += `&myNetwork=true`
    if (typology) endpoint += `&typology=${JSON.stringify(typology)}`
    if (tools) endpoint += `&tools=${JSON.stringify(tools)}`
    if (keyMaterials)
      endpoint += `&keyMaterials=${JSON.stringify(keyMaterials)}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type FetchProjectsPayload = {
  email?: string
  page?: number
  perPage?: number
  search?: string
  sortBy?: string
  onlyFeatured?: boolean
  pastFeatured?: boolean
  onlyFollowing?: boolean
  typology?: string[]
  tools?: string[]
  keyMaterials?: string[]
  tags?: string[]
  action?: string
  competition?: string
}
export const fetchProjects = createAsyncThunk<any, FetchProjectsPayload>(
  "projects/fetchProjects",
  async ({
    email,
    page = 1,
    perPage = 10,
    sortBy = "score",
    search = "",
    onlyFeatured,
    pastFeatured,
    onlyFollowing,
    competition,
    typology,
    tools,
    keyMaterials,
    tags,
    action,
  }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/projects?skip=${
      page * perPage
    }&limit=${perPage}&sortBy=${sortBy}`

    if (search) endpoint += `&search=${search}`
    if (onlyFeatured) endpoint += `&onlyFeatured=true`
    if (pastFeatured) endpoint += `&pastFeatured=true`
    if (onlyFollowing) endpoint += `&onlyFollowing=true`
    if (competition) endpoint += `&competition=${competition}`
    if (typology) endpoint += `&typology=${typology}`
    if (tools) endpoint += `&tools=${tools}`
    if (keyMaterials) endpoint += `&keyMaterials=${keyMaterials}`
    if (tags) endpoint += `&tags=${tags}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type FetchSimpleProjectsPayload = {
  email?: string
  page?: number
  perPage?: number
}

export const fetchHomeHeroProject = createAsyncThunk<
  any,
  FetchSimpleProjectsPayload
>(
  "projects/fetchSimpleMainFeatureProject",
  async ({ email, page = 1, perPage = 10 }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/simple_projects?skip=${
      page * perPage
    }&limit=${perPage}&sortBy=createdAt&heroFeature=true`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)
export const fetchHomeFeaturedProjects = createAsyncThunk<
  any,
  FetchSimpleProjectsPayload
>(
  "projects/fetchSimpleFeaturedProjects",
  async ({ email, page = 1, perPage = 10 }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/simple_projects?skip=${
      page * perPage
    }&limit=${perPage}&sortBy=recentlyUpdated&onlyFeatured=true`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

export const fetchHomeLatestProjects = createAsyncThunk<
  any,
  FetchSimpleProjectsPayload
>(
  "projects/fetchSimpleLatestProjects",
  async ({ email, page = 1, perPage = 10 }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/simple_projects?skip=${
      page * perPage
    }&limit=${perPage}&sortBy=recentlyUpdated&filterProfile=true`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type FetchProjectPayload = {
  id: string
  email?: string
}
export const fetchProject = createAsyncThunk<any, FetchProjectPayload>(
  "projects/fetchProject",
  async ({ id, email }) => {
    return useApi(null, `/projects/${id}?email=${email}`).then((res) =>
      res.json()
    )
  }
)

type CreateProjectPayload = {
  auth: Auth0ContextInterface
  project: Project
  published: boolean
}
export const createProject = createAsyncThunk<any, CreateProjectPayload>(
  "projects/createProject",
  async ({ auth, project, published }) => {
    const form = new FormData()
    form.append("name", project.name)
    form.append("description", project.description)
    project.responsibilities &&
      form.append("responsibilities", project.responsibilities)
    project.location && form.append("location", project.location)
    form.append("category", JSON.stringify(project.category as string[]))
    if (project.state === "") {
      form.append("state", "unbuilt")
    } else form.append("state", project.state)
    project.stateDate && form.append("stateDate", project.stateDate.toString())
    form.append("role", project.role?.replace("Other:", "").trim() || "")
    const tags = JSON.stringify(project.tags as string[])
    const lowercaseTags = tags.toLowerCase()

    form.append("tags", lowercaseTags)
    form.append("tools", JSON.stringify(project.tools as string[]))
    form.append("published", JSON.stringify(published))
    form.append(
      "keyMaterials",
      JSON.stringify(project.keyMaterials as string[])
    )

    project.images?.forEach((image) => {
      form.append(`image-${image.order}`, image.file)
    })

    project.type && form.append("type", project.type)
    project.firmName && form.append("firmName", project.firmName)

    form.append("origin", window.location.origin)

    form.append("collaborators", JSON.stringify(project.collaborators))
    form.append("invites", JSON.stringify(project.invites))

    return useApi(auth, "/projects", {
      method: "POST",
      body: form,
      headers: {
        "Content-Type": "Automatic",
      },
    }).then(async (res) => {
      try {
        const result = await res.json()
        return Promise.resolve(result)
      } catch (error) {
        throw new Error("There was an error processing your request.")
      }
    })
  }
)

type UpdateProjectPayload = {
  auth: Auth0ContextInterface
  project: Project
  published: boolean
}
export const updateProject = createAsyncThunk<any, UpdateProjectPayload>(
  "projects/updateProject",
  async ({ auth, project, published }) => {
    const form = new FormData()
    form.append("name", project.name)
    form.append("description", project.description)
    project.responsibilities !== null &&
      project.responsibilities !== undefined &&
      form.append("responsibilities", project.responsibilities)
    form.append("category", JSON.stringify(project.category as string[]))
    if (project.state === "") {
      form.append("state", "unbuilt")
    } else form.append("state", project.state)
    project.stateDate && form.append("stateDate", project.stateDate.toString())
    form.append("role", project.role?.replace("Other:", "").trim() || "")
    if (!project.location) form.append("location", "")
    else form.append("location", project.location)
    const tags = JSON.stringify(project.tags as string[])
    const lowercaseTags = tags.toLowerCase()

    form.append("tags", lowercaseTags)
    form.append("tools", JSON.stringify(project.tools as string[]))
    form.append(
      "keyMaterials",
      JSON.stringify(project.keyMaterials as string[])
    )
    form.append("published", JSON.stringify(published))

    form.append("origin", window.location.origin)

    form.append("collaborators", JSON.stringify(project.collaborators))

    project.type && form.append("type", project.type)

    project.firmName && form.append("firmName", project.firmName)

    const projectImages = project.images ?? []
    const existingImages = projectImages.filter((image) => !image.file)
    //const newImages = projectImages.filter((image) => image.file)

    existingImages.map((image) =>
      form.append(
        "images[]",
        JSON.stringify({
          id: image.id,
          order: image.order,
          caption: image.caption,
          credits: image.credits,
        })
      )
    )
    //New images are uploaded in ReactJS
    /*
    newImages.forEach((image) =>
      form.append(`image-${image.order}`, image.file)
    )
    */

    project.removedImages?.forEach((id) => form.append("removedImages[]", id))

    return useApi(auth, `/projects/${project.id}`, {
      method: "PATCH",
      body: form,
      headers: {
        "Content-Type": "Automatic",
      },
    }).then((res) => res.json())
  }
)

type DeleteProjectPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteProject = createAsyncThunk<any, DeleteProjectPayload>(
  "projects/deleteProject",
  async ({ auth, id }) => {
    return useApi(auth, `/projects/${id}`, {
      method: "DELETE",
    }).then((res) => res.text())
  }
)
type AddCommentPayload = {
  auth: Auth0ContextInterface
  id: string
  text: string
  parentCommentId?: string
  mentions?: CommentMention[]
}
export const addComment = createAsyncThunk<any, AddCommentPayload>(
  "projects/addComment",
  async ({ auth, id, text, parentCommentId, mentions }) => {
    return useApi(auth, `/projects/${id}/comments`, {
      method: "POST",
      body: JSON.stringify({
        text,
        parentCommentId,
        mentions,
        origin: window.location.origin,
      }),
    }).then((res) => res.json())
  }
)

type UpdateCommentPayload = {
  auth: Auth0ContextInterface
  projectId: string
  commentId: string
  profileId: string
  text?: string
  hidden?: boolean
  mentions?: CommentMention[]
}
export const updateComment = createAsyncThunk<any, UpdateCommentPayload>(
  "projects/updateComment",
  async ({ auth, projectId, profileId, commentId, text, hidden, mentions }) => {
    return useApi(auth, `/projects/${projectId}/comments/${commentId}`, {
      method: "PATCH",
      body: JSON.stringify({ text, hidden, mentions }),
    }).then((res) => res.json())
  }
)

type ShareProjectPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const shareProject = createAsyncThunk<any, ShareProjectPayload>(
  "projects/share",
  async ({ auth, id }) => {
    return useApi(auth, `/projects/${id}/share`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type LikeProjectPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likeProject = createAsyncThunk<any, LikeProjectPayload>(
  "projects/like",
  async ({ auth, id }) => {
    return useApi(auth, `/projects/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikeProjectPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikeProject = createAsyncThunk<any, DislikeProjectPayload>(
  "projects/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/projects/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type LikeCommentPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likeComment = createAsyncThunk<any, LikeCommentPayload>(
  "projects/comment/like",
  async ({ auth, id }) => {
    return useApi(auth, `/comments/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikeCommentPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikeComment = createAsyncThunk<any, DislikeCommentPayload>(
  "projects/comment/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/comments/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type NominateProjectPayload = {
  auth: Auth0ContextInterface
  id: string
  hide?: boolean
  lastNominated?: string
  lastNominationDate?: Date
}
export const nominateProject = createAsyncThunk<any, NominateProjectPayload>(
  "projects/nominate",
  async ({ auth, id, hide }) => {
    return useApi(auth, `/projects/${id}/nominate`, {
      method: "POST",
      body: JSON.stringify({ hide, origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

export const unnominateProject = createAsyncThunk<any, NominateProjectPayload>(
  "projects/unnominate",
  async ({ auth, id }) => {
    return useApi(auth, `/projects/${id}/nominate`, {
      method: "DELETE",
    }).then((res) => res.json())
  }
)

type GetProjectTagsSuggestionsPayload = {
  auth: Auth0ContextInterface
}
export const getProjectTagSuggestions = createAsyncThunk<
  any,
  GetProjectTagsSuggestionsPayload
>("projects/getProjectTagSuggestions", async ({ auth }) => {
  return useApi(auth, "/projects/tags/suggestions", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type FetchS3SignedURL = {
  auth: Auth0ContextInterface
  uploadType: string
  contentId: string
  fileName: string
  fileType: string
  order: number
  credits?: string
  caption?: string
  width?: number
  height?: number
  profileId?: string
}

export const getS3SignedURL = createAsyncThunk<any, FetchS3SignedURL>(
  "projects/getS3SignedURL",
  async ({
    auth,
    uploadType,
    contentId,
    fileName,
    fileType,
    order,
    credits,
    caption,
    width,
    height,
    profileId,
  }) => {
    const upload: any = {}
    if (uploadType && fileName && fileType) {
      upload.uploadType = uploadType
      upload.contentId = contentId
      upload.fileName = fileName
      upload.fileType = fileType
      upload.order = order
      upload.credits = credits
      upload.caption = caption
      upload.width = width
      upload.height = height
      upload.profileId = profileId
    }

    if (Object.keys(upload).length > 0 && upload.constructor === Object) {
      return useApi(auth, "/sign_s3", {
        method: "POST",
        body: JSON.stringify(upload),
      }).then((res) => res.json())
    }
  }
)
type GetProjectPlaceholderPayload = {
  auth: Auth0ContextInterface
}
export const getProjectPlaceholder = createAsyncThunk<
  any,
  GetProjectPlaceholderPayload
>("projects/getProjectPlaceholder", async ({ auth }) => {
  return useApi(auth, "/projects/create_placeholder", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type UpdateImageDetailPayload = {
  auth: Auth0ContextInterface
  id: string
  credits?: string
  caption?: string
}
export const updateImageDetail = createAsyncThunk<
  any,
  UpdateImageDetailPayload
>("projects/updateImageDetail", async ({ auth, id, credits, caption }) => {
  return useApi(auth, `/projects/image/${id}`, {
    method: "PATCH",
    body: JSON.stringify({ credits, caption }),
  }).then((res) => res.text())
})

type ValidateProjectUrlParameters = {
  projectLink: string
}

export const validateProjectUrl = createAsyncThunk<
  any,
  ValidateProjectUrlParameters
>("projects/validateProjectUrl", async ({ projectLink }) => {
  const form = {
    projectLink,
  }

  return useApi(null, "/projects/validate-url", {
    method: "POST",
    body: JSON.stringify(form),
  }).then((res) => res.json())
})

type ValidateIfProjectWasAlreadySubmittedParameters = {
  projectLink: string
  campaign?: string
}

export const validateIfProjectWasAlreadySubmitted = createAsyncThunk<
  any,
  ValidateIfProjectWasAlreadySubmittedParameters
>(
  "projects/ValidateIfProjectWasAlreadySubmittedParameters",
  async ({ projectLink, campaign }) => {
    const form = {
      projectLink,
      campaign,
    }

    return useApi(null, "/projects/awards/already-submitted", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => res.text())
  }
)

type SubmitInnovationAwardsPayload = {
  auth: Auth0ContextInterface
  name?: string
  projectLink: string
  projectTitle?: string
  schoolName?: string
  code: string
}

export const submitInnovationAwards = createAsyncThunk<
  any,
  SubmitInnovationAwardsPayload
>(
  "projects/submitInnovationAwards",
  async ({ auth, name, projectLink, projectTitle, code, schoolName }) => {
    const form = {
      name,
      projectLink,
      projectTitle,
      origin: window.location.origin,
      code,
      schoolName,
    }

    return useApi(auth, "/projects/innovation-awards", {
      method: "POST",
      body: JSON.stringify(form),
    }).then((res) => res.text())
  }
)

type FetchHomePageProjectsPayload = {
  type?: "following" | "recent" | "popular" | "awards"
  perPage?: number
  page?: number
  email?: string
}

export const fetchHomePageProjects = createAsyncThunk<
  any,
  FetchHomePageProjectsPayload
>(
  "projects/fetchHomePageProjects",
  async ({ email, type, page = 1, perPage = 10 }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/projects/homepage?skip=${
      page * perPage
    }&limit=${perPage}&type=${type}`
    if (email) endpoint += `&email=${email}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type ConfirmCollaboratorPayload = {
  auth: Auth0ContextInterface
  id: string
}

export const confirmCollaborator = createAsyncThunk<
  any,
  ConfirmCollaboratorPayload
>("projects/confirmCollaborator", async ({ auth, id }) => {
  return useApi(auth, `/projects/${id}/collaborator/confirm`, {
    method: "POST",
    body: JSON.stringify({}),
  }).then((res) => res.json())
})

type RejectCollaboratorPayload = {
  auth: Auth0ContextInterface
  id: string
}

export const rejectCollaborator = createAsyncThunk<
  any,
  RejectCollaboratorPayload
>("projects/rejectCollaborator", async ({ auth, id }) => {
  return useApi(auth, `/projects/${id}/collaborator/reject`, {
    method: "POST",
    body: JSON.stringify({}),
  }).then((res) => res.json())
})

type InviteCollaboratorPayload = {
  auth: Auth0ContextInterface
  projectId: string
  email: string
  discipline?: string
  company?: string
}
export const inviteCollaborator = createAsyncThunk<
  any,
  InviteCollaboratorPayload
>(
  "projects/inviteCollaborator",
  async ({ auth, projectId, email, discipline, company }) => {
    return useApi(auth, `/project/inviteCollaborator`, {
      method: "POST",
      body: JSON.stringify({ projectId, email, discipline, company }),
    }).then((res) => res.json())
  }
)

type ValidateInvitationEmailPayload = {
  auth: Auth0ContextInterface
  projectId: string
  email: string
}
export const validateInvitationEmail = createAsyncThunk<
  any,
  ValidateInvitationEmailPayload
>("projects/validateInvitationEmail", async ({ auth, projectId, email }) => {
  return useApi(auth, `/project/validateInvitationEmail`, {
    method: "POST",
    body: JSON.stringify({ projectId, email }),
  }).then((res) => res.text())
})

type FetchAwardsWinnersPayload = {
  campaign: string
}

export const fetchAwardsWinners = createAsyncThunk<
  any,
  FetchAwardsWinnersPayload
>("projects/fetchAwardsWinners", async ({ campaign }) => {
  return useApi(null, `/projects/innovation-awards-winners/${campaign}`, {
    method: "GET",
  }).then((res) => res.json())
})

type FlagProjectPayload = {
  auth: Auth0ContextInterface
  projectId: string
  reason: string
  otherDescription?: string
}
export const flagProject = createAsyncThunk<any, FlagProjectPayload>(
  "projects/flag",
  async ({ auth, projectId, reason, otherDescription }) => {
    const data = {
      projectId,
      reason,
      otherDescription,
      origin: window.location.origin,
    }

    return useApi(auth, `/flags`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  projects: [],
  allProjects: [],
  competitionProjects: [],
  allProjectsCurrentPage: 1,
  currentFilter: [],
  homeHeroProject: [],
  homeFeaturedProjects: [],
  homeLatestProjects: [],
  awardsWinners: undefined,
  home: {
    popular: {
      projects: [],
      count: 0,
    },
    recent: {
      projects: [],
      count: 0,
    },
    following: {
      projects: [],
      count: 0,
    },
    awards: {
      projects: [],
      count: 0,
    },
  },
  projectsCount: 0,
  newProjectsCount: 0,
  project: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
  tagSuggestions: [],
  projectPlaceholder: [],
}

export default createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state, action) => {
        state.status = LoadingStatuses.Loading
        state.error = null
        state.project = null
        state.currentFilter =
          action.meta.arg.action === "FETCH" ||
          action.meta.arg.action === "SEARCH"
            ? []
            : state.currentFilter
        state.allProjects =
          action.meta.arg.page === 0 ||
          action.meta.arg.page === 1 ||
          action.meta.arg.action === "FETCH" ||
          action.meta.arg.action === "SEARCH"
            ? []
            : state.allProjects

        state.competitionProjects = []
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.projectsCount = action.payload.count
        state.projects = action.payload.data

        state.allProjectsCurrentPage = action.meta.arg.page
          ? action.meta.arg.page
          : 1

        state.currentFilter = {
          competition: action.meta.arg.competition,
          onlyFollowing: action.meta.arg.onlyFollowing,
          search: action.meta.arg.search,
          tools: action.meta.arg.tools,
          keyMaterials: action.meta.arg.keyMaterials,
          typology: action.meta.arg.typology,
          tags: action.meta.arg.tags,
        }

        state.allProjects =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.allProjects?.concat(action.payload.data)

        if (action.meta.arg.competition?.includes("studio-awards")) {
          const competitionProjects = [...state.competitionProjects]

          action.payload.data.forEach((row: any) => {
            const index = competitionProjects.findIndex((project) => {
              return project.groupName === row.firmName
            })

            if (index > -1) {
              competitionProjects[index].projects.push(row)
            } else {
              const newRow = {
                groupName: row.firmName,
                projects: [row],
              }
              competitionProjects.push(newRow)
            }
          })

          state.competitionProjects = competitionProjects
        }

        state.error = null
      })

      .addCase(newFetchProjects.pending, (state, action) => {
        state.status = LoadingStatuses.Loading
        state.error = null
        state.project = null

        if (action.meta.arg.page === 0 || action.meta.arg.page === 1) {
          state.allProjects = []
          state.newProjectsCount = 0
        }
      })
      .addCase(newFetchProjects.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.projectsCount = action.payload.count
        state.projects = action.payload.data

        state.newProjectsCount =
          state.newProjectsCount + action.payload.data.length

        state.allProjects =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.allProjects?.concat(action.payload.data)

        state.error = null
      })

      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.error = null
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.error = null
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.error = null
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(fetchProject.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.project = null
        state.error = null
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.project = action.payload
      })
      .addCase(fetchProject.rejected, (state, _action) => {
        state.status = LoadingStatuses.Failed
        state.project = null
      })
      .addCase(fetchHomeLatestProjects.fulfilled, (state, action) => {
        state.homeLatestProjects = action.payload.data
      })
      .addCase(fetchHomeLatestProjects.rejected, (state, _action) => {
        state.homeLatestProjects = []
      })
      .addCase(fetchHomeHeroProject.fulfilled, (state, action) => {
        state.homeHeroProject = action.payload.data
      })
      .addCase(fetchHomeHeroProject.rejected, (state, _action) => {
        state.homeHeroProject = []
      })
      .addCase(fetchHomeFeaturedProjects.fulfilled, (state, action) => {
        state.homeFeaturedProjects = action.payload.data
      })
      .addCase(fetchHomeFeaturedProjects.rejected, (state, _action) => {
        state.homeFeaturedProjects = []
      })
      .addCase(fetchAwardsWinners.pending, (state, _action) => {
        state.awardsWinners = undefined
      })
      .addCase(fetchAwardsWinners.fulfilled, (state, action) => {
        state.awardsWinners = action.payload
      })
      .addCase(confirmCollaborator.fulfilled, (state, action) => {
        if (state.project && state.project.collaborators) {
          let collaborators = [...state.project.collaborators]
          collaborators.map((collaborator) => {
            if (action.meta.arg.id === collaborator.id)
              collaborator.status = "confirmed"

            return collaborator
          })

          state.project.collaborators = collaborators
        }
      })
      .addCase(rejectCollaborator.fulfilled, (state, action) => {
        if (state.project && state.project.collaborators) {
          let collaborators = [...state.project.collaborators].filter(
            (collaborator) => action.meta.arg.id !== collaborator.id
          )

          state.project.collaborators = collaborators
        }
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          if (action.meta.arg.parentCommentId && state.project.comments) {
            const updatedCommentsReplies = [...state.project.comments]
            updatedCommentsReplies.forEach((comment) => {
              if (comment.id === action.meta.arg.parentCommentId) {
                const newReply = action.payload
                if (action.meta.arg.mentions) {
                  newReply.mentions = action.meta.arg.mentions
                }
                comment.replies!.unshift(newReply)
              }
            })

            state.project.commentsCount!++
            state.project.commented = true
          } else {
            const newComments = [...state.project!.comments!, action.payload]
            const project = {
              ...state.project,
              comments: newComments,
            } as Project
            state.project = project
            state.project.commentsCount!++
            state.project.commented = true
          }
        }

        const updatedProjects = [...state.projects]
        updatedProjects.forEach((project) => {
          if (project.id! === action.payload.projectId) {
            project.commentsCount!++
          }
        })
        state.projects = updatedProjects

        if (state.home) {
          const updatedProjectsFollowing = [...state.home.following.projects]
          updatedProjectsFollowing.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && project.comments) {
                const updatedCommentsRepliesFollowing = [...project.comments]
                updatedCommentsRepliesFollowing.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                project.comments = updatedCommentsRepliesFollowing
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                project.comments!.unshift(newComment)
              }

              project.commentsCount!++
              project.commented = true
            }
          })
          state.home.following.projects = updatedProjectsFollowing

          const updatedProjectsPopular = [...state.home.popular.projects]
          updatedProjectsPopular.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && project.comments) {
                const updatedCommentsRepliesPopular = [...project.comments]
                updatedCommentsRepliesPopular.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                project.comments = updatedCommentsRepliesPopular
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                project.comments!.unshift(newComment)
              }

              project.commentsCount!++
              project.commented = true
            }
          })
          state.home.popular.projects = updatedProjectsPopular

          const updatedProjectsRecent = [...state.home.recent.projects]
          updatedProjectsRecent.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && project.comments) {
                const updatedCommentsRepliesRecent = [...project.comments]
                updatedCommentsRepliesRecent.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                project.comments = updatedCommentsRepliesRecent
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                project.comments!.unshift(newComment)
              }

              project.commentsCount!++
              project.commented = true
            }
          })

          state.home.recent.projects = updatedProjectsRecent

          const updatedProjectsAwards = [...state.home.awards.projects]
          updatedProjectsAwards.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && project.comments) {
                const updatedCommentsRepliesAwards = [...project.comments]
                updatedCommentsRepliesAwards.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                project.comments = updatedCommentsRepliesAwards
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                project.comments!.unshift(newComment)
              }

              project.commentsCount!++
              project.commented = true
            }
          })

          state.home.awards.projects = updatedProjectsAwards
        }

        if (state.allProjects) {
          const updatedAllProjects = [...state.allProjects]
          updatedAllProjects.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && project.comments) {
                const updatedCommentsRepliesAllProjects = [...project.comments]
                updatedCommentsRepliesAllProjects.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.payload
                    if (action.meta.arg.mentions) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                project.comments = updatedCommentsRepliesAllProjects
              } else {
                const newComment = action.payload
                if (action.meta.arg.mentions) {
                  newComment.mentions = action.meta.arg.mentions
                }
                project.comments!.unshift(newComment)
              }

              project.commentsCount!++
              project.commented = true
            }
          })

          state.allProjects = updatedAllProjects
        }
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const updatedComments = [...state.project!.comments!]
          updatedComments.forEach((comment) => {
            if (comment.id! === action.payload.id) {
              comment.text = action.payload.text

              if (action.payload.hidden !== comment.hidden) {
                comment.hidden = action.payload.hidden
              }

              if (action.meta.arg.mentions) {
                comment.mentions = action.meta.arg.mentions
              }
            }
          })

          const commentsWithoutHidden = updatedComments.filter((comment) => {
            if (!comment.hidden && !comment.author?.isAdmin) return comment

            return false
          })

          const project = {
            ...state.project,
            comments: commentsWithoutHidden,
            commentsCount: commentsWithoutHidden.length,
          } as Project
          state.project = project
        }

        const editedComment = action.payload
        const profileId = action.meta.arg.profileId

        if (state.allProjects) {
          const updatedProjects = [...state.allProjects]
          updatedProjects.forEach((project) => {
            if (project.comments && project.id! === action.meta.arg.projectId) {
              if (editedComment.parentCommentId) {
                const comments = [...project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })

                    comment.replies = comment.replies?.filter((reply) => {
                      if (!reply.hidden) return comment

                      return false
                    })
                  }
                  return comment
                })

                const commentsWithoutHidden = updatedComments.filter(
                  (comment) => {
                    if (!comment.hidden) return comment

                    return false
                  }
                )

                project.comments = commentsWithoutHidden
                project.commentsCount = commentsWithoutHidden.length
                project.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId && !comment.hidden
                )
              } else {
                const comments = [...project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })

                const commentsWithoutHidden = updatedComments.filter(
                  (comment) => {
                    if (!comment.hidden) return comment

                    return false
                  }
                )

                project.comments = commentsWithoutHidden
                project.commentsCount = commentsWithoutHidden.length
                project.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId && !comment.hidden
                )
              }
            }
          })

          state.allProjects = updatedProjects
        }
      })
      .addCase(likeProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const project = { ...state.project, liked: true } as Project
          project.likes!++
          state.project = project
        }

        if (state.home) {
          const updatedProjectsFollowing = [...state.home.following.projects]
          updatedProjectsFollowing.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })
          state.home.following.projects = updatedProjectsFollowing

          const updatedProjectsPopular = [...state.home.popular.projects]
          updatedProjectsPopular.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })
          state.home.popular.projects = updatedProjectsPopular

          const updatedProjectsRecent = [...state.home.recent.projects]
          updatedProjectsRecent.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })

          state.home.recent.projects = updatedProjectsRecent

          const updatedProjectsAwards = [...state.home.awards.projects]
          updatedProjectsAwards.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })

          state.home.awards.projects = updatedProjectsAwards
        }

        if (state.homeHeroProject) {
          const updatedProjectsHero = [...state.homeHeroProject]
          updatedProjectsHero.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })
          state.homeHeroProject = updatedProjectsHero
        }

        if (state.allProjects) {
          const updatedAllProjects = [...state.allProjects]
          updatedAllProjects.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!++
              project.liked = true
            }
          })

          state.allProjects = updatedAllProjects
        }
      })
      .addCase(dislikeProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const project = { ...state.project, liked: false } as Project
          project.likes!--
          state.project = project
        }

        if (state.home) {
          const updatedProjectsFollowing = [...state.home.following.projects]
          updatedProjectsFollowing.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })
          state.home.following.projects = updatedProjectsFollowing

          const updatedProjectsPopular = [...state.home.popular.projects]
          updatedProjectsPopular.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })
          state.home.popular.projects = updatedProjectsPopular

          const updatedProjectsRecent = [...state.home.recent.projects]
          updatedProjectsRecent.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })

          state.home.recent.projects = updatedProjectsRecent

          const updatedProjectsAwards = [...state.home.awards.projects]
          updatedProjectsAwards.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })

          state.home.awards.projects = updatedProjectsAwards
        }

        if (state.homeHeroProject) {
          const updatedProjectsHero = [...state.homeHeroProject]
          updatedProjectsHero.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })
          state.homeHeroProject = updatedProjectsHero
        }

        if (state.allProjects) {
          const updatedAllProjects = [...state.allProjects]
          updatedAllProjects.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.likes!--
              project.liked = false
            }
          })

          state.allProjects = updatedAllProjects
        }
      })
      .addCase(nominateProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const project = { ...state.project, nominated: true } as Project
          project.nominations!++
          state.project = project
        }

        const lastNominationDate = new Date(
          action.meta.arg.lastNominationDate as any
        )
        const now =
          new Date().getDate() +
          new Date().getMonth() +
          new Date().getFullYear()
        const lastNomination = action.meta.arg.lastNominationDate
          ? lastNominationDate.getDate() +
            lastNominationDate.getMonth() +
            lastNominationDate.getFullYear()
          : 0

        if (state.home) {
          const updatedProjectsFollowing = [...state.home.following.projects]
          updatedProjectsFollowing.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })
          state.home.following.projects = updatedProjectsFollowing

          const updatedProjectsPopular = [...state.home.popular.projects]
          updatedProjectsPopular.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })
          state.home.popular.projects = updatedProjectsPopular

          const updatedProjectsRecent = [...state.home.recent.projects]
          updatedProjectsRecent.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })

          state.home.recent.projects = updatedProjectsRecent

          const updatedProjectsAwards = [...state.home.awards.projects]
          updatedProjectsAwards.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })

          state.home.awards.projects = updatedProjectsAwards
        }

        if (state.homeHeroProject) {
          const updatedProjectsHero = [...state.homeHeroProject]
          updatedProjectsHero.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })
          state.homeHeroProject = updatedProjectsHero
        }

        if (state.allProjects) {
          const updatedAllProjects = [...state.allProjects]
          updatedAllProjects.forEach((project) => {
            if (
              now === lastNomination &&
              project.id! === action.meta.arg.lastNominated
            ) {
              project.nominations!--
              project.nominated = false
            }

            if (project.id! === action.meta.arg.id) {
              project.nominations!++
              project.nominated = true
            }
          })

          state.allProjects = updatedAllProjects
        }
      })
      .addCase(shareProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const project = { ...state.project, shared: true } as Project
          project.shares!++
          state.project = project
        }
      })
      .addCase(unnominateProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        if (state.project) {
          const project = { ...state.project, nominated: false } as Project
          project.nominations!--
          state.project = project
        }

        if (state.home) {
          const updatedProjectsFollowing = [...state.home.following.projects]
          updatedProjectsFollowing.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })
          state.home.following.projects = updatedProjectsFollowing

          const updatedProjectsPopular = [...state.home.popular.projects]
          updatedProjectsPopular.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })
          state.home.popular.projects = updatedProjectsFollowing

          const updatedProjectsRecent = [...state.home.recent.projects]
          updatedProjectsRecent.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })

          state.home.recent.projects = updatedProjectsRecent

          const updatedProjectsAwards = [...state.home.awards.projects]
          updatedProjectsAwards.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })

          state.home.awards.projects = updatedProjectsAwards
        }

        if (state.homeHeroProject) {
          const updatedProjectsHero = [...state.homeHeroProject]
          updatedProjectsHero.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })
          state.homeHeroProject = updatedProjectsHero
        }

        if (state.allProjects) {
          const updatedAllProjects = [...state.allProjects]
          updatedAllProjects.forEach((project) => {
            if (project.id! === action.meta.arg.id) {
              project.nominations!--
              project.nominated = false
            }
          })

          state.allProjects = updatedAllProjects
        }
      })
      .addCase(getProjectTagSuggestions.fulfilled, (state, action) => {
        state.tagSuggestions = action.payload
      })
      .addCase(getProjectPlaceholder.fulfilled, (state, action) => {
        state.projectPlaceholder = action.payload
      })
      .addCase(updateImageDetail.fulfilled, (state, action) => {
        state.error = null
      })
      .addCase(updateImageDetail.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(fetchHomePageProjects.fulfilled, (state, action) => {
        if (action.meta.arg.type) {
          state.home[action.meta.arg.type].count = action.payload.count

          state.home[action.meta.arg.type].projects =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.home[action.meta.arg.type].projects.concat(
                  action.payload.data
                )
        }
      })
      .addCase(likeComment.fulfilled, (state, action) => {
        if (state.project) {
          const updatedComments = [...state.project!.comments!]
          updatedComments.forEach((comment) => {
            if (comment.id! === action.meta.arg.id) {
              comment.liked = true
              comment.likes = comment.likes || 0 + 1
            }

            if (comment.replies) {
              comment.replies.forEach((reply) => {
                if (reply.id! === action.meta.arg.id) {
                  reply.liked = true
                  reply.likes = reply.likes || 0 + 1
                }
              })
            }
          })

          const project = {
            ...state.project,
            comments: updatedComments,
          } as Project
          state.project = project
        }
        const updatedProjects = [...state.projects]
        state.projects = updatedProjects
      })
      .addCase(dislikeComment.fulfilled, (state, action) => {
        if (state.project) {
          const updatedComments = [...state.project!.comments!]
          updatedComments.forEach((comment) => {
            if (comment.id! === action.meta.arg.id) {
              comment.liked = false
              comment.likes = comment.likes || 0 - 1
            }

            if (comment.replies) {
              comment.replies.forEach((reply) => {
                if (reply.id! === action.meta.arg.id) {
                  reply.liked = false
                  reply.likes = reply.likes || 0 - 1
                }
              })
            }
          })

          const project = {
            ...state.project,
            comments: updatedComments,
          } as Project
          state.project = project
        }
      })
  },
})
//#endregion

//#region selectors
export const selectAllProjects = ({ projects }: RootState) => projects.projects
export const selectProjectsStatus = ({ projects }: RootState) => projects.status
export const selectCompetitionProjects = ({ projects }: RootState) =>
  projects.competitionProjects
//#endregion
