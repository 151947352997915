import React, { useState } from "react"

import View from "./View"

type Props = {
  jobTitle?: string
  error?: string
  onChange: (newValue: string) => void
}

const suggestions = [
  "Project Architect",
  "Project Manager",
  "Principal",
  "Architectural Designer",
  "Architectural Associate",
  "Lead Designer",
  "Design Director",
  "Interior Designer",
  "Technical Lead",
  "Spec Writer",
  "Student",
  "Looking for work",
  "Other: ",
]

const ProfileJobTitle = ({ jobTitle, onChange, error }: Props) => {
  const [value, setValue] = useState<string>(jobTitle || "")

  const onInputChange = (newValue: string) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <View
      value={value}
      error={error}
      label="Job title *"
      suggestions={suggestions}
      onInputChange={onInputChange}
    />
  )
}

export default ProfileJobTitle
