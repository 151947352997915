import { Box } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const Button = styled(Box)`
  display: none;

  ${theme.breakpoints.down("sm")} {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px 0px rgba(218, 218, 218, 1);
    background-color: #fafafa;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    box-sizing: border-box;
    position: sticky;
    top: calc(65px + env(safe-area-inset-top));
    margin-top: calc(8px - env(safe-area-inset-top));

    && img {
      width: 11px;
    }
  }
`
