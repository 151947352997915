import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Box, Chip, FormHelperText, Button } from "@material-ui/core"
import TextFieldLabelAbove from "../TextFieldLabelAbove"

export type Props = {
  value: string
  tags: string[]
  suggestions: string[]
  displayError: boolean
  onInputChange: (value: string) => void
  onEnteredPressed: (jobTag: string) => void
  onDeletedPressed: (jobTag: string) => void
}

const View = ({
  tags,
  value,
  suggestions,
  displayError,
  onInputChange,
  onEnteredPressed,
  onDeletedPressed,
}: Props) => {
  const onKeyPress = (event: any) => {
    if (event.key === "Enter" && value.trim().length > 0) {
      event.preventDefault()
      onEnteredPressed(value)
    }
  }

  const onAddPress = (event: any) => {
    if (value.trim().length > 0) {
      event.preventDefault()
      onEnteredPressed(value)
    }
  }

  const handleDelete = (tagToDelete: string) => () => {
    onDeletedPressed(tagToDelete)
  }

  return (
    <>
      <Box marginBottom={2}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value}
          options={suggestions}
          id="job-tags-autocomplete"
          onInputChange={(_, newValue) => onInputChange(newValue)}
          renderInput={(params) => (
            <TextFieldLabelAbove
              {...params}
              margin="normal"
              onKeyPress={onKeyPress}
              style={{ height: "48px", paddingTop: 0 }}
              displayTitle={"Project Tags"}
              placeholder="Add project tags..."
              name={"project-tags"}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Button size="small" color="primary" onClick={onAddPress}>
                    Add tag
                  </Button>
                ),
              }}
            />
          )}
        />

        <FormHelperText error={false}>
          Add tags by pressing enter...
        </FormHelperText>
        {displayError && (
          <Box marginBottom={2}>
            <FormHelperText error={true}>
              There was an error saving the project tag. Please try again!
            </FormHelperText>
          </Box>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="flex-start">
        {tags.map((tag: string, index: number) => (
          <Box key={index} marginRight={1} marginTop={1} display="flex">
            <Chip
              label={tag}
              onDelete={handleDelete(tag)}
              color="primary"
              data-testid="project-tag-item"
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default View
