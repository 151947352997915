import React from "react"
import View from "./view"
import { Comment } from "../../redux/projects"

type Props = {
  comment: Comment
}

const CommentContentText = ({ comment }: Props) => {
  return <View comment={comment} />
}

export default CommentContentText
