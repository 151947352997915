import React from "react"

import Header from "./Header"
import Step from "./Step"

import {
  MainContainer,
  DatesBox,
  DatesContainer,
  DescriptionFont,
  HowItWorksTitle,
  StepsBox,
  DescriptionBox,
  DescriptionContainer,
  DescriptionIconBox,
  HowItWorksBox,
  HowItWorksContainer,
  AwardsBox,
  AwardsContainer,
  AwardsTitle,
  AwardsContent,
  AwardsButton,
  JuryContainer,
  DatesTitle,
  DatesList,
  DatesListItem,
  DatesTimerBox,
  DatesTimerBoxTitle,
  DatesDescriptionBox,
  RequirementsBox,
  RequirementsContainer,
  RequirementsTitle,
  RequirementsFlexBox,
  RequirementsLeftBox,
  RequirementsRightBox,
  RequirementsInnerTitle,
  RequirementsItem,
  DescriptionFlexBox,
  DescriptionLeftBox,
  EmailBox,
  EmailTitle,
  EmailLink,
  CountdownText,
  CountdownLabel,
  CountdownFlexBox,
  CountdownItem,
  JuryBoxBordered,
  PanelistsTitle,
  JuryBox,
  PanelistsBox,
  ProjectsBox,
  ProjectsContainer,
  ProjectsTitle,
  ProjectsContent,
  ProjectsText,
  ProjectsButtonBox,
  ProjectsImageBox,
  VideoBoxBordered,
  VideoContainer,
  VideoTitle,
  VideoBox,
  VideoCarouselBox,
  AwardsLine,
  PendingJuryBox,
  PendingJuryBoxBordered,
  PendingJuryContainer,
} from "./styles"

import spBigIcon from "../../images/innovation-awards/sp-big-icon.svg"
import awardsIcon from "../../images/innovation-awards/awards-icon.svg"
import featureIcon from "../../images/innovation-awards/feature-icon.svg"
import emailWhiteIcon from "../../images/innovation-awards/email-white-icon.svg"

import projectsImage from "../../images/innovation-awards/projects-image.jpg"
import projectsImageMobile from "../../images/innovation-awards/projects-image-mobile.jpg"

import { Button } from "../../components"
import { InnovationAwardsForm } from "."
import { Box } from "@material-ui/core"
import Countdown from "react-countdown"
import Panelist from "./Panelist"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import MarketingDialog from "./MarketingDialog"
import JuryReview from "./JuryReview"
import InnovationAwardsAnnouncement from "../../components/InnovationAwardsAnnouncement"
import InnovationAwardsJury from "../../components/InnovationAwardsJury"
import VideoGallery from "../../components/VideoGallery"

import BackButton from "../../components/BackButton"
import environment from "../../environment"
import { Competition } from "../../redux/competition"
import FormBox from "./FormBox"
import { Profile } from "../../redux/profile"
import { TypeAnimation } from "react-type-animation"

interface Props {
  goToForm: () => void
  goToProject: () => void
  goToProjects: () => void
  goToUrl: (url: string) => void
  onSubmit: (data: InnovationAwardsForm) => void
  onClose: () => void
  onCloseMarketingDialog: () => void
  showMessage: boolean
  isAuthenticated: boolean
  marketingDialog: boolean
  competition?: Competition
  authenticatedProfile?: Profile
}

const View = ({
  goToForm,
  goToProject,
  goToProjects,
  onSubmit,
  onClose,
  onCloseMarketingDialog,
  goToUrl,
  showMessage,
  isAuthenticated,
  marketingDialog,
  competition,
  authenticatedProfile,
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width < theme.breakpoints.values.sm

  const renderHTML = (rawHTML: string) => {
    const html = rawHTML?.replace(
      "{awardsIcon}",
      `<img src='${featureIcon}' alt='awards' />`
    )
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: html },
    })
  }

  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <CountdownFlexBox>
        <CountdownItem>
          <CountdownText>{String(days).padStart(2, "0")}</CountdownText>
          <CountdownLabel>DAYS</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownText>{String(hours).padStart(2, "0")}</CountdownText>
          <CountdownLabel>HOURS</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownText>{String(minutes).padStart(2, "0")}</CountdownText>
          <CountdownLabel>MINUTES</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownText>{String(seconds).padStart(2, "0")}</CountdownText>
          <CountdownLabel>SECONDS</CountdownLabel>
        </CountdownItem>
      </CountdownFlexBox>
    )
  }

  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <BackButton
        style={{
          marginLeft: "20px",
          position: "fixed",
          marginTop: environment.device === "ios-app" ? "0" : "",
        }}
      />
      <Header
        goToForm={competition?.status === "closed" ? goToProjects : goToForm}
        competition={competition}
      />
      <DescriptionBox>
        <DescriptionContainer>
          <DescriptionFlexBox>
            <DescriptionLeftBox>
              <DescriptionFont variant={"body1"}>
                {renderHTML(competition?.description!)}
              </DescriptionFont>
            </DescriptionLeftBox>
            <DescriptionIconBox>
              <img src={spBigIcon} alt={"SP Icon"} />
            </DescriptionIconBox>
          </DescriptionFlexBox>
        </DescriptionContainer>
      </DescriptionBox>

      <div id="winners">
        {competition?.winners?.professional?.length === 4 &&
        competition?.winners?.student?.length === 4 ? (
          <>
            <InnovationAwardsAnnouncement competition={competition} />
            <InnovationAwardsJury origin={"landing-page"} />
          </>
        ) : (
          <>
            {competition?.projectReview &&
              competition?.projectReview.length > 0 && (
                <JuryReview
                  goToProjects={goToProjects}
                  competition={competition}
                />
              )}
          </>
        )}
      </div>

      {competition?.winnerVideos && competition.winnerVideos.length > 0 && (
        <>
          <VideoBoxBordered>
            <VideoContainer>
              <VideoTitle variant={"h1"}>Meet the Winners</VideoTitle>
            </VideoContainer>
          </VideoBoxBordered>
          <VideoBox id="meet-the-winners">
            <VideoContainer>
              <VideoCarouselBox>
                <VideoGallery videos={competition.winnerVideos} />
              </VideoCarouselBox>
            </VideoContainer>
          </VideoBox>
        </>
      )}

      {competition?.jury && competition.jury.length > 0 ? (
        <>
          <JuryBoxBordered id={"jury"}>
            <JuryContainer>
              <PanelistsTitle variant={"h1"}>Meet the Jury</PanelistsTitle>
            </JuryContainer>
          </JuryBoxBordered>
          <JuryBox>
            <JuryContainer>
              <PanelistsBox>
                {competition.jury?.map((item) => {
                  return <Panelist jury={item} />
                })}
              </PanelistsBox>
            </JuryContainer>
          </JuryBox>
        </>
      ) : (
        <PendingJuryBox>
          <PendingJuryBoxBordered>
            <PendingJuryContainer>
              <PanelistsTitle variant={"h1"}>Jury</PanelistsTitle>
              <TypeAnimation
                sequence={["Coming soon", 500, "", 500]}
                cursor={true}
                repeat={Infinity}
              />
            </PendingJuryContainer>
          </PendingJuryBoxBordered>
        </PendingJuryBox>
      )}

      {competition?.showEntries && (
        <ProjectsBox>
          <ProjectsContainer>
            <ProjectsContent>
              <ProjectsTitle variant={"h1"}>Project Entries</ProjectsTitle>
              <ProjectsText>
                Take a look at the competition! See how your peers and fellow
                cohorts are advancing the field of Architecture. How does your
                work stack up against Architecture’s rising stars?
                <br />
                <span>Submit your designs today!</span>
              </ProjectsText>
              <ProjectsButtonBox>
                {competition.status === "closed" ? (
                  <Button
                    onClick={goToProjects}
                    children={"All Submissions"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                ) : (
                  <Button
                    onClick={goToForm}
                    children={"Submit Your Work"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                )}
              </ProjectsButtonBox>
            </ProjectsContent>
            <ProjectsImageBox>
              <img
                src={isMobile ? projectsImageMobile : projectsImage}
                alt={"Projects"}
              />
            </ProjectsImageBox>
          </ProjectsContainer>
        </ProjectsBox>
      )}

      <HowItWorksBox>
        <HowItWorksContainer>
          <HowItWorksTitle variant={"h1"}>How it Works</HowItWorksTitle>
        </HowItWorksContainer>
      </HowItWorksBox>
      <HowItWorksBox>
        <HowItWorksContainer>
          <StepsBox>
            {competition?.howItWorks?.map((item) => {
              return (
                <Step
                  number={item.step!}
                  text={item.title}
                  icon={item.icon!}
                  handleClick={
                    item.icon === "upload"
                      ? goToProject
                      : item.icon === "submit"
                      ? goToForm
                      : undefined
                  }
                />
              )
            })}
          </StepsBox>
        </HowItWorksContainer>
      </HowItWorksBox>

      {competition?.awardsDescription && (
        <AwardsBox>
          <AwardsContainer>
            <AwardsTitle variant={"h1"}>
              <img src={awardsIcon} alt={"awards"} />
              Awards
            </AwardsTitle>
            <AwardsContent>
              <AwardsLine>
                {renderHTML(competition.awardsDescription)}
              </AwardsLine>
              <AwardsButton>
                {competition.lastCompetitionUrl ? (
                  <Button
                    onClick={() => goToUrl(competition.lastCompetitionUrl!)}
                    children={"View Past Winners"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                ) : (
                  <Button
                    onClick={goToProjects}
                    children={"All Submissions"}
                    color={"secondary"}
                    size={"medium"}
                    style={{
                      width: "194px",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                )}
              </AwardsButton>
            </AwardsContent>
          </AwardsContainer>
        </AwardsBox>
      )}
      {competition?.dates && competition.dates.length > 0 && (
        <DatesBox>
          <DatesContainer>
            <DatesDescriptionBox>
              <DatesTitle variant="h1">Dates</DatesTitle>
              <DatesList>
                {competition.dates?.map((item) => {
                  return <DatesListItem>{renderHTML(item)}</DatesListItem>
                })}
              </DatesList>
            </DatesDescriptionBox>
            <DatesTimerBox>
              <DatesTimerBoxTitle>Call For Entries</DatesTimerBoxTitle>

              <Box>
                <Countdown
                  date={competition.entriesDeadline}
                  renderer={renderer}
                />
              </Box>

              {competition.status === "closed" ? (
                <Button
                  onClick={goToProjects}
                  children={"All Submissions"}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    width: "194px",
                    height: "48px",
                    fontSize: "16px",
                  }}
                />
              ) : (
                <Button
                  onClick={goToForm}
                  children={"Submit Your Work"}
                  color={"secondary"}
                  size={"medium"}
                  style={{
                    width: "194px",
                    height: "48px",
                    fontSize: "16px",
                  }}
                />
              )}
            </DatesTimerBox>
          </DatesContainer>
        </DatesBox>
      )}

      <RequirementsBox id={"requirementsArea"}>
        <RequirementsContainer>
          <RequirementsTitle variant={"h1"}>Requirements</RequirementsTitle>
        </RequirementsContainer>
      </RequirementsBox>

      <RequirementsBox>
        <RequirementsContainer>
          <RequirementsFlexBox>
            <RequirementsLeftBox>
              {competition?.requirements?.left?.map((item) => {
                return (
                  <Box>
                    <RequirementsInnerTitle>
                      {item.title}
                    </RequirementsInnerTitle>
                    {item.items.map((subItem) => {
                      return (
                        <RequirementsItem>
                          {renderHTML(subItem.item)}
                        </RequirementsItem>
                      )
                    })}
                  </Box>
                )
              })}
            </RequirementsLeftBox>
            <RequirementsRightBox>
              {competition?.requirements?.right?.map((item) => {
                return (
                  <Box>
                    <RequirementsInnerTitle>
                      {item.title}
                    </RequirementsInnerTitle>
                    {item.items.map((subItem) => {
                      return (
                        <RequirementsItem>
                          {renderHTML(subItem.item)}
                          {subItem.subItems && subItem.subItems.length > 0 && (
                            <ul>
                              {subItem.subItems.map((subSubItem) => {
                                return <li>{subSubItem}</li>
                              })}
                            </ul>
                          )}
                        </RequirementsItem>
                      )
                    })}
                  </Box>
                )
              })}
            </RequirementsRightBox>
          </RequirementsFlexBox>
        </RequirementsContainer>
      </RequirementsBox>

      {competition?.registerOpen && (
        <FormBox
          onSubmit={onSubmit}
          showMessage={showMessage}
          onClose={onClose}
          authenticatedProfile={authenticatedProfile}
        />
      )}

      <EmailBox>
        <EmailTitle variant={"h1"}>For Questions Contact</EmailTitle>
        <EmailLink href="mailto:sethkopka@blackspectacles.com">
          <img src={emailWhiteIcon} alt={"email"} />
          sethkopka@blackspectacles.com
        </EmailLink>
      </EmailBox>

      <MarketingDialog
        open={marketingDialog}
        onClose={onCloseMarketingDialog}
        competition={competition}
      />
    </MainContainer>
  )
}

export default View
