import { useAuth0 } from "@auth0/auth0-react"
import { FormikHelpers } from "formik"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { JobPosition } from "../../redux/jobPositions"
import { getGroupSuggestions } from "../../redux/profile"
import View from "./View"

export type JobPositionWithDates = {
  id?: string
  title?: string
  company?: string
  description?: string
  startDate?: Date
  endDate?: Date
  current?: boolean
  startMonth: number
  startYear: number
  endMonth: number
  endYear: number
}
export type Props = {
  jobPosition?: JobPosition
  onSubmit: (jobPosition: JobPosition) => void
}

const emptyJobPosition: JobPosition = {
  title: "",
  company: "",
  description: "",
  current: false,
  startDate: new Date(),
}

const JobPositionForm = ({ jobPosition, onSubmit }: Props) => {
  const auth = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const groupSuggestions = useSelector(
    (state: AppState) => state.profile.groupSuggestions
  )

  const handleOnSubmitForm = async (
    jobPosition: JobPositionWithDates,
    bag: FormikHelpers<JobPositionWithDates>
  ) => {
    const { startMonth, startYear, endMonth, endYear, ...job } = jobPosition
    job.startDate = new Date(startYear, startMonth - 1, 1)
    job.current
      ? (job.endDate = undefined)
      : (job.endDate = new Date(endYear, endMonth - 1, 1))

    if (!job.current && job.endDate! < job.startDate!) {
      if (endYear < startYear)
        bag.setFieldError(
          "endYear",
          "End date can not be earlier than start date"
        )

      if (endMonth < startMonth)
        bag.setFieldError(
          "endMonth",
          "End date can not be earlier than start date"
        )
      return
    }

    onSubmit(job as JobPosition)
  }

  const onCancel = () => {
    history.push("/experiences/edit")
  }

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getGroupSuggestions({ auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth])

  const initialValues: JobPositionWithDates = {
    ...(jobPosition || emptyJobPosition),
    startMonth: jobPosition?.id
      ? new Date(jobPosition.startDate).getMonth() + 1
      : 0,
    startYear: jobPosition?.id
      ? new Date(jobPosition.startDate).getFullYear()
      : 0,
    endMonth: jobPosition?.endDate
      ? new Date(jobPosition.endDate).getMonth() + 1
      : 0,
    endYear: jobPosition?.endDate
      ? new Date(jobPosition.endDate).getFullYear()
      : 0,
  }

  return (
    <View
      values={initialValues}
      onSubmit={handleOnSubmitForm}
      onCancel={onCancel}
      suggestions={groupSuggestions || []}
    />
  )
}

export default JobPositionForm
