import React from "react"

import { useLocation } from "react-router-dom"

import View from "./View"
import { Profile, updateProfile } from "../../redux/profile"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"

const POPUP_KEY = "SP_COMPLETE_PROFILE_POPUP"

type Props = {
  profile: Profile
}

const CompleteProfilePopUp = ({ profile }: Props) => {
  const [open, setOpen] = React.useState(false)

  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const location = useLocation()

  const handleClose = () => {
    setOpen(false)
    updateShowPopUp()
    sessionStorage.setItem(POPUP_KEY, "true")
  }

  const updateShowPopUp = async () => {
    const editedProfile = Object.assign({}, profile)
    editedProfile.popUpShownAt = new Date()
    try {
      await dispatch(updateProfile({ profile: editedProfile, auth }))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (sessionStorage.getItem(POPUP_KEY) === null) {
      setTimeout(() => {
        setOpen(true)
      }, 5000)
    }
    //eslint-disable-next-line
  }, [location.pathname])

  return <View open={open} onClose={handleClose} profile={profile} />
}

export default CompleteProfilePopUp
