import _ from "lodash"
import { SelectOption } from "../components/SelectField"

export const monthsNames: SelectOption[] = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
]

export const availableYears: SelectOption[] = _.rangeRight(
  1990,
  new Date().getFullYear() + 1
).map((year) => ({ name: year, value: year }))
