import React, { useState, useEffect } from "react"

import IconActive from "../../images/new-homepage/icons/share-active.svg"
import IconDefault from "../../images/new-homepage/icons/share-default.svg"
import IconHover from "../../images/new-homepage/icons/share-hover.svg"

import {
  IconContainer,
  Icon,
  CountLabel,
  IconFlexBox,
  CounterFlexBox,
} from "./styles"
import { useAuth0 } from "@auth0/auth0-react"
import { Typography } from "@material-ui/core"
import Dialog from "./dialog"
import { News } from "../../redux/adminNews"

export type Props = {
  dispatchComplete: boolean
  news: News
  handleShareClick: (like: boolean) => Promise<void>
  dialogIsOpen: boolean
  handleCloseDialog: () => void
  handleOpenDialog: () => void
}

const View = ({
  dispatchComplete,
  news,
  handleShareClick,
  dialogIsOpen,
  handleCloseDialog,
  handleOpenDialog,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const [wasShared, setWasShared] = useState(false)
  const [shareCount, setShareCount] = useState(0)
  const [hover, setHover] = React.useState(false)

  const getIcon = (shared?: boolean) => {
    if (shared) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  useEffect(() => {
    setWasShared(news.shared!)
    setShareCount(news.shareCount!)
  }, [news])

  const handleShare = () => {
    if (!disableShare) {
      if (!wasShared) {
        if (isAuthenticated) {
          setWasShared(true)
          setShareCount(shareCount + 1)
        }
      }
      handleShareClick(wasShared)
    }
  }

  const handleClick = async () => {
    handleOpenDialog()
  }

  let disableShare = false
  if (!dispatchComplete) disableShare = true
  return (
    <>
      <IconContainer
        $isActive={wasShared}
        onClick={() => handleClick()}
        data-testid="news-share-button"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconFlexBox>
          <CounterFlexBox>
            <Icon src={getIcon(wasShared)} alt="" />
            <CountLabel>{shareCount}</CountLabel>
          </CounterFlexBox>
          <Typography>Share</Typography>
        </IconFlexBox>
      </IconContainer>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        onClose={handleCloseDialog}
        handleShare={handleShare}
        news={news}
      />
    </>
  )
}

export default View
