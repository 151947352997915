import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import View from "./View"
import { Helmet } from "react-helmet"
import PreviewImage from "../../images/career-fair/preview.jpg"
import RouteChangeTracker from "../../components/RouteChangeTracker"

export interface InnovationAwardsCoAuthor {
  name: string
  profileLink: string
}

export interface InnovationAwardsForm {
  name: string
  category: string
  email: string
  projectLink: string
  projectTitle: string
  updated: boolean
  singleAuthor: boolean
  groupProject: boolean
  coAuthors?: InnovationAwardsCoAuthor[]
}

const CareerFairPage = () => {
  const history = useHistory()

  const { isAuthenticated } = useAuth0()

  // @ts-ignore
  window.prerenderReady = true

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Firm Spotlight`}
        classTitle="FirmSpotlight"
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={PreviewImage} />
        <meta property="og:title" content={`Firm Spotlight - Spectacular`} />
        <meta
          property="og:description"
          content={`Spectacular’s Firm Spotlight offers a unique opportunity for firms to reach a wider audience by taking over the Spectacular homepage, social channels, and email list. Achieve your firm’s goals by attracting the industry’s top talent!`}
        />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta name="title" content={`Firm Spotlight - Spectacular`} />
        <meta
          name="description"
          content={`Spectacular’s Firm Spotlight offers a unique opportunity for firms to reach a wider audience by taking over the Spectacular homepage, social channels, and email list. Achieve your firm’s goals by attracting the industry’s top talent!`}
        />
        <title>Firm Spotlight - Spectacular</title>
      </Helmet>
      <View isAuthenticated={isAuthenticated} />
    </>
  )
}

export default CareerFairPage
