import React, { useState } from "react"

import View from "./View"

type Props = {
  discipline?: string
  onChange: (newValue: string) => void
}

const suggestions = [
  "Architecture",
  "Interior Architecture",
  "Landscape Architecture",
  "Civil Engineering",
  "MEP",
  "Structural Engineering",
  "Contractors",
  "Sub Contractors",
  "Other: ",
]

const ProjectDiscipline = ({ discipline, onChange }: Props) => {
  const [value, setValue] = useState<string>(discipline || "")

  const onInputChange = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <View
      value={value}
      suggestions={suggestions}
      onInputChange={onInputChange}
    />
  )
}

export default ProjectDiscipline
