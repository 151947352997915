import { useAuth0 } from "@auth0/auth0-react"
import React, { useCallback, useEffect, useState } from "react"
import View from "./View"
import { Helmet } from "react-helmet"
import AwardsLogo from "../../images/innovation-awards/awards-logo.svg"
import { useHistory } from "react-router-dom"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import { ambassadorsApply } from "../../redux/profile"
import { useSelector } from "react-redux"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import {
  getAmbassadors,
  LoadingStatuses,
  selectAmbassadorsStatus,
} from "../../redux/ambassador"
import LoadingPage from "../LoadingPage"

export interface FormProps {
  name: string
  program: string
  email: string
  status: string
  other?: string
  phoneNumber: string
  organization?: string
}

const StudentAmbassadorPage = () => {
  // @ts-ignore
  window.prerenderReady = true

  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const history = useHistory()
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated
  const dispatch = useAppDispatch()

  const status = useSelector(selectAmbassadorsStatus)

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  const handleSubmit = async (data: FormProps) => {
    try {
      if (!isAuthenticated) {
        await dispatch(toggleDialog({ opened: true }))
        return
      }

      const { type } = await dispatch(
        ambassadorsApply({
          auth,
          program: data.program,
          programStatus: data.status === "Other:" ? data.other! : data.status,
          phoneNumber: data.phoneNumber,
          organization: data.organization,
        })
      )
      if (ambassadorsApply.fulfilled.type === type) {
        setShowMessage(true)

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const loadAmbassadors = useCallback(async () => {
    try {
      const { type } = await dispatch(getAmbassadors())

      if (type === getAmbassadors.fulfilled.type) {
        // @ts-ignore
        window.prerenderReady = true
      }
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch])

  useEffect(() => {
    loadAmbassadors()
  }, [loadAmbassadors])

  const handleClose = () => {
    setShowMessage(false)
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Student Ambassador`}
        classTitle="StudentAmbassador"
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={AwardsLogo} />
        <meta
          property="og:title"
          content={`Spectacular - Student Ambassador Program`}
        />
        <meta
          property="og:description"
          content={`Spectacular is looking for Student Ambassadors to assist in building relationships with on-campus student organizations in the effort to elevate the work of talented architecture students.`}
        />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta
          name="title"
          content={`Spectacular - Student Ambassador Program`}
        />
        <meta
          name="description"
          content={`Spectacular is looking for Student Ambassadors to assist in building relationships with on-campus student organizations in the effort to elevate the work of talented architecture students.`}
        />
        <title>Spectacular - Student Ambassador Program</title>
      </Helmet>
      {status === LoadingStatuses.Idle || status === LoadingStatuses.Loading ? (
        <LoadingPage />
      ) : (
        <View
          authenticatedProfile={authenticatedProfile}
          isAuthenticated={isAuthenticated}
          onSubmit={handleSubmit}
          onClose={handleClose}
          showMessage={showMessage}
        />
      )}
    </>
  )
}

export default StudentAmbassadorPage
