import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"
import Quotes from "../../images/new-homepage/quotes.png"
import MobileQuotes from "../../images/new-homepage/quotes-mobile.png"

export const JuryBorder = styled(Box)`
  width: 100%;
  border-bottom: 2px solid #ffffff;
  padding-top: 80px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 64px;
  }

  ${theme.breakpoints.down("xs")} {
    padding-top: 48px;
  }
`

export const JuryBorderBottom = styled(Box)`
  width: 100%;
  border-top: 2px solid #ffffff;
  padding-bottom: 170px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 120px;
  }
`

export const JuryReviewContainer = styled(Box)`
  background-color: #2d2d2d;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`

export const JuryReviewBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0px 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0px 20px;
  }
`

export const JuryReviewBoxLP = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0px 185px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0px 20px;
  }
`

export const JuryReviewTitleLP = styled(Typography)`
  padding-top: 60px;
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    padding-top: 42px;
  }
`

export const JuryReviewTitle = styled(Typography)`
  padding-top: 40px;
  font-size: 25px;
  font-family: Public-Sans-Bold;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
  }

  ${theme.breakpoints.down("xs")} {
    padding-top: 16px;
  }
`

export const JuryReviewList = styled(Box)`
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
    gap: 16px;
    margin-bottom: -32px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0;
  }
`

export const JuryReviewCard = styled(Box)<{ $isHomepage?: boolean }>`
  margin-top: ${({ $isHomepage }) => ($isHomepage ? "-250px" : "-220px")};
  align-self: flex-end;
  z-index: 8;
  width: ${({ $isHomepage }) => ($isHomepage ? "750px" : "650px")};
  height: 400px;
  filter: drop-shadow(-10px 11px 66px rgba(0, 0, 0, 0.25));
  display: flex;
  box-sizing: border-box;
  padding: 40px 56px;
  background: url(${Quotes}) #3f3e3e;
  background-position: 253px 138px;
  background-repeat: no-repeat;
  border-radius: 1px;

  @media only screen and (max-width: 1150px) {
    width: ${({ $isHomepage }) => ($isHomepage ? "650px" : "650px")};
    height: 350px;
    margin-top: ${({ $isHomepage }) => ($isHomepage ? "-250px" : "-150px")};
    background-position: 275px 130px;
    padding: ${({ $isHomepage }) => ($isHomepage ? "40px 56px" : "20px")};
  }

  @media only screen and (max-width: 1005px) {
    margin-top: ${({ $isHomepage }) => ($isHomepage ? "-230px" : "-110px")};
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 20px;
    width: 100%;
    height: auto;
    background: #3f3e3e;
    padding: 0;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0px;
  }
`

export const JuryReviewCardDescription = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    padding: 24px;
    box-sizing: border-box;

    background: url(${MobileQuotes}) #3f3e3e;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 10px;
  }
`

export const JuryReviewCardImage = styled(Box)`
  && img {
    height: 283px;
  }

  ${theme.breakpoints.down("sm")} {
    && img {
      height: 235px;
      display: flex;
    }
  }
`

export const JuryReviewCardTitle = styled(Typography)`
  color: #fff;
  font-size: 64px;
  line-height: 55.42px;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    line-height: 35.78px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
    line-height: 20.78px;
  }
`

export const JuryReviewCardText = styled(Typography)`
  color: #fff;
  font-size: 23px;
  line-height: 27px;
  padding-right: 40px;

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;
    line-height: 22px;
    padding-right: 8px;
  }
`

export const JuryReviewCardHint = styled(Typography)`
  color: #fff;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 20px;
    margin-top: 16px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 20px;
    line-height: 18px;
    margin-top: 12px;
  }
`

export const JuryReviewCardPosition = styled(Typography)`
  color: #fff;
  font-size: 16px;
`

export const JuryProject = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`

export const JuryProjectTitles = styled(Box)`
  padding-left: 32px;
  padding-top: 48px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
    padding-top: 12px;
  }
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: 0;
  padding-bottom: 40%;
  position: relative;
  border-radius: 4px;

  @media only screen and (max-width: 1250px) {
    padding-bottom: 45%;
    max-width: 800px;
  }

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
    padding-bottom: 75%;
  }
`

export const ImageThumbnail = styled.img`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
`

export const ProjectName = styled(Typography)`
  font-size: 24px;
  font-family: "Public-Sans-Bold";
  line-height: 28px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ProfileName = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`
