import React, { ReactNode } from "react"
import { CircularProgress } from "@material-ui/core"
import { colors } from "../../theme"
import { Button } from "../Button"

export type Props = {
  connectedState: string
  isLoading: boolean
  onConnect: () => void
  onWithdraw: () => void
  onConfirm: () => void
  color?: string
  children?: string
  style?: any
}

const View = ({
  onConnect,
  onWithdraw,
  onConfirm,
  connectedState,
  isLoading,
  color,
  children,
  style,
}: Props) => {
  const clickEvent = (type: string) => {
    if (type === "connect") onConnect()
    if (type === "confirm") onConfirm()
    if (type === "withdraw") onWithdraw()
  }

  let displayChildren: ReactNode
  let displayHoverChildren: ReactNode
  let displayColor: any = undefined
  let displayHoverColor: any = undefined
  let eventType: string
  let disabled: boolean = false

  const styles = style ? style : { width: "100%" }

  if (
    connectedState === "requested" ||
    connectedState === "request-received" ||
    connectedState === "admin"
  ) {
    displayColor = "lightGrey"
    displayChildren = isLoading ? (
      <CircularProgress size={16} style={{ color: "#979797" }} />
    ) : (
      <>Pending</>
    )

    if (connectedState === "request-received") {
      displayHoverColor = "primaryHover"
      displayHoverChildren = isLoading ? (
        <CircularProgress size={16} style={{ color: colors.white }} />
      ) : (
        <>Confirm</>
      )

      eventType = "confirm"
    } else {
      displayHoverColor = "mediumGrey"
      displayHoverChildren = isLoading ? (
        <CircularProgress size={16} style={{ color: "#979797" }} />
      ) : (
        <>Withdraw</>
      )

      eventType = "withdraw"
    }
  } else if (connectedState === "accepted") {
    disabled = true
    displayChildren = <>Connected</>
  } else {
    displayColor = color === "black" ? "primary" : "secondary"
    eventType = "connect"

    displayChildren = isLoading ? (
      <CircularProgress size={16} style={{ color: colors.white }} />
    ) : (
      <>{children ? children : "Connect"}</>
    )
  }

  return (
    <Button
      size={"medium"}
      children={displayChildren}
      hoverChildren={displayHoverChildren}
      hoverColor={displayHoverColor}
      onClick={() => {
        clickEvent(eventType)
      }}
      color={displayColor}
      disabled={disabled || isLoading}
      style={styles}
    />
  )
}

export default View
