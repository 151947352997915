import { SelectOption } from "../components/SelectField"

const degree: Array<SelectOption> = [
  { value: "Minor", name: "Minor" },
  { value: "Bachelors", name: "Bachelors" },
  { value: "Masters", name: "Masters" },
  { value: "Doctorate", name: "Doctorate" },
  { value: "Associates", name: "Associates" },
]

export default degree
