import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import { match } from "react-router-dom"
import {
  Project,
  fetchProject,
  selectProject,
  updateProject,
} from "../../../redux/adminProjects"
import View from "./View"
import { EditableProjectFeatured } from "../../../components/AdminProjectFeaturedRow"
import {
  createProjectFeatured,
  updateProjectFeatured,
  deleteProjectFeatured,
} from "../../../redux/adminProjectFeatured"
import { EditableProjectMainFeature } from "../../../components/AdminProjectMainFeatureRow"
import {
  createProjectMainFeature,
  updateProjectMainFeature,
  deleteProjectMainFeature,
} from "../../../redux/adminProjectMainFeature"
import { useAuth0 } from "@auth0/auth0-react"
import { EditableProjectReward } from "../../../components/AdminProjectRewardRow"
import {
  createProjecReward,
  deleteProjectReward,
  updateProjectReward,
} from "../../../redux/adminProjectReward"

interface MatchParams {
  id: string
}
export interface Props {
  match: match<MatchParams>
}

const ProjectEditPage = ({ match }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const project = useSelector(selectProject)
  const projectId = match.params.id
  const [featuredList, setFeaturedList] = useState<EditableProjectFeatured[]>(
    []
  )

  const handleAddNewFeatured = () => {
    const newFeatured: EditableProjectFeatured = {
      id: `new-${featuredList.length}`,
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      projectId: project?.id,
      editing: true,
    }
    setFeaturedList([newFeatured, ...featuredList])
  }

  const handleSaveFeatured = async (
    projectFeatured: EditableProjectFeatured
  ) => {
    const reduxThunk = projectFeatured.id!.startsWith("new-")
      ? createProjectFeatured
      : updateProjectFeatured
    try {
      const { type, payload } = await dispatch(
        reduxThunk({ auth, projectFeatured })
      )
      if (reduxThunk.fulfilled.type === type) {
        const updatedFeaturedList = featuredList.map((featured) =>
          featured.id === projectFeatured.id ? payload : featured
        )
        setFeaturedList(updatedFeaturedList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteFeatured = async (
    projectFeatured: EditableProjectFeatured
  ) => {
    try {
      const { type } = await dispatch(
        deleteProjectFeatured({ auth, id: projectFeatured.id! })
      )
      if (deleteProjectFeatured.fulfilled.type === type) {
        const updatedFeaturedList = featuredList.filter(
          (featured) => featured.id !== projectFeatured.id
        )
        setFeaturedList(updatedFeaturedList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditProject = async (project: Project) => {
    try {
      await dispatch(updateProject({ auth, project }))
    } catch (error) {
      console.error(error)
    }
  }

  const [mainFeatureList, setMainFeatureList] = useState<
    EditableProjectMainFeature[]
  >([])

  const [rewardList, setRewardList] = useState<EditableProjectReward[]>([])

  const handleAddNewMainFeature = () => {
    const newMainFeature: EditableProjectMainFeature = {
      id: `new-${mainFeatureList.length}`,
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      projectId: project?.id,
      editing: true,
    }
    setMainFeatureList([newMainFeature, ...mainFeatureList])
  }

  const handleSaveMainFeature = async (
    projectMainFeature: EditableProjectMainFeature
  ) => {
    const reduxThunk = projectMainFeature.id!.startsWith("new-")
      ? createProjectMainFeature
      : updateProjectMainFeature
    try {
      const { type, payload } = await dispatch(
        reduxThunk({ auth, projectMainFeature })
      )
      if (reduxThunk.fulfilled.type === type) {
        const updatedMainFeatureList = mainFeatureList.map((mainFeature) =>
          mainFeature.id === projectMainFeature.id ? payload : mainFeature
        )
        setMainFeatureList(updatedMainFeatureList)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleDeleteMainFeature = async (
    projectMainFeature: EditableProjectMainFeature
  ) => {
    try {
      const { type } = await dispatch(
        deleteProjectMainFeature({ auth, id: projectMainFeature.id! })
      )
      if (deleteProjectMainFeature.fulfilled.type === type) {
        const updatedMainFeatureList = mainFeatureList.filter(
          (mainFeature) => mainFeature.id !== projectMainFeature.id
        )
        setMainFeatureList(updatedMainFeatureList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddNewReward = () => {
    const newReward: EditableProjectReward = {
      id: `new-${rewardList.length}`,
      projectId: project?.id,
      profileId: project?.profileId,
      editing: true,
    }
    setRewardList([newReward, ...rewardList])
  }

  const handleSaveReward = async (projectReward: EditableProjectReward) => {
    const reduxThunk = projectReward.id!.startsWith("new-")
      ? createProjecReward
      : updateProjectReward
    try {
      const { type, payload } = await dispatch(
        reduxThunk({ auth, projectReward })
      )
      if (reduxThunk.fulfilled.type === type) {
        const updatedRewardList = rewardList.map((reward) =>
          reward.id === projectReward.id ? payload : reward
        )
        setRewardList(updatedRewardList)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleDeleteReward = async (projectReward: EditableProjectReward) => {
    try {
      const { type } = await dispatch(
        deleteProjectReward({ auth, id: projectReward.id! })
      )
      if (deleteProjectReward.fulfilled.type === type) {
        const updatedRewardList = rewardList.filter(
          (reward) => reward.id !== projectReward.id
        )
        setRewardList(updatedRewardList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchProject({ id: projectId, auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth, projectId])

  useEffect(() => {
    if (project?.features) setFeaturedList(project.features)
    if (project?.mainFeature) setMainFeatureList(project.mainFeature)
    if (project?.rewards) setRewardList(project.rewards)
  }, [project])

  return (
    <View
      project={project}
      featuredList={featuredList}
      onAddNewFeatured={handleAddNewFeatured}
      onSaveFeatured={handleSaveFeatured}
      onDeleteFeatured={handleDeleteFeatured}
      onEditProject={handleEditProject}
      mainFeatureList={mainFeatureList}
      onAddNewMainFeature={handleAddNewMainFeature}
      onSaveMainFeature={handleSaveMainFeature}
      onDeleteMainFeature={handleDeleteMainFeature}
      rewardList={rewardList}
      onAddNewReward={handleAddNewReward}
      onSaveReward={handleSaveReward}
      onDeleteReward={handleDeleteReward}
    />
  )
}

export default ProjectEditPage
