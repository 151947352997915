import React from "react"
import { Project } from "../../../redux/adminProjects"
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Container,
  TableSortLabel,
  Grid,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import moment from "moment"
import { SortType, SortProperty } from "../../../redux/adminProjects"
import SquarePicture from "../../../components/SquarePicture"

export type Props = {
  projects: Project[]
  onSortChanged: (property: SortProperty) => void
  sort: SortType
}

const View = ({ projects, onSortChanged, sort }: Props) => {
  const now = () =>
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )

  const parseDate = (dateString: string) => {
    const dateParts = dateString.split("-")
    return new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2])
    )
  }

  const isHeroFeatured = (project: Project) => {
    const heroFeature = project.mainFeature!.find(
      (x) => parseDate(x.startDate!) <= now() && now() <= parseDate(x.endDate!)
    )

    if (!heroFeature) return false

    return true
  }

  const isFeatured = (project: Project) => {
    const feature = project.features!.find(
      (x) => parseDate(x.startDate!) <= now() && now() <= parseDate(x.endDate!)
    )

    if (!feature) return false

    return true
  }

  return (
    <Container>
      <Box marginBottom={2}>
        <Typography variant="h2">Project List</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                key="name"
                sortDirection={
                  sort.property === "name" ? sort.direction : false
                }
              >
                <TableSortLabel
                  active={sort.property === "name"}
                  direction={sort.property === "name" ? sort.direction : "asc"}
                  onClick={() => onSortChanged("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                key="location"
                sortDirection={
                  sort.property === "location" ? sort.direction : false
                }
              >
                <TableSortLabel
                  active={sort.property === "location"}
                  direction={
                    sort.property === "location" ? sort.direction : "asc"
                  }
                  onClick={() => onSortChanged("location")}
                >
                  Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                key="likes"
                sortDirection={
                  sort.property === "likes" ? sort.direction : false
                }
              >
                <TableSortLabel
                  active={sort.property === "likes"}
                  direction={sort.property === "likes" ? sort.direction : "asc"}
                  onClick={() => onSortChanged("likes")}
                >
                  # Likes
                </TableSortLabel>
              </TableCell>
              <TableCell
                key="comments"
                sortDirection={
                  sort.property === "comments" ? sort.direction : false
                }
              >
                <TableSortLabel
                  active={sort.property === "comments"}
                  direction={
                    sort.property === "comments" ? sort.direction : "asc"
                  }
                  onClick={() => onSortChanged("comments")}
                >
                  # Comments
                </TableSortLabel>
              </TableCell>
              <TableCell>Currently Featured</TableCell>
              <TableCell
                key="created_at"
                sortDirection={
                  sort.property === "created_at" ? sort.direction : false
                }
              >
                <TableSortLabel
                  active={sort.property === "created_at"}
                  direction={
                    sort.property === "created_at" ? sort.direction : "asc"
                  }
                  onClick={() => onSortChanged("created_at")}
                >
                  Posted At
                </TableSortLabel>
              </TableCell>
              <TableCell># Hero Features</TableCell>
              <TableCell># Features</TableCell>
              <TableCell>Thumbnails</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow key={project.id}>
                <TableCell component="th" scope="row">
                  {project.name}
                </TableCell>
                <TableCell>{project.location}</TableCell>
                <TableCell>{project.likes || 0}</TableCell>
                <TableCell>{project.commentsCount || 0}</TableCell>
                <TableCell>
                  {isHeroFeatured(project)
                    ? "Hero"
                    : isFeatured(project)
                    ? "Yes"
                    : "No"}
                </TableCell>
                <TableCell>
                  {moment(project.createdAt).format("MMM Do YY")}
                </TableCell>
                <TableCell>{project.mainFeature?.length || 0}</TableCell>
                <TableCell>{project.features?.length || 0}</TableCell>
                <TableCell>
                  <Grid container>
                    {project.images?.map((image) => (
                      <Grid item key={image.id} xs>
                        <SquarePicture src={image.thumbnailImageUrl || ""} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Link to={`/admin/projects/${project.id}/edit`}>Edit</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default View
