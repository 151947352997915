import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { Instagram } from "@material-ui/icons"
import XIcon from "../../images/icon-social-x.svg"

import { Profile } from "../../redux/profile"
import PhoneIcon from "../../images/ico-phone.svg"
import EmailIcon from "../../images/ico-email.svg"
import LocationIcon from "../../images/location-icon-dark.svg"
import { WebsiteIcon } from "../../components/Icons"
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg"
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg"

import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"

import {
  IconAnchor,
  DarkLabel,
  LightLabel,
  CounterBox,
  CounterArea,
  TitleLabel,
  FitText,
  IconBox,
  MobileContactBox,
  ContactBox,
  MemberSinceArea,
  SocialBox,
} from "./styles"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import moment from "moment"
import ConnectionListDialog from "../../components/ConnectionListDialog"
import SimpleAddress from "../../components/SimpleAddress"
import { useAuth0 } from "@auth0/auth0-react"
import ProjectWinnerBadge from "../../components/ProjectWinnerBadge"
import AmbassadorBadge from "../../images/student-ambassador-badge.svg"

import "./styles.css"

const IconLink = ({ link, children }: any) => {
  if (!link || !link.trim()) return children
  link =
    link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `//${link}`

  return (
    <IconAnchor href={link} rel="noopener" target="_blank">
      {children}
    </IconAnchor>
  )
}

interface Props {
  profile: Profile
  isConnected?: boolean
}

const AboutSelfSection = ({ profile, isConnected }: Props) => {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()

  const { width } = useWindowDimensions()

  const [open, setOpen] = useState(false)
  const [connectionListType, setConnectionListType] = useState("")

  const handleOpen = (type: string) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
    } else {
      if (
        (type === "followers" && profile?.followersCount! > 0) ||
        (type === "connections" && profile?.connectionsCount! > 0)
      ) {
        setOpen(true)
        setConnectionListType(type)
      }
    }
  }
  const handleClose = () => setOpen(false)

  const displayedEmail =
    profile.type === "group" ? profile.contactEmail : profile.email

  return (
    <>
      {width < theme.breakpoints.values.sm && (
        <Box
          marginBottom={3}
          marginTop={width >= theme.breakpoints.values.sm ? "13px" : 3}
          style={{ borderBottom: "0.5px solid #D8D8D8" }}
        />
      )}

      {(isConnected || profile.type === "group") &&
      width >= theme.breakpoints.values.md ? (
        <>
          <Box marginBottom={2}>
            <TitleLabel>contact</TitleLabel>
          </Box>
          <Box marginBottom={3}>
            {profile.location && profile.type === "group" && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "16px",
                  alignItems: "flex-start",
                }}
              >
                <Box style={{ width: "20px" }}>
                  <IconBox src={LocationIcon} alt="location" />
                </Box>
                <FitText style={{ marginTop: "2px" }}>
                  {SimpleAddress({
                    profileType: profile!.type,
                    location: profile.location,
                  })}
                </FitText>
              </div>
            )}
            {profile.phoneNumber && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "16px",
                  maxHeight: "14px",
                }}
              >
                <Box style={{ width: "20px" }}>
                  <IconBox src={PhoneIcon} alt="phone" />
                </Box>
                <FitText style={{ marginTop: "2px" }}>
                  {profile.phoneNumber}
                </FitText>
              </div>
            )}
            {displayedEmail && (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Box style={{ width: "20px" }}>
                  <IconBox src={EmailIcon} alt="email" />
                </Box>
                <FitText style={{ wordBreak: "break-all" }}>
                  {displayedEmail}
                </FitText>
              </div>
            )}
          </Box>
        </>
      ) : null}

      {profile.aboutSelf &&
        !(profile.type === "group" && width >= theme.breakpoints.values.md) && (
          <>
            <Box marginBottom={width >= theme.breakpoints.values.sm ? 2 : 1.5}>
              <TitleLabel>about me</TitleLabel>
            </Box>
            <Box>
              <FitText
                style={{
                  fontSize:
                    width >= theme.breakpoints.values.sm ? "16px" : "14px",
                  color: "#2D2D2D",
                }}
              >
                {profile.aboutSelf}
              </FitText>
            </Box>
          </>
        )}

      {profile.isAmbassador && (
        <div className="profileDetailsWinnerBadge">
          <img src={AmbassadorBadge} alt="Student Ambassador" />
        </div>
      )}

      {profile.rewards && profile.rewards?.length > 0 && (
        <>
          {profile.rewards.map((reward) => {
            return (
              <div className="profileDetailsWinnerBadge" key={reward.id}>
                <ProjectWinnerBadge variant="horizontal" reward={reward} />
              </div>
            )
          })}
        </>
      )}

      {(isConnected || profile.type === "group") &&
      width < theme.breakpoints.values.md ? (
        <>
          <Box
            marginBottom={width >= theme.breakpoints.values.sm ? 2 : 1.5}
            marginTop={3}
          >
            <TitleLabel>contact</TitleLabel>
          </Box>
          <ContactBox>
            {profile.type === "group" && profile.location && (
              <MobileContactBox
                marginBottom={width >= theme.breakpoints.values.sm ? 0 : 1}
              >
                <IconBox src={LocationIcon} alt="location" />
                <FitText>
                  {SimpleAddress({
                    profileType: profile!.type,
                    location: profile.location,
                  })}
                </FitText>
              </MobileContactBox>
            )}
            {profile.phoneNumber && (
              <MobileContactBox
                marginBottom={width >= theme.breakpoints.values.sm ? 0 : 1}
              >
                <IconBox src={PhoneIcon} alt="phone" />
                <FitText style={{ marginTop: "2px" }}>
                  {profile.phoneNumber}
                </FitText>
              </MobileContactBox>
            )}
            <MobileContactBox>
              <IconBox src={EmailIcon} alt="email" />
              <FitText>{profile.email}</FitText>
            </MobileContactBox>
          </ContactBox>
        </>
      ) : null}

      <Box
        marginTop={profile.aboutSelf ? 3 : 3}
        marginBottom={width >= theme.breakpoints.values.sm ? 2 : 1.5}
      >
        <TitleLabel>stats</TitleLabel>
      </Box>

      {(width <= theme.breakpoints.values.md &&
        width >= theme.breakpoints.values.sm) ||
      (width >= theme.breakpoints.values.md && profile.type === "group") ? (
        <>
          <CounterArea
            style={
              width >= theme.breakpoints.values.md ? { flexFlow: "column" } : {}
            }
          >
            <CounterBox
              style={{
                cursor: profile.followersCount === 0 ? "default" : "pointer",
              }}
              onClick={() => handleOpen("followers")}
            >
              <DarkLabel>{profile.followersCount}</DarkLabel>
              <LightLabel>
                {profile.followersCount === 1 ? "follower" : "followers"}
              </LightLabel>
            </CounterBox>
            {profile.type !== "group" && (
              <CounterBox
                marginLeft={2}
                style={{
                  cursor:
                    profile.connectionsCount === 0 ? "default" : "pointer",
                }}
                onClick={() => handleOpen("connections")}
              >
                <DarkLabel>{profile.connectionsCount}</DarkLabel>
                <LightLabel>
                  {profile.connectionsCount === 1
                    ? "connection"
                    : "connections"}
                </LightLabel>
              </CounterBox>
            )}
            <CounterBox
              marginLeft={width >= theme.breakpoints.values.md ? 0 : 2}
              marginTop={width >= theme.breakpoints.values.md ? 2 : 0}
            >
              <DarkLabel>{profile.likes}</DarkLabel>
              <LightLabel>{profile.likes === 1 ? "like" : "likes"}</LightLabel>
            </CounterBox>
            <CounterBox
              marginLeft={width >= theme.breakpoints.values.md ? 0 : 2}
              marginTop={width >= theme.breakpoints.values.md ? 2 : 0}
            >
              <DarkLabel>{profile.nominations}</DarkLabel>
              <LightLabel>
                {profile.nominations === 1 ? "nomination" : "nominations"}
              </LightLabel>
            </CounterBox>
          </CounterArea>
        </>
      ) : (
        <>
          {(profile.type !== "group" ||
            (profile.type === "group" &&
              width < theme.breakpoints.values.sm)) && (
            <>
              <CounterArea>
                <CounterBox
                  style={{
                    cursor:
                      profile.followersCount === 0 ? "default" : "pointer",
                  }}
                  onClick={() => handleOpen("followers")}
                >
                  <DarkLabel>{profile.followersCount}</DarkLabel>
                  <LightLabel>
                    {profile.followersCount === 1 ? "follower" : "followers"}
                  </LightLabel>
                </CounterBox>
                {profile.type !== "group" && (
                  <CounterBox
                    onClick={() => handleOpen("connections")}
                    style={{
                      cursor:
                        profile.connectionsCount === 0 ? "default" : "pointer",
                    }}
                    marginLeft={width >= theme.breakpoints.values.md ? 2 : 0}
                    marginTop={width >= theme.breakpoints.values.sm ? 0 : 1}
                  >
                    <DarkLabel>{profile.connectionsCount}</DarkLabel>
                    <LightLabel>
                      {profile.connectionsCount === 1
                        ? "connection"
                        : "connections"}
                    </LightLabel>
                  </CounterBox>
                )}
              </CounterArea>

              <CounterArea>
                <CounterBox
                  marginTop={width >= theme.breakpoints.values.sm ? 2 : 1}
                >
                  <DarkLabel>{profile.likes}</DarkLabel>
                  <LightLabel>
                    {profile.likes === 1 ? "like" : "likes"}
                  </LightLabel>
                </CounterBox>
                <CounterBox
                  marginLeft={width >= theme.breakpoints.values.md ? 2 : 0}
                  marginTop={width >= theme.breakpoints.values.sm ? 2 : 1}
                >
                  <DarkLabel>{profile.nominations}</DarkLabel>
                  <LightLabel>
                    {profile.nominations === 1 ? "nomination" : "nominations"}
                  </LightLabel>
                </CounterBox>
              </CounterArea>
            </>
          )}
        </>
      )}

      {(profile.linkedinUrl ||
        profile.twitterUrl ||
        profile.facebookUrl ||
        profile.instagramUrl ||
        profile.personalCompanyUrl) && (
        <Box
          marginBottom={width >= theme.breakpoints.values.sm ? 2 : 1.5}
          marginTop={3}
        >
          <TitleLabel>on the web</TitleLabel>
        </Box>
      )}

      <SocialBox marginBottom={3}>
        {profile.personalCompanyUrl && (
          <IconLink link={profile.personalCompanyUrl}>
            <WebsiteIcon />
          </IconLink>
        )}
        {profile.linkedinUrl && (
          <IconLink link={profile.linkedinUrl}>
            <LinkedInIcon />
          </IconLink>
        )}
        {profile.twitterUrl && (
          <IconLink link={profile.twitterUrl}>
            <img src={XIcon} alt="X" style={{ width: "18px" }} />
          </IconLink>
        )}
        {profile.facebookUrl && (
          <IconLink link={profile.facebookUrl}>
            <FacebookIcon />
          </IconLink>
        )}
        {profile.instagramUrl && (
          <IconLink link={profile.instagramUrl}>
            <Instagram />
          </IconLink>
        )}
      </SocialBox>
      {width >= theme.breakpoints.values.md && (
        <LightLabel style={{ lineHeight: "11px", marginTop: 0 }}>
          Member since: {moment(profile.createdAt).format("MMMM D, YYYY")}
        </LightLabel>
      )}
      {width < theme.breakpoints.values.md && (
        <MemberSinceArea>
          <LightLabel
            style={{
              lineHeight: "16px",
              marginTop: 0,
              fontSize: width >= theme.breakpoints.values.sm ? "14px" : "12px",
            }}
          >
            Member since: {moment(profile.createdAt).format("MMMM D, YYYY")}
          </LightLabel>
        </MemberSinceArea>
      )}
      {open && (
        <ConnectionListDialog
          open={open}
          type={connectionListType}
          onClose={handleClose}
          profile={profile}
        />
      )}
    </>
  )
}

export default AboutSelfSection
