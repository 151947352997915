import React from "react"
import ReactDOM from "react-dom"
import { IntercomProvider } from "react-use-intercom"

import "./index.css"
import "./fonts/FreightDispProBook-regular.woff2"
import "./fonts/FreightDispProBook-regular.woff"
import "./fonts/FreightDispProBook-regular.woff"

import * as serviceWorker from "./serviceWorker"

import App from "./App"
import { Provider } from "react-redux"
import store from "./redux/configureStore"

import AuthProvider from "./components/AuthProvider"
import { ThemeProvider } from "@material-ui/styles"
import theme from "./theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { enUS } from "date-fns/locale"
import { SegmentProvider } from "./context"

import { BrowserRouter as Router } from "react-router-dom"
import { StyleSheetManager } from "styled-components"
import AppUrlListener from "./components/AppUrlListener"

//const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? ""
const INTERCOM_APP_ID = "lvaymr7v"

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js") // Caminho para o seu arquivo service-worker.js
//       .then((registration) => {})
//       .catch((error) => {})
//   })
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
        <AuthProvider>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <ThemeProvider theme={theme}>
              <Router>
                <AppUrlListener />
                <SegmentProvider>
                  <StyleSheetManager disableCSSOMInjection={true}>
                    <App />
                  </StyleSheetManager>
                </SegmentProvider>
              </Router>
            </ThemeProvider>
          </IntercomProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
