import React from "react"
import JobRequestForm from "../JobRequestForm"
import { JobRequest } from "../../redux/jobListings"
import { RequestDialog, RequestDialogTitle, RequestDialogBox } from "./Styles"
import { RequestDialogText } from "../JobRequestForm/styles"
import { Box, Button } from "@material-ui/core"

export type Props = {
  onClose: () => void
  onSubmit: (values: JobRequest) => void
  open: boolean
  requested: boolean
}

const View = ({ open, onSubmit, onClose, requested }: Props) => {
  return (
    <RequestDialog maxWidth={"md"} open={open} onClose={onClose}>
      <RequestDialogBox>
        {requested ? (
          <>
            <RequestDialogTitle variant="h3">
              Request received
            </RequestDialogTitle>
            <Box marginTop="40px">
              <RequestDialogText>
                Thanks! The Spectacular Team will reach out after reviewing your
                request.
              </RequestDialogText>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Button
                style={{
                  border: "1px solid black",
                  borderColor: "#2D2D2D",
                  width: "151px",
                  height: "54px",
                  fontSize: "16px",
                }}
                color="primary"
                size="small"
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
          </>
        ) : (
          <>
            <RequestDialogTitle variant="h3">
              Important details
            </RequestDialogTitle>
            <JobRequestForm
              onClose={onClose}
              onSubmit={onSubmit}
              requested={requested}
            />
          </>
        )}
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
