import React from "react"
import { Box, Grid, Typography, LinearProgress } from "@material-ui/core"
import { FieldArray, FormikContextType, useFormikContext } from "formik"

import { Project } from "../../redux/projects"
import { Profile, ProfileType } from "../../redux/profile"

import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"

import AddIcon from "../../images/add-collaborator-icon.svg"

import ProjectRole from "../../components/ProjectRole"
import ProjectTags from "../../components/ProjectTags"
import ProjectDeleteLink from "../../components/ProjectDeleteLink"

import { WizardStep, StepProps } from "./Wizard"

import { StyledLinkBox, StyledLink } from "./styles"
import { AddAuthor, InlineLink } from "../InnovationAwardPage/styles"
import TextFieldLabelAbove from "../../components/TextFieldLabelAbove"
import ProjectCollaborator from "../../components/ProjectCollaborator"
import ProjectDiscipline from "../../components/ProjectDiscipline"
import InviteCollaboratorDialog from "../ProjectWizardPage/InviteCollaboratorDialog"
import { FormSpacingBox } from "../ProjectWizardPage/styles"

const MAX_CHAR_COUNT = 3000

interface Props extends StepProps {
  profile: Profile
  isUploading: boolean
  currentFile: string
  inviteCollaboratorDialogIsOpen: boolean
  onChangeInviteCollaboratorDialog: (value: boolean) => void
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}
const autocompleteService = { current: null }
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

const Step3: React.FC<Props> = ({
  profile,
  onCancel,
  isUploading,
  currentFile,
  inviteCollaboratorDialogIsOpen,
  onChangeInviteCollaboratorDialog,
}) => {
  const formik: FormikContextType<Project> = useFormikContext()

  const { values: project } = formik

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
  }

  let savedLocation: string = ""
  if (formik.values.location) {
    savedLocation = formik.values.location
  }
  let defaultLocation: PlaceType = {
    description: savedLocation,
    structured_formatting: {
      main_text: "main text",
      secondary_text: savedLocation,
      main_text_matched_substrings: [
        {
          offset: 1,
          length: 1,
        },
      ],
    },
  }
  const classes = useStyles()
  const [value, setValue] = React.useState<PlaceType | null>(defaultLocation)
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const loaded = React.useRef(false)
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB882GbnKnvMUITZPoLHSzr8No39YHUymE&libraries=places",
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }
  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )
  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <WizardStep>
      <>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography tabIndex={0} variant="h3">
            Edit Project
          </Typography>
          <Box mt={3}>
            <StyledLinkBox onClick={onCancel}>
              <StyledLink>Cancel</StyledLink>
            </StyledLinkBox>
          </Box>
        </Box>
        <ProjectDeleteLink project={project} />

        {profile.type === ProfileType.INDIVIDUAL && (
          <FormSpacingBox>
            <ProjectRole
              role={formik.values.role!}
              onChange={(value) => formik.setFieldValue("role", value)}
            />
          </FormSpacingBox>
        )}

        <FormSpacingBox>
          <TextFieldLabelAbove
            name="responsibilities"
            displayTitle="Responsibilities"
            placeholder="Tell us about your responsibilities on this project"
            multiline={true}
            rows={12}
            rowsMax={24}
            data-testid="project-description-field"
            value={formik.values.responsibilities}
            onChange={formik.handleChange}
            onInput={characterCounter}
          />
          <Typography variant="body2" style={{ float: "right" }}>
            {formik.values.responsibilities?.length}/{MAX_CHAR_COUNT} characters
          </Typography>
        </FormSpacingBox>

        <FormSpacingBox>
          <Autocomplete
            id="google-map-demo"
            freeSolo={true}
            popupIcon={""}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(_: any, newValue: any) => {
              setOptions(newValue ? [newValue, ...options] : options)
              setValue(newValue)
              formik.setFieldValue("location", newValue?.description)
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            renderInput={(params) => (
              <TextFieldLabelAbove
                {...params}
                name="location"
                displayTitle="Location"
                style={{ height: "48px", paddingTop: 0 }}
                placeholder="City, state, zip code, or address"
                data-testid="project-location-field"
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  formik.setFieldValue("location", e.target.value)
                }}
              />
            )}
            renderOption={(option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [
                  match.offset,
                  match.offset + match.length,
                ])
              )

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part: any, index: number) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              )
            }}
          />
        </FormSpacingBox>

        <FormSpacingBox>
          <ProjectTags
            initialTags={formik.values.tags}
            onChange={(values) => formik.setFieldValue("tags", values)}
          />
        </FormSpacingBox>

        <FieldArray name="collaborators">
          {({ push, remove }) => (
            <>
              {formik.values.collaborators &&
                formik.values.collaborators.length > 0 && (
                  <>
                    {formik.values.collaborators.map((collaborator, index) => (
                      <>
                        <FormSpacingBox>
                          <Typography variant={"h5"}>
                            Project collaborator
                          </Typography>
                        </FormSpacingBox>
                        <FormSpacingBox
                          key={`discipline-${index}-${collaborator.profileId}`}
                        >
                          <ProjectDiscipline
                            discipline={collaborator.discipline}
                            onChange={(value) =>
                              formik.setFieldValue(
                                `collaborators.${index}.discipline`,
                                value
                              )
                            }
                          />
                        </FormSpacingBox>

                        <FormSpacingBox
                          key={`company-${index}-${collaborator.profileId}`}
                        >
                          <TextFieldLabelAbove
                            fullWidth
                            name={`collaborators.${index}.company`}
                            displayTitle="Company name"
                            placeholder="Type in company name"
                            style={{ height: "48px" }}
                          />
                        </FormSpacingBox>

                        <FormSpacingBox
                          key={
                            "collaborator-" +
                            index +
                            "-" +
                            collaborator.profileId
                          }
                        >
                          <ProjectCollaborator
                            index={index}
                            initialCollaborators={formik.values.collaborators}
                            initialCollaborator={collaborator}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `collaborators.${index}.name`,
                                value?.display
                              )
                              formik.setFieldValue(
                                `collaborators.${index}.profileId`,
                                value?.cleanId
                              )
                            }}
                          />
                        </FormSpacingBox>

                        {formik.values.collaborators &&
                          formik.values.collaborators.length > 1 && (
                            <FormSpacingBox key={index}>
                              <AddAuthor onClick={() => remove(index)}>
                                - Remove Collaborator
                              </AddAuthor>
                            </FormSpacingBox>
                          )}
                      </>
                    ))}
                    <FormSpacingBox>
                      Didn't find your collaborator?{" "}
                      <InlineLink
                        onClick={() => onChangeInviteCollaboratorDialog(true)}
                      >
                        Click here
                      </InlineLink>{" "}
                      to invite them to Spectacular.
                    </FormSpacingBox>
                    <FormSpacingBox>
                      <Box
                        onClick={() =>
                          push({
                            discipline: "",
                            company: "",
                            name: "",
                            profileId: "",
                            id: "",
                          })
                        }
                        style={{
                          backgroundColor: "#2d2d2d",
                          color: "#fff",
                          width: "184px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          justifyContent: "center",
                          gap: "12px",
                        }}
                      >
                        <img src={AddIcon} alt={"add"} /> Add Collaborator
                      </Box>
                    </FormSpacingBox>
                  </>
                )}
            </>
          )}
        </FieldArray>

        {isUploading ? (
          <Box marginTop={12}>
            <Typography>Uploading: {currentFile}</Typography>
            <LinearProgress />
          </Box>
        ) : null}
      </>

      <InviteCollaboratorDialog
        projectId={formik.values.id}
        open={inviteCollaboratorDialogIsOpen}
        onClose={() => onChangeInviteCollaboratorDialog(false)}
      />
    </WizardStep>
  )
}

Step3.defaultProps = {}

export default Step3
