import React from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { Notification } from "../../redux/bellNotifications"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import NotificationFollowIcon from "../../images/notify-person.svg"
import * as RouteConstants from "../../constants/routes"
import moment from "moment"
import {
  NotificationBox,
  AvatarBox,
  DescriptionBox,
  AvatarIcon,
  NotificationAvatar,
} from "../NotificationsBell/styles"

export type Props = {
  notification: Notification
  onNotificationClick: (notificationId?: string) => void
  goToNotifications?: boolean
}

const View = ({
  notification,
  goToNotifications,
  onNotificationClick,
}: Props) => {
  const profileLink = `${RouteConstants.PROFILE_CONTAINER}/${notification?.profile?.username}`
  const history = useHistory()

  const goToURL = (url: string) => {
    if (goToNotifications) {
      history.push("/notifications")
    } else {
      onNotificationClick(notification.id)
      history.push(url)
    }
  }

  return (
    <NotificationBox
      onClick={() => {
        goToURL(profileLink)
      }}
      style={{ cursor: "pointer" }}
    >
      <AvatarBox>
        <NotificationAvatar
          src={
            notification?.profile?.smallImageUrl === undefined
              ? getRandomPlaceholder(notification?.profile?.placeholderAvatar!)
              : (notification?.profile?.smallImageUrl as string)
          }
          alt={"notification"}
        />

        <AvatarIcon src={NotificationFollowIcon} alt={"notification"} />
      </AvatarBox>
      <DescriptionBox>
        <Box style={{ marginTop: "-10px" }}>
          <b>Congratulations</b>
          &nbsp;your profile has been created! &nbsp;
          <span style={{ color: "#717171" }}>
            {moment(notification?.createdAt).fromNow(true).replace(" ", "")}
          </span>
        </Box>
      </DescriptionBox>
    </NotificationBox>
  )
}

export default View
