import React from "react"

import { Box } from "@material-ui/core"

import { NullstateTab } from "../../styles"
import { useWindowDimensions } from "../../../../hooks"
import { ProfileProject } from "../../../../redux/profile"

import { paginate } from "../../../../utils"
import { MainPagination } from "../Styles"
import ProjectCard from "../../../../cards/ProjectCard"
import { BoxBody } from "../../../../components/Styles/GridCell"
import ProfileNullstate from "../../../../components/ProfileNullstate"
import {
  PlaceholderProjectCard,
  ProjectsListCardBox,
} from "../../../HomePage/styles"
import NewPostButton from "../../../../components/NewPostButton"

interface Props {
  isPublicProfile: boolean
  onAddProject: () => void
  goToUrl: (value: string) => void
  projects: ProfileProject[]
  featuredProject: ProfileProject
}

export const ProjectsTab = ({
  projects,
  onAddProject,
  goToUrl,
  isPublicProfile,
  featuredProject,
}: Props) => {
  const { width } = useWindowDimensions()
  const [pagination, setPagination] = React.useState(paginate(projects))

  const handlePagination = (_: any, page: number) => {
    setPagination(paginate(projects, page))
    document
      .getElementById("ProjectList")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  if (projects.length === 0)
    return (
      <NullstateTab>
        {!isPublicProfile ? (
          <ProfileNullstate
            onClick={onAddProject}
            type={"group-owner-projects-tab"}
          />
        ) : (
          <ProfileNullstate
            onClick={() => goToUrl("/projects")}
            type={"group-projects-tab"}
          />
        )}
      </NullstateTab>
    )

  return (
    <>
      {!isPublicProfile && (
        <Box style={{ paddingTop: "40px" }}>
          <NewPostButton origin="projects" />
        </Box>
      )}
      <Box style={{ paddingTop: "40px" }}>
        {width > 960 ? (
          <ProjectCard
            project={featuredProject as any}
            large={true}
            source={"profiles-page"}
          />
        ) : null}

        {width > 960 ? (
          <BoxBody
            style={{
              marginTop: "40px",
              marginBottom: "40px",
              width: "100%",
              borderBottom: "0.5px solid #D8D8D8",
            }}
          />
        ) : null}

        <Box id={"ProjectList"} />

        <ProjectsListCardBox>
          {pagination.items.map((project) => {
            return (
              <ProjectCard
                project={project}
                hideComments={true}
                source={"profiles-page"}
              />
            )
          })}
          {!isEven(projects?.length) && <PlaceholderProjectCard />}
        </ProjectsListCardBox>

        <Box paddingTop={5} paddingBottom={8}>
          {pagination.totalPages > 1 && (
            <MainPagination
              variant="outlined"
              page={pagination.page}
              onChange={handlePagination}
              count={pagination.totalPages}
              data-testid="load-more-projects-pagination"
            />
          )}
        </Box>
      </Box>
    </>
  )
}
