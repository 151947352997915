import React, { useState } from "react"
import { sortBy, cloneDeep } from "lodash"
import { Formik, FormikProps } from "formik"
import { Box, Typography, Grid, Button } from "@material-ui/core"

import { Project, Image } from "../../redux/adminProjects"
import projectStates from "../../constants/project-states"
import projectCategories from "../../constants/project-categories"
import { minResX, minResY } from "../../constants/img-resolutions"
import ProjectRole from "../ProjectRole"

import TextField from "../TextField"
import ProjectTags from "../ProjectTags"
import SelectField from "../SelectField"
import ImageUploader from "../ImageUploader"

import { FormStyled } from "./styles"

type Props = {
  project: Project
  onSubmit: (values: Project) => Promise<void>
}

const removedImageIds = Array(0)
const View = ({ project, onSubmit }: Props) => {
  const [images, setImages] = useState<Image[]>(project.images ?? [])
  const sortedImages = sortBy(images, "order")

  const handleAddImages = (
    newImages: Array<Image>,
    formik: FormikProps<Project>
  ) => {
    const allImages = [...images, ...newImages]
    formik.setFieldValue("images", allImages)
    setImages(allImages)
  }

  const handleSortImages = (
    sortedImages: Array<Image>,
    formik: FormikProps<Project>
  ) => {
    formik.setFieldValue("images", sortedImages)
    setImages(sortedImages)
  }

  const handleRemoveImage = (
    removedImage: Image,
    formik: FormikProps<Project>
  ) => {
    const allImages = images.filter((image) => image !== removedImage)
    const sortedImages = sortBy(cloneDeep(allImages), "order")
    sortedImages.forEach((image, index) => (image.order = index))
    formik.setFieldValue("images", sortedImages)
    setImages(sortedImages)
    if (removedImage.id) {
      removedImageIds.push(removedImage.id)
      formik.setFieldValue("removedImages", removedImageIds)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={project}
      render={(formik) => (
        <FormStyled>
          <Grid container>
            <Grid item>
              <TextField
                name="name"
                label="Title"
                data-testid="project-name-field"
              />
              <TextField
                name="description"
                label="Tell us about your project"
                multiline={true}
                rows={4}
                rowsMax={24}
                data-testid="project-description-field"
              />
              <SelectField
                name="category"
                label="Project category"
                options={projectCategories}
                blank="Select project category"
                data-testid="project-category-field"
              />
              <TextField
                name="location"
                label="Location (optional)"
                data-testid="project-location-field"
              />
              <ProjectRole
                role={formik.values.role!}
                onChange={(value) => formik.setFieldValue("role", value)}
              />
              <SelectField
                name="state"
                label="Project status"
                options={projectStates}
                blank="Select project status"
                data-testid="project-state-field"
              />
              <ProjectTags
                initialTags={formik.values.tags}
                onChange={(values) => formik.setFieldValue("tags", values)}
              />
              <Box marginTop={2}>
                Image upload
                <ImageUploader
                  allowMultiple
                  minResX={minResX}
                  minResY={minResY}
                  title="Add images"
                  images={sortedImages}
                  onAddImages={(added) => handleAddImages(added, formik)}
                  onSortImages={(sorted) => handleSortImages(sorted, formik)}
                  onRemoveImage={(removed) =>
                    handleRemoveImage(removed, formik)
                  }
                />
                <Box marginBottom={6} marginTop={2}>
                  <Box marginBottom={2} marginTop={2}>
                    <Typography tabIndex={0} variant="caption">
                      Image resolution minimum is 1600x1068. Accepted file types
                      include .jpeg, .jpg, .png, .pdf and .tiff. PDF and TIFF
                      will be converted to JPG
                    </Typography>
                  </Box>
                  <Box marginBottom={2} marginTop={2}>
                    <Typography tabIndex={0} variant="caption">
                      Images larger than 10MB will be compressed
                    </Typography>
                  </Box>
                  <Typography tabIndex={0} variant="caption">
                    You can drag and drop images to re-arrange. The first image
                    will be your project thumbnail.
                  </Typography>
                </Box>
              </Box>

              <Button
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
              >
                Update project
              </Button>
            </Grid>
          </Grid>
        </FormStyled>
      )}
    />
  )
}

export default View
