import {
  Box,
  Button as MUIButton,
  Dialog as MUIDialog,
} from "@material-ui/core"
import styled from "styled-components"

import theme from "../../../theme"

export const ContentPadding = styled(Box)`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;
  }
`

export const ContentText = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding-top: 24px;
  padding-bottom: 24px;

  ${theme.breakpoints.up("sm")} {
    width: 600px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const PopoverTitle = styled.span`
  color: #2d2d2d;
  font-size: 20px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const PopoverHeader = styled(Box)`
  height: 64px;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  justify-content: space-between;
  padding: 0 24px 0 24px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px 0 20px;
  }
`

export const Boxshadow = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 15px;
    right: 19px;
    box-sizing: border-box;
    border: 0.6em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const Divider = styled(Box)`
  width: 100%;
  border-top: 0.5px solid #d8d8d8;
`

export const Dialog = styled(MUIDialog)`
  min-height: 100vh;
  margin: 0 !important;

  && {
    .MuiPaper-rounded {
      border-radius: 1px !important;
    }
  }
`

export const PopoverContainer = styled.div`
  margin: 0 !important;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  margin-top: 24px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 1px;
  background-color: #fff;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);
`

export const Button = styled(MUIButton)`
  height: 40px !important;
  max-height: 40px !important;
  max-width: 140px;
  min-width: 95px;
  padding: 8px 22px;
  border-radius: 1px;
  font-family: "Public-Sans";
  font-weight: 400 !important;

  && {
    font-weight: 400 !important;

    MuiButton-label {
      font-weight: 400 !important;
    }
  }
`
