import React from "react"
import { Link } from "react-router-dom"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Switch,
  TextField as MUITextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { Profile } from "../../redux/profile"
import { Image } from "../../redux/projects"
import TextField from "../TextField"
import ImageUploader from "../ImageUploader"
import { FormStyled, SocialIcon, EmailLink } from "./styles"
import SelectField from "../SelectField"
import companySizes from "../../constants/company-sizes"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"
import iconLinkedin from "../../images/icons/social/linkedin.svg"
import iconX from "../../images/icons/social/x.svg"
import iconFacebook from "../../images/icons/social/facebook.svg"
import iconInstagram from "../../images/icons/social/instagram.svg"
import iconWebsite from "../../images/icons/social/website.svg"
import GroupType from "../GroupType"
import { phoneValidation } from "../../utils/phoneValidation"

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}
const autocompleteService = { current: null }
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

const emailBody = () =>
  `I would like to unsubscribe from the Spectacular Newsletter.`
const mailTo = () =>
  `mailto:?subject=Unsubscribe from Spectacular Newsletter&body=${encodeURIComponent(
    emailBody()
  )}`

export type Props = {
  profile: Profile
  disableSubmit: boolean
  usernameCheck: string
  emailCheck: string
  usernameValue: string
  emailValue: string
  onCheckUsernameAvailability: (
    _event: React.ChangeEvent<{ value: any }>
  ) => void
  onCheckEmailAvailability: (_event: React.ChangeEvent<{ value: any }>) => void
  onSubmit: Function
  onCancel: Function
  adminEditing?: boolean
}

const GroupProfileSchema = Yup.object().shape({
  groupName: Yup.string().required("Required").typeError("Required"),
  groupType: Yup.string().required("Required").typeError("Required"),
  username: Yup.string().required("Required").typeError("Required"),
  contactEmail: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Must be a valid email"
    ),
  firstName: Yup.string().required("Required").typeError("Required"),
  lastName: Yup.string().required("Required").typeError("Required"),
  companySize: Yup.string().required("Required").typeError("Required"),
  location: Yup.string().required("Required").typeError("Required"),
  aboutSelf: Yup.string()
    .max(300, "Too long!")
    .typeError("Required")
    .required("Required"),
})

const AdminProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required").typeError("Required"),
  username: Yup.string().required("Required").typeError("Required"),
})

const View = ({
  profile,
  onCancel,
  onSubmit,
  emailValue,
  emailCheck,
  usernameValue,
  usernameCheck,
  adminEditing,
  disableSubmit,
  onCheckEmailAvailability,
  onCheckUsernameAvailability,
}: Props) => {
  const creatingProfile =
    profile.location === null || profile.aboutSelf === null

  const handleAddImage = (
    images: Array<Image>,
    formik: FormikProps<Profile>
  ) => {
    const image = images[0]
    formik.setFieldValue("file", image.file)
    formik.setFieldValue("smallImageUrl", image.url)
  }

  const handleRemoveImage = (formik: FormikProps<Profile>) => {
    formik.setFieldValue("file", null)
    formik.setFieldValue("smallImageUrl", null)
  }

  const handleAddBannerImage = (
    images: Array<Image>,
    formik: FormikProps<Profile>
  ) => {
    const image = images[0]
    formik.setFieldValue("bannerFile", image.file)
    formik.setFieldValue("bannerImageUrl", image.url)
  }

  const handleRemoveBannerImage = (formik: FormikProps<Profile>) => {
    formik.setFieldValue("bannerFile", null)
    formik.setFieldValue("bannerImageUrl", null)
  }

  const buildImages = (values: Profile) =>
    values.smallImageUrl ? [{ order: 0, url: values.smallImageUrl }] : []

  const buildBannerImage = (values: Profile) =>
    values.bannerImageUrl ? [{ order: 0, url: values.bannerImageUrl }] : []

  let defaultLocation: PlaceType = {
    description: profile?.location,
    structured_formatting: {
      main_text: "main text",
      secondary_text: profile?.location,
      main_text_matched_substrings: [
        {
          offset: 1,
          length: 1,
        },
      ],
    },
  }

  const MAX_CHAR_COUNT = 220

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
  }

  const validatePhone = (e: any) => {
    e.target.value = phoneValidation(e.target.value)
  }

  const classes = useStyles()
  const [value, setValue] = React.useState<PlaceType | null>(defaultLocation)
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB882GbnKnvMUITZPoLHSzr8No39YHUymE&libraries=places",
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const [state, setState] = React.useState({
    checkedB: false,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [event.target.name]: event.target.checked })

  const maxLength = 300
  return (
    <Formik
      initialValues={profile}
      onSubmit={async (values: Profile) => onSubmit(values)}
      validationSchema={adminEditing ? AdminProfileSchema : GroupProfileSchema}
      enableReinitialize={true}
    >
      {(formik) => (
        <FormStyled>
          <Grid container>
            <Grid item xs={12}>
              <Typography tabIndex={0} variant="h3">
                {creatingProfile
                  ? "Complete Admin Profile"
                  : "Edit Admin Profile"}
              </Typography>
              <Box marginTop={2} fontSize={14}>
                * = Required
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                name="groupName"
                label="Group name*"
                data-testid="firstName"
              />
              <MUITextField
                name="username"
                label="Group sub-domain*"
                value={usernameValue}
                onChange={(text) => {
                  formik.setFieldValue("username", usernameValue)
                  onCheckUsernameAvailability(text)
                }}
                error={!!usernameCheck}
                helperText={usernameCheck || ""}
                autoComplete="off"
                inputProps={{ "data-testid": "username" }}
                fullWidth
              />
              <Box marginTop={5}>
                <Typography tabIndex={0} variant="h4">
                  Admin Info
                </Typography>
              </Box>

              <MUITextField
                name="contactEmail"
                label="Admin Email*"
                value={emailValue}
                onChange={(text) => {
                  formik.setFieldValue("contactEmail", emailValue)
                  onCheckEmailAvailability(text)
                }}
                error={!!emailCheck}
                helperText={emailCheck || ""}
                autoComplete="off"
                inputProps={{ "data-testid": "contactEmail" }}
                fullWidth
              />

              <Grid container>
                <Grid item xs={12}>
                  <Box marginRight={1}>
                    <TextField
                      name="phoneNumber"
                      label="Phone number"
                      onInput={validatePhone}
                      maxLength={20}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <TextField name="firstName" label="First name*" />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <TextField name="lastName" label="Last name*" />
                </Grid>
              </Grid>
              <Box marginTop={5}>
                <Typography tabIndex={0} variant="h4">
                  Your Group
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <SelectField
                      name="companySize"
                      label="Group size *"
                      options={companySizes}
                      blank="Select group size"
                      onChange={(e: string) => {
                        if (e !== "") {
                          formik.setFieldValue("companySize", e)
                        } else if (e === "") {
                          formik.setFieldError("companySize", "Required")
                          setTimeout(() => {
                            formik.setFieldTouched("companySize", true, true)
                          }, 150)
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <GroupType
                      error={formik.errors.groupType}
                      groupType={formik.values.groupType!}
                      onChange={(value) =>
                        formik.setFieldValue("groupType", value)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Autocomplete
                id="google-map-demo"
                freeSolo={true}
                popupIcon={""}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                onBlur={() => formik.setFieldTouched("location")}
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(_: any, newValue: any) => {
                  setOptions(newValue ? [newValue, ...options] : options)
                  setValue(newValue)
                  formik.setFieldValue("location", newValue?.description)
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    name="location"
                    label="City, state, zip code, or address*"
                    data-testid="profile-location-field"
                    variant="outlined"
                    fullWidth
                    helperText={formik.errors.location}
                    error={formik.errors.location !== undefined}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      formik.setFieldValue("location", e.target.value)
                    }}
                  />
                )}
                renderOption={(option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  )

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                        <Typography variant="body2" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }}
              />
              <Grid container>
                <Grid item xs={6}>
                  <Box marginTop={1} marginRight={1} fontSize={14}>
                    Do you have multiple locations?
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Switch
                    checked={state.checkedB}
                    onChange={handleChange}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  {state.checkedB ? (
                    <Box marginTop={1} fontSize={14}>
                      <Typography variant="caption">
                        We’re currently working on a feature that will allow you
                        to connect your offices under one account. For now, we
                        recommend creating a unique firm profile for each
                        physical location.
                      </Typography>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
              <TextField
                name="aboutSelf"
                label="Tell us about the group*"
                multiline={true}
                rows={4}
                rowsMax={24}
                maxLength={maxLength}
                onChange={formik.handleChange}
                onInput={characterCounter}
              />
              <Typography variant="caption" style={{ float: "right" }}>
                {formik.values.aboutSelf?.length}/{MAX_CHAR_COUNT} characters
              </Typography>

              <Box marginTop={2}>
                <Typography tabIndex={0} variant="h6">
                  Profile image
                </Typography>
                <ImageUploader
                  images={buildImages(formik.values)}
                  onRemoveImage={() => handleRemoveImage(formik)}
                  onAddImages={(images: Array<Image>) =>
                    handleAddImage(images, formik)
                  }
                  title="Upload image"
                />
                <Box marginBottom={2} marginTop={2}>
                  <Typography tabIndex={0} variant="caption">
                    Image resolution minimum is 100x100. Accepted file types
                    include .jpeg, .jpg, and .png. Images larger than 10MB will
                    be compressed.
                  </Typography>
                </Box>
              </Box>

              <Box marginTop={2}>
                <Typography tabIndex={0} variant="h6">
                  Banner image
                </Typography>
                <ImageUploader
                  banner
                  title="Upload image"
                  images={buildBannerImage(formik.values)}
                  onAddImages={(images: Array<Image>) =>
                    handleAddBannerImage(images, formik)
                  }
                  onRemoveImage={() => handleRemoveBannerImage(formik)}
                />
                <Box marginBottom={2} marginTop={2}>
                  <Typography tabIndex={0} variant="caption">
                    Recommended banner resolution is 1440x160. Accepted file
                    types include .jpeg, .jpg, and .png. Images larger than 10MB
                    will be compressed.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box marginLeft={{ xs: 0, sm: 2 }} marginTop={{ xs: 3, sm: 0 }}>
                <MUITextField
                  name="linkedinUrl"
                  label="LinkedIn"
                  onChange={(event: any) => {
                    formik.setFieldValue("linkedinUrl", event.target.value)
                  }}
                  value={formik.values.linkedinUrl}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SocialIcon src={iconLinkedin} alt="LinkedIn" />
                      </IconButton>
                    ),
                  }}
                />

                <MUITextField
                  name="twitterUrl"
                  label="X"
                  onChange={(event: any) => {
                    formik.setFieldValue("twitterUrl", event.target.value)
                  }}
                  value={formik.values.twitterUrl}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SocialIcon src={iconX} alt="X" />
                      </IconButton>
                    ),
                  }}
                />

                <MUITextField
                  name="facebookUrl"
                  label="Facebook"
                  onChange={(event: any) => {
                    formik.setFieldValue("facebookUrl", event.target.value)
                  }}
                  value={formik.values.facebookUrl}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SocialIcon src={iconFacebook} alt="Facebook" />
                      </IconButton>
                    ),
                  }}
                />

                <MUITextField
                  name="instagramUrl"
                  label="Instagram"
                  onChange={(event: any) => {
                    formik.setFieldValue("instagramUrl", event.target.value)
                  }}
                  value={formik.values.instagramUrl}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SocialIcon src={iconInstagram} alt="Instagram" />
                      </IconButton>
                    ),
                  }}
                />

                <MUITextField
                  name="personalCompanyUrl"
                  label="Group website URL"
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      "personalCompanyUrl",
                      event.target.value
                    )
                  }}
                  value={formik.values.personalCompanyUrl}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SocialIcon src={iconWebsite} alt="Group website URL" />
                      </IconButton>
                    ),
                  }}
                />
                <Box marginLeft={{ xs: 0, sm: 2 }} paddingTop={3}>
                  <Typography tabIndex={0} variant="h5">
                    Notification Management
                  </Typography>

                  <Box marginTop={1}>
                    <FormControlLabel
                      label="Receive Notifications by Email"
                      control={
                        <Checkbox
                          name="allowEmailNotifications"
                          onChange={({ target }) =>
                            formik.setFieldValue(
                              "allowEmailNotifications",
                              target.checked
                            )
                          }
                          checked={formik.values.allowEmailNotifications}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box marginLeft={{ xs: 0, sm: 2 }} paddingTop={3}>
                  <Typography tabIndex={0} variant="h5">
                    Newsletter Subscription
                  </Typography>

                  <Box marginTop={1}>
                    <FormControlLabel
                      label="Daily Spectacular"
                      control={
                        <Checkbox
                          name="dailyNewsletter"
                          onChange={({ target }) =>
                            formik.setFieldValue(
                              "dailyNewsletter",
                              target.checked
                            )
                          }
                          checked={formik.values.dailyNewsletter}
                          disabled={
                            formik.values.bothNewsletter ||
                            formik.values.noNewsletter
                          }
                        />
                      }
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      label="Weekly Spectacular"
                      control={
                        <Checkbox
                          name="weeklyNewsletter"
                          onChange={({ target }) =>
                            formik.setFieldValue(
                              "weeklyNewsletter",
                              target.checked
                            )
                          }
                          checked={formik.values.weeklyNewsletter}
                          disabled={
                            formik.values.bothNewsletter ||
                            formik.values.noNewsletter
                          }
                        />
                      }
                    />
                  </Box>

                  <Box marginTop={2}>
                    <Typography>
                      <span style={{ fontWeight: 600 }}>
                        Daily Spectacular{" "}
                      </span>
                      is sent every day and contains all the latest stories from
                      Spectacular.
                    </Typography>
                  </Box>

                  <Box marginTop={2}>
                    <Typography>
                      <span style={{ fontWeight: 600 }}>
                        Weekly Spectacular{" "}
                      </span>{" "}
                      is a curated newsletter that is sent every Thursday,
                      containing highlights from Spectacular. Weekly Spectacular
                      subscribers will also receive occasional updates about
                      events, competitions and breaking news.
                    </Typography>
                  </Box>

                  <Box marginTop={3}>
                    <Typography style={{ marginBottom: "1em" }}>
                      We will only use your email address to send you the
                      newsletters you have requested. We will never give your
                      details to anyone else without your consent. You can
                      unsubscribe at any time by clicking on the unsubscribe
                      link at the bottom of every email, or by emailing us at{" "}
                      <EmailLink
                        onClick={(e) => {
                          window.location = mailTo() as any
                          e.preventDefault()
                        }}
                      >
                        hello@spectacular.design
                      </EmailLink>
                      {". "}
                    </Typography>
                    <Typography>
                      For more details, please see our{" "}
                      <Link to="/privacy_policy">privacy note</Link>.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginTop={3} width="100%" display="flex">
                <Box marginRight={3}>
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting || disableSubmit}
                    type="submit"
                  >
                    Update profile
                  </Button>
                </Box>
                <Button onClick={() => onCancel()}>Cancel</Button>
              </Box>
            </Grid>
          </Grid>
        </FormStyled>
      )}
    </Formik>
  )
}

export default View
