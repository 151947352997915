import React, { useState, useEffect } from "react"

import IconActive from "../../images/new-homepage/icons/like-active.svg"
import IconDefault from "../../images/new-homepage/icons/like-default.svg"
import IconHover from "../../images/new-homepage/icons/like-hover.svg"

import {
  IconContainer,
  Icon,
  CountLabel,
  IconFlexBox,
  CounterFlexBox,
} from "./styles"
import { useAuth0 } from "@auth0/auth0-react"
import { Typography } from "@material-ui/core"
import { News } from "../../redux/adminNews"

export type Props = {
  dispatchComplete: boolean
  news: News
  handleLikeClick: (like: boolean) => Promise<void>
}

const View = ({ dispatchComplete, news, handleLikeClick }: Props) => {
  const { isAuthenticated } = useAuth0()
  const [like, setLike] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [hover, setHover] = React.useState(false)

  const getIcon = (liked?: boolean) => {
    if (liked) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  useEffect(() => {
    setLike(news.liked!)
    setLikeCount(news.likeCount!)
  }, [news])

  const handleClick = () => {
    if (!disableLike) {
      if (like) {
        if (isAuthenticated) {
          setLike(false)
          if (likeCount > 0) setLikeCount(likeCount - 1)
        }
      } else {
        if (isAuthenticated) {
          setLike(true)
          setLikeCount(likeCount + 1)
        }
      }
      handleLikeClick(like)
    }
  }
  let disableLike = false
  if (!dispatchComplete) disableLike = true
  return (
    <IconContainer
      $isActive={like}
      onClick={() => handleClick()}
      data-testid="news-like-button"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <IconFlexBox>
        <CounterFlexBox>
          <Icon src={getIcon(like)} alt="" />
          <CountLabel>{likeCount}</CountLabel>
        </CounterFlexBox>
        <Typography>Like</Typography>
      </IconFlexBox>
    </IconContainer>
  )
}

export default View
