import React, { useState } from "react"
import { Formik, FormikHelpers } from "formik"
import { Project } from "../../redux/projects"
import { Profile } from "../../redux/profile"
import {
  ButtonContainer,
  FormStyled,
  StyledLinkBox,
  StyledLink,
  SaveIcon,
} from "./styles"
import StepNavigation from "../../components/StepNavigation"
import { Box, Typography } from "@material-ui/core"
import UpLoadingPage from "../UpLoadingPage"
import { Button } from "../../components"

import SaveIconSvg from "../../images/save-icon.svg"
import { useWindowDimensions } from "../../hooks"

export interface StepProps {
  validationSchema?: any
  groupValidationSchema?: any
  onCancel: () => void
  onSubmit?: (values: any, bag: any) => void
}

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
type WizardProps = {
  children: React.ReactNode[]
  initialValues: Project
  profile: Profile
  onSubmit: Function
  error?: string
  onCancel: () => void
}
const Wizard = ({
  children,
  initialValues,
  onSubmit,
  error,
  profile,
}: WizardProps) => {
  const { width } = useWindowDimensions()
  const [stepNumber, setStepNumber] = useState(0)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)
  const [isDraft, setIsDraft] = useState(false)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1
  const isFirstStep = stepNumber === 0

  const next = (values: Project) => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
    scrollTop()
  }

  const goToStep = (step: number, values: Project) => {
    setSnapshot(values)
    setStepNumber(step)
  }

  const stepNames = [
    width > 600 ? "1. Upload Images" : "1. Images",
    width > 600 ? "2. Required Project Details" : "2. Details",
    width > 600 ? "3. Additional Details" : "3 Additional",
  ]

  const [uploading, setUploading] = useState(false)
  const handleSubmit = async (values: Project, bag: FormikHelpers<Project>) => {
    if ((step as any).props.onSubmit) (step as any).props.onSubmit(values, bag)
    if (isLastStep) {
      setUploading(!uploading)
      window.scrollTo(0, 0)
      return onSubmit(values, bag, true)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <>
      {uploading || isDraft ? (
        <UpLoadingPage
          message={
            uploading ? "Uploading files..." : "Saving project as a draft..."
          }
        />
      ) : (
        <Formik
          initialValues={snapshot}
          onSubmit={handleSubmit}
          profile={profile}
          validationSchema={
            profile.type === "group"
              ? (step as any).props.groupValidationSchema
              : (step as any).props.validationSchema
          }
        >
          {(formik) => (
            <FormStyled>
              <StepNavigation
                currentStepNumber={stepNumber}
                goToStep={goToStep}
                steps={stepNames}
              />
              {error && (
                <Typography tabIndex={0} color="error">
                  {error}
                </Typography>
              )}
              {step}
              <ButtonContainer isFirstStep={isFirstStep}>
                {!isFirstStep && (
                  <StyledLinkBox
                    onClick={() =>
                      goToStep(
                        Math.min(stepNumber - 1, totalSteps - 1),
                        formik.values
                      )
                    }
                  >
                    <StyledLink>Back</StyledLink>
                  </StyledLinkBox>
                )}

                <Box style={{ textAlign: "right" }}>
                  {!isFirstStep && (
                    <Button
                      style={{
                        height: "48px",
                        width: "152px",
                        marginRight: "16px",
                      }}
                      color={"white"}
                      size="large"
                      data-testid="project-draft-button"
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        setIsDraft(true)
                        formik.setFieldValue("published", false)
                        onSubmit(formik.values, formik, false)
                        window.scrollTo(0, 0)
                      }}
                    >
                      <SaveIcon src={SaveIconSvg} alt={"save"} />
                      Save as draft
                    </Button>
                  )}
                  <Button
                    style={{
                      height: "48px",
                      width: "142px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    data-testid="project-create-button"
                    disabled={formik.isSubmitting}
                    type="submit"
                  >
                    {isLastStep ? "Update project" : "Continue"}
                  </Button>
                </Box>
              </ButtonContainer>
            </FormStyled>
          )}
        </Formik>
      )}
    </>
  )
}

export type WizardStepProps = {
  children: JSX.Element
}
export const WizardStep: any = ({ children }: WizardStepProps) => children

export default Wizard
