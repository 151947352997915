import React, { MouseEvent, useState } from "react"
import { DotButton, Triangle, MenuItem, MenuBox } from "./Styles"
import { Popover } from "@material-ui/core"
import DotIcon from "../../images/post-icon-menu.svg"
import EditIcon from "../../images/post-icon-edit.svg"
import DeleteIcon from "../../images/post-icon-delete.svg"
import ReportIcon from "../../images/post-icon-flag.svg"
import { Post } from "../../redux/posts"
import { Profile } from "../../redux/profile"

export type Props = {
  post: Post
  authenticatedProfile?: Profile
  displayEdit?: boolean
  onDelete: () => void
  onEdit: () => void
  onReport: () => void
}

const View = ({
  post,
  authenticatedProfile,
  displayEdit,
  onDelete,
  onEdit,
  onReport,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const deletePost = () => {
    onDelete()
    handleClose()
  }

  const editPost = () => {
    onEdit()
    handleClose()
  }

  const flagPost = () => {
    onReport()
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <DotButton onClick={handleClick}>
        <img src={DotIcon} alt={"dot menu"} />
      </DotButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            background: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            paddingLeft: "15px",
            paddingBottom: "15px",
            paddingRight: "15px",
            marginLeft: "15px",
          },
        }}
      >
        <Triangle />
        <MenuBox>
          {displayEdit &&
            post.profile?.connectionId ===
              authenticatedProfile?.connectionId && (
              <>
                <MenuItem onClick={editPost}>
                  <img src={EditIcon} alt={"Edit"} />
                  <span>Edit Post</span>
                </MenuItem>

                <MenuItem onClick={deletePost}>
                  <img src={DeleteIcon} alt={"Delete"} />
                  <span>Delete Post</span>
                </MenuItem>
              </>
            )}
          {post.profile?.connectionId !==
            authenticatedProfile?.connectionId && (
            <MenuItem onClick={flagPost}>
              <img src={ReportIcon} alt={"Report"} />
              <span>Report Post</span>
            </MenuItem>
          )}
        </MenuBox>
      </Popover>
    </>
  )
}

export default View
