import React from "react"
import { Box } from "@material-ui/core"
import { Profile } from "../../redux/profile"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import {
  FormArea,
  FormContainer,
  FormBG,
  FormSpacingBox,
  FormTitle,
  FormSubTitle,
} from "../InnovationAwardPage/styles"
import TextField from "../InnovationAwardPage/TextField"
import { Button } from "../../components"
import { FormProps } from "."
import SelectField from "../InnovationAwardPage/SelectField"
import SuccessDialog from "./SuccessDialog"
import { phoneValidation } from "../../utils/phoneValidation"

interface Props {
  authenticatedProfile?: Profile
  onSubmit: (data: FormProps) => void
  showMessage: boolean
  onClose: () => void
}

const options: any[] = [
  { value: "Freshman", name: "Freshman" },
  { value: "Sophomore", name: "Sophomore" },
  { value: "Junior", name: "Junior" },
  { value: "Senior", name: "Senior" },
  { value: "Graduate student", name: "Graduate student" },
  { value: "Other:", name: "Other:" },
]

const FormBox = ({
  authenticatedProfile,
  onSubmit,
  showMessage,
  onClose,
}: Props) => {
  const initialValues: FormProps = {
    name: `${
      authenticatedProfile
        ? authenticatedProfile.firstName + " " + authenticatedProfile.lastName
        : ""
    }`,
    email: `${authenticatedProfile ? authenticatedProfile.email : ""}`,
    program: "",
    status: "",
    phoneNumber: "",
    organization: "",
  }

  const handleSubmit = async (
    values: FormProps,
    actions: FormikHelpers<FormProps>
  ) => {
    await onSubmit(values)
    actions.setSubmitting(false)
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    email: Yup.string()
      .required("This field is required.")
      .email("This field must be a valid email address."),
    program: Yup.string().required("This field is required."),
    status: Yup.string().required("This field is required."),
    phoneNumber: Yup.string().required("This field is required."),
    other: Yup.string()
      .ensure()
      .when("status", {
        is: "Other:",
        then: Yup.string().required("This field is required."),
      }),
  })

  const validatePhone = (e: any) => {
    e.target.value = phoneValidation(e.target.value)
  }

  return (
    <FormArea id="awardForm">
      <FormContainer>
        <FormBG>
          <Formik
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
            initialValues={initialValues}
            validationSchema={ValidationSchema}
          >
            {(formik) => (
              <Form>
                <FormTitle variant={"h1"}>
                  Apply to be a student ambassador:
                </FormTitle>
                <FormSubTitle>
                  Required fields are marked with an asterisk <span>*</span>
                </FormSubTitle>
                <Box>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="John Doe"
                    displayTitle="Name"
                    style={{ height: "48px" }}
                    presetValue={
                      authenticatedProfile
                        ? authenticatedProfile.firstName +
                          " " +
                          authenticatedProfile.lastName
                        : undefined
                    }
                  />
                </Box>
                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="program"
                    displayTitle="Architecture program / University"
                    placeholder="Name of the institution"
                    style={{ height: "48px" }}
                  />
                </FormSpacingBox>
                <FormSpacingBox>
                  <SelectField
                    name="status"
                    options={options}
                    blank="Ex: Freshman, Sophomore"
                    label="Year"
                    style={{
                      fontSize: "16px",
                    }}
                    onFocus={() => formik.setFieldTouched("status", true)}
                  />
                </FormSpacingBox>
                {formik.values.status === "Other:" && (
                  <Box>
                    <TextField
                      fullWidth
                      name="other"
                      displayTitle=""
                      placeholder="Other: "
                      style={{ height: "48px" }}
                    />
                  </Box>
                )}
                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="organization"
                    displayTitle="Organization involvement"
                    placeholder="Ex: AIAS, NOMAS, WIA, etc."
                    style={{ height: "48px" }}
                  />
                </FormSpacingBox>
                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="email"
                    displayTitle="Email"
                    placeholder="john.doe@example.com"
                    style={{ height: "48px" }}
                    presetValue={
                      authenticatedProfile
                        ? authenticatedProfile.email
                        : undefined
                    }
                  />
                </FormSpacingBox>
                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="phoneNumber"
                    displayTitle="Cell Phone"
                    placeholder="9999999999"
                    style={{ height: "48px" }}
                    onInput={validatePhone}
                    maxLength={20}
                    presetValue={
                      authenticatedProfile
                        ? authenticatedProfile.phoneNumber
                        : undefined
                    }
                  />
                </FormSpacingBox>
                <FormSpacingBox>
                  <Button
                    type={"submit"}
                    children={"Apply"}
                    color={"secondary"}
                    size={"medium"}
                    disabled={formik.isSubmitting}
                    style={{
                      width: "203px",
                      height: "56px",
                      fontSize: "16px",
                    }}
                  />
                </FormSpacingBox>
              </Form>
            )}
          </Formik>
        </FormBG>
      </FormContainer>
      <SuccessDialog open={showMessage} onClose={onClose} />
    </FormArea>
  )
}

export default FormBox
