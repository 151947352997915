export const paginate = (items: any[], page = 1, perPage = 6) => {
  const offset = perPage * (page - 1)
  const totalPages = Math.ceil(items.length / perPage)
  const paginatedItems = items.slice(offset, perPage * page)

  return {
    page,
    perPage,
    total: items.length,
    items: paginatedItems,
    totalPages: totalPages,
    previousPage: page - 1 ? page - 1 : null,
    nextPage: totalPages > page ? page + 1 : null,
  }
}
