import React from "react"
import { Notification } from "../../redux/bellNotifications"

import View from "./View"

type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  hideButton?: boolean
  goToNotifications?: boolean
}

const NotificationFollow = ({
  notification,
  onNotificationClick,
  hideButton,
  goToNotifications,
}: Props) => {
  return (
    <View
      notification={notification}
      onNotificationClick={onNotificationClick}
      hideButton={hideButton}
      goToNotifications={goToNotifications}
    />
  )
}

export default NotificationFollow
