import React, { useState } from "react"

import FeaturedIcon from "../../images/new-homepage/icons/nominate-active.svg"
import CloseDialog from "../../images/new-homepage/icons/close-dialog.svg"

import {
  DialogArea,
  DialogBody,
  DialogBox,
  DialogHeader,
  DialogTitle,
  DialogLine1,
  DialogLine2,
  DialogButtonBox,
  CloseDialogButton,
} from "./styles"
import { Profile } from "../../redux/profile"
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import { Button } from "../Button"

export type Props = {
  onClose: () => void
  handleNominate: (hide?: boolean) => void
  dialogIsOpen: boolean
  profile?: Profile
}

const Dialog = ({ dialogIsOpen, onClose, handleNominate, profile }: Props) => {
  const [hide, setHide] = useState(false)

  const Nominate = () => {
    handleNominate(hide)
  }

  const lastNominationDate = new Date(profile?.lastNominationDate as any)

  const now =
    new Date().getDate() + new Date().getMonth() + new Date().getFullYear()
  const lastNomination = profile?.lastNominationDate
    ? lastNominationDate.getDate() +
      lastNominationDate.getMonth() +
      lastNominationDate.getFullYear()
    : 0

  return (
    <DialogArea maxWidth={"md"} open={dialogIsOpen} onClose={onClose}>
      <DialogBox>
        <CloseDialogButton src={CloseDialog} onClick={onClose} />
        <DialogHeader>
          <img src={FeaturedIcon} alt={"Nominate Project"} />
          <DialogTitle>Nominate Project</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {now === lastNomination ? (
            <>
              <DialogLine1>
                Nominate this project to be the featured project of the day on
                Spectacular's home page. You can only nominate one project per
                day- and you've already nominated a project.
              </DialogLine1>
              <DialogLine2>
                This nomination will replace your previous nomination
              </DialogLine2>
            </>
          ) : (
            <>
              <DialogLine1>
                Nominate this project to be the featured project of the day on
                Spectacular's home page.
              </DialogLine1>
              <DialogLine2>
                You can only nominate one project per day- so make it a great
                one!
              </DialogLine2>
            </>
          )}
        </DialogBody>
        {now === lastNomination ? (
          <DialogButtonBox>
            <Button
              onClick={() => onClose()}
              children={"Cancel"}
              color={"whiteBlueBorder"}
              size={"medium"}
              style={{
                width: "119px",
                height: "40px",
                fontSize: "16px",
              }}
            />
            <Button
              onClick={() => Nominate()}
              children={"Continue"}
              color={"secondary"}
              size={"medium"}
              style={{
                width: "119px",
                height: "40px",
                fontSize: "16px",
              }}
            />
          </DialogButtonBox>
        ) : (
          <DialogButtonBox>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setHide(e.target.checked)} />
                }
                label="Don't show me again"
              />
            </FormGroup>
            <Button
              onClick={() => Nominate()}
              children={"Nominate"}
              color={"secondary"}
              size={"medium"}
              style={{
                width: "119px",
                height: "40px",
                fontSize: "16px",
              }}
            />
          </DialogButtonBox>
        )}
      </DialogBox>
    </DialogArea>
  )
}

export default Dialog
