import styled from "styled-components"
import { Button } from "@material-ui/core"
import theme from "../../theme"

export const ActionButton = styled(Button)`
  && {
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
  }
`
