import React from "react"
import * as Yup from "yup"
import { Formik, FormikProps } from "formik"
import { Link } from "react-router-dom"
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  TextField as MUITextField,
} from "@material-ui/core"
import { Image } from "../../redux/projects"
import { Profile } from "../../redux/profile"
import TextField from "../TextField"
import ImageUploader from "../ImageUploader"
import { FormStyled, EmailLink } from "./styles"
import SelectField from "../../components/SelectField"
import ProfileJobTitle from "../../components/ProfileJobTitle"

import IconButton from "@material-ui/core/IconButton"
import iconLinkedin from "../../images/icons/social/linkedin.svg"
import iconX from "../../images/icons/social/x.svg"
import iconFacebook from "../../images/icons/social/facebook.svg"
import iconInstagram from "../../images/icons/social/instagram.svg"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"
import { WebsiteIcon } from "../../components/Icons"
import { phoneValidation } from "../../utils/phoneValidation"

const MAX_CHAR_COUNT = 220

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}
const autocompleteService = { current: null }
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

interface PlaceType {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

export type Props = {
  profile: Profile
  disableSubmit: boolean
  usernameCheck: string
  usernameValue: string
  onCheckUsernameAvailability: (
    _event: React.ChangeEvent<{ value: any }>
  ) => void
  onSubmit: Function
  onCancel: Function
  adminEditing?: boolean
}

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required").typeError("Required"),
  lastName: Yup.string().required("Required").typeError("Required"),
  location: Yup.string().required("Required").typeError("Required"),
  username: Yup.string().required("Required").typeError("Required"),
  aboutSelf: Yup.string()
    .max(300, "Too long!")
    .typeError("Required")
    .required("Required"),
  jobTitle: Yup.string().required("Required").typeError("Required"),
  company: Yup.string().required("Required").typeError("Required"),
})

const AdminProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required").typeError("Required"),
  lastName: Yup.string().required("Required").typeError("Required"),
  username: Yup.string().required("Required").typeError("Required"),
})

const View = ({
  profile,
  disableSubmit,
  usernameCheck,
  usernameValue,
  onCheckUsernameAvailability,
  onSubmit,
  onCancel,
  adminEditing,
}: Props) => {
  const creatingProfile =
    profile.location === null || profile.aboutSelf === null

  const handleAddImage = (
    images: Array<Image>,
    formik: FormikProps<Profile>
  ) => {
    const image = images[0]
    formik.setFieldValue("file", image.file)
    formik.setFieldValue("smallImageUrl", image.url)
  }

  const handleRemoveImage = (formik: FormikProps<Profile>) => {
    formik.setFieldValue("file", null)
    formik.setFieldValue("smallImageUrl", null)
  }

  const buildImages = (values: Profile) => {
    return values.smallImageUrl ? [{ order: 0, url: values.smallImageUrl }] : []
  }
  let defaultLocation: PlaceType = {
    description: profile?.location,
    structured_formatting: {
      main_text: "main text",
      secondary_text: profile?.location,
      main_text_matched_substrings: [
        {
          offset: 1,
          length: 1,
        },
      ],
    },
  }

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
  }

  const validatePhone = (e: any) => {
    e.target.value = phoneValidation(e.target.value)
  }

  const classes = useStyles()
  const [value, setValue] = React.useState<PlaceType | null>(defaultLocation)
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<PlaceType[]>([])
  const loaded = React.useRef(false)
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB882GbnKnvMUITZPoLHSzr8No39YHUymE&libraries=places",
        document.querySelector("head"),
        "google-maps"
      )
    }

    loaded.current = true
  }
  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )
  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[]

        if (value) newOptions = [value]

        if (results) newOptions = [...newOptions, ...results]

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const emailBody = () =>
    `I would like to unsubscribe from the Spectacular Newsletter.`
  const mailTo = () =>
    `mailto:?subject=Unsubscribe from Spectacular Newsletter&body=${encodeURIComponent(
      emailBody()
    )}`

  return (
    <Formik
      initialValues={profile}
      onSubmit={async (values: Profile) => onSubmit(values)}
      validationSchema={adminEditing ? AdminProfileSchema : ProfileSchema}
      enableReinitialize={true}
    >
      {(formik) => (
        <FormStyled>
          <Grid container>
            <Grid item xs={12}>
              <Typography tabIndex={0} variant="h3">
                {creatingProfile ? "Complete profile" : "Edit profile"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography tabIndex={0} variant="h5">
                Personal information
              </Typography>
              <TextField
                name="firstName"
                label="First name *"
                data-testid="firstName"
              />
              <TextField name="lastName" label="Last name *" />
              <MUITextField
                name="username"
                label="Username *"
                value={usernameValue}
                onChange={(text) => {
                  formik.setFieldValue("username", usernameValue)
                  onCheckUsernameAvailability(text)
                }}
                error={usernameCheck !== ""}
                helperText={usernameCheck || ""}
                autoComplete="off"
                inputProps={{ "data-testid": "username" }}
                fullWidth
              />
              <Grid container>
                <Grid item xs={6}>
                  <Box marginRight={1}>
                    <ProfileJobTitle
                      error={formik.errors.jobTitle}
                      jobTitle={formik.values.jobTitle}
                      onChange={(jobTitle) =>
                        formik.setFieldValue("jobTitle", jobTitle)
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginLeft={1}>
                    <TextField
                      name="company"
                      label="Company *"
                      error={
                        formik.errors.company !== undefined &&
                        formik.touched.company
                      }
                      helperText={formik.errors.company}
                    />
                  </Box>
                </Grid>
              </Grid>
              <TextField
                name="phoneNumber"
                label="Phone number"
                onInput={validatePhone}
                maxLength={20}
              />
              <Autocomplete
                id="google-map-demo"
                freeSolo={true}
                popupIcon={""}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                onBlur={() => formik.setFieldTouched("location")}
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(_: any, newValue: any) => {
                  setOptions(newValue ? [newValue, ...options] : options)
                  setValue(newValue)
                  formik.setFieldValue("location", newValue?.description)
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    fullWidth
                    name="location"
                    data-testid="profile-location-field"
                    label="City, state, zip code, or address *"
                    helperText={formik.errors.location}
                    error={formik.errors.location !== undefined}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      formik.setFieldValue("location", e.target.value)
                    }}
                  />
                )}
                renderOption={(option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  )

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                        <Typography variant="body2" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }}
              />
              <TextField
                name="aboutSelf"
                label={"Tell us about yourself*"}
                multiline={true}
                rows={4}
                rowsMax={24}
                onChange={formik.handleChange}
                onInput={characterCounter}
              />
              <Typography variant="caption" style={{ float: "right" }}>
                {formik.values.aboutSelf?.length}/{MAX_CHAR_COUNT} characters
              </Typography>
              {adminEditing && (
                <Box marginY={2}>
                  <SelectField
                    name="role"
                    label="Role"
                    options={[
                      { name: "(no role)", value: "user" },
                      { name: "Admin", value: "admin" },
                    ]}
                    blank="Select role"
                    data-testid="role"
                    margin="none"
                  />
                </Box>
              )}
              <Typography tabIndex={0} variant="h6">
                Profile image
              </Typography>
              <ImageUploader
                images={buildImages(formik.values)}
                onRemoveImage={() => handleRemoveImage(formik)}
                onAddImages={(images: Array<Image>) =>
                  handleAddImage(images, formik)
                }
                title="Upload image"
              />
              <Box marginBottom={2} marginTop={2}>
                <Typography tabIndex={0} variant="caption">
                  Image resolution minimum is 100x100. Accepted file types
                  include .jpeg, .jpg, and .png. Images larger than 10MB will be
                  compressed.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box marginLeft={{ xs: 0, sm: 2 }} marginTop={{ xs: 3, sm: 0 }}>
                <Typography tabIndex={0} variant="h5">
                  Profile links
                </Typography>
                <TextField
                  name="linkedinUrl"
                  label="LinkedIn"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <img
                          src={iconLinkedin}
                          height={25}
                          width={25}
                          alt="LinkedIn"
                        />
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  name="twitterUrl"
                  label="X"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <img src={iconX} height={25} width={25} alt="X" />
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  name="facebookUrl"
                  label="Facebook"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <img
                          src={iconFacebook}
                          height={25}
                          width={25}
                          alt="Facebook"
                        />
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  name="instagramUrl"
                  label="Instagram"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <img
                          src={iconInstagram}
                          height={25}
                          width={25}
                          alt="Instagram"
                        />
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  name="personalCompanyUrl"
                  label="Personal Website"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <WebsiteIcon width={25} height={25} fill="#858585" />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <Box marginLeft={{ xs: 0, sm: 2 }} paddingTop={3}>
                <Typography tabIndex={0} variant="h5">
                  Notification Management
                </Typography>

                <Box marginTop={1}>
                  <FormControlLabel
                    label="Receive Notifications by Email"
                    control={
                      <Checkbox
                        name="allowEmailNotifications"
                        onChange={({ target }) =>
                          formik.setFieldValue(
                            "allowEmailNotifications",
                            target.checked
                          )
                        }
                        checked={formik.values.allowEmailNotifications}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box marginLeft={{ xs: 0, sm: 2 }} paddingTop={3}>
                <Typography tabIndex={0} variant="h5">
                  Newsletter Subscriptions
                </Typography>

                <Box marginTop={1}>
                  <FormControlLabel
                    label="Daily Spectacular"
                    control={
                      <Checkbox
                        name="dailyNewsletter"
                        onChange={({ target }) =>
                          formik.setFieldValue(
                            "dailyNewsletter",
                            target.checked
                          )
                        }
                        checked={formik.values.dailyNewsletter}
                        disabled={
                          formik.values.bothNewsletter ||
                          formik.values.noNewsletter
                        }
                      />
                    }
                  />
                </Box>

                <Box>
                  <FormControlLabel
                    label="Weekly Spectacular"
                    control={
                      <Checkbox
                        name="weeklyNewsletter"
                        onChange={({ target }) =>
                          formik.setFieldValue(
                            "weeklyNewsletter",
                            target.checked
                          )
                        }
                        checked={formik.values.weeklyNewsletter}
                        disabled={
                          formik.values.bothNewsletter ||
                          formik.values.noNewsletter
                        }
                      />
                    }
                  />
                </Box>

                <Box marginTop={2}>
                  <Typography>
                    <span style={{ fontWeight: 600 }}>Daily Spectacular </span>
                    is sent every day and contains all the latest stories from
                    Spectacular.
                  </Typography>
                </Box>

                <Box marginTop={2}>
                  <Typography>
                    <span style={{ fontWeight: 600 }}>Weekly Spectacular </span>{" "}
                    is a curated newsletter that is sent every Thursday,
                    containing highlights from Spectacular. Weekly Spectacular
                    subscribers will also receive occasional updates about
                    events, competitions and breaking news.
                  </Typography>
                </Box>

                <Box marginTop={3}>
                  <Typography style={{ marginBottom: "1em" }}>
                    We will only use your email address to send you the
                    newsletters you have requested. We will never give your
                    details to anyone else without your consent. You can
                    unsubscribe at any time by clicking on the unsubscribe link
                    at the bottom of every email, or by emailing us at{" "}
                    <EmailLink
                      onClick={(e) => {
                        window.location = mailTo() as any
                        e.preventDefault()
                      }}
                    >
                      hello@spectacular.design
                    </EmailLink>
                    {". "}
                  </Typography>
                  <Typography>
                    For more details, please see our{" "}
                    <Link to="/privacy_policy">privacy notice</Link>.
                  </Typography>
                </Box>
              </Box>

              <Box marginLeft={{ xs: 0, sm: 2 }} paddingTop={3}>
                <Box marginTop={3}>
                  <Typography>
                    Permanently <Link to="/delete_account">delete</Link> your
                    Spectacular account.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginTop={3} width="100%" display="flex">
                <Box marginRight={3}>
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting || disableSubmit}
                    type="submit"
                  >
                    {creatingProfile ? "Create profile" : "Update profile"}
                  </Button>
                </Box>
                <Button onClick={() => onCancel()}>Cancel</Button>
              </Box>
            </Grid>
          </Grid>
        </FormStyled>
      )}
    </Formik>
  )
}

export default View
