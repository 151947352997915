import React from "react"
import {
  TableContainer,
  Table,
  Typography,
  Box,
  Container,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
} from "@material-ui/core"
import { Project } from "../../../redux/adminProjects"
import LoadingPage from "../../LoadingPage"
import AdminProjectFeaturedRow, {
  EditableProjectFeatured,
} from "../../../components/AdminProjectFeaturedRow"
import ProjectEditForm from "../../../components/AdminProjectEditForm"
import AdminProjectMainFeatureRow, {
  EditableProjectMainFeature,
} from "../../../components/AdminProjectMainFeatureRow"
import AdminProjectRewardRow, {
  EditableProjectReward,
} from "../../../components/AdminProjectRewardRow"
export type Props = {
  project?: Project | null
  featuredList: EditableProjectFeatured[]
  onAddNewFeatured: () => void
  onSaveFeatured: (featured: EditableProjectFeatured) => void
  onDeleteFeatured: (featured: EditableProjectFeatured) => void
  onEditProject: (project: Project) => Promise<void>
  mainFeatureList: EditableProjectMainFeature[]
  onAddNewMainFeature: () => void
  onSaveMainFeature: (mainFeature: EditableProjectMainFeature) => void
  onAddNewReward: () => void
  onDeleteMainFeature: (mainFeature: EditableProjectMainFeature) => void
  onSaveReward: (mainFeature: EditableProjectReward) => void
  onDeleteReward: (mainFeature: EditableProjectReward) => void
  rewardList: EditableProjectReward[]
}

const View = ({
  project,
  featuredList,
  onAddNewFeatured,
  onSaveFeatured,
  onDeleteFeatured,
  onEditProject,
  mainFeatureList,
  onAddNewMainFeature,
  onSaveMainFeature,
  onDeleteMainFeature,
  onAddNewReward,
  onSaveReward,
  onDeleteReward,
  rewardList,
}: Props) => {
  if (!project) {
    return <LoadingPage />
  }

  return (
    <Container>
      <Box marginBottom={2}>
        <Typography variant="h2">Edit {project.name}</Typography>
      </Box>
      <Box marginBottom={2}>
        <ProjectEditForm project={project} onSubmit={onEditProject} />
      </Box>
      <Box marginBottom={1}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h5">Featured List</Typography>
          </Grid>
          <Grid item xs container justify="flex-end">
            <Button color="primary" size="small" onClick={onAddNewFeatured}>
              + Add Featured
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featuredList.map((featured) => (
                <AdminProjectFeaturedRow
                  key={`${featured.id}${featured.startDate}${featured.endDate}`}
                  projectFeatured={featured}
                  onSave={onSaveFeatured}
                  onDelete={onDeleteFeatured}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box marginTop={5} marginBottom={1}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h5">Set as Hero on Homepage</Typography>
          </Grid>
          <Grid item xs container justify="flex-end">
            <Button color="primary" size="small" onClick={onAddNewMainFeature}>
              + Schedule for Hero
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Hero Title</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mainFeatureList.map((mainFeature) => (
                <AdminProjectMainFeatureRow
                  key={`${mainFeature.id}${mainFeature.startDate}${mainFeature.endDate}`}
                  projectMainFeature={mainFeature}
                  onSave={onSaveMainFeature}
                  onDelete={onDeleteMainFeature}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box marginTop={5} marginBottom={1}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h5">Rewards</Typography>
          </Grid>
          <Grid item xs container justify="flex-end">
            <Button color="primary" size="small" onClick={onAddNewReward}>
              + Add reward
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Campaign</TableCell>
                <TableCell>Prize</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardList.map((reward) => (
                <AdminProjectRewardRow
                  key={reward.id}
                  projectReward={reward}
                  onSave={onSaveReward}
                  onDelete={onDeleteReward}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  )
}

export default View
