import theme, { colors } from "../../theme"
import styled from "styled-components"
import { Grid, Box, Button } from "@material-ui/core"

export const EducationGrid = styled(Grid)`
  &.has-job-experience {
    ${theme.breakpoints.up("md")} {
      border-left: 1px solid ${colors.mediumGray};
      padding-left: ${theme.spacing(3)}px;
    }
  }
`

export const CredentialListBox = styled(Box)`
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  li {
    display: list-item;
    padding-left: 0;
    color: ${colors.dark};
  }
`

export const JobDescriptionBox = styled(Box)`
  font-family: "Public-Sans";
  font-size: 1em;
  line-height: 1.375;
  font-weight: 400;
  color: ${colors.dark};
`

export const PlusIcon = styled.img`
  height: 32px;
  width: 32px;
`

export const ActionButton = styled(Button)`
  height: 48px;
  font-family: Public-Sans-Bold;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 1px;
`
