import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #fff !important;
  font-family: Public-Sans !important;
  content: "\a";
  white-space: pre;

  &:active {
    user-select: none;
  }

  :hover {
    color: #fff;
    cursor: pointer;
  }
`
export const StyledLinkTag = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #3057e1 !important;
  font-family: Public-Sans !important;
  content: "\a";
  white-space: pre;

  &:active {
    user-select: none;
  }

  :hover {
    color: #3057e1;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const StyledLinkBox = styled.span`
  background: #2d2d2d;
  padding: 0 8px;
  border-radius: 20px;
  box-sizing: border-box;

  :hover {
    background: #000;
    cursor: pointer;
  }
`
export const StyledLinkBoxSmall = styled.span`
  color: #fff;

  :hover {
    cursor: pointer;
  }
`

export const StyledLinkSmall = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: #fff !important;
  content: "\a";
  white-space: pre;

  &:active {
    user-select: none;
  }

  :hover {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
  }
`
