import { Form } from "formik"
import styled from "styled-components"

export const FormStyled = styled(Form)`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`
export const EmailLink = styled.div`
  cursor: pointer;
  color: #717171;
  background: none !important;
  display: inline;
  :hover {
    color: #2d2d2d;
  }
`
