import styled from "styled-components"
import { Button, Box, Grid } from "@material-ui/core"

import theme from "../../../../theme"

export const LocationSpan = styled.span`
  cursor: pointer;

  :hover {
    color: #5d5d5d;
  }
`

export const AdButton = styled(Button)`
  height: 48px;
  max-width: 140px;
  padding: 8px 22px;
  border-radius: 1px;
  font-family: "Public-Sans";
  font-weight: 400 !important;

  && {
    font-weight: 400 !important;

    MuiButton-label {
      font-weight: 400 !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    max-height: 32px;
    max-width: 121px;
  }
`

export const CounterText = styled(Box)`
  font-size: 16px;
  color: #979797;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const CounterArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 340px) {
    flex-flow: column;
    align-items: flex-start;
  }
`

export const CounterFilterArea = styled(Box)`
  display: flex;
  align-items: center;

  @media (max-width: 340px) {
    margin-top: 20px;
  }
`

export const CounterAreaGroup = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    flex-flow: column;
    align-items: flex-start;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: wrap;
    align-items: center;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    align-items: flex-start;
  }
`

export const CounterFilterAreaGroup = styled(Box)`
  display: flex;
  align-items: center;

  @media (max-width: 1080px) {
    margin-top: 20px;
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 0;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`

export const JobMainGrid = styled(Grid)`
  padding-bottom: 96px;
  flex-wrap: initial;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 16px;
    flex-wrap: wrap;
  }

  ${theme.breakpoints.down("xs")} {
    padding-bottom: 60px;
  }
`

export const JobAdArea = styled(Grid)`
  padding-top: 32px;
  min-width: 368px;
  max-width: 368px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 24px;
    min-width: 100%;
    max-width: 100%;
  }
`
