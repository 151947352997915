import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import theme from "../../theme"

export const CardBox = styled(Box)`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    gap: 8px;
  }
`

export const Avatar = styled.img`
  width: 70px;
  height: 70px;
  display: flex;
  object-fit: cover;

  ${theme.breakpoints.down("sm")} {
    width: 40.97px;
    height: 40.97px;
  }
`

export const ProfileTitle = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 16px;
  line-height: 22px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`

export const ProfileSubTitle = styled(Typography)`
  font-family: Public-Sans;
  font-size: 16px;
  line-height: 22px;

  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`

export const LocationText = styled(Typography)`
  font-family: Public-Sans;
  font-size: 14px;
  line-height: 22px;
  color: #717171;
  text-decoration: underline;
  text-underline-offset: 2px;
`

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${theme.breakpoints.down("sm")} {
    && button {
      height: 30px !important;
      width: auto !important;
      font-size: 12px !important;
    }

    && img {
      width: 14px !important;
      height: 16px !important;
    }
  }
`

export const ButtonIcon = styled.img`
  margin-right: 10px;
`
