import React from "react"
import { useAppDispatch } from "../../redux/configureStore"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Collaborator,
  confirmCollaborator,
  rejectCollaborator,
} from "../../redux/projects"

type Props = {
  collaborator: Collaborator
  remove?: boolean
}

const CollaboratorDotMenu = ({ collaborator, remove }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleRemove = async () => {
    try {
      const { type } = await dispatch(
        rejectCollaborator({ auth, id: collaborator.id! })
      )

      if (rejectCollaborator.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleConfirm = async () => {
    try {
      const { type } = await dispatch(
        confirmCollaborator({ auth, id: collaborator.id! })
      )

      if (confirmCollaborator.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <View onRemove={handleRemove} onConfirm={handleConfirm} remove={remove} />
  )
}

export default CollaboratorDotMenu
