import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { createIndividualProfile } from "../../redux/adminProfiles"
import { IndividualProfileSignUp } from "../../redux/profile"
import View from "./View"
import { RootState } from "../../redux/rootReducer"
import { useAuth0 } from "@auth0/auth0-react"

const AdminIndividualProfileForm = () => {
  const history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const error = useSelector((state: RootState) => state.adminProfiles.error)

  const initialValues: IndividualProfileSignUp = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termAcceptance: false,
  }

  const handleSubmit = async (profile: IndividualProfileSignUp) => {
    try {
      const resultAction = await dispatch(
        createIndividualProfile({ auth, profile })
      )
      if (createIndividualProfile.fulfilled.type === resultAction.type) {
        history.push("/admin/profiles")
      }
    } catch (e) {
      console.error(e)
    }
  }

  return <View values={initialValues} onSubmit={handleSubmit} error={error} />
}

export default AdminIndividualProfileForm
