import React, { useState } from "react"
import { WizardContainer } from "./styles"

import Payment from "./Payment"
import Plan from "./Plan"
import { JobListing } from "../../redux/jobListings"
import { useHistory } from "react-router"
import { PROFILE_CONTAINER } from "../../constants/routes"
import { Product } from "../../redux/product"

export enum WizardSteps {
  PLAN = "plan",
  PAYMENT = "payment",
}

const getWizardSteps = (operation: string): WizardSteps[] => {
  switch (operation) {
    case "edit":
    case "existing_job":
      return [WizardSteps.PAYMENT]
    case "start":
      return [WizardSteps.PLAN, WizardSteps.PAYMENT]
    default:
      return []
  }
}

type Props = {
  job: JobListing
  products: Product[]
  operation: string
}
const Wizard = ({ job, products, operation }: Props) => {
  const history = useHistory()

  const wizardSteps = getWizardSteps(operation)
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [currentStep, setCurrentStep] = useState<number>(0)

  const goToStep = (step: WizardSteps) => {
    setCurrentStep(wizardSteps.indexOf(step))
    window.scroll(0, 0)
  }

  const handleBackButton = () => {
    const prevStepIndex = currentStep - 1
    if (prevStepIndex >= 0) {
      return goToStep(wizardSteps[prevStepIndex])
    }

    switch (operation) {
      case "edit":
        return history.push(
          `${PROFILE_CONTAINER}/${job.profile?.username}/job_listings/${job.id}`
        )
      default:
        return history.goBack()
    }
  }

  const renderWizardStep = () => {
    const currentWizardStep = wizardSteps[currentStep]
    switch (currentWizardStep) {
      case WizardSteps.PLAN:
        return (
          <Plan
            job={job}
            goToStep={goToStep}
            onSelectProduct={setSelectedProduct}
            products={products}
          />
        )
      case WizardSteps.PAYMENT:
        return (
          <Payment
            job={job}
            handleBack={handleBackButton}
            product={selectedProduct}
          />
        )
      default:
        return null
    }
  }

  return <WizardContainer>{renderWizardStep()}</WizardContainer>
}

export default Wizard
