import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const CardBox = styled(Box)`
  flex: 40%;
  background: #ffffff;
  border: 0.5px solid #ebeffc;
  border-radius: 5px;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
    border: 0;
    border-radius: 0;
  }
`

export const CardHeader = styled(Box)`
  padding: 16px 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;

  && img {
    border-radius: 50%;
    display: flex;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 8px 12px;
  }
`

export const StyledAvatar = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`

export const HeaderBox = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const HeaderDate = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -3px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #2d2d2d;
`

export const ProfileTitle = styled(Typography)`
  font-size: 16px;
  font-family: PUblic-Sans-Medium;

  &:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const CardContentBigText = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  padding: 20px 20px 24px;
  border-bottom: 1px solid #c4c4c4;
  overflow: hidden;
  white-space: pre-line;

  display: flex;
  flex-flow: column;
  justify-content: center;

  ${theme.breakpoints.down("xs")} {
    padding: 20px 12px 24px;
  }
`
export const CardTitle = styled(Box)`
  font-size: 24px;
  line-height: 28px;
  white-space: pre-line;
`

export const CardHint = styled(Box)`
  font-size: 14px;
  padding: 16px 0;
`

export const CardBigText = styled(Box)`
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
  padding-bottom: 8px;
`

export const CardFooterTitle = styled(Box)`
  font-size: 18px;
  line-height: 24px;
  white-space: pre-line;
`

export const CardFooterHint = styled(Box)`
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`

export const CardBigTextBelow = styled(Box)`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
`

export const PostCardContentImage = styled(Box)`
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  position: relative;
  background-color: #f7f7f7;
`

export const CardFooter = styled(Box)`
  padding: 16px 24px 24px;
  position: relative;

  ${theme.breakpoints.down("xs")} {
    padding: 0px 12px 20px;
  }
`

export const ImageThumbnail = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  height: auto;
  object-fit: contain;
  display: flex;
`

export const CardActions = styled(Box)`
  padding: 12px 0;

  ${theme.breakpoints.down("xs")} {
    padding: 12px;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 0 12px;
    display: none;
  }
`

export const DividerBorder = styled(Box)`
  width: 100%;
  border-bottom: 0.5px solid #d8d8d8;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const LinkBox = styled.a`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const SeeMoreButton = styled.span`
  cursor: pointer;
  color: #3057e1;
  font-family: Public-Sans-Bold;
  font-size: 14px;
`
