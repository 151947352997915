import React from "react"
import { Tabs } from "@material-ui/core"

import { TabItem, TabLabel } from "../styles"

import AboutDetails from "../AboutDetails"
import { Profile } from "../../../redux/profile"
import { ProjectsDetails } from "../ProjectsDetails"
import ExperienceDetailPage from "../../ExperienceDetailPage"
import * as RouteConstants from "../../../constants/routes"
import { PostsDetails } from "../PostsDetails"
interface Props {
  profile: Profile
  authenticatedProfile: Profile
  currentTab: string
  isPublicProfile: boolean
  isGroupProfile: boolean
  isConnected: boolean
  isOfflineUser: boolean
  onAddProject: () => void
  onTabClicked: (url: string) => void
  goToUrl: (url: string) => void
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  onEditAbout: (plainText: string, richText: string) => Promise<void>
}

const IndividualTabView = ({
  profile,
  authenticatedProfile,
  currentTab,
  onEditAbout,
  onAddProject,
  onTabChanged,
  onTabClicked,
  goToUrl,
  isPublicProfile,
  isGroupProfile,
  isConnected,
  isOfflineUser,
}: Props) => {
  const projects = profile.projects ?? []
  let projectCount = projects.length

  if (isPublicProfile)
    projectCount = projects.filter(
      (project) => project.published === true
    ).length

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={onTabChanged}
        indicatorColor="primary"
        style={{ borderBottom: "0.5px solid #D8D8D8", padding: 0 }}
        TabIndicatorProps={{ style: { height: "1px" } }}
      >
        <TabItem
          label={
            <TabLabel variant="h6" display="inline">
              Projects ({projectCount})
            </TabLabel>
          }
          value="projects"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}`
          )}
        />
        <TabItem
          label={
            <TabLabel tabIndex={0} variant="h6" display="inline">
              Experience
            </TabLabel>
          }
          value="experience"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/experience`
          )}
        />
        <TabItem
          label={
            <TabLabel tabIndex={0} variant="h6" display="inline">
              About
            </TabLabel>
          }
          value="about"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/about`
          )}
        />
        <TabItem
          label={
            <TabLabel tabIndex={0} variant="h6" display="inline">
              Posts ({profile.postsQuantity})
            </TabLabel>
          }
          value="posts"
          onClick={onTabClicked.bind(
            this,
            `${RouteConstants.PROFILE_CONTAINER}/${profile.username}/posts`
          )}
        />
      </Tabs>
      {currentTab === "projects" && (
        <ProjectsDetails
          projects={projects}
          onAddProject={onAddProject}
          goToUrl={goToUrl}
          isPublicProfile={isPublicProfile}
        />
      )}
      {currentTab === "experience" && (
        <ExperienceDetailPage
          authenticatedProfile={authenticatedProfile}
          isPublicProfile={isPublicProfile}
          profile={profile}
        />
      )}
      {currentTab === "about" && (
        <AboutDetails
          profile={profile}
          authenticatedProfile={authenticatedProfile}
          onEditAbout={onEditAbout}
          isPublicProfile={isPublicProfile}
          isConnected={isConnected}
          isGroupProfile={isGroupProfile}
          isOfflineUser={isOfflineUser}
        />
      )}
      {currentTab === "posts" && (
        <PostsDetails
          isPublicProfile={isPublicProfile}
          profile={profile}
          authenticatedProfile={authenticatedProfile}
        />
      )}
    </>
  )
}

export default IndividualTabView
