import React from "react"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import View from "./View"

interface Props {
  origin: "profile" | "homepage" | "projects" | "update-nav" | "image-nav"
}

const PostButton = ({ origin }: Props) => {
  const profile = useSelector((state: AppState) => state.profile.profile)

  return <View profile={profile!} origin={origin} />
}

export default PostButton
