import React from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import moment from "moment"
import { AddLink, ExperienceBox, EditLink } from "./styles"
import { JobPosition } from "../../redux/jobPositions"
import { Education } from "../../redux/educations"
import ProfileCredentials from "../../components/ProfileCredentials"
import ProfileGroups from "../../components/ProfileGroups"
import ProfileSkills from "../../components/ProfileSkills"
import ProfileAwards from "../../components/ProfileAwards"

export interface Props {
  awards: string[]
  groups: string[]
  jobPositions: JobPosition[]
  educations: Education[]
  skills: string[]
  onNewSkillAdded: (skills: string[]) => void
  onNewJobClicked: () => void
  onNewEducationClicked: () => void
  onCompletedClicked: () => void
  onAddGroups: (values: string[]) => void
  onAddAwards: (values: string[]) => void
}

const View = ({
  awards,
  groups,
  skills,
  educations,
  jobPositions,
  onAddAwards,
  onAddGroups,
  onNewSkillAdded,
  onNewJobClicked,
  onNewEducationClicked,
  onCompletedClicked,
}: Props) => {
  return (
    <Box paddingLeft={2} paddingRight={2}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Box marginBottom={3}>
                <Typography tabIndex={0} variant="h3">
                  Complete your experience
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={3}>
                <Typography tabIndex={0} variant="h5">
                  Experience
                </Typography>
              </Box>
              {jobPositions.map((job) => (
                <ExperienceBox
                  key={job.id}
                  marginBottom={2}
                  padding={2}
                  aria-label="job position details"
                >
                  <Typography tabIndex={0} variant="subtitle2">
                    <EditLink
                      to={`/job_positions/${job.id}/edit`}
                      aria-label="edit job position"
                    >
                      Edit
                    </EditLink>
                  </Typography>
                  <Box paddingRight={6.5}>
                    <Typography
                      tabIndex={0}
                      variant="subtitle2"
                      data-testid="jobTitle"
                    >
                      {job.title}
                    </Typography>
                  </Box>
                  <Typography tabIndex={0} variant="subtitle2">
                    {job.company}
                  </Typography>
                  <Typography tabIndex={0} variant="subtitle2">
                    {moment(job.startDate).format("MM/YYYY")} -{" "}
                    {job.current
                      ? "Present"
                      : moment(job.endDate).format("MM/YYYY")}
                  </Typography>
                  <Typography tabIndex={0} variant="subtitle2">
                    {job.description}
                  </Typography>
                </ExperienceBox>
              ))}
              <Box marginBottom={3}>
                <AddLink
                  onClick={onNewJobClicked}
                  aria-label="add job position"
                >
                  + Add job position
                </AddLink>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={3}>
                <Typography tabIndex={0} variant="h5">
                  Education
                </Typography>
              </Box>
              {educations.map((education) => (
                <ExperienceBox
                  key={education.id}
                  marginBottom={2}
                  padding={2}
                  aria-label="education details"
                >
                  <Typography tabIndex={0} variant="subtitle2">
                    <EditLink
                      to={`/educations/${education.id}/edit`}
                      aria-label="edit education"
                    >
                      Edit
                    </EditLink>
                  </Typography>
                  <Box paddingRight={6.5}>
                    <Typography
                      variant="subtitle2"
                      data-testid="educationSchool"
                    >
                      {education.school}
                    </Typography>
                  </Box>
                  <Typography tabIndex={0} variant="subtitle2">
                    {education.degree}
                  </Typography>
                  <Typography tabIndex={0} variant="subtitle2">
                    {education.field}
                  </Typography>
                  <Typography tabIndex={0} variant="subtitle2">
                    {education.startYear} -{" "}
                    {education.current ? "Present" : education.endYear}
                  </Typography>
                </ExperienceBox>
              ))}
              <AddLink
                onClick={onNewEducationClicked}
                aria-label="add education"
              >
                + Add education
              </AddLink>
            </Grid>
            <Grid item xs={12} aria-label="credentials list">
              <Box marginTop={4}>
                <Typography tabIndex={0} variant="h5">
                  Credentials
                </Typography>
              </Box>
              <ProfileCredentials />
            </Grid>
            <Grid item xs={12} aria-label="profile skills list">
              <Box marginTop={4}>
                <Typography tabIndex={0} variant="h5">
                  Skills
                </Typography>
              </Box>
              <ProfileSkills
                initialSkills={skills}
                onChange={onNewSkillAdded}
              />
            </Grid>
            <Grid item xs={12} aria-label="credentials list">
              <Box marginTop={4}>
                <Typography tabIndex={0} variant="h5">
                  Professional Affiliations
                </Typography>
              </Box>
              <ProfileGroups initialGroups={groups} onChange={onAddGroups} />
            </Grid>
            <Grid item xs={12} aria-label="credentials list">
              <Box marginTop={4}>
                <Typography tabIndex={0} variant="h5">
                  Awards
                </Typography>
              </Box>
              <ProfileAwards initialAwards={awards} onChange={onAddAwards} />
            </Grid>
            <Grid item xs={12}>
              <Box marginTop={4}>
                <Button color="primary" onClick={onCompletedClicked}>
                  Complete experience
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default View
