import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ProjectCard from "../../cards/ProjectCard"
import { useAppDispatch } from "../../redux/configureStore"
import {
  NoResultsBox,
  NoResultsShortcut,
  NoResultsShortcuts,
  NoResultsText,
  NoResultsTitle,
  PlaceholderProjectCard,
  ProjectsListBox,
  ProjectsListCardBox,
  ProjectsListContainer,
  ShowMoreBorder,
  ShowMoreBox,
  ShowMoreButton,
  ShowMoreLabel,
} from "./styles"
import { Feed, filterFeed } from "../../redux/feed"
import PostCard from "../../cards/PostCard"
import ShowMoreIcon from "../../images/new-homepage/icons/show-more.svg"
import LoadingPage from "../LoadingPage"
import SuggestionsCard from "../../cards/SuggestionsCard"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import InfiniteScroll from "react-infinite-scroll-component"
import NewsCard from "../../cards/NewsCard"

type Props = {
  search: string
  isHashtag: boolean
  onShortcutClick: (value: string) => void
}

const FilterFeed = ({ search, isHashtag, onShortcutClick }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const homeFeed = useSelector((state: any) => state.feed.searchFeed) as Feed[]
  const idHash = useSelector((state: any) => state.feed.searchHash) as string

  const checkHasMore = (payload: Feed[]) => {
    const checkPayload = payload.filter((item) => {
      return item.type !== "connections"
    })

    if (checkPayload.length === 0) return true

    return false
  }

  const loadFeed = useCallback(
    async (hash: string, pagination: boolean) => {
      try {
        setIsLoading(true)
        const { payload, type } = await dispatch(
          filterFeed({ auth, idHash: hash, pagination, search, isHashtag })
        )

        if (filterFeed.fulfilled.type === type) {
          if (checkHasMore(payload.feed)) setHasMore(false)
          setIsLoading(false)
          setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true
          }, 0)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [dispatch, auth, search]
  )

  useEffect(() => {
    loadFeed(idHash, false)
    // eslint-disable-next-line
  }, [loadFeed])

  const isEven = (number: number) => {
    return number % 2 === 0
  }

  return (
    <ProjectsListContainer>
      <ProjectsListBox>
        <InfiniteScroll
          dataLength={homeFeed.length}
          next={() => loadFeed(idHash, true)}
          hasMore={isAuthenticated ? hasMore : false}
          loader={<LoadingPage />}
          style={{ overflow: "hidden" }}
        >
          <ProjectsListCardBox>
            {homeFeed.map((feed) => {
              return (
                <>
                  {feed.type === "project" && (
                    <ProjectCard project={feed.project!} source="homepage" />
                  )}
                  {feed.type === "post" && (
                    <PostCard post={feed.post!} isHomepage={true} />
                  )}
                  {feed.type === "profile" && (
                    <SuggestionsCard
                      profiles={feed.connections!}
                      isFiltered={true}
                    />
                  )}
                  {feed.type === "news" && <NewsCard news={feed.news!} />}
                </>
              )
            })}
            {!isEven(homeFeed.length) && <PlaceholderProjectCard />}
          </ProjectsListCardBox>
        </InfiniteScroll>
        {homeFeed.length === 0 && !isLoading && (
          <NoResultsBox>
            <NoResultsTitle variant="h2">No results found.</NoResultsTitle>
            <NoResultsText>
              Sorry, we couldn’t find any results for this search.
              <br />
              Try shortening or rephrasing your search.
            </NoResultsText>
            <NoResultsShortcuts>
              <NoResultsShortcut onClick={() => onShortcutClick("Concrete")}>
                Concrete
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Photography")}>
                Photography
              </NoResultsShortcut>
              <NoResultsShortcut
                onClick={() => onShortcutClick("Interior Design")}
              >
                Interior Design
              </NoResultsShortcut>
              <NoResultsShortcut onClick={() => onShortcutClick("Chicago")}>
                Chicago
              </NoResultsShortcut>
            </NoResultsShortcuts>
          </NoResultsBox>
        )}

        {!isAuthenticated && (
          <ShowMoreBox>
            <ShowMoreBorder />
            <ShowMoreButton
              onClick={async () => {
                await dispatch(toggleDialog({ opened: true }))
              }}
            >
              <img src={ShowMoreIcon} alt="Show More" />
            </ShowMoreButton>
            <ShowMoreLabel
              onClick={async () => {
                await dispatch(toggleDialog({ opened: true }))
              }}
            >
              Show more
            </ShowMoreLabel>
          </ShowMoreBox>
        )}
      </ProjectsListBox>
    </ProjectsListContainer>
  )
}

export default FilterFeed
