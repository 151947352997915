import React from "react"
import { JobListing } from "../../redux/jobListings"
import {
  RequestDialog,
  RequestDialogTitle,
  RequestDialogBox,
  ButtonBox,
  DialogBody,
  LeftBox,
  RightBox,
} from "./Styles"
import { Button } from "../Button"
import { Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { normalizeProjectTitle } from "../../utils"

export type Props = {
  onClose: () => void
  open: boolean
  job: JobListing
}

const View = ({ open, onClose, job }: Props) => {
  const history = useHistory()

  const jobUrl = `/sp/${job.profile?.username}/job_listings/${
    job.id
  }/${normalizeProjectTitle(job.title)}`

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <RequestDialog maxWidth={"md"} open={open}>
      <RequestDialogBox>
        <RequestDialogTitle variant="h3">
          Job successfully promoted!
        </RequestDialogTitle>

        <DialogBody>
          <LeftBox>
            <img
              src={job.profile?.smallImageUrl}
              alt={job.profile?.groupName}
            />
          </LeftBox>
          <RightBox>
            <Typography>{job.title}</Typography>
            <span>
              {job.profile?.groupName} • {job.location}
            </span>
          </RightBox>
        </DialogBody>

        <ButtonBox>
          <Button
            color="whiteGreyBorder"
            onClick={() => goToUrl("/dashboard")}
            style={{
              height: "48px",
              width: "160px",
            }}
          >
            Back to dashboard
          </Button>
          <Button
            color="primary"
            onClick={() => goToUrl(jobUrl)}
            style={{
              height: "48px",
              width: "160px",
            }}
          >
            View on career site
          </Button>
        </ButtonBox>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
