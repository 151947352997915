import React, { useState } from "react"
import { CircularProgress } from "@material-ui/core"
import { Profile } from "../../redux/profile"

import {
  Avatar,
  ProjectThumbnail,
  Author,
  Title,
  Location,
  CardBox,
  ContentBox,
  NetworkingBox,
  LinkBox,
  ProjectThumbnailPlaceholder,
  AvatarBox,
  ProjectsBox,
  ThumbnailBox,
  DeleteButton,
} from "./styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import FollowButtonList from "../../components/FollowButtonList"
import { Button } from "../../components/Button"
import SimpleAddress from "../../components/SimpleAddress"
import ConnectButton from "../../components/ConnectButton"

import DeleteIcon from "../../images/x-default.svg"
import DeleteIconHover from "../../images/x-hover.svg"
import ConfirmDialog from "../../components/ConfirmDialog"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  profile: Profile
  goToProfile: () => string
  confirmButtons?: boolean
  isSuggestion?: boolean
  isLoading: boolean
  onConfirmConnection: () => void
  onDenyConnection: () => void
  handleDeleteSuggestion: () => void
  handleDeleteDialogOpen: (value: boolean) => void
  isDeleteDialogOpen: boolean
}

const View = ({
  profile,
  goToProfile,
  confirmButtons,
  isSuggestion,
  isLoading,
  onConfirmConnection,
  onDenyConnection,
  handleDeleteSuggestion,
  handleDeleteDialogOpen,
  isDeleteDialogOpen,
}: Props) => {
  const [hover, setHover] = useState(false)

  const name =
    profile.type === "group"
      ? profile.groupName
      : profile.firstName + " " + profile.lastName

  const alt =
    profile?.type === "individual"
      ? `Network for architects ${profile?.firstName} ${profile?.lastName}`
      : `Network for architects ${profile?.groupName}`

  let jobTitle = profile?.jobTitle
  if (profile?.jobTitle && profile.jobTitle.includes("Other: ")) {
    jobTitle = profile.jobTitle.replace("Other: ", "")
  }

  let location = SimpleAddress({
    profileType: profile!.type,
    location: profile!.location,
  })

  return (
    <CardBox>
      <ConfirmDialog
        title="Remove suggestion"
        message="Are you sure you want to remove this suggestion?"
        isOpen={isDeleteDialogOpen}
        onConfirm={handleDeleteSuggestion}
        onClose={() => handleDeleteDialogOpen(false)}
      />

      {isSuggestion && (
        <DeleteButton
          src={hover ? DeleteIconHover : DeleteIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"Delete"}
          onClick={() => handleDeleteDialogOpen(true)}
        />
      )}

      <ProjectsBox>
        <ThumbnailBox>
          {!profile.projects![0] ? (
            <ProjectThumbnailPlaceholder />
          ) : (
            <>
              {
                <ProjectThumbnail
                  src={profile.projects![0].smallImageUrl}
                  alt={`Network for architects ${profile.projects![0].name}`}
                />
              }
            </>
          )}
        </ThumbnailBox>
        <ThumbnailBox>
          {!profile.projects![1] ? (
            <ProjectThumbnailPlaceholder />
          ) : (
            <>
              {
                <ProjectThumbnail
                  src={profile.projects![1].smallImageUrl}
                  alt={`Network for architects ${profile.projects![1].name}`}
                />
              }
            </>
          )}
        </ThumbnailBox>
      </ProjectsBox>
      <AvatarBox>
        <LinkBox to={goToProfile}>
          <Avatar
            src={
              profile.smallImageUrl === undefined
                ? getRandomPlaceholder(profile.placeholderAvatar!)
                : (profile.smallImageUrl as string)
            }
            alt={alt}
          />
        </LinkBox>
      </AvatarBox>
      <ContentBox>
        <Author to={goToProfile}>
          {name}
          {profile.isAmbassador && (
            <img
              src={AmbassadorIcon}
              alt="Student Ambassador"
              className="StudentAmbassadorIcon"
            />
          )}
        </Author>
        <Title>
          {profile.type === "group" ? (
            <>
              {profile.companySize && <>Company Size: {profile.companySize}</>}
            </>
          ) : (
            <>
              {jobTitle} {profile?.company ? "at " + profile?.company : ""}
            </>
          )}
        </Title>
        <Location to={"/profiles?location=" + profile.location}>
          {location}
        </Location>
      </ContentBox>
      <NetworkingBox>
        {confirmButtons ? (
          <>
            <Button
              onClick={onDenyConnection}
              children={
                isLoading ? (
                  <CircularProgress size={16} style={{ color: "#2d2d2d" }} />
                ) : (
                  <>Ignore</>
                )
              }
              color={"lightBlue"}
            />
            <Button
              onClick={onConfirmConnection}
              children={
                isLoading ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  <>Accept</>
                )
              }
              color={"secondary"}
            />
          </>
        ) : (
          <>
            <FollowButtonList profile={profile} />
            <ConnectButton
              profile={profile}
              action={"connect"}
              isSuggestion={isSuggestion}
            />
          </>
        )}
      </NetworkingBox>
    </CardBox>
  )
}

export default View
