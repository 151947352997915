import styled from "styled-components"
import theme from "../../theme"
import { Box, Dialog, Typography } from "@material-ui/core"

export const FlagDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 5px !important;
  width: 100% !important;

  && .MuiDialog-paperWidthMd {
    max-width: 516px !important;
  }
`

export const FlagDialogTitle = styled(Box)`
  font-size: 20px;
  color: #2d2d2d;
  border-bottom: 0.5px solid #dbdbdb;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    padding: 0 20px;
  }
`

export const FlagDialogBox = styled(Box)`
  width: 516px;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: auto;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const ItensTitle = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Bold;
  color: #2d2d2d;
  padding: 0 24px;
`

export const ItemBox = styled(Box)<{ $isActive: boolean }>`
  padding: 8px 24px;
  width: 100%;
  box-sizing: border-box;
  color: #717171;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive
      ? `
		font-family: Public-Sans-Bold;
		background-color: #D8D8D8;
	`
      : null}

  &:hover {
    font-family: Public-Sans-Bold;
    background-color: #d8d8d8;
  }
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 24px;
`

export const TextBox = styled(Box)`
  padding: 0 24px;
`

export const StyledTextarea = styled.textarea`
  border: 1px solid #d8d8d8;
  background: #ffffff;
  color: #717171;
  font-family: Public-Sans-Bold;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;

  &:focus {
    border: 1px solid #d8d8d8;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &:focus-visible {
    border: 1px solid #d8d8d8;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`

export const Counter = styled(Box)`
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  color: #2d2d2d;
  font-size: 14px;
`

export const SuccessMessage = styled(Typography)`
  padding: 0 24px;
  color: #717171;
  font-size: 16px;
  line-height: 21px;
`
