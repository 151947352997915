import React from "react"

import { Image as ImageType } from "../../redux/projects"

import View from "./View"
import Loader from "./Loader"

interface IBreakpoint {
  width: number
  itemsToShow: number
  itemsToScroll: number
}

export const defaultBreakpoints = (expanded: boolean): IBreakpoint[] => [
  {
    width: 400,
    itemsToShow: expanded ? 1 : 3,
    itemsToScroll: expanded ? 1 : 3,
  },
  {
    width: 550,
    itemsToShow: expanded ? 1 : 3,
    itemsToScroll: expanded ? 1 : 3,
  },
  {
    width: 850,
    itemsToShow: expanded ? 1 : 6,
    itemsToScroll: expanded ? 1 : 6,
  },
]

export const getBreakpoint = (breakpoints: IBreakpoint[], width: number) => {
  const breakpoint = breakpoints.find((x) => width <= x.width + 450)

  if (!breakpoint) return breakpoints[breakpoints.length - 1]

  return breakpoint
}

export interface ImageGalleryProps {
  images: ImageType[]
  projectName?: string
}

type Props = ImageGalleryProps

const ImageGallery: React.FC<Props> = ({ images, projectName }) => {
  const [mounted, setMounted] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [state, setState] = React.useState<ImageType[]>([])
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selected, setSelected] = React.useState<ImageType>(images[0])

  React.useEffect(() => {
    ;(async () => {
      try {
        const newState = new Array<ImageType>()
        for (const image of images) {
          newState.push(image)
        }
        setMounted(true)
        setState(newState)
        setSelected(newState[0])
      } catch (error) {
        console.error(error)
      }
    })()
  }, [images])

  const handleSelect = (img: ImageType, idx: number) => {
    setSelected(img)
    setSelectedIndex(idx)
  }

  const toggleExpand = () => setExpanded(!expanded)

  if (!mounted) return <Loader />

  if (!selected || images.length === 0)
    return <div style={{ textAlign: "center" }} /> // we need a null state

  return (
    <View
      images={state}
      projectName={projectName}
      expanded={expanded}
      selected={selected}
      onSelect={handleSelect}
      selectedIndex={selectedIndex}
      toggleExpanded={toggleExpand}
    />
  )
}

export default ImageGallery
