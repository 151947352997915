import React from "react"
import styled from "styled-components"
import { Box, Grid } from "@material-ui/core"
import theme, { colors } from "../../theme"
import { Link } from "react-router-dom"

export const Author = styled(({ ...props }) => <Link {...props} />)`
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  line-height: 22px;
  color: ${colors.dark};
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const LinkBox = styled(({ ...props }) => <Link {...props} />)`
  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const Title = styled(Box)`
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Location = styled(({ ...props }) => <Link {...props} />)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.mediumLightGray};
  text-decoration: underline;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
    color: ${colors.mediumLightGray};
  }
`

export const AvatarBox = styled(Box)`
  border: 4px solid #fff;
  border-radius: 5px;
  margin-top: -32px;

  ${theme.breakpoints.down("xs")} {
    margin-top: -68px;
  }
`

export const Avatar = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  object-position: top;
`

export const ThumbnailBox = styled(Box)`
  flex: 40%;
`

export const ProjectThumbnail = styled.img`
  width: 125px;
  height: 74px;
  object-fit: cover;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    height: 111px;
  }
`

export const ProjectThumbnailPlaceholder = styled.div`
  width: 125px;
  height: 74px;
  background-color: #f7f7f7;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    height: 111px;
  }
`

export const CardBox = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: column;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: 275px;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  min-height: 353px;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    min-height: auto;
    gap: 20px;
  }
`

export const ContentBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const NetworkingBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;
  margin-top: auto;
  width: 100% !important;

  && button {
    width: 100% !important;
    height: 48px !important;
  }
`

export const ImagesBox = styled(Grid)``

export const ProjectsBox = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const DeleteButton = styled.img`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
`
