export const resolveImageDimensionsByURL = (url: string) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
  })
