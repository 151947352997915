import React from "react"
import View from "./View"

export type Props = {
  handleAllRead: () => void
  handleChangeState: (value: string) => void
}

const NotificationDotMenu = ({ handleAllRead, handleChangeState }: Props) => {
  return (
    <View handleAllRead={handleAllRead} handleChangeState={handleChangeState} />
  )
}

export default NotificationDotMenu
