import { StepButton } from "@material-ui/core"
import React from "react"
import { StepperStyled, StyledStepLabel } from "./styles"

import CompletedSetIcon from "../../images/complete-step-icon.svg"

export type Props = {
  currentStepNumber: number
  steps: string[]
  goToStep: Function
}

const View = ({ currentStepNumber, goToStep, steps }: Props) => (
  <StepperStyled activeStep={currentStepNumber} alternativeLabel>
    {steps.map((label, index) => (
      <StepButton
        key={label}
        onClick={() => goToStep(index)}
        disabled={currentStepNumber <= index}
      >
        <StyledStepLabel $isCurrentStep={currentStepNumber === index}>
          {index < currentStepNumber && (
            <img src={CompletedSetIcon} alt={"completed"} />
          )}
          {label}
        </StyledStepLabel>
      </StepButton>
    ))}
  </StepperStyled>
)

export default View
