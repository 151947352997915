import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Box } from "@material-ui/core"
import TextFieldLabelAbove from "../TextFieldLabelAbove"
import {
  Avatar,
  DescriptionBox,
  NameTitle,
  UserBox,
} from "../CommentTextField/styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"

import { Profile } from "../../redux/profile"

export type Props = {
  suggestions: Profile[]
  value?: Profile | null
  onInputChange: (value: string) => void
  onSetValue: (value: Profile | null) => void
  disabled: boolean
  index: number
}

const View = ({
  suggestions,
  value,
  onInputChange,
  onSetValue,
  disabled,
  index,
}: Props) => {
  return (
    <Autocomplete
      disabled={disabled}
      selectOnFocus
      clearOnBlur
      freeSolo
      value={value}
      onChange={(e, newValue) => {
        if (typeof newValue !== "string") onSetValue(newValue)
      }}
      options={suggestions}
      renderOption={(option) => {
        return (
          <Box>
            <UserBox>
              <Avatar
                src={
                  option.smallImageUrl ||
                  getRandomPlaceholder(option?.placeholderAvatar!)
                }
              />
              <DescriptionBox>
                <NameTitle>{option.display}</NameTitle>
              </DescriptionBox>
            </UserBox>
          </Box>
        )
      }}
      id={"project-collaborator-autocomplete-" + index}
      onInputChange={(_, newValue) => onInputChange(newValue)}
      getOptionLabel={(option) => (option.display ? option.display : "")}
      renderInput={(params) => (
        <TextFieldLabelAbove
          {...params}
          margin="normal"
          style={{ height: "48px", paddingTop: 0 }}
          displayTitle={"Name"}
          placeholder="Type in Person Name"
          name={"project-collaborators-" + index}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}

export default View
