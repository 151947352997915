import React from "react"

import { IconContainer } from "./styles"

import NetworkIcon from "../../images/new-header/header-network-icon.svg"
import NetworkIconActive from "../../images/new-header/header-network-icon-active.svg"
import { LinkBox } from "../../cards/ProjectCard/styles"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { RedDot } from "../InviteBell/styles"
import { logEvent } from "../../utils/analytics"

export const NetworkNavIcon = () => {
  const location = useLocation()
  const active = location.pathname.includes("/network")

  const unreadCount = useSelector(
    (state: any) => state.bellNotifications?.connectionsUnderadCount
  )

  const handleClick = async () => {
    await logEvent("navbar_network_click")
  }

  return (
    <LinkBox to={"/network"} onClick={handleClick} $isActive={active}>
      <IconContainer $isActive={active}>
        {unreadCount > 0 && (
          <RedDot
            style={{
              minWidth: unreadCount > 9 ? "19px" : "14px",
              marginLeft: unreadCount > 9 ? "35px" : "30px",
            }}
          >
            {unreadCount > 9 ? "9 +" : unreadCount}
          </RedDot>
        )}
        <img alt="network" src={active ? NetworkIconActive : NetworkIcon} />
        Network
      </IconContainer>
    </LinkBox>
  )
}
