import React from "react"
import Image from "material-ui-image"
import { Box, Grid, Typography } from "@material-ui/core"

import CoverSrc from "../../images/press-cover.jpg"
import {
  MainContainer,
  TextContainer,
  Text,
} from "../../components/Styles/StaticPages"

const View = () => (
  <MainContainer>
    <Image src={CoverSrc} aspectRatio={16 / 9} disableSpinner />
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6} md={6}>
        <TextContainer>
          <Typography variant="h1">Press</Typography>
          <Typography variant="h4">Overview</Typography>
          <Text py={2}>
            Spectacular is the professional network for architects and firms.
            It’s a community-driven space for all members of the architecture
            world to put their best foot forward. To seek inspiration. To
            discover jobs and talent. To grow your network. Functioning as a
            digital design gallery for individuals and firms to showcase their
            portfolios, Spectacular creates opportunities for everyone in the
            industry to build and make lasting connections through a shared
            appreciation of their work.
          </Text>
          <Box py={2}>
            <Typography variant="h4">Founder biography</Typography>
            <Typography variant="subtitle1">
              Marc Teer, CEO and Founder, Spectacular & Black Spectacles{" "}
            </Typography>
            <Text py={2}>
              Marc Teer is the founder and CEO of Black Spectacles, a member of
              the AIA College of Fellows, former award-winning architect at
              Gensler, Assistant Adjunct Professor at the Illinois Institute of
              Technology, and Chair of AIA Chicago’s Young Architects Forum.
              Marc founded Black Spectacles in 2010 to advance the creative
              potential of architecture and design through online learning,
              adding professional architecture network, Spectacular, to the
              business family in 2021.
            </Text>
          </Box>
        </TextContainer>
      </Grid>
    </Grid>
  </MainContainer>
)

export default View
