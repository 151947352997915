import React from "react"
import { CircularProgress } from "@material-ui/core"
import theme, { colors } from "../../theme"
import { FollowButton, FollowButtonNoPadding } from "./styles"
import { useWindowDimensions } from "../../hooks"

export type Props = {
  onMouseOver: (value: boolean) => void
  hover: boolean
  isFollowing: boolean
  isLoading: boolean
  onFollow: () => void
  onUnfollow: () => void
  noPadding?: boolean
  style?: any
}

const View = ({
  onMouseOver,
  onFollow,
  onUnfollow,
  hover,
  isFollowing,
  isLoading,
  noPadding,
  style,
}: Props) => {
  const ButtonComponent = noPadding ? FollowButtonNoPadding : FollowButton

  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const onMouseEnter = () => {
    onMouseOver(isMobile ? false : true)
  }

  const onMouseOut = () => {
    onMouseOver(false)
  }

  return (
    <>
      {isFollowing ? (
        hover ? (
          <ButtonComponent
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseOut}
            style={{
              color: "#fff",
              border: "1px solid #F44C49",
              background: "#F44C49",
              ...style,
            }}
            disabled={isLoading}
            onClick={onUnfollow}
            variant="outlined"
            size="medium"
            data-testid="unfollow-button"
            type="button"
          >
            {isLoading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <>Unfollow</>
            )}
          </ButtonComponent>
        ) : (
          <ButtonComponent
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseOut}
            style={{
              background: "white",
              border: "1px solid black",
              borderColor: colors.dark,
              ...style,
            }}
            disabled={isLoading}
            onClick={onUnfollow}
            variant="outlined"
            size="medium"
            data-testid="unfollow-button"
            type="button"
          >
            {isLoading ? <CircularProgress size={16} /> : <>Following</>}
          </ButtonComponent>
        )
      ) : (
        <ButtonComponent
          style={
            hover
              ? {
                  background: "#2d2d2d",
                  color: "#fff",
                  ...style,
                }
              : {
                  background: "#EBEFFC",
                  color: "#2d2d2d",
                  ...style,
                }
          }
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseOut}
          size="medium"
          data-testid="follow-button"
          type="button"
          disabled={isLoading}
          onClick={onFollow}
        >
          {isLoading ? (
            <CircularProgress size={16} style={{ color: colors.white }} />
          ) : (
            <>+ Follow</>
          )}
        </ButtonComponent>
      )}
    </>
  )
}

export default View
