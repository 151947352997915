import React, { useState } from "react"

import {
  SuccessDialogArea,
  SuccessDialogBox,
  DialogBgBox,
  CloseIconImg,
  DialogSpIcon,
  TextfieldBox,
  SuccessSubscriptionIconImg,
  SuccessDialogTitle,
  SuccessDialogText,
} from "./styles"

import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"

import { Button } from "../../components"

import SPIcon from "../../images/innovation-awards/sp-big-icon.svg"
import SuccessIcon from "../../images/innovation-awards/subscription-success-icon.svg"
import CloseIcon from "../../images/innovation-awards/close-icon.png"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import TextField from "../WeekendCompetitionPage/TextField"
import {
  submitInnovationAwards,
  validateIfProjectWasAlreadySubmitted,
  validateProjectUrl,
} from "../../redux/projects"

export type Props = {
  onClose: () => void
  open: boolean
  campaign: string
}

export interface Collaborator {
  name: string
}

export type FormValues = {
  projectLink: string
  schoolName: string
}
const RegisterDialog = ({ open, onClose, campaign }: Props) => {
  const auth = useAuth0()

  const checkIfUrlIsValid = async (url?: string) => {
    try {
      if (!url) return false

      const { payload } = await dispatch(
        validateProjectUrl({ projectLink: url })
      )

      if (payload.error) return false

      return true
    } catch (error) {
      return false
    }
  }

  const checkIfProjectWasAlreadySubmitted = async (url?: string) => {
    try {
      if (!url) return false

      const { payload } = await dispatch(
        validateIfProjectWasAlreadySubmitted({ projectLink: url, campaign })
      )
      return payload === "Valid"
    } catch (error) {
      return false
    }
  }

  const ValidationSchema = Yup.object().shape({
    schoolName: Yup.string().required("This field is required."),
    projectLink: Yup.string()
      .required("This field is required.")
      .test(
        `test-project-link`,
        "The link must be a Spectacular published project",
        function (value) {
          return checkIfUrlIsValid(value!)
        }
      )
      .test(
        `test-project-alreadt-submitted`,
        "Your project was already submitted to Studio Awards",
        function (value) {
          return checkIfProjectWasAlreadySubmitted(value!)
        }
      ),
  })

  const [registered, setRegistered] = useState(false)

  const dispatch = useAppDispatch()

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      const { type } = await dispatch(
        submitInnovationAwards({
          ...values,
          auth,
          code: campaign,
        })
      )
      if (submitInnovationAwards.fulfilled.type === type) {
        actions.setSubmitting(false)
        setRegistered(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const initialValues: FormValues = {
    projectLink: "",
    schoolName: "",
  }

  return (
    <SuccessDialogArea
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      scroll={"body"}
    >
      <SuccessDialogBox>
        <DialogBgBox>
          <CloseIconImg src={CloseIcon} alt={"Close"} onClick={onClose} />
          {!registered ? (
            <>
              <DialogSpIcon src={SPIcon} alt={"success"} />
              <Formik
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions)
                }}
                initialValues={initialValues}
                validationSchema={ValidationSchema}
              >
                {(formik) => (
                  <Form style={{ width: "100%" }}>
                    <TextfieldBox>
                      <TextField
                        fullWidth
                        name="projectLink"
                        displayTitle="Add the Spectacular Project Link"
                        placeholder="Add the URL"
                        style={{ height: "48px" }}
                      />
                    </TextfieldBox>

                    <TextfieldBox>
                      <TextField
                        fullWidth
                        name="schoolName"
                        placeholder="Write the school name"
                        displayTitle="School Name"
                        style={{ height: "48px" }}
                      />
                    </TextfieldBox>

                    <TextfieldBox style={{ marginTop: "35px" }}>
                      <Button
                        type={"submit"}
                        children={"Submit"}
                        color={"secondary"}
                        size={"medium"}
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.dirty
                        }
                        style={{
                          width: "100%",
                          height: "56px",
                          fontSize: "16px",
                        }}
                      />
                    </TextfieldBox>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <SuccessSubscriptionIconImg src={SuccessIcon} alt={"success"} />
              <SuccessDialogTitle variant="h2">Thank you!</SuccessDialogTitle>
              <SuccessDialogText>
                <span>Your registration has been sent</span>
              </SuccessDialogText>
            </>
          )}
        </DialogBgBox>
      </SuccessDialogBox>
    </SuccessDialogArea>
  )
}

export default RegisterDialog
