import React from "react"
import styled from "styled-components"
import { Button, Container, Typography, Link } from "@material-ui/core"
import theme from "../../theme"

export const Actions = styled.div`
  display: flex;
`

export const NavbarContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.primary.main};
    max-width: 100%;
    padding: 0;
    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    z-index: 10;
  }
`

export const NavBarButton = styled(Button)`
  height: 66px;
  width: 164px;
  padding-left: 0px;
  padding-right: 0px;
`

export const Logo = styled.img`
  padding: 0 ${theme.spacing(2)}px;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${theme.palette.primary.main};
  height: 66px;
  padding: 0px ${theme.spacing(1)}px;
  min-width: 166px;
  position: relative;
`

export const LogoText = styled(Typography)`
  position: absolute;
  bottom: 0;
  right: 15px;
`

export const LinksContainer = styled(Container)`
  padding-left: 0;
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  margin: 23.5px 10px;
  font-size: 14px;
  font-weight: bold;
`
