import React from "react"
import { JobListing } from "../../redux/jobListings"

import View from "./View"

type Props = {
  onClose: () => void
  open: boolean
  job: JobListing
}

const JobPromotionDialog = ({ open, onClose, job }: Props) => {
  const handleClose = () => {
    onClose()
  }

  return <View onClose={handleClose} open={open} job={job} />
}

export default JobPromotionDialog
