import { Select } from "@material-ui/core"
import styled from "styled-components"
import { colors } from "../../theme"

export const MainSelect = styled(Select)`
  background-color: ${colors.white};
  text-align: center;
  border: solid 0.5px ${colors.dark};
  border-radius: 0;
  height: 58px;
  .MuiSelect-select:focus {
    background-color: ${colors.white};
  }
  .MuiSelect-select {
    padding-right: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`
