import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"
import {
  nominateProject,
  Project,
  unnominateProject,
} from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { Profile, updateProfileNominationState } from "../../redux/profile"
import { logEvent } from "../../utils/analytics"

type Props = {
  project: Project
}

const ProjectNominationButton = ({ project }: Props) => {
  const auth = useAuth0()
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const [dispatchComplete, setDispatchComplete] = useState(true)

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  ) as Profile

  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleProjectNominate = async (hide?: boolean) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    setDispatchComplete(false)
    try {
      handleCloseDialog()

      const lastNominated = authenticatedProfile?.lastNominatedProject

      const nominateStatus = await dispatch(
        nominateProject({
          auth,
          id: project.id!,
          hide,
          lastNominated,
          lastNominationDate: authenticatedProfile?.lastNominationDate,
        })
      )
      if (nominateStatus.type === "projects/nominate/fulfilled") {
        setDispatchComplete(true)
        await dispatch(
          updateProfileNominationState({ hide, nominatedProject: project.id! })
        )
        await logEvent("project_nominate")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleProjectUnnominate = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }
    setDispatchComplete(false)
    try {
      const unnominateStatus = await dispatch(
        unnominateProject({ auth, id: project.id! })
      )
      if (unnominateStatus.type === "projects/unnominate/fulfilled")
        setDispatchComplete(true)
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseDialog = () => {
    setDialogIsOpen(false)
  }

  const handleOpenDialog = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    setDialogIsOpen(true)
  }

  return (
    <View
      dispatchComplete={dispatchComplete}
      project={project}
      onNominate={handleProjectNominate}
      onUnnominate={handleProjectUnnominate}
      dialogIsOpen={dialogIsOpen}
      handleCloseDialog={handleCloseDialog}
      handleOpenDialog={handleOpenDialog}
      authenticatedProfile={authenticatedProfile}
    />
  )
}

export default ProjectNominationButton
