import React, { useState } from "react"
import View from "./view"
import Cookies from "universal-cookie"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { removePlayerId, selectProfile } from "../../redux/profile"
import { useAuth0 } from "@auth0/auth0-react"
import { useAppDispatch } from "../../redux/configureStore"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"

const Navbar = () => {
  let history = useHistory()
  const profile = useSelector(selectProfile)

  const auth = useAuth0()
  const logout = auth.logout
  const dispatch = useAppDispatch()

  const [menuOpen, setMenuOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const onMenuClicked = () => {
    setMenuOpen(true)
  }

  const goToUrl = (url: string) => {
    handleMenuClose()
    handleDrawerClose()
    history.push(url)
  }

  const handleLogout = async () => {
    const url =
      environment.device === "desktop"
        ? window.location.origin
        : environment.redirectUri

    const cookies = new Cookies()
    cookies.remove("bks.token", { path: "/" })

    if (cookies.get("sp_player_id")) {
      await dispatch(
        removePlayerId({ auth, playerId: cookies.get("sp_player_id") })
      )
    }

    await logout({
      logoutParams: {
        returnTo: url,
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: "_self",
        })
      },
    })
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <View
      profile={profile}
      onMenuClicked={onMenuClicked}
      goToUrl={goToUrl}
      logOut={handleLogout}
      menuOpen={menuOpen}
      onMenuClose={handleMenuClose}
      isDrawerOpen={isDrawerOpen}
      onDrawerOpen={handleDrawerOpen}
      onDrawerClose={handleDrawerClose}
    />
  )
}

export default Navbar
