import React from "react"
import View from "./View"
import { useScript } from "../../hooks/useScript"
import { Helmet } from "react-helmet"
import RouteChangeTracker from "../../components/RouteChangeTracker"

const CookiePolicyPage = () => {
  setTimeout(() => {
    // @ts-ignore
    window.prerenderReady = true
  }, 100)

  useScript("https://app.termly.io/embed-policy.min.js")

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Cookie Policy`}
        classTitle="CookiePolicy"
      />
      <Helmet>
        <title>Cookie Policy - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Cookie Policy - Spectacular`} />
        <meta name="title" content={`Cookie Policy - Spectacular`} />
      </Helmet>
      <View />
    </>
  )
}

export default CookiePolicyPage
