import React, { useState, useEffect } from "react"

import IconActive from "../../images/new-homepage/icons/nominate-active.svg"
import IconDefault from "../../images/new-homepage/icons/nominate-default.svg"
import IconHover from "../../images/new-homepage/icons/nominate-hover.svg"

import {
  IconContainer,
  Icon,
  CountLabel,
  IconFlexBox,
  CounterFlexBox,
} from "./styles"
import { Project } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { Typography } from "@material-ui/core"
import { Profile } from "../../redux/profile"
import Dialog from "./dialog"
export type Props = {
  dispatchComplete: boolean
  project: Project
  onNominate: (hide?: boolean) => Promise<void>
  onUnnominate: () => Promise<void>
  dialogIsOpen: boolean
  handleCloseDialog: () => void
  handleOpenDialog: () => void
  authenticatedProfile: Profile
}

const View = ({
  dispatchComplete,
  project,
  onNominate,
  onUnnominate,
  dialogIsOpen,
  handleCloseDialog,
  handleOpenDialog,
  authenticatedProfile,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const [nomination, setNomination] = useState(false)
  const [nominationCount, setNominationCount] = useState(0)
  const [hover, setHover] = React.useState(false)

  const getIcon = (nominated?: boolean) => {
    if (nominated) return IconActive

    if (hover) return IconHover

    return IconDefault
  }

  useEffect(() => {
    setNomination(project.nominated!)
    setNominationCount(project.nominations!)
  }, [project])

  const handleNominate = (hide?: boolean) => {
    if (!disableNomination) {
      if (nomination) {
        if (isAuthenticated) {
          setNomination(false)
          if (nominationCount > 0) setNominationCount(nominationCount - 1)
        }
        onUnnominate()
      } else {
        if (isAuthenticated) {
          setNomination(true)
          setNominationCount(nominationCount + 1)
        }
        onNominate(hide)
      }
    }
  }

  const handleClick = () => {
    if (nomination) {
      handleNominate()
    } else {
      const lastNominationDate = new Date(
        authenticatedProfile?.lastNominationDate as any
      )
      const now =
        new Date().getDate() + new Date().getMonth() + new Date().getFullYear()
      const lastNomination = authenticatedProfile?.lastNominationDate
        ? lastNominationDate.getDate() +
          lastNominationDate.getMonth() +
          lastNominationDate.getFullYear()
        : 0

      if (now !== lastNomination && authenticatedProfile?.hideNominationPopup) {
        handleNominate()
      } else {
        handleOpenDialog()
      }
    }
  }

  let disableNomination = false
  if (!project.canBeNominated || !dispatchComplete) disableNomination = true
  return (
    <>
      <IconContainer
        $isActive={nomination}
        onClick={() => handleClick()}
        data-testid="nominate-button"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconFlexBox>
          <CounterFlexBox>
            <Icon src={getIcon(nomination)} alt="" />
            <CountLabel>{nominationCount}</CountLabel>
          </CounterFlexBox>
          <Typography>Nominate</Typography>
        </IconFlexBox>
      </IconContainer>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        onClose={handleCloseDialog}
        profile={authenticatedProfile}
        handleNominate={handleNominate}
      />
    </>
  )
}

export default View
