import theme, { colors } from "../../theme"
import styled from "styled-components"
import {
  FormHelperText,
  Grid,
  Tab,
  Button,
  Box,
  Container,
  Typography,
} from "@material-ui/core"

export const Root = styled("div")`
  width: 100%;
  margin: 0 auto;
`

export const StatContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const StatCell = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

export const StateLabel = styled(Typography)`
  font-size: 14px;
`

export const StateValue = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding-right: 4px;
`

export const FollowButton = styled.button`
  width: 268px;
  height: 40px;
  border: none;
  color: white;
  display: flex;
  cursor: pointer;
  font-family: Public-Sans;
  text-align: center;
  align-items: center;
  background: #3057e1;
  justify-content: center;
`

export const BannerContainer = styled.div`
  width: 100%;
  height: 151px;
`

export const BannerImage = styled.img`
  top: 145px;
  left: 0px;
  width: 100%;
  height: 151px;
  z-index: -999;
  object-fit: cover;
  object-position: top;
  position: absolute;

  ${theme.breakpoints.down("sm")} {
    top: 127px;
  }

  ${theme.breakpoints.down(600)} {
    top: 115px;
  }
`

export const ProfileContainer = styled(Container)`
  padding: 0 ${theme.spacing(4)}px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 40px;
    margin-top: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 0 ${theme.spacing(2)}px;
  }

  && div.profile-container {
    padding-right: ${theme.spacing(4)}px;

    ${theme.breakpoints.down("sm")} {
      padding-right: 0;
    }
  }

  && p.MuiTypography-body2 {
    font-size: 1em;
  }
`
export const SocialBox = styled(Box)`
  display: flex;

  && a {
    fill: #fff;
    color: #fff;
    width: 32px;
    margin: 0 8px;
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: center;
    background: #2d2d2d;
  }

  && a:first-child {
    margin-left: 0;
  }

  && a:last-child {
    margin-right: 0;
  }

  && a:hover {
    background: #000000;
  }
`

export const ProfilePicture = styled.img`
  width: 100%;
`

export const IconAnchor = styled.a`
  background-color: #2d2d2d;
  &:hover {
    background-color: #717171;
  }
`

export const RightColumn = styled(Grid)`
  > div > div {
    padding-left: ${theme.spacing(3)}px;
  }

  .profile-education div.buttress-divider {
    margin-left: 0;
  }

  ${theme.breakpoints.down("sm")} {
    display: flex;
    margin-top: 26px;

    div.buttress-divider {
      margin-left: 0;
    }
  }

  ${theme.breakpoints.down("xs")} {
    display: flex;
    flex-direction: row;
    margin-top: 0px;

    > div > div {
      padding-left: 0;
    }

    && div.buttress-divider {
      margin-left: 0;
    }
  }
`

export const ProfileDivider = styled(Grid)`
  flex-basis: 0;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const TabLabel = styled(Typography)`
  font-size: 20px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const TabItem = styled(Tab)`
  && {
    min-width: inherit;
    padding: 0 0 22px 0;
    margin-right: 40px;

    ${theme.breakpoints.down("md")} {
      margin-right: 32px;
      padding: 0 0 10px 0;
    }

    ${theme.breakpoints.down("xs")} {
      padding: 0 0 4px 0;
    }

    h6 {
      font-family: "Public-Sans";
      text-transform: none;
      color: ${colors.dark};
      font-weight: 400;

      ${theme.breakpoints.down("md")} {
        font-size: 16px;
      }
    }
  }
`

export const SubHeaderText = styled(FormHelperText)`
  font-family: "Public-Sans";
  font-size: 16px;
  color: #2d2d2d;
  margin: 0 !important;

  && {
    .MuiFormHelperText-root {
      margin-top: 0 !important;
    }
  }
`

export const FeaturedIndicator = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 40px;
`

export const PlusIcon = styled.img`
  height: 32px;
  width: 32px;
`

export const ActionButton = styled(Button)`
  height: 48px;
  font-family: Public-Sans-Bold;
  padding: 16px;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 1px;
`

export const RichTextEditorContainer = styled(Box)`
  padding-bottom: ${theme.spacing(3)}px;
  border-top: solid 0.5px ${colors.slate};
  border-left: solid 0.5px ${colors.slate};
  border-right: solid 0.5px ${colors.slate};
  border-bottom: solid 0.5px ${colors.slate};
  && {
    #mui-rte-editor {
      padding: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`

export const LocationText = styled.span`
  && {
    font-family: "Public-Sans";
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #717171;
    display: flex;

    &:before {
      margin-top: -5px;
    }

    &:after {
      margin-bottom: -3px;
    }
  }
`

export const TitleLabel = styled(Typography)`
  font-size: 12px;
  color: ${colors.mediumLightGray};
  text-transform: uppercase;
  line-height: 10px;
`

export const DarkLabel = styled(Typography)`
  font-size: 20px;
  line-height: 15px;
  color: ${colors.dark};
`

export const LightLabel = styled(Typography)`
  font-size: 14px;
  line-height: 11px;
  color: ${colors.mediumLightGray};
  margin-top: 4px;
`

export const CounterBox = styled(Box)`
  background: #f7f7f7;
  width: 100%;
  text-align: center;
  height: 56px;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  
  ${theme.breakpoints.down("sm")} {
    height: 64px;
  }
}
`
export const CounterArea = styled(Box)`
  display: flex;
  flex-flow: inerhit;

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
  }
`

export const FitText = styled.span`
  line-height: 22px;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &:before {
    margin-top: -5px;
  }

  &:after {
    margin-bottom: -3px;
  }
`

export const IconBox = styled.img`
  margin-right: 6px;
`

export const TextBox = styled.div`
  ${theme.breakpoints.down("xs")} {
    text-align: center;
    justify-content: center;
  }
`
export const ButtonsBox = styled(Box)`
  display: flex;
  flex-flow: inherit;
  margin-top: 20px;
  gap: 16px;

  ${theme.breakpoints.down("xs")} {
    flex-flow: wrap;
    justify-content: center;
    margin-top: 24px;
    gap: 8px;
  }
`

export const ContactBox = styled(Box)`
  ${theme.breakpoints.down("sm")} {
    flex-flow: wrap;
    display: flex;
    gap: 16px;
  }
  ${theme.breakpoints.down("xs")} {
    display: block;
  }
`

export const MobileContactBox = styled(Box)`
  height: 46px;
  border: 0.5px #d8d8d8 solid;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  font-size: 14px;
  padding: 0 8px;
`

export const TabTitleText = styled.span`
  font-size: 20px;
  line-height: 25px;

  ${theme.breakpoints.down("xs")} {
    font-size: 18px;
    line-height: 22px;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &:before {
    margin-top: -5px;
  }

  &:after {
    margin-bottom: -3px;
  }
`

export const AboutText = styled.div`
  font-size: 16px;
  padding-top: 12px;

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }

  line-height: 20px;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &:before {
    margin-top: -5px;
  }

  &:after {
    margin-bottom: -3px;
  }
`

export const StyledLink = styled.div`
  font-size: 14px;
  color: #3057e1;
  font-weight: 700;
  line-height: 15px;
  margin-top: 16px;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &:before {
    margin-top: -5px;
  }

  &:after {
    margin-bottom: -3px;
  }
`

export const MemberSinceArea = styled.div`
  ${theme.breakpoints.down("sm")} {
    border-top: 0.5px solid #d8d8d8;
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: -64px;
    box-sizing: border-box;
    ${theme.breakpoints.down("xs")} {
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -20px;
    }
  }
`

export const MobileAvatarBox = styled(Box)`
  margin-bottom: 20px;

  ${theme.breakpoints.down("xs")} {
    display: flex;
    justify-content: center;
  }
`

export const NullstateTab = styled(Box)<{ profileType?: string }>`
  padding-top: ${(props) => (props.profileType === "group" ? "32px" : "40px")};
  padding-left: 0;
  padding-bottom: 96px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 24px;
    padding-bottom: 60px;
  }
`

export const PostTabBox = styled(Box)`
  padding-left: 0 !important;
`

export const PostCardBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 32px;
`
