import styled from "styled-components"
import { Dialog, Typography, Box, Tab } from "@material-ui/core"
import theme, { colors } from "../../theme"

export const ConnectionListDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 0;
`

export const ConnectionListDialogHeader = styled(Box)`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  line-height: 27px;
  border-bottom: 0.5px solid #d8d8d8;

  ${theme.breakpoints.down("sm")} {
    padding: 0 32px;
  }

  ${theme.breakpoints.down("xs")} {
    min-height: 64px;
    padding: 0 20px;
  }
`

export const ConnectionListDialogBox = styled(Box)`
  padding: 40px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 32px 20px;
  }
`

export const DialogCloseButton = styled.img`
  cursor: pointer;
`

export const TabLabel = styled(Typography)`
  font-size: 20px;

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`

export const TabItem = styled(Tab)`
  && {
    min-width: inherit;
    padding: 0 0 22px 0;
    margin-right: 40px;

    ${theme.breakpoints.down("md")} {
      padding: 0 0 22px 0;
    }

    ${theme.breakpoints.down("xs")} {
      margin-right: 24px;
      padding: 0 0 15px 0;
    }

    h6 {
      font-family: "Public-Sans";
      text-transform: none;
      color: ${colors.dark};
      font-weight: 400;

      ${theme.breakpoints.down("xs")} {
        font-size: 16px;
      }
    }
  }
`

export const Divider = styled.div`
  border-top: 0.5px solid #d8d8d8;
  margin: 24px 0;
  width: 100%;
`
