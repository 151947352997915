import React from "react"
import { Profile } from "../../redux/profile"
import View from "./View"

type Props = {
  profile: Profile
  source?: string
}

const ProfileCardIcon = ({ profile, source }: Props) => {
  return <View profile={profile} source={source} />
}

export default ProfileCardIcon
