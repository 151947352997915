import React from "react"
import moment from "moment"
import { Box, Button, Container, Hidden, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import {
  DetailsBox,
  SocialBox,
  FeaturedIndicator,
  SlateUnderLineLink,
  ProfileInfoContainer,
  ProjectDetailsContainer,
  FlexBox,
  LeftBox,
  RightBox,
  DescriptionTitle,
  DescriptionText,
} from "./styles"

import { Collaborator, Project } from "../../redux/projects"

// import { Button as DefaultButton } from "../../components"

import FeaturedIcon from "../../images/featured-icon.svg"

import projectStates from "../../constants/project-states"
import { projectToolOptions } from "../../constants/project-tools"
import { projectCategories } from "../../constants/project-categories"

import ImageGallery from "../../components/ImageGallery"
import ButtressDivider from "../../components/ButtressDivider"
import ProjectComments from "../../components/ProjectComments"
import ProjectDeleteLink from "../../components/ProjectDeleteLink"
import ProjectPostComment from "../../components/ProjectPostComment"
import { useAuth0 } from "@auth0/auth0-react"
import { Collaborator as CollaboratorView } from "./Collaborator"
import { Profile } from "../../redux/profile"
import ProjectCardActions from "../../components/ProjectCardActions"
import ProjectWinnerBadge from "../../components/ProjectWinnerBadge"

import "./styles.css"
import ProjectDescriptionText from "../../components/ProjectDescriptionText"

export type Props = {
  project: Project
  authenticatedProfile: Profile
  isProjectOwner: boolean
  goToUrl: (url: string) => void
  isCollaborator?: Collaborator[]
}

const View = ({
  project,
  authenticatedProfile,
  isProjectOwner,
  goToUrl,
  isCollaborator,
}: Props) => {
  const { isAuthenticated } = useAuth0()

  const stateName = projectStates.find(
    (state) => state.value === project.state
  )?.name

  const otherCategory = project.category!.filter((e) => {
    let currCategory = true
    for (let item in projectCategories) {
      if (projectCategories[item].value === e) currCategory = false
    }
    return currCategory
  })

  const foundOtherCategory = otherCategory.toString()

  const featured = project.featured
  const images =
    project.images?.slice()?.sort((a, b) => a.order - b.order) || []

  const history = useHistory()

  const keyMaterials = project.keyMaterials ?? []

  const isProjectComplete = project.state === "complete"

  const publishedDate = project.publishedAt
    ? moment(project.publishedAt).format("MMMM DD[,] YYYY")
    : moment(project.updatedAt).format("MMMM DD[,] YYYY")

  let options = projectToolOptions
  const otherTool = project.tools!.filter((e) => {
    let currTool = true
    for (let item in options) {
      if (options[item].value === e) currTool = false
    }
    return currTool
  })

  const foundOtherTool = otherTool.toString()

  const collaborators = project.collaborators?.filter((collaborator) => {
    if (
      collaborator.status !== "pending" ||
      collaborator.profile?.connectionId ===
        authenticatedProfile?.connectionId ||
      isProjectOwner
    )
      return true

    return false
  })

  const showButtons = collaborators?.length === 0

  return (
    <Container>
      <Box marginTop={3} marginBottom={3}>
        <Typography
          tabIndex={0}
          variant="h1"
          style={{ fontFamily: "Public-Sans" }}
        >
          {project.name}
        </Typography>
        {isAuthenticated && project.canBeEdited && (
          <Box marginTop={2}>
            <Box marginRight={2} display="inline">
              <Button
                color="primary"
                size="medium"
                onClick={() => {
                  history.push(`/projects/${project.id!}/edit`)
                }}
              >
                Edit project
              </Button>
            </Box>
            <ProjectDeleteLink project={project} />
          </Box>
        )}
      </Box>

      <Box paddingBottom={5}>
        <ImageGallery images={images} projectName={project.name} />
      </Box>

      <FlexBox>
        <LeftBox>
          <DetailsBox>
            <Box role="group" aria-label="Project details">
              <Box aria-label="project description">
                <DescriptionTitle variant={"h5"}>
                  Project Description
                </DescriptionTitle>

                <DescriptionText>
                  <ProjectDescriptionText project={project} />
                </DescriptionText>
              </Box>
              <SocialBox id={"comments"}>
                <ProjectCardActions project={project} />
              </SocialBox>
            </Box>
            {isAuthenticated && <ProjectPostComment id={project.id!} />}
            <ProjectComments project={project} />
          </DetailsBox>
        </LeftBox>

        <RightBox>
          <ProfileInfoContainer aria-label="author details">
            <DescriptionTitle variant={"h5"}>
              Project Collaborators
            </DescriptionTitle>

            <CollaboratorView
              profile={project.profile}
              showButtons={showButtons}
              authenticatedProfile={authenticatedProfile}
              isProjectOwner={isProjectOwner}
              project={project}
            />

            {collaborators?.map((collaborator) => {
              return (
                <CollaboratorView
                  collaborator={collaborator}
                  showButtons={false}
                  authenticatedProfile={authenticatedProfile}
                  isProjectOwner={isProjectOwner}
                />
              )
            })}

            {/* {
              !isProjectOwner && (!isCollaborator || isCollaborator?.length === 0) && (
                <Box>
                  <DefaultButton
                    onClick={() => goToUrl("/innovation-awards")}
                    children={"Request to be collaborator"}
                    color={"whiteGreyBorder"}
                    size={"medium"}
                    style={{
                      width: "100%",
                      height: "48px",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              )
            } */}
          </ProfileInfoContainer>

          <ProjectDetailsContainer>
            {featured && (
              <Box marginLeft={2} height={32}>
                <FeaturedIndicator
                  src={FeaturedIcon}
                  aria-label="featured tag"
                />
              </Box>
            )}

            <DescriptionTitle variant={"h5"}>Project details</DescriptionTitle>

            {project.rewards && project.rewards?.length > 0 && (
              <>
                {project.rewards.map((reward) => {
                  return (
                    <div className="projectDetailsWinnerBadge" key={reward.id}>
                      <ProjectWinnerBadge
                        variant="horizontal"
                        reward={reward}
                      />
                    </div>
                  )
                })}
              </>
            )}

            <Box marginTop={1}>
              <Box>
                {stateName && (
                  <Typography>
                    <b>Project status:</b> {stateName}
                  </Typography>
                )}

                {project.stateDate && (
                  <Typography>
                    <b>
                      {isProjectComplete
                        ? "Date completed"
                        : "Estimated completion date"}
                      :
                    </b>{" "}
                    {moment(project.stateDate).format("MMM[,] YYYY")}
                  </Typography>
                )}

                {project.published && (
                  <Typography>
                    <b>Published:</b> {publishedDate}
                  </Typography>
                )}
              </Box>

              {project.location && (
                <Box marginTop={3}>
                  <Typography>
                    <b>Project location:</b>{" "}
                  </Typography>
                  {project.location}
                </Box>
              )}

              {project.type === "firm" && project.firmName && (
                <Box marginTop={3}>
                  <Typography>
                    <b>Company:</b> {project.firmName}
                  </Typography>
                </Box>
              )}

              {project.type === "school" && project.firmName && (
                <Box marginTop={3}>
                  <Typography>
                    <b>School:</b> {project.firmName}
                  </Typography>
                </Box>
              )}

              {(project.role || project.responsibilities) && (
                <Box marginTop={3}>
                  {project.role && (
                    <Typography style={{ marginBottom: ".5em" }}>
                      <b>My role:</b> {project.role}
                    </Typography>
                  )}
                  {project.responsibilities && (
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {project.responsibilities}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </ProjectDetailsContainer>

          <ProjectDetailsContainer>
            <DescriptionTitle variant={"h5"}>Typology</DescriptionTitle>

            <Typography>
              {project.category!.map((category: string, idx: number) => {
                let categoryMatch = projectCategories.find(
                  (option) => option.value === category
                )
                if (categoryMatch == null && foundOtherCategory !== null) {
                  const addOther = {
                    name: foundOtherCategory,
                    value: foundOtherCategory,
                  }
                  categoryMatch = addOther
                }

                return (
                  <>
                    <SlateUnderLineLink
                      href={`/projects?typology=${JSON.stringify([category])}`}
                    >
                      {categoryMatch?.name}
                    </SlateUnderLineLink>
                    {idx === project.category!.length - 1 ? "" : ", "}
                  </>
                )
              })}
            </Typography>

            <Box marginTop={4} display="block" alignItems="flex-start">
              <DescriptionTitle variant={"h5"}>Tools</DescriptionTitle>

              <Typography>
                {project.tools!.map((tool: string, idx: number) => {
                  let toolMatch = options.find(
                    (option) => option.value === tool
                  )
                  if (toolMatch == null && foundOtherTool !== null) {
                    const addOther = {
                      name: foundOtherTool,
                      value: foundOtherTool,
                    }
                    toolMatch = addOther
                  }

                  return (
                    <>
                      <SlateUnderLineLink
                        href={`/projects?tools=${JSON.stringify([tool])}`}
                      >
                        {toolMatch?.name}
                      </SlateUnderLineLink>
                      {idx === project.tools!.length - 1 ? " " : ", "}
                    </>
                  )
                })}
              </Typography>
            </Box>

            <Box marginTop={4} display="block" alignItems="flex-start">
              <DescriptionTitle variant={"h5"}>Key Materials</DescriptionTitle>

              <Typography>
                {keyMaterials.map((material, idx) => (
                  <>
                    <SlateUnderLineLink
                      href={`/projects?keyMaterials=${JSON.stringify([
                        material,
                      ])}`}
                    >
                      {material}
                    </SlateUnderLineLink>
                    {idx === keyMaterials.length - 1 ? " " : ", "}
                  </>
                ))}
              </Typography>
            </Box>
          </ProjectDetailsContainer>

          {project.tags && project.tags.length > 0 ? (
            <>
              <ProjectDetailsContainer>
                <DescriptionTitle variant={"h5"}>Tags</DescriptionTitle>

                <Box marginTop={1} display="block" alignItems="flex-start">
                  {project.tags!.map((tag: string, index: number) => (
                    <Box
                      key={index}
                      marginRight={1}
                      marginBottom={1}
                      marginTop={1}
                      display="inline-block"
                    >
                      <Button
                        size="small"
                        color="primary"
                        onClick={() =>
                          goToUrl(`/projects?tags=${JSON.stringify([tag])}`)
                        }
                      >
                        {tag}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </ProjectDetailsContainer>
            </>
          ) : null}
        </RightBox>
      </FlexBox>

      <Hidden mdUp implementation="css">
        <ButtressDivider />
        <Box marginTop={5} id="comments">
          <ProjectPostComment id={project.id!} />
          <ProjectComments project={project} />
        </Box>
      </Hidden>
    </Container>
  )
}

export default View
