import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Profile } from "../../../../redux/profile"
import LoadingPage from "../../../LoadingPage"
import { ConnectionsBox, ConnectionsHeader, ConnectionsList } from "./styles"
import { Box } from "@material-ui/core"
import { useWindowDimensions } from "../../../../hooks"
import theme from "../../../../theme"
import GrowNetworkCard from "../../../../cards/GrowNetworkCard"

export type Props = {
  suggestions: Profile[]
  suggestionsCount: number
  requests: Profile[]
  requestsCount: number
  hasMore: boolean
  page: number
  isLoading: boolean
  onUpdateHasMore: (value: boolean) => void
  onUpdatePage: (value: number) => void
}

const View = ({
  suggestions,
  suggestionsCount,
  requests,
  requestsCount,
  hasMore,
  page,
  isLoading,
  onUpdateHasMore,
  onUpdatePage,
}: Props) => {
  const fetchMoreData = () => {
    if (suggestions.length >= suggestionsCount) {
      onUpdateHasMore(false)
      return
    }
    onUpdatePage(page + 1)
  }

  const { width } = useWindowDimensions()

  const isMobile = width < theme.breakpoints.values.sm

  if (isLoading)
    return (
      <ConnectionsBox>
        <LoadingPage />
      </ConnectionsBox>
    )

  return (
    <>
      {requestsCount > 0 && (
        <ConnectionsBox>
          <ConnectionsHeader>
            <Box>
              {requestsCount} connection{" "}
              {requestsCount === 1 ? "request" : "requests"}
            </Box>
          </ConnectionsHeader>
          <ConnectionsList>
            {requests.map((profile) => {
              return <GrowNetworkCard profile={profile} confirmButtons={true} />
            })}
          </ConnectionsList>
        </ConnectionsBox>
      )}

      <ConnectionsBox
        style={{
          marginTop: requestsCount > 0 ? (isMobile ? "32px" : "48px") : "0px",
        }}
      >
        <ConnectionsHeader>
          <Box>Suggestions (People you may know)</Box>
        </ConnectionsHeader>
        {suggestions.length > 0 ? (
          <InfiniteScroll
            dataLength={suggestions.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingPage />}
            style={{ overflow: "hidden" }}
          >
            <ConnectionsList>
              {suggestions.map((profile) => {
                return <GrowNetworkCard profile={profile} isSuggestion={true} />
              })}
            </ConnectionsList>
          </InfiniteScroll>
        ) : (
          <ConnectionsList style={{ justifyContent: "left" }}>
            There are no suggestions for now
          </ConnectionsList>
        )}
      </ConnectionsBox>
    </>
  )
}

export default View
