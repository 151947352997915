import React, { useState } from "react"
import { Tabs, Grid } from "@material-ui/core"
import {
  ConnectionListDialog,
  ConnectionListDialogHeader,
  ConnectionListDialogBox,
  DialogCloseButton,
  TabItem,
  TabLabel,
  Divider,
} from "./Styles"

import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import "./fix-dialog.css"
import { ConnectionList, Profile } from "../../redux/profile"

import ProfileListingCard from "../../cards/ProfileListingCard"

import LoadingPage from "../../pages/LoadingPage"

export type Props = {
  onClose: () => void
  open: boolean
  currentTab: string
  onTabChanged: (event: React.ChangeEvent<{}>, tab: string) => void
  connectionsList: ConnectionList
  profile: Profile
}

const View = ({
  open,
  currentTab,
  onClose,
  onTabChanged,
  connectionsList,
  profile,
}: Props) => {
  const [hover, setHover] = useState(false)

  return (
    <ConnectionListDialog
      maxWidth={"lg"}
      fullWidth={true}
      open={open}
      onClose={onClose}
      className={"ConnectionListDialog"}
    >
      <ConnectionListDialogHeader>
        <Tabs
          value={currentTab}
          onChange={onTabChanged}
          indicatorColor="primary"
          style={{ alignSelf: "flex-end" }}
          TabIndicatorProps={{ style: { height: "1px" } }}
        >
          {connectionsList?.connections?.length > 0 &&
            profile.type !== "group" && (
              <TabItem
                label={
                  <TabLabel variant="h6" display="inline">
                    Connections
                  </TabLabel>
                }
                value="connections"
              />
            )}
          {connectionsList?.followers?.length > 0 && (
            <TabItem
              label={
                <TabLabel tabIndex={0} variant="h6" display="inline">
                  Followers
                </TabLabel>
              }
              value="followers"
            />
          )}
        </Tabs>

        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </ConnectionListDialogHeader>
      <ConnectionListDialogBox>
        {connectionsList ? (
          <>
            {currentTab === "connections" &&
              connectionsList?.connections?.length > 0 && (
                <Grid
                  container
                  direction="column"
                  style={{ flexWrap: "nowrap" }}
                >
                  {connectionsList.connections.map(
                    (connectionProfile: Profile, idx: number) => (
                      <>
                        <ProfileListingCard profile={connectionProfile} />
                        {connectionsList.connections.length - 1 !== idx && (
                          <>
                            <Divider />
                          </>
                        )}
                      </>
                    )
                  )}
                </Grid>
              )}
            {currentTab === "followers" &&
              connectionsList?.followers?.length > 0 && (
                <Grid
                  container
                  direction="column"
                  style={{ flexWrap: "nowrap" }}
                >
                  {connectionsList.followers.map(
                    (connectionProfile: Profile, idx: number) => (
                      <>
                        <ProfileListingCard profile={connectionProfile} />
                        {connectionsList.followers.length - 1 !== idx && (
                          <>
                            <Divider />
                          </>
                        )}
                      </>
                    )
                  )}
                </Grid>
              )}
          </>
        ) : (
          <LoadingPage />
        )}
      </ConnectionListDialogBox>
    </ConnectionListDialog>
  )
}

export default View
