import React from "react"
import Dialog from "../NewPostButton/Dialog"
import { Profile } from "../../redux/profile"
import { Image } from "../../redux/projects"
import { Post, PostMention } from "../../redux/posts"

export type Props = {
  post: Post
  authenticatedProfile: Profile
  handleEditDialogOpen: (value: boolean) => void
  isEditDialogOpen: boolean
  onSubmit: (
    content: string,
    images: Image[],
    removedImages: string[],
    mentions?: PostMention[],
    postId?: string
  ) => void
  currentFile: string
  isPosting: boolean
  isUploading: boolean
}

const View = ({
  post,
  authenticatedProfile,
  handleEditDialogOpen,
  isEditDialogOpen,
  onSubmit,
  currentFile,
  isPosting,
  isUploading,
}: Props) => {
  return (
    <>
      {isEditDialogOpen && (
        <Dialog
          post={post}
          profile={authenticatedProfile}
          onClose={() => handleEditDialogOpen(false)}
          open={isEditDialogOpen}
          onSubmit={onSubmit}
          currentFile={currentFile}
          isPosting={isPosting}
          isUploading={isUploading}
          isEditing={true}
        />
      )}
    </>
  )
}

export default View
