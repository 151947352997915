import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../../theme"

export const Container = styled(Box)<{}>`
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 32px;
`

export const InnerBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 56px 80px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 20px;
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column-reverse;
    gap: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    align-items: flex-start;
  }
`

export const StyledLogo = styled.img`
  @media only screen and (max-width: 1210px) {
    width: 500px;
  }

  @media only screen and (max-width: 1090px) {
    width: 450px;
  }

  @media only screen and (max-width: 990px) {
    width: 400px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    max-width: 500px;
    align-self: center;
  }
`
export const ContentBox = styled(Box)`
  display: flex;
  gap: 32px;
  flex-flow: column;

  && img {
    width: 282px;
  }

  @media only screen and (max-width: 1090px) {
    && img {
      width: 220px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    && button {
      width: 100% !important;
    }

    && img {
      width: 200px;
    }

    gap: 0;
  }
`

export const Title = styled(Typography)`
  font-size: 70px;
  line-height: 57.6px;
  color: #fff;
  font-weight: 400;

  @media only screen and (max-width: 1320px) {
    font-size: 54px;
  }

  @media only screen and (max-width: 1090px) {
    font-size: 48px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    font-size: 40px;
    line-height: 45.6px;
    margin-top: 8px;
  }
`

export const Description = styled(Typography)`
  font-size: 16px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    margin: 24px 0;
  }
`
