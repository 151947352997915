import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { selectProfile } from "../../redux/profile"
import {
  fetchJobListing,
  fetchPromotedJobs,
  RequestOrigin,
} from "../../redux/jobListings"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { SegmentContext } from "../../context"
import { Helmet } from "react-helmet"
import { ActionsType as MultiActionType } from "../../components/JobsDropDownMultiselect/View"

import {
  initialEmploymentType,
  initialExperienceLevel,
  initialWorkplaceType,
} from "./initialStates"
import { LocationScrollState } from "../../types/historyLocationStates"
import { RootState } from "../../redux/rootReducer"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { SEOTagsJobs } from "../../constants/seo-tags"
import { capitalize } from "../../utils/capitalize"

type Props = {
  perPage?: number
  search?: string
  sortBy?: string
}

type DefaultFilters = {
  term: string
  location: string
  sortBy: string
  tag: string
  workplaceType: MultiActionType[]
  employmentType: MultiActionType[]
  experienceLevel: MultiActionType[]
  experienceLevelFilter: string
  employmentTypeFilter: string
  workplaceTypeFilter: string
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const JobsPage = ({ perPage = 10, sortBy = "createdAt" }: Props) => {
  const query = useQuery()
  const [action, setAction] = useState("POP")
  const history = useHistory()

  useEffect(() => {
    const state = history.location.state as LocationScrollState
    if (state && state.scrollToTop) {
      window.scroll(0, 0)
    }
  }, [history])

  const currentFilter = useSelector(
    (state: any) => state.jobListings.jobsPage.currentFilter
  )

  let queriedWorkplaceType = JSON.parse(JSON.stringify(initialWorkplaceType))
  let queriedWorkplaceTypeFilter = ""
  let queriedEmploymentType = JSON.parse(JSON.stringify(initialEmploymentType))
  let queriedEmploymentTypeFilter = ""
  let queriedExperienceLevel = JSON.parse(
    JSON.stringify(initialExperienceLevel)
  )
  let queriedExperienceLevelFilter = ""
  if (query.get("workplaceType")) {
    queriedWorkplaceType.map((value: MultiActionType) => {
      value.active = value.action === query.get("workplaceType")

      if (value.active) queriedWorkplaceTypeFilter = value.action

      return value
    })
  }
  if (query.get("employmentType")) {
    queriedEmploymentType.map((value: MultiActionType) => {
      value.active = value.action === query.get("employmentType")

      if (value.active) queriedEmploymentTypeFilter = value.action

      return value
    })
  }
  if (query.get("experienceLevel")) {
    queriedExperienceLevel.map((value: MultiActionType) => {
      value.active = value.action === query.get("experienceLevel")

      if (value.active) queriedExperienceLevelFilter = value.action

      return value
    })
  }

  const initialFilters: DefaultFilters = {
    term: query.get("search") || "",
    location: query.get("location") || "",
    tag: query.get("tag") || "",
    sortBy: "recent",
    workplaceType: JSON.parse(JSON.stringify(queriedWorkplaceType)),
    employmentType: JSON.parse(JSON.stringify(queriedEmploymentType)),
    experienceLevel: JSON.parse(JSON.stringify(queriedExperienceLevel)),
    experienceLevelFilter: queriedExperienceLevelFilter,
    employmentTypeFilter: queriedEmploymentTypeFilter,
    workplaceTypeFilter: queriedWorkplaceTypeFilter,
  }

  if (
    action === "POP" &&
    history.action !== "PUSH" &&
    Object.keys(currentFilter || {}).length > 0
  ) {
    initialFilters.term =
      query.get("search") && !currentFilter?.search
        ? initialFilters.term
        : currentFilter?.search
    initialFilters.location =
      query.get("location") && !currentFilter?.location
        ? initialFilters.location
        : currentFilter?.location
    initialFilters.tag =
      query.get("tag") && !currentFilter?.tag
        ? initialFilters.tag
        : currentFilter?.tag
    initialFilters.sortBy = currentFilter?.sortBy
    initialFilters.experienceLevelFilter =
      query.get("experienceLevel") && !currentFilter?.experienceLevelFilter
        ? initialFilters.experienceLevelFilter
        : currentFilter?.experienceLevelFilter
    initialFilters.employmentTypeFilter =
      query.get("employmentType") && !currentFilter?.employmentTypeFilter
        ? initialFilters.employmentTypeFilter
        : currentFilter?.employmentTypeFilter
    initialFilters.workplaceTypeFilter =
      query.get("workplaceType") && !currentFilter?.workplaceTypeFilter
        ? initialFilters.workplaceTypeFilter
        : currentFilter?.workplaceTypeFilter
    initialFilters.workplaceType =
      query.get("workplaceType") && currentFilter?.workplaceType?.length <= 0
        ? initialFilters.workplaceType
        : currentFilter?.workplaceType
    initialFilters.employmentType =
      query.get("employmentType") && currentFilter?.employmentType?.length <= 0
        ? initialFilters.employmentType
        : currentFilter?.employmentType
    initialFilters.experienceLevel =
      query.get("experienceLevel") &&
      currentFilter?.experienceLevel?.length <= 0
        ? initialFilters.experienceLevel
        : currentFilter?.experienceLevel
  }

  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const tag = initialFilters.tag
  const [filterBy, setFilterBy] = useState(initialFilters.sortBy)
  const profile = useSelector(selectProfile)
  const [checkCount, setCheckCount] = useState(0)
  const [term, setTerm] = useState(initialFilters.term)
  const [location, setLocation] = useState(initialFilters.location)
  const [resetedValues, setResetedValues] = useState(false)
  const [workplaceType, setWorkplaceType] = useState(
    JSON.parse(JSON.stringify(initialFilters.workplaceType))
  )
  const [employmentType, setEmploymentType] = useState(
    JSON.parse(JSON.stringify(initialFilters.employmentType))
  )
  const [experienceLevel, setExperienceLevel] = useState(
    JSON.parse(JSON.stringify(initialFilters.experienceLevel))
  )
  const [experienceLevelFilter, setExperienceLevelFilter] = useState(
    initialFilters.experienceLevelFilter
  )
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState(
    initialFilters.employmentTypeFilter
  )
  const [workplaceTypeFilter, setWorkplaceTypeFilter] = useState(
    initialFilters.workplaceTypeFilter
  )

  const { analytics: segment } = React.useContext(SegmentContext)

  const currentPage = useSelector(
    (state: any) => state.jobListings.jobsPage.allJobListingsCurrentPage
  )

  const [page, setPage] = React.useState(
    currentPage > 1 &&
      ((action !== "POP" && history.action === "PUSH") ||
        (action === "POP" && history.action === "POP"))
      ? currentPage
      : 1
  )

  const jobs = useSelector(
    (state: any) => state.jobListings.jobsPage.allJobListings
  )

  const promotedJobs = useSelector(
    (state: any) => state.jobListings.promotedJobListings
  )

  const jobsCount = useSelector(
    (state: any) => state.jobListings.jobsPage.jobListingsCount
  )

  const fetchedPages = useSelector(
    (state: RootState) => state.jobListings.jobsPage.fetchedPages
  )

  const [totalPages, setTotalPages] = useState<number>()

  useEffect(() => {
    if (["FETCH", "SEARCH"].includes(action)) {
      setDataBound(false)
      setTotalPages(undefined)
    }
  }, [action, setTotalPages])

  const hasMoreDefaultValue = jobs.length < jobsCount

  const [hasMore, setHasMore] = useState(hasMoreDefaultValue)

  const handleChangeAction = (action: string) => {
    setAction(action)
    if (action === "FETCH") {
      setPage(1)
    }
  }

  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const handleChangeFilterBy = async (value: string) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setFilterBy(value)
  }

  const handleChangeWorkplaceType = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setWorkplaceType(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setWorkplaceTypeFilter(tempFilter)
    setPage(1)
  }

  const handleChangeExperienceLevel = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setExperienceLevel(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setExperienceLevelFilter(tempFilter)
    setPage(1)
  }

  const handleChangeEmploymentType = (value: MultiActionType[]) => {
    handleChangeAction("FETCH")
    setCheckCount(0)
    setEmploymentType(value)
    let tempFilter = ""
    value.forEach((item) => {
      if (item.active)
        tempFilter += tempFilter ? "," + item.action : item.action
    })

    setEmploymentTypeFilter(tempFilter)
    setPage(1)
  }

  const handleTermChanged = (term: string) => setTerm(term)
  const handleLocationChanged = (location: string) => setLocation(location)

  const handleSearchSubmit = async (
    newLocation?: string,
    newSearch?: string
  ): Promise<void> => {
    try {
      setAction("SEARCH")
      setHasMore(true)
      setPage(1)
      setCheckCount(0)
      const { type, payload } = await dispatch(
        fetchJobListing({
          page: 0,
          perPage,
          tag,
          location: newLocation ? newLocation : location,
          search: newSearch ? newSearch : term,
          workplaceType: workplaceType,
          employmentType: employmentType,
          experienceLevel: experienceLevel,
          experienceLevelFilter: experienceLevelFilter,
          employmentTypeFilter: employmentTypeFilter,
          workplaceTypeFilter: workplaceTypeFilter,
          requestOrigin: RequestOrigin.JOBS,
        })
      )
      if (fetchJobListing.fulfilled.type === type) {
        setTotalPages(Math.ceil(payload.count / perPage))
        if (payload.data.length >= payload.count) setHasMore(false)
        setDataBound(true)

        if (page === 1) loadPromotedJobs(newLocation, newSearch)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangePagination = (value: number) => {
    handleChangeAction("PAGINATE")
    setPage(value)
  }

  const handleClearFilters = () => {
    handleChangeAction("FETCH")
    setTerm("")
    setLocation("")
    setWorkplaceType(JSON.parse(JSON.stringify(initialWorkplaceType)))
    setEmploymentType(JSON.parse(JSON.stringify(initialEmploymentType)))
    setExperienceLevel(JSON.parse(JSON.stringify(initialExperienceLevel)))
    setWorkplaceTypeFilter("")
    setExperienceLevelFilter("")
    setEmploymentTypeFilter("")
    setPage(1)
    setResetedValues(true)
    setHasMore(true)
    setCheckCount(0)

    setTimeout(() => {
      setResetedValues(false)
    }, 1000)
  }

  const [dataBound, setDataBound] = useState<boolean>(false)

  const loadJobs = useCallback(async () => {
    try {
      if (history.action === "POP" && action === "POP") {
        setDataBound(true)
      }

      const fulfilledCalls: boolean[] = []
      if (
        (history.action !== "POP" ||
          action === "FETCH" ||
          action === "PAGINATE" ||
          jobs.length === 0) &&
        action !== "SEARCH"
      ) {
        setHasMore(true)
        setDataBound(false)
        const { type, payload } = await dispatch(
          fetchJobListing({
            page,
            perPage,
            location,
            search: term,
            tag,
            sortBy: filterBy,
            workplaceType: workplaceType,
            employmentType: employmentType,
            experienceLevel: experienceLevel,
            experienceLevelFilter: experienceLevelFilter,
            employmentTypeFilter: employmentTypeFilter,
            workplaceTypeFilter: workplaceTypeFilter,
            requestOrigin: RequestOrigin.JOBS,
          })
        )

        fulfilledCalls.push(fetchJobListing.fulfilled.type === type)
        if (fetchJobListing.fulfilled.type === type) {
          setTotalPages(Math.ceil(payload.count / perPage))
          setCheckCount(checkCount + payload.data.length)
          if (payload.data.length + checkCount >= payload.count) {
            setHasMore(false)
          }

          if (page === 1) loadPromotedJobs()

          setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true
          }, 0)
        }
      }
      if (fulfilledCalls.length === 2 && !fulfilledCalls.includes(false)) {
        setDataBound(true)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [page, filterBy, experienceLevel, employmentType, workplaceType])

  const loadPromotedJobs = async (newLocation?: string, newSearch?: string) => {
    try {
      await dispatch(
        fetchPromotedJobs({
          location: newLocation ? newLocation : location,
          search: newSearch ? newSearch : term,
          tag,
          experienceLevelFilter: experienceLevelFilter,
          employmentTypeFilter: employmentTypeFilter,
          workplaceTypeFilter: workplaceTypeFilter,
        })
      )
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }

  useEffect(() => {
    loadJobs()
  }, [loadJobs])

  useEffect(() => {
    if (segment) {
      segment.track({
        type: "track",
        event: "Job List Viewed",
        userId: isAuthenticated ? profile?.connectionId : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment])

  useEffect(() => {
    return history.listen((loc) => {
      const params = new URLSearchParams(loc.search.substring(1))
      const newLocation = params.get("location")
      const newSearch = params.get("search")

      if (newSearch) {
        handleTermChanged(newSearch)
        handleLocationChanged("")
        handleSearchSubmit("", newSearch)
        window.scrollTo(0, 0)
      }
      if (newLocation) {
        handleLocationChanged(newLocation)
        handleTermChanged("")
        handleSearchSubmit(newLocation, "")
        window.scrollTo(0, 0)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const getPageTitle = () => {
    if (currentFilter) {
      const defaultTitle = "Architecture Jobs - Spectacular"
      let title = ""

      if (currentFilter.search) {
        title += `${capitalize(currentFilter.search)} `
      }

      if (currentFilter.location) {
        title += `${capitalize(currentFilter.location)} `
      }

      if (currentFilter.tag) {
        title += `${capitalize(currentFilter.tag)} `
      }

      if (currentFilter.employmentTypeFilter) {
        title += `${capitalize(currentFilter.employmentTypeFilter)} `
      }

      if (currentFilter.experienceLevelFilter) {
        title += `${capitalize(currentFilter.experienceLevelFilter)} `
      }

      if (currentFilter.workplaceTypeFilter) {
        title += `${capitalize(currentFilter.workplaceTypeFilter)} `
      }

      if (title) {
        return `${title} ${defaultTitle}`
      }
    }

    return "Find Architecture Jobs - Spectacular"
  }

  return (
    <>
      <RouteChangeTracker screenTitle={`Jobs`} classTitle="Jobs" />
      <Helmet>
        <meta name="keywords" content={SEOTagsJobs.join(",")} />
        <meta name="title" content={getPageTitle()} />
        <meta
          name="description"
          content={
            "Search exclusive job listings from top architecture firms around the United States. Build a portfolio and apply for jobs all in one place."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={
            "Search exclusive job listings from top architecture firms around the United States. Build a portfolio and apply for jobs all in one place."
          }
        />
        <meta property="og:site_name" content={"Spectacular Jobs"} />
        <meta property="og:locale" content="en_US" />
        <title>{getPageTitle()}</title>
      </Helmet>
      <View
        dataBound={dataBound}
        jobs={jobs}
        promotedJobs={promotedJobs}
        term={term}
        location={location}
        filterBy={filterBy}
        jobsCount={jobsCount}
        page={page}
        perPage={perPage}
        fetchedPages={fetchedPages}
        totalPages={totalPages}
        profile={profile}
        workplaceType={workplaceType}
        experienceLevel={experienceLevel}
        employmentType={employmentType}
        resetedValues={resetedValues}
        hasMore={hasMore}
        onTermChanged={handleTermChanged}
        onLocationChanged={handleLocationChanged}
        handleChangeFilterBy={handleChangeFilterBy}
        handleSearchSubmit={handleSearchSubmit}
        handleChangePagination={handleChangePagination}
        handleChangeWorkplaceType={handleChangeWorkplaceType}
        handleChangeExperienceLevel={handleChangeExperienceLevel}
        handleChangeEmploymentType={handleChangeEmploymentType}
        handleClearFilters={handleClearFilters}
        handleChangeHasMore={handleChangeHasMore}
      />
    </>
  )
}

export default JobsPage
