import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const IconContainer = styled(Box)<{ $isActive: boolean }>`
  display: flex;
  flex-flow: row;
  cursor: pointer;
  justify-content: center;

  && p {
    font-size: 12px;
    margin-top: 5px;
    color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#717171")};
  }
`

export const Icon = styled.img`
  height: 20px;
`

export const CountLabel = styled.span`
  font-size: 12px;
  color: ${colors.slate};
  font-family: Public-Sans;
  line-height: 12px;
  margin-top: 2px;
`

export const IconFlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
`

export const CounterFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
`

export const DialogArea = styled(Dialog)`
  min-height: 100vh;
`

export const DialogBox = styled(Box)`
  overflow-x: hidden;
  width: 410px;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px 16px 16px;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const DialogHeader = styled(Box)`
  border-bottom: 1px solid #c4c4c4;
  text-align: center;
  padding-bottom: 12px;

  && img {
    height: 32px;
  }
`
export const DialogTitle = styled(Box)`
  font-size: 20px;
  margin-top: 5px;
`

export const DialogBody = styled(Box)`
  padding: 12px 0;
`

export const DialogLine1 = styled(Typography)`
  font-size: 16px;
  color: #717171;
  line-height: 22px;
`

export const DialogLine2 = styled(Typography)`
  font-size: 16px;
  color: #717171;
  line-height: 22px;
  margin-top: 10px;
`

export const DialogButtonBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  flex-flow: column;
  gap: 10px;

  && button {
    width: 100% !important;
  }
`

export const CloseDialogButton = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
