import React from "react"
import {
  Grid,
  Container,
  Typography,
  Box,
  NativeSelect,
  InputLabel,
} from "@material-ui/core"
import AdminIndividualProfileForm from "../../../components/AdminIndividualProfileForm"
import AdminGroupProfileForm from "../../../components/AdminGroupProfileForm"
import { ProfileType } from "../../../redux/profile"

type Props = {
  profileType: string
  onProfileTypeChanged: (event: React.ChangeEvent<{ value: unknown }>) => void
}

const View = ({ profileType, onProfileTypeChanged }: Props) => {
  return (
    <Container>
      <Grid container justify="center">
        <Grid item container sm={6}>
          <Grid item sm={12}>
            <Box marginBottom={2}>
              <Typography variant="h3">Create new profile</Typography>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box marginBottom={2}>
              <InputLabel htmlFor="profile-type">Profile type</InputLabel>
              <NativeSelect
                value={profileType}
                onChange={onProfileTypeChanged}
                inputProps={{
                  name: "type",
                  id: "profile-type",
                }}
              >
                <option value={ProfileType.INDIVIDUAL}>Individual</option>
                <option value={ProfileType.GROUP}>group</option>
              </NativeSelect>
            </Box>
          </Grid>
          {profileType === ProfileType.INDIVIDUAL && (
            <AdminIndividualProfileForm />
          )}
          {profileType === ProfileType.GROUP && <AdminGroupProfileForm />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default View
