import React, { useEffect, useState } from "react"

import View from "./View"
import { useAppDispatch } from "../../redux/configureStore"
import { getProjectTagSuggestions } from "../../redux/projects"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/rootReducer"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  initialTags?: string[]
  onChange: (values: string[]) => void
}

const ProjectTags = ({ initialTags, onChange }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<string>("")
  const [tags, setTags] = useState<string[]>(initialTags || [])
  const [displayError, setDisplayError] = useState<boolean>(false)
  const suggestions = useSelector(
    (state: RootState) => state.projects.tagSuggestions
  )

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getProjectTagSuggestions({ auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth])

  const saveTag = async (tags: string[]) => {
    setTags(tags)
    onChange(tags)
    setDisplayError(false)
  }

  const onInputChange = (value: string) => setValue(value)

  const onAdd = (newTag: string) => {
    saveTag([...tags, newTag])
    setValue("")
  }

  const onDelete = (tagToDelete: string) =>
    saveTag(tags.filter((credential: string) => credential !== tagToDelete))

  return (
    <View
      tags={tags}
      value={value}
      onEnteredPressed={onAdd}
      onDeletedPressed={onDelete}
      displayError={displayError}
      onInputChange={onInputChange}
      suggestions={suggestions || []}
    />
  )
}

export default ProjectTags
