import React from "react"
import { Box, Link, Typography } from "@material-ui/core"
import MUIEditIcon from "@material-ui/icons/Edit"
import MUIDeleteIcon from "@material-ui/icons/Delete"
import MUISaveIcon from "@material-ui/icons/Save"
import { Form } from "formik"
import styled from "styled-components"
import theme, { colors } from "../../theme"
import BorderUpDialog from "../../images/innovation-awards/dialog-border-up.png"
import BorderDownDialog from "../../images/innovation-awards/dialog-border-down.png"

export const SaveIcon = styled(MUISaveIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${colors.dark};
`

export const EditIcon = styled(MUIEditIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${colors.dark};
`

export const DeleteIcon = styled(MUIDeleteIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${colors.dark};
`

export const IconOverlayRight = styled.div`
  top: ${theme.spacing(1)}px;
  right: ${theme.spacing(1)}px;
  z-index: 4;
  position: absolute;
`

export const IconOverlayLeft = styled.div`
  top: ${theme.spacing(1)}px;
  left: ${theme.spacing(1)}px;
  z-index: 4;
  position: absolute;
`

export const TextOverlayContainer = styled.div`
  height: 24px;
  display: flex;
  text-align: center;
  padding: 0.25em 0.5em;
  font-size: 0.875em;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
`

export const ListItemOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.666667%;
  position: relative;
  background: ${colors.lightGray};
  border-color: ${colors.gray};
  border-width: 1px;
  border-style: solid;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
`

export const ImageThumbnail = styled.img<{ auto: boolean }>`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
`

export const ImageErrorList = styled.li`
  color: red;
  padding: 0.2rem;
`

export const ProjectForm = styled(Form)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`

export const FormStyled = styled(Form)`
  max-width: 362px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.8rem;
  ${theme.breakpoints.up("sm")} {
    max-width: 800px;
  }
`

export const ButtonContainer = styled.div<{ isFirstStep: boolean }>`
  margin-top: 2.5rem;
  display: flex;
  justify-content: ${({ isFirstStep }) =>
    isFirstStep ? "flex-end" : "space-between"};
  align-items: center;
  width: 100%;
`

export const SlateLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  padding-bottom: 2px;
  color: ${colors.slate};
  cursor: text;
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
`

export const StyledLinkBox = styled(Box)`
  display: inline;
  font-family: Public-Sans;
  a {
    border-bottom: solid 0.5px ${colors.dark};
  }
`

export const Title = styled(Typography)`
  font-size: 16px;
  margin-bottom: -12px;

  && span {
    color: #ff0000;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`
export const DatePickerBox = styled(Box)`
  && input {
    padding: 0 12px !important;
    height: 48px !important;
  }
`

export const DialogBgBox = styled(Box)`
  box-sizing: border-box;
  background: url(${BorderUpDialog}) top left no-repeat,
    url(${BorderDownDialog}) bottom right no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  min-height: 460px;
  padding: 40px 40px;

  ${theme.breakpoints.down("xs")} {
    padding: 40px 20px;
  }
`

export const ProjectWizardCopy = styled(Typography)`
  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
  }
`

export const FormSpacingBox = styled(Box)`
  padding-top: 27px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 20px;

    && button {
      font-size: 14px !important;
    }
  }
`
