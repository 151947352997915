import React from "react"
import {
  RequestDialog,
  RequestDialogTitle,
  RequestDialogBox,
  RequestDialogLabel,
} from "./Styles"
import { Box, Button } from "@material-ui/core"

import "./fix-dialog.css"

export type Props = {
  onClose: () => void
  onSubmit: () => void
  open: boolean
  filterBy: string
  onSetSortBy: (value: string) => void
}

const View = ({ open, filterBy, onClose, onSubmit, onSetSortBy }: Props) => {
  return (
    <RequestDialog open={open} onClose={onClose} className={"FilterDialog"}>
      <RequestDialogBox>
        <RequestDialogTitle style={{ paddingBottom: "8px" }}>
          Sort search by:
        </RequestDialogTitle>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("score")}
          className={filterBy === "score" ? "activeSortBy" : ""}
        >
          Most appreciated
        </RequestDialogLabel>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("likes")}
          className={filterBy === "likes" ? "activeSortBy" : ""}
        >
          Most liked
        </RequestDialogLabel>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("comments")}
          className={filterBy === "comments" ? "activeSortBy" : ""}
        >
          Most discussed
        </RequestDialogLabel>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("nominations")}
          className={filterBy === "nominations" ? "activeSortBy" : ""}
        >
          Most nominated
        </RequestDialogLabel>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("createdAt")}
          className={filterBy === "createdAt" ? "activeSortBy" : ""}
        >
          Recently created
        </RequestDialogLabel>
        <RequestDialogLabel
          style={{ padding: "8px 20px" }}
          onClick={() => onSetSortBy("recentlyUpdated")}
          className={filterBy === "recentlyUpdated" ? "activeSortBy" : ""}
        >
          Recently updated
        </RequestDialogLabel>
        <Box
          paddingRight={2.5}
          paddingLeft={2.5}
          paddingTop={3}
          style={{ marginTop: "auto" }}
        >
          <Button
            style={{
              width: "100%",
              height: "48px",
              fontSize: "14px",
              fontWeight: 700,
            }}
            color="primary"
            size="small"
            type="submit"
            onClick={onSubmit}
          >
            Done
          </Button>
        </Box>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
