import React from "react"
import { Grid, Box, Typography } from "@material-ui/core"
import { FormikContextType, useFormikContext } from "formik"

import { StyledImageList } from "../../components/StyledDragnDrop"
import ProjectDeleteLink from "../../components/ProjectDeleteLink"
import ImageCreditsDialog from "../../components/ImageCreditsDialog"
import { StyledDropzone, RejectedFile } from "../../components/StyledDropzone"

import { Image as ImageType, Project } from "../../redux/projects"

import {
  StyledLink,
  StyledLinkBox,
  ImageErrorList,
  ImageThumbnail,
  ListItemOverlay,
  IconOverlayLeft,
  TextOverlayContainer,
  ImageThumbnailContainer,
} from "./styles"
import { WizardStep, StepProps } from "./Wizard"
import { ProjectWizardCopy } from "../ProjectWizardPage/styles"

interface Props extends StepProps {
  project: Project
}

const Step1: React.FC<Props> = ({ project, onCancel }) => {
  const { values, setFieldValue }: FormikContextType<Project> =
    useFormikContext()
  const [idx, setIdx] = React.useState(0)
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isCompressing, setIsCompressing] = React.useState(false)
  const [isConverting, setIsConverting] = React.useState(false)
  const [errors, setErrors] = React.useState<RejectedFile[]>([])
  const [removedImages, setRemovedImages] = React.useState([])

  const nextIndex = (idx + 1) % values.images!.length
  const prevIndex = (idx + values.images!.length - 1) % values.images!.length

  const handleImagesChange = (
    newImages: ImageType[],
    errors?: RejectedFile[]
  ) => {
    setErrors(errors ? errors : [])

    setFieldValue("images", newImages)
  }

  const handleImageListChange = (
    newImages: ImageType[],
    removed?: string[]
  ) => {
    setErrors([])

    if (removed && removed.length > 0) {
      handleRemovedImages(removed)
      setFieldValue("removedImages", removedImages)
    }

    setFieldValue("images", newImages)
  }

  const handleRemovedImages = (removed: string[]) => {
    const newRemovedImages: any = removedImages
    newRemovedImages.push(removed)
    setRemovedImages(newRemovedImages)
  }

  const handleIsCompressing = (isCompressing: boolean) => {
    setIsCompressing(isCompressing)
  }

  const handleIsConverting = (isConverting: boolean) => {
    setIsConverting(isConverting)
  }

  const handleCancel = () => onCancel()

  const handleEdit = (imageOrder: number) => {
    setIdx(values.images!.findIndex((image) => image.order === imageOrder))
    setIsDialogOpen(true)
  }

  window.addEventListener("turbolinks:before-render", () => {
    const highestTimeoutId = setTimeout(function () {})
    for (let i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i)
    }
  })

  return (
    <WizardStep>
      <>
        <ImageCreditsDialog
          index={idx % values.images!.length}
          isOpen={isDialogOpen}
          images={values.images!}
          onClose={() => setIsDialogOpen(false)}
          onChange={(newImages) => setFieldValue("images", newImages)}
          onEditImage={(removed) => {
            handleRemovedImages(removed)
            setFieldValue("removedImages", removedImages)
          }}
          onConfirm={(newImages) => {
            setFieldValue("images", newImages)
            setIsDialogOpen(false)
          }}
          onDelete={(imageOrder) => {
            const newImages = Array.from(values.images!)
            newImages.splice(
              values.images!.findIndex((image) => image.order === imageOrder),
              1
            )
            setFieldValue("images", newImages)
          }}
          onNav={(dir) =>
            dir === "next" ? setIdx(nextIndex) : setIdx(prevIndex)
          }
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography tabIndex={0} variant="h3">
            Edit Project
          </Typography>
          <Box>
            <StyledLinkBox onClick={handleCancel}>
              <StyledLink>Cancel</StyledLink>
            </StyledLinkBox>
          </Box>
        </Box>
        <ProjectDeleteLink project={project} />
        <Box>
          <ProjectWizardCopy>
            Upload your design. The first image will be used as the thumbnail
            and cover image for your project. Add credits and captions to each
            image by clicking on the Edit icon. In order to be considered for
            feature opportunities, we request at least one orthographic image
            (elevation, plan, section, diagram, etc).
          </ProjectWizardCopy>
        </Box>

        <Box>
          {errors ? (
            <ul>
              {errors.map(({ file, errors }) => (
                <ImageErrorList key={`file-${file.name}`}>
                  {errors[0].message}
                </ImageErrorList>
              ))}
            </ul>
          ) : null}
        </Box>

        {values.images && values.images.length > 0 ? (
          <Grid container justify="center">
            <ImageThumbnailContainer>
              <ListItemOverlay>
                <IconOverlayLeft>
                  <TextOverlayContainer>Cover Photo</TextOverlayContainer>
                </IconOverlayLeft>
              </ListItemOverlay>
              <ImageThumbnail
                auto={
                  values.images[0].dimensions &&
                  values.images[0].dimensions!.height &&
                  values.images[0].dimensions!.height! > 600
                    ? values.images[0].dimensions!.width! >= 1000
                      ? true
                      : false
                    : true
                }
                src={
                  values.images[0].url
                    ? values.images[0].url
                    : values.images[0].largeImageUrl
                }
                alt={`img-${values.images[0].order}`}
              />
            </ImageThumbnailContainer>
          </Grid>
        ) : null}

        {values.images && values.images.length === 0 ? (
          <Box>
            <StyledDropzone
              onChange={handleImagesChange}
              onCompress={handleIsCompressing}
              onConvert={handleIsConverting}
              images={values.images ? values.images : []}
            />
          </Box>
        ) : null}

        <Box marginTop={2}>
          <StyledImageList
            onEdit={handleEdit}
            images={values.images!}
            onChange={handleImageListChange}
            onSelect={(_) => {}}
          />
        </Box>
        {values.images && values.images.length > 0 ? (
          <Grid container justify="center">
            <StyledDropzone
              compact={true}
              onChange={handleImagesChange}
              onCompress={handleIsCompressing}
              onConvert={handleIsConverting}
              images={values.images ? values.images : []}
            />
          </Grid>
        ) : null}
        {isCompressing ? (
          <Box marginTop={1} textAlign={"center"}>
            <Typography>Compressing Image...</Typography>
          </Box>
        ) : null}

        {isConverting ? (
          <Box marginTop={1} textAlign={"center"}>
            <Typography>Converting File...</Typography>
          </Box>
        ) : null}
      </>
    </WizardStep>
  )
}

Step1.defaultProps = {}

export default Step1
