import styled from "styled-components"
import { Dialog, Typography, Box } from "@material-ui/core"
import { colors } from "../../theme"

export const RequestDialog = styled(Dialog)`
  min-height: 100vh;
  z-index: 2147483098 !important;
`

export const HeaderBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #c4c4c4;
`

export const HeaderItems = styled(Box)`
  display: flex;
  gap: 32px;
`

export const HeaderItem = styled(Box)<{ $isActive?: boolean }>`
  font-family: Public-Sans-Bold;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 0;

  ${({ $isActive }) =>
    $isActive
      ? `
    color:#fff;
    background-color:#2d2d2d;
    border-radius: 37px;
    padding: 4px 8px;
  `
      : ""}
`

export const HeaderTitle = styled(Typography)`
  font-size: 12px;
`

export const RequestDialogLabel = styled(Box)`
  font-size: 14px;
  color: ${colors.mediumLightGray};
`

export const RequestDialogBox = styled(Box)`
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-flow: column;

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
`

export const RequestDialogSwitch = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`

export const RequestDialogCloseIcon = styled(Box)`
  text-align: right;
  padding-right: 20px;
  height: 16px;
`

export const FiltersBox = styled(Box)`
  margin-top: 16px;
`

export const FiltersItems = styled(Box)`
  margin-top: 8px;
`

export const FiltersTitle = styled(Typography)`
  color: #979797;
  font-size: 14px;
  padding: 0 20px;
`

export const FiltersItem = styled.div<{ $isActive?: boolean }>`
  color: #2d2d2d;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 20px;

  ${({ $isActive }) =>
    $isActive
      ? `
    background-color:#DADADA;
    font-family: Public-Sans-Bold;
  `
      : ""}
`

export const DividerBox = styled(Box)`
  padding: 16px 20px;
`

export const Diviver = styled(Box)`
  border-top: 1px solid #c4c4c4;
  width: 100%;
`
