import React from "react"
import { Divider } from "@material-ui/core"
import { Buttress, Container } from "./styles"

import ButtressImage from "../../images/ui-buttress.svg"

const View = () => {
  return (
    <Container className="buttress-divider" aria-hidden>
      <Buttress src={ButtressImage} alt="" />
      <Divider />
    </Container>
  )
}

export default View
