import React from "react"

import View from "./View"
import { Post } from "../../redux/posts"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"

type Props = {
  post: Post
  displayEdit?: boolean
  handleDeleteDialogOpen: (value: boolean) => void
  handleEditDialogOpen: (value: boolean) => void
  handleFlagDialogOpen: (value: boolean) => void
}

const PostDotMenu = ({
  post,
  displayEdit,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
  handleFlagDialogOpen,
}: Props) => {
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const handleDelete = async () => {
    handleDeleteDialogOpen(true)
  }

  const handleEdit = async () => {
    handleEditDialogOpen(true)
  }

  const handleReport = async () => {
    handleFlagDialogOpen(true)
  }

  return (
    <View
      post={post}
      authenticatedProfile={authenticatedProfile}
      displayEdit={displayEdit}
      onDelete={handleDelete}
      onEdit={handleEdit}
      onReport={handleReport}
    />
  )
}

export default PostDotMenu
