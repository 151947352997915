import React, { useState } from "react"
import { fetchProjects } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  onClose: () => void
  onSetHasMore: (value: boolean) => void
  onChangeAction: (value: string) => void
  handleSetAppliedSortBy: (value: string) => void
  open: boolean
  searchText?: string
  filterBy: string
}

const FilterProjectDialog = ({
  open,
  searchText,
  filterBy,
  onClose,
  onSetHasMore,
  onChangeAction,
  handleSetAppliedSortBy,
}: Props) => {
  const [sortByDialog, setSortByDialog] = useState(filterBy)

  const dispatch = useAppDispatch()

  const { user } = useAuth0()

  const handleClose = () => {
    onClose()
    setSortByDialog(filterBy)
  }

  const handleSetSortBy = (value: string) => {
    setSortByDialog(value)
  }
  const handleSubmit = async () => {
    onClose()
    onChangeAction("SEARCH")
    onSetHasMore(true)
    handleSetAppliedSortBy(sortByDialog)
    window.scrollTo(0, 0)
    const { type, payload } = await dispatch(
      fetchProjects({
        email: user?.email,
        page: 1,
        perPage: 12,
        sortBy: sortByDialog,
        search: searchText,
        action: "SEARCH",
      })
    )

    if (fetchProjects.fulfilled.type === type) {
      if (payload.data.length >= payload.count) onSetHasMore(false)
    }
  }

  return (
    <View
      onClose={handleClose}
      onSetSortBy={handleSetSortBy}
      onSubmit={handleSubmit}
      open={open}
      filterBy={sortByDialog}
    />
  )
}

export default FilterProjectDialog
