import React, { useState } from "react"
import {
  addComment,
  CommentMention,
  deleteProject,
  Project,
} from "../../redux/projects"
import View from "./View"
import * as RouteConstants from "../../constants/routes"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import { fetchProfileByUsernameWithAuthorization } from "../../redux/profile"
import { useSelector } from "react-redux"
import { updateFeedState } from "../../redux/feed"

export type Props = {
  project: Project
  source?: string
  large?: boolean
  awards?: boolean
  hideComments?: boolean
  showFirmBadge?: boolean
}

const ProjectCard = ({
  project,
  source,
  large,
  awards,
  hideComments,
  showFirmBadge,
}: Props) => {
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated
  const dispatch = useAppDispatch()

  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isFlagDialogOpen, setIsFlagDialogOpen] = React.useState(false)
  const [isPostingComment, setIsPostingComment] = useState(false)

  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )

  const isPublicProfile = authenticatedProfile?.username !== project?.username

  const goToProfile = () => {
    return `${RouteConstants.PROFILE_CONTAINER}/${project.username}`
  }

  const handlePostComment = async (
    text: string,
    parentCommentId?: string,
    mentions?: CommentMention[]
  ) => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      setIsPostingComment(true)
      const { type, payload } = await dispatch(
        addComment({ id: project.id!, auth, text, parentCommentId, mentions })
      )
      if (addComment.fulfilled.type === type) {
        setIsPostingComment(false)

        await dispatch(
          updateFeedState({
            type: "project",
            id: project.id!,
            action: "comment",
            comment: payload,
            parentCommentId,
            mentions,
          })
        )
      }
    } catch (error) {
      console.error(error)
      setIsPostingComment(false)
    }
  }

  const handleDialogOpen = async (value: boolean) => {
    setIsDialogOpen(value)
  }

  const handleFlagDialogOpen = async (value: boolean) => {
    setIsFlagDialogOpen(value)
  }

  const handleDeleteProject = async () => {
    try {
      const { type } = await dispatch(deleteProject({ auth, id: project.id! }))
      if (deleteProject.fulfilled.type === type) {
        const { type } = await dispatch(
          fetchProfileByUsernameWithAuthorization({
            auth,
            username: (project as any).username,
          })
        )

        if (fetchProfileByUsernameWithAuthorization.fulfilled.type === type)
          setIsDialogOpen(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <View
      project={project}
      goToProfile={goToProfile}
      handlePostComment={handlePostComment}
      source={source}
      large={large}
      isPostingComment={isPostingComment}
      awards={awards}
      hideComments={hideComments}
      isDialogOpen={isDialogOpen}
      isFlagDialogOpen={isFlagDialogOpen}
      isPublicProfile={isPublicProfile}
      isAuthenticated={isAuthenticated}
      handleDialogOpen={handleDialogOpen}
      handleFlagDialogOpen={handleFlagDialogOpen}
      handleDeleteProject={handleDeleteProject}
      showFirmBadge={showFirmBadge}
      authenticatedProfile={authenticatedProfile}
    />
  )
}

export default ProjectCard
