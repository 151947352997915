import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"

import {
  Profile,
  confirmConnectionRequest,
  connectionRequest,
  withdrawConnectionRequest,
  sendNotificationEmailAndRemoveSuggestion,
} from "../../redux/profile"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import { updateSuggestionState } from "../../redux/connections"

type Props = {
  profile: Profile
  children?: string
  color?: string
  style?: any
  action?: string
  onClose?: () => void
  handleOpenDialog?: () => void
  isSuggestion?: boolean
}

const ConnectButton = ({
  profile,
  action,
  style,
  children,
  color,
  onClose,
  handleOpenDialog,
  isSuggestion,
}: Props) => {
  const { isAuthenticated } = useAuth0()
  const auth = useAuth0()

  const dispatch = useAppDispatch()
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )
  const [connectedState, setConnectedState] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (authenticatedProfile?.connections?.connections) {
      const connected = authenticatedProfile?.connections?.connections.filter(
        (connection) => {
          return connection.connectionId === profile.connectionId
        }
      )

      if (connected?.length! > 0) {
        setConnectedState(connected[0].status)
      } else {
        const requests = authenticatedProfile?.connections?.requests.filter(
          (request) => {
            return request.profileId === profile.connectionId
          }
        )

        if (requests?.length! > 0) setConnectedState("request-received")
        else setConnectedState("")
      }
    }
  }, [connectedState, authenticatedProfile, profile])

  const handleConnect = async () => {
    if (!isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    if (action === "connect") {
      try {
        setIsLoading(true)
        const profileId = authenticatedProfile?.connectionId
        const connectionId = profile.connectionId
        const isAdmin = authenticatedProfile?.isAdmin

        const { type } = await dispatch(
          connectionRequest({ auth, profileId, connectionId, isAdmin })
        )
        setIsLoading(false)

        if (connectionRequest.fulfilled.type === type) {
          setConnectedState("requested")
          if (onClose) onClose()

          await dispatch(
            sendNotificationEmailAndRemoveSuggestion({
              auth,
              profileId: connectionId!,
              templateName: "PROFILE_CONNECTION_REQUEST",
              isSuggestion,
            })
          )

          if (isSuggestion) {
            await dispatch(
              updateSuggestionState({
                profileId: connectionId!,
              })
            )
          }

          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } catch (error) {
        setIsLoading(false)
        return Promise.reject(error)
      }
    } else {
      if (handleOpenDialog) handleOpenDialog()
    }
  }

  const handleWithdraw = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        withdrawConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (withdrawConnectionRequest.fulfilled.type === type) {
        setConnectedState("")
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        confirmConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (confirmConnectionRequest.fulfilled.type === type) {
        setConnectedState("accepted")

        await dispatch(
          sendNotificationEmailAndRemoveSuggestion({
            auth,
            profileId: connectionId!,
            templateName: "PROFILE_CONNECTION_ACCEPTED",
          })
        )
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  return (
    <>
      {authenticatedProfile &&
      authenticatedProfile?.connectionId !== profile.connectionId ? (
        <View
          connectedState={connectedState}
          isLoading={isLoading}
          onConnect={handleConnect}
          onWithdraw={handleWithdraw}
          onConfirm={handleConfirm}
          color={color}
          children={children}
          style={style}
        />
      ) : !authenticatedProfile ? (
        <View
          connectedState={connectedState}
          isLoading={isLoading}
          onConnect={handleConnect}
          onWithdraw={handleWithdraw}
          onConfirm={handleConfirm}
          color={color}
          children={children}
          style={style}
        />
      ) : null}
    </>
  )
}

export default ConnectButton
