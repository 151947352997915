import React from "react"
import { Box, CircularProgress, Grid } from "@material-ui/core"
import { Profile } from "../../redux/profile"
import { Button } from "../../components/Button"

import {
  Avatar,
  ProjectThumbnail,
  Author,
  Title,
  CardBox,
  ContentBox,
  NetworkingBox,
  ImagesBox,
  ProjectThumbnailPlaceholder,
  LinkBox,
} from "./styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import FollowButtonList from "../../components/FollowButtonList"
import ConnectButton from "../../components/ConnectButton"

export type Props = {
  profile: Profile
  goToProject: (projectData: any) => string
  goToProfile: () => string
  goToEditProfile: () => void
  isCurrentProfile: boolean
  source?: string
  confirmButtons?: boolean
  isSuggestion?: boolean
  isLoading: boolean
  onConfirmConnection: () => void
  onDenyConnection: () => void
}

const View = ({
  profile,
  goToProject,
  goToProfile,
  goToEditProfile,
  isCurrentProfile,
  isSuggestion,
  source,
  confirmButtons,
  isLoading,
  onConfirmConnection,
  onDenyConnection,
}: Props) => {
  const name =
    profile.type === "group"
      ? profile.groupName!
      : profile.firstName! + " " + profile.lastName!

  const alt =
    profile?.type === "individual"
      ? `Network for architects ${profile?.firstName} ${profile?.lastName}`
      : `Network for architects ${profile?.groupName}`

  let jobTitle = profile?.jobTitle
  if (profile?.jobTitle && profile.jobTitle.includes("Other: ")) {
    jobTitle = profile.jobTitle.replace("Other: ", "")
  }

  if (!jobTitle) jobTitle = ""

  return (
    <CardBox>
      <ContentBox>
        <LinkBox to={goToProfile}>
          <Avatar
            src={
              profile.smallImageUrl === undefined
                ? getRandomPlaceholder(profile.placeholderAvatar!)
                : (profile.smallImageUrl as string)
            }
            alt={alt}
          />
        </LinkBox>
        <Box paddingLeft={1} paddingRight={2}>
          <Author to={goToProfile()}>{name}</Author>
          <Title>
            {profile.type === "group" ? (
              <>
                {profile.companySize && (
                  <>Company Size: {profile.companySize}</>
                )}
              </>
            ) : (
              <>{jobTitle}</>
            )}
          </Title>
        </Box>
        <NetworkingBox>
          {confirmButtons ? (
            <>
              <Button
                onClick={onDenyConnection}
                children={
                  isLoading ? (
                    <CircularProgress size={16} style={{ color: "#2d2d2d" }} />
                  ) : (
                    <>Ignore</>
                  )
                }
                color={"lightBlue"}
              />
              <Button
                onClick={onConfirmConnection}
                children={
                  isLoading ? (
                    <CircularProgress size={16} style={{ color: "#fff" }} />
                  ) : (
                    <>Accept</>
                  )
                }
                color={"secondary"}
                style={{ marginLeft: "8px" }}
              />
            </>
          ) : (
            <>
              {isCurrentProfile ? (
                <Button
                  onClick={goToEditProfile}
                  children={"Edit profile"}
                  color={"primary"}
                />
              ) : (
                <>
                  <FollowButtonList profile={profile} />
                  {profile.type !== "group" && (
                    <ConnectButton
                      profile={profile}
                      style={{ marginLeft: "8px" }}
                      action={"connect"}
                      isSuggestion={isSuggestion}
                    />
                  )}
                </>
              )}
            </>
          )}
        </NetworkingBox>
      </ContentBox>
      <ImagesBox container spacing={1} direction="row">
        <Grid item xs={4} style={{ display: "flex" }}>
          {profile.projects![0] ? (
            <LinkBox
              to={goToProject(profile.projects![0])}
              style={{ display: "flex" }}
            >
              <ProjectThumbnail
                src={profile.projects![0].smallImageUrl}
                alt={`Network for architects ${profile.projects![0].name}`}
              />
            </LinkBox>
          ) : (
            <ProjectThumbnailPlaceholder />
          )}
        </Grid>
        <Grid item xs={4} style={{ display: "flex" }}>
          {profile.projects![1] ? (
            <LinkBox
              to={goToProject(profile.projects![1])}
              style={{ display: "flex" }}
            >
              <ProjectThumbnail
                src={profile.projects![1].smallImageUrl}
                alt={`Network for architects ${profile.projects![1].name}`}
              />
            </LinkBox>
          ) : (
            <ProjectThumbnailPlaceholder />
          )}
        </Grid>
        <Grid item xs={4} style={{ display: "flex" }}>
          {profile.projects![2] ? (
            <LinkBox
              to={goToProject(profile.projects![2])}
              style={{ display: "flex" }}
            >
              <ProjectThumbnail
                src={profile.projects![2].smallImageUrl}
                alt={`Network for architects ${profile.projects![2].name}`}
              />
            </LinkBox>
          ) : (
            <ProjectThumbnailPlaceholder />
          )}
        </Grid>
      </ImagesBox>
    </CardBox>
  )
}

export default View
