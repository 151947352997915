import React from "react"
import { Icon } from "@material-ui/core"
import MUIRichTextEditor from "mui-rte"
import { RawDraftContentState } from "draft-js"
import { convertToRaw } from "draft-js"

import ImageH2 from "../../images/h2.svg"
import ImageH3 from "../../images/h3.svg"

export type Props = {
  defaultValue?: any
  label: string
  maxLength?: number
  onBlur?: () => void
  onChange: (plainText: string, richText: string, event?: any) => void
}

const getDraftJSType = (type: string) => {
  let newType = type
  switch (type) {
    case "H1": {
      newType = "header-one"
      break
    }
    case "H2": {
      newType = "header-two"
      break
    }
    case "H3": {
      newType = "header-three"
    }
  }
  return newType
}

export const parseControls = (raw: RawDraftContentState) => {
  const result = Object.assign(raw, {})
  result.blocks = raw.blocks.map((block) => {
    return {
      ...block,
      type: getDraftJSType(block.type),
    }
  })
  return result
}

const View = ({ defaultValue, label, onChange, maxLength, onBlur }: Props) => {
  return (
    <MUIRichTextEditor
      label={label}
      defaultValue={defaultValue}
      maxLength={maxLength}
      inlineToolbar={true}
      controls={[
        "title",
        "bold",
        "italic",
        "underline",
        "bulletList",
        "numberList",
        "quote",
        "undo",
        "redo",
      ]}
      customControls={[
        {
          name: "h2",
          icon: (
            <Icon>
              <img src={ImageH2} height={22} width={22} alt="h2 header" />
            </Icon>
          ),
          type: "block",
          blockWrapper: <h2 aria-hidden />,
        },
        {
          name: "h3",
          icon: (
            <Icon>
              <img src={ImageH3} height={22} width={22} alt="h3 header " />
            </Icon>
          ),
          type: "block",
          blockWrapper: <h3 aria-hidden />,
        },
      ]}
      onBlur={onBlur}
      onChange={(event) => {
        onChange(
          event.getCurrentContent().getPlainText(),
          JSON.stringify(
            parseControls(convertToRaw(event.getCurrentContent()))
          ),
          event
        )
      }}
    />
  )
}

export default View
