import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { useApi } from "../hooks"
import { Profile } from "./profile"
import { ActionsType } from "../components/JobsDropDownMultiselect/View"
import { RootState } from "./rootReducer"

//#region types
export type JobListing = {
  id?: string
  title: string
  tags: string[]
  jobUrl?: string
  location: string
  summary: string
  status: string
  description?: string
  descriptionRtf?: string
  createdAt?: Date
  profile?: Profile
  profileId?: string
  employmentType: string
  workplaceType: string
  experienceLevel: string
  origin?: string
  isPromoted?: boolean
  descriptionHtml?: string
  applies: number
}

export type ApplicantsList = {
  applicants: Applicant[]
}

export type Applicant = {
  applyDate: Date
  profile: Profile
}

export type SearchFilter = {
  employmentType?: ActionsType[]
  employmentTypeFilter?: string
  experienceLevel?: ActionsType[]
  experienceLevelFilter?: string
  location?: string
  search?: string
  sortBy?: string
  status?: string
  tag?: string
  workplaceType?: ActionsType[]
  workplaceTypeFilter?: string
}

export type JobRequest = {
  website: string
  informations: string
  origin: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export enum RequestOrigin {
  DASHBOARD = "dashboardPage",
  JOBS = "jobsPage",
}

type JobStateByOrigin = {
  allJobListings: JobListing[]
  allJobListingsCurrentPage: number
  currentFilter: SearchFilter
  fetchedPages: number[]
  jobListings: JobListing[]
  jobListingsCount: number
}

type SliceState = {
  jobListingStatus: LoadingStatuses
  applicants: Applicant[]
  promotedJobListings: JobListing[]
  promotedJobListingsCount: number
  selectedJob?: JobListing
  selectedStatus: LoadingStatuses
  status: LoadingStatuses
  error: string | null | undefined
  tagSuggestions: string[]
  jobsPage: JobStateByOrigin
  dashboardPage: JobStateByOrigin
}
//#endregion

//#region api
type CreateJobListingPayload = {
  auth: Auth0ContextInterface
  jobListing: JobListing
}
export const createJobListing = createAsyncThunk<any, CreateJobListingPayload>(
  "jobListings/createJobListing",
  async ({ auth, jobListing }) => {
    let tags = jobListing.tags.map((tag) => tag.toLowerCase())
    jobListing.tags = tags
    jobListing.origin = window.location.origin
    return useApi(auth, "/jobs", {
      method: "POST",
      body: JSON.stringify(jobListing),
    }).then((res) => res.json())
  }
)

type AdminCreateJobListingPayload = {
  auth: Auth0ContextInterface
  jobListing: JobListing
}
export const adminCreateJobListing = createAsyncThunk<
  any,
  AdminCreateJobListingPayload
>("jobListings/adminCreateJobListing", async ({ auth, jobListing }) => {
  let tags = jobListing.tags.map((tag) => tag.toLowerCase())
  jobListing.tags = tags
  return useApi(auth, "/admin/jobs", {
    method: "POST",
    body: JSON.stringify(jobListing),
  }).then((res) => res.json())
})

type GetJobListingByIdPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const getJobListingById = createAsyncThunk<
  any,
  GetJobListingByIdPayload
>("jobListings/getJobListingById", async ({ auth, id }) => {
  return useApi(auth.isAuthenticated ? auth : null, `/jobs/${id}`, {
    method: "GET",
  }).then((res) => res.json())
})

export const openJobListingById = createAsyncThunk<
  any,
  {
    id: string
    auth: Auth0ContextInterface
  }
>("jobListings/openJobListingById", async ({ auth, id }) => {
  return useApi(auth, `/jobs/${id}/open`, {
    method: "GET",
  }).then((res) => res.text())
})

export const closeJobListingById = createAsyncThunk<
  any,
  {
    id: string
    auth: Auth0ContextInterface
  }
>("jobListings/closeJobListingById", async ({ auth, id }) => {
  return useApi(auth, `/jobs/${id}/close`, {
    method: "GET",
  }).then((res) => res.text())
})

type ApplyToJobListingByIdPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const applyToJobListingById = createAsyncThunk<
  any,
  ApplyToJobListingByIdPayload
>("jobListings/applyToJobListingById", async ({ auth, id }) => {
  return useApi(auth, `/jobs/apply/${id}`, {
    method: "POST",
    body: JSON.stringify({ origin: window.location.origin }),
  }).then((res) => res.text())
})

type SendEmailToJobListingByIdPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const sendEmailToJobListingById = createAsyncThunk<
  any,
  SendEmailToJobListingByIdPayload
>("jobListings/sendEmailToJobListingById", async ({ auth, id }) => {
  return useApi(auth, `/jobs/email/${id}`, {
    method: "POST",
    body: JSON.stringify({ origin: window.location.origin }),
  }).then((res) => res.text())
})

type FetchJobListingPayload = {
  page: number
  perPage?: number
  tag?: string
  location?: string
  search?: string
  filterProfile?: boolean
  sortBy?: string
  experienceLevel?: ActionsType[]
  employmentType?: ActionsType[]
  workplaceType?: ActionsType[]
  experienceLevelFilter?: string
  employmentTypeFilter?: string
  workplaceTypeFilter?: string
  action?: string
  profileId?: string
  requestOrigin: RequestOrigin
  status?: string
}
export const fetchJobListing = createAsyncThunk<any, FetchJobListingPayload>(
  "jobs/fetchJobListing",
  async ({
    page = 1,
    perPage = 10,
    tag,
    location,
    search = "",
    filterProfile = false,
    experienceLevel,
    employmentType,
    workplaceType,
    experienceLevelFilter,
    employmentTypeFilter,
    workplaceTypeFilter,
    sortBy,
    action,
    profileId,
    status,
  }) => {
    page = page > 0 ? (page -= 1) : 0
    let endpoint = `/jobs?skip=${page * perPage}&limit=${perPage}`
    if (profileId) endpoint += `&profileId=${profileId ?? ""}`
    if (status) endpoint += `&status=${status ?? ""}`
    if (tag) endpoint += `&tag=${tag ?? ""}`
    if (location) endpoint += `&location=${location ?? ""}`
    if (search) endpoint += `&search=${search ?? ""}`
    if (filterProfile) endpoint += `&filterProfile=true`
    if (experienceLevelFilter)
      endpoint += `&experienceLevel=${experienceLevelFilter ?? ""}`
    if (employmentTypeFilter)
      endpoint += `&employmentType=${employmentTypeFilter ?? ""}`
    if (workplaceTypeFilter)
      endpoint += `&workplaceType=${workplaceTypeFilter ?? ""}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type FetchPromotedJobsPayload = {
  tag?: string
  location?: string
  search?: string
  experienceLevelFilter?: string
  employmentTypeFilter?: string
  workplaceTypeFilter?: string
}
export const fetchPromotedJobs = createAsyncThunk<
  any,
  FetchPromotedJobsPayload
>(
  "jobs/fetchPromotedJobs",
  async ({
    tag,
    location,
    search,
    experienceLevelFilter,
    employmentTypeFilter,
    workplaceTypeFilter,
  }) => {
    let endpoint = `/jobs/promoted?search=${search ?? ""}`
    if (tag) endpoint += `&tag=${tag ?? ""}`
    if (location) endpoint += `&location=${location ?? ""}`
    if (experienceLevelFilter)
      endpoint += `&experienceLevel=${experienceLevelFilter ?? ""}`
    if (employmentTypeFilter)
      endpoint += `&employmentType=${employmentTypeFilter ?? ""}`
    if (workplaceTypeFilter)
      endpoint += `&workplaceType=${workplaceTypeFilter ?? ""}`
    return useApi(null, endpoint).then((res) => res.json())
  }
)

type UpdateJobListingPayload = {
  auth: Auth0ContextInterface
  jobListing: JobListing
}
export const updateJobListing = createAsyncThunk<any, UpdateJobListingPayload>(
  "jobListings/updateJobListing",
  async ({ auth, jobListing }) => {
    delete jobListing.profile
    let tags = jobListing.tags.map((tag) => tag.toLowerCase())
    jobListing.tags = tags
    return useApi(auth, `/jobs/${jobListing.id}`, {
      method: "PATCH",
      // Replace `undefined` to `null` because `undefined` values are removed by JSON.stringify
      body: JSON.stringify(jobListing, (k, v) => (v === undefined ? null : v)),
    }).then((_) => jobListing)
  }
)

type AdminUpdateJobListingPayload = {
  auth: Auth0ContextInterface
  jobListing: JobListing
}
export const adminUpdateJobListing = createAsyncThunk<
  any,
  AdminUpdateJobListingPayload
>("jobListings/adminUpdateJobListing", async ({ auth, jobListing }) => {
  //delete jobListing.profile
  let tags = jobListing.tags.map((tag) => tag.toLowerCase())
  jobListing.tags = tags
  return useApi(auth, `/admin/jobs/${jobListing.id}`, {
    method: "PATCH",
    // Replace `undefined` to `null` because `undefined` values are removed by JSON.stringify
    body: JSON.stringify(jobListing, (k, v) => (v === undefined ? null : v)),
  }).then((_) => jobListing)
})

type DeleteJobListingPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteJobListing = createAsyncThunk<any, DeleteJobListingPayload>(
  "experience/jobListings/deleteJobListing",
  async ({ auth, id }) => {
    return useApi(auth, `/jobs/${id}`, {
      method: "DELETE",
    }).then((res) => res.text())
  }
)

type GetJobTagsSuggestionsPayload = {
  auth: Auth0ContextInterface
}
export const getJobTagSuggestions = createAsyncThunk<
  any,
  GetJobTagsSuggestionsPayload
>("jobListings/getJobTagSuggestions", async ({ auth }) => {
  return useApi(auth, "/jobs/tags/suggestions", {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
})

type RequestJobsCreationPayload = {
  auth: Auth0ContextInterface
  jobRequest: JobRequest
}
export const requestJobsCreation = createAsyncThunk<
  any,
  RequestJobsCreationPayload
>("jobListings/jobsRequest", async ({ auth, jobRequest }) => {
  return useApi(auth, "/jobs/request", {
    method: "POST",
    body: JSON.stringify(jobRequest),
  }).then((res) => {
    return res.text()
  })
})

type ValidateJobUrlPayload = {
  auth: Auth0ContextInterface
  url: string
}
export const validateJobUrl = createAsyncThunk<any, ValidateJobUrlPayload>(
  "jobListings/validateUrl",
  async ({ auth, url }) => {
    return useApi(auth, "/jobs/validate/url", {
      method: "POST",
      body: JSON.stringify({ url: url }),
    }).then((res) => {
      return res.json()
    })
  }
)

type GetJobApplicantsPayload = {
  auth: Auth0ContextInterface
  jobId: string
}
export const getJobApplicants = createAsyncThunk<any, GetJobApplicantsPayload>(
  "jobs/applicants/get",
  async ({ auth, jobId }) => {
    return useApi(auth, `/jobs/applicants/${jobId}`, {
      method: "GET",
    }).then((res) => {
      return res.json()
    })
  }
)

export const clearSelectedJob = createAsyncThunk<any>(
  "jobListings/clearSelectedJob",
  async () => {
    return ""
  }
)
//#endregion

//#region slice
const initialOriginState: JobStateByOrigin = {
  allJobListings: [],
  allJobListingsCurrentPage: 1,
  currentFilter: {},
  fetchedPages: [],
  jobListings: [],
  jobListingsCount: 0,
}

const initialState: SliceState = {
  jobListingStatus: LoadingStatuses.Idle,
  promotedJobListings: [],
  applicants: [],
  promotedJobListingsCount: 0,
  tagSuggestions: [],
  status: LoadingStatuses.Idle,
  error: undefined,
  selectedJob: undefined,
  selectedStatus: LoadingStatuses.Idle,
  jobsPage: { ...initialOriginState },
  dashboardPage: { ...initialOriginState },
}

const filterResultsFromJobArray = (
  jobArray: JobListing[],
  deletedId: string
): { countRemoved: number; filteredArray: JobListing[] } => {
  const checkedIndexes = jobArray.map((job, i) =>
    job.id === deletedId ? i : -1
  )
  const indexesToRemove = checkedIndexes.filter((i) => i >= 0)
  const filteredArray = jobArray.filter(
    (job, i) => !indexesToRemove.includes(i)
  )
  const countRemoved = indexesToRemove.length * -1
  return { countRemoved, filteredArray }
}

export default createSlice({
  name: "jobListings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createJobListing.fulfilled, (state, action) => {
        state.dashboardPage.jobListings = [
          action.payload,
          ...state.dashboardPage.jobListings,
        ]
        state.jobsPage.jobListings = [
          action.payload,
          ...state.jobsPage.jobListings,
        ]
      })
      .addCase(createJobListing.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(adminCreateJobListing.fulfilled, (state, action) => {
        state.dashboardPage.jobListings = [
          action.payload,
          ...state.dashboardPage.jobListings,
        ]
        state.jobsPage.jobListings = [
          action.payload,
          ...state.jobsPage.jobListings,
        ]
      })
      .addCase(adminCreateJobListing.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(updateJobListing.fulfilled, (state, action) => {
        state.selectedJob = undefined
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(adminUpdateJobListing.fulfilled, (state, action) => {
        state.selectedJob = undefined
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(deleteJobListing.fulfilled, (state, action) => {
        const deletedJobId = action.meta.arg.id
        // clear selected job if selected job ID was deleted
        if (state.selectedJob?.id === deletedJobId) {
          state.selectedJob = undefined
        }
        // filter deletedId from dashboard page listings
        const dpAllJobResults = filterResultsFromJobArray(
          state.dashboardPage.allJobListings,
          deletedJobId
        )
        state.dashboardPage.allJobListings = dpAllJobResults.filteredArray
        state.dashboardPage.jobListingsCount += dpAllJobResults.countRemoved
        state.dashboardPage.jobListings =
          state.dashboardPage.jobListings.filter(
            (job) => job.id !== deletedJobId
          )
        // filter deletedId from jobs page listings
        const jpAllJobResults = filterResultsFromJobArray(
          state.jobsPage.allJobListings,
          deletedJobId
        )
        state.jobsPage.allJobListings = jpAllJobResults.filteredArray
        state.jobsPage.jobListingsCount += jpAllJobResults.countRemoved
        state.jobsPage.jobListings = state.jobsPage.jobListings.filter(
          (job) => job.id !== deletedJobId
        )
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(clearSelectedJob.fulfilled, (state, action) => {
        state.selectedJob = undefined
      })
      .addCase(closeJobListingById.fulfilled, (state, action) => {
        if (state.selectedJob) state.selectedJob.status = "closed"
      })
      .addCase(getJobListingById.pending, (state, action) => {
        state.selectedJob = undefined
      })
      .addCase(getJobListingById.fulfilled, (state, action) => {
        state.selectedJob = action.payload
      })
      .addCase(getJobListingById.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(fetchJobListing.pending, (state, action) => {
        const { requestOrigin } = action.meta.arg

        state[requestOrigin].currentFilter =
          action.meta.arg.action === "FETCH" ||
          action.meta.arg.action === "SEARCH"
            ? {}
            : state[requestOrigin].currentFilter
        state[requestOrigin].allJobListings =
          action.meta.arg.page === 0 ||
          action.meta.arg.page === 1 ||
          action.meta.arg.action === "FETCH" ||
          action.meta.arg.action === "SEARCH"
            ? []
            : state[requestOrigin].allJobListings
      })
      .addCase(fetchJobListing.fulfilled, (state, action) => {
        const { page, requestOrigin } = action.meta.arg

        state[requestOrigin].jobListings = action.payload.data
        state[requestOrigin].jobListingsCount = action.payload.count
        state[requestOrigin].allJobListingsCurrentPage = page ? page : 1

        if (!state[requestOrigin].fetchedPages.includes(page)) {
          state[requestOrigin].fetchedPages = [
            ...state[requestOrigin].fetchedPages,
            page,
          ]
        } else if (
          page === 1 &&
          page < Math.max(...state[requestOrigin].fetchedPages)
        ) {
          state[requestOrigin].fetchedPages = [page]
        }

        state[requestOrigin].currentFilter = {
          location: action.meta.arg.location,
          search: action.meta.arg.search,
          workplaceType: action.meta.arg.workplaceType,
          employmentType: action.meta.arg.employmentType,
          experienceLevel: action.meta.arg.experienceLevel,
          workplaceTypeFilter: action.meta.arg.workplaceTypeFilter,
          employmentTypeFilter: action.meta.arg.employmentTypeFilter,
          experienceLevelFilter: action.meta.arg.experienceLevelFilter,
          sortBy: action.meta.arg.sortBy,
          status: action.meta.arg.status,
          tag: action.meta.arg.tag,
        }

        state[requestOrigin].allJobListings =
          page === 0 || page === 1
            ? action.payload.data
            : state[requestOrigin].allJobListings?.concat(action.payload.data)
      })
      .addCase(fetchPromotedJobs.pending, (state, action) => {
        state.promotedJobListings = []
      })
      .addCase(fetchPromotedJobs.fulfilled, (state, action) => {
        state.promotedJobListings = action.payload.data
        state.promotedJobListingsCount = action.payload.count
      })
      .addCase(fetchJobListing.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(getJobTagSuggestions.fulfilled, (state, action) => {
        state.tagSuggestions = action.payload
      })
      .addCase(getJobApplicants.fulfilled, (state, action) => {
        state.applicants = action.payload
      })
      .addCase(getJobApplicants.pending, (state, action) => {
        state.applicants = []
      })
  },
})
//#endregion

//#region selectors
export const selectApplicants = ({ jobListings }: RootState) =>
  jobListings.applicants
//#endregion
