import React from "react"
import { Box, Container, Link, Typography } from "@material-ui/core"

const View = () => {
  const handleReloadPage = () => {
    window.location.reload()
  }

  return (
    <div className="main-content">
      <Container>
        <Box paddingTop={4} paddingBottom={3}>
          <Typography variant="h1" align="center">
            Oops, something's gone wrong!
          </Typography>
        </Box>
        <Box paddingTop={4} paddingBottom={3}>
          <Typography variant="h5" align="center">
            Seems like there is an internal issue. Nothing to worry about it.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" align="center">
            Try clicking <Link onClick={() => handleReloadPage()}>here</Link> to
            reload the page.
          </Typography>
        </Box>
      </Container>
    </div>
  )
}

export default View
