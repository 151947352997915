import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { Profile } from "./profile"

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export type CollaboratorManagement = {
  id?: string
  profileId?: string
  projectId?: string
  projectName?: string
  displayName?: string
  username?: string
  smallImageUrl?: string
  projectThumbnail?: string
  placeholderAvatar?: number
  isAdmin?: boolean
  status?: string
  origin?: string
  inviteDate?: Date
  isAmbassador?: boolean
}

type SliceState = {
  connections: Profile[]
  connectionsCount: number
  followers: Profile[]
  followersCount: number
  following: Profile[]
  followingCount: number
  requests: Profile[]
  requestsCount: number
  suggestions: Profile[]
  suggestionsCount: number
  collaborators: CollaboratorManagement[]
  collaboratorsCount: number
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type FetchNetworkPagePayload = {
  auth: Auth0ContextInterface
  search?: string
  type: string
  page: number
  perPage: number
}

export const fetchNetworkPage = createAsyncThunk<any, FetchNetworkPagePayload>(
  "profile/fetchNetworkPage",
  async ({ auth, page = 1, perPage = 10, search, type }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/profile/network/${type}/?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type DeleteSuggestionPayload = {
  auth: Auth0ContextInterface
  profileId: string
}

export const deleteSuggestion = createAsyncThunk<any, DeleteSuggestionPayload>(
  "profile/deleteSuggestion",
  async ({ auth, profileId }) => {
    let endpoint = `/profile/suggestion/${profileId}`
    return useApi(auth, endpoint, { method: "DELETE" }).then((res) =>
      res.text()
    )
  }
)

type FetchNetworkCollaboratorsPagePayload = {
  auth: Auth0ContextInterface
  search?: string
  type: string
  page: number
  perPage: number
}

export const fetchNetworkCollaboratorsPage = createAsyncThunk<
  any,
  FetchNetworkCollaboratorsPagePayload
>(
  "profile/fetchNetworkCollaboratorsPage",
  async ({ auth, page = 1, perPage = 10, search, type }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/profile/network/collaborators/${type}/?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type UpdateProfileConnetionRequestStatePayload = {
  profileId: string
  type: string
}

export const updateProfileConnetionRequestState = createAsyncThunk<
  any,
  UpdateProfileConnetionRequestStatePayload
>("profile/updateProfileConnetionRequestState", async ({ profileId, type }) => {
  return ""
})

type UpdateCollaboratorsStatePayload = {
  collaboratorId: string
  action: string
}

export const updateCollaboratorsState = createAsyncThunk<
  any,
  UpdateCollaboratorsStatePayload
>("profile/updateCollaboratorsState", async ({ collaboratorId, action }) => {
  return ""
})

type UpdateFollowingStatePayload = {
  profileId: string
  type: string
}

export const updateFollowingState = createAsyncThunk<
  any,
  UpdateFollowingStatePayload
>("profile/updateFollowingState", async ({ profileId, type }) => {
  return ""
})

type UpdateSuggestionStatePayload = {
  profileId: string
}

export const updateSuggestionState = createAsyncThunk<
  any,
  UpdateSuggestionStatePayload
>("profile/updateSuggestionState", async ({ profileId }) => {
  return ""
})
//#endregion

//#region slice
const initialState: SliceState = {
  connections: [],
  connectionsCount: 0,
  followers: [],
  followersCount: 0,
  following: [],
  followingCount: 0,
  requests: [],
  requestsCount: 0,
  suggestions: [],
  suggestionsCount: 0,
  collaborators: [],
  collaboratorsCount: 0,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "connections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetworkPage.fulfilled, (state, action) => {
        if (action.meta.arg.type === "followers") {
          state.followersCount = action.payload.count
          state.followers =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.followers.concat(action.payload.data)
        } else if (action.meta.arg.type === "following") {
          state.followingCount = action.payload.count
          state.following =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.following.concat(action.payload.data)
        } else if (action.meta.arg.type === "connections") {
          state.connectionsCount = action.payload.count
          state.connections =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.connections.concat(action.payload.data)
        } else if (action.meta.arg.type === "requests") {
          state.requestsCount = action.payload.count
          state.requests =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.requests.concat(action.payload.data)
        } else if (action.meta.arg.type === "suggestions") {
          state.suggestionsCount = action.payload.count
          state.suggestions =
            action.meta.arg.page === 0 || action.meta.arg.page === 1
              ? action.payload.data
              : state.suggestions.concat(action.payload.data)
        }
      })
      .addCase(
        updateProfileConnetionRequestState.fulfilled,
        (state, action) => {
          if (action.meta.arg.type === "requests") {
            const updatedList = state.requests.filter((connection) => {
              return connection.connectionId !== action.meta.arg.profileId
            })

            state.requests = updatedList
            state.requestsCount = state.requestsCount - 1
          } else if (action.meta.arg.type === "connections") {
            const updatedList = state.connections.filter((connection) => {
              return connection.connectionId !== action.meta.arg.profileId
            })

            state.connections = updatedList
            state.connectionsCount = state.connectionsCount - 1
          }
        }
      )
      .addCase(updateFollowingState.fulfilled, (state, action) => {
        if (action.meta.arg.type === "following") {
          const updatedList = state.following.filter((connection) => {
            return connection.connectionId !== action.meta.arg.profileId
          })

          state.following = updatedList
          state.followingCount = state.followingCount - 1
        }
      })
      .addCase(updateSuggestionState.fulfilled, (state, action) => {
        const updatedList = state.suggestions.filter((connection) => {
          return connection.connectionId !== action.meta.arg.profileId
        })

        state.suggestions = updatedList
        state.suggestionsCount = state.suggestionsCount - 1
      })
      .addCase(fetchNetworkCollaboratorsPage.fulfilled, (state, action) => {
        state.collaboratorsCount = action.payload.count
        state.collaborators =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.collaborators.concat(action.payload.data)
      })
      .addCase(updateCollaboratorsState.fulfilled, (state, action) => {
        if (action.meta.arg.action === "confirm") {
          const updatedList = state.collaborators.map((collaborator) => {
            if (collaborator.id === action.meta.arg.collaboratorId)
              collaborator.status = "confirmed"

            return collaborator
          })

          state.collaborators = updatedList
        } else if (action.meta.arg.action === "remove") {
          const updatedList = state.collaborators.filter((collaborator) => {
            return collaborator.id !== action.meta.arg.collaboratorId
          })

          state.collaborators = updatedList
          state.collaboratorsCount = state.collaboratorsCount - 1
        }
      })
      .addCase(deleteSuggestion.fulfilled, (state, action) => {
        const updatedSuggestions = [...state.suggestions]
        updatedSuggestions.forEach((suggestion, key) => {
          if (suggestion.connectionId! === action.meta.arg.profileId) {
            updatedSuggestions.splice(key, 1)
          }
        })
        state.suggestions = updatedSuggestions
      })
  },
})
//#endregion

//#region selectors
//#endregion
