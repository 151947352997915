import React from "react"
import { useHistory } from "react-router-dom"
import { Box } from "@material-ui/core"
import { Notification } from "../../redux/bellNotifications"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import NotificationFollowIcon from "../../images/notify-person.svg"
import NotificationConnectIcon from "../../images/notify-link.svg"
import NewNotificationIcon from "../../images/new-notification.svg"
import ReadNotificationIcon from "../../images/new-notification-white.svg"
import FollowButtonList from "../FollowButtonList"
import ConnectButtonConfirm from "../ConnectButtonConfirm"
import * as RouteConstants from "../../constants/routes"
import moment from "moment"

import {
  NotificationBox,
  AvatarBox,
  DescriptionBox,
  ButtonBox,
  AvatarIcon,
  NotificationAvatar,
} from "../NotificationsBell/styles"

export type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  hideButton?: boolean
  goToNotifications?: boolean
}

const View = ({
  notification,
  onNotificationClick,
  hideButton,
  goToNotifications,
}: Props) => {
  const profileLink = `${RouteConstants.PROFILE_CONTAINER}/${notification?.profile?.username}`
  const history = useHistory()

  const goToURL = (url: string) => {
    if (goToNotifications) {
      history.push("/notifications")
    } else {
      onNotificationClick(notification?.id)
      history.push(url)
    }
  }

  return (
    <NotificationBox>
      <AvatarBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <NotificationAvatar
          src={
            notification?.profile?.smallImageUrl === undefined
              ? getRandomPlaceholder(notification?.profile?.placeholderAvatar!)
              : (notification?.profile?.smallImageUrl as string)
          }
          alt={notification?.origin}
        />

        <AvatarIcon
          src={
            notification?.origin === "follow"
              ? NotificationFollowIcon
              : NotificationConnectIcon
          }
          alt={notification?.origin}
        />
      </AvatarBox>
      <DescriptionBox
        onClick={() => {
          goToURL(profileLink)
        }}
      >
        <Box style={{ marginTop: "-10px" }}>
          <b>
            {notification?.profile?.type === "group" ? (
              <>{notification?.profile?.groupName}</>
            ) : (
              <>
                {notification?.profile?.firstName}{" "}
                {notification?.profile?.lastName}
              </>
            )}
          </b>
          {notification?.origin === "follow" ? (
            <>&nbsp;started following you. &nbsp;</>
          ) : (
            <>&nbsp;sent a request. &nbsp;</>
          )}
          <span style={{ color: "#717171" }}>
            {moment(notification?.createdAt).fromNow(true).replace(" ", "")}
          </span>
        </Box>
      </DescriptionBox>
      {!hideButton && (
        <ButtonBox>
          {notification?.origin === "follow" ? (
            <FollowButtonList
              profile={notification?.profile!}
              noPadding={true}
            />
          ) : (
            <>
              <ConnectButtonConfirm
                profile={notification?.profile!}
                type={"confirm"}
              />
              <ConnectButtonConfirm
                profile={notification?.profile!}
                type={"deny"}
              />
            </>
          )}
        </ButtonBox>
      )}
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
        {notification?.origin === "follow" && (
          <img
            alt={"notification-state"}
            src={
              notification?.state !== "clicked"
                ? NewNotificationIcon
                : ReadNotificationIcon
            }
            width={"8px"}
            height={"8px"}
            style={{ marginTop: "-10px" }}
          />
        )}
      </Box>
    </NotificationBox>
  )
}

export default View
