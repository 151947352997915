import React from "react"
import ImageCarousel, { RenderPaginationProps } from "react-elastic-carousel"

import {
  Square,
  ImageThumbnail,
  CarouselContainer,
  ThumbnailContainer,
  PaginationContainer,
} from "./Styles"

import { useWindowDimensions } from "../../hooks"
import {
  VideoGalleryProps,
  defaultBreakpoints,
  getBreakpoint,
  VideoType,
} from "."

interface Props extends VideoGalleryProps {
  onSelect: (img: VideoType, idx: number) => void
}

const Carousel = React.forwardRef<any, Props>(({ videos, onSelect }, ref) => {
  const { width } = useWindowDimensions()

  const breakpoints = defaultBreakpoints(false)

  const renderPagination = ({
    pages,
    onClick,
    activePage,
  }: RenderPaginationProps) =>
    videos.length > getBreakpoint(breakpoints, width).itemsToShow ? (
      <PaginationContainer>
        {pages.map((page: number) => (
          <Square
            key={page}
            isActive={activePage === page}
            onClick={() => onClick(page.toString())}
          />
        ))}
      </PaginationContainer>
    ) : (
      <div />
    )

  const renderThumbnail = (video: VideoType, margin?: boolean) => {
    return (
      <ThumbnailContainer
        key={video.order}
        onClick={() => onSelect(video, video.order)}
      >
        <figure>
          <ImageThumbnail draggable={false} src={video.smallThumbnail} />
        </figure>
      </ThumbnailContainer>
    )
  }

  return (
    <CarouselContainer>
      <ImageCarousel
        ref={ref}
        isRTL={false}
        itemPadding={[0, 0]}
        breakPoints={breakpoints}
        showArrows={false}
        renderPagination={renderPagination}
        enableMouseSwipe={true}
      >
        {videos.map((video) => renderThumbnail(video))}
      </ImageCarousel>
    </CarouselContainer>
  )
})

export default Carousel
