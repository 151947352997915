import React, { useState } from "react"
import * as Yup from "yup"
import { ProjectReward } from "../../redux/adminProjectReward"

import View from "./View"

export interface EditableProjectReward extends ProjectReward {
  editing?: boolean
}

const RewardSchema = Yup.object().shape({
  campaign: Yup.string().required("Required"),
  prize: Yup.string().required("Required"),
})

type Props = {
  projectReward: EditableProjectReward
  onSave: (projectReward: EditableProjectReward) => void
  onDelete: (projectReward: EditableProjectReward) => void
}

const AdminProjectRewardRow = ({ projectReward, onSave, onDelete }: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [original] = useState(projectReward)
  const [reward, setReward] = useState(projectReward)

  const validate = async (feat: EditableProjectReward) =>
    await RewardSchema.validate(feat)

  const handleOnSave = async () => {
    try {
      await validate(reward)

      setError(null)

      onSave(reward)
      setReward({ ...reward, editing: false })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message)
    }
  }

  const handleOnChange = async (
    data: any | null,
    property: keyof EditableProjectReward
  ) => {
    if (reward.prize) setError(null)

    if (reward.campaign) setError(null)

    setReward({ ...reward, [property]: data })
  }

  const handleOnDateChange = (
    date: Date | null,
    property: keyof EditableProjectReward
  ) => {
    setError(null)
    setReward({ ...reward, [property]: date?.toDateString() })
  }

  const handleOnDelete = () => onDelete(reward)

  const handleOnEdit = () => setReward({ ...reward, editing: true })

  const handleOnCancel = () => setReward({ ...original, editing: false })

  return (
    <View
      error={error}
      reward={reward}
      onEdit={handleOnEdit}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onChange={handleOnChange}
      onDateChange={handleOnDateChange}
      onDelete={handleOnDelete}
    />
  )
}

export default AdminProjectRewardRow
