import React from "react"
import * as Yup from "yup"
import { FormikContextType, useFormikContext } from "formik"
import { Grid, Box, Typography } from "@material-ui/core"

import { Image as ImageType, Project } from "../../redux/projects"

import { StyledImageList } from "../../components/StyledDragnDrop"
import ImageCreditsDialog from "../../components/ImageCreditsDialog"
import { StyledDropzone, RejectedFile } from "../../components/StyledDropzone"

import {
  StyledLink,
  StyledLinkBox,
  ImageErrorList,
  ImageThumbnail,
  ListItemOverlay,
  IconOverlayLeft,
  TextOverlayContainer,
  ImageThumbnailContainer,
  ProjectWizardCopy,
} from "./styles"

import { WizardStep, StepProps } from "./Wizard"

const COVER_IMAGE_INDEX = 0

interface Props extends StepProps {}

const Step1: React.FC<Props> = ({ onCancel }) => {
  const formik: FormikContextType<Project> = useFormikContext()
  const [idx, setIdx] = React.useState(0)
  const [isCompressing, setisCompressing] = React.useState(false)
  const [isConverting, setIsConverting] = React.useState(false)
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [errors, setErrors] = React.useState<RejectedFile[]>([])
  const [removedImages, setRemovedImages] = React.useState([])

  const nextIndex = (idx + 1) % formik.values.images!.length
  const prevIndex =
    (idx + formik.values.images!.length - 1) % formik.values.images!.length

  const handleImagesChange = (
    newImages: ImageType[],
    errors?: RejectedFile[]
  ) => {
    setErrors(errors ? errors : [])

    formik.setFieldValue("images", newImages)
  }

  const handleImageListChange = (
    newImages: ImageType[],
    removed?: string[]
  ) => {
    setErrors([])

    if (removed && removed.length > 0) {
      handleRemovedImages(removed)
      formik.setFieldValue("removedImages", removedImages)
    }

    formik.setFieldValue("images", newImages)
  }

  const handleIsCompressing = (isCompressing: boolean) => {
    setisCompressing(isCompressing)
  }

  const handleIsConverting = (isConverting: boolean) => {
    setIsConverting(isConverting)
  }

  const handleRemovedImages = (removed: string[]) => {
    const newRemovedImages: any = removedImages
    newRemovedImages.push(removed)
    setRemovedImages(newRemovedImages)
  }

  const handleEdit = (imageOrder: number) => {
    setIdx(
      formik.values.images!.findIndex((image) => image.order === imageOrder)
    )
    setIsDialogOpen(true)
  }

  window.addEventListener("turbolinks:before-render", () => {
    const highestTimeoutId = setTimeout(function () {})
    for (let i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i)
    }
  })

  return (
    <WizardStep>
      <>
        <ImageCreditsDialog
          index={idx % formik.values.images!.length}
          isOpen={isDialogOpen}
          images={formik.values.images!}
          onClose={() => setIsDialogOpen(false)}
          onChange={(newImages) => formik.setFieldValue("images", newImages)}
          onEditImage={(removed) => {
            handleRemovedImages(removed)
            formik.setFieldValue("removedImages", removedImages)
          }}
          onConfirm={(newImages) => {
            formik.setFieldValue("images", newImages)
            setIsDialogOpen(false)
          }}
          onDelete={(imageOrder) => {
            const newImages = Array.from(formik.values.images!)
            newImages.splice(
              formik.values.images!.findIndex(
                (image) => image.order === imageOrder
              ),
              1
            )
            formik.setFieldValue("images", newImages)
          }}
          onNav={(dir) =>
            dir === "next" ? setIdx(nextIndex) : setIdx(prevIndex)
          }
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography tabIndex={0} variant="h3">
            Let's create a project
          </Typography>
          <Box>
            <StyledLinkBox onClick={onCancel}>
              <StyledLink>Cancel</StyledLink>
            </StyledLinkBox>
          </Box>
        </Box>

        <Box>
          <ProjectWizardCopy>
            Here's your chance to shine! Upload your design here. The first
            image you upload will be the thumbnail and cover image for your
            project. Make sure to add credits and captions to each image by
            clicking on the "Edit" icon after it's uploaded. You can rearrange
            the order of your photos by clicking and dragging images. In order
            to be considered for feature opportunities, we request at least one
            orthographic image (elevation, plan, section, diagram, etc).
          </ProjectWizardCopy>
        </Box>

        <Box>
          {errors ? (
            <ul>
              {errors.map(({ file, errors }) => (
                <ImageErrorList key={`file-${file.name}`}>
                  {errors[0].message}
                </ImageErrorList>
              ))}
            </ul>
          ) : null}
        </Box>

        {formik.values.images && formik.values.images.length > 0 ? (
          <Grid container justify="center">
            <ImageThumbnailContainer>
              <ListItemOverlay>
                <IconOverlayLeft>
                  <TextOverlayContainer>Cover Photo</TextOverlayContainer>
                </IconOverlayLeft>
              </ListItemOverlay>
              <ImageThumbnail
                auto={
                  formik.values.images[COVER_IMAGE_INDEX].dimensions &&
                  formik.values.images[COVER_IMAGE_INDEX].dimensions!.height &&
                  formik.values.images[COVER_IMAGE_INDEX].dimensions!.height! >
                    600
                    ? formik.values.images[COVER_IMAGE_INDEX].dimensions!
                        .width! >= 1000
                      ? true
                      : false
                    : true
                }
                src={
                  formik.values.images[COVER_IMAGE_INDEX].url
                    ? formik.values.images[COVER_IMAGE_INDEX].url
                    : formik.values.images[COVER_IMAGE_INDEX].largeImageUrl
                }
                alt={`img-${formik.values.images[COVER_IMAGE_INDEX].order}`}
              />
            </ImageThumbnailContainer>
          </Grid>
        ) : null}

        {formik.values.images && formik.values.images.length === 0 ? (
          <Box>
            <StyledDropzone
              onChange={handleImagesChange}
              onCompress={handleIsCompressing}
              onConvert={handleIsConverting}
              images={formik.values.images ? formik.values.images : []}
            />
          </Box>
        ) : null}

        <Box marginTop={4}>
          <StyledImageList
            onEdit={handleEdit}
            images={formik.values.images!}
            onChange={handleImageListChange}
            onSelect={(_) => {}}
          />
        </Box>

        {formik.values.images && formik.values.images.length > 0 ? (
          <Grid container justify="center">
            <StyledDropzone
              compact={true}
              onChange={handleImagesChange}
              onCompress={handleIsCompressing}
              onConvert={handleIsConverting}
              images={formik.values.images ? formik.values.images : []}
            />
          </Grid>
        ) : null}
        {isCompressing ? (
          <Box marginTop={1} textAlign={"center"}>
            <Typography>Compressing Image...</Typography>
          </Box>
        ) : null}

        {isConverting ? (
          <Box marginTop={1} textAlign={"center"}>
            <Typography>Converting File...</Typography>
          </Box>
        ) : null}
      </>
      {formik.errors.images && (
        <ImageErrorList>
          To continue, please upload at least one image. We appreciate your
          cooperation!
        </ImageErrorList>
      )}
    </WizardStep>
  )
}

Step1.defaultProps = {
  validationSchema: Yup.object().shape({
    images: Yup.array().min(1, "Required"),
  }),
  groupValidationSchema: Yup.object().shape({
    images: Yup.array().min(1, "Required"),
  }),
}

export default Step1
