import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { Post } from "./posts"
import { RootState } from "./rootReducer"
//#region types
export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  posts: Post[]
  post: Post | null | undefined
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type FetchPostsPayload = {
  auth: Auth0ContextInterface
}
export const fetchPosts = createAsyncThunk<any, FetchPostsPayload>(
  "posts/admin/fetchPosts",
  async ({ auth }) => {
    const endpoint = "/admin/posts"
    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type FetchPostPayload = {
  id: string
  auth: Auth0ContextInterface
}
export const fetchPost = createAsyncThunk<any, FetchPostPayload>(
  "posts/admin/fetchPost",
  async ({ auth, id }) => {
    return useApi(auth, `/admin/posts/${id}`).then((res) => res.json())
  }
)

type UpdatePostPayload = {
  auth: Auth0ContextInterface
  status: string
  id: string
}
export const updatePost = createAsyncThunk<any, UpdatePostPayload>(
  "posts/admin/updatePost",
  async ({ auth, status, id }) => {
    return useApi(auth, `/admin/posts/flag/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  posts: [],
  post: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "adminPosts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.posts = action.payload
        state.error = null
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.post = action.payload
        state.error = null
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        const updatedPosts = [...state.posts]
        updatedPosts.forEach((post, key) => {
          if (post.id! === action.meta.arg.id) {
            updatedPosts.splice(key, 1)
          }
        })
        state.posts = updatedPosts
      })
  },
})
//#endregion

//#region selectors
export const selectAllPosts = ({ adminPosts }: RootState) => adminPosts.posts
export const selectPost = ({ adminPosts }: RootState) => adminPosts.post
//#endregion
