import { Box, Dialog, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import bgHeader from "../../images/innovation-awards/header-bg-desktop.png"
import bgHeaderMobile from "../../images/innovation-awards/header-bg-mobile.png"

import BorderUpDialog from "../../images/innovation-awards/dialog-border-up.png"
import BorderDownDialog from "../../images/innovation-awards/dialog-border-down.png"
import Quotes from "../../images/new-homepage/quotes-dark.png"

import ulIcon from "../../images/innovation-awards/ul-icon.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};
  margin-bottom: -64px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background: url(${bgHeader});
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  height: 417px;
  overflow: hidden;
  border-bottom: 2px solid #000000;

  ${theme.breakpoints.down("sm")} {
    height: auto;
  }

  ${theme.breakpoints.down("xs")} {
    height: 316px;
    align-items: center;
    background: url(${bgHeaderMobile});
    background-position: right;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
  }
`

export const HeaderButtonBox = styled(Box)`
  padding-top: 24px;
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 100px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;

    && button {
      width: 90% !important;
    }
  }

  @media only screen and (max-width: 429px) {
    padding: 0 20px;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const StyledLogo = styled.img`
  ${theme.breakpoints.down("xs")} {
    width: 100%;
    max-width: 320px;
  }
`

export const DescriptionBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #F5F5F5;
  border-bottom: 2px solid #000000;
  box-sizing: border:box;
`

export const DescriptionContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const DescriptionFlexBox = styled(Box)`
  display: flex;
  gap: 32px;
`

export const DescriptionLeftBox = styled(Box)`
  padding-bottom: 78px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 47px;
  }
`

export const DescriptionFont = styled(Typography)`
  padding-top: 96px;
  font-size: 20px;
  line-height: 36px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 50px;
  }
`

export const DescriptionIconBox = styled(Box)`
  border-left: 2px solid #000000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 418px;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const RegisterBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #fff;
`

export const RegisterContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding-left: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const RegisterTitle = styled(Typography)`
  font-size: 48px;
  color: #2d2d2d;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const RegisterContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 26px;

  ${theme.breakpoints.down("sm")} {
    padding: 20px 0;
  }
`

export const HowItWorksBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  padding: 60px 0 64px;

  ${theme.breakpoints.down("sm")} {
    padding: 30px 20px 32px;
  }
`

export const HowItWorksBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  border-bottom: 2px solid #fff;
`

export const HowItWorksContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  display: flex;
  gap: 32px;
  position: relative;

  @media only screen and (max-width: 1420px) {
    padding-left: 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    flex-flow: column;
    gap: 0;
  }
`

export const HowToWinImage = styled.img`
  position: absolute;
  top: -220px;
  right: 0;

  @media only screen and (max-width: 1210px) {
    width: 400px;
    top: -150px;
    right: 50px;
  }

  @media only screen and (max-width: 1110px) {
    right: 0;
  }

  @media only screen and (max-width: 1060px) {
    width: 300px;
    top: -80px;
    right: 0;
  }

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const HowItWorksDescriptionBox = styled(Box)`
  width: 100%;
  padding: 48px 64px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 32px;
    flex-flow: column;
    width: auto;
    border-left: 0;
    border-right: 0;
  }
`

export const HowItWorksSubTitle = styled(Typography)`
  font-size: 16px;
  margin-top: 16px;
  line-height: 24px;
  color: #fff;

  && img {
    width: 19px;
    vertical-align: middle;
  }
`

export const HowItWorksTitle = styled(Typography)`
  font-size: 64px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`

export const HowItWorksList = styled(Box)`
  color: #fff;
  font-size: 20px;

  && ol {
    padding: 0;
    margin: 0;
  }

  && li {
    padding-left: 10px;
  }
`

export const HowItWorksListItem = styled(Box)`
  color: #fff;
  font-size: 16px;
  padding-left: 36px;
  margin-top: 18px;

  && img {
    width: 23px;
    vertical-align: middle;
  }

  && span {
    font-family: Public-Sans-Bold;
  }

  && a {
    color: #fff;
    font-family: Public-Sans-Black;
  }
`

export const HowItWorksInnerList = styled(Box)`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: 16px;
`

export const HowItWorksInnerTitle = styled(Typography)`
  font-size: 40px;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
  }
`

export const FormSpacingBox = styled(Box)`
  padding-top: 27px;

  ${theme.breakpoints.down("xs")} {
    padding-top: 20px;

    && button {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
`

export const SuccessDialogArea = styled(Dialog)`
  min-height: 100vh;
`

export const SuccessDialogBox = styled(Box)`
  overflow-x: hidden;
  width: 640px;
  height: fit-content;
  box-sizing: border-box;
  padding: 27px;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const DialogBgBox = styled(Box)`
  box-sizing: border-box;
  background: url(${BorderUpDialog}) top left no-repeat,
    url(${BorderDownDialog}) bottom right no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: 80px 0;
`

export const TextfieldBox = styled(Box)`
  text-align: left;
  margin-top: 48px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 0 20px;

    && button {
      width: 100% !important;
    }
  }
`

export const SuccessDialogTitle = styled(Typography)`
  font-size: 48px;
  color: #2d2d2d;
  margin-top: 32px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 18px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 40px;
  }
`

export const SuccessDialogText = styled(Typography)`
  font-size: 24px;
  margin-top: 30px;

  && span {
    font-family: "Public-Sans-Bold";
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 16px;
  }

  @media only screen and (max-width: 390px) {
    font-size: 20px;
  }
`

export const SuccessDialogHint = styled(Typography)`
  font-family: "Public-Sans-Bold";
  font-size: 18px;
  line-height: 36px;
  margin-top: 49px;

  @media only screen and (max-width: 390px) {
    font-size: 16px;
    line-height: 32px;
  }
`

export const DialogIconsBox = styled(Box)`
  margin-top: 26px;
  display: flex;
  gap: 21px;

  @media only screen and (max-width: 390px) {
    gap: 16px;

    && img {
      width: 40px;
    }
  }
`

export const DialogManualShareBox = styled(Box)`
  display: flex;
  gap: 55px;
  margin-top: 22px;

  @media only screen and (max-width: 390px) {
    flex-flow: column;
    gap: 12px;
  }
`

export const CopyLinkText = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const EmailText = styled.a`
  font-size: 16px;
  font-family: Public-Sans-Medium;
  cursor: pointer;
  color: #2d2d2d;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  && img {
    margin-right: 14px;
    vertical-align: middle;
  }
`

export const SpIconImg = styled.img`
  margin-top: 39px;

  ${theme.breakpoints.down("xs")} {
    margin-top: 23px;
  }
`

export const CheckboxLabel = styled(Typography)`
  font-size: 16px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`

export const CheckboxLabelSmall = styled(Typography)`
  font-size: 12px;
  margin-top: -2px;

  && span {
    color: #ff0000;
  }
`
export const SuccessIconImg = styled.img`
  ${theme.breakpoints.down("xs")} {
    height: 70px;
  }

  @media only screen and (max-width: 390px) {
    height: 60px;
  }
`

export const SuccessSubscriptionIconImg = styled.img`
  height: 66px;
`

export const DialogSpIcon = styled.img`
  height: 72px;
`

export const CloseIconImg = styled.img`
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;
`

export const RequirementsBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;
`

export const RequirementsContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const RequirementsTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  padding-top: 68px;
  padding-bottom: 64px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding-top: 44px;
    padding-bottom: 42px;
    font-size: 40px;
  }
`

export const RequirementsFlexBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    display: initial;
  }
`

export const RequirementsLeftBox = styled(Box)`
  box-sizing: border-box;
  border-right: 2px solid #2d2d2d;
  border-left: 2px solid #2d2d2d;
  width: 100%;
  padding: 28px 20px 49px;
  flex-flow: column;
  display: flex;
  gap: 32px;

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0;
    margin-top: 45px;
    padding-bottom: 87px;
  }
`

export const RequirementsInnerTitle = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 24px;
`

export const RequirementsItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 17px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }
`
export const RequirementsSpacedBox = styled(Box)`
  margin-top: 45px;
`

export const QuotesBox = styled(Box)`
  background-color: #000;
  display: flex;
  justify-content: center;
`

export const QuotesContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const QuotesFlexBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 100px;
  box-sizing: border-box;
  padding: 100px 0;

  background: url(${Quotes});
  background-position: 400px bottom;
  background-repeat: no-repeat;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    gap: 32px;
    padding: 40px 0 64px;

    background-position: right bottom;
    background-size: 50%;
  }
`

export const QuotesDescriptionBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    order: 2;
  }
`

export const QuotesDescription = styled(Typography)`
  color: #fff;
  font-size: 48px;
  line-height: 57.6px;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    line-height: 40px;
  }
`

export const QuotesHint = styled(Typography)`
  font-family: Public-Sans-Bold;
  font-size: 36px;
  color: #fff;
  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`

export const QuotesImageBox = styled(Box)`
  ${theme.breakpoints.down("sm")} {
    order: 1;
  }
`

export const OportinityBox = styled(Box)`
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 80px;

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    padding: 32px 0;
    gap: 32px;
  }
`

export const OportinityItem = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  gap: 16px;
`
