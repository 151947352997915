import React, { useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Box, Typography, IconButton, Menu, MenuItem } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import {
  ProfileAvatar,
  CommentBox,
  CommentArea,
  CommentAuthorName,
  CommentsBox,
  CommentFooter,
  FooterLinks,
  Link,
  CommentReplyArea,
  ReplyMessageBox,
} from "./styles"
import {
  Comment,
  CommentMention,
  dislikeComment,
  likeComment,
} from "../../redux/projects"
import { Profile } from "../../redux/profile"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { BlockingBox } from "../../cards/ProjectCard/styles"
import CommentContentText from "../CommentContentText"
import CommentTextField from "../CommentTextField"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import { useAppDispatch } from "../../redux/configureStore"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  comment: Comment
  onUpdateComment: (text: string, mentions?: CommentMention[]) => Promise<void>
  onPostComment: (
    text: string,
    mentions?: CommentMention[],
    parentCommentId?: string
  ) => Promise<void>
  onHideComment: (hidden: boolean) => Promise<void>
  onSetReplyMode: () => void
  authenticatedProfile?: Profile
  isPostingComment?: boolean
  isReplyMode?: boolean
}

const View = ({
  comment,
  onUpdateComment,
  onPostComment,
  onHideComment,
  onSetReplyMode,
  authenticatedProfile,
  isPostingComment,
  isReplyMode,
}: Props) => {
  const auth = useAuth0()
  const isAuthenticated = auth.isAuthenticated
  const dispatch = useAppDispatch()
  const history = useHistory()
  const profile = useSelector((state: any): Profile => state.profile.profile)
  const author =
    comment.author?.username === profile?.username ? profile : comment.author
  const [isEditMode, setEditMode] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dispatchComplete, setDispatchComplete] = useState(true)

  const goToUrl = (url: string) => {
    history.push(url)
  }

  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleEditMode = () => {
    setEditMode(!isEditMode)
    handleClose()
  }
  const handleSave = async (comment: string, mentions?: CommentMention[]) => {
    if (comment.trim().length > 0) {
      try {
        await onUpdateComment(comment.trim(), mentions)
        toggleEditMode()
      } catch (_error) {}
    }
  }

  const handleHide = async () => {
    try {
      await onHideComment(!comment.hidden)
      handleClose()
    } catch (_error) {}
  }

  const handleLike = async (comment: Comment) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
      return
    }

    if (dispatchComplete) {
      setDispatchComplete(false)
      try {
        const { type } = await dispatch(likeComment({ auth, id: comment.id! }))
        if (type === likeComment.fulfilled.type) {
          setDispatchComplete(true)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleDislike = async (comment: Comment) => {
    if (!isAuthenticated) {
      dispatch(toggleDialog({ opened: true }))
      return
    }

    if (dispatchComplete) {
      setDispatchComplete(false)
      try {
        const { type } = await dispatch(
          dislikeComment({ auth, id: comment.id! })
        )
        if (type === dislikeComment.fulfilled.type) {
          setDispatchComplete(true)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const postReply = (
    comment: string,
    mentions?: CommentMention[],
    parentCommentId?: string
  ) => {
    if (!isPostingComment && comment) {
      onPostComment(comment, mentions, parentCommentId)
    }
  }

  const renderMenu = () => {
    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ float: "right" }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: "15ch",
            },
          }}
        >
          <MenuItem onClick={toggleEditMode}>
            <Typography tabIndex={0} variant="caption">
              Edit Comment
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleHide}>
            <Typography tabIndex={0} variant="caption">
              Delete Comment
            </Typography>
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <CommentsBox key={comment.id} id={comment.id}>
      <CommentBox>
        <ProfileAvatar
          onClick={() => goToUrl(`/sp/${author?.username}`)}
          src={
            author?.smallImageUrl ||
            getRandomPlaceholder(author?.placeholderAvatar!)
          }
        />
        <Box width={"100%"}>
          <CommentArea>
            <CommentAuthorName>
              {author?.type === "group"
                ? author?.groupName
                : `${author!.firstName} ${author!.lastName}`}
              {author?.isAmbassador && (
                <img
                  src={AmbassadorIcon}
                  alt="Student Ambassador"
                  className="StudentAmbassadorIcon"
                />
              )}
              {author?.id === profile?.id ? renderMenu() : null}
              <span>
                &nbsp;&nbsp;•&nbsp;&nbsp;{moment(comment.createdAt).fromNow()}
              </span>
            </CommentAuthorName>
            {!isEditMode ? (
              <Box marginTop={"8px"}>
                <CommentContentText comment={comment} />
              </Box>
            ) : (
              <Box>
                <CommentTextField
                  defaultValue={comment.text}
                  isEditMode={true}
                  toggleEditMode={toggleEditMode}
                  handleSave={handleSave}
                  focus={true}
                />
              </Box>
            )}
          </CommentArea>
          <CommentFooter>
            <FooterLinks>
              <Link
                $isActive={comment.liked}
                onClick={() =>
                  comment.liked ? handleDislike(comment) : handleLike(comment)
                }
              >
                Like
              </Link>
              <Link $isActive={isReplyMode} onClick={onSetReplyMode}>
                Reply
              </Link>
            </FooterLinks>
            {comment.replies && comment.replies.length > 0 && (
              <>
                {comment.replies.map((reply) => {
                  return (
                    <CommentReplyArea key={reply.id} id={reply.id}>
                      <ProfileAvatar
                        onClick={() => goToUrl(`/sp/${reply.author?.username}`)}
                        src={
                          reply.author?.smallImageUrl ||
                          getRandomPlaceholder(reply.author?.placeholderAvatar!)
                        }
                      />
                      <Box width={"100%"}>
                        <ReplyMessageBox>
                          <CommentAuthorName>
                            {reply.author?.type === "group"
                              ? reply.author?.groupName
                              : `${reply.author!.firstName} ${
                                  reply.author!.lastName
                                }`}
                            {reply.author?.isAmbassador && (
                              <img
                                src={AmbassadorIcon}
                                alt="Student Ambassador"
                                className="StudentAmbassadorIcon"
                              />
                            )}
                            <span>
                              &nbsp;&nbsp;•&nbsp;&nbsp;
                              {moment(reply.createdAt).fromNow()}
                            </span>
                          </CommentAuthorName>
                          <CommentContentText comment={reply} />
                        </ReplyMessageBox>
                        <FooterLinks>
                          <Link
                            $isActive={reply.liked}
                            onClick={() =>
                              reply.liked
                                ? handleDislike(reply)
                                : handleLike(reply)
                            }
                          >
                            Like
                          </Link>
                        </FooterLinks>
                      </Box>
                    </CommentReplyArea>
                  )
                })}
              </>
            )}
            {isReplyMode && (
              <BlockingBox $isPosting={isPostingComment}>
                <CommentReplyArea>
                  <ProfileAvatar
                    onClick={() =>
                      goToUrl(`/sp/${authenticatedProfile?.username}`)
                    }
                    src={
                      authenticatedProfile?.smallImageUrl ||
                      getRandomPlaceholder(
                        authenticatedProfile?.placeholderAvatar!
                      )
                    }
                  />
                  <CommentTextField
                    handleSave={postReply}
                    focus={true}
                    parentCommentId={comment.id}
                    replyFromDetails={true}
                  />
                </CommentReplyArea>
              </BlockingBox>
            )}
          </CommentFooter>
        </Box>
      </CommentBox>
    </CommentsBox>
  )
}

export default View
