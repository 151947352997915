import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { useAppDispatch } from "../../redux/configureStore"
import {
  Project,
  validateProjectUrl,
  validateIfProjectWasAlreadySubmitted,
} from "../../redux/projects"
import { Profile } from "../../redux/profile"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import {
  FormArea,
  FormContainer,
  FormBG,
  FormSpacingBox,
  FormTitle,
  FormSubTitle,
} from "./styles"
import TextField from "./TextField"
import { Button } from "../../components"
import { InnovationAwardsForm } from "."
import SuccessDialog from "./SuccessDialog"

interface Props {
  onSubmit: (data: InnovationAwardsForm) => void
  showMessage: boolean
  onClose: () => void
  authenticatedProfile?: Profile
}

const FormBox = ({
  onSubmit,
  showMessage,
  onClose,
  authenticatedProfile,
}: Props) => {
  const [projectLink, setProjectLink] = useState("")
  const [project, setProject] = useState<Project>()

  const dispatch = useAppDispatch()

  const initialValues: InnovationAwardsForm = {
    name: `${
      authenticatedProfile
        ? authenticatedProfile.firstName + " " + authenticatedProfile.lastName
        : ""
    }`,
    projectLink: "",
    projectTitle: "",
  }

  const checkIfUrlIsValid = async (url?: string) => {
    try {
      if (!url) return false

      const { payload } = await dispatch(
        validateProjectUrl({ projectLink: url })
      )

      if (payload.error) return false

      setProjectLink(url)
      setProject(payload)
      return true
    } catch (error) {
      return false
    }
  }

  const checkIfProjectWasAlreadySubmitted = async (url?: string) => {
    try {
      if (!url) return false

      const { payload } = await dispatch(
        validateIfProjectWasAlreadySubmitted({ projectLink: url })
      )
      return payload === "Valid"
    } catch (error) {
      return false
    }
  }

  const handleSubmit = async (
    values: InnovationAwardsForm,
    actions: FormikHelpers<InnovationAwardsForm>
  ) => {
    await onSubmit(values)
    actions.setSubmitting(false)
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    projectTitle: Yup.string().required("This field is required."),
    projectLink: Yup.string()
      .required("This field is required.")
      .test(
        `test-project-link`,
        "The link must be a Spectacular published project",
        function (value) {
          return checkIfUrlIsValid(value!)
        }
      )
      .test(
        `test-project-alreadt-submitted`,
        "Your project was already submitted to Innovation Awards",
        function (value) {
          return checkIfProjectWasAlreadySubmitted(value!)
        }
      ),
  })

  return (
    <FormArea id="awardForm">
      <FormContainer>
        <FormBG>
          <Formik
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
            initialValues={initialValues}
            validationSchema={ValidationSchema}
          >
            {(formik) => (
              <Form>
                <FormTitle variant={"h1"}>Submit Your Project</FormTitle>
                <FormSubTitle>
                  Required fields are marked with an asterisk <span>*</span>
                </FormSubTitle>
                <Box>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="John Doe"
                    displayTitle="Name"
                    style={{ height: "48px" }}
                    presetValue={
                      authenticatedProfile
                        ? authenticatedProfile.firstName +
                          " " +
                          authenticatedProfile.lastName
                        : undefined
                    }
                  />
                </Box>
                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="projectLink"
                    displayTitle="Project Link"
                    placeholder="Add the URL"
                    style={{ height: "48px" }}
                  />
                </FormSpacingBox>

                <FormSpacingBox>
                  <TextField
                    fullWidth
                    name="projectTitle"
                    displayTitle="Project Title"
                    placeholder="Provide project name"
                    style={{ height: "48px" }}
                  />
                </FormSpacingBox>
                <FormSpacingBox>
                  <Button
                    type={"submit"}
                    children={"Submit Project"}
                    color={"secondary"}
                    size={"medium"}
                    disabled={formik.isSubmitting}
                    style={{
                      width: "203px",
                      height: "56px",
                      fontSize: "16px",
                    }}
                  />
                </FormSpacingBox>
              </Form>
            )}
          </Formik>
        </FormBG>
      </FormContainer>
      <SuccessDialog
        open={showMessage}
        onClose={onClose}
        projectLink={projectLink}
        project={project}
      />
    </FormArea>
  )
}

export default FormBox
