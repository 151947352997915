import React from "react"
import { useHistory } from "react-router-dom"
import View from "./View"

const AdminNavBar = () => {
  let history = useHistory()
  const handleGoToHome = () => history.push("/")

  return <View goToHome={handleGoToHome} />
}

export default AdminNavBar
