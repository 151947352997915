import React from "react"
import View from "./View"
import Loader from "./Loader"
import { WinnerVideos } from "../../redux/competition"

interface IBreakpoint {
  width: number
  itemsToShow: number
  itemsToScroll: number
}

export const defaultBreakpoints = (expanded: boolean): IBreakpoint[] => [
  {
    width: 400,
    itemsToShow: expanded ? 1 : 2,
    itemsToScroll: expanded ? 1 : 2,
  },
  {
    width: 550,
    itemsToShow: expanded ? 1 : 3,
    itemsToScroll: expanded ? 1 : 3,
  },
  {
    width: 850,
    itemsToShow: expanded ? 1 : 4,
    itemsToScroll: expanded ? 1 : 4,
  },
]

export const getBreakpoint = (breakpoints: IBreakpoint[], width: number) => {
  const breakpoint = breakpoints.find((x) => width <= x.width + 450)

  if (!breakpoint) return breakpoints[breakpoints.length - 1]

  return breakpoint
}

export interface VideoGalleryProps {
  videos: WinnerVideos[]
}

type Props = VideoGalleryProps

const VideoGallery: React.FC<Props> = ({ videos }) => {
  const [mounted, setMounted] = React.useState(false)
  const [state, setState] = React.useState<WinnerVideos[]>([])
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selected, setSelected] = React.useState<WinnerVideos>(videos[0])

  React.useEffect(() => {
    ;(async () => {
      try {
        const newState = new Array<WinnerVideos>()
        for (const video of videos) {
          newState.push(video)
        }
        setMounted(true)
        setState(newState)
        setSelected(newState[0])
      } catch (error) {
        console.error(error)
      }
    })()
  }, [videos])

  const handleSelect = (video: WinnerVideos, idx: number) => {
    setSelected(video)
    setSelectedIndex(idx)
  }

  if (!mounted) return <Loader />

  if (!selected || videos.length === 0)
    return <div style={{ textAlign: "center" }} /> // we need a null state

  return (
    <View
      videos={state}
      onSelect={handleSelect}
      selectedIndex={selectedIndex}
    />
  )
}

export default VideoGallery
