import styled from "styled-components"

import theme, { colors } from "../../theme"

export const BellContainer = styled.div<{
  hover?: any
  isNav: boolean
  width: number
  height: number
}>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ hover }) => (hover ? `cursor: pointer;` : "")}
  background-color: ${colors.white};
  white-space: nowrap;

  @media only screen and (max-width: 1230px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media only screen and (max-width: 1130px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  && img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    font-family: Public-Sans-Medium;
  }
`

export const RedDot = styled.div`
  position: absolute;
  background-color: #f44c49;
  min-width: 14px;
  margin-left: -16px;
  color: #fff;
  border-radius: 8px;
  font-size: 10px;
  height: 14px;
  margin-top: -37px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  border: 1px solid #ffffff;
  word-spacing: -1px;
`
