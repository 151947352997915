import React from "react"

import Header from "./Header"

import {
  IconBox,
  IconButtonBox,
  IconTitle,
  MainContainer,
  RegistrationBox,
  RegistrationContainer,
  RegistrationFlexBox,
  RegistrationLeftBox,
  RegistrationRightBox,
  StyledIcon,
} from "./styles"

import { Button } from "../../components"

import FirmIcon from "../../images/career-fair/icon-firm.svg"
import IndividualIcon from "../../images/career-fair/icon-individual.svg"

interface Props {
  isAuthenticated: boolean
  onChangeView: (view: string) => void
}

const View = ({ isAuthenticated, onChangeView }: Props) => {
  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <Header currentView={"home"} />

      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <RegistrationLeftBox>
              <IconBox>
                <StyledIcon src={IndividualIcon} alt="Individual" />
                <IconTitle>Individual</IconTitle>
                <IconButtonBox>
                  <Button
                    children={"Learn more"}
                    color="secondary"
                    style={{ width: "298px" }}
                    onClick={() => onChangeView("individual")}
                  />
                </IconButtonBox>
              </IconBox>
            </RegistrationLeftBox>
            <RegistrationRightBox>
              <IconBox>
                <StyledIcon src={FirmIcon} alt="Firm" />
                <IconTitle>Firm</IconTitle>
                <IconButtonBox>
                  <Button
                    children={"Learn more"}
                    color="secondary"
                    style={{ width: "298px" }}
                    onClick={() => onChangeView("firm")}
                  />
                </IconButtonBox>
              </IconBox>
            </RegistrationRightBox>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>
    </MainContainer>
  )
}

export default View
