import React, { useEffect } from "react"
import { Box, Button } from "@material-ui/core"
import { MentionsInput, Mention } from "react-mentions"

import defaultStyle from "./defaultStyle"
import defaultStyleEdit from "./defaultStyleEdit"
import whiteStyle from "./whiteStyle"
import defaultMentionStyle from "./defaultMentionStyle"
import {
  DescriptionBox,
  MentionsBox,
  NameTitle,
  PostButton,
  UserBox,
} from "./styles"
import Avatar from "../Avatar"

export type Props = {
  handleChangeComment: (comment: string) => void
  handleGetSuggestions: (text: string, callback: any) => void
  onSave: () => void
  handleEnteringComment: (value: boolean) => void
  comment: string
  commenting: boolean
  oneLine?: boolean
  focus?: boolean
  replyFromDetails?: boolean
  isEditMode?: boolean
  toggleEditMode?: () => void
}

const View = ({
  handleChangeComment,
  handleGetSuggestions,
  onSave,
  handleEnteringComment,
  commenting,
  comment,
  oneLine,
  focus,
  replyFromDetails,
  isEditMode,
  toggleEditMode,
}: Props) => {
  const handleCancel = () => {
    handleEnteringComment(false)
    handleChangeComment("")
    if (toggleEditMode) toggleEditMode()
  }

  let inputEl = React.createRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (focus) {
      inputEl?.current?.focus()
    }
  }, [focus, inputEl])

  return (
    <MentionsBox
      $hasInnerButton={oneLine}
      $replyFromDetails={replyFromDetails}
      $isEditMode={isEditMode}
    >
      <MentionsInput
        inputRef={inputEl}
        onFocus={() => handleEnteringComment(true)}
        value={comment}
        onChange={(e) => handleChangeComment(e.target.value)}
        style={
          oneLine ? whiteStyle : isEditMode ? defaultStyleEdit : defaultStyle
        }
        allowSpaceInQuery
        placeholder={"Add a comment..."}
        forceSuggestionsAboveCursor
      >
        <Mention
          trigger={"@"}
          markup={"@{{__display__}}{{(__id__)}}"}
          displayTransform={(id, name) => `@${name}`}
          data={handleGetSuggestions}
          style={defaultMentionStyle}
          appendSpaceOnAdd
          renderSuggestion={(suggestion) => {
            const newData = { ...suggestion } as any
            return (
              <UserBox>
                <Avatar profile={newData} width={32} height={32} />
                <DescriptionBox>
                  <NameTitle>{newData.name}</NameTitle>
                </DescriptionBox>
              </UserBox>
            )
          }}
        />
      </MentionsInput>

      {commenting && !oneLine && !replyFromDetails && (
        <>
          <Box paddingTop={1} paddingBottom={1}>
            <Box marginRight={2} display="inline">
              <Button
                size="small"
                color="primary"
                data-testid="saveButton"
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </>
      )}

      {(oneLine || replyFromDetails) && (
        <PostButton onClick={onSave}>Post</PostButton>
      )}
    </MentionsBox>
  )
}

export default View
