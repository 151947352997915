import React from "react"
import Image from "material-ui-image"

import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"

interface ImageProps {
  src?: string
  alt?: string
  placeholderAvatar?: number
}

const SquarePicture = ({ src, placeholderAvatar, alt }: ImageProps) => {
  return (
    <Image
      src={
        src === undefined
          ? getRandomPlaceholder(placeholderAvatar!)
          : (src as string)
      }
      disableSpinner
      style={{
        objectFit: "cover",
        objectPositon: "50% 50%",
      }}
      alt={alt}
    />
  )
}

export default SquarePicture
