import React from "react"
import styled from "styled-components"
import { TextField, TextFieldProps } from "@material-ui/core"

import "./edge-clearfix.css"
import { useWindowDimensions } from "../../../hooks"

const Field = styled(TextField)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  && {
    .MuiInputLabel-filled {
      transform: translate(12px, 14px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.7);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }
  }
`

export type Props = TextFieldProps & {
  errorMessage?: string | null
  inputTestId?: string
  compact?: any
  mobile?: any
}

const View = ({
  mobile,
  compact,
  inputTestId,
  errorMessage,
  ...props
}: Props) => {
  const { width } = useWindowDimensions()
  return (
    <Field
      {...props}
      error={!!errorMessage}
      style={
        !!errorMessage
          ? { paddingBottom: "5px" }
          : { paddingBottom: width < 960 ? "16px" : "24px" }
      }
      helperText={errorMessage || ""}
      InputLabelProps={{
        style: {
          color: "#979797",
          fontSize: mobile ? "14px" : "16px",
        },
      }}
      InputProps={{
        style: { maxHeight: "48px" },
        ...props.InputProps,
      }}
    />
  )
}

export default View
