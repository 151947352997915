import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { fetchProject, Project } from "../../redux/projects"
import { useHistory } from "react-router-dom"
import LoadingPage from "../LoadingPage"

import View from "./view"
import MobileView from "./MobileView"
import { useWindowDimensions } from "../../hooks"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import camelize from "../../utils/camelize"

interface Params {
  id: string
}

const ProjectDetailPage = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams<Params>()

  const { user, isAuthenticated } = useAuth0()
  const email = user?.email

  const project = useSelector((state: any): Project => state.projects.project)
  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )
  const history = useHistory()
  const { width } = useWindowDimensions()

  const isProjectOwner =
    authenticatedProfile?.connectionId === project?.profile?.connectionId

  const isCollaborator = project?.collaborators?.filter(
    (value) => value === authenticatedProfile?.connectionId
  )

  const goToUrl = (url: string) => history.push(url)

  useEffect(() => {
    ;(async () => {
      try {
        const { type, payload } = await dispatch(fetchProject({ id, email }))

        if (
          (!isAuthenticated && !payload.published) ||
          (payload.profile?.username !== authenticatedProfile?.username &&
            !payload.published)
        ) {
          goToUrl(`/sp/${payload.profile?.username}`)
        }

        if (fetchProject.fulfilled.type === type) {
          setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true
          }, 0)
        }
      } catch (error) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line
  }, [dispatch, id, email])

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 0)
  }, [history.location.hash])

  if (!project) {
    return <LoadingPage />
  } else {
    const screenTitle = `Project Details - ${project.name}`
    return (
      <>
        <RouteChangeTracker
          screenTitle={screenTitle}
          classTitle={camelize(screenTitle)}
        />
        <Helmet>
          <meta name="title" content={`${project.name} - Spectacular`} />
          <meta name="description" content={project.description} />
          <meta property="og:title" content={`${project.name} - Spectacular`} />
          <meta property="og:type" content="project" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={project.medImageUrl} />
          <meta property="og:description" content={project.description} />
          <meta property="og:site_name" content={"Spectacular"} />
          <meta property="og:locale" content="en_US" />
          <title>{`${project.name} - Spectacular`}</title>
        </Helmet>
        {width > 960 ? (
          <View
            project={project}
            goToUrl={goToUrl}
            authenticatedProfile={authenticatedProfile}
            isProjectOwner={isProjectOwner}
            isCollaborator={isCollaborator}
          />
        ) : (
          <MobileView
            project={project}
            goToUrl={goToUrl}
            authenticatedProfile={authenticatedProfile}
            isProjectOwner={isProjectOwner}
          />
        )}
      </>
    )
  }
}

export default ProjectDetailPage
