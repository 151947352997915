import React, { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useAppDispatch } from "../../redux/configureStore"
import { Box } from "@material-ui/core"

import NotificationCard from "../../cards/NotificationCard"

import {
  fetchNotifications,
  updateNotificationStateById,
} from "../../redux/bellNotifications"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"
import { useAuth0 } from "@auth0/auth0-react"

export type Props = {
  onClose?: () => void
  handleChangeHasMore: (value: boolean) => void
  handleChangePagination: (value: number) => void
  notificationState?: string
  hasMore: boolean
  page: number
}

const NotificationsBell = ({
  onClose,
  handleChangeHasMore,
  handleChangePagination,
  notificationState,
  hasMore,
  page,
}: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const notifications = useSelector(
    (state: any) => state.bellNotifications?.allNotifications
  )
  const notificationsCount = useSelector(
    (state: any) => state.bellNotifications?.notificationsCount
  )

  const handleNotificationClick = async (notificationId?: string) => {
    if (!notificationId) {
      setTimeout(function () {
        if (onClose) onClose()
      }, 1000)
    } else {
      try {
        const state = "clicked"
        const { type } = await dispatch(
          updateNotificationStateById({ auth, notificationId, state })
        )
        if (updateNotificationStateById.fulfilled.type === type) {
          if (onClose) onClose()
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }

  const fetchMoreData = () => {
    if (notifications.length >= notificationsCount) {
      handleChangeHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  const loadNotifications = useCallback(
    async (reset) => {
      try {
        await dispatch(
          fetchNotifications({
            currentPage: page,
            auth,
            reset,
            notificationState,
          })
        )

        if (reset) {
          handleChangePagination(0)
          handleChangeHasMore(true)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line
    [dispatch, auth, page]
  )

  useEffect(() => {
    loadNotifications(false)
  }, [loadNotifications])

  const seeAll = () => {
    if (onClose) onClose()
    history.push("/network/connections")
  }

  const notificationComponent = (
    notification: any,
    displayLine: string,
    showDivider: boolean,
    index: number
  ) => {
    return (
      <>
        {displayLine !== "none" ? (
          <>
            {showDivider ? (
              <Box
                key={index + "divider"}
                style={{
                  width: "97%",
                  paddingBottom: "16px",
                  marginTop: "4px",
                  borderTop: "0.5px solid #d8d8d8",
                }}
              />
            ) : null}
            <Box
              key={index + "line"}
              style={{ width: "100%", paddingBottom: "4px", fontSize: "20px" }}
            >
              {displayLine === "Connect Requests" ? (
                <Box
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>{displayLine}</Box>
                  <Box
                    onClick={seeAll}
                    style={{
                      fontSize: "14px",
                      color: "#717171",
                      paddingRight: "16px",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    See all
                  </Box>
                </Box>
              ) : (
                <>{displayLine}</>
              )}
            </Box>
          </>
        ) : null}

        <NotificationCard
          handleNotificationClick={handleNotificationClick}
          index={index}
          notification={notification}
        />
      </>
    )
  }

  let countToday = 0,
    countWeek = 0,
    countMonth = 0,
    countEarlier = 0,
    countConnections = 0

  return (
    <>
      {notifications ? (
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="notificationsContainer"
        >
          {notifications.map((notification: any, index: number) => {
            if (
              (notification.origin === "comment" ||
                notification.origin === "commentReply" ||
                notification.origin === "commentMention" ||
                notification.origin === "collaborator" ||
                notification.origin === "like" ||
                notification.origin === "share" ||
                notification.origin === "nomination") &&
              !notification.project
            )
              return null

            let displayLine = "none"
            let showDivider = false

            if (notification.origin !== "connection") {
              if (moment().isSame(notification.createdAt, "day")) {
                countToday++
                if (countToday === 1) {
                  displayLine = "Today"
                  showDivider = countConnections > 0
                }
              } else if (
                moment().isSame(notification.createdAt, "week") &&
                !moment().isSame(notification.createdAt, "day")
              ) {
                countWeek++
                if (countWeek === 1) {
                  displayLine = "This week"
                  showDivider = countToday > 0 || countConnections > 0
                }
              } else if (
                moment().isSame(notification.createdAt, "month") &&
                !moment().isSame(notification.createdAt, "week") &&
                !moment().isSame(notification.createdAt, "day")
              ) {
                countMonth++
                if (countMonth === 1) {
                  displayLine = "This month"
                  showDivider =
                    countToday > 0 || countWeek > 0 || countConnections > 0
                }
              } else {
                countEarlier++
                if (countEarlier === 1) {
                  displayLine = "Earlier"
                  showDivider =
                    countToday > 0 ||
                    countWeek > 0 ||
                    countMonth > 0 ||
                    countConnections > 0
                }
              }
            } else {
              countConnections++
              if (countConnections === 1) {
                displayLine = "Connect Requests"
              }
            }

            return notificationComponent(
              notification,
              displayLine,
              showDivider,
              index
            )
          })}
        </InfiniteScroll>
      ) : null}
    </>
  )
}

export default NotificationsBell
