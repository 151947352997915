import React, { MouseEvent, useState } from "react"
import { useLocation, NavLink as RouterLink } from "react-router-dom"
import { ItemBox, MenuBox, StyledLink } from "./styles"
import MenuArrow from "../../images/new-header/menu-drop-down.svg"
import MenuArrowActive from "../../images/new-header/menu-drop-down-active.svg"
import MenuArrowHover from "../../images/new-header/menu-drop-down-hover.svg"
import { Popover } from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectCompetitions } from "../../redux/competition"

export const AwardsNavItem = () => {
  const [isHover, setIsHover] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const competitions = useSelector(selectCompetitions)
  const navCompetitions = competitions?.filter(
    (competition) => competition.showOnNavbar
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const location = useLocation()
  const active = navCompetitions?.some((competition) =>
    competition.url ? location.pathname.includes(competition.url) : false
  )

  const MenuIcon = open
    ? MenuArrowActive
    : active || isHover
    ? MenuArrowHover
    : MenuArrow

  return (
    <>
      <ItemBox
        $isActive={active}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
      >
        Awards
        <img src={MenuIcon} alt="Arrow" />
      </ItemBox>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.1)",
            marginTop: "30px",
          },
        }}
      >
        <MenuBox>
          {navCompetitions?.map((competition, key) => {
            return (
              <StyledLink
                onClick={handleClose}
                component={RouterLink}
                to={competition.url}
                key={`comp-${key}`}
              >
                {competition.displayTitle}
                {" - "}
                {competition.date}
              </StyledLink>
            )
          })}
        </MenuBox>
      </Popover>
    </>
  )
}
