export const resolveImageDimensions = (file: any) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const image = new Image()
    let url: string
    image.onload = () => {
      file.width = image.width
      file.height = image.height
      resolve({ width: image.width, height: image.height })
    }
    url = URL.createObjectURL(file)
    image.src = url
  })
