import React from "react"

import { Notification } from "../../redux/bellNotifications"

import View from "./View"

type Props = {
  notification: Notification
  onNotificationClick: (notificationId: string) => void
  goToNotifications?: boolean
}

const NotificationPost = ({
  notification,
  goToNotifications,
  onNotificationClick,
}: Props) => {
  return (
    <View
      notification={notification}
      onNotificationClick={onNotificationClick}
      goToNotifications={goToNotifications}
    />
  )
}

export default NotificationPost
