import React from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { createEducation, Education } from "../../redux/educations"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

const EducationNewPage = () => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleCreateEducation = async (education: Education) => {
    try {
      const { type } = await dispatch(createEducation({ education, auth }))
      if (createEducation.fulfilled.type === type) {
        history.push(`/experiences/edit`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`New Education`}
        classTitle="NewEducation"
      />
      <Helmet>
        <title>New Education - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`New Education - Spectacular`} />
        <meta name="title" content={`New Education - Spectacular`} />
      </Helmet>
      <View onSubmit={handleCreateEducation} />
    </>
  )
}

export default EducationNewPage
