import React from "react"
import { Navbar } from "./styles"
import { NetworkNavIcon } from "../NetworkNavIcon"
import InviteBellMobile from "../InviteBellMobile"
import { HomeNavIcon } from "../HomeNavIcon"
import { JobsNavIcon } from "../JobsNavIcon"
import { NewPostNavIcon } from "../NewPostNavIcon"

const FooterNavbar = () => {
  return (
    <Navbar>
      <HomeNavIcon />
      <NetworkNavIcon />
      <NewPostNavIcon />
      <JobsNavIcon />
      <InviteBellMobile />
    </Navbar>
  )
}

export default FooterNavbar
