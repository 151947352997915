import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import Cookies from "universal-cookie"

import View from "./View"
import environment from "../../environment"
import { Browser } from "@capacitor/browser"

const VerifiedEmail = () => {
  const cookies = new Cookies()
  cookies.set("verified_email", true, {
    path: "/",
  })
  const { loginWithRedirect } = useAuth0()

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  setTimeout(() => {
    login()
  }, 100)

  return <View signIn={login} />
}
export default VerifiedEmail
