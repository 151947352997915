import React, { useState } from "react"
import View from "./View"
import { Profile } from "../../redux/profile"
import { Post, PostMention, updatePost, uploadImages } from "../../redux/posts"
import { Image } from "../../redux/projects"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  post: Post
  authenticatedProfile: Profile
  handleEditDialogOpen: (value: boolean) => void
  isEditDialogOpen: boolean
}

const EditPost = ({
  post,
  authenticatedProfile,
  isEditDialogOpen,
  handleEditDialogOpen,
}: Props) => {
  const [uploadedImages, setUploadedImages] = useState<any[]>([])
  const [currentFile, setCurrentFile] = useState<string>("")
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isPosting, setIsPosting] = useState<boolean>(false)

  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleUploadImages = async (images: Image[], post: Post) => {
    for (const key in images) {
      setIsUploading(true)

      const image = images[key as any]
      if (!image.id) {
        if (uploadedImages.findIndex((x) => x === image.file.name) === -1) {
          setUploadedImages([image.file.name, ...uploadedImages])
          setCurrentFile(image.file.name)

          const newImage = await dispatch(
            uploadImages({
              postId: post.id as string,
              fileType: image.file.type,
              order: image.order,
              width: image.dimensions?.width,
              height: image.dimensions?.height,
              auth,
              image,
            })
          )

          //Upload the image to the S3 bucket
          const myHeaders = new Headers()
          myHeaders.append("Content-Type", images[key as any].file.type)

          await fetch(newImage.payload.signedUrl, {
            method: "PUT",
            headers: myHeaders,
            body: images[key as any].file,
          })
        }
      }
    }
  }

  const handleSave = async (
    content: string,
    images: Image[],
    removedImages: string[],
    mentions?: PostMention[],
    postId?: string
  ) => {
    try {
      setIsPosting(true)

      const { payload, type } = await dispatch(
        updatePost({
          auth,
          postId: postId!,
          content,
          mentions,
          removedImages,
        })
      )
      if (updatePost.fulfilled.type === type) {
        //UPLOAD IMAGES
        await handleUploadImages(images, payload)
        //FETCH POSTS
        setIsUploading(false)
        setIsPosting(false)
        handleEditDialogOpen(false)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <View
      post={post}
      authenticatedProfile={authenticatedProfile}
      isEditDialogOpen={isEditDialogOpen}
      currentFile={currentFile}
      isUploading={isUploading}
      isPosting={isPosting}
      onSubmit={handleSave}
      handleEditDialogOpen={handleEditDialogOpen}
    />
  )
}

export default EditPost
