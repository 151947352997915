import React from "react"
import { CircularProgress } from "@material-ui/core"

import {
  Avatar,
  ProjectThumbnail,
  TextBox,
  CardBox,
  ContentBox,
  NetworkingBox,
  LinkBox,
  ProjectThumbnailPlaceholder,
  TimeBox,
  ProjectsBox,
  AvatarBox,
} from "./styles"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { Button } from "../../components/Button"
import { CollaboratorManagement } from "../../redux/connections"

import PendingIcon from "../../images/network/icon-pending.png"
import { ButtonIcon } from "../ConnectionCard/styles"
import moment from "moment"
import AmbassadorIcon from "../../images/student-ambassador-icon.svg"

export type Props = {
  collaborator: CollaboratorManagement
  goToProject: (projectData: any) => string
  goToProfile: () => string
  isLoading: boolean
  onConfirm: (collaboratorId: string) => void
  onRemove: (collaboratorId: string) => void
}

const View = ({
  collaborator,
  goToProject,
  goToProfile,
  isLoading,
  onConfirm,
  onRemove,
}: Props) => {
  return (
    <CardBox>
      <AvatarBox>
        <LinkBox to={goToProfile}>
          <Avatar
            src={
              collaborator.smallImageUrl === undefined
                ? getRandomPlaceholder(collaborator.placeholderAvatar!)
                : (collaborator.smallImageUrl as string)
            }
          />
        </LinkBox>
      </AvatarBox>
      <ContentBox>
        {collaborator.origin === "received" ? (
          <>
            <TextBox>
              <span>{collaborator.displayName}</span>
              {collaborator.isAmbassador && (
                <img
                  src={AmbassadorIcon}
                  alt="Student Ambassador"
                  className="StudentAmbassadorIcon"
                />
              )}
              wants to invite you as a collaborator on{" "}
              <span>{collaborator.projectName}</span> project.
            </TextBox>
            <TimeBox>{moment(collaborator.inviteDate).fromNow()}</TimeBox>
            <NetworkingBox>
              {collaborator.status === "pending" ? (
                <>
                  <Button
                    onClick={() => onConfirm(collaborator.id!)}
                    children={
                      isLoading ? (
                        <CircularProgress size={16} style={{ color: "#fff" }} />
                      ) : (
                        <>Accept</>
                      )
                    }
                    color={"secondary"}
                  />
                  <Button
                    onClick={() => onRemove(collaborator.id!)}
                    children={
                      isLoading ? (
                        <CircularProgress
                          size={16}
                          style={{ color: "#2d2d2d" }}
                        />
                      ) : (
                        <>Remove</>
                      )
                    }
                    color={"lightBlue"}
                  />
                </>
              ) : (
                <>
                  <Button children={<>Accepted</>} disabled={true} />
                </>
              )}
            </NetworkingBox>
          </>
        ) : (
          <>
            <TextBox>
              You invited <span>{collaborator.displayName}</span>
              {collaborator.isAmbassador && (
                <img
                  src={AmbassadorIcon}
                  alt="Student Ambassador"
                  className="StudentAmbassadorIcon"
                />
              )}{" "}
              as a collaborator on <span>{collaborator.projectName}</span>{" "}
              project.
            </TextBox>
            <TimeBox>{moment(collaborator.inviteDate).fromNow()}</TimeBox>
            <NetworkingBox>
              {collaborator.status === "pending" ? (
                <>
                  <Button
                    children={
                      <>
                        <ButtonIcon src={PendingIcon} alt={"Pending"} /> Pending
                      </>
                    }
                    color={"lightGrey"}
                    style={{ cursor: "default" }}
                  />
                </>
              ) : (
                <>
                  <Button children={<>Accepted</>} disabled={true} />
                </>
              )}
            </NetworkingBox>
          </>
        )}
      </ContentBox>
      <ProjectsBox marginTop={{ xs: 2, sm: 0 }}>
        {!collaborator.projectThumbnail ? (
          <ProjectThumbnailPlaceholder />
        ) : (
          <>
            {
              <LinkBox to={goToProject(collaborator)}>
                <ProjectThumbnail
                  src={collaborator.projectThumbnail}
                  alt={`Network for architects ${collaborator.projectName}`}
                />
              </LinkBox>
            }
          </>
        )}
      </ProjectsBox>
    </CardBox>
  )
}

export default View
