import React from "react"
import { FormikHelpers } from "formik"
import { useHistory } from "react-router-dom"

import View from "./View"

import { Education } from "../../redux/educations"

export type Props = {
  education?: Education
  onSubmit: (education: Education) => void
}

const EducationNewPage = ({ education, onSubmit }: Props) => {
  let history = useHistory()

  const handleOnSubmitForm = async (
    education: Education,
    helpers: FormikHelpers<Education>
  ) => {
    if (education.current) education.endYear = undefined

    if (education.endYear && education.endYear < education.startYear) {
      helpers.setFieldError("endYear", "End year can not be before start year")
      return
    }

    onSubmit(education)
  }

  const onCancel = () => history.push("/experiences/edit")

  const initialValues: Education = {
    school: "",
    degree: "",
    field: "",
    startYear: 0,
    endYear: 0,
    current: false,
    ...education,
    additionalInfo: education?.additionalInfo ? education?.additionalInfo : "",
    additionalInfoRTF: education?.additionalInfoRTF
      ? education?.additionalInfoRTF
      : "",
  }

  return (
    <View
      values={initialValues}
      onSubmit={handleOnSubmitForm}
      onCancel={onCancel}
    />
  )
}

export default EducationNewPage
