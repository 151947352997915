import { Box } from "@material-ui/core"
import styled from "styled-components"
import { colors } from "../../theme"

export const IconContainer = styled(Box)<{ $isActive: boolean }>`
  display: flex;
  flex-flow: row;
  cursor: pointer;
  justify-content: center;

  && p {
    font-size: 12px;
    margin-top: 5px;
    color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#717171")};
  }
`

export const Icon = styled.img`
  height: 20px;
`

export const CountLabel = styled.span`
  font-size: 12px;
  color: ${colors.slate};
  font-family: Public-Sans;
  margin-top: 2px;
  line-height: 12px;
`

export const IconFlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
`

export const CounterFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
`
