import React from "react"

import { NewsCardActionsBox } from "../../components/Styles/Global"
import { News } from "../../redux/adminNews"
import NewsLikeIcon from "../NewsLikeIcon"
import NewsShareIcon from "../NewsShareIcon"

type Props = {
  news: News
}

const View = ({ news }: Props) => {
  return (
    <NewsCardActionsBox>
      <NewsLikeIcon news={news} />
      <NewsShareIcon news={news} />
    </NewsCardActionsBox>
  )
}

export default View
