import React, { useState } from "react"
import { Box } from "@material-ui/core"
import {
  FilterDialog,
  FilterDialogBox,
  FilterDialogTitle,
  DialogOption,
  FilterDialogHeader,
  DialogCloseButton,
} from "./Styles"
import { Button } from "../Button"
import { ActionsType } from "./View"
import Active from "../../images/dialog-checked.svg"
import Inactive from "../../images/dialog-not-checked.svg"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"

import "./fix-dialog.css"

export type Props = {
  onClick: (value: ActionsType) => void
  onClose: () => void
  open: boolean
  actions: ActionsType[]
  title: string
}

const Dialog = ({ onClick, actions, onClose, open, title }: Props) => {
  const [selected, setSelected] = useState(actions[0])
  const [hover, setHover] = useState(false)

  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

  return (
    <FilterDialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      className={"DropDownFilterDialog"}
    >
      <FilterDialogHeader>
        <FilterDialogTitle>{title}</FilterDialogTitle>
        <DialogCloseButton
          src={hover ? CloseIconHover : CloseIcon}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alt={"close"}
          onClick={onClose}
        />
      </FilterDialogHeader>
      <FilterDialogBox>
        <Box paddingRight={2.5} paddingLeft={2.5}>
          {actions.map((action) => (
            <DialogOption onClick={() => setSelected(action)}>
              <img
                src={selected.action === action.action ? Active : Inactive}
                alt={"checked"}
              />
              {action.display}
            </DialogOption>
          ))}
        </Box>
        <Box
          paddingRight={2.5}
          paddingLeft={2.5}
          paddingTop={3}
          style={{ marginTop: "auto", paddingBottom: iOSSafari ? "65px" : "" }}
        >
          <Button
            style={{
              width: "100%",
              height: "48px",
              fontSize: "14px",
              fontWeight: 700,
            }}
            color="primary"
            size="small"
            type="submit"
            onClick={() => onClick(selected)}
          >
            Show results
          </Button>
        </Box>
      </FilterDialogBox>
    </FilterDialog>
  )
}

export default Dialog
