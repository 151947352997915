import React, { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"

import { useAppDispatch } from "../../redux/configureStore"
import { Box } from "@material-ui/core"

import NotificationFollow from "../NotificationFollow"
import NotificationDotMenu from "../NotificationDotMenu"
import LoadingPage from "../../pages/LoadingPage"

import {
  fetchRequests,
  updateNotificationStateById,
  updateNotificationsState,
} from "../../redux/bellNotifications"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"
import { useAuth0 } from "@auth0/auth0-react"

const ConnectionRequestList = () => {
  moment.locale("en", {
    relativeTime: {
      s: "%d s",
      m: "1 m",
      mm: "%d m",
      h: "1 h",
      hh: "%d h",
      d: "1 d",
      dd: function (number) {
        if (number < 7) {
          return number + " d"
        } else {
          var weeks = Math.round(number / 7)
          return weeks + " w"
        }
      },
      M: "1 mo",
      MM: "%d mo",
      y: "1 y",
      yy: "%d y",
    },
  })

  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const [hasMore, setHasMore] = useState(true)
  const [notificationState, setNotificationState] = useState("all")

  const currentPage = useSelector(
    (state: any) => state.bellNotifications?.requestCurrentPage
  )

  const [page, setPage] = React.useState(currentPage > 0 ? currentPage : 0)

  const profile = useSelector((state: any) => state.profile.profile)

  const notifications = useSelector(
    (state: any) => state.bellNotifications.allRequests
  )
  const notificationsCount = useSelector(
    (state: any) => state.bellNotifications.requestsCount
  )

  const profileId = profile.connectionId

  const fetchMoreData = () => {
    if (notifications.length >= notificationsCount) {
      setHasMore(false)
      return
    }

    handleChangePagination(page + 1)
  }

  const handleChangePagination = (value: number) => {
    setPage(value)
  }

  const handleChangeHasMore = (value: boolean) => {
    setHasMore(value)
  }

  const loadNotifications = useCallback(async () => {
    try {
      await dispatch(
        fetchRequests({ currentPage: page, auth, notificationState })
      )
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [dispatch, auth, page])

  useEffect(() => {
    loadNotifications()
  }, [loadNotifications])

  const handleNotificationClick = async (notificationId: string) => {
    try {
      const state = "clicked"
      const { type } = await dispatch(
        updateNotificationStateById({ auth, notificationId, state })
      )
      if (updateNotificationStateById.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleAllRead = async () => {
    try {
      const state = "clicked"
      const { type } = await dispatch(
        updateNotificationsState({ auth, profileId, state })
      )
      if (updateNotificationsState.fulfilled.type === type) {
        await dispatch(
          fetchRequests({
            currentPage: 0,
            auth,
            notificationState,
          })
        )

        handleChangeHasMore(true)
        handleChangePagination(0)

        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleChangeState = async (value: string) => {
    setNotificationState(value)
    await dispatch(
      fetchRequests({
        currentPage: 0,
        auth,
        notificationState: value,
      })
    )

    handleChangeHasMore(true)
    handleChangePagination(0)
  }

  return (
    <>
      <Box
        style={{
          fontSize: "25px",
          height: "64px",
          alignContent: "center",
          display: "flex",
          flexFlow: "wrap",
          padding: "0 32px 0 16px",
          justifyContent: "space-between",
        }}
      >
        <Box>Connection requests</Box>
        <NotificationDotMenu
          handleAllRead={handleAllRead}
          handleChangeState={handleChangeState}
        />
      </Box>
      <Box
        style={{
          width: "100%",
          borderTop: "0.5px solid #d8d8d8",
        }}
      />
      <Box style={{ padding: "16px 16px" }}>
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={notifications.length > 0 ? <LoadingPage /> : null}
          scrollableTarget="notificationsContainer"
        >
          {notifications.map((notification: any, index: number) => {
            return (
              <NotificationFollow
                onNotificationClick={handleNotificationClick}
                notification={notification}
              />
            )
          })}

          {notifications.length === 0 ? (
            <Box
              style={{
                textAlign: "center",
                marginTop: "54px",
                minHeight: "400px",
                fontSize: "18px",
              }}
            >
              No new connection <br /> requests
            </Box>
          ) : null}
        </InfiniteScroll>
      </Box>
    </>
  )
}

export default ConnectionRequestList
