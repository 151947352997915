import React, { useState } from "react"
import {
  Box,
  Link,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core"

const defaultOptions = [
  "NCARB",
  "AIA",
  "Associate AIA",
  "FAIA",
  "LEED",
  "LEED AP BD+C",
  "LEED AP O+M",
  "LEED AP ID+C",
  "LEED AP ND",
  "LEED Homes",
  "LEED Green Associate",
  "Licensed",
  "NCIDQ",
  "Other",
]

export type Props = {
  value: string
  credentials: string[]
  suggestions: string[]
  displayError: boolean
  onInputChange: (value: string) => void
  onEnteredPressed: (jobTag: string) => void
  onDeletedPressed: (jobTag: string) => void
}

const View = ({ credentials, onEnteredPressed }: Props) => {
  const [showAll, setShowAll] = useState(false)
  const [options, setOptions] = useState(defaultOptions.slice(0, 4))
  const [showLink, setShowLink] = useState("Show all...")

  const onShowAll = () => {
    if (!showAll) {
      setOptions(defaultOptions)
      setShowLink("Show less...")
    } else {
      setOptions(defaultOptions.slice(0, 4))
      setShowLink("Show all...")
    }
    setShowAll(!showAll)
  }

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    onEnteredPressed(target.value)

  const onBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    onEnteredPressed(target.value)
  }
  return (
    <Box marginBottom={3}>
      <FormControl component="fieldset">
        <Box marginBottom={1}>
          <FormGroup>
            {options.map((option) => {
              const otherCredential = credentials.filter(
                (e) => !options.includes(e)
              )
              return option === "Other" ? (
                <FormControlLabel
                  label=""
                  control={
                    <TextField
                      name={option}
                      defaultValue={otherCredential}
                      label="Other"
                      data-testid="other"
                      onBlur={onBlur}
                    />
                  }
                />
              ) : (
                <FormControlLabel
                  label={option}
                  control={
                    <Checkbox
                      checked={
                        credentials.find((credential) => credential === option)
                          ? true
                          : false
                      }
                      value={option}
                      onChange={onChange}
                    />
                  }
                />
              )
            })}
          </FormGroup>
        </Box>
        <Link onClick={onShowAll}>{showLink}</Link>
      </FormControl>
    </Box>
  )
}

export default View
