import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type JobPosition = {
  id?: string
  title: string
  company: string
  startDate: Date
  endDate?: Date
  description: string
  current: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  jobPositions: JobPosition[]
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type CreateJobPositionPayload = {
  auth: Auth0ContextInterface
  jobPosition: JobPosition
}
export const createJobPosition = createAsyncThunk<
  any,
  CreateJobPositionPayload
>(
  "experience/jobPositions/createJobPosition",
  async ({ auth, jobPosition }) => {
    const body = { ...jobPosition, origin: window.location.origin }

    return useApi(auth, "/job_positions", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type UpdateJobPositionPayload = {
  auth: Auth0ContextInterface
  jobPosition: JobPosition
}
export const updateJobPosition = createAsyncThunk<
  any,
  UpdateJobPositionPayload
>(
  "experience/jobPositions/updateJobPosition",
  async ({ auth, jobPosition }) => {
    return useApi(auth, `/job_positions/${jobPosition.id}`, {
      method: "PATCH",
      // Replace `undefined` to `null` because `undefined` values are removed by JSON.stringify
      body: JSON.stringify(jobPosition, (k, v) => (v === undefined ? null : v)),
    }).then((res) => res.text())
  }
)

type DeleteJobPositionPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const deleteJobPosition = createAsyncThunk<
  any,
  DeleteJobPositionPayload
>("experience/jobPositions/deleteJobPosition", async ({ auth, id }) => {
  return useApi(auth, `/job_positions/${id}`, {
    method: "DELETE",
  }).then((res) => res.text())
})
//#endregion

//#region slice
const initialState: SliceState = {
  jobPositions: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "jobPositions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createJobPosition.fulfilled, (state, action) => {
        state.jobPositions = [action.payload, ...state.jobPositions]
      })
      .addCase(createJobPosition.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(updateJobPosition.fulfilled, (state, _action) => {
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(deleteJobPosition.fulfilled, (state, _action) => {
        state.status = LoadingStatuses.Succeeded
      })
  },
})
//#endregion

//#region selectors
//#endregion
