import styled from "styled-components"
import { Box } from "@material-ui/core"

export const PopUp = styled(Box)`
  width: 360px;
  background: #fff;
  box-shadow: 0px 10px 40px rgba(113, 113, 113, 0.15);
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 999999999999;
  transition: all 0.4s;
`

export const PopUpTitle = styled(Box)`
  font-size: 20px;
  align-content: center;
  display: flex;
  flex-flow: wrap;
`

export const PopUpBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 16px;
  height: 64px;
`

export const PopUpCloseIcon = styled.img`
  cursor: pointer;
`
