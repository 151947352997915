import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { News } from "./adminNews"

//#region types
export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  newsList: News[]
  status: LoadingStatuses
  error: string | null | undefined
  allNews: News[]
  count: number
  newCount: number
}
//#endregion

//#region api
type LikeNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likeNews = createAsyncThunk<any, LikeNewsPayload>(
  "news/like",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikeNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikeNews = createAsyncThunk<any, DislikeNewsPayload>(
  "news/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type ShareNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const shareNews = createAsyncThunk<any, ShareNewsPayload>(
  "news/share",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/share`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type ViewNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const viewNews = createAsyncThunk<any, ViewNewsPayload>(
  "news/view",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/view`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type getAllNewsPayload = {
  page?: number
  perPage?: number
  search?: string
  email?: string
  sortBy: string
}
export const getAllNews = createAsyncThunk<any, getAllNewsPayload>(
  "news/fetchAll",
  async ({ email, page = 1, perPage = 8, search, sortBy }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/news?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    if (email) endpoint += `&email=${email}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

//#endregion

//#region slice
const initialState: SliceState = {
  newsList: [],
  status: LoadingStatuses.Idle,
  error: undefined,
  allNews: [],
  count: 0,
  newCount: 0,
}

export default createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.count = action.payload.count
        state.status = LoadingStatuses.Succeeded

        state.newCount = state.newCount + action.payload.data.length

        state.allNews =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.allNews?.concat(action.payload.data)

        state.error = null
      })
      .addCase(getAllNews.pending, (state, action) => {
        state.status = LoadingStatuses.Loading

        if (action.meta.arg.page === 0 || action.meta.arg.page === 1) {
          state.allNews = []
          state.newCount = 0
        }
      })
  },
})
//#endregion

//#region selectors
//#endregion
