import React from "react"

import Header from "./Header"

import {
  MainContainer,
  RegistrationBox,
  RegistrationContainer,
  RegistrationFlexBox,
  RegistrationItem,
  RegistrationLeftBox,
  RegistrationTitle,
  HowItWorksBox,
  HowItWorksContainer,
  HowItWorksTitle,
  HowItWorksFlexBox,
  HowItWorksLeftBox,
  HowItWorksText,
  RegisterText,
  RegisterButtonBox,
} from "./styles"

import { Button } from "../../components"

import { Browser } from "@capacitor/browser"
import environment from "../../environment"

interface Props {
  isAuthenticated: boolean
  currentView: string
}

const SelectedView = ({ isAuthenticated, currentView }: Props) => {
  const handleRegister = async () => {
    if (environment.device === "desktop") {
      if (currentView === "individual") {
        window.open(
          "https://attendee.gotowebinar.com/register/7722592978732531032"
        )
      } else {
        window.location.href = "mailto:sethkopka@blackspectacles.com"
      }
    } else {
      if (currentView === "individual") {
        await Browser.open({
          url: "https://attendee.gotowebinar.com/register/7722592978732531032",
          windowName: "_self",
        })
      } else {
        await Browser.open({
          url: "mailto:sethkopka@blackspectacles.com",
          windowName: "_self",
        })
      }
    }
  }

  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <Header currentView={currentView} />

      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationTitle variant="h2">
            This is your opportunity to:
          </RegistrationTitle>
        </RegistrationContainer>
      </RegistrationBox>
      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <RegistrationLeftBox>
              {currentView === "individual" ? (
                <>
                  <RegistrationItem>
                    Get your foot in the door with amazing firms in your area
                  </RegistrationItem>
                  <RegistrationItem>
                    Learn about exciting opportunities to jumpstart your career
                  </RegistrationItem>
                  <RegistrationItem>
                    Get early access to internships before they are made public
                  </RegistrationItem>
                </>
              ) : (
                <>
                  <RegistrationItem>
                    Secure the next wave of emerging professional talent
                  </RegistrationItem>
                  <RegistrationItem>
                    Gain immediate access to the largest community of
                    architecture students in the US
                  </RegistrationItem>
                  <RegistrationItem>
                    Create awareness for your firm from the comfort of your
                    office
                  </RegistrationItem>
                </>
              )}
            </RegistrationLeftBox>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>

      <HowItWorksBox>
        <HowItWorksContainer>
          <HowItWorksTitle variant="h2">How it Works</HowItWorksTitle>
        </HowItWorksContainer>
      </HowItWorksBox>

      <HowItWorksBox>
        <HowItWorksContainer>
          <HowItWorksFlexBox>
            <HowItWorksLeftBox>
              {currentView === "individual" ? (
                <HowItWorksText>
                  The live webinar will take place at 2pm CST on Friday, April
                  12. Each firm will have 5 minutes to introduce their office,
                  the exciting work they do, and the opportunities that are
                  currently available. After the webinar, a list of the
                  opportunities will be available exclusively to those who
                  attended the webinar.
                </HowItWorksText>
              ) : (
                <HowItWorksText>
                  The live webinar will take place at 2pm CST on Friday, April
                  12. Firms will be able to present their design-work, firm
                  culture, and exciting internship opportunities to our
                  community of emerging professionals.
                </HowItWorksText>
              )}
            </HowItWorksLeftBox>
          </HowItWorksFlexBox>
        </HowItWorksContainer>
      </HowItWorksBox>

      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationTitle variant="h2">Register</RegistrationTitle>
        </RegistrationContainer>
      </RegistrationBox>
      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <RegistrationLeftBox>
              {currentView === "individual" ? (
                <>
                  <RegisterText>Space is limited. Act fast!</RegisterText>
                  <RegisterText>
                    Register today to reserve your spot.
                  </RegisterText>
                  <RegisterButtonBox>
                    <Button
                      children={"Register"}
                      color="secondary"
                      style={{ width: "298px" }}
                      onClick={handleRegister}
                    />
                  </RegisterButtonBox>
                </>
              ) : (
                <>
                  <RegisterText>Space is limited. Act fast!</RegisterText>
                  <RegisterText>
                    Send an email to{" "}
                    <a href="mailto:sethkopka@blackspectacles.com">
                      sethkopka@blackspectacles.com
                    </a>{" "}
                    today to reserve your spot!
                  </RegisterText>
                  <RegisterButtonBox>
                    <a href="mailto:sethkopka@blackspectacles.com">
                      <Button
                        children={"Register"}
                        color="secondary"
                        style={{ width: "298px" }}
                      />
                    </a>
                  </RegisterButtonBox>
                </>
              )}
            </RegistrationLeftBox>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>
    </MainContainer>
  )
}

export default SelectedView
