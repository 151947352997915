import React from "react"
import { useSelector } from "react-redux"
import { useHistory, match, Redirect } from "react-router-dom"
import { useAppDispatch } from "../../redux/configureStore"
import { Profile } from "../../redux/profile"
import {
  updateEducation,
  Education,
  deleteEducation,
} from "../../redux/educations"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { Helmet } from "react-helmet"

interface MatchParams {
  id: string
}
export interface Props {
  match: match<MatchParams>
}

const EducationEditPage = (props: Props) => {
  let history = useHistory()
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profile = useSelector((state: any): Profile => state.profile.profile)
  const education: Education | undefined = (profile?.educations ?? []).find(
    (education) => education.id === props.match.params.id
  )

  const handleEducationUpdate = async (education: Education) => {
    try {
      const { type } = await dispatch(updateEducation({ education, auth }))
      if (updateEducation.fulfilled.type === type) {
        history.push(`/experiences/edit`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteEducation = async (education?: Education) => {
    if (education?.id) {
      try {
        const { type } = await dispatch(
          deleteEducation({ id: education.id, auth })
        )
        if (deleteEducation.fulfilled.type === type) {
          history.push(`/experiences/edit`)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  if (!education) {
    return <Redirect to="/experiences/edit" />
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Edit Education`}
        classTitle="EditEducation"
      />
      <Helmet>
        <title>Edit Education - Spectacular</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`Edit Education - Spectacular`} />
        <meta name="title" content={`Edit Education - Spectacular`} />
      </Helmet>
      <View
        education={education}
        onSubmit={handleEducationUpdate}
        onDelete={handleDeleteEducation}
      />
    </>
  )
}

export default EducationEditPage
