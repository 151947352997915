import React from "react"
import { Box, Link, Icon } from "@material-ui/core"
import styled from "styled-components"
import theme, { colors } from "../../theme"
import { Link as RouterLink } from "react-router-dom"

export const FeaturedIndicator = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`

export const StyledA = styled.a`
  text-decoration: none;
  &:active {
    user-select: none;
  }
  a {
    text-decoration: none;
  }
`

export const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  &:active {
    user-select: none;
  }
  a {
    text-decoration: none;
  }
`

export const PointerBox = styled(Box)`
  cursor: pointer;
`

export const PointerIcon = styled(Icon)`
  cursor: pointer;
`

export const RegularBodyText = styled(Box)`
  font-family: Times New Roman;
  font-size: 18px;
  text-align: initial;
`

export const SlateUnderLineLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: inherit;
  border-bottom: solid 0.5px ${colors.darkerGray};
  color: ${colors.slate};
`

export const BlueUnderLineLink = styled(({ ...props }) => <Link {...props} />)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.accent};
  padding-bottom: 2px;
  border-bottom: solid 0.5px;
  border-color: ${colors.accent};
  text-decoration: none;
`

export const DarkUnderLineLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-size: inherit;
  border-bottom: solid 0.5px ${colors.slate};
  color: ${colors.dark};
`

export const BackLink = styled(RouterLink)`
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 1px solid ${colors.dark};
  color: ${colors.dark};
`

export const ProjectCardActionsBox = styled.div<{ $isLarge?: boolean }>`
  margin-left: ${theme.spacing(0)}px;
  display: flex;
  align-items: flex-start;

  ${({ $isLarge }) =>
    $isLarge
      ? `
    justify-content: flex-end;
    gap: 40px;
  `
      : `
    justify-content: space-evenly;
  `};

  ${theme.breakpoints.down("sm")} {
    margin-left: 0;

    justify-content: space-between;

    && button {
      border: none;
      height: 32px;
      min-width: 48px;
      padding: 0;
    }
  }
`

export const NewsCardActionsBox = styled.div`
  justify-content: space-evenly;
  margin-left: ${theme.spacing(0)}px;
  display: flex;
  align-items: flex-start;

  ${theme.breakpoints.down("xs")} {
    margin-left: 0;

    && button {
      border: none;
      height: 32px;
      min-width: 48px;
      padding: 0;
    }
  }
`

export const PostCardActionsBox = styled.div`
  justify-content: space-evenly;
  margin-left: ${theme.spacing(0)}px;
  display: flex;
  align-items: flex-start;

  ${theme.breakpoints.down("xs")} {
    margin-left: 0;

    justify-content: space-between;

    && button {
      border: none;
      height: 32px;
      min-width: 48px;
      padding: 0;
    }
  }
`
export const ProfileCardIcon = styled(Box)`
  ${theme.breakpoints.down("xs")} {
    margin-left: 0;
  }
`

export const IconComment = styled.img`
  width: 24px;
  height: 24px;

  ${theme.breakpoints.down("xs")} {
    width: 16px;
    height: 15px;
  }
`

export const IconLike = styled.img`
  width: 24px;
  height: 18px;
`

export const IconNomination = styled.img`
  width: 17px;
  height: 17px;
`

export const IconLikeProfile = styled.img`
  width: 12px;
`

export const IconNominationProfile = styled.img`
  width: 15px;
`

export const DarkText = styled(Box)`
  font-size: 12px;
  font-weight: bold;
  line-height: 10px;
  color: ${colors.dark};
`

export const Text = styled(Box)`
  font-size: 12px;
  color: ${colors.slate};
`

export const Divider = styled.div`
  border-top: 0.5px solid #d8d8d8;
  margin: 24px 0;
  width: 100%;
`
