import React from "react"
import View from "./view"
import { Project } from "../../redux/projects"

type Props = {
  project: Project
}

const ProjectDescriptionText = ({ project }: Props) => {
  return <View project={project} />
}

export default ProjectDescriptionText
