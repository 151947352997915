import React from "react"
import * as Yup from "yup"
import { FormikContextType, useFormikContext } from "formik"
import { convertToRaw, RawDraftContentState } from "draft-js"

import {
  Box,
  FormHelperText,
  TextField as MUITextField,
} from "@material-ui/core"

import { WizardStep } from "./Wizard"
import { JobListing } from "../../redux/jobListings"

import JobListingTags from "../../components/JobListingTags"
import RichTextEditor from "../../components/RichTextEditor"
import TextField from "../../components/TextField"

import {
  RichTextEditorContainer,
  JobSummaryBox,
  JobTagsBox,
  JobUrlBox,
  JobDescriptionBox,
  Divider,
  StyledTextFieldArea,
  BoxHeader,
  BoxHeaderTitle,
  TextFieldLabel,
  TextFieldDescription,
} from "./styles"

import "./errors.css"

const Step2: React.FC = () => {
  const formik: FormikContextType<JobListing> = useFormikContext()

  const [richTextValue, setRichTextValue] = React.useState(
    formik.values?.descriptionRtf
  )

  const parseMaxLength = (raw: RawDraftContentState) => {
    const result = Object.assign(raw, {})

    let currentCount = 0

    result.blocks = raw.blocks.map((block, index) => {
      if (currentCount > 6000) {
        return {
          ...block,
          text: "",
        }
      }

      const oldCount = currentCount
      const textLength = block.text.length
      currentCount += textLength
      let newText = block.text

      if (currentCount + index > 6000) {
        const diff = 6000 - oldCount - index
        newText = block.text.substr(0, diff)
      }

      return {
        ...block,
        text: newText,
      }
    })

    result.blocks = result.blocks.filter((block) => {
      return block.text.length > 0
    })

    return result
  }

  return (
    <WizardStep>
      <div>
        <BoxHeader>
          <BoxHeaderTitle>Job Details</BoxHeaderTitle>
        </BoxHeader>
        <Divider />
        <JobSummaryBox>
          <TextFieldLabel
            style={{
              color:
                formik.errors.summary && formik.touched.summary
                  ? "#f44336"
                  : "",
            }}
            tabIndex={0}
            variant="h5"
          >
            Job summary{" "}
            <span
              style={{
                color:
                  formik.errors.summary && formik.touched.summary
                    ? "#f44336"
                    : "",
              }}
            >
              *
            </span>
          </TextFieldLabel>
          <TextFieldDescription
            style={{
              color:
                formik.errors.summary && formik.touched.summary
                  ? "#f44336"
                  : "",
            }}
            tabIndex={0}
            variant="h5"
          >
            Briefly describe your job in 1-2 sentences. This is what candidates
            will see when scrolling through the job board.
          </TextFieldDescription>
          <StyledTextFieldArea>
            <TextField
              variant={"outlined"}
              name="summary"
              multiline={true}
              rows={4}
              rowsMax={4}
              maxLength={184}
              data-testid="project-summary-field"
              value={formik.values.summary}
              onChange={formik.handleChange}
            />
          </StyledTextFieldArea>
        </JobSummaryBox>
        <JobDescriptionBox>
          <TextFieldLabel
            style={{
              color:
                formik.errors.description && formik.touched.description
                  ? "#f44336"
                  : "",
            }}
            tabIndex={0}
            variant="h5"
          >
            Job description{" "}
            <span
              style={{
                color:
                  formik.errors.description && formik.touched.description
                    ? "#f44336"
                    : "",
              }}
            >
              *
            </span>
          </TextFieldLabel>
          <TextFieldDescription
            style={{
              color:
                formik.errors.description && formik.touched.description
                  ? "#f44336"
                  : "",
            }}
            tabIndex={0}
            variant="h5"
          >
            Describe the roles and responsibilities, qualifications and skills
            required, and benefits of this job.
          </TextFieldDescription>
          <RichTextEditorContainer
            style={{
              borderColor:
                formik.errors.description && formik.touched.description
                  ? "#f44336"
                  : "",
            }}
            error={formik.errors.description && formik.touched.description}
          >
            <RichTextEditor
              onBlur={() => formik.setFieldTouched("description")}
              label="Type job description here..."
              defaultValue={richTextValue}
              data-testid="description"
              onChange={(plainText, richText, event) => {
                if (plainText.length > 6000) {
                  formik.setFieldValue("description", plainText.substr(0, 6000))
                  formik.setFieldValue(
                    "descriptionRtf",
                    JSON.stringify(
                      parseMaxLength(convertToRaw(event.getCurrentContent()))
                    )
                  )
                  setRichTextValue(
                    JSON.stringify(
                      parseMaxLength(convertToRaw(event.getCurrentContent()))
                    )
                  )
                } else {
                  formik.setFieldValue("description", plainText)
                  formik.setFieldValue("descriptionRtf", richText)
                }
              }}
            />
          </RichTextEditorContainer>
          {formik.errors.description && formik.touched.description && (
            <FormHelperText error>{formik.errors.description}</FormHelperText>
          )}
        </JobDescriptionBox>
        <JobTagsBox>
          <Box>
            <TextFieldLabel tabIndex={0} variant="h5">
              Job tags
            </TextFieldLabel>
            <TextFieldDescription tabIndex={0} variant="h5">
              Add job tags to make it easier for qualified candidates to search
              for your job. (Select up to 10 tags.)
            </TextFieldDescription>
          </Box>
          <JobListingTags
            initialTags={formik.values?.tags}
            onBlur={() => formik.setSubmitting(false)}
            onFocus={() => formik.setSubmitting(true)}
            onChange={(values) => formik.setFieldValue("tags", values)}
          />
        </JobTagsBox>
        <JobUrlBox>
          <TextFieldLabel
            style={{
              color:
                formik.errors.jobUrl && formik.touched.jobUrl ? "#f44336" : "",
            }}
            tabIndex={0}
            variant="h5"
          >
            Link to job posting{" "}
            <span
              style={{
                color:
                  formik.errors.jobUrl && formik.touched.jobUrl
                    ? "#f44336"
                    : "",
              }}
            >
              *
            </span>
          </TextFieldLabel>
          <MUITextField
            name="jobUrl"
            data-testid="jobUrl"
            inputProps={{ maxLength: 250 }}
            value={formik.values?.jobUrl}
            error={formik.errors.jobUrl !== undefined && formik.touched.jobUrl}
            helperText={
              formik.errors.jobUrl !== undefined && formik.touched.jobUrl
                ? formik.errors.jobUrl
                : ""
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              formik.setFieldValue("jobUrl", e.target.value)
            }}
          />
        </JobUrlBox>
        <Divider />
      </div>
    </WizardStep>
  )
}

Step2.defaultProps = {
  validationSchema: Yup.object().shape({
    jobUrl: Yup.string().test("CheckDefaultValue", "Required", function (item) {
      return (
        this.parent.jobUrl &&
        this.parent.jobUrl !== "https://" &&
        this.parent.jobUrl !== "HTTPS://" &&
        this.parent.jobUrl !== "HTTP://" &&
        this.parent.jobUrl !== "http://"
      )
    }),
    summary: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
}

export default Step2
