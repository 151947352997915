import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Button } from "../Button"

import theme from "../../theme"

export const NullstateButton = styled(Button)`
  height: 48px;
  padding: 0 16px;
  font-size: 16px;
`

export const NullstateTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 50px;
  line-height: 61px;
  margin-top: -14px;

  ${theme.breakpoints.down("sm")} {
    font-size: 40px;
    line-height: 47px;
    margin-top: -10px;
    padding-right: 20px;
  }
`

export const NullstateText = styled(Typography)`
  font-family: "Public-Sans";
  font-size: 16px;
  color: #2d2d2d;
  line-height: 16px;
  margin-top: -2px;

  ${theme.breakpoints.down("sm")} {
    line-height: 21px;
    margin-bottom: -2px;
  }
`

export const PlusIcon = styled.img`
  display: flex;
`
