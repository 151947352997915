import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import theme from "../../theme"

export const IconContainer = styled(Box)<{ $isActive?: boolean }>`
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  height: 100%;
  color: ${({ $isActive }) => ($isActive ? `#979797;` : "#2d2d2d;")}

  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }

  ${({ $isActive }) =>
    $isActive
      ? `text-decoration-line: underline !important;
  text-decoration-thickness: 4px !important;
  text-decoration-color: rgb(0, 0, 0) !important;
  text-underline-offset: 10px !important;`
      : ""}

  &:hover {
    cursor: pointer;
  }

  && img {
    height: 24px;
    padding-bottom: 6px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  @media only screen and (max-width: 1230px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  
  @media only screen and (max-width: 1130px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    font-family: Public-Sans-Medium;
    
    ${({ $isActive }) =>
      $isActive ? `text-decoration-line: none !important;` : ""}
  }

`

export const PostOptionsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 20px;

  ${theme.breakpoints.down("sm")} {
    position: absolute;
    bottom: calc(env(safe-area-inset-bottom) + 80px);
  }
`

export const Item = styled(Box)`
  background: #fff;
  border: 1px solid #979797;
  border-radius: 13px;
  box-shadow: 0px 0px 5.199999809265137px 0px #00000040;
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`

export const Title = styled(Typography)`
  font-size: 16px;
  font-family: Public-Sans-Medium;
`

export const Description = styled(Typography)`
  font-size: 14px;
`

export const Close = styled(Box)`
  border: 1px solid #979797;
  border-radius: 50%;
  box-shadow: 0px 0px 5.199999809265137px 0px #00000040;
  background: #fff;
  width: 32px;
  height: 32px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PostButtonContainer = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
`

export const PostButton = styled(Box)`
  background: #2d2d2d;
  color: #fff;
  height: 33px;
  width: 95px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-size: 16px;
`
