import styled from "styled-components"
import { Box, Button, Dialog, InputBase } from "@material-ui/core"

import theme, { colors } from "../../theme"

export const CustomInput = styled(InputBase)`
  flex: 1;
  color: #2d2d2d;
  font-size: 14px;
  margin-left: 13px;

  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    margin-left: 11px;
  }
`

export const ContentPadding = styled(Box)`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
`

export const ContentText = styled.div`
  font-weight: 400;
  color: #2d2d2d;
  font-size: 16px;
  padding-top: 24px;
  padding-bottom: 24px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`

export const PopoverTitle = styled.span`
  color: #2d2d2d;
  font-size: 20px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const PopoverHeader = styled(Box)`
  height: 64px;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  justify-content: space-between;
  padding: 0 16px 0 16px;

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px 0 20px;
  }
`

export const Boxshadow = styled.div`
  position: relative;
  margin-top: 10px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 15px;
    right: 19px;
    box-sizing: border-box;
    border: 0.6em solid black;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 3%);
  }
`

export const Divider = styled(Box)`
  width: 100%;
  border-top: 0.5px solid #d8d8d8;
`

export const ReferralDialog = styled(Dialog)`
  min-height: 100vh;
  margin: 0 !important;
`

export const IconContainer = styled.div<{
  hover?: any
  isNav: boolean
  width: number
  height: number
}>`
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  height: ${(props) => props.height}px;
  ${({ hover }) => (hover ? `cursor: pointer;` : "")}

  @media only screen and (max-width: 1230px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media only screen and (max-width: 1130px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 12px;
    font-family: Public-Sans-Medium;
  }
`

export const PopoverContainer = styled.div`
  margin: 0 !important;
  width: 100%;
  max-width: 416px;
  max-height: 80vh;
  margin-top: 24px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 1px;
  background-color: #fff;
  box-shadow: 0px 8px 20px rgba(113, 113, 113, 0.25);
`

export const CopyButton = styled(Button)`
  color: #ffffff;
  font-size: 14px;
  max-width: 80px;
  max-height: 42px;
  background: #3057e1;
  font-family: "Public-Sans-Bold";
  border-radius: 0px 1px 1px 0px;

  :hover {
    background: #1c43cd !important;
  }
`
