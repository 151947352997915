import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

export const colors = {
  dark: "#2d2d2d",
  slate: "#858585",
  white: "#ffffff",
  accent: "#3057e1",
  trueBlack: "#000000",
  alert: "#f44c49",
  lightGray: "#fafafa",
  gray: "#f2f2f2",
  darkerGray: "#979797",
  mediumGray: "#d8d8d8",
  mediumLightGray: "#717171",
  green: "#00e676",
}

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      fontFamily: "FreightDispProBook",
      fontSize: 50,
      lineHeight: 1.076923,
      color: colors.dark,
    },
    h2: {
      fontFamily: "FreightDispProBook",
      fontSize: 40,
      lineHeight: 1.17,
      color: colors.dark,
    },
    h3: {
      fontFamily: "Public-Sans",
      fontSize: 36,
      lineHeight: 1.5,
      color: colors.dark,
    },
    h4: {
      fontFamily: "Public-Sans",
      fontSize: 25,
      lineHeight: 1.2,
      color: colors.dark,
    },
    h5: {
      fontFamily: "Public-Sans",
      fontSize: 22,
      lineHeight: 1.41,
      color: colors.dark,
    },
    body1: {
      fontFamily: "Public-Sans",
      fontSize: 16,
      color: colors.dark,
      lineHeight: 1.375,
    },
    body2: {
      fontSize: 14,
      fontFamily: "Public-Sans",
      color: colors.dark,
      lineHeight: 1.375,
    },
    subtitle2: {
      fontFamily: "Public-Sans",
      fontSize: 14,
      lineHeight: 1.5,
      color: colors.dark,
    },
  },
  palette: {
    primary: {
      main: colors.dark,
    },
    secondary: {
      main: colors.accent,
    },
  },
  props: {
    MuiTextField: {
      variant: "filled",
      fullWidth: true,
      margin: "normal",
    },
    MuiButton: {
      variant: "contained",
      size: "large",
      disableElevation: true,
    },
    MuiFormControl: {
      variant: "filled",
      fullWidth: true,
      margin: "normal",
    },
    MuiLink: {
      underline: "always",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: colors.lightGray,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: "none",
        fontFamily: "Public-Sans",
        whiteSpace: "nowrap",
      },
      sizeLarge: {
        padding: "14px 40px",
        fontFamily: "Public-Sans-Bold",
        fontSize: 14,
      },
      outlinedSizeLarge: {
        paddingTop: "13px !important",
        paddingBottom: "13px !important",
        fontFamily: "Public-Sans-Bold",
        fontSize: 14,
      },
      sizeSmall: {
        fontFamily: "Public-Sans",
        fontSize: 14,
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.slate,
      },
    },
  },
})

Object.assign(theme, {
  ...theme.typography,
  ...theme.palette,
  ...theme.props,
  overrides: {
    ...theme.overrides,
    MUIRichTextEditor: {
      placeHolder: {
        paddingLeft: 10,
      },
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
