import styled from "styled-components"
import { Box } from "@material-ui/core"

export const FormBox = styled(Box)`
    display: flex;
    gap 16px;
    box-sizing: border-box;
    padding: 0 8px;
    margin-bottom: 24px;
`
