import React from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { Box, Typography, Button } from "@material-ui/core"

import { StyledLink, RequestDialogText } from "./styles"

import TextField from "../TextField"
import { JobRequest } from "../../redux/jobListings"

export type Props = {
  values: JobRequest
  onClose: () => void
  onSubmit: (values: JobRequest) => void
}

// eslint-disable-next-line no-useless-escape
const URLRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-?]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/

const JobListingSchema = Yup.object().shape({
  informations: Yup.string().required("Required"),
  website: Yup.string()
    .matches(URLRegex, "Must be a valid URL")
    .required("Required")
    .typeError("Required"),
})

const View = ({ values, onSubmit, onClose }: Props) => {
  const MAX_CHAR_COUNT = 5000

  const characterCounter = (e: any) => {
    if (e.target.value.length > MAX_CHAR_COUNT)
      e.target.value = e.target.value.substr(0, MAX_CHAR_COUNT)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={values}
      enableReinitialize={true}
      validationSchema={JobListingSchema}
    >
      {(formik) => (
        <Form>
          <Box marginTop="40px">
            <RequestDialogText>What’s your career page URL?</RequestDialogText>
            <TextField
              name={`website`}
              placeholder={"https://"}
              data-testid="requestWebsite"
            />
          </Box>

          <Box marginTop="32px">
            <RequestDialogText>
              Details and other information.
            </RequestDialogText>
            <TextField
              rows={4}
              multiline={true}
              name={`informations`}
              data-testid="requestInformations"
              onInput={characterCounter}
            />
            <Typography variant="body2" style={{ textAlign: "right" }}>
              {formik.values.informations?.length}/
              <span
                style={{
                  color:
                    formik.values.informations?.length === MAX_CHAR_COUNT
                      ? "red"
                      : "black",
                }}
              >
                {MAX_CHAR_COUNT} characters
              </span>
            </Typography>
          </Box>
          <Box
            style={{ display: "flex", alignItems: "center", marginTop: "40px" }}
          >
            <Button
              style={{
                border: "1px solid black",
                borderColor: "#2D2D2D",
                width: "151px",
                height: "54px",
                fontSize: "16px",
              }}
              color="primary"
              size="small"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Send
            </Button>
            <StyledLink onClick={onClose}>Cancel</StyledLink>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default View
