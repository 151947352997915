import styled from "styled-components"
import { colors } from "../../theme"
import { Pagination } from "@material-ui/lab"

export const MainPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    width: 56px;
    height: 56px;
    border-radius: 0;
    margin: 0;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: ${colors.dark};
    color: ${colors.white};
  }

  .MuiPagination-ul {
    li {
      margin-right: 6px;
    }

    li:last-child {
      margin: 0;
    }
  }
`
