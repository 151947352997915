import React from "react"
import View, { ActionsType } from "./View"

type Props = {
  onClick: (value: ActionsType) => void
  actions: ActionsType[]
  title: string
  defaultValue?: string
}

const DropDown = ({ onClick, actions, title, defaultValue }: Props) => {
  return (
    <View
      onClick={onClick}
      actions={actions}
      title={title}
      defaultValue={defaultValue}
    />
  )
}

export default DropDown
