import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
import { Auth0ContextInterface } from "@auth0/auth0-react"

//#region types
export type Product = {
  id: string
  title: string
  amount: number
  bestOption: boolean
  benefits: ProductBenefit[]
  stripePriceId: string
  stripeProductId: string
}

export type Tax = {
  taxRate: number
  taxId: string
}

export type ProductBenefit = {
  text: string
  active: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  products?: Product[]
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetCompetitionPayload = {
  type: string
}
export const getProducts = createAsyncThunk<any, GetCompetitionPayload>(
  "products/get",
  async ({ type }) => {
    let endpoint = `/products?type=${type}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type GetTaxPayload = {
  auth: Auth0ContextInterface
  productId: string
  address: {
    line1: string
    state: string
    postalCode: string
    city: string
    country: string
  }
}
export const getTax = createAsyncThunk<any, GetTaxPayload>(
  "products/tax/get",
  async ({ auth, productId, address }) => {
    let endpoint = `/products/tax`

    const body = {
      productId,
      address,
    }

    return useApi(auth, endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type CreatePaymentPayload = {
  auth: Auth0ContextInterface
  productId: string
  jobId?: string
  taxId?: string
}
export const createPayment = createAsyncThunk<any, CreatePaymentPayload>(
  "products/payment/create",
  async ({ auth, productId, jobId, taxId }) => {
    let endpoint = `/products/payment`

    const body = {
      productId,
      jobId,
      taxId,
    }

    return useApi(auth, endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type ConfirmSubscriptionPayload = {
  auth: Auth0ContextInterface
  paymentMethodId: string
  subscriptionId: string
  jobId?: string
}
export const confirmSubscription = createAsyncThunk<
  any,
  ConfirmSubscriptionPayload
>(
  "products/payment/confirm",
  async ({ auth, paymentMethodId, subscriptionId, jobId }) => {
    let endpoint = `/products/confirm`

    const body = {
      paymentMethodId,
      subscriptionId,
      jobId,
    }

    return useApi(auth, endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  products: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state, action) => {
      state.products = []
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload
      state.status = LoadingStatuses.Succeeded
    })
  },
})
//#endregion

//#region selectors
export const selectProducts = ({ product }: RootState) => product.products
//#endregion
