import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { useHistory, RouteComponentProps } from "react-router-dom"

import {
  updateProfile,
  fetchProfileByUsername,
  fetchProfileByUsernameWithAuthorization,
} from "../../redux/profile"
import LoadingPage from "../LoadingPage"
import { AppState, useAppDispatch } from "../../redux/configureStore"

import View from "./View"
import GroupView from "./GroupView"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import camelize from "../../utils/camelize"

interface MatchParams {
  tab: string
  username: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const ProfileDetailPage: React.FC<Props> = (props) => {
  let username = props.match.params.username
  let history = useHistory()

  const auth = useAuth0()
  const { isAuthenticated } = useAuth0()

  const dispatch = useAppDispatch()
  const [isConnected, setIsConnected] = useState(false)
  const [editedAbout, setEditedAbout] = useState(false)
  const authenticatedProfile = useSelector(
    (state: any) => state.profile.profile
  )
  const [currentTab, setCurrentTab] = useState(
    props.match.params.tab || "projects"
  )

  const profile = useSelector(
    (state: AppState) => state.profile.selectedProfile
  )

  const handleTabChanged = (_event: React.ChangeEvent<{}>, tab: string) =>
    setCurrentTab(tab)

  const handleTabClicked = (url: string) =>
    window.history.replaceState(null, "Spectacular", url)

  const handleAddProject = () => history.push("/projects/new")

  const handleAddJobListing = () => history.push("/job_listings/new")

  const handleEditAbout = async (plainText: string, richText: string) => {
    const editedProfile = Object.assign({}, profile)
    editedProfile.about = plainText
    editedProfile.aboutRft = richText

    setEditedAbout(true)

    try {
      await dispatch(updateProfile({ profile: editedProfile, auth }))

      if (isAuthenticated)
        dispatch(fetchProfileByUsernameWithAuthorization({ auth, username }))
      else dispatch(fetchProfileByUsername({ username }))
    } catch (error) {
      console.error(error)
    }
  }
  const goToUrl = (url: string) => history.push(url)

  const isPublicProfile = authenticatedProfile?.username !== profile?.username
  const isOfflineUser = authenticatedProfile === undefined
  const isGroupProfile = authenticatedProfile?.type === "group"

  useEffect(() => {
    if (authenticatedProfile?.connections?.connections) {
      const connected = authenticatedProfile?.connections?.connections.filter(
        (connection: any) => {
          return connection.connectionId === profile?.connectionId
        }
      )

      if (connected?.length! > 0) {
        setIsConnected(connected[0].status === "accepted")
      } else {
        setIsConnected(false)
      }
    }

    if (!editedAbout) setCurrentTab(props.match.params.tab || "projects")

    // eslint-disable-next-line
  }, [isConnected, authenticatedProfile, profile])

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        const { type } = await dispatch(
          fetchProfileByUsernameWithAuthorization({ auth, username })
        )

        if (fetchProfileByUsernameWithAuthorization.fulfilled.type === type) {
          setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true
          }, 0)
        }
      } else {
        const { type } = await dispatch(fetchProfileByUsername({ username }))

        if (fetchProfileByUsername.fulfilled.type === type) {
          setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true
          }, 0)
        }
      }
    })()
    // eslint-disable-next-line
  }, [dispatch, username, auth, isAuthenticated])

  if (!profile) {
    return <LoadingPage />
  }

  const screenTitle = `Profile Details - ${
    profile!.type === "individual"
      ? profile?.firstName + " " + profile?.lastName
      : profile?.groupName
  }`

  return (
    <>
      <RouteChangeTracker
        screenTitle={screenTitle}
        classTitle={camelize(screenTitle)}
      />
      <Helmet>
        <meta
          property="title"
          content={`${
            profile!.type === "individual"
              ? profile?.firstName + " " + profile?.lastName
              : profile?.groupName
          } - Spectacular`}
        />
        <meta
          name="description"
          content={profile?.about || profile?.aboutSelf}
        />
        <meta property="og:type" content="profile" />
        <meta
          property="og:title"
          content={`${
            profile!.type === "individual"
              ? profile?.firstName + " " + profile?.lastName
              : profile?.groupName
          } - Spectacular`}
        />
        <meta property="og:url" content={window.location.href} />

        <meta property="og:image" content={profile?.smallImageUrl} />

        <meta
          property="og:description"
          content={profile?.about || profile?.aboutSelf}
        />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <title>{`${
          profile!.type === "individual"
            ? profile?.firstName + " " + profile?.lastName
            : profile?.groupName
        } - Spectacular`}</title>
      </Helmet>
      {profile!.type === "group" ? (
        <GroupView
          goToUrl={goToUrl}
          profile={profile!}
          authenticatedProfile={authenticatedProfile}
          currentTab={currentTab}
          onEditAbout={handleEditAbout}
          onTabChanged={handleTabChanged}
          onTabClicked={handleTabClicked}
          onAddProject={handleAddProject}
          isPublicProfile={isPublicProfile}
          onAddJobListing={handleAddJobListing}
        />
      ) : (
        <View
          goToUrl={goToUrl}
          profile={profile!}
          authenticatedProfile={authenticatedProfile}
          currentTab={currentTab}
          onEditAbout={handleEditAbout}
          onTabChanged={handleTabChanged}
          onTabClicked={handleTabClicked}
          onAddProject={handleAddProject}
          isPublicProfile={isPublicProfile}
          isGroupProfile={isGroupProfile}
          isConnected={isConnected}
          isOfflineUser={isOfflineUser}
          onAddJobListing={handleAddJobListing}
        />
      )}
    </>
  )
}

export default ProfileDetailPage
