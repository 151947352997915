import { Box } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const BioBox = styled(Box)`
  display: flex;
  gap: 24px;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`

export const ImageBox = styled(Box)`
  min-width: 33%;

  && img {
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 24px;
`
