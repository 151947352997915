import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import View from "./View"

type Props = {
  hideSignUp?: boolean
}

const NavbarUnauthenticated = ({ hideSignUp }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const history = useHistory()
  const goToUrl = (url: string) => {
    handleMenuClose()
    handleDrawerClose()
    history.push(url)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <View
      hideSignUp={hideSignUp}
      goToUrl={goToUrl}
      menuOpen={menuOpen}
      onMenuClose={handleMenuClose}
      isDrawerOpen={isDrawerOpen}
      onDrawerOpen={handleDrawerOpen}
      onDrawerClose={handleDrawerClose}
    />
  )
}

export default NavbarUnauthenticated
