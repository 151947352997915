import { Box } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const PostsContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const PostsBox = styled(Box)`
  width: 100%;
  max-width: 600px;

  ${theme.breakpoints.down("xs")} {
    padding: 40px 20px;
  }
`
