import React, { useState } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"

import {
  Profile,
  confirmConnectionRequest,
  denyConnectionRequest,
  undoDeniedRequest,
  sendNotificationEmailAndRemoveSuggestion,
} from "../../redux/profile"

import {
  fetchNotifications,
  fetchRequests,
} from "../../redux/bellNotifications"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  profile: Profile
  type: string
}

const ConnectButtonConfirm = ({ profile, type }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        confirmConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (confirmConnectionRequest.fulfilled.type === type) {
        await dispatch(
          fetchNotifications({ currentPage: 0, auth, reset: true })
        )
        await dispatch(fetchRequests({ currentPage: 0, auth }))

        await dispatch(
          sendNotificationEmailAndRemoveSuggestion({
            auth,
            profileId: connectionId!,
            templateName: "PROFILE_CONNECTION_ACCEPTED",
          })
        )
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleDeny = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        denyConnectionRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (denyConnectionRequest.fulfilled.type === type) {
        await dispatch(
          fetchNotifications({ currentPage: 0, auth, reset: true })
        )
        await dispatch(fetchRequests({ currentPage: 0, auth }))
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  const handleUndo = async () => {
    try {
      setIsLoading(true)
      const profileId = authenticatedProfile?.connectionId
      const connectionId = profile.connectionId

      const { type } = await dispatch(
        undoDeniedRequest({ auth, profileId, connectionId })
      )

      setIsLoading(false)

      if (undoDeniedRequest.fulfilled.type === type) {
        await dispatch(
          fetchNotifications({ currentPage: 0, auth, reset: true })
        )
        await dispatch(fetchRequests({ currentPage: 0, auth }))
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      setIsLoading(false)
      return Promise.reject(error)
    }
  }

  return (
    <>
      {authenticatedProfile &&
      profile &&
      authenticatedProfile?.connectionId !== profile?.connectionId ? (
        <View
          isLoading={isLoading}
          onConfirm={handleConfirm}
          onDeny={handleDeny}
          onUndo={handleUndo}
          type={type}
        />
      ) : null}
    </>
  )
}

export default ConnectButtonConfirm
