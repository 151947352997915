import React from "react"

import { BgBox, ButtonBox } from "./styles"

import Carousel from "react-material-ui-carousel"
import CarouselProjectCard from "./CarrouselProjectCard"
import CategoryCard from "./CategoryCard"
import { Button } from "../Button"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { Competition } from "../../redux/competition"
import { logEvent } from "../../utils/analytics"

interface Props {
  goToUrl: (url: string) => void
  competition: Competition
}

const CarouselView = ({ goToUrl, competition }: Props) => {
  const { width } = useWindowDimensions()
  const isTablet =
    width <= theme.breakpoints.values.md && width >= theme.breakpoints.values.sm
  const isMobile = width < theme.breakpoints.values.sm

  const renderedCarousel = () => {
    const result = []

    if (
      competition.winners?.student &&
      competition.winners?.student?.length > 0
    ) {
      result.push(<CategoryCard label={"Student"} />)
      competition.winners.student.forEach((project) => {
        result.push(<CarouselProjectCard project={project} label={"Student"} />)
      })
    }

    if (
      competition.winners?.professional &&
      competition.winners?.professional?.length > 0
    ) {
      result.push(<CategoryCard label={"Professional"} />)
      competition.winners.professional.forEach((project) => {
        result.push(
          <CarouselProjectCard project={project} label={"Professional"} />
        )
      })
    }

    if (
      competition.winners?.winners &&
      competition.winners?.winners?.length > 0
    ) {
      competition.winners.winners.forEach((project) => {
        result.push(
          <CarouselProjectCard project={project} competition={competition} />
        )
      })
    }

    return result
  }

  const handleClick = async () => {
    goToUrl(`${competition.url}#winners`)

    await logEvent("winners_banner_click")
  }

  return (
    <BgBox>
      <Carousel
        className="carouselHeight"
        navButtonsAlwaysInvisible={true}
        animation={"fade"}
        interval={5000}
        indicatorContainerProps={{
          style: {
            marginTop: isTablet ? "-45px" : isMobile ? "-30px" : "-45px",
            zIndex: 5,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "0 5px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#3057E1",
          },
        }}
        autoPlay={true}
      >
        {renderedCarousel().map((item) => {
          return item
        })}
      </Carousel>
      <ButtonBox>
        <Button
          onClick={handleClick}
          children={"See all the winners"}
          color={"secondary"}
          size={"medium"}
          style={{
            width: isMobile ? "100%" : "194px",
            height: "48px",
            fontSize: "16px",
            marginTop: "auto",
          }}
        />
      </ButtonBox>
    </BgBox>
  )
}

export default CarouselView
