import React from "react"
import {
  ImageThumbnail,
  ImageThumbnailContainer,
  JuryBorder,
  JuryBorderBottom,
  JuryProject,
  JuryProjectTitles,
  JuryReviewBox,
  JuryReviewCard,
  JuryReviewCardDescription,
  JuryReviewCardHint,
  JuryReviewCardImage,
  JuryReviewCardText,
  JuryReviewContainer,
  JuryReviewList,
  JuryReviewTitleLP,
  JuryReviewTitle,
  ProfileName,
  ProjectName,
  JuryReviewBoxLP,
  JuryReviewCardPosition,
} from "./styles"

import { JuryReviews } from "./jury-review"
import { StyledLink } from "../InnovationAwardsAnnouncement/styles"
import { LinkBox } from "../../cards/ProjectCard/styles"
import Carousel from "react-material-ui-carousel"

import ArrowRight from "../../images/innovation-awards/arrow-right.png"
import ArrowLeft from "../../images/innovation-awards/arrow-left.png"

import "./styles.css"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { JuryReview } from "../../redux/competition"

interface Props {
  origin: string
  competition?: string
  juryReview?: JuryReview[]
}

const View = ({ origin, competition, juryReview }: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const TitleComponent =
    origin === "homepage" ? JuryReviewTitle : JuryReviewTitleLP

  const BoxComponent = origin === "homepage" ? JuryReviewBox : JuryReviewBoxLP

  const reviews = juryReview ? juryReview : JuryReviews

  const renderHTML = (rawHTML: string) => {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    })
  }

  return (
    <JuryReviewContainer>
      <BoxComponent>
        <TitleComponent variant={origin === "landing-page" ? "h1" : "h3"}>
          What the jury said
        </TitleComponent>
      </BoxComponent>
      <JuryBorder />
      <BoxComponent>
        <Carousel
          className="juryCarousel"
          navButtonsAlwaysVisible={true}
          animation={"fade"}
          interval={10000}
          NextIcon={<img src={ArrowRight} alt={"Arrow"} />}
          PrevIcon={<img src={ArrowLeft} alt={"Arrow"} />}
          indicatorContainerProps={{
            style: {
              marginTop: isMobile ? "67px" : "41px",
              zIndex: 5,
              position: "absolute",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              padding: "0 5px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#3057E1",
            },
          }}
          autoPlay={true}
          navButtonsProps={{
            style: {
              backgroundColor: "initial",
            },
          }}
          navButtonsWrapperProps={{
            style: {
              display: "flex",
              marginTop: isMobile ? "65px" : "74px",
              zIndex: 6,
            },
          }}
        >
          {reviews.map((jury) => {
            return (
              <JuryReviewList>
                <JuryProject>
                  <ImageThumbnailContainer>
                    <LinkBox to={jury.projectLink}>
                      <ImageThumbnail
                        src={jury.projectImage}
                        alt={jury.projectName}
                      />
                    </LinkBox>
                  </ImageThumbnailContainer>
                  <JuryProjectTitles>
                    <ProjectName>
                      <StyledLink to={jury.projectLink}>
                        {jury.projectName}
                      </StyledLink>
                    </ProjectName>
                    <ProfileName>
                      {jury.collaborators && jury.collaborators?.length > 1 ? (
                        <>
                          Multiowner (
                          {jury.collaborators.map((collaborator, key) => {
                            return (
                              <StyledLink to={collaborator.link}>
                                {collaborator.name}
                                {key < jury.collaborators?.length - 1 && ", "}
                              </StyledLink>
                            )
                          })}
                          )
                        </>
                      ) : (
                        <>
                          <StyledLink to={jury.collaborators[0].link}>
                            {jury.collaborators[0].name}
                          </StyledLink>
                        </>
                      )}
                    </ProfileName>
                  </JuryProjectTitles>
                </JuryProject>
                <JuryReviewCard $isHomepage={origin === "homepage"}>
                  <JuryReviewCardDescription>
                    <JuryReviewCardText variant="h2">
                      {jury.quote}
                    </JuryReviewCardText>
                    <JuryReviewCardHint variant="h2">
                      {jury.name}
                    </JuryReviewCardHint>
                    {jury.description && (
                      <JuryReviewCardPosition>
                        {renderHTML(jury.description)}
                      </JuryReviewCardPosition>
                    )}
                  </JuryReviewCardDescription>
                  <JuryReviewCardImage>
                    <img src={jury.image} alt={jury.name} />
                  </JuryReviewCardImage>
                </JuryReviewCard>
              </JuryReviewList>
            )
          })}
        </Carousel>
      </BoxComponent>
      <JuryBorderBottom />
    </JuryReviewContainer>
  )
}

export default View
