import React from "react"

import View from "./NewView"
import { projectToolOptions } from "../../constants/project-tools"

type Props = {
  error?: string
  tools: string[]
  onChange: (tools: string[]) => void
}

const ProjectToolSelector = ({ tools, onChange, error }: Props) => {
  const handleChange = (tool: string) => {
    let noOtherTool = false
    for (let item in projectToolOptions) {
      if (projectToolOptions[item].value === tool) noOtherTool = true
    }

    if (noOtherTool) {
      if (tools.find((tl) => tl === tool)) {
        onChange(tools.filter((tl) => tl !== tool))
      } else {
        onChange([...tools, tool])
      }
    } else {
      const existingOther = tools.filter((e) => {
        let currTool = true
        for (let item in projectToolOptions) {
          if (projectToolOptions[item].value === e) currTool = false
        }
        return currTool
      })

      const existingOtherStr = existingOther.toString()

      let updatedTools = tools.filter(
        (item) => !item.includes(existingOtherStr)
      )

      if (!existingOtherStr) updatedTools = tools.filter((item) => item)

      onChange([...updatedTools, tool].filter((tool) => tool.length > 0))
    }
  }

  return <View error={error} tools={tools} onChange={handleChange} />
}

export default ProjectToolSelector
