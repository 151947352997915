import React, { useState, ChangeEvent } from "react"
import {
  Box,
  Grid,
  Checkbox,
  TextField,
  Typography,
  FormGroup,
  FormHelperText,
} from "@material-ui/core"

import { SelectOption } from "../SelectField"

import { projectToolsByCategory } from "../../constants/project-tools"

import { ScrollableContainer } from "./styles"
import { StyledFormControlLabel } from "../ProjectTypology/styles"

type Props = {
  error?: string
  tools: string[]
  onChange: (tool: string) => void
}

const MAX_ALLOWED = 5

const View = ({ tools, onChange, error }: Props) => {
  const [options] = useState(projectToolsByCategory)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    onChange(target.value)

  const onBlur = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onChange(target.value)

  let otherTool = tools
  options.forEach((category) => {
    category.options.map((option: any[]) => {
      otherTool = otherTool.filter((e) => !Object.values(option).includes(e))
      return otherTool
    })
  })

  const isDisabled = tools.length === MAX_ALLOWED

  function FormRow(options: SelectOption[]) {
    return (
      <Grid container xs={12}>
        {options.map((option, idx) => {
          return option.value === "other" ? (
            <Grid item xs={12}>
              <TextField
                label="Other"
                onBlur={onBlur}
                data-testid="other"
                name={option.value}
                defaultValue={otherTool}
              />
            </Grid>
          ) : (
            <Grid item md={4} xs={6}>
              <StyledFormControlLabel
                key={idx}
                label={option.name}
                control={
                  <Checkbox
                    checked={
                      tools.find((tool) => tool === option.value) ? true : false
                    }
                    value={option.value}
                    onChange={handleChange}
                    disabled={
                      isDisabled &&
                      !(tools.find((tool) => tool === option.value)
                        ? true
                        : false)
                    }
                  />
                }
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Box marginTop={3} marginBottom={5}>
      <Typography style={error ? { color: "#f44336" } : {}} variant="h5">
        Project Tools <span style={{ color: "#ff0000" }}>*</span>
      </Typography>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <FormHelperText style={{ margin: ".5em 0 0" }}>
        Select no more than 5 options
      </FormHelperText>
      <Box marginTop={0.5}>
        <FormGroup>
          <ScrollableContainer>
            {options.map((chunk) => (
              <Box marginLeft={1.5} marginRight={1.5} marginTop={1}>
                <Typography style={{ fontWeight: 600, background: "#e5e5e5" }}>
                  {chunk.category}
                </Typography>
                {FormRow(chunk.options)}
              </Box>
            ))}
          </ScrollableContainer>
        </FormGroup>
      </Box>
    </Box>
  )
}

export default View
