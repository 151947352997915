import { StepLabel, Stepper } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const StepperStyled = styled(Stepper)`
  padding: 0 ${theme.spacing(3)}px ${theme.spacing(3)}px;
  background: transparent;
`

export const StyledStepLabel = styled(StepLabel)<{ $isCurrentStep: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ $isCurrentStep }) =>
    $isCurrentStep ? "1px solid #000" : ""};
  padding-bottom: ${({ $isCurrentStep }) => ($isCurrentStep ? "5px" : "")};

  && img {
    margin-right: 5px;
  }
`
