import React from "react"

import { Box, Grid } from "@material-ui/core"

import LocationIcon from "../../images/location-icon.svg"
import ConfirmConnectionDialog from "../../components/ConfirmConnectionDialog"

import { Profile } from "../../redux/profile"
import Avatar from "../../components/Avatar"

import {
  LocationText,
  FitText,
  TextBox,
  ButtonsBox,
  MobileAvatarBox,
} from "./styles"

import FollowButton from "../../components/FollowButton"
import ConnectButton from "../../components/ConnectButton"
import ConnectDotMenu from "../../components/ConnectDotMenu"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

import AboutSelfSection from "./AboutSelfSection"
import SimpleAddress from "../../components/SimpleAddress"

interface Props {
  profile: Profile
  authenticatedProfile: Profile
  isPublicProfile: boolean
  isConnected: boolean
  goToUrl: (url: string) => void
}

const ProfileDetails = ({
  profile,
  authenticatedProfile,
  isPublicProfile,
  isConnected,
  goToUrl,
}: Props) => {
  const { width } = useWindowDimensions()

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  let jobTitle = profile.jobTitle
  if (profile.jobTitle && profile.jobTitle.includes("Other: ")) {
    jobTitle = profile.jobTitle.replace("Other: ", "")
  }

  let name = profile.groupName
  if (profile.type === "individual") {
    if (profile.firstName) {
      name = `${profile.firstName} ${profile.lastName}`
    } else {
      name = `${profile.lastName}`
    }
  }

  let ButtonsArea = null

  if (width >= theme.breakpoints.values.md) {
    ButtonsArea = (
      <>
        <ButtonsBox marginBottom={3}>
          {authenticatedProfile?.type !== "group" ? (
            <>
              <ConnectButton
                profile={profile}
                action={"dialog"}
                handleOpenDialog={handleOpen}
              />
              <FollowButton
                profile={profile}
                size={"small"}
                color={"light-blue"}
              />
              {isConnected ? <ConnectDotMenu profile={profile} /> : null}
            </>
          ) : (
            <>
              <Grid md={12}>
                <FollowButton profile={profile} />
              </Grid>
            </>
          )}
        </ButtonsBox>
      </>
    )
  } else if (width >= theme.breakpoints.values.sm) {
  } else {
    ButtonsArea = (
      <>
        <ButtonsBox marginBottom={3}>
          {authenticatedProfile?.type !== "group" ? (
            <>
              <Grid
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexFlow: "inerhit",
                  maxWidth: "100%",
                }}
              >
                <ConnectButton
                  profile={profile}
                  action={"dialog"}
                  handleOpenDialog={handleOpen}
                  style={{ height: "48px", width: "100%" }}
                />
                {isConnected ? <ConnectDotMenu profile={profile} /> : null}
              </Grid>
              <Grid
                sm={12}
                xs={12}
                style={{
                  textAlign: "center",
                  maxWidth: "100%",
                }}
              >
                <FollowButton profile={profile} color={"light-blue"} />
              </Grid>
            </>
          ) : (
            <Grid sm={12} xs={12} style={{ textAlign: "center" }}>
              <FollowButton
                profile={profile}
                style={{ height: "48px", maxWidth: "100%" }}
              />
            </Grid>
          )}
        </ButtonsBox>
      </>
    )
  }

  return (
    <>
      {width > theme.breakpoints.values.sm &&
      width < theme.breakpoints.values.md ? (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box>
              <MobileAvatarBox className="profile" style={{ display: "flex" }}>
                <Avatar hover width={176} height={176} profile={profile} />
              </MobileAvatarBox>
            </Box>
            <Box>
              <ButtonsBox marginBottom={2.5}>
                {authenticatedProfile?.type !== "group" ? (
                  <>
                    <ConnectButton
                      profile={profile}
                      action={"dialog"}
                      handleOpenDialog={handleOpen}
                      style={{ width: "103px" }}
                    />
                    <FollowButton
                      profile={profile}
                      size={"small"}
                      color={"light-blue"}
                    />
                    {isConnected ? <ConnectDotMenu profile={profile} /> : null}
                  </>
                ) : (
                  <>
                    <Grid md={12}>
                      <FollowButton
                        profile={profile}
                        style={{ width: "103px" }}
                      />
                    </Grid>
                  </>
                )}
              </ButtonsBox>
            </Box>
          </Box>
          <TextBox style={{ paddingBottom: "16px" }}>
            <FitText style={{ fontSize: "25px", lineHeight: "28px" }}>
              <h1
                style={{
                  fontSize: "25px",
                  lineHeight: "28px",
                  fontWeight: 500,
                }}
              >
                {name}
              </h1>
            </FitText>
          </TextBox>
          {profile.jobTitle && profile.company && (
            <TextBox style={{ marginBottom: "4px" }}>
              <FitText style={{ fontSize: "16px", color: "#2D2D2D" }}>
                {profile.company ? (
                  <>
                    {profile.jobTitle
                      ? `${jobTitle} at ${profile.company}`
                      : profile.company}
                  </>
                ) : (
                  <>{profile.jobTitle ? jobTitle : null}</>
                )}
              </FitText>
            </TextBox>
          )}
          {profile.location && (
            <TextBox
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={LocationIcon}
                alt="location"
                style={{ marginRight: "6px", height: "11px" }}
              />
              <LocationText
                tabIndex={0}
                onClick={() =>
                  goToUrl(`/profiles?location=${profile.location}`)
                }
              >
                {SimpleAddress({
                  profileType: profile!.type,
                  location: profile.location,
                })}
              </LocationText>
            </TextBox>
          )}
        </>
      ) : (
        <>
          {width >= theme.breakpoints.values.md ? (
            <Box
              marginBottom={2.5}
              className="profile"
              style={{ display: "flex" }}
            >
              <Avatar hover width={272} height={272} profile={profile} />
            </Box>
          ) : (
            <MobileAvatarBox className="profile">
              <Avatar hover width={176} height={176} profile={profile} />
            </MobileAvatarBox>
          )}
          <TextBox style={{ marginBottom: "16px" }}>
            <FitText style={{ fontSize: "25px", lineHeight: "28px" }}>
              <h1
                style={{
                  fontSize: "25px",
                  lineHeight: "28px",
                  fontWeight: 500,
                }}
              >
                {name}
              </h1>
            </FitText>
          </TextBox>
          {profile.jobTitle && (
            <TextBox style={{ marginBottom: "4px" }}>
              <FitText style={{ fontSize: "16px", color: "#2D2D2D" }}>
                {jobTitle}
              </FitText>
            </TextBox>
          )}
          {profile.company && (
            <TextBox style={{ marginBottom: "4px" }}>
              <FitText style={{ fontSize: "16px", color: "#2D2D2D" }}>
                {profile.company}
              </FitText>
            </TextBox>
          )}
          {profile.location && (
            <TextBox
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={LocationIcon}
                alt="location"
                style={{ marginRight: "6px", height: "11px" }}
              />
              <LocationText
                tabIndex={0}
                onClick={() =>
                  goToUrl(`/profiles?location=${profile.location}`)
                }
              >
                {SimpleAddress({
                  profileType: profile!.type,
                  location: profile.location,
                })}
              </LocationText>
            </TextBox>
          )}
        </>
      )}

      {ButtonsArea}

      {width >= theme.breakpoints.values.md && (
        <>
          <Box
            marginBottom={3}
            style={{ borderBottom: "0.5px solid #D8D8D8" }}
          />
          <AboutSelfSection profile={profile} isConnected={isConnected} />
        </>
      )}

      <ConfirmConnectionDialog
        open={open}
        onClose={handleClose}
        profile={profile}
      />
    </>
  )
}

export default ProfileDetails
