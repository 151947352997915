import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import environment from "../environment"
import { isMobile } from "react-device-detect"
import axios from "axios"
import Cookies from "universal-cookie"

type SliceState = {
  configurations: any[]
}

type GeoLocation = {
  country: string
  city: string
  region: string
  ip: string
}

//#endregion

//#region api
export const fetchConfigurations = createAsyncThunk<any>(
  "configurations",
  async () => {
    return useApi(null, `/configurations`, {
      method: "GET",
    }).then(async (res) => {
      return await res.json()
    })
  }
)

type SettingsPayload = {
  profileId?: string
}

export const setSettings = createAsyncThunk<any, SettingsPayload>(
  "settings",
  async ({ profileId }) => {
    const device =
      isMobile && environment.device === "desktop"
        ? "mobile"
        : environment.device

    let geoLocation = {} as GeoLocation
    try {
      const data = await axios.get(`https://ipinfo.io/json`)
      geoLocation = data.data as GeoLocation
    } catch (error) {
      geoLocation = {} as GeoLocation
    }

    const cookies = new Cookies()
    const sessionId = cookies.get("_ga")

    const body = {
      sessionId,
      device,
      profileId,
      country: geoLocation.country,
      city: geoLocation.city,
      state: geoLocation.region,
      ip: geoLocation.ip,
    }

    return useApi(null, `/settings`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then(async (res) => {
      return await res.text()
    })
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  configurations: [],
}

export default createSlice({
  name: "configurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurations.fulfilled, (state, action) => {
      state.configurations = action.payload
    })
  },
})
//#endregion
