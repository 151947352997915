import React from "react"
import { useHistory } from "react-router-dom"

import IconPlus from "../../images/icon-plus.svg"
import IconStar from "../../images/icon-star.svg"
import { Profile } from "../../redux/profile"
import { getRandomPlaceholder } from "../../utils/getRandomPlaceholder"
import { Button } from "../Button"
import {
  BorderBottom,
  BorderLeft,
  BorderRight,
  BorderTop,
  InnerBorder,
  SpotlightBox,
  SpotlightButtonBox,
  SpotlightContainer,
  SpotlightCopy,
  SpotlightFlexBox,
  SpotlightFloatingCompanyName,
  SpotlightFloatingIcon,
  SpotlightLeftBox,
  SpotlightRightBox,
  SpotlightSubTitle,
  SpotlightTitle,
} from "./styles"

interface Props {
  profile: Profile
}

const View = ({ profile }: Props) => {
  const history = useHistory()

  const goToUrl = (url: string) => {
    history.push(url)
  }

  return (
    <SpotlightContainer>
      <BorderTop />
      <SpotlightBox>
        <BorderLeft />
        <BorderRight />
        <SpotlightFlexBox>
          <SpotlightLeftBox>
            <SpotlightTitle variant="h2">
              Firm Spotlight <img src={IconStar} alt="Firm Spotlight" />
            </SpotlightTitle>

            <SpotlightSubTitle variant="h2">
              {profile.groupName}
            </SpotlightSubTitle>

            <SpotlightCopy>
              {profile.spotlights && <>{profile.spotlights[0].description}</>}
            </SpotlightCopy>

            <SpotlightButtonBox>
              <Button
                onClick={() => goToUrl(`/sp/${profile?.username}`)}
                children={"Learn more"}
                color={"secondary"}
                size={"medium"}
                style={{
                  width: "194px",
                  height: "48px",
                  fontSize: "16px",
                }}
              />
            </SpotlightButtonBox>
          </SpotlightLeftBox>

          <SpotlightRightBox>
            <InnerBorder />
            <SpotlightFloatingCompanyName>
              {profile.groupName}
            </SpotlightFloatingCompanyName>
            <SpotlightFloatingIcon>
              <img src={IconPlus} alt={"Spectacular"} />
            </SpotlightFloatingIcon>
            <img
              src={
                profile.smallImageUrl ||
                getRandomPlaceholder(profile?.placeholderAvatar!)
              }
              alt={profile.groupName}
            />
          </SpotlightRightBox>
        </SpotlightFlexBox>
      </SpotlightBox>
      <BorderBottom />
    </SpotlightContainer>
  )
}

export default View
