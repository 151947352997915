import React from "react"
import { Profile } from "../../redux/profile"

import View from "./View"

type Props = {
  profile: Profile
  onClose: () => void
  onRemove: (profile: Profile) => void
  open: boolean
}

const RemoveConnectionDialog = ({
  profile,
  open,
  onClose,
  onRemove,
}: Props) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <View
      profile={profile}
      onClose={handleClose}
      open={open}
      onRemove={onRemove}
    />
  )
}

export default RemoveConnectionDialog
