import React from "react"
import { Grid, Link } from "@material-ui/core"
import { Link as LinkRouter } from "react-router-dom"

import PlusIconSrc from "../../images/icon-plus-white.svg"
import XIcon from "../../images/icon-social-x.svg"
import FacebookIcon from "../../images/icon-social-facebook.svg"
import LinkedInIcon from "../../images/icon-social-linkedin.svg"
import { FooterContainer, SocialLinksContainer, StyledXIcon } from "./styles"
import InstagramIcon from "../../images/icon-social-instagram.svg"
import { StyledLink } from "../../components/Navbar/styles"
import {
  PlusIcon,
  Text,
  TextCopyright,
  StyledLinkBox,
  StyledLinkEmailBox,
} from "./styles"
import env from "../../environment"
import FooterNavbar from "../FooterNavbar"
import { useAuth0 } from "@auth0/auth0-react"

const Footer = () => {
  const year = new Date().getFullYear()
  const { isAuthenticated } = useAuth0()

  return (
    <>
      {isAuthenticated && <FooterNavbar />}
      <FooterContainer
        style={
          env.device !== "desktop" ? { padding: 0, marginBottom: "73px" } : {}
        }
      >
        <Grid container direction="column">
          <Text justifyContent="center" display="flex" my={1}>
            <PlusIcon
              src={PlusIconSrc}
              alt="Spectacular"
              aria-label="specatular Icon"
            />
          </Text>
          {env.device === "desktop" && (
            <>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/featured_projects"
                      aria-label="Featured projects"
                    >
                      Featured projects
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/press"
                      aria-label="press"
                    >
                      Press
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/member_benefits"
                      aria-label="member benefits"
                    >
                      Member benefits
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/about"
                      aria-label="about"
                    >
                      About us
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/jobs"
                      aria-label="jobs"
                    >
                      Jobs
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/terms_use"
                      aria-label="Terms of use"
                    >
                      Terms of Use
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/cookie_policy"
                      aria-label="Cookie policy"
                    >
                      Cookie Policy
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      href="https://www.spectacular.design/recruitment"
                      target="_blank"
                    >
                      Firms
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/disclaimer"
                      aria-label="Disclaimer"
                    >
                      Disclaimer
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      component={LinkRouter}
                      to="/privacy_policy"
                      aria-label="Privacy policy"
                    >
                      Privacy policy
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      href="https://www.spectacular.design/advertising"
                      target="_blank"
                    >
                      Sponsors
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledLinkBox justifyContent="center" display="flex" my={1}>
                    <StyledLink
                      href="https://help.spectacular.design"
                      target="_blank"
                    >
                      Help
                    </StyledLink>
                  </StyledLinkBox>
                </Grid>
              </Grid>
              <StyledLinkEmailBox
                justifyContent="center"
                display="flex"
                paddingY={6}
              >
                <StyledLink
                  href="mailto:hello@spectacular.design"
                  color="inherit"
                >
                  hello@spectacular.design
                </StyledLink>
              </StyledLinkEmailBox>
              <Grid item xs={12}>
                <SocialLinksContainer
                  justifyContent="center"
                  display="flex"
                  paddingBottom={6}
                >
                  <Link
                    href="https://www.facebook.com/Spectacular-Design-103266274984298/?modal=admin_todo_tour&sa=D&source=editors&ust=1617379249915000&usg=AFQjCNH9fCYDJQ6WjOwIPCqehRHO5ZzMNw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookIcon} alt="Facebook" />
                  </Link>
                  <Link
                    href="https://www.instagram.com/spectacular_des?source=editors&ust=1617379249916000&usg=AFQjCNEvvanNA4luiAbh72hbEFZOEwZsLg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramIcon} alt="Instagram" />
                  </Link>
                  <Link
                    href="https://x.com/Spectacular_des?source=editors&ust=1617379249917000&usg=AFQjCNGimVhw5_DUHQLZgnhfGXp8oVvhvg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledXIcon src={XIcon} alt="X" />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/spectaculardesign/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkedInIcon} alt="LinkedIn" />
                  </Link>
                </SocialLinksContainer>
              </Grid>
              <TextCopyright justifyContent="center" display="flex" my={1}>
                2093 Philadelphia Pike #8864, Claymont, DE 19703 | All rights
                reserved &copy; {year} Spectacular
              </TextCopyright>
            </>
          )}
        </Grid>
      </FooterContainer>
    </>
  )
}

export default Footer
