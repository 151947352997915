import React, { useState } from "react"
import * as Yup from "yup"

import View from "./View"

import { ProjectMainFeature } from "../../redux/adminProjectMainFeature"

export interface EditableProjectMainFeature extends ProjectMainFeature {
  editing?: boolean
}

const MainFeatureSchema = Yup.object().shape({
  endDate: Yup.string().required("Required"),
  startDate: Yup.string().required("Required"),
  heroTitle: Yup.string()
    .required("Required")
    .max(62, "Hero Title must be at most 62 characters"),
})

type Props = {
  projectMainFeature: EditableProjectMainFeature
  onSave: (projectMainFeature: EditableProjectMainFeature) => void
  onDelete: (projectMainFeature: EditableProjectMainFeature) => void
}

const AdminProjectMainFeatureRow = ({
  projectMainFeature,
  onSave,
  onDelete,
}: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [original] = useState(projectMainFeature)
  const [mainFeature, setMainFeature] = useState(projectMainFeature)

  const validate = async (feat: EditableProjectMainFeature) =>
    await MainFeatureSchema.validate(feat)

  const handleOnSave = async () => {
    try {
      await validate(mainFeature)

      setError(null)

      onSave(mainFeature)
      setMainFeature({ ...mainFeature, editing: false })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message)
    }
  }

  const handleOnChange = async (
    data: any | null,
    property: keyof EditableProjectMainFeature
  ) => {
    if (mainFeature.heroTitle && mainFeature.heroTitle.length > 61)
      setError("Hero Title must be at most 62 characters")

    if (mainFeature.heroTitle && mainFeature.heroTitle.length < 61)
      setError(null)

    setMainFeature({ ...mainFeature, [property]: data })
  }

  const handleOnDateChange = (
    date: Date | null,
    property: keyof EditableProjectMainFeature
  ) => {
    setError(null)
    setMainFeature({ ...mainFeature, [property]: date?.toDateString() })
  }

  const handleOnDelete = () => onDelete(mainFeature)

  const handleOnEdit = () => setMainFeature({ ...mainFeature, editing: true })

  const handleOnCancel = () => setMainFeature({ ...original, editing: false })

  return (
    <View
      error={error}
      mainFeature={mainFeature}
      onEdit={handleOnEdit}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onChange={handleOnChange}
      onDateChange={handleOnDateChange}
      onDelete={handleOnDelete}
    />
  )
}

export default AdminProjectMainFeatureRow
