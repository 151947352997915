import React from "react"

import View from "./View"

type Props = {
  onClose: () => void
  open: boolean
}

const PostOptionsDialog = ({ open, onClose }: Props) => {
  const handleClose = () => {
    onClose()
  }

  return <View onClose={handleClose} open={open} />
}

export default PostOptionsDialog
