import React from "react"

import NotificationFollow from "../../components/NotificationFollow"
import NotificationProject from "../../components/NotificationProject"
import NotificationProfile from "../../components/NotificationProfile"
import NotificationSourceProfile from "../../components/NotificationSourceProfile"

import moment from "moment"
import NotificationPost from "../../components/NotificationPost"

export type Props = {
  handleNotificationClick: (value?: string) => void
  notification: any
  index: number
  hideButton?: boolean
  goToNotifications?: boolean
}

const NotificationCard = ({
  handleNotificationClick,
  notification,
  index,
  hideButton,
  goToNotifications,
}: Props) => {
  moment.locale("en", {
    relativeTime: {
      s: "%d s",
      m: "1 m",
      mm: "%d m",
      h: "1 h",
      hh: "%d h",
      d: "1 d",
      dd: function (number) {
        if (number < 7) {
          return number + " d"
        } else {
          var weeks = Math.round(number / 7)
          return weeks + " w"
        }
      },
      M: "1 mo",
      MM: "%d mo",
      y: "1 y",
      yy: "%d y",
    },
  })

  return (
    <>
      {notification.origin === "follow" ||
      notification.origin === "connection" ? (
        <NotificationFollow
          onNotificationClick={handleNotificationClick}
          key={index + "notification"}
          notification={notification}
          hideButton={hideButton}
          goToNotifications={goToNotifications}
        />
      ) : null}

      {notification.origin === "comment" ||
      notification.origin === "like" ||
      notification.origin === "nomination" ||
      notification.origin === "share" ||
      notification.origin === "collaborator" ||
      notification.origin === "confirmCollaborator" ||
      notification.origin === "commentReply" ||
      notification.origin === "likeComment" ||
      notification.origin === "commentMention" ? (
        <NotificationProject
          onNotificationClick={handleNotificationClick}
          key={index + "notification"}
          notification={notification}
          hideButton={hideButton}
          goToNotifications={goToNotifications}
        />
      ) : null}

      {notification.origin === "defaultProfile" ? (
        <NotificationProfile
          onNotificationClick={handleNotificationClick}
          key={index + "notification"}
          notification={notification}
          goToNotifications={goToNotifications}
        />
      ) : null}

      {notification.origin === "postLike" ||
      notification.origin === "postShare" ||
      notification.origin === "postMention" ||
      notification.origin === "postCommentMention" ||
      notification.origin === "postComment" ||
      notification.origin === "postLikeComment" ||
      notification.origin === "postCommentReply" ? (
        <NotificationPost
          onNotificationClick={handleNotificationClick}
          key={index + "notification"}
          notification={notification}
          goToNotifications={goToNotifications}
        />
      ) : null}

      {notification.origin === "connectionDenied" ||
      notification.origin === "connectionAccepted" ||
      notification.origin === "inviteAccepted" ? (
        <NotificationSourceProfile
          onNotificationClick={handleNotificationClick}
          key={index + "notification"}
          notification={notification}
          hideButton={hideButton}
          goToNotifications={goToNotifications}
        />
      ) : null}
    </>
  )
}

export default NotificationCard
