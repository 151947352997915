import { SelectOption } from "../components/SelectField"

const companySizes: Array<SelectOption> = [
  { value: "1", name: "1" },
  { value: "2-9", name: "2-9" },
  { value: "10-49", name: "10-49" },
  { value: "50-99", name: "50-99" },
  { value: "100-499", name: "100-499" },
  { value: "500-999", name: "500-999" },
  { value: "1000+", name: "1000+" },
]

export default companySizes
