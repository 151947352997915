import React, { useState } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import View from "./View"
import { Comment, CommentMention, updateComment } from "../../redux/projects"
import { useAuth0 } from "@auth0/auth0-react"
import { PostComment, PostMention, editComment } from "../../redux/posts"
import { updateCommentState } from "../../redux/feed"

interface Props {
  comment: PostComment | Comment
  origin: "post" | "project"
  isOpen: boolean
  isHomepage?: boolean
  onClose: () => void
}

const EditCommentDialog = ({
  origin,
  comment,
  isOpen,
  isHomepage,
  onClose,
}: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profile = useSelector((state: AppState) => state.profile.profile)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSave = async (
    text: string,
    mentions?: PostMention[] | CommentMention[]
  ) => {
    try {
      setIsSubmitting(true)
      if (origin === "post") {
        const postMentions = mentions as PostMention[]
        const postComment = comment as PostComment

        const { payload, type } = await dispatch(
          editComment({
            auth,
            text,
            mentions: postMentions,
            commentId: postComment.id!,
            postId: postComment.postId!,
          })
        )
        if (editComment.fulfilled.type === type) {
          if (isHomepage) {
            await dispatch(
              updateCommentState({
                type: "post",
                comment: payload,
                postId: postComment.postId,
              })
            )
          }

          onClose()
          setIsSubmitting(false)

          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } else {
        const projectMentions = mentions as CommentMention[]
        const projectComment = comment as Comment

        const { payload, type } = await dispatch(
          updateComment({
            auth,
            text,
            mentions: projectMentions,
            commentId: projectComment.id!,
            projectId: projectComment.projectId!,
            profileId: profile?.connectionId!,
          })
        )
        if (updateComment.fulfilled.type === type) {
          if (isHomepage) {
            await dispatch(
              updateCommentState({
                type: "project",
                comment: payload,
                projectId: projectComment.projectId,
              })
            )
          }

          onClose()
          setIsSubmitting(false)

          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <View
      comment={comment}
      profile={profile!}
      onClose={onClose}
      onSubmit={handleSave}
      open={isOpen}
      isSubmitting={isSubmitting}
    />
  )
}

export default EditCommentDialog
