import React from "react"
import {
  RequestDialog,
  HeaderTitle,
  RequestDialogBox,
  HeaderBox,
  HeaderItems,
  HeaderItem,
  FiltersBox,
  FiltersTitle,
  FiltersItem,
  FiltersItems,
  Diviver,
  DividerBox,
} from "./Styles"
import { Box } from "@material-ui/core"
import { keyMaterials, typology, tools } from "../HomeSearchBar/project-search"
import { profileType } from "../HomeSearchBar/profile-search"

import "./fix-dialog.css"
import { Button } from "../Button"

export type Props = {
  onClose: () => void
  open: boolean
  activeSearch: string
  onChangeActiveSearch: (value: string, reset: boolean) => void
  typologyFilter?: string[]
  toolsFilter?: string[]
  keyMaterialsFilter?: string[]
  myNetworkFilter: boolean
  profileTypeFilter?: string[]
  onUpdateTypologyFilter: (value: string) => void
  onUpdateToolsFilter: (value: string) => void
  onUpdateKeyMaterialsFilter: (value: string) => void
  onUpdateNetworkFilter: (value: boolean) => void
  onUpdateProfileTypeFilter: (value: string) => void
}

const View = ({
  open,
  onClose,
  activeSearch,
  onChangeActiveSearch,
  typologyFilter,
  toolsFilter,
  keyMaterialsFilter,
  myNetworkFilter,
  profileTypeFilter,
  onUpdateTypologyFilter,
  onUpdateToolsFilter,
  onUpdateKeyMaterialsFilter,
  onUpdateNetworkFilter,
  onUpdateProfileTypeFilter,
}: Props) => {
  return (
    <RequestDialog
      open={open}
      onClose={onClose}
      className={"FilterDialogHomePage"}
    >
      <RequestDialogBox>
        <HeaderBox>
          <HeaderTitle>Filter by</HeaderTitle>
          <HeaderItems>
            <HeaderItem
              onClick={() => onChangeActiveSearch("projects", true)}
              $isActive={activeSearch === "projects"}
            >
              Projects
            </HeaderItem>
            <HeaderItem
              onClick={() => onChangeActiveSearch("profiles", true)}
              $isActive={activeSearch === "profiles"}
            >
              People
            </HeaderItem>
            <HeaderItem
              onClick={() => onChangeActiveSearch("posts", true)}
              $isActive={activeSearch === "posts"}
            >
              Posts
            </HeaderItem>
          </HeaderItems>
        </HeaderBox>
        {activeSearch === "projects" && (
          <FiltersBox>
            <FiltersTitle>Typology</FiltersTitle>
            <FiltersItems>
              {typology.map((item) => {
                return (
                  <FiltersItem
                    key={item.value}
                    onClick={() => onUpdateTypologyFilter(item.value)}
                    $isActive={typologyFilter?.includes(item.value)}
                  >
                    {item.display}
                  </FiltersItem>
                )
              })}
            </FiltersItems>
            <DividerBox>
              <Diviver />
            </DividerBox>
            <FiltersTitle>Key Materials</FiltersTitle>
            <FiltersItems>
              {keyMaterials.map((item) => {
                return (
                  <FiltersItem
                    key={item.value}
                    onClick={() => onUpdateKeyMaterialsFilter(item.value)}
                    $isActive={keyMaterialsFilter?.includes(item.value)}
                  >
                    {item.display}
                  </FiltersItem>
                )
              })}
            </FiltersItems>
            <DividerBox>
              <Diviver />
            </DividerBox>
            <FiltersTitle>Tools</FiltersTitle>
            <FiltersItems>
              {tools.map((item) => {
                return (
                  <FiltersItem
                    key={item.value}
                    onClick={() => onUpdateToolsFilter(item.value)}
                    $isActive={toolsFilter?.includes(item.value)}
                  >
                    {item.display}
                  </FiltersItem>
                )
              })}
            </FiltersItems>
            <DividerBox>
              <Diviver />
            </DividerBox>
            <FiltersTitle>My Network</FiltersTitle>
            <FiltersItems>
              <FiltersItem
                onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
                $isActive={myNetworkFilter}
              >
                My Network
              </FiltersItem>
            </FiltersItems>
          </FiltersBox>
        )}
        {activeSearch === "profiles" && (
          <FiltersBox>
            <FiltersTitle>Profile Type</FiltersTitle>
            <FiltersItems>
              {profileType.map((item) => {
                return (
                  <FiltersItem
                    key={item.value}
                    onClick={() => onUpdateProfileTypeFilter(item.value)}
                    $isActive={profileTypeFilter?.includes(item.value)}
                  >
                    {item.display}
                  </FiltersItem>
                )
              })}
            </FiltersItems>
            <DividerBox>
              <Diviver />
            </DividerBox>
            <FiltersTitle>My Network</FiltersTitle>
            <FiltersItems>
              <FiltersItem
                onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
                $isActive={myNetworkFilter}
              >
                My Network
              </FiltersItem>
            </FiltersItems>
          </FiltersBox>
        )}
        {activeSearch === "posts" && (
          <FiltersBox>
            <FiltersTitle>My Network</FiltersTitle>
            <FiltersItems>
              <FiltersItem
                onClick={() => onUpdateNetworkFilter(!myNetworkFilter)}
                $isActive={myNetworkFilter}
              >
                My Network
              </FiltersItem>
            </FiltersItems>
          </FiltersBox>
        )}
        <Box
          paddingRight={2.5}
          paddingLeft={2.5}
          paddingTop={3}
          style={{ marginTop: "auto" }}
        >
          <Button
            style={{
              width: "100%",
              height: "48px",
              fontSize: "14px",
              fontWeight: 700,
            }}
            color="secondary"
            size="small"
            type="submit"
            onClick={onClose}
            children={"Apply"}
          />
        </Box>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
