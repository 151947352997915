import React from "react"
import {
  Divider,
  CardBox,
  CardActions,
  PostCardContentImage,
  CardHeader,
  ProfileTitle,
  ImageThumbnail,
  CardFooter,
  DividerBorder,
  SeeMoreButton,
  CardBigTextBelow,
  LinkBox,
  HeaderBox,
  StyledAvatar,
  CardFooterTitle,
  CardFooterHint,
  HeaderDate,
  CardContentBigText,
  CardBigText,
  CardTitle,
  CardHint,
} from "./styles"
import Zoomable from "react-instagram-zoom"
import { News } from "../../redux/adminNews"
import NewsCardIconActions from "../../components/NewsCardIconActions"
import moment from "moment"

export type Props = {
  news: News
  onNewsVisit: () => void
}

const View = ({ news, onNewsVisit }: Props) => {
  const renderContentComponent = () => {
    if (news.imageUrl) {
      return (
        <PostCardContentImage>
          <Zoomable>
            <LinkBox
              href={news.url}
              target="_blank"
              referrerPolicy="noreferrer"
              onClick={onNewsVisit}
            >
              <ImageThumbnail src={news.imageUrl} alt={news.title} />
            </LinkBox>
          </Zoomable>
        </PostCardContentImage>
      )
    } else {
      return (
        <CardContentBigText>
          <CardTitle>{news.title}</CardTitle>
          <CardHint>from {news.origin.name}</CardHint>
          <CardBigText>{news.description}</CardBigText>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <SeeMoreButton>Read more</SeeMoreButton>
          </LinkBox>
        </CardContentBigText>
      )
    }
  }

  return (
    <CardBox key={news.id}>
      <CardHeader>
        <HeaderBox>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <StyledAvatar src={news.origin.logo} />
          </LinkBox>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <ProfileTitle>{news.origin.name}</ProfileTitle>
            {news.date && (
              <HeaderDate>{moment(news.date).format("MMM DD")}</HeaderDate>
            )}
          </LinkBox>
        </HeaderBox>
      </CardHeader>

      {renderContentComponent()}

      <CardActions>
        <NewsCardIconActions news={news} />
      </CardActions>
      <Divider>
        <DividerBorder />
      </Divider>

      {news.imageUrl && (
        <CardFooter>
          <CardFooterTitle>{news.title}</CardFooterTitle>
          <CardFooterHint>from {news.origin.name}</CardFooterHint>
          <CardBigTextBelow>{news.description}</CardBigTextBelow>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <SeeMoreButton>Read More</SeeMoreButton>
          </LinkBox>
        </CardFooter>
      )}
    </CardBox>
  )
}

export default View
