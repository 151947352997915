let url = window.origin
if (!url.includes("://")) {
  url = url.includes("localhost") ? `http://${url}` : `https://${url}`
}

const content = `
<div id="common-navigation-main">
  <style>
    @font-face {
      font-family: 'Flama';
      font-style: normal;
      font-weight: normal;
      src: local('Flama'), url('https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/Flama+Regular.woff') format('woff');
    }

    #common-navigation-main *{
      margin:0;
      padding:0;
      font-size: 14px;
      line-height: 16px;
      color:#2D2D2D;
      background-color: white;
      font-family: "Flama", sans-serif;
      font-weight: 600;
    }

    #common-navigation-main{
      height: 85px;
      background-color: white;
      display:flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      border-top:#313131 4px solid;
      border-bottom: #313131 1px solid;
      align-items: center;
      box-sizing: border-box;
      padding: 0 24px;
      position: relative;
      z-index: 11;
    }

    #common-navigation-left{
      display:flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    #common-navigation-main a,
    #common-navigation-main a:hover,
    #common-navigation-main a:active,
    #common-navigation-main a:focus{
      display: block;
      text-decoration: none;
    }

    #common-navigation-bkslogo a{
      display:flex;
    }
    
    #common-navigation-bkslogo a:hover{
      display:flex;
    }

    #common-navigation-bkslogo img{
      height: 60px;
      width: 132px;
      fill:#2D2D2D;
      color: #2D2D2D;
    }

    #common-navigation-nav{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: 72px;
      gap: 32px;
    }

    #common-navigation-nav #common-navigation-nav-item{
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      display: block;
      justify-content: flex-start;
    }
    
    #common-navigation-nav #common-navigation-nav-item img{
      margin-top: -2px;
      margin-left: 1px;
      position: absolute;
    }

    #common-navigation-whatsnew{
      height: 36px;
      background-color: #E5E5E5;
      border-radius: 1px;
      display: flex;
      align-items: center;
      width: 157px;
      justify-content: center;
    }
    #common-navigation-whatsnew img{
      background-color: #E5E5E5;
      fill:#E5E5E5;
      margin-left: 3px;
    }
    #common-navigation-whatsnew-text{
      font-size: 14px;
      line-height: 11px;
      background-color: #E5E5E5;
    }

    #common-navigation-mobile-icon {
      display:none;
    }

    #common-navigation-mobile-menu {
      display:none;
      visibility:hidden;
      opacity:0;
      transition: opacity 0.3s ease-in;
      position:absolute;
      top:54px;
      background-color: #ffffff;
      padding: 24px 20px;
      width: 100vw;
      box-sizing: border-box;
      margin-left: -20px;
      z-index: 70;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 7%);
    }

    @media only screen and (max-width: 960px) {
      
      #common-navigation-main{
        height: 60px;
        padding: 0 20px;
      }

      #common-navigation-nav{
        display:none;
      }
      
      #common-navigation-bkslogo img{
        height: 40px;
        width: 87px;
      }
      
      #common-navigation-whatsnew{
        height: 24px;
        width: auto;
        padding: 0 8px;
      }
        
      #common-navigation-mobile-icon {
        display:initial;
        margin-left: 16px;
        cursor: pointer;
      }
      
      #common-navigation-mobile-icon img{
        fill:#2d2d2d;
      }
      
      #common-navigation-mobile-menu {
        display:initial;
      }
      
      #common-navigation-mobile-menu-item{
        font-size: 14px;
        text-decoration: none;
      }
      
      #common-navigation-mobile-menu-item a{
        line-height: 11px;
      }

      #common-navigation-mobile-menu-item img{
        margin-top: -3px;
        margin-left: 1px;
        position: absolute;
      }
    }

  </style>

  <div id="common-navigation-left">
    <section id="common-navigation-bkslogo">
      <a href="https://go.blackspectacles.com" >
        <img src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/bks.svg" alt="Black Spectacles logo">
      </a>
    </section>
    <nav id="common-navigation-nav">
      <div id="common-navigation-nav-item">
        <a href="https://go.blackspectacles.com" >LEARN</a>
      </div>
      <div id="common-navigation-nav-item">
        <a href="${url}" >
          SPECTACULAR<img src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/sp-cross.svg" alt="Spectacular logo">
        </a>
      </div>
    </nav>
    <div id="common-navigation-mobile-icon" onclick="document.getElementById('common-navigation-mobile-menu').style.visibility = !document.getElementById('common-navigation-mobile-menu').style.visibility || document.getElementById('common-navigation-mobile-menu').style.visibility === 'hidden' ? 'visible' : 'hidden'; document.getElementById('common-navigation-mobile-menu').style.opacity = !document.getElementById('common-navigation-mobile-menu').style.opacity || document.getElementById('common-navigation-mobile-menu').style.opacity === '0' ? '1' : '0'">
      <img src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/arrow-down.svg" alt="Mobile Menu">
    </div>
  </div>
  <div id="common-navigation-right">
    <div id="common-navigation-whatsnew">
      <a id="common-navigation-whatsnew-text" href="https://go.blackspectacles.com/blog/building-upon-the-black-spectacles-mission">
        Read what's new <img src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/arrow-right.svg" alt="arrow">
      </a>
    </div>
  </div>
  <div id="common-navigation-mobile-menu">
    <div id="common-navigation-mobile-menu-item">
      <a href="https://go.blackspectacles.com" >LEARN</a>
    </div>
    <div id="common-navigation-mobile-menu-item" style="margin-top: 24px;">
      <a href="${url}" >
        SPECTACULAR<img src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/sp-cross.svg" alt="Spectacular logo">
      </a>
    </div>
  </div>
</div>
`

export const CommonNavbar = () => {
  document.body.insertAdjacentHTML("beforebegin", content)
  window.onscroll = function () {
    document.getElementById("common-navigation-mobile-menu")!.style.opacity =
      "0"
    document.getElementById("common-navigation-mobile-menu")!.style.visibility =
      "hidden"
  }
}
