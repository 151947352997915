import { useAuth0 } from "@auth0/auth0-react"
import React from "react"

const LoginWithRedirectPage = () => {
  const auth = useAuth0()

  auth.loginWithRedirect()

  return <></>
}

export default LoginWithRedirectPage
