import React, { useEffect, useCallback } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { useSelector } from "react-redux"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"
import {
  getJobApplicants,
  JobListing,
  selectApplicants,
} from "../../redux/jobListings"

type Props = {
  job: JobListing
  onClose: () => void
  open: boolean
}

const JobApplicantsListDialog = ({ job, open, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  const jobApplicants = useSelector(selectApplicants)

  const handleClose = () => {
    onClose()
  }

  const loadDetailedConnections = useCallback(
    async () => {
      if (job.id) {
        try {
          await dispatch(
            getJobApplicants({
              auth,
              jobId: job.id,
            })
          )
        } catch (e) {
          console.error(e)
        }
      }
    },
    // eslint-disable-next-line
    [dispatch]
  )

  useEffect(() => {
    loadDetailedConnections()
  }, [loadDetailedConnections])

  return (
    <View onClose={handleClose} jobApplicants={jobApplicants} open={open} />
  )
}

export default JobApplicantsListDialog
