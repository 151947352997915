import React from "react"

import {
  IAWinnersCarouselBox,
  IAWinnersCarouselContainer,
  WinnersImgBox,
  WinnersLogoBox,
  WinnersTitleBox,
} from "./styles"

import IAWhiteLogo from "../../images/innovation-awards/ia-white-logo.png"
import IAWinnerLogo from "../../images/innovation-awards/winners-logo.svg"

interface Props {
  label: string
}

const CategoryCard = ({ label }: Props) => {
  return (
    <IAWinnersCarouselContainer>
      <IAWinnersCarouselBox>
        <WinnersImgBox>
          <img src={IAWhiteLogo} alt={"Innovation Awards"} />
        </WinnersImgBox>
        <WinnersLogoBox>
          <img src={IAWinnerLogo} alt={"Innovation Awards"} />
          <WinnersTitleBox>
            Category <br />
            <span>{label}</span>
          </WinnersTitleBox>
        </WinnersLogoBox>
      </IAWinnersCarouselBox>
    </IAWinnersCarouselContainer>
  )
}

export default CategoryCard
