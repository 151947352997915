import { Box } from "@material-ui/core"
import styled from "styled-components"

export const CheckboxContainer = styled(Box)`
  display: flex;
  gap: 12px;
  align-items: flex-start;

  && .MuiIconButton-root {
    padding: 0 !important;
  }
`
