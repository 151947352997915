import React, { useState } from "react"
import { Box, Grid, Link, Typography } from "@material-ui/core"
import JobPositionForm from "../../components/JobPositionForm"
import { JobPosition } from "../../redux/jobPositions"
import ConfirmDialog from "../../components/ConfirmDialog"

export type Props = {
  jobPosition: JobPosition | undefined
  onSubmit: (values: JobPosition) => void
  onDelete: (jobPosition?: JobPosition) => void
}

const View = ({ jobPosition, onSubmit, onDelete }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const showConfirmation = () => {
    setIsDialogOpen(true)
  }

  return (
    <Box paddingLeft={2} paddingRight={2}>
      <Grid container justify="center">
        <Grid item lg={3} sm={6}>
          <Box marginTop={5}>
            <Typography tabIndex={0} variant="h5">
              Edit job position
            </Typography>
          </Box>
          <Box marginTop={2} marginBottom={2}>
            <Link onClick={showConfirmation} color="error">
              Delete position
            </Link>
          </Box>
        </Grid>
      </Grid>
      <JobPositionForm jobPosition={jobPosition} onSubmit={onSubmit} />
      <ConfirmDialog
        title="Delete position"
        message="Are you sure you want to delete this position?"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => onDelete(jobPosition)}
      />
    </Box>
  )
}

export default View
