import _ from "lodash"
import React from "react"
import ImageCarousel, {
  RenderArrowProps,
  RenderPaginationProps,
} from "react-elastic-carousel"

import {
  Square,
  ExpandedRow,
  LeftPaginationArrow,
  RightPaginationArrow,
  ImageThumbnail,
  ArrowContainer,
  CarouselContainer,
  ThumbnailContainer,
  PaginationContainer,
} from "./Styles"

import { useWindowDimensions } from "../../hooks"
import { Image as ImageType, ImageUtils } from "../../redux/projects"
import { ImageGalleryProps, defaultBreakpoints, getBreakpoint } from "./"

const MIN_CHUNK_LENGTH = 18

interface Props extends ImageGalleryProps {
  selected: number
  expanded: boolean
  onSelect: (img: ImageType, idx: number) => void
}

const Carousel = React.forwardRef<any, Props>(
  ({ images, projectName, selected, expanded, onSelect }, ref) => {
    const { width } = useWindowDimensions()

    const breakpoints = defaultBreakpoints(expanded)

    const chunked = _.chunk(images, MIN_CHUNK_LENGTH)

    const renderArrow = ({ type, onClick, isEdge }: RenderArrowProps) => (
      <ArrowContainer onClick={onClick} disabled={isEdge}>
        {type === "PREV" ? <LeftPaginationArrow /> : <RightPaginationArrow />}
      </ArrowContainer>
    )

    const renderPagination = ({
      pages,
      onClick,
      activePage,
    }: RenderPaginationProps) =>
      images.length > getBreakpoint(breakpoints, width).itemsToShow ? (
        <PaginationContainer>
          {pages.map((page: number) => (
            <Square
              key={page}
              isActive={activePage === page}
              onClick={() => onClick(page.toString())}
            />
          ))}
        </PaginationContainer>
      ) : (
        <div />
      )

    const renderThumbnail = (image: ImageType, margin?: boolean) => {
      return (
        <ThumbnailContainer
          key={image.order}
          isSelected={image.order === selected}
          onClick={() => onSelect(image, image.order)}
        >
          <figure>
            <ImageThumbnail
              draggable={false}
              alt={`Online architect portfolio ${projectName}`}
              src={ImageUtils.getThumbnailSrc(image)}
            />
          </figure>
        </ThumbnailContainer>
      )
    }

    const renderExpandedRow = (data: ImageType[], idx: number) => {
      return (
        <ExpandedRow key={idx}>
          {data.map((image) => renderThumbnail(image, true))}
        </ExpandedRow>
      )
    }

    return (
      <CarouselContainer>
        <ImageCarousel
          ref={ref}
          isRTL={false}
          itemPadding={[0, 0]}
          renderArrow={renderArrow}
          breakPoints={breakpoints}
          showArrows={width >= 550 ? true : false}
          renderPagination={renderPagination}
          enableMouseSwipe={false}
        >
          {!expanded
            ? images.map((image) => renderThumbnail(image))
            : chunked.map((images, idx) => renderExpandedRow(images, idx))}
        </ImageCarousel>
      </CarouselContainer>
    )
  }
)

export default Carousel
