import React, { useState, ChangeEvent, useEffect } from "react"
import Cookies from "universal-cookie"
import { useAppDispatch } from "../../redux/configureStore"
import { reSendVerificationEmail } from "../../redux/profile"

import View from "./View"

const NewUser = () => {
  const dispatch = useAppDispatch()
  const cookies = new Cookies()
  const cookieEmailAddress = cookies.get("new_reg_email")
  let message = ""
  const [sendStatus, setSendStatus] = useState(message)
  const [emailAddress, setEmailAddress] = useState(cookieEmailAddress)
  const handleEmailChange = (event: ChangeEvent<{ value: any }>) => {
    setEmailAddress(event.target.value)
  }

  const handleSubmit = async (emailAddress: string) => {
    if (emailAddress) {
      try {
        const { payload } = await dispatch(
          reSendVerificationEmail({ email: emailAddress })
        )
        setSendStatus((message = payload.response))
      } catch (error) {
        console.error(error)
      }
    } else {
      setSendStatus((message = "Please enter your email address."))
    }
  }
  useEffect(() => {
    setEmailAddress(emailAddress)
  }, [emailAddress])
  return (
    <View
      cookieEmailAddress={cookieEmailAddress}
      emailValue={emailAddress}
      onEmailChange={handleEmailChange}
      handleSubmit={handleSubmit}
      sendStatus={sendStatus}
    />
  )
}

export default NewUser
