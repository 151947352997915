import { SelectOption } from "../components/SelectField"

const projectStates: Array<SelectOption> = [
  // { value: "incomplete", name: "Incomplete" },
  { value: "in_progress", name: "In Progress" },
  { value: "unbuilt", name: "Unbuilt" },
  { value: "complete", name: "Complete" },
]

export default projectStates
