import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useAppDispatch } from "../../redux/configureStore"

import { updateNotificationStateById } from "../../redux/bellNotifications"

import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"

import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

const NotificationPopUp = () => {
  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState()

  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const history = useHistory()

  const { width } = useWindowDimensions()

  const notifications = useSelector(
    (state: any) => state.bellNotifications.allNotifications
  )

  const handleNotificationClick = () => {
    history.push("/notifications")
  }

  const handleSetOpen = (value: boolean) => {
    setOpen(value)
  }

  const handleUpdateNotificationState = async (notificationId?: string) => {
    try {
      const state = "popped"
      const { type } = await dispatch(
        updateNotificationStateById({ auth, notificationId, state })
      )
      if (updateNotificationStateById.fulfilled.type === type) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    if (notifications) {
      const filteredNotification = (notifications ?? []).reduce(
        (a: any, b: any) => {
          return new Date(a.createdAt as any as string) >
            new Date(b.createdAt as any as string)
            ? a
            : b
        },
        notifications[0]
      )

      if (filteredNotification?.state === "new") {
        setNotification(filteredNotification)
        handleSetOpen(true)

        handleUpdateNotificationState(filteredNotification?.id)

        setTimeout(() => {
          handleSetOpen(false)
        }, 3000)
      }
    }

    //eslint-disable-next-line
  }, [notifications])

  return (
    <>
      {notification && width > theme.breakpoints.values.sm && (
        <View
          notification={notification}
          open={open}
          handleNotificationClick={handleNotificationClick}
          handleSetOpen={handleSetOpen}
        />
      )}
    </>
  )
}

export default NotificationPopUp
