import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import rootReducer, { RootState } from "./rootReducer"
import logger from "redux-logger"
import environment from "../environment"

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    environment.environment === "Production"
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(logger),
})

export type AppDispatch = typeof store.dispatch
export type AppState = RootState
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store
