import React from "react"

import View from "./View"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { Project } from "../../redux/projects"

type Props = {
  onDelete: () => void
  onEdit: () => void
  onFlag: () => void
  source?: string
  project: Project
}

const ProjectDotMenu = ({
  onDelete,
  onEdit,
  onFlag,
  source,
  project,
}: Props) => {
  const authenticatedProfile = useSelector(
    (state: AppState) => state.profile.profile
  )

  return (
    <View
      onDelete={onDelete}
      onEdit={onEdit}
      onFlag={onFlag}
      authenticatedProfile={authenticatedProfile}
      source={source}
      project={project}
    />
  )
}

export default ProjectDotMenu
