import styled from "styled-components"
import { Box } from "@material-ui/core"

export const ItemBox = styled(Box)<{ $isActive?: boolean }>`
  text-decoration: none;
  margin: 23px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: #717171;
  }

  && img {
    margin-left: 4px;
    position: relative;
  }

  @media only screen and (max-width: 1370px) {
    margin: 23px 10px;
  }

  @media only screen and (max-width: 1265px) {
    margin: 23px 6px;
  }

  @media only screen and (max-width: 1150px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 1030px) {
    margin: 23px 3px;
  }
  @media only screen and (max-width: 990px) {
    font-size: 10px;
  }
`

export const MenuBox = styled.div`
  width: 300px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
  padding: 10px 0;
`

export const StyledA = styled.a`
  font-size: 14px;
  text-decoration: none;
  padding: 10px 20px;
  color: #2d2d2d;
  &:hover {
    background-color: #f2f2f2;
    font-family: Public-Sans-Bold;
  }
`
