import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import { getCredentialSuggestions } from "../../redux/profile"
import { updateCredentialsProfile } from "../../redux/profile"
import View from "./View"
import { RootState } from "../../redux/rootReducer"
import { useAuth0 } from "@auth0/auth0-react"

const ProfileCredentials = () => {
  const dispatch = useAppDispatch()
  const auth = useAuth0()
  const [value, setValue] = useState<string>("")
  const profile = useSelector((state: any) => state.profile.profile)
  const suggestions = useSelector(
    (state: RootState) => state.profile.credentialsSuggestions
  )
  const [credentials, setCredentials] = useState<string[]>(
    profile?.credentials || []
  )
  const [displayError, setDisplayError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCredentialSuggestions({ auth }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth])

  const defaultOptions = [
    "NCARB",
    "AIA",
    "Associate AIA",
    "FAIA",
    "LEED",
    "LEED AP BD+C",
    "LEED AP O+M",
    "LEED AP ID+C",
    "LEED AP ND",
    "LEED Homes",
    "LEED Green Associate",
    "Licensed",
    "NCIDQ",
    "Other",
  ]
  const saveCredentials = async (credentials: string[]) => {
    setDisplayError(false)

    try {
      const { type } = await dispatch(
        updateCredentialsProfile({ credentials, auth })
      )
      if (updateCredentialsProfile.fulfilled.type === type) {
        setCredentials(credentials)
      } else {
        setDisplayError(true)
      }
    } catch (error) {
      setDisplayError(true)
      console.error(error)
    }
  }

  const onInputChange = (value: string) => setValue(value)

  const handleAddCredential = (newCredential: string) => {
    const otherCredential = !defaultOptions.includes(newCredential)

    if (otherCredential) {
      const existingOther = credentials.filter(
        (e) => !defaultOptions.includes(e)
      )
      const existingOtherStr = existingOther.toString()

      let updatedCredentials = credentials.filter(
        (item) => !item.includes(existingOtherStr)
      )

      if (!existingOtherStr)
        updatedCredentials = credentials.filter((item) => item)
      saveCredentials([...updatedCredentials, newCredential])
    } else {
      if (credentials.find((tl) => tl === newCredential)) {
        saveCredentials(credentials.filter((tl) => tl !== newCredential))
      } else {
        saveCredentials([...credentials, newCredential])
      }
    }
    setValue("")
  }

  const handleDeleteCredential = (credentialToDelete: string) => {
    const filteredCredentials = credentials.filter(
      (credential: string) => credential !== credentialToDelete
    )
    saveCredentials(filteredCredentials)
  }

  useEffect(() => {
    if (profile?.credentials) setCredentials(profile.credentials)
  }, [profile])

  return (
    <View
      value={value}
      suggestions={suggestions || []}
      credentials={credentials}
      onInputChange={onInputChange}
      onEnteredPressed={handleAddCredential}
      onDeletedPressed={handleDeleteCredential}
      displayError={displayError}
    />
  )
}

export default ProfileCredentials
