import styled from "styled-components"
import { Dialog, Typography, Box } from "@material-ui/core"
import theme, { colors } from "../../theme"

export const RequestDialog = styled(Dialog)`
  min-height: 100vh;
`

export const RequestDialogTitle = styled(Typography)`
  font-size: 25px;
  color: ${colors.trueBlack};
  border-bottom: 0.5px solid ${colors.dark};
  padding-bottom: 32px;
`

export const RequestDialogBox = styled(Box)`
  padding: 56px;
  width: 700px;
  overflow-x: hidden;

  ${theme.breakpoints.down("sm")} {
    width: 450px;
    padding: 36px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 12px;
    width: 250px;
    padding: 16px;
  }

  ${theme.breakpoints.down(350)} {
    font-size: 12px;
    width: 250px;
    padding: 16px;
  }
`
