import React, { useState } from "react"
import {
  RequestDialog,
  RequestDialogTitle,
  RequestDialogBox,
  DialogCloseButton,
} from "./Styles"
import { Box } from "@material-ui/core"
import CloseIcon from "../../images/x-default.svg"
import CloseIconHover from "../../images/x-hover.svg"
import PostProjectIcon from "../../images/app-navbar/post-project.svg"

import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import { useHistory } from "react-router-dom"
import {
  Description,
  Item,
  PostOptionsBox,
  Title,
} from "../NewPostNavIcon/styles"
import NewPostButton from "../NewPostButton"

export type Props = {
  onClose: () => void
  open: boolean
}

const View = ({ open, onClose }: Props) => {
  const [hover, setHover] = useState(false)
  const history = useHistory()

  const { width } = useWindowDimensions()

  const closeOptions = () => {
    onClose()
  }

  const goToProject = () => {
    closeOptions()
    history.push("/projects/new")
  }

  return (
    <RequestDialog
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      className={"ConnectionRequestDialog"}
    >
      <RequestDialogTitle>
        <Box>What do you want to post?</Box>
        {width > theme.breakpoints.values.sm && (
          <DialogCloseButton
            src={hover ? CloseIconHover : CloseIcon}
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseLeave={() => {
              setHover(false)
            }}
            alt={"close"}
            onClick={onClose}
          />
        )}
      </RequestDialogTitle>
      <RequestDialogBox>
        <PostOptionsBox>
          <Item onClick={goToProject}>
            <img src={PostProjectIcon} alt="Project" />
            <Box>
              <Title>Project</Title>
              <Description>
                Get discovered by uploading your studio or professional work
                here
              </Description>
            </Box>
          </Item>
          <NewPostButton origin="image-nav" closeNav={closeOptions} />
          <NewPostButton origin="update-nav" closeNav={closeOptions} />
        </PostOptionsBox>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default View
