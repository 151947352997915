import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Box } from "@material-ui/core"

export type Props = {
  label: string
  value: string
  error?: string | null
  suggestions: string[]
  onInputChange: (value: string) => void
}

const View = ({ value, label, error, suggestions, onInputChange }: Props) => (
  <Box>
    <Autocomplete
      freeSolo
      value={value}
      inputValue={value}
      options={suggestions}
      id="profile-job-title"
      onInputChange={(_, newValue) => onInputChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          helperText={error}
          error={error !== undefined}
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  </Box>
)

export default View
