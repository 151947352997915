import React, { useState } from "react"
import * as Yup from "yup"
import { Spotlight } from "../../redux/adminSpotlight"

import View from "./View"

export interface EditableSpotlight extends Spotlight {
  editing?: boolean
}

const SpotlightScheme = Yup.object().shape({
  endDate: Yup.string().required("Required"),
  startDate: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
})

type Props = {
  profileSpotlight: EditableSpotlight
  onSave: (profileSpotlight: EditableSpotlight) => void
  onDelete: (profileSpotlight: EditableSpotlight) => void
}

const AdminFirmSpotlightRow = ({
  profileSpotlight,
  onSave,
  onDelete,
}: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [original] = useState(profileSpotlight)
  const [spotlight, setSpotlight] = useState(profileSpotlight)

  const validate = async (feat: EditableSpotlight) =>
    await SpotlightScheme.validate(feat)

  const handleOnSave = async () => {
    try {
      await validate(spotlight)

      setError(null)

      onSave(spotlight)
      setSpotlight({ ...spotlight, editing: false })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message)
    }
  }

  const handleOnChange = async (
    data: any | null,
    property: keyof EditableSpotlight
  ) => {
    if (spotlight.description) setError(null)

    if (spotlight.startDate) setError(null)

    if (spotlight.endDate) setError(null)

    setSpotlight({ ...spotlight, [property]: data })
  }

  const handleOnDateChange = (
    date: Date | null,
    property: keyof EditableSpotlight
  ) => {
    setError(null)
    setSpotlight({ ...spotlight, [property]: date?.toDateString() })
  }

  const handleOnDelete = () => onDelete(spotlight)

  const handleOnEdit = () => setSpotlight({ ...spotlight, editing: true })

  const handleOnCancel = () => setSpotlight({ ...original, editing: false })

  return (
    <View
      error={error}
      spotlight={spotlight}
      onEdit={handleOnEdit}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onChange={handleOnChange}
      onDateChange={handleOnDateChange}
      onDelete={handleOnDelete}
    />
  )
}

export default AdminFirmSpotlightRow
