import { Box, Dialog, Tooltip } from "@material-ui/core"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"
import styled from "styled-components"
import theme, { colors } from "../../theme"

export const IconContainer = styled(Box)<{ $isActive: boolean }>`
  display: flex;
  flex-flow: row;
  cursor: pointer;
  justify-content: center;

  && p {
    font-size: 12px;
    margin-top: 5px;
    color: ${({ $isActive }) => ($isActive ? "#2d2d2d" : "#717171")};
  }
`

export const Icon = styled.img`
  height: 20px;
`

export const CountLabel = styled.span`
  font-size: 12px;
  color: ${colors.slate};
  font-family: Public-Sans;
  margin-top: 2px;
  line-height: 12px;
`

export const IconFlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
`

export const CounterFlexBox = styled(Box)`
  display: flex;
  gap: 8px;
`

export const DialogArea = styled(Dialog)`
  min-height: 100vh;
`

export const DialogBox = styled(Box)`
  overflow-x: hidden;
  width: 520px;
  height: fit-content;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    width: 100%;
  }
`

export const CloseDialogButton = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

export const DialogHeader = styled(Box)`
  border-bottom: 1px solid #c4c4c4;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  && img {
    height: 32px;
  }
`
export const DialogTitle = styled(Box)`
  font-size: 20px;
  margin-top: 5px;
`

export const DialogBody = styled(Box)`
  padding: 32px 24px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
`

export const StyledTooltip = styled(Tooltip)`
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const StyledA = styled.a`
  width: 100%;
  flex: 40%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #2d2d2d;
  }

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const StyledFacebookShareButton = styled(FacebookShareButton)`
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const StyledLinkedinShareButton = styled(LinkedinShareButton)`
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const StyledPinterestShareButton = styled(PinterestShareButton)`
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const StyledTwitterShareButton = styled(TwitterShareButton)`
  width: 100%;
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
  }
`

export const ShareItemBox = styled(Box)`
  height: 64px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  font-family: Public-Sans-Bold;
  color: #2d2d2d;
  cursor: pointer;

  && img {
    width: 24px;
    margin-right: 24px;
  }

  &:hover {
    background: #f9f9f9;
  }
`
