import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import environment from "../../environment"

const AuthProvider = ({ children }: any) => {
  const domain = environment.auth0Domain
  const clientId = environment.auth0ClientId
  const audience = environment.auth0Audience
  const redirectUri = environment.redirectUri

  return environment.device === "desktop" ? (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/authentication_callback`,
        audience: audience,
      }}
    >
      {children}
    </Auth0Provider>
  ) : (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: "openid profile email offline_access",
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
