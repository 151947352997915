import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import { addComment, CommentMention } from "../../redux/projects"
import { Profile } from "../../redux/profile"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

type Props = {
  id: string
}

const ProjectPostComment = ({ id }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profile = useSelector((state: any): Profile => state.profile?.profile)
  const [displayError, setDisplayError] = useState(false)

  const handlePostComment = async (
    text: string,
    mentions?: CommentMention[]
  ): Promise<void> => {
    try {
      const { type } = await dispatch(addComment({ id, auth, text, mentions }))
      if (addComment.fulfilled.type === type) {
        if (displayError) setDisplayError(false)
        return Promise.resolve()
      } else {
        setDisplayError(true)
        return Promise.reject()
      }
    } catch (error) {
      setDisplayError(true)
      console.error(error)
      return Promise.reject(error)
    }
  }

  return (
    <View
      onPostComment={handlePostComment}
      profile={profile}
      displayError={displayError}
    />
  )
}

export default ProjectPostComment
