import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

export const SearchBox = styled(Box)`
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #dfe5f8;
  width: 100%;
`

export const UpperBox = styled(Box)`
  display: flex;
  box-sizing: border-box;
`

export const SearchIconElement = styled.img`
  position: absolute;
  margin-top: 9px;
  margin-left: 14px;
  cursor: pointer;
`

export const ClearSearchElement = styled.img`
  position: absolute;
  cursor: pointer;
  top: 32px;
  position: absolute;
  cursor: pointer;
  right: 26px;

  ${theme.breakpoints.down("xs")} {
    top: 21px;
    right: 22px;
  }
`

export const LeftBox = styled(Box)`
  position: relative;
  padding: 24px 16px 12px;
  width: 100%;
  box-sizing: border-box;

  ${theme.breakpoints.down("xs")} {
    padding: 12px;
  }
`

export const RightBox = styled(Box)`
  border-left: 0.5px solid #dfe5f8;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 4px;
  padding: 0 8px;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const RightBoxMobile = styled(Box)`
  border-left: 0.5px solid #dfe5f8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 64px;

  && img {
    position: initial;
    margin-top: 2px;
    margin-left: 0;
  }

  ${theme.breakpoints.up("sm")} {
    display: none;
  }
`

export const LowerBox = styled(Box)`
  border-top: 0.5px solid #dfe5f8;
  height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  justify-content: space-between;

  ${theme.breakpoints.down("xs")} {
    display: none;
  }
`

export const SearchInput = styled.input`
  border: 1px solid #dadada;
  border-radius: 45px;
  font-family: Public-Sans-Medium;
  font-size: 16px;
  color: #2d2d2d;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 36px;
  padding-right: 28px;

  ::placeholder {
    opacity: 1;
    color: #2d2d2d;
  }

  &:focus {
    border: 1px solid #dadada !important;
    outline: none !important;
    background-color: #fff !important;
  }

  &: hover {
    border: 1px solid #979797;
    background-color: #f2f2f2;
  }
`

export const SearchLabel = styled(Typography)`
  font-size: 12px;
  padding: 0 8px;
`

export const SearchCategories = styled(Box)`
  display: flex;
  gap: 8px;
`

export const SearchCategory = styled(Box)<{ $isActive: boolean }>`
  font-family: Public-Sans-Bold;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;

  ${({ $isActive }) =>
    $isActive
      ? `
    color:#fff;
    background-color:#2d2d2d;
    border-radius: 37px;
    padding: 4px 8px;
  `
      : `
    &: hover {
      background-color:#D9D9D9;
      border-radius: 37px;
      padding: 4px 8px;
    }    
  `}
`

export const SuggestedItem = styled(Typography)`
  font-size: 12px;
  color: #3057e1;
  cursor: pointer;
  && span {
    font-family: Public-Sans-Bold;
    color: #979797;
  }
`

export const FilterItem = styled(Box)<{ $isActive: boolean }>`
  font-family: Public-Sans-Bold;
  font-size: 14px;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive
      ? `
    color:#fff;
    background-color:#2d2d2d;
    border-radius: 37px;
    padding: 4px 8px;
  `
      : ""}
`
