import styled from "styled-components"
import { Box, Typography, Button, Tabs, Tab } from "@material-ui/core"
import { Pagination } from "@material-ui/lab"

import theme, { colors } from "../../../theme"
import { Link } from "react-router-dom"

export const CustomTab = styled(Tabs)`
  && {
    .MuiTab-wrapper {
      margin-top: -6px !important;
      margin-bottom: -6px !important;
    }
  }
`

export const TabItem = styled(Tab)`
  && {
    min-width: inherit;
    padding: 0 0 40px 0;
    margin-right: 40px;

    ${theme.breakpoints.down("md")} {
      margin-right: 32px;
      padding: 0 0 10px 0;
    }

    h6 {
      font-family: "Public-Sans";
      text-transform: none;
      color: ${colors.dark};
      font-weight: 400;

      ${theme.breakpoints.down("md")} {
        font-size: 16px;
      }
    }
  }
`

export const LocationText = styled.span`
  margin-top: -4px;
  && {
    font-family: "Public-Sans";
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #717171;

    &:before {
      margin-top: -5px;
    }

    &:after {
      margin-bottom: -3px;
    }
  }
`

export const MainPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    width: 56px;
    height: 56px;
    border-radius: 0;
    margin: 0;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: ${colors.dark};
    color: ${colors.white};
  }

  .MuiPagination-ul {
    li {
      margin-right: 6px;
    }

    li:last-child {
      margin: 0;
    }
  }
`

export const RequestBox = styled(Box)`
  background-color: ${colors.gray};
  padding: 44px 56px;

  ${theme.breakpoints.down("sm")} {
    text-align: center;
  }
`

export const RequestBoxWithJobs = styled(Box)`
  padding: 40px 32px 32px;
  background-color: #f7f7f7;

  ${theme.breakpoints.down("sm")} {
    padding: 16px;
  }
`

export const RequestBoxTitle = styled(Typography)`
  color: #2d2d2d;
  font-size: 40px;
  line-height: 45px;

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
    line-height: 28px;
  }
`

export const JobTitle = styled(Link)`
  color: #2d2d2d;
  cursor: pointer;
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const RequestBoxText = styled(Typography)`
  font-family: "Public-Sans";
  font-size: 16px;
  color: #2d2d2d;
  padding-top: 20px;
`

export const RequestButton = styled(Button)`
  // margin-left: 40px;

  ${theme.breakpoints.down("sm")} {
    width: 139.2px;
    margin-left: 0;
    margin-top: 32px;
    align-self: center;
  }
`

export const AboutSelfText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: -3px;
`
