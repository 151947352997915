import React from "react"
import _ from "lodash"
import { DraggableLocation, DropResult } from "react-beautiful-dnd"

import View from "./View"

import { Image as ImageType } from "../../redux/projects"

const MAX_PER_ROW = 4

export interface Props {
  images?: ImageType[]
  onSelect: (index: number) => void
  onChange: (newImages: ImageType[], removed?: string[]) => void
}

export const NewStyledImageList: React.FC<Props> = ({
  images,
  onChange,
  onSelect,
}) => {
  const [rows, setRows] = React.useState<Array<ImageType[]>>(
    _.chunk(images!, MAX_PER_ROW)
  )

  React.useEffect(() => {
    setRows(_.chunk(images!, MAX_PER_ROW))
  }, [images])

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)

    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const move = (
    source: ImageType[],
    destination: ImageType[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const result: any = {}
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)

    const resolveResult = () => {
      result[droppableSource.droppableId] = sourceClone
      result[droppableDestination.droppableId] = destClone

      return result
    }

    const [removed] = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)

    return resolveResult()
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) return

    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      const items = reorder(rows[sInd], source.index, destination.index)
      const newState = [...rows]
      newState[sInd] = items
      handleChange(newState)
    } else {
      const result = move(rows[sInd], rows[dInd], source, destination)
      const newState = [...rows]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      handleChange(newState.filter((group) => group.length))
    }
  }

  const handleChange = (newRows: Array<ImageType[]>, removed?: string[]) => {
    const ordered: ImageType[] = []
    for (const row of Array.from(newRows)) {
      for (const image of row) {
        ordered.push(Object.assign({}, image))
      }
    }

    for (let idx = 0; idx < ordered.length; idx++) {
      ordered[idx].order = idx
    }

    onChange(ordered, removed)
    setRows(newRows)
  }

  return (
    <View
      rows={rows}
      images={images!}
      onClick={onSelect}
      onDragEnd={onDragEnd}
      onChange={handleChange}
    />
  )
}
