import { Link } from "react-router-dom"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"

import bgWinners from "../../images/innovation-awards/ia-winners-bg.png"

export const BgBox = styled(Box)`
  background-color: #000000;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding-bottom: 40px;

  && .carouselHeight {
    height: -webkit-fill-available;
  }

  && .CarouselItem {
    height: -webkit-fill-available !important;
  }

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 20px;

    && .carouselHeight {
      height: auto;
    }

    && .CarouselItem {
      height: auto !important;
    }
  }

  ${theme.breakpoints.down("xs")} {
    padding-bottom: 20px;
  }
`

export const IAWinnersCarouselContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    margin-top: 0;
  }
`

export const IAWinnersCarouselBorderedContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    margin-top: 0;
    height: auto;
    padding-bottom: 32px;
  }
`

export const IAWinnersCarouselBorderedBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.down("sm")} {
    padding: 0px 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 0px 20px;
  }
`

export const IAWinnersCarouselBorderedProjectBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 20px 80px 48px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: -webkit-fill-available;

  ${theme.breakpoints.down("sm")} {
    height: auto;
    padding: 32px 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 10px 10px 0;
  }
`

export const IAWinnersCarouselBox = styled(Box)`
  max-width: 1440px;
  width: 100%;
  padding: 80px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 40px;
  }
  ${theme.breakpoints.down("xs")} {
    padding: 20px;
  }
`

export const IAWinnersBorder = styled(Box)`
  border-bottom: 2px solid #ffffff;
  width: 100%;
`

export const WinnersImgBox = styled(Box)`
  padding-top: 100px;

  && img {
    width: 450px;
  }

  @media only screen and (max-width: 1130px) {
    padding-top: 80px;
  }

  @media only screen and (max-width: 1030px) {
    padding-top: 60px;
  }

  ${theme.breakpoints.down("sm")} {
    padding-top: 80px;
  }

  @media only screen and (max-width: 820px) {
    padding-top: 60px;
  }

  @media only screen and (max-width: 730px) {
    padding-top: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    && img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 450px) {
    padding-top: 20px;
  }
`

export const WinnersLogoBox = styled(Box)`
  display: flex;
  gap: 32px;
  padding-top: 80px;
  width: 100%;
  justify-content: center;

  && img {
    height: 170px;
  }

  ${theme.breakpoints.down("sm")} {
    gap: 20px;

    && img {
      height: 150px;
    }
  }

  @media only screen and (max-width: 730px) {
    padding-top: 60px;
  }

  @media only screen and (max-width: 700px) {
    padding-top: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    gap: 20px;

    padding-top: 32px;

    && img {
      height: 100px;
    }
  }

  @media only screen and (max-width: 450px) {
    padding-top: 20px;
  }
`

export const WinnersTitleBox = styled(Typography)`
  color:#fff;
  font-size: 50px;
  line-height: 56px;
  margin-top: -10px;
  text-align: left;
  
  && span {
    font-size: 64px;
    font-family: Public-Sans-Bold;
  }
  
  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
    line-height: 45px;
    margin-top: -10px;

    && span {
      font-size: 50px;
    }
  }
  
  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
    line-height: 30px;
    margin-top: -5px;

    && span {
      font-size: 32px;
    }
  }
  }
`

export const IAWinnersHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;

  && img {
    width: 275px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 16px 0;
  }

  @media only screen and (max-width: 700px) {
    && img {
      width: 200px;
    }
  }

  @media only screen and (max-width: 460px) {
    && img {
      width: 180px;
    }
  }

  @media only screen and (max-width: 360px) {
    && img {
      width: 160px;
    }
  }
`

export const IAWinnersCategory = styled(Typography)`
  font-size: 48px;
  color: #fff;
  font-family: Public-Sans-Extra-Light;

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 24px;
  }
`

export const IAWinnersProject = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;

  ${theme.breakpoints.down("sm")} {
    padding-top: 0px;
  }
`

export const IAPeopleChoiceBadge = styled(Box)`
  display: flex;
  flex-flow: column;
  min-width: 200px;
  justify-content: center;
  padding-right: 32px;

  && img {
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    min-width: 140px;

    && img {
      height: 120px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    justify-content: flex-start;
    min-width: 70px;

    && img {
      height: 70px;
    }

    width: auto;
    padding-right: 8px;
  }
`

export const ImageThumbnailContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 59%;
  position: relative;
  border-radius: 4px;
`

export const ImageThumbnail = styled.img`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
`

export const IAWinnersThumbnail = styled(Box)`
  width: 850px;
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.down("xs")} {
    width: 90%;
  }
`
export const IAThumbnailNames = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: end;
`

export const ProjectName = styled(Typography)<{ $alignAtEnd?: boolean }>`
  margin-top: 10px;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  font-family: Public-Sans-Bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 0px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 14px;
  }
`

export const ProfileName = styled(Typography)<{ $alignAtEnd?: boolean }>`
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;

  -webkit-box-orient: vertical;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 20px;
  }

  @media only screen and (max-width: 620px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
    color: #fff;
  }
`

export const PeopleChoiceLabel = styled(Typography)`
  font-size: 40px;
  color: #fff;
  font-family: Public-Sans-Extra-Light;
  margin-top: 16px;

  && sup {
    font-size: 24px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 28px;
    line-height: 34px;

    && sup {
      font-size: 16px;
    }
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;

    && sup {
      font-size: 8px;
    }
  }

  @media only screen and (max-width: 420px) {
    && img {
      font-size: 16px;
    }
  }
`

export const IAWinnersProjectFooter = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100%;
  text-align: right;
`

export const ButtonBox = styled(Box)`
  text-align: center;
  padding: 24px 20px 0;

  ${theme.breakpoints.down("xs")} {
    padding: 8px 20px 0;

    && button {
      height: 40px !important;
    }
  }
`

export const WinnersTitle = styled(Typography)`
  font-size: 64px;
  line-height: 36px;
  margin-top: 88px;
  padding-bottom: 76px;
  box-sizing: border-box;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    margin-top: 56px;
    font-size: 40px;
    padding-bottom: 41px;
  }
`

export const WinnersBoxBordered = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  border-bottom: 2px solid #fff;
`

export const WinnersBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #000;
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
  padding-bottom: 66px;

  ${theme.breakpoints.down("sm")} {
    padding-bottom: 56px;
  }
`

export const WinnersContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 20px;
  }
`

export const WinnersHeader = styled(Box)`
  padding: 64px 0;

  ${theme.breakpoints.down("sm")} {
    padding: 40px 0;
  }
`

export const WinnersProjectsBoxSingleRow = styled(Box)<{
  $hasPaddingTop?: boolean
}>`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 32px;

  padding-top: ${({ $hasPaddingTop }) => ($hasPaddingTop ? "64px" : "")};
`

export const WinnersProjectsBox = styled(Box)<{ $hasPaddingTop?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px 24px;

  padding-top: ${({ $hasPaddingTop }) => ($hasPaddingTop ? "64px" : "")};
`

export const CardBox = styled(Box)`
  flex: 40%;

  ${theme.breakpoints.down("xs")} {
    flex: auto;
    width: 100%;
  }
`

export const CardImageThumbnailContainer = styled.div<{
  $isSingleRow?: boolean
}>`
  position: relative;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: ${({ $isSingleRow }) => ($isSingleRow ? "4/2" : "4/3")};

  ${theme.breakpoints.down("xs")} {
    aspect-ratio: 4/3;
  }
`

export const CardImageThumbnail = styled.img<{ $isSingleRow?: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  object-fit: cover;
  aspect-ratio: ${({ $isSingleRow }) => ($isSingleRow ? "4/2" : "4/3")};
  height: -webkit-fill-available;

  ${theme.breakpoints.down("xs")} {
    aspect-ratio: 4/3;
  }
`

export const CardNames = styled(Box)`
  margin-top: 8px;
`

export const CardProjectName = styled(Typography)`
  color:#fff;
  font-size: 16px;
  ling-height 18px;
  font-family: Public-Sans-Bold;
`

export const CardProfileName = styled(Typography)`
  color: #fff;
  font-size: 14px;
`

export const InnovationAwardsCarouselPlaceholder = styled(Box)`
  background: url(${bgWinners});
  background-position: center;
  background-color: #000000;
  background-size: cover;

  height: 880px;

  @media only screen and (max-width: 1180px) {
    height: 860px;
  }

  @media only screen and (max-width: 1130px) {
    height: 830px;
  }

  @media only screen and (max-width: 1080px) {
    height: 790px;
  }

  @media only screen and (max-width: 1030px) {
    height: 750px;
  }

  ${theme.breakpoints.down("sm")} {
    height: 750px;
  }

  @media only screen and (max-width: 920px) {
    height: 720px;
  }

  @media only screen and (max-width: 870px) {
    height: 680px;
  }

  @media only screen and (max-width: 820px) {
    height: 650px;
  }

  @media only screen and (max-width: 780px) {
    height: 630px;
  }

  @media only screen and (max-width: 730px) {
    height: 610px;
  }

  @media only screen and (max-width: 700px) {
    height: 570px;
  }

  @media only screen and (max-width: 650px) {
    height: 540px;
  }

  @media only screen and (max-width: 540px) {
    height: 490px;
  }

  @media only screen and (max-width: 510px) {
    height: 480px;
  }

  @media only screen and (max-width: 480px) {
    height: 450px;
  }

  @media only screen and (max-width: 450px) {
    height: 430px;
  }

  @media only screen and (max-width: 414px) {
    height: 410px;
  }

  @media only screen and (max-width: 395px) {
    height: 400px;
  }

  @media only screen and (max-width: 375px) {
    height: 390px;
  }
`
